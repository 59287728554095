<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <div
        class="d-flex justify-center align-center mb-3 mt-4"
        style="width:100%"
      >
        <div class="mr-2">
          <div
            class="rounded-pill white d-flex align-center justify-space-between py-1 px-1"
            style="height: 100%; position: relative;"
          >
            <a-label class="px-4">{{ $t("period") }}:</a-label>
            <v-btn
              small
              rounded
              fab
              :text="toggle_mode !== 0"
              :color="toggle_mode === 0 ? 'secondary' : 'black'"
              @click="toggle_mode = 0"
            >
              <v-icon small :color="toggle_mode === 0 ? 'white' : 'black'">
                mdi-table-row
              </v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              fab
              :text="toggle_mode !== 1"
              :color="toggle_mode === 1 ? 'secondary' : 'black'"
              @click="toggle_mode = 1"
              class="ml-2"
            >
              <v-icon small :color="toggle_mode === 1 ? 'white' : 'black'">
                mdi-table-large
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <template v-if="!toggle_mode">
        <a-rms-strategy-weeks-resume-kpis></a-rms-strategy-weeks-resume-kpis>
      </template>
      <template v-if="toggle_mode">
        <a-rms-strategy-weeks-detail-kpis></a-rms-strategy-weeks-detail-kpis>
      </template>
      <div class="d-flex justify-end" v-if="show_bulk_edit">
        <a-chm-bulk-edit
          ref="channelManagerBulkEdit"
          :save-on-close="true"
          @onSave="pricesChanged"
        >
          <template v-slot:button>
            <v-btn
              depressed
              class="super-raised mt-2"
              right
              rounded
              outlined
              color="info"
              @click="openChannelManagerBulkEdit"
            >
              <v-icon small class="mr-2">mdi-pencil-outline</v-icon>
              {{ $t("bulk_edit") }}
            </v-btn>
          </template>
        </a-chm-bulk-edit>
      </div>
      <div
        class="d-flex justify-start pb-3 pt-4"
        v-for="(item, week_index) in filtered_weeks"
        :key="'week_' + week_index"
        @mouseover="hoverWeek(week_index)"
        @mouseleave="hoverWeek(null)"
      >
        <div class="flex-grow-0" style="flex-basis:100px;">
          <a-rms-labels :row="row * (week === week_index)"> </a-rms-labels>
        </div>
        <div class="flex-grow-1">
          <v-row no-gutters>
            <v-col
              class="cols7 pl-3"
              v-for="(day, index) in item"
              :key="'day_' + index"
            >
              <a-rms-day
                v-if="mustShowDay(day)"
                :item="day"
                :detail_days="detail_days"
                @getDayDetails="getDayDetails"
                @hoverRow="hoverRow"
              >
              </a-rms-day>
              <a-rms-day-empty v-else> </a-rms-day-empty>
            </v-col>
          </v-row>
        </div>
      </div>
      <div
        class="day_details_mini secondary darken-2"
        v-if="detail_days.length > 0 && show_details_mini"
      >
        <v-card class="pa-0">
          <v-card-title class="pa-0">
            <v-btn
              icon
              color="grey"
              small
              @click="closeAllDaysDetails"
              class="mt-0"
              style="position: absolute; top: 10px; right: 15px;"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              color="grey"
              small
              @click="
                show_details_mini = false;
                show_details = true;
              "
              class="mt-0"
              style="position: absolute; top: 10px; right: 45px;"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <div class="secondary darken-3 pl-3" style="width:100%">
              <v-tabs
                background-color="transparent"
                center-active
                dark
                class="d-flex justify-start"
              >
                <v-tab
                  @click="
                    show_details_mini = false;
                    show_details = true;
                  "
                  v-for="(date, index) in detail_days"
                  :key="'tab_' + index"
                  class="pl-3 pr-10 text-capitalize"
                  >{{ getFriendlyDay(date) }}
                  <v-btn
                    icon
                    x-small
                    @click.stop="dropDayDetails(date)"
                    style="position:absolute; top: 15px; right: 10px;"
                    class="mt-0"
                  >
                    <v-icon x-small color="grey " style="margin-top: 2px;"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-tab>
              </v-tabs>
            </div>
          </v-card-title>
        </v-card>
      </div>
      <v-dialog
        v-model="show_details"
        style="margin-left:50px;"
        max-width="90%"
        class="pa-0"
        scrollable
        fullscreen
      >
        <v-card class="pa-0">
          <v-card-title class="pa-0">
            <v-btn
              icon
              color="grey"
              small
              @click="closeAllDaysDetails"
              class="mt-0"
              style="position: absolute; top: 10px; right: 15px;"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              color="grey"
              small
              @click="
                show_details_mini = true;
                show_details = false;
              "
              class="mt-0"
              style="position: absolute; top: 10px; right: 45px;"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <div class="secondary darken-3 pl-3" style="width:100%">
              <v-tabs
                background-color="transparent"
                center-active
                dark
                class="d-flex justify-start"
                @click="show_details_mini = false"
                v-model="tab"
              >
                <v-tab
                  @click="show_details_mini = false"
                  v-for="(date, index) in detail_days"
                  :key="'tab_' + index"
                  class="pl-3 pr-10 text-capitalize"
                  >{{ getFriendlyDay(date) }}
                  <v-btn
                    icon
                    x-small
                    @click.stop="dropDayDetails(date)"
                    style="position:absolute; top: 15px; right: 10px;"
                    class="mt-0"
                  >
                    <v-icon x-small color="grey " style="margin-top: 2px;"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-tab>
              </v-tabs>
            </div>
          </v-card-title>
          <v-card-text class="px-0" style="overflow-x:hidden;">
            <v-row>
              <v-col cols="12" sm="3"></v-col>
              <v-col cols="12" sm="6">
                <v-list nav class="mb-0 pb-0">
                  <v-list-item-group
                    v-model="selectedTab"
                    color="secondary"
                    class="d-flex"
                  >
                    <v-list-item
                      class="mx-2 text-center mb-0 "
                      v-for="(item, i) in items"
                      :key="i"
                    >
                      <v-list-item-content class="ml-0 pr-3">
                        <v-icon class="mb-2">{{ item.icon }}</v-icon>
                        <v-list-item-title
                          class="text-uppercase"
                          v-text="$t(item.text)"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col cols="12" sm="3"></v-col>
              <v-col cols="12" class="pt-0">
                <div class="px-3">
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(date, i) in detail_days" :key="i">
                      <v-row v-if="selectedTab === 0">
                        <v-col cols="12" sm="12">
                          <a-rms-day-room-types
                            :date="date"
                            @close-dialog="dropDayDetails(date)"
                          ></a-rms-day-room-types>
                        </v-col>
                        <v-col cols="12" sm="9" class="pt-0">
                          <a-rms-day-general
                            :date="date"
                            :dense="true"
                          ></a-rms-day-general>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <a-rms-day-general-events
                            :date="date"
                            ref="a-rms-day-general-events"
                          >
                          </a-rms-day-general-events>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0">
                          <a-rms-day-general-pickup
                            :date="date"
                            :dense="true"
                          ></a-rms-day-general-pickup>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <a-rms-day-compset :date="date"></a-rms-day-compset>
                        </v-col>
                      </v-row>
                      <a-rms-day-availability
                        :ref="'a-rms-day-availability_' + tab"
                        v-if="selectedTab === 1"
                        :date="date"
                        :max_days="7"
                      ></a-rms-day-availability>
                      <a-rms-day-price-evolution
                        v-if="selectedTab === 2"
                        :date="date"
                      ></a-rms-day-price-evolution>
                      <a-rms-day-comments
                        v-if="selectedTab === 3"
                        :date="date"
                      ></a-rms-day-comments>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:loading>
      <v-row>
        <v-col cols="12" md="3" v-for="i in 4" :key="i">
          <v-card height="90px">
            <v-skeleton-loader
              type="list-item-three-line"
              height="200"
              class="elevation-0"
            >
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <v-card-text>
          <v-row class="">
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="3" v-for="j in 12" :key="j">
                  <v-skeleton-loader
                    type="card"
                    height="200"
                    class="elevation-0"
                  >
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="3" v-for="j in 12" :key="j">
                  <v-skeleton-loader
                    type="card"
                    height="200"
                    class="elevation-0"
                  >
                  </v-skeleton-loader>
                </v-col> </v-row
            ></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import * as Utils from "@/utils.js";
import { mapState, mapGetters, mapActions } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
import aLabel from "@/components/aLabel.vue";
import aRmsDay from "@/components/rms/hotelsdot/aRmsDay";
import aRmsDayEmpty from "@/components/rms/hotelsdot/aRmsDayEmpty";
import aRmsLabels from "@/components/rms/hotelsdot/aRmsLabels";
import aRmsDayAvailability from "@/components/rms/hotelsdot/aRmsDayAvailability";
import aRmsStrategyWeeksResumeKpis from "@/components/rms/hotelsdot/aRmsStrategyWeeksResumeKpis";
import aRmsStrategyWeeksDetailKpis from "@/components/rms/hotelsdot/aRmsStrategyWeeksDetailKpis";
import aRmsDayGeneral from "@/components/rms/hotelsdot/aRmsDayGeneral.vue";
import aRmsDayRoomTypes from "@/components/rms/hotelsdot/aRmsDayRoomTypes.vue";
import aRmsDayComments from "@/components/rms/hotelsdot/aRmsDayComments.vue";
import aRmsDayPriceEvolution from "@/components/rms/hotelsdot/aRmsDayPriceEvolution.vue";
import aRmsDayGeneralEvents from "@/components/rms/hotelsdot/aRmsDayGeneralEvents.vue";
import ARmsDayGeneralPickup from "@/components/rms/hotelsdot/aRmsDayGeneralPickup.vue";
import aChmBulkEdit from "@/components/channel-manager/aChmBulkEdit.vue";
import ARmsDayCompset from "@/components/rms/hotelsdot/aRmsDayCompset.vue";

export default {
  name: "a-rms-strategy-weeks",
  components: {
    ARmsDayCompset,
    aChmBulkEdit,
    ARmsDayGeneralPickup,
    aBaseComponent,
    aLabel,
    aRmsDay,
    aRmsDayEmpty,
    aRmsLabels,
    aRmsDayAvailability,
    aRmsStrategyWeeksResumeKpis,
    aRmsStrategyWeeksDetailKpis,
    // aCard,
    // aLabel,
    // aPeriodCompare,
    // aKpi,
    //aCardKpi,
    aRmsDayGeneral,
    aRmsDayRoomTypes,
    aRmsDayComments,
    aRmsDayPriceEvolution,
    aRmsDayGeneralEvents
  },
  props: {
    event_period: {
      type: Array,
      default: () => []
    }
  },
  data: vm => ({
    title: "",
    subscribed_modules: [
      "rate_available_rate_extractions",
      "rms_all_kpis_daily",
      "rms_all_kpis_daily_pickup",
      "rms_pvp_mlos_changes_daily",
      "rms_strategy_range_kpis",
      "rms_strategy_range_kpis_table",
      "rms_strategy_range_kpis_pickups_table",
      "segmentschannels_segments",
      "segmentschannels_segment_channels",
      "roomtype_types_mapped"
    ],
    subscribed_modules_footer: ["rms_roomtype_availability"],
    // init_date: "",
    current_visible_week_index: 0,
    item_height: 300,
    table_height: "calc(100% - 200px)",
    edit_event_period: [],
    show_range_selector: false,
    picker_date: null,
    picker_date2: null,
    event_selected: null,
    detail_days: [],
    show_details: false,
    show_details_mini: false,
    row: null,
    week: null,
    selectedTab: 0,
    toggle_mode: 0,
    items: [
      { text: "general", icon: "mdi-information-outline" },
      // { text: "room_types", icon: "mdi-bed-king-outline" },
      { text: "availability", icon: "mdi-select-compare" },
      // { text: "competence", icon: "mdi-compare" },
      { text: "price_evolution", icon: "mdi-chart-timeline-variant" },
      { text: "comments", icon: "mdi-comment-multiple-outline" }
    ],
    tab: 0
  }),
  watch: {
    picker_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        let d = Vue.moment(newVal + "-01").add(1, "month");
        this.picker_date2 = d.format("YYYY-MM");
      }
    },
    picker_date2(newVal, oldVal) {
      if (newVal !== oldVal) {
        let d = Vue.moment(newVal + "-01").subtract(1, "month");
        this.picker_date = d.format("YYYY-MM");
      }
    },
    event_selected(newVal) {
      if (newVal) {
        let event = this.events.find(e => e.id === newVal);
        this.setPeriod([event.start_date, event.end_date]);
      }
    },
    detail_days: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          let date = newVal[this.tab];
          if (!this.show_details_mini) this.show_details = true;
          this.getRates();
          this.$store.dispatch("setRateDateToCompare", date);
          this.setAvailabilityRange(date);
        } else {
          // this.show_details_mini = false;
          this.show_details = false;
        }
      }
    },
    tab(newVal, oldVal) {
      if (newVal >= 0) {
        let date = this.detail_days[newVal];
        this.getRates();
        this.$store.dispatch("setRateDateToCompare", date);
        this.setAvailabilityRange(date);
      }
    },
    year() {
      this.getHotelEvents();
    },
    current_hotel() {
      this.setDefault();
    },
    event_period() {
      this.getDailyRates();
      this.getDailyWeather();
    }
  },
  methods: {
    openChannelManagerBulkEdit() {
      this.$refs.channelManagerBulkEdit.openModal();
    },
    setAvailabilityRange(date) {
      // Reload availability dates
      let init_date_moment = Vue.moment(date).subtract(3, "days");
      this.availability_from = init_date_moment.format("YYYY-MM-DD");
      this.availability_to = init_date_moment
        .add(6, "days")
        .format("YYYY-MM-DD");
      this.$store.dispatch("fetchRmsRoomtypeAvailability");
    },
    getRates() {
      this.$store.dispatch("setRateFilters", {
        extraction_id: this.available_rate_import_ids[0],
        vs_id: this.available_rate_import_ids[1],
        pax: 2,
        childs: 0,
        regime: [],
        cancel_policy: [],
        genius: false,
        mobile_device: true
      });
    },
    closeAllDaysDetails() {
      this.detail_days = [];
    },
    mustShowDay(day) {
      if (!day) return false;
      if (!day.date) return false;
      if (
        !Vue.moment(day.date).isBetween(
          this.event_period[0],
          this.event_period[1],
          undefined,
          "[]"
        )
      )
        return false;
      return true;
    },
    hoverRow(index) {
      this.row = index;
    },
    hoverWeek(index) {
      this.week = index;
    },
    getDayDetails(date) {
      if (!this.detail_days.includes(date)) {
        this.detail_days.push(date);
      }
      this.tab = this.detail_days.indexOf(date);
    },
    dropDayDetails(date) {
      if (this.detail_days.includes(date)) {
        let index = this.detail_days.indexOf(date);
        if (index !== -1) {
          this.detail_days.splice(index, 1);
        }
      }
    },
    getHotelEvents() {
      if (!this.current_hotel) return;
      this.$store
        .dispatch("getHotelEvents", {
          hotel_id: this.current_hotel.id,
          year: this.year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getChannelManagerTable() {
      this.$store.dispatch("fetchChannelmanagerTableData", {}).catch(() => {
        Utils.showError(this, "something_went_wrong");
      });
    },
    getDailyRates() {
      this.$store.dispatch("fetchRmsAllKpisDailyCompset").catch(() => {
        Utils.showError(this, "something_went_wrong");
      });
    },
    getDailyWeather() {
      this.$store.dispatch("fetchRmsWeatherDaily").catch(() => {
        Utils.showError(this, "something_went_wrong");
      });
    },
    setPeriod(v) {
      this.edit_event_period = JSON.parse(JSON.stringify(v));
      this.picker_date = Vue.moment(v[0]).format("YYYY-MM");
    },

    showRangeSelector() {
      this.show_range_selector = true;
      this.edit_event_period = JSON.parse(JSON.stringify(this.event_period));
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    getImpactColor(impact) {
      return this.calendar_store.impacts.find(i => i.value === impact).color;
    },
    eventPeriodAllowedDate(date) {
      return true;
    },
    getTitleDateFormat(dates) {
      return Utils.getFriendlyPeriod(dates[0], dates[1]);
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date);
    },
    getFriendlyDay(date) {
      return date ? Utils.getFriendlyDay(date) : "";
    },
    setDefault() {
      // Scroll event control
      setTimeout(() => {
        this.getHotelEvents();
        this.getChannelManagerTable();
        this.getDailyRates();
        this.getDailyWeather();

        const scroller = document.getElementById("weeks_table");
        if (scroller) {
          scroller.addEventListener("scroll", e => {
            this.current_visible_week_index = parseInt(
              e.target.scrollTop / this.item_height
            );
          });
        }
      }, 1000);
    },
    pricesChanged() {
      this.$store.dispatch("fetchRmsAllKpisDaily");
      this.$store.dispatch("fetchRmsPvpMlosChangesDaily");
    },

    ...mapActions([])
  },
  computed: {
    availability_from: {
      get() {
        return this.$store.state.rms.availability_from;
      },
      set(value) {
        this.$store.dispatch("setAvailabilityFrom", value);
      }
    },
    availability_to: {
      get() {
        return this.$store.state.rms.availability_to;
      },
      set(value) {
        this.$store.dispatch("setAvailabilityTo", value);
      }
    },
    available_rate_import_ids() {
      // In this.available_rate_extractions we have a list of [{date: "2020-01-01", extractions: {id: 1234, time: "12:01:01"}}, {...}]
      // From there we take a list of ids to make an easy validation
      let ids = [];
      if (this.available_rate_extractions) {
        ids = this.available_rate_extractions.reduce(
          (ids, cur) => ids.concat(cur.extractions.map(imp => imp.id)),
          []
        );
      }
      return ids;
    },
    nextHotelEvents() {
      return Array.isArray(this.events)
        ? this.events
            .filter(
              e =>
                (e.impact === "high" ||
                  e.impact === "very_high" ||
                  e.impact === "exceptional") &&
                Vue.moment(e.start_date).isAfter(Vue.moment())
            )
            .slice(0, 5)
            .map(e => {
              return {
                type: e.type,
                impact: e.impact,
                text: e.name,
                value: [e.start_date, e.end_date]
              };
            })
        : [];
    },
    events() {
      return this.calendar_store.hotel_events;
    },
    next_available_periods() {
      let next_available_periods = [
        {
          text: "next_30_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(30, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_7_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(7, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_15_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(15, "days")
              .format("YYYY-MM-DD")
          ]
        },

        {
          text: "this_month",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_month",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "this_week",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("week")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("week")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_week",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(1, "week")
              .endOf("week")
              .format("YYYY-MM-DD")
          ]
        }
      ];
      let periods = next_available_periods.concat(this.nextHotelEvents);
      for (let period of periods) {
        period.text = this.$t(period.text);
        period.active =
          period.value[0] === this.edit_event_period[0] &&
          period.value[1] === this.edit_event_period[1];
      }
      return periods;
    },
    min_allowed_date() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    init_date() {
      return this.event_period[0];
    },
    end_date() {
      return this.event_period[1];
    },
    year() {
      return Vue.moment(this.init_date).format("YYYY");
    },
    formatedEventPeriod() {
      let a = Utils.getFriendlyPeriod(
        this.event_period[0],
        this.event_period[1]
      );
      return a;
    },
    formatedEditingEventPeriod() {
      let a = Utils.getFriendlyPeriod(
        this.edit_event_period[0],
        this.edit_event_period[1]
      );
      return a;
    },
    weeks_by_year() {
      let weeks_by_year = {};
      this.available_years.forEach(year => {
        weeks_by_year[year] = Utils.getYearlyCalendar(year);
      });
      return weeks_by_year;
    },
    all_weeks() {
      let weeks = [];
      for (let y in this.weeks_by_year) {
        for (let m in this.weeks_by_year[y]) {
          for (let w in this.weeks_by_year[y][m]) {
            let week = this.weeks_by_year[y][m][w];
            weeks.push(this.weeks_by_year[y][m][w]);
          }
        }
      }
      return weeks;
    },
    initial_week_index() {
      let index = this.all_weeks.findIndex(w => {
        if (w.find(d => d && d.date === this.init_date)) {
          return true;
        }
      });
      return index;
    },
    end_week_index() {
      let index = this.all_weeks.findIndex(w => {
        if (w.find(d => d && d.date === this.end_date)) {
          return true;
        }
      });
      return index;
    },
    filtered_weeks() {
      return this.all_weeks.slice(
        this.initial_week_index,
        this.end_week_index + 1
      );
    },
    current_visible_week() {
      return this.all_weeks[this.current_visible_week_index];
    },
    visible_days() {
      let days = [];
      let rows =
        parseInt(this.table_height / this.item_height) +
        this.initial_week_index +
        1;
      for (let r = 0; r < rows; r++) {
        days = days.concat(
          this.filtered_weeks[this.current_visible_week_index + r]
            ? this.filtered_weeks[this.current_visible_week_index + r].map(
                d => d && d.date
              )
            : null
        );
      }
      return days;
    },
    first_visible_day() {
      return this.visible_days.find(d => d);
    },
    last_visible_day() {
      return this.visible_days.toReversed().find(d => d);
    },
    total_days() {
      return Utils.getDuration(this.event_period[0], this.event_period[1]);
    },
    show_bulk_edit() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.channelManagerTableData &&
        this.$store.state.channelmanager.channelManagerTableData.columns
      );
    },
    ...mapState({
      rms: state => state.rms,
      hotelsdot: state => state.hotelsdot,
      calendar_store: state => state.calendar,
      available_rate_extractions: state => state.rate.available_rate_extractions
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days",
      getDayEvents: "getDayEvents",
      available_years: "getHotelAvailableYears"
    })
  },
  mounted() {
    this.setDefault();
    this.getRates();
  }
};
</script>
<style lang="scss">
.v-list--dense .v-list-item {
  min-height: 33px;
}
.v-date-picker-title__date {
  font-size: 16px;
}

.cols7 {
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  flex-basis: calc(100% / 7);
}

$height: 19px;
.a-rms-labels {
  font-size: 11px;
  line-height: height;
  text-align: right;

  .a-rms-day-row-day {
    overflow: hidden;
    height: $height;
  }
  .a-rms-day-row-header {
    overflow: hidden;
    font-weight: 600;
    color: #cacaca;
    height: $height;
  }
  .a-rms-day-row {
    overflow: hidden;
    height: $height;
  }
  .a-rms-day-row-resume {
    height: $height;
    font-weight: 600;
  }
}
.day_details_mini {
  position: fixed;
  width: 90%;
  bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 20;
}
</style>
