<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-select
        v-if="!is_all_hotels"
        v-model="calendar_year"
        :items="available_years.reverse()"
        mandatory
        rounded
        outlined
        dense
        class="mt-8"
        style="max-width: 120px;"
      ></v-select>
      <v-select
        v-if="!is_all_hotels"
        multiple
        chips
        v-model="visible_calendars"
        :items="getHotelCalendars"
        :item-text="item => item.name"
        :item-value="item => item.id"
        mandatory
        rounded
        outlined
        dense
        class="ml-2 mt-8"
        style="max-width: 300px;"
      >
        <template v-slot:selection="{ item, index }">
          <span class="mr-1" v-if="index < 1">{{ getCalendarName(item) }}</span>
          <span
            v-if="index === 1"
            class="text-grey text-caption align-self-center"
          >
            (+{{ visible_calendars.length - 1 }} otros)
          </span>
        </template>
      </v-select>
    </template>
    <div
      class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
      style="position:relative"
      v-if="is_all_hotels"
    >
      <div class="px-10 mx-5">
        <h3 class="text-h3 d-block">
          {{ $t("which_hotel_you_want_setup") }}
        </h3>
        <v-btn
          class="mt-10"
          color="secondary"
          rounded
          @click="open_hotel_selector"
          >{{ $t("select_hotel") }}</v-btn
        >
      </div>
    </div>
    <div v-else>
      <!-- KPIS -->
      <div
        class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
        style="position:relative"
        v-if="ready"
      >
        <div class="px-10 mx-5">
          <span class="text-h1 d-block bold">
            {{ opened_days }}
          </span>
          <span class="text-h5 d-block subtitle">{{ $t("open_days") }}</span>
        </div>
        <div class="px-10 mx-5">
          <span class="text-h1 d-block">{{ total_hotel_events }}</span>
          <span class="text-h5 d-block">{{ $t("events") }}</span>
        </div>
        <div class="px-10 mx-5">
          <span class="text-h1 d-block">{{ getHotelCalendars.length }}</span>
          <span class="text-h5 d-block">{{ $t("calendars") }}</span>
        </div>
        <div class="px-10 mx-5">
          <span class="text-h1 d-block">{{ total_hotel_day_comments }}</span>
          <span class="text-h5 d-block">{{ $t("days_with_reviews") }}</span>
        </div>
      </div>
      <!-- end PAGE HEADER -->
      <v-tabs
        class="px-1"
        background-color="transparent"
        slider-size="2"
        v-model="tab"
        dark
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          {{ $t("opening_calendar") }}
        </v-tab>
        <v-tab>
          {{ $t("comparative_dates") }}
        </v-tab>
        <v-tab>
          {{ $t("events") }}
        </v-tab>
        <v-tab>
          {{ $t("reviews") }}
        </v-tab>
        <v-tab>
          {{ $t("subscriptions") }}
        </v-tab>
      </v-tabs>
      <v-card>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text>
              <calendar-openings
                v-if="ready_comparatives_dates"
              ></calendar-openings>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <comparative-dates></comparative-dates>
          </v-tab-item>
          <v-tab-item>
            <v-toolbar color="white" light flat>
              <v-toolbar-title>{{ $t("hotel_events") }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <calendar-events
                :events="events"
                :available_calendars="available_calendars"
                @getCalendars="getCalendars"
              ></calendar-events>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-toolbar color="white" light flat>
              <v-toolbar-title>{{ $t("hotel_comments") }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <calendar-comments></calendar-comments>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-toolbar color="white" light flat>
              <v-toolbar-title>{{
                $t("hotel_calendar_subscriptions")
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <hotel-subscribed-calendars
                @getCalendars="getCalendars"
              ></hotel-subscribed-calendars>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aPage from "@/components/aPage";
/* import EventProgress from "@/components/calendar/EventProgress.vue"; */
import CalendarComments from "@/components/calendar/CalendarComments.vue";
import CalendarEvents from "@/components/calendar/CalendarEvents.vue";
import HotelSubscribedCalendars from "@/components/calendar/HotelSubscribedCalendars.vue";
import ComparativeDates from "@/components/calendar/ComparativeDates.vue";
import CalendarOpenings from "@/components/calendar/CalendarOpenings.vue";

export default {
  name: "Calendar",
  components: {
    aPage,
    /* EventProgress, */
    CalendarComments,
    CalendarEvents,
    HotelSubscribedCalendars,
    ComparativeDates,
    CalendarOpenings
  },
  data: () => ({
    app_id: "calendar-app",
    image_source: "/img/header-2.jpg", // Mandatory path
    image_height: 380, // Mandatory number
    tab: 0
  }),
  computed: {
    available_calendars() {
      let calendars = JSON.parse(JSON.stringify(this.getHotelCalendars));
      calendars.push({ id: "new", name: this.$t("new_calendar") });
      return calendars;
    },
    weekday_name() {
      return this.weekday_to_set >= 0
        ? this.getWeekDayName(this.weekday_to_set)
        : "";
    },
    ready() {
      return this.calendar_year && this.current_hotel && this.current_hotel.id;
    },
    ready_comparatives_dates() {
      return (
        this.ready &&
        Object.keys(this.calendar_store.hotel_comparative_dates).length > 0
      );
    },
    calendar_year: {
      get() {
        return this.calendar_store.calendar_year;
      },
      set(value) {
        this.$store.dispatch("setCalendarYear", value);
      }
    },
    visible_calendars: {
      get() {
        return this.calendar_store.visible_calendars;
      },
      set(value) {
        this.$store.dispatch("setVisibleCalendars", value);
      }
    },
    total_hotel_events() {
      return this.events.length;
    },
    total_hotel_day_comments() {
      return this.calendar_store.hotel_comments
        ? this.calendar_store.hotel_comments
            .map(c => c.date)
            .filter((c, index, self) => {
              return self.indexOf(c) === index;
            }).length
        : 0;
    },
    events() {
      // Añadir la duracion, el mes de inicio y el intervalo de días
      return this.calendar_store.hotel_events.filter(e =>
        this.calendar_store.visible_calendars.includes(e.calendar_id)
      );
    },
    is_all_hotels: function() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      available_years: "getHotelAvailableYears",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days",
      getHotelCalendars: "getHotelCalendars"
    })
  },
  methods: {
    open_hotel_selector: function() {
      this.$store.dispatch("setShowHotelList", true);
    },
    getCalendarName(item) {
      let char = this.visible_calendars.length > 1 ? 20 : 30;
      return item.name.length > char
        ? item.name.substring(0, char) + "..."
        : item.name;
    },
    /* hasComments(date) {
      return this.calendar_store && this.calendar_store.hotel_comments
        ? this.calendar_store.hotel_comments.filter(c => c.date === date).length
        : 0;
    }, */
    /* getDay(date) {
      return Vue.moment(date).format("D");
    }, */
    getDayName(date) {
      return Vue.moment(date).format("dddd,D MMMM");
    },
    getCalendars() {
      this.$store
        .dispatch("getCalendars", {
          // hotel_id: this.current_hotel.id
        })
        .then(() => {
          this.visible_calendars = this.getHotelCalendars.map(c => c.id);
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelEvents() {
      this.$store
        .dispatch("getHotelEvents", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelComments() {
      this.$store
        .dispatch("getHotelComments", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    /* setOpeningDate(date) {
      this.$store
        .dispatch("setOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          opening_date: date,
          closing_date: this.calendar_store.closing_date
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setClosingDate(date) {
      this.$store
        .dispatch("setOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          opening_date: this.calendar_store.opening_date,
          closing_date: date
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }, */
    getOpeningPeriod() {
      this.$store
        .dispatch("getOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelComparativeDates() {
      this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year,
          from_date: Vue.moment(this.calendar_year)
            .startOf("year")
            .format("YYYY-MM-DD"),
          to_date: Vue.moment(this.calendar_year)
            .endOf("year")
            .format("YYYY-MM-DD")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelYearData() {
      this.getOpeningPeriod();
      this.getHotelEvents();
      this.getHotelComments();
      this.getCalendars();
      this.getHotelComparativeDates();
    }
  },
  watch: {
    ready(v) {
      if (v) {
        this.getHotelYearData();
      }
    },
    calendar_year() {
      if (this.ready) {
        this.getHotelYearData();
      }
    }
  },
  mounted() {
    this.calendar_year = Vue.moment()
      .year()
      .toString();
  }
};
</script>

<style scoped>
/* .calendar_year {
  text-align: center;
}
.calendar_month {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px;
  min-height: 350px;
}
.calendar_month_name {
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto;
  color: #444;
  text-transform: lowercase;
}
.calendar_week_number {
  width: 30px;
  display: flex;
  align-items: center;
  background: #e5e5e5;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: default;
}
.calendar_week_number .calendar_day_content_number {
  color: #ffffff;
  line-height: 33px;
  cursor: default;
}
.calendar_week_header {
  display: flex;
  margin: 20px 0 10px 0;
}
.calendar_weekday_header:first-of-type {
  width: 30px;
  flex: none;
}
.calendar_weekday_header {
  flex: 1;
  color: #cacaca;
  font-weight: 500;
}
.calendar_week {
  margin-bottom: 10px;
  display: flex;
}
.calendar_day {
  flex: 1;
}
.calendar_day_invisible {
}
.calendar_day_content {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #4bd;
  color: white;
  margin: 0 auto;
  line-height: 30px;
  cursor: pointer;
  background: #4bd;
  font-size: 13px;
  font-weight: 500;
}
.calendar_day_content.blocked {
  cursor: not-allowed;
}
.calendar_day_content_number {
  width: 30px;
  height: 30px;
  border: none;
  color: #cacaca;
  margin: 0 auto;
  line-height: 25px;
  cursor: default;
  background: none;
}
.calendar_day_content:hover {
  border: 1px solid #4bd !important;
  background: #f5fdff;
  color: #4bd;
}
.calendar_day_content.closed {
  border: 1px solid #dedede;
  background: none;
  color: #333333;
} */
</style>
