<template>
  <a-test-comp-1 ref="test-comp-1"></a-test-comp-1>
</template>

<script>
import Vue from "vue";
import aTestComp1 from "@/components/examples/aTestComp1";
import * as Utils from "@/utils.js";

export default {
  name: "a-test-app",
  props: {},
  methods: {},
  components: {
    aTestComp1
  },
  data: () => ({}),
  mounted() {},
  computed: {}
};
</script>
