<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <div
        class="d-flex justify-space-between align-center mb-3 mt-4"
        style="width:100%"
      >
        <a-rms-vs-selector-single
          ref="a-rms-vs-selector-single"
          @updateVs="updateVs"
          large
          hide_pickup
        ></a-rms-vs-selector-single>
        <div class="mr-0 d-flex">
          <div
            class="rounded-pill white d-flex align-center justify-space-between py-1 px-1"
            style="height: 100%; position: relative;"
          >
            <a-label class="px-4">{{ $t("comparative") }}:</a-label>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  fab
                  :color="kpi_mode === 'relative' ? 'secondary' : 'white'"
                  @click="kpi_mode = 'relative'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="kpi_mode === 'relative' ? 'white' : 'black'"
                  >
                    mdi-percent-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("percentage") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  fab
                  :color="kpi_mode === 'absolute' ? 'secondary' : 'white'"
                  @click="kpi_mode = 'absolute'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="kpi_mode === 'absolute' ? 'white' : 'black'"
                  >
                    mdi-counter
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("absolute") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  rounded
                  fab
                  :color="kpi_mode === 'hide_vs' ? 'secondary' : 'white'"
                  @click="kpi_mode = 'hide_vs'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="kpi_mode === 'hide_vs' ? 'white' : 'black'"
                  >
                    mdi-circle-off-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("hide_vs") }}</span>
            </v-tooltip>
          </div>
          <div
            class="rounded-pill white d-flex align-center justify-space-between py-1 px-1"
            style="height: 100%; position: relative;"
          >
            <a-label class="px-4">{{ $t("view") }}:</a-label>
            <template>
              <v-tooltip
                bottom
                v-for="(group, index) in strategy_groups_icons"
                :key="'group_' + index"
              >
                <template v-slot:activator="{ on, attrs }" v-if="group">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    rounded
                    fab
                    :text="false"
                    :color="group.isActive ? 'secondary' : 'transparent'"
                    @click="group.isActive = !group.isActive"
                    class="ml-2"
                  >
                    <v-icon small :color="group.isActive ? 'white' : 'black'">{{
                      group.icon
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  <template
                    v-if="
                      group.group.includes('pickup_') &&
                        group.group !== 'pickup_0'
                    "
                  >
                    {{ current_hotel.settings.pickup_spots[group.group] }}
                  </template>
                  <template v-else>
                    {{ $t(group.group) }}
                  </template>
                </span>
              </v-tooltip>
            </template>
          </div>
        </div>
      </div>
      <a-rms-strategy-day-table
        :configStrategyTable="configStrategyTable"
        :columns="strategyValues"
        :totalPeriod="totalPeriodValues"
        :days="days"
        :roomTypes="roomtype_types_mapped"
        :segments="segments"
        @getDayDetails="getDayDetails"
        :kpiMode="kpi_mode"
      >
      </a-rms-strategy-day-table>
      <div
        class="day_details_mini secondary darken-2"
        v-if="detail_days.length > 0 && show_details_mini"
      >
        <v-card class="pa-0">
          <v-card-title class="pa-0">
            <v-btn
              icon
              color="grey"
              small
              @click="closeAllDaysDetails"
              class="mt-0"
              style="position: absolute; top: 10px; right: 15px;"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              color="grey"
              small
              @click="
                show_details_mini = false;
                show_details = true;
              "
              class="mt-0"
              style="position: absolute; top: 10px; right: 45px;"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <div class="secondary darken-3 pl-3" style="width:100%">
              <v-tabs
                background-color="transparent"
                center-active
                dark
                class="d-flex justify-start"
              >
                <v-tab
                  @click="
                    show_details_mini = false;
                    show_details = true;
                  "
                  v-for="(date, index) in detail_days"
                  :key="'tab_' + index"
                  class="pl-3 pr-10 text-capitalize"
                  >{{ getFriendlyDay(date) }}
                  <v-btn
                    icon
                    x-small
                    @click.stop="dropDayDetails(date)"
                    style="position:absolute; top: 15px; right: 10px;"
                    class="mt-0"
                  >
                    <v-icon x-small color="grey " style="margin-top: 2px;"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-tab>
              </v-tabs>
            </div>
          </v-card-title>
        </v-card>
      </div>
      <v-dialog
        v-model="show_details"
        style="margin-left:50px;"
        max-width="90%"
        class="pa-0"
        scrollable
        fullscreen
      >
        <v-card class="pa-0">
          <v-card-title class="pa-0">
            <v-btn
              icon
              color="grey"
              small
              @click="closeAllDaysDetails"
              class="mt-0"
              style="position: absolute; top: 10px; right: 15px;"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              color="grey"
              small
              @click="
                show_details_mini = true;
                show_details = false;
              "
              class="mt-0"
              style="position: absolute; top: 10px; right: 45px;"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <div class="secondary darken-3 pl-3" style="width:100%">
              <v-tabs
                background-color="transparent"
                center-active
                dark
                class="d-flex justify-start"
                @click="show_details_mini = false"
                v-model="tab"
              >
                <v-tab
                  @click="show_details_mini = false"
                  v-for="(date, index) in detail_days"
                  :key="'tab_' + index"
                  class="pl-3 pr-10 text-capitalize"
                  >{{ getFriendlyDay(date) }}
                  <v-btn
                    icon
                    x-small
                    @click.stop="dropDayDetails(date)"
                    style="position:absolute; top: 15px; right: 10px;"
                    class="mt-0"
                  >
                    <v-icon x-small color="grey " style="margin-top: 2px;"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-tab>
              </v-tabs>
            </div>
          </v-card-title>
          <v-card-text class="px-0" style="overflow-x:hidden;">
            <v-row>
              <v-col cols="12" sm="3"></v-col>
              <v-col cols="12" sm="6">
                <v-list nav class="mb-0 pb-0">
                  <v-list-item-group
                    v-model="selectedTab"
                    color="secondary"
                    class="d-flex"
                  >
                    <v-list-item
                      class="mx-2 text-center mb-0 "
                      v-for="(item, i) in items"
                      :key="i"
                    >
                      <v-list-item-content class="ml-0 pr-3">
                        <v-icon class="mb-2">{{ item.icon }}</v-icon>
                        <v-list-item-title
                          class="text-uppercase"
                          v-text="$t(item.text)"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col cols="12" sm="3"></v-col>
              <v-col cols="12" class="pt-0">
                <div class="px-3">
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                      <v-row v-if="selectedTab === 0">
                        <v-col cols="12" sm="12">
                          <a-rms-day-room-types
                            :date="detail_days[tab]"
                          ></a-rms-day-room-types>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0">
                          <a-rms-day-general
                            :date="detail_days[tab]"
                            :dense="true"
                          ></a-rms-day-general>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0">
                          <v-row>
                            <v-col cols="12">
                              <a-rms-day-general-events
                                :date="detail_days[tab]"
                                ref="a-rms-day-general-events"
                              >
                              </a-rms-day-general-events>
                            </v-col>
                            <v-col cols="12">
                              <a-rms-day-detail
                                :date="detail_days[tab]"
                                ref="a-rms-day-detail"
                              ></a-rms-day-detail>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <a-rms-day-availability
                        :ref="'a-rms-day-availability_' + tab"
                        v-if="selectedTab === 1"
                        :date="detail_days[tab]"
                        :max_days="7"
                      ></a-rms-day-availability>
                      <a-rms-day-price-evolution
                        v-if="selectedTab === 2"
                        :date="detail_days[tab]"
                      ></a-rms-day-price-evolution>
                      <a-rms-day-comments
                        v-if="selectedTab === 3"
                        :date="detail_days[tab]"
                      ></a-rms-day-comments>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:loading>
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            v-for="i in 12"
            :key="i"
            type="card"
            height="70"
            class="elevation-0 mb-2"
          >
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
// import aRmsDay from "@/components/rms/aRmsDay";
// import aRmsDayEmpty from "@/components/rms/aRmsDayEmpty";
import aRmsDayAvailability from "@/components/rms/aRmsDayAvailability";
// import aCardKpi from "@/components/rms_ui/aCardKpi";
//import aCardKpiIcon from "@/components/rms_ui/aCardKpiIcon";
import aRmsDayGeneral from "@/components/rms/aRmsDayGeneral.vue";
import aRmsDayRoomTypes from "@/components/rms/aRmsDayRoomTypes.vue";
import aRmsDayComments from "@/components/rms/aRmsDayComments.vue";
import aRmsDayDetail from "@/components/rms/aRmsDayDetail.vue";
import aRmsDayPriceEvolution from "@/components/rms/aRmsDayPriceEvolution.vue";
import aRmsDayGeneralEvents from "@/components/rms/aRmsDayGeneralEvents.vue";
import aRmsStrategyDayTable from "@/components/rms/axel/aRmsStrategyDayTable.vue";
import aRmsVsSelectorSingle from "@/components/rms/aRmsVsSelectorSingle";
import aLabel from "@/components/aLabel.vue";

import * as Utils from "@/utils.js";
import login from "@/views/Login.vue";

const config_strategy_table = {
  "": {
    columns: {
      date: {}
    },
    icon: null
  },
  cityMKD: {
    columns: {
      "MKD-14": { kpi: "city_demand", block: "pickup_4" },
      "MKD-7": { kpi: "city_demand", block: "pickup_3" },
      "MKD-3": { kpi: "city_demand", block: "pickup_2" },
      "MKD-Final": { kpi: "city_demand", block: "otb" }
    },
    icon: "mdi-home-city-outline"
  },
  rates: {
    columns: {
      current_rate: { kpi: "pvp", block: "otb" },
      mlos: { kpi: "mlos", block: "otb" },
      compset_min_price: { kpi: "compset_min_price", block: "otb" },
      compset_avg_min_price: { kpi: "compset_avg_min_price", block: "otb" }
    },
    icon: "mdi-tag-multiple-outline"
  },
  OTB: {
    columns: {
      occupancy: { kpi: "occupancy", block: "otb" },
      occupied: { kpi: "rn", block: "otb" },
      adr: { kpi: "adr", block: "otb" }
    },
    icon: "mdi-alarm-check"
  },
  roomtype: {
    columns: {
      Single: {
        kpi: "rn",
        block: "otb"
      }
    },
    icon: "mdi-sofa-outline"
  },
  segment: {
    columns: {
      Web: {
        kpi: "rn",
        block: "otb"
      },
      Direct: {
        kpi: "rn",
        block: "otb"
      },
      Affiliate: {
        kpi: "rn",
        block: "otb"
      }
    },
    icon: "mdi-hub-outline"
  },
  inventory: {
    columns: {
      out_of_order: { kpi: "locked", block: "otb" },
      tentative: { kpi: "tentative", block: "otb" },
      available: { kpi: "free", block: "otb", zero: true },
      available_pace: { kpi: "available_pace", block: "otb", zero: true }
    },
    icon: "mdi-ballot-outline"
  },
  revenue: {
    columns: {
      room_revenue: { kpi: "room_revenue", block: "otb" },
      room_revenue_other: { kpi: "othroom_revenue", block: "otb" },
      room_revenue_total: { kpi: "accommodation_revenue", block: "otb" }
    },
    icon: "mdi-cash-multiple"
  },
  pickup_0: {
    columns: {
      sold_rooms: { kpi: "rn", block: "pickup_0" },
      avg_price: { kpi: "adr", block: "pickup_0" },
      room_revenue: { kpi: "room_revenue", block: "pickup_0" }
    },
    icon: "mdi-numeric-0-circle-outline"
  },
  pickup_1: {
    columns: {
      sold_rooms: { kpi: "rn", block: "pickup_1" },
      avg_price: { kpi: "adr", block: "pickup_1" },
      room_revenue: { kpi: "room_revenue", block: "pickup_1" }
    },
    icon: "mdi-numeric-1-circle-outline"
  },
  pickup_2: {
    columns: {
      sold_rooms: { kpi: "rn", block: "pickup_2" },
      avg_price: { kpi: "adr", block: "pickup_2" },
      room_revenue: { kpi: "room_revenue", block: "pickup_2" }
    },
    icon: "mdi-numeric-3-circle-outline"
  },
  pickup_3: {
    columns: {
      sold_rooms: { kpi: "rn", block: "pickup_3" },
      avg_price: { kpi: "adr", block: "pickup_3" },
      room_revenue: { kpi: "room_revenue", block: "pickup_3" }
    },
    icon: "mdi-numeric-7-circle-outline"
  },
  pickup_4: {
    columns: {
      sold_rooms: { kpi: "rn", block: "pickup_4" },
      avg_price: { kpi: "adr", block: "pickup_4" },
      room_revenue: { kpi: "room_revenue", block: "pickup_4" }
    },
    icon: "mdi-dots-vertical-circle-outline"
  }
};

export default {
  name: "a-rms-strategy-weeks",
  components: {
    aBaseComponent,
    // aRmsDay,
    // aRmsDayEmpty,
    aRmsDayAvailability,
    aLabel,
    // aCardKpi,
    //aCardKpiIcon,
    aRmsDayGeneral,
    aRmsDayRoomTypes,
    aRmsDayComments,
    aRmsDayPriceEvolution,
    aRmsDayDetail,
    aRmsDayGeneralEvents,
    aRmsStrategyDayTable,
    aRmsVsSelectorSingle
  },
  props: {
    event_period: {
      type: Array,
      default: () => []
    }
  },
  data: vm => ({
    title: "",
    subscribed_modules: [
      "rate_available_rate_extractions",
      "rms_all_kpis_daily_dimension",
      "rms_all_kpis_period_dimension",
      "segmentschannels_segments",
      "segmentschannels_segment_channels",
      "roomtype_types_mapped"
    ],
    subscribed_modules_footer: ["rms_roomtype_availability"],
    // init_date: "",
    current_visible_week_index: 0,
    item_height: 300,
    table_height: "calc(100% - 200px)",
    edit_event_period: [],
    show_range_selector: false,
    picker_date: null,
    picker_date2: null,
    event_selected: null,
    detail_days: [],
    show_details: false,
    show_details_mini: false,
    row: null,
    week: null,
    selectedTab: 0,
    items: [
      { text: "general", icon: "mdi-information-outline" },
      // { text: "room_types", icon: "mdi-bed-king-outline" },
      { text: "availability", icon: "mdi-select-compare" },
      // { text: "competence", icon: "mdi-compare" },
      { text: "price_evolution", icon: "mdi-chart-timeline-variant" },
      { text: "comments", icon: "mdi-comment-multiple-outline" }
    ],
    tab: 0,
    kpi_mode: "relative",
    // toggle_mode: 1,
    // period_mode: 0,
    strategy_groups_icons: Object.keys(config_strategy_table)
      .map(group => {
        return {
          group: group,
          icon: config_strategy_table[group].icon,
          isActive: true
        };
      })
      .filter(g => g.group !== "")
  }),
  watch: {
    picker_date(newVal, oldVal) {
      if (newVal !== oldVal) {
        let d = Vue.moment(newVal + "-01").add(1, "month");
        this.picker_date2 = d.format("YYYY-MM");
      }
    },
    picker_date2(newVal, oldVal) {
      if (newVal !== oldVal) {
        let d = Vue.moment(newVal + "-01").subtract(1, "month");
        this.picker_date = d.format("YYYY-MM");
      }
    },
    event_selected(newVal) {
      if (newVal) {
        let event = this.events.find(e => e.id === newVal);
        this.setPeriod([event.start_date, event.end_date]);
      }
    },
    detail_days: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          let date = newVal[this.tab];
          if (!this.show_details_mini) this.show_details = true;
          this.getRates();
          this.$store.dispatch("setRateDateToCompare", date);
          this.setAvailabilityRange(date);
        } else {
          // this.show_details_mini = false;
          this.show_details = false;
        }
      }
    },
    tab(newVal, oldVal) {
      if (newVal >= 0) {
        let date = this.detail_days[newVal];
        this.getRates();
        this.$store.dispatch("setRateDateToCompare", date);
        this.setAvailabilityRange(date);
      }
    },
    year() {
      this.getHotelEvents();
    }
  },
  methods: {
    updateVs(vs) {
      this.$store.dispatch("setRmsStrategyVsSpot", vs);
    },
    setAvailabilityRange(date) {
      // Reload availability dates
      let init_date_moment = Vue.moment(date).subtract(3, "days");
      this.availability_from = init_date_moment.format("YYYY-MM-DD");
      this.availability_to = init_date_moment
        .add(6, "days")
        .format("YYYY-MM-DD");
      this.$store.dispatch("fetchRmsRoomtypeAvailability");
    },
    getRates() {
      this.$store.dispatch("setRateFilters", {
        extraction_id: this.available_rate_import_ids[0],
        vs_id: this.available_rate_import_ids[1],
        pax: 2,
        childs: 0,
        regime: [],
        cancel_policy: [],
        genius: false,
        mobile_device: true
      });
    },
    closeAllDaysDetails() {
      this.detail_days = [];
    },
    getDayDetails(date) {
      if (!this.detail_days.includes(date)) {
        this.detail_days.push(date);
      }
      this.tab = this.detail_days.indexOf(date);
    },
    dropDayDetails(date) {
      if (this.detail_days.includes(date)) {
        let index = this.detail_days.indexOf(date);
        if (index !== -1) {
          this.detail_days.splice(index, 1);
        }
      }
    },
    getHotelEvents() {
      if (this.current_hotel) {
        this.$store
          .dispatch("getHotelEvents", {
            hotel_id: this.current_hotel.id,
            year: this.year
          })
          .catch(() => {
            Utils.showError(this, "something_went_wrong");
          });
      }
    },
    setPeriod(v) {
      this.edit_event_period = JSON.parse(JSON.stringify(v));
      this.picker_date = Vue.moment(v[0]).format("YYYY-MM");
    },
    getFriendlyDay(date) {
      return date ? Utils.getFriendlyDay(date) : "";
    },
    setDefault() {
      // Scroll event control
      setTimeout(() => {
        this.getHotelEvents();

        const scroller = document.getElementById("weeks_table");
        if (scroller) {
          scroller.addEventListener("scroll", e => {
            this.current_visible_week_index = parseInt(
              e.target.scrollTop / this.item_height
            );
          });
        }
      }, 1000);
    },
    ...mapActions([])
  },
  computed: {
    days() {
      let days = [];
      // Build an array of days starting on initial event_period and ending in end date period
      let init_date = this.event_period[0];
      let end_date = this.event_period[1];
      let current_date = init_date;
      while (current_date <= end_date) {
        days.push(current_date);
        current_date = Vue.moment(current_date)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
      return days;
    },
    strategyValues() {
      // return strategy_data;
      return this.rms.all_kpis_daily_dimension;
    },
    totalPeriodValues() {
      return this.rms.all_kpis_period_dimension;
    },
    minSoldRoomsPickup4() {
      return Object.keys(this.rms.all_kpis_daily_dimension).reduce(
        (acc, cur) => {
          return acc
            ? Math.min(acc, this.rms.all_kpis_daily_dimension[cur].pickup_4.rn)
            : this.rms.all_kpis_daily_dimension[cur].pickup_4.rn;
        },
        null
      );
    },
    maxSoldRoomsPickup4() {
      return Object.keys(this.rms.all_kpis_daily_dimension).reduce(
        (acc, cur) => {
          return acc
            ? Math.max(acc, this.rms.all_kpis_daily_dimension[cur].pickup_4.rn)
            : this.rms.all_kpis_daily_dimension[cur].pickup_4.rn;
        },
        null
      );
    },
    configStrategyTable() {
      return {
        "": config_strategy_table[""],
        ...this.strategy_groups_icons.reduce((acc, cur) => {
          if (cur.isActive) {
            acc[cur.group] = config_strategy_table[cur.group];
            if (cur.group === "roomtype") {
              acc[cur.group].columns = this.roomtype_types_mapped.reduce(
                (accRoomType, curRoomType) => {
                  accRoomType[curRoomType.code] = {
                    kpi: "rn",
                    block: "otb",
                    limit: curRoomType.available_rooms
                  };
                  return accRoomType;
                },
                {}
              );
            }
            if (cur.group === "segment") {
              acc[cur.group].columns = this.segments.reduce(
                (accSegment, curSegment) => {
                  accSegment[curSegment.code] = {
                    kpi: "rn",
                    block: "otb"
                  };
                  return accSegment;
                },
                {}
              );
            }
            if (cur.group.startsWith("pickup_")) {
              for (let col of Object.keys(acc[cur.group].columns)) {
                acc[cur.group].columns[col].limit = 0;
                if (cur.group === "pickup_4" && col === "sold_rooms") {
                  acc[cur.group].columns[col].min = this.minSoldRoomsPickup4;
                  acc[cur.group].columns[col].max = this.maxSoldRoomsPickup4;
                }
              }
            }
            if (cur.group === "inventory") {
              const limit = Math.round(
                this.roomtype_types_mapped.reduce(
                  (accRoomType, curRoomType) => {
                    return accRoomType
                      ? accRoomType + curRoomType.available_rooms
                      : curRoomType.available_rooms;
                  },
                  0
                ) * 0.2
              );
              acc[cur.group].columns["available"].limit = limit;
              acc[cur.group].columns["available_pace"].limit = limit;
            }
          }
          return acc;
        }, {})
      };
    },
    availability_from: {
      get() {
        return this.$store.state.rms.availability_from;
      },
      set(value) {
        this.$store.dispatch("setAvailabilityFrom", value);
      }
    },
    availability_to: {
      get() {
        return this.$store.state.rms.availability_to;
      },
      set(value) {
        this.$store.dispatch("setAvailabilityTo", value);
      }
    },
    available_rate_import_ids() {
      // In this.available_rate_extractions we have a list of [{date: "2020-01-01", extractions: {id: 1234, time: "12:01:01"}}, {...}]
      // From there we take a list of ids to make an easy validation
      let ids = [];
      if (this.available_rate_extractions) {
        ids = this.available_rate_extractions.reduce(
          (ids, cur) => ids.concat(cur.extractions.map(imp => imp.id)),
          []
        );
      }
      return ids;
    },
    nextHotelEvents() {
      return Array.isArray(this.events)
        ? this.events
            .filter(
              e =>
                (e.impact === "high" ||
                  e.impact === "very_high" ||
                  e.impact === "exceptional") &&
                Vue.moment(e.start_date).isAfter(Vue.moment())
            )
            .slice(0, 5)
            .map(e => {
              return {
                type: e.type,
                impact: e.impact,
                text: e.name,
                value: [e.start_date, e.end_date]
              };
            })
        : [];
    },
    events() {
      return this.calendar_store.hotel_events;
    },
    next_available_periods() {
      let next_available_periods = [
        {
          text: "next_30_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(30, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_7_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(7, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_15_days",
          type: "period",
          impact: "high",
          value: [
            Vue.moment().format("YYYY-MM-DD"),
            Vue.moment()
              .add(15, "days")
              .format("YYYY-MM-DD")
          ]
        },

        {
          text: "this_month",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_month",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "this_week",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .startOf("week")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .endOf("week")
              .format("YYYY-MM-DD")
          ]
        },
        {
          text: "next_week",
          type: "period",
          impact: "high",
          value: [
            Vue.moment()
              .add(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD"),
            Vue.moment()
              .add(1, "week")
              .endOf("week")
              .format("YYYY-MM-DD")
          ]
        }
      ];
      let periods = next_available_periods.concat(this.nextHotelEvents);
      for (let period of periods) {
        period.text = this.$t(period.text);
        period.active =
          period.value[0] === this.edit_event_period[0] &&
          period.value[1] === this.edit_event_period[1];
      }
      return periods;
    },
    init_date() {
      return this.event_period[0];
    },
    end_date() {
      return this.event_period[1];
    },
    year() {
      return Vue.moment(this.init_date).format("YYYY");
    },
    weeks_by_year() {
      let weeks_by_year = {};
      let init_year = parseInt(Vue.moment().format("YYYY"));
      weeks_by_year[init_year] = Utils.getYearlyCalendar(init_year);
      weeks_by_year[init_year + 1] = Utils.getYearlyCalendar(init_year + 1);
      return weeks_by_year;
    },
    all_weeks() {
      let weeks = [];
      for (let y in this.weeks_by_year) {
        for (let m in this.weeks_by_year[y]) {
          for (let w in this.weeks_by_year[y][m]) {
            let week = this.weeks_by_year[y][m][w];
            weeks.push(this.weeks_by_year[y][m][w]);
          }
        }
      }
      return weeks;
    },
    current_visible_week() {
      return this.all_weeks[this.current_visible_week_index];
    },
    ...mapState({
      rms: state => state.rms,
      hotelsdot: state => state.hotelsdot,
      calendar_store: state => state.calendar,
      available_rate_extractions: state =>
        state.rate.available_rate_extractions,
      roomtype_types_mapped: state => state.roomtype.types_mapped,
      segments: state => state.segmentschannels.segments
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days",
      getDayEvents: "getDayEvents",
      available_years: "getHotelAvailableYears"
    })
  },
  mounted() {
    this.setDefault();
    this.getRates();
  }
};
</script>
<style lang="scss">
.v-list--dense .v-list-item {
  min-height: 33px;
}
.v-date-picker-title__date {
  font-size: 16px;
}

.cols7 {
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  flex-basis: calc(100% / 7);
}

$height: 19px;
.a-rms-day {
  font-size: 11px;
  line-height: height;
  text-align: right;
  &:focus::before {
    background: white;
  }

  .a-rms-day-row-day {
    height: $height;
    font-weight: 600;
    text-transform: uppercase;
    color: #444444;
    letter-spacing: -0.5px;
  }
  .a-rms-day-row-header {
    font-size: 11px;
    font-weight: 500;
    color: #666666;
    text-transform: uppercase;
    height: $height;
    overflow: hidden;
    letter-spacing: -0.5px;
  }
  .a-rms-day-row {
    height: $height;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .a-rms-day-row-resume {
    height: $height;
    font-weight: 600;
    // border-top: 1px solid #e5e5e5;
    overflow: hidden;
    // padding-top: 5px;
    // margin-top: 5px;
  }
}
.day_details_mini {
  position: fixed;
  width: 90%;
  bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 20;
}
</style>
