var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.available[_vm.permission])?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-avatar',{attrs:{"outlined":!_vm.item.permissions[_vm.permission],"color":_vm.item.permissions[_vm.permission]
                          ? 'secondary'
                          : 'grey lighten-3'}},[_c('v-icon',{attrs:{"dark":_vm.item.permissions[_vm.permission]}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]),_c('v-fade-transition',[(
                            hover &&
                              _vm.item.permissions[_vm.permission] &&
                              !_vm.mandatory
                          )?_c('v-overlay',{attrs:{"absolute":"","color":"primary","opacity":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"secondary","rounded":""},on:{"click":function($event){return _vm.toggle(_vm.permission)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1):_vm._e()],1)],1)]}}],null,true)}),_c('h2',{staticClass:"ml-4 text-h6 section-title",class:_vm.item.permissions[_vm.permission]
                      ? 'primary--text'
                      : 'black--text'},[_vm._v(" "+_vm._s(_vm.$t("permission_" + _vm.permission))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',{staticClass:"section_options"},_vm._l((_vm.section_visible_permissions),function(p){return _c('permissions-editor-switch',{key:p,attrs:{"label":'permission_' + p,"permission":p,"available":_vm.available,"item":_vm.item,"disabled":!_vm.item.permissions[_vm.permission]},on:{"changedPermission":_vm.changedPermission}})}),1)],1)],1)],1),_c('v-fade-transition',[(hover && !_vm.item.permissions[_vm.permission])?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',{attrs:{"color":"secondary","rounded":"","prepend-icon":"mdi-check"},on:{"click":function($event){return _vm.toggle(_vm.permission)}}},[_vm._v(_vm._s(_vm.$t("enable")))])],1):_vm._e()],1)],1)]}}],null,false,3795423688)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }