<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index">
        <template>
          <v-list-item-action>
            <v-checkbox
              :input-value="rms[`show_${item.code}`]"
              @change="updateVs(item.code)"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ label(item.code) }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "a-rms-vs-selector",
  data() {
    return {
      items: [
        { code: "bud" },
        { code: "stly" },
        { code: "ly" },
        { code: "pickup_1" },
        { code: "pickup_2" },
        { code: "pickup_3" },
        { code: "pickup_4" },
        { code: "pickup_5" },
        { code: "pickup_6" }
      ]
    };
  },
  methods: {
    updateVs(vs) {
      this.$store.dispatch("setShow", {
        vs: vs,
        value: !this.rms["show_" + vs]
      });
    },
    label(code) {
      // for pickup_*, build the text
      if (code.startsWith("pickup_")) {
        return (
          this.$t("pickup") +
          " " +
          (this.current_hotel
            ? this.current_hotel.settings.pickup_spots[code]
            : "")
        );
      }
      return this.$t(code);
    },

    ...mapActions(["initStrategyShowSpots"])
  },
  computed: {
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  watch: {
    current_hotel: {
      handler() {
        if (this.current_hotel) {
          this.initStrategyShowSpots();
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {}
};
</script>
<style></style>
