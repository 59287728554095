<template>
  <v-card color="grey lighten-4" outlined>
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      {{ $t("rates") }}
    </v-card-title>
    <v-card-text style="height: 90%;" class="white pa-3">
      <v-data-table
        v-if="all_kpis_day_compset"
        :headers="rateDayDetailHeaders"
        :items="comparisonItems"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.label="{ item }">
          <span
            class="caption font-weight-medium d-flex"
            :class="{ 'text-uppercase': item.self }"
            :key="'label_' + item.id"
          >
            <span :class="item.self ? 'font-weight-bold' : ''">{{
              item.label
            }}</span>
          </span>
        </template>
        <template v-slot:item.min_price="{ item }">
          <div
            class="d-flex justify-center align-center"
            :key="'min_price_' + item.id"
          >
            <template v-if="item.is_mobile_offer || item.is_genius_offer">
              <v-tooltip bottom content-class="a-rate-tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    v-if="item.is_mobile_offer"
                    color="yellow darken-2"
                    x-small
                    style="margin-bottom: 1px;"
                    class="mr-2"
                    >mdi-cellphone
                  </v-icon>
                </template>
                <span
                  class="text-center"
                  style="font-size:13px;line-height:11px;"
                  v-html="$t('is_mobile_offer')"
                ></span>
              </v-tooltip>
              <v-tooltip bottom content-class="a-rate-tooltip">
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    class="blue--text text--darken-4 mt-n1 mr-2 "
                    v-if="item.is_genius_offer"
                    style="font-size:13px;font-weight:600;"
                    >.g</span
                  >
                </template>
                <span
                  class="text-center"
                  style="font-size:13px;line-height:11px;"
                  v-html="$t('is_genius_offer')"
                ></span>
              </v-tooltip>
            </template>
            <span v-else class="mr-2" style="width: 10px"> </span>
            <a-kpi
              v-if="item.min_price"
              :class="item.self ? 'font-weight-bold' : ''"
              class="subtitle-2"
              :kpi="'min_price'"
              :value="item.min_price"
            >
            </a-kpi>
            <v-chip v-else outlined color="red" small dark> SOLD OUT</v-chip>
          </div>
        </template>
        <template v-slot:item.regime="{ item }">
          <v-tooltip
            bottom
            content-class="a-rate-tooltip"
            :key="'regime_' + item.id"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                color="grey darken-2"
                small
                v-on="on"
                v-if="item.regime === 'alo'"
              >
                mdi-bed-king-outline
              </v-icon>
              <v-icon
                color="grey darken-2"
                small
                v-on="on"
                v-if="item.regime === 'ad'"
              >
                mdi-coffee-outline
              </v-icon>
              <v-icon
                color="grey darken-2"
                small
                v-on="on"
                v-if="item.regime === 'mp'"
              >
                mdi-silverware-variant
              </v-icon>
              <v-icon
                color="grey darken-2"
                small
                v-on="on"
                v-if="item.regime === 'pc'"
              >
                mdi-silverware
              </v-icon>
            </template>
            <span>{{ $t(item.regime) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.room_type_name="{ item }">
          <span
            class="caption font-weight-medium"
            :class="item.self ? 'font-weight-bold' : ''"
            :key="'room_type_name_' + item.id"
            >{{ item.room_type_name }}</span
          >
        </template>
        <template v-slot:item.refund_policy="{ item }">
          <v-tooltip
            bottom
            content-class="a-rate-tooltip"
            :key="'refund_policy_' + item.id"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-if="item.free_cancellation"
                color="green"
                outlined
                small
                :class="item.self ? 'font-weight-bold' : ''"
                v-on="on"
              >
                <v-icon left small
                  >mdi-numeric-{{ item.free_cancellation_days }}-circle
                </v-icon>
                CG
              </v-chip>
              <v-chip
                v-if="item.no_refundable"
                color="red darken-3"
                outlined
                small
                :class="item.self ? 'font-weight-bold' : ''"
                v-on="on"
              >
                NR
              </v-chip>
              <v-chip
                v-if="
                  item.min_price &&
                    !item.no_refundable &&
                    !item.free_cancellation
                "
                color="yellow darken-3"
                outlined
                small
                :class="item.self ? 'font-weight-bold' : ''"
                v-on="on"
              >
                PR
              </v-chip>
            </template>
            <span v-if="item.free_cancellation">{{
              $t("free_cancellation")
            }}</span>
            <span v-if="item.no_refundable">{{ $t("no_refundable") }}</span>
            <span
              v-if="
                item.min_price && !item.no_refundable && !item.free_cancellation
              "
              >{{ $t("partial_refundable") }}</span
            >
          </v-tooltip>
        </template>
        <template v-slot:item.minimum_nights="{ item }">
          <span
            :class="item.self ? 'font-weight-bold' : ''"
            :key="'minimum_nights_' + item.id"
          >
            {{ item.minimum_nights }}
          </span>
        </template>
        <template v-slot:item.capacity="{ item }">
          <div :key="'capacity_' + item.id">
            <v-icon
              class="ml-n1"
              v-for="i in item.room_type_adults"
              :key="'type_adults_' + i + '_' + item.competitor_id"
              color="grey darken-2"
              small
              >mdi-account
            </v-icon>
            <span class="pa-1"></span>
            <v-icon
              class="ml-n1 mt-1"
              v-for="i in item.room_type_childs"
              :key="'type_childs_' + i + '_' + item.competitor_id"
              color="grey darken-2"
              x-small
              >mdi-account
            </v-icon>
          </div>
        </template>
        <template v-slot:[`item.min_price_1`]="{ item }">
          <span
            v-if="item.min_price"
            :class="pickupPriceColor(item.min_price, item.min_price_1)"
            class="text-no-wrap"
            :key="'min_price_1_' + item.id"
          >
            {{ item.min_price - item.min_price_1 }}
            <template v-if="item.currency === 'EUR'">
              <span class="currency-symbol">€</span>
            </template>
          </span>
        </template>
        <template v-slot:[`item.min_price_7`]="{ item }">
          <span
            v-if="item.min_price"
            :class="pickupPriceColor(item.min_price, item.min_price_7)"
            class="text-no-wrap"
            :key="'min_price_7_' + item.id"
          >
            {{ item.min_price - item.min_price_7 }}
            <template v-if="item.currency === 'EUR'">
              <span class="currency-symbol">€</span>
            </template>
          </span>
        </template>
        <template v-slot:[`item.min_price_14`]="{ item }">
          <span
            v-if="item.min_price"
            :class="pickupPriceColor(item.min_price, item.min_price_14)"
            class="text-no-wrap"
            :key="'min_price_14_' + item.id"
          >
            {{ item.min_price - item.min_price_14 }}
            <template v-if="item.currency === 'EUR'">
              <span class="currency-symbol">€</span>
            </template>
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi";
import * as Utils from "@/utils";

var numeral = require("numeral");

export default {
  name: "a-rms-day-compset",
  components: {
    aKpi
  },
  props: ["date"],
  data: vm => ({
    subscribed_modules: [],
    sortBy: "price",
    kpis: [
      "min_price",
      "minimum_nights",
      "regime",
      "refund_policy",
      "room_type_name",
      "capacity",
      "min_price_1",
      "min_price_7",
      "min_price_14"
    ],
    type_kpis: [
      "room_type_name",
      "capacity",
      "price",
      "minimum_nights",
      "regime",
      "refund_policy",
      "available_rooms"
    ],
    tab: 0,
    loading_rates: false
  }),
  watch: {},
  methods: {
    competitorData(competitor) {
      let item = {};
      if (competitor && this.all_kpis_day_compset) {
        if (
          this.all_kpis_day_compset &&
          this.all_kpis_day_compset.some(x => x.competitor_id === competitor.id)
        ) {
          item = {
            ...item,
            ...this.all_kpis_day_compset.find(
              x => x.competitor_id === competitor.id
            )
          };
        }
        item["id"] = competitor.id + "-" + this.date;
        item["label"] = competitor.name;
      }
      return item;
    },
    getDayRates(date) {
      this.$store
        .dispatch("fetchRmsAllKpisDayCompset", { date: date })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getRateCompetitors() {
      this.$store.dispatch("fetchRateCompetitors").catch(() => {
        Utils.showError(this, "something_went_wrong");
      });
    },
    pickupPriceColor(price, price1) {
      return price1 < price
        ? "green--text"
        : price1 > price
        ? "red--text"
        : "black--text";
    }
  },
  computed: {
    comparisonItems() {
      const items = [];
      // Insert hotel
      // items.push(this.competitorData(this.hotel_competitor));
      items.push({ ...this.competitorData(this.hotel_competitor) }); // Clonar objecte

      // Insert hotel competitors
      for (const competitor of this.competitors) {
        // items.push(this.competitorData(competitor));
        items.push({ ...this.competitorData(competitor) });
      }

      return items.sort((a, b) => {
        if (!b.min_price || a.min_price < b.min_price) return -1;
        if (!a.min_price || a.min_price > b.min_price) return 1;
        return 0;
      });
    },
    rateDayDetailHeaders() {
      var headers = [
        {
          text: "",
          value: "label",
          align: "start",
          sortable: false
        }
      ];
      for (const kpi of this.kpis) {
        headers.push({
          text: this.$t(kpi),
          value: kpi,
          align: "center",
          sortable: false
        });
      }

      return headers;
    },
    compared_competitors: {
      get() {
        return this.$store.state.rate.compared_competitors;
      },
      set(value) {
        this.$store.dispatch("setComparedCompetitors", value);
      }
    },
    all_kpis_day_compset() {
      return this.all_kpis_compset_by_date &&
        this.all_kpis_compset_by_date[this.date]
        ? this.all_kpis_compset_by_date[this.date]
        : null;
    },
    ...mapState({
      all_kpis_compset_by_date: state => state.rms.all_kpis_compset_by_date
    }),
    ...mapGetters({
      competitors: "getOnlyCompetitors",
      hotel_competitor: "getHotelFromCompetitors",
      l: "current_hotel_language"
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.getDayRates(this.date);
      this.getRateCompetitors();
      this.compared_competitors = [];
    });
  }
};
</script>
<style lang="scss" scoped>
.active-tab-bg {
  background-color: transparent;
  font-weight: bold;
  color: white;
}

.image-bg {
  background-image: url("/img/header.jpg");
}

*::v-deep .v-tabs-slider {
  background-color: white;
  color: white;
}
</style>
