<template>
  <div>
    <component :is="componentInstance" :ref="normalized_current_type" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    normalized_current_type() {
      let template = "rate";
      if (this.current_hotel) {
        if (this.current_hotel.permissions["dashboard_rms_hotelsdot"]) {
          template = "rmsHotelsdot";
        }
        if (this.current_hotel.permissions["dashboard_rms"]) {
          template = "rms";
        }
      }
      return template;
    },
    // Conditional component importation
    componentInstance() {
      const name = this.normalized_current_type;
      return () => import(`../views/dashboard/${name}`);
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
