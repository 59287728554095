<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <div class="a-rms-day-column-values">
        <div class="mb-1">
          <a-period :period="period"></a-period>
          <!-- <a-period v-if="period === 'otb'" :period="period"></a-period>
          <a-period-compare
            v-else
            :periods="[null, period]"
            :bw="!hover"
          ></a-period-compare> -->
        </div>
        <div class="px-3">
          <a-kpi
            kpi="occupancy"
            :class="occupancy_class"
            :value="period_data.occupancy"
          ></a-kpi>
        </div>
        <div class="px-3">
          <a-kpi kpi="rn" :value="period_data.rn"></a-kpi>
        </div>
        <div class="px-3">
          <a-kpi kpi="adr" :value="period_data.adr"></a-kpi>
        </div>
        <div class="px-3">
          <a-kpi kpi="room_revenue" :value="period_data.room_revenue"></a-kpi>
        </div>
      </div>
    </template>
  </v-hover>
</template>

<script>
import aPeriod from "@/components/rms_ui/aPeriod";
import aKpi from "@/components/rms_ui/aKpi";
//import aPeriodCompare from "@/components/rms_ui/aPeriodCompare";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "a-rms-strategy-column-values",
  components: {
    aPeriod,
    aKpi
    //aPeriodCompare
  },
  props: {
    period: {
      type: String,
      mandatory: true,
      default: ""
    },
    day_data: {
      type: Object,
      mandatory: true,
      default: () => {}
    }
  },
  data: vm => ({}),
  watch: {},
  methods: {},
  computed: {
    occupancy_class() {
      let v = this.period_data.occupancy;
      let clas = [this.text_class];
      if (v >= this.rms.occupancy_max) clas.push("green--text");
      if (v <= this.rms.occupancy_min) clas.push("red--text");
      return clas.join(" ");
    },
    period_data() {
      return this.day_data[this.period];
    },
    ...mapState({
      rms: state => state.rms
      // calendar_store: state => state.calendar
    }),
    ...mapGetters({
      // getRmsDayKpis: "getRmsDayKpis",
      // getDayEvents: "getDayEvents"
    })
  }
};
</script>
<style scoped lang="scss">
.a-rms-day-column-values {
  flex-grow: 1;
  max-width: 150px;
  div {
    text-align: center;
    height: 20px;
    font-size: 13px;
    line-height: 14px;
  }
}
</style>
