<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <v-row>
      <v-col
        v-for="page in settings_pages"
        :key="page.title"
        cols="12"
        sm="6"
        md="3"
      >
        <a-settings-card
          :image_source="page.image_source"
          :title="page.title"
          :description="page.description"
          :route_path="page.route_path"
          :disabled="page.disabled"
        ></a-settings-card>
      </v-col>
    </v-row>
  </a-page>
</template>

<script>
import aPage from "@/components/aPage";
import aSettingsCard from "@/components/aSettingsCard";
import { mapGetters } from "vuex";

export default {
  components: {
    aPage,
    aSettingsCard
  },
  data: () => ({
    app_id: "settings_app",
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 276 //Mandatory number,
  }),
  methods: {
    disabledByPermission(permission) {
      return (
        this.current_user &&
        this.current_hotel &&
        (!this.current_user.permissions[permission] ||
          !this.current_hotel.permissions[permission])
      );
    }
  },
  computed: {
    settings_pages() {
      return [
        {
          disabled: this.disabledByPermission("hotelmanagement"),
          image_source: "/img/settings/hotels.jpeg",
          title: this.$t("hotels"),
          description: this.$t("hotels_description"),
          route_path: {
            name: "hotelmanagement"
          }
        },
        {
          disabled: this.disabledByPermission("customermanagement"),
          image_source: "/img/settings/team.jpg",
          title: this.$t("team"),
          description: this.$t("team_description"),
          route_path: {
            name: "team"
          }
        },
        {
          disabled: this.disabledByPermission("roomtypemanagement"),
          image_source: "/img/settings/roomtypes.jpeg",
          title: this.$t("roomtypes"),
          description: this.$t("roomtypes_description"),
          route_path: {
            name: "room-types"
          }
        },
        {
          disabled: this.disabledByPermission("budget"),
          image_source: "/img/settings/budget.jpg",
          title: this.$t("budget"),
          description: this.$t("budget_description"),
          route_path: {
            name: "budget"
          }
        },
        {
          disabled: this.disabledByPermission("eventsmanagement"),
          image_source: "/img/settings/events.jpeg",
          title: this.$t("event-management"),
          description: this.$t("event_management_description"),
          route_path: {
            name: "event-management"
          }
        },
        {
          disabled: this.disabledByPermission("calendarmanagement"),
          image_source: "/img/settings/calendar.jpg",
          title: this.$t("calendar"),
          description: this.$t("calendar_description"),
          route_path: {
            name: "calendar"
          }
        },
        {
          disabled: this.disabledByPermission("accounting"),
          image_source: "/img/settings/accounting.jpeg",
          title: this.$t("accounting"),
          description: this.$t("accounting_description"),
          route_path: {
            name: "accounting"
          }
        },
        {
          disabled: this.disabledByPermission("apistatus"),
          image_source: "/img/settings/apistatus.jpeg",
          title: this.$t("api_status"),
          description: this.$t("api_status_description"),
          route_path: {
            name: "api-status"
          }
        }
      ];
    },
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  }
};
</script>
