<template>
  <div :id="app_id">
    <v-tabs
      class="px-1"
      background-color="transparent"
      slider-size="2"
      v-model="tab"
      dark
      grow
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        {{ $t("segments") }}
      </v-tab>
      <v-tab>
        {{ $t("channels") }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <segments
              :segments="segments_store.segments"
              @getSegments="getSegments"
            ></segments>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <channels
              :channels="segments_store.segment_channels"
              @getSegments="getSegments"
            ></channels>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Segments from "@/components/segments/Segments.vue";
import Channels from "@/components/segments/Channels.vue";

export default {
  name: "segmentsChannelsTabsApp",
  components: {
    Channels,
    Segments
  },
  data: () => ({
    app_id: "segments-channels-tabs-app",
    selected: { name: "" },
    tab: 0
  }),
  computed: {
    ...mapState({
      segments_store: state => state.segmentschannels
    })
  },
  methods: {
    getSegments() {
      this.$emit("getSegments");
    }
  }
};
</script>

<style lang="scss" scoped>
.swatch-style {
  border-radius: 7px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.v-avatar {
  justify-content: center !important;
}
</style>
