<template>
  <div id="rate-min-prices" class="d-flex rate-data-row">
    <div class="item rate-labels semibold">
      <div v-if="$vuetify.breakpoint.smAndUp" class="simple_view">
        <v-avatar :color="competitor.color" class="mt-n1" size="16">
          <v-img src="/img/otas/mirai.png"></v-img>
        </v-avatar>
        {{ $t("your_web") }}
      </div>
      <div v-else class="simple_view py-0 pl-2">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              x-small
              depressed
              :color="competitor.color"
              class="ma-0 v-list-item__avatar subtitle-2 white--text text-uppercase justify-center"
              >W
            </v-btn>
          </template>
          <span>{{ $t("your_web") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="(day, index) in days"
          :key="'rate-price-min-' + day + '-competitor-' + competitor.id"
          class="day"
          :class="getDayClass(day)"
          @mouseover="
            date_hover = day;
            day_hover = index;
          "
          @mouseleave="
            date_hover = '';
            day_hover = '';
          "
          @click="showDate(day, competitor.id)"
        >
          <div
            v-if="mustRender(day)"
            class="day-date semibold"
            :class="'visible-day visible-day-' + index"
          >
            <div
              class="simple_view"
              v-if="daily_rates && daily_rates[day] && mode == 'rate'"
            >
              <div class="rate_data_item">
                <span class="block rate_data_main">
                  <v-tooltip
                    left
                    content-class="a-rate-tooltip"
                    v-if="daily_rates[day][0]"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <a-kpi
                          :class="
                            parity_colors[daily_rates[day][0].parity_code] +
                              '--text'
                          "
                          :kpi="'min_price'"
                          :value="daily_rates[day][0].min_price"
                          :increment="false"
                          class="font-weight-bold"
                        ></a-kpi>
                      </div>
                    </template>
                    <span
                      class="text-center"
                      style="font-size: 13px; line-height: 11px"
                    >
                      {{ daily_rates[day][0].room_type_name }}
                      <br />
                      <v-icon
                        class="ml-n1"
                        v-for="i in daily_rates[day][0].room_type_adults"
                        :key="'adults_' + i + '_' + competitor.id + '_' + day"
                        color="primary"
                        small
                        >mdi-account</v-icon
                      >
                      <span class="pa-1"></span>
                      <v-icon
                        class="ml-n1 mt-1"
                        v-for="i in daily_rates[day][0].room_type_childs"
                        :key="'childs_' + i + '_' + competitor.id + '_' + day"
                        color="primary"
                        x-small
                        >mdi-account</v-icon
                      >
                    </span>
                  </v-tooltip>
                  <!-- <a-kpi
                    v-else
                    :kpi="'min_price'"
                    :value="''"
                    :increment="false"
                    class="font-weight-bold"
                  ></a-kpi> -->
                </span>
                <span class="block rate_data_vs">
                  <v-row class="mx-0">
                    <v-col class="pa-0" cols="4">
                      <!-- Free cancellation -->
                      <v-tooltip
                        bottom
                        content-class="a-rate-tooltip"
                        v-if="daily_rates[day][0]"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="daily_rates[day][0].free_cancelation"
                            color="grey lighten-1"
                            small
                            >mdi-alpha-c-circle</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size: 13px; line-height: 11px"
                          >{{ $t("free_cancellation") }}<br />(
                          <b>{{ daily_rates[day][0].free_cancelation_days }}</b>
                          {{ $t("daysb") }} )</span
                        >
                      </v-tooltip>
                    </v-col>
                    <v-col class="pa-0" cols="4">
                      <!-- Minimum days -->
                      <!-- Free cancellation -->
                      <v-tooltip
                        bottom
                        content-class="a-rate-tooltip"
                        v-if="daily_rates[day][0]"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="daily_rates[day][0].minimum_nights > 1"
                            color="grey lighten-1"
                            small
                            >mdi-numeric-{{
                              daily_rates[day][0].minimum_nights
                            }}-circle</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size: 13px; line-height: 11px"
                          >{{ $t("minimum_stay") }}: <br />
                          <b>{{ daily_rates[day][0].minimum_nights }}</b>
                          {{ $t("days") }}</span
                        >
                      </v-tooltip>

                      <!-- Adults -->
                      <!-- <v-icon class="ml-n1" v-for="i in daily_rates[day][0].room_type_adults" :key="'adults_' + i + '_' + competitor.id + '_' + day" color="grey lighten-1" small>mdi-account</v-icon>
                      <v-icon class="ml-n1" v-for="i in daily_rates[day][0].room_type_childs" :key="'childs_' + i + '_' + competitor.id + '_' + day" color="grey lighten-1" x-small>mdi-account</v-icon> -->
                    </v-col>
                    <v-col class="pa-0" cols="4">
                      <!-- Regime -->
                      <v-tooltip
                        bottom
                        content-class="a-rate-tooltip"
                        v-if="daily_rates[day][0]"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="grey lighten-1"
                            v-if="daily_rates[day][0].regime == 'alo'"
                            style="font-size: 19px"
                            >mdi-bed-king-outline</v-icon
                          >
                          <v-icon
                            v-on="on"
                            color="grey lighten-1"
                            v-if="daily_rates[day][0].regime == 'ad'"
                            style="font-size: 19px"
                            >mdi-coffee-outline</v-icon
                          >
                          <v-icon
                            v-on="on"
                            color="grey lighten-1"
                            v-if="daily_rates[day][0].regime == 'mp'"
                            style="font-size: 19px"
                            >mdi-silverware-variant</v-icon
                          >
                          <v-icon
                            v-on="on"
                            color="grey lighten-1"
                            v-if="daily_rates[day][0].regime == 'pc'"
                            style="font-size: 19px"
                            >mdi-silverware</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size: 13px; line-height: 11px"
                          >{{ $t(daily_rates[day][0].regime) }}</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </span>
              </div>
            </div>
            <div
              class="simple_view"
              v-if="
                daily_availability &&
                  daily_availability[day] &&
                  mode == 'availability'
              "
            >
              <div
                class="rate_data_item"
                style="line-height: 40px"
                :class="{ 'green--text': daily_availability[day].free_sell }"
              >
                <a-kpi
                  :kpi="'rate_available_rooms'"
                  :value="daily_availability[day].rate_available_rooms"
                  :increment="true"
                  :vs_value="
                    daily_rates_vs && daily_rates_vs[day]
                      ? daily_rates_vs[day].rate_available_rooms
                      : null
                  "
                  :increment_value="
                    increments && increments[day]
                      ? increments[day].rate_available_rooms
                      : null
                  "
                  :simple="true"
                ></a-kpi>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- :color="isHover(day) ? 'primary' : 'grey lighten-1'"  -->
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";

export default {
  name: "rate-min-price",
  components: {
    aKpi
  },
  props: [
    "days",
    "days_per_screen",
    "competitor",
    "daily_rates",
    "daily_availability",
    "ota_daily_rates",
    "ota_daily_availability",
    "mode",
    "increments",
    "daily_rates_vs",
    "filters",
    "days_to_render",
    "groupDisplay"
  ],
  data: vm => ({
    hover: false
  }),
  methods: {
    getDayClass(day) {
      var clas = "";
      const parity_code =
        this.daily_rates[day] && this.daily_rates[day][0]
          ? this.daily_rates[day][0].parity_code
          : null;
      clas += this.parity_colors[parity_code] + "_bg";
      clas += this.is_weekend(day) ? " weekend" : "";
      return clas;
    },
    changeCompetitorVisibility(competitor) {
      if (!competitor.self) {
        if (this.isInvisible(competitor.id)) {
          this.$store.dispatch("removeVisibleCompetitor", competitor.id);
        } else {
          this.$store.dispatch("addVisibleCompetitor", competitor.id);
        }
      }
    },
    showDate(date, competitor_id) {
      if (this.groupDisplay) {
        const c = this.getCompetitorById(competitor_id);

        this.$store.dispatch("setCurrentHotelById", c.hotel_id);
        this.$router.push({ name: "rate" });
      } else {
        this.show_rate_date_to_compare = true;
        this.$store.dispatch("setComparedCompetitors", [competitor_id]);
        this.$store.dispatch("setRateDateToCompare", date);
      }
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    },
    isHover(date) {
      return this.date_hover == date;
    },
    rankingClass(date) {
      return this.increments &&
        this.increments[date] &&
        this.increments[date][0] &&
        this.increments[date][0].ranking > 0
        ? "decrement"
        : this.increments &&
          this.increments[date] &&
          this.increments[date][0] &&
          this.increments[date][0].ranking < 0
        ? "increment"
        : "";
    },
    mustRender(date) {
      // Must render if date inside days_per_screen
      return this.days_to_render.indexOf(date) >= 0;
    },
    isInvisible(id) {
      return this.invisible_competitors.indexOf(id) >= 0;
    }
  },
  computed: {
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },
    ...mapState({
      invisible_competitors: state => state.rate.invisible_competitors,
      parity_colors: state => state.parity_colors
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getCompetitorById: "getCompetitorById"
    })
  }
};
</script>
