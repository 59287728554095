<template>
  <div>
    <div
      class="a-rms-day-row-events d-flex justify-center align-center pb-2"
      :class="events_class"
      v-if="calendar_store"
    >
      <v-tooltip color="black" bottom v-for="event in events" :key="event.name">
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            x-small
            text-color="white"
            :color="eventColor(event)"
            class="mr-1"
          >
            <v-icon x-small class="mr-1">
              {{ getEventTypeIcon(event.type) }}
            </v-icon>
            <b>{{ event.alias.slice(0, 10) }}</b>
          </v-chip>
        </template>
        <span>{{ event.alias }}</span>
      </v-tooltip>
    </div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          no_title
          class="a-rms-day-hotelsdot pa-1"
          :class="card_class"
          :style="hover ? 'outline: 2px solid #44a2bc;' : ''"
          @click="getDayDetails"
          :data-date="item.date"
        >
          <div class="a-rms-day-row-day d-flex align-center justify-start pb-2">
            <a-weather-day :date="item.date"></a-weather-day>
            <v-spacer></v-spacer>
            <v-icon v-if="has_comments" color="accent" class="mr-2" x-small
              >mdi-comment-outline</v-icon
            >
            <v-chip
              v-if="isToday"
              dark
              x-small
              color="blue"
              class="mr-2 pl-2 pr-2"
            >
              {{ $t("today") }}
            </v-chip>
            <span>
              {{ getFriendlyDay }}
            </span>
          </div>
          <a-rms-day-header
            :labels="['MKTD', 'RK', 'RN Disp', 'PVP']"
            :date="item.date"
          ></a-rms-day-header>
          <div class="a-rms-day-row" v-if="day_data && day_data.otb">
            <v-row no-gutters>
              <v-col cols="3">
                <a-kpi
                  :class="getMktClass"
                  :value="day_data.otb.market_demand"
                  kpi="market_demand"
                  xx_small
                  right
                  v-if="day_data.otb.market_demand > 0"
                ></a-kpi>
              </v-col>
              <v-col cols="2">
                <a-rms-day-rk-tooltip
                  :date="item.date"
                  :pickup="false"
                  v-if="all_kpis_daily_compset"
                >
                  <v-icon v-show="hover" x-small class="tooltip-launcher"
                    >mdi-arrow-top-right-thick</v-icon
                  >
                  <a-kpi
                    class="font-weight-bold"
                    :value="
                      day_data.otb.compset_ranking > 0
                        ? day_data.otb.compset_ranking
                        : null
                    "
                    kpi="compset_ranking"
                    xx_small
                    right
                  ></a-kpi>
                </a-rms-day-rk-tooltip>
              </v-col>
              <v-col cols="3">
                <a-kpi
                  class="font-weight-bold"
                  :value="day_data.otb.free"
                  kpi="available"
                  xx_small
                  right
                ></a-kpi>
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <a-kpi
                  class="font-weight-bold"
                  :class="pvp_class"
                  :value="day_data.otb.pvp"
                  kpi="pvp"
                  xx_small
                  right
                ></a-kpi>
                <v-avatar
                  size="9"
                  class="avatar ml-1"
                  :color="pvp_prev_color"
                  v-if="show_pvp_change_prev"
                ></v-avatar>
              </v-col>
            </v-row>
          </div>
          <a-rms-day-header
            :labels="['CUP', 'GPC', 'GPB', 'MLOS']"
          ></a-rms-day-header>
          <div class="a-rms-day-row" v-if="day_data && day_data.otb">
            <v-row no-gutters>
              <v-col cols="36">
                <a-kpi
                  class="font-weight-bold"
                  :value="day_data.otb.cupos"
                  kpi="cupos"
                  xx_small
                  right
                ></a-kpi>
              </v-col>
              <v-col cols="2">
                <a-kpi
                  class="font-weight-bold"
                  :value="day_data.otb.groups_confirmed"
                  kpi="groups_confirmed"
                  xx_small
                  right
                ></a-kpi>
              </v-col>
              <v-col cols="3">
                <a-kpi
                  class="font-weight-bold"
                  :value="day_data.otb.groups_locked"
                  kpi="groups_locked"
                  xx_small
                  right
                ></a-kpi>
              </v-col>
              <v-col cols="4" class="d-flex justify-end"
                ><a-kpi
                  class="font-weight-bold"
                  :class="mlos_class"
                  :value="day_data.otb.mlos"
                  kpi="mlos"
                  xx_small
                  right
                  v-if="day_data.otb.mlos > 0"
                ></a-kpi>
                <v-avatar
                  size="9"
                  class="avatar ml-1"
                  :color="mlos_prev_color"
                  v-if="show_mlos_change_prev"
                ></v-avatar
              ></v-col>
            </v-row>
          </div>
          <a-rms-day-header
            class="mt-2"
            :labels="['Occ', 'RN', 'ADR', 'Rev']"
          ></a-rms-day-header>
          <a-rms-day-row
            class="a-rms-day-row-resume"
            :day_data="day_data"
            :periods="['otb', null]"
            :index="10"
            @hoverRow="hoverRow"
            text_class="font-weight-bold"
            :class="color_class"
          ></a-rms-day-row>
          <a-rms-day-row-vs
            v-if="rms.show_bud"
            :day_data="day_data"
            :periods="['otb', 'bud']"
            :index="1"
            @hoverRow="hoverRow"
          ></a-rms-day-row-vs>
          <a-rms-day-row-vs
            v-if="rms.show_stly"
            :day_data="day_data"
            :periods="['otb', 'stly']"
            :index="2"
            @hoverRow="hoverRow"
          ></a-rms-day-row-vs>
          <a-rms-day-row-vs
            :day_data="day_data"
            v-if="rms.show_ly"
            :periods="['otb', 'ly']"
            :index="3"
            @hoverRow="hoverRow"
          ></a-rms-day-row-vs>
          <div class="a-rms-day-row-sep"></div>
          <a-rms-day-row-pickup
            v-if="rms.show_pickup_1"
            class="a-rms-day-row-pickup"
            :day_data="day_data"
            :date="item.date"
            :spot="1"
            :hover="hover"
            :periods="['otb', 'pickup_1']"
            :index="4"
            @hoverRow="hoverRow"
          ></a-rms-day-row-pickup>
          <a-rms-day-row-pickup
            v-if="rms.show_pickup_2"
            class="a-rms-day-row-pickup"
            :day_data="day_data"
            :date="item.date"
            :spot="2"
            :hover="hover"
            :periods="['otb', 'pickup_2']"
            :index="5"
            @hoverRow="hoverRow"
          ></a-rms-day-row-pickup>
          <a-rms-day-row-pickup
            v-if="rms.show_pickup_3"
            class="a-rms-day-row-pickup"
            :day_data="day_data"
            :date="item.date"
            :spot="3"
            :hover="hover"
            :periods="['otb', 'pickup_3']"
            :index="6"
            @hoverRow="hoverRow"
          ></a-rms-day-row-pickup>
          <a-rms-day-row-pickup
            v-if="rms.show_pickup_4"
            class="a-rms-day-row-pickup"
            :day_data="day_data"
            :date="item.date"
            :spot="4"
            :hover="hover"
            :periods="['otb', 'pickup_4']"
            :index="7"
            @hoverRow="hoverRow"
          ></a-rms-day-row-pickup>
          <a-rms-day-row-pickup
            v-if="rms.show_pickup_5"
            class="a-rms-day-row-pickup"
            :day_data="day_data"
            :date="item.date"
            :spot="5"
            :hover="hover"
            :periods="['otb', 'pickup_5']"
            :index="8"
            @hoverRow="hoverRow"
          ></a-rms-day-row-pickup>
          <a-rms-day-row-pickup
            v-if="rms.show_pickup_6"
            class="a-rms-day-row-pickup"
            :day_data="day_data"
            :date="item.date"
            :spot="6"
            :hover="hover"
            :periods="['otb', 'pickup_6']"
            :index="9"
            @hoverRow="hoverRow"
          ></a-rms-day-row-pickup>
          <!-- <a-rms-day-row
        class="a-rms-day-row-resume"
        :day_data="day_data"
        :periods="['otb', null]"
        :index="10"
        @hoverRow="hoverRow"
        text_class="font-weight-bold"
      ></a-rms-day-row> -->
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

var numeral = require("numeral");
import * as Utils from "@/utils.js";
import aRmsDayHeader from "@/components/rms/hotelsdot/aRmsDayHeader";
import aRmsDayRow from "@/components/rms/hotelsdot/aRmsDayRow";
import aRmsDayRowPickup from "@/components/rms/hotelsdot/aRmsDayRowPickup";
import aRmsDayRowVs from "@/components/rms/hotelsdot/aRmsDayRowVs";
import aKpi from "@/components/rms_ui/aKpi";
import aWeatherDay from "@/components/rms_ui/aWeatherDay";
import ARmsDayRkTooltip from "@/components/rms/hotelsdot/aRmsDayRkTooltip.vue";

export default {
  name: "a-rms-day",
  components: {
    ARmsDayRkTooltip,
    aRmsDayHeader,
    aRmsDayRow,
    aRmsDayRowPickup,
    aRmsDayRowVs,
    aKpi,
    aWeatherDay
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    detail_days: {
      type: Array,
      default: () => []
    },
    event_period: {
      type: Array,
      default: () => []
    }
  },
  data: vm => ({
    title: "",
    subscribed_modules: ["rms_all_kpis_daily"]
  }),
  watch: {},
  methods: {
    getDayDetails() {
      this.$emit("getDayDetails", this.item.date);
    },
    hoverRow(index) {
      this.$emit("hoverRow", index);
    },
    eventColor(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    pvp_mlos_changes(kpi, spot) {
      return this.rms.pvp_mlos_changes_daily &&
        this.rms.pvp_mlos_changes_daily[this.item.date] &&
        this.rms.pvp_mlos_changes_daily[this.item.date][kpi]
        ? this.rms.pvp_mlos_changes_daily[this.item.date][kpi][spot]
        : 0;
    },
    ...mapActions([])
  },
  computed: {
    isToday() {
      return this.item.date === Vue.moment().format("YYYY-MM-DD");
    },
    getMktClass() {
      let clas = ["font-weight-bold"];

      if (this.day_data && this.day_data.otb.market_demand >= this.rms.mktd_max)
        clas.push("green--text");
      if (this.day_data && this.day_data.otb.market_demand <= this.rms.mktd_min)
        clas.push("red--text");
      return clas.join(" ");
    },
    events() {
      return this.item && this.item.date
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.item.date).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
    },
    isSelected() {
      return this.detail_days.indexOf(this.item.date) >= 0;
    },
    getFriendlyDay() {
      return this.item ? Utils.getFriendlyDay(this.item.date, false, true) : "";
    },
    day_data() {
      return this.item ? this.getRmsDayKpis(this.item.date) : {};
    },
    has_comments() {
      return this.day_data && this.day_data.otb.has_comments;
    },
    color_class() {
      if (!this.day_data || !this.day_data.otb || !this.day_data.stly)
        return "neutral";

      let diff = this.day_data.otb.occupancy - this.day_data.stly.occupancy;
      if (diff > 0) return "positive";
      if (diff < 0) return "negative";
      return "neutral";
    },
    card_class() {
      let classes = [];
      if (this.item.date === Vue.moment().format("YYYY-MM-DD"))
        classes.push("selected");
      if (Vue.moment(this.item.date).isBefore(undefined, "day"))
        classes.push("previous");
      if (this.day_data && this.day_data.otb.occupancy > 99.9)
        classes.push("soldout");

      classes.push(this.isSelected ? "elevation-3" : "elevation-0");
      classes.push(this.color_class);
      return classes.join(" ");
    },
    ...mapState({
      rms: state => state.rms,
      calendar_store: state => state.calendar
    }),
    events_class() {
      let classes = [];
      if (Vue.moment(this.item.date).isBefore(undefined, "day"))
        classes.push("previous");
      if (this.day_data && this.day_data.otb.occupancy > 99.9)
        classes.push("soldout");

      return classes.join(" ");
    },
    show_pvp_change_prev() {
      return (
        this.pvp_mlos_changes("pvp", "prev") &&
        this.pvp_mlos_changes("pvp", "prev") !== 0
      );
    },
    has_pvp_change_today() {
      return (
        this.pvp_mlos_changes("pvp", "pickup0") &&
        this.pvp_mlos_changes("pvp", "pickup0") !== 0
      );
    },
    pvp_class() {
      if (this.has_pvp_change_today) {
        return this.pvp_mlos_changes("pvp", "pickup0") < 0
          ? "red--text"
          : "blue--text";
      }
      return "";
    },
    pvp_prev_color() {
      if (this.show_pvp_change_prev) {
        return this.pvp_mlos_changes("pvp", "prev") < 0 ? "red" : "blue";
      }
      return "";
    },
    show_mlos_change_prev() {
      return (
        this.pvp_mlos_changes("mlos", "prev") &&
        this.pvp_mlos_changes("mlos", "prev") !== 0
      );
    },
    has_mlos_change_today() {
      return (
        this.pvp_mlos_changes("mlos", "pickup0") &&
        this.pvp_mlos_changes("mlos", "pickup0") !== 0
      );
    },
    mlos_class() {
      if (this.has_mlos_change_today) {
        return this.pvp_mlos_changes("mlos", "pickup0") < 0
          ? "red--text"
          : "blue--text";
      }
      return "";
    },
    mlos_prev_color() {
      if (this.show_mlos_change_prev) {
        return this.pvp_mlos_changes("mlos", "prev") < 0 ? "red" : "blue";
      }
      return "";
    },
    ...mapState({
      rms: state => state.rms,
      calendar_store: state => state.calendar,
      all_kpis_daily_compset: state => state.rms.all_kpis_daily_compset
    }),
    ...mapGetters({
      getRmsDayKpis: "getRmsDayKpis",
      getDayEvents: "getDayEvents"
    })
  },
  mounted() {}
};
</script>
<style lang="scss">
.a-rms-day-hotelsdot {
  border-left: 1px solid grey;
  border-top: 4px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  transition: all 0.5s;

  &.weekend {
    background-color: #fffef4;
  }
  &:not(.hovered).previous,
  &:not(.hovered).soldout {
    opacity: 0.7;
    color: #666;
  }
  &.positive {
    border-top-color: rgb(23, 171, 112);
  }
  &.negative {
    border-top-color: rgb(172, 0, 0);
  }
  &.neutral {
    border-top-color: orange;
  }

  $height: 19px;
  font-size: 11px;
  line-height: $height;
  text-align: right;
  &:focus::before {
    background: white;
  }
  .a-rms-day-row-day {
    height: $height;
    font-weight: 600;
    text-transform: uppercase;
    color: #444444;
    letter-spacing: -0.5px;
  }
  .a-rms-day-row-header {
    font-weight: 500;
    color: #aaaaaa;
    height: $height;
    overflow: hidden;
    letter-spacing: -0.5px;
  }
  .a-rms-day-row {
    height: $height;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .a-rms-day-row-resume {
    height: $height;
    font-weight: 600;
    //border-top: 1px solid #e5e5e5;
    //border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    // padding-top: 5px;
    // margin-top: 5px;

    &.positive {
      background-color: rgba(23, 171, 112, 0.1);
    }
    &.negative {
      background-color: rgba(172, 0, 0, 0.1);
    }
    &.neutral {
      background-color: rgba(255, 165, 0, 0.1);
    }
  }
  .a-rms-day-row-sep {
    //height: 1px;
    //font-weight: 600;
    //border-top: 1px solid #e5e5e5;
    //border-bottom: 1px solid #e5e5e5;
    //overflow: hidden;
    // padding-top: 5px;
    // margin-top: 5px;
  }
  .a-rms-day-row-pickup {
    background-color: rgba(100, 100, 100, 0.1);
  }
}
.a-rms-day-row-events {
  height: 19px;
  &.previous,
  &.soldout {
    opacity: 0.7;
  }
}
.tooltip-launcher {
  position: absolute !important;
}
</style>
