<template>
  <v-card color="grey lighten-4 mb-5">
    <v-card-title>
      <v-row class="align-center">
        <v-col
          cols="6"
          class="text-uppercase font-weight-bold grey--text text--darken-3"
        >
          {{ $t("availability") }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" class="d-flex align-center justify-end">
          <v-switch
            v-model="only_rooms"
            :label="$t('only_rooms')"
            class="mt-0 pt-0 mr-5"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="only_changes"
            :label="$t('only_changes')"
            class="mt-0 pt-0 mr-5"
            dense
            hide-details
          ></v-switch>
          <v-select
            v-model="vs_pickup"
            :items="vs_pickups"
            :label="$t('compare_with')"
            rounded
            outlined
            dense
            hide-details
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="white pa-0">
      <div :class="table_class">
        <v-btn
          v-show="page > 0"
          @click.prevent="moveDownSlider"
          id="arrow-left"
          class="arrow"
          rounded
          color="white"
          fab
          :loading="loading_page"
          small
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          v-show="hasMorePages"
          @click.prevent="moveUpSlider"
          id="arrow-right"
          class="arrow"
          rounded
          fab
          color="white"
          :loading="loading_page"
          small
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <a-rms-strategy-availability-columns
          :columns="filtered_columns"
          :page="page"
          :only_rooms="only_rooms"
          :roomtypes_per_screen="roomtypes_per_screen"
          :roomtypes_total="roomtypes_total"
        ></a-rms-strategy-availability-columns>
        <a-rms-strategy-availability-row
          v-for="(item, index) in filtered_rows"
          :key="index"
          :columns="filtered_columns"
          :rowData="item"
          :rowIndex="index"
          :vs_pickup="vs_pickup"
          :only_rooms="only_rooms"
          :page="page"
          :roomtypes_per_screen="roomtypes_per_screen"
          :roomtypes_total="roomtypes_total"
        ></a-rms-strategy-availability-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import aRmsStrategyAvailabilityColumns from "@/components/rms/aRmsStrategyAvailabilityColumns";
import aRmsStrategyAvailabilityRow from "@/components/rms/aRmsStrategyAvailabilityRow";

export default {
  name: "a-rms-strategy-availability-table",
  components: {
    aRmsStrategyAvailabilityColumns,
    aRmsStrategyAvailabilityRow
  },
  props: {
    kpi: String,
    columns: Object,
    rows: Array
  },
  data: () => ({
    only_rooms: false,
    only_changes: false,
    vs_pickup: null,
    loading_page: false,
    page: 0
  }),
  computed: {
    vs_pickups() {
      return [
        { value: "pickup_1", text: this.getPickupLiteral("pickup_1") },
        { value: "pickup_2", text: this.getPickupLiteral("pickup_2") },
        { value: "pickup_3", text: this.getPickupLiteral("pickup_3") },
        { value: "pickup_4", text: this.getPickupLiteral("pickup_4") },
        { value: "pickup_5", text: this.getPickupLiteral("pickup_5") },
        { value: "pickup_6", text: this.getPickupLiteral("pickup_6") }
      ];
    },
    filtered_columns() {
      let cols = JSON.parse(JSON.stringify(this.columns));
      if (this.only_rooms) {
        Object.keys(cols).forEach(value => {
          cols[value] = cols[value].filter(value => {
            return value === "free" || value === "locked";
          });
        });
      }
      return cols;
    },
    filtered_rows() {
      let rows = this.rows;
      if (this.only_changes) {
        rows = rows.filter(row => {
          return Object.values(Object.values(row)[0]).some(data => {
            return data["free"][this.vs_pickup] !== 0;
          });
        });
        return rows;
      }
      return rows;
    },
    table_class() {
      let clas = "table-wrap page_" + this.page;
      return clas;
    },
    roomtypes_total() {
      return Object.keys(this.filtered_columns).length;
    },
    roomtypes_per_screen() {
      let roomtypes_per_screen = 3;
      if (this.only_rooms) {
        roomtypes_per_screen = 6;
      }
      return roomtypes_per_screen;
    },
    pages() {
      return this.roomtypes_total / this.roomtypes_per_screen;
    },
    hasMorePages() {
      return this.page < this.pages - 1;
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    moveDownSlider() {
      if (this.page > 0) {
        this.loading_page = true;
        this.page--;
        setTimeout(() => {
          this.loading_page = false;
        }, 200);
      }
    },
    moveUpSlider() {
      if (this.page < this.pages) {
        this.loading_page = true;
        this.page++;
        setTimeout(() => {
          this.loading_page = false;
        }, 200);
      }
    },
    getPickupLiteral(period) {
      let pickups = {
        pickup_1: this.$t("pickup_1"),
        pickup_2:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_3:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_4:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_5:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_6:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_6"]
      };
      if (pickups[period]) {
        return pickups[period];
      } else {
        return period.replace("_", " ");
      }
    }
  },
  mounted() {
    this.vs_pickup = this.vs_pickups[0].value;
  }
};
</script>

<style lang="scss" scoped>
.v-select {
  max-width: 200px;
}
.table-wrap {
  position: relative;
  .arrow {
    background: #fff;
    color: #aaaaaa;
    position: absolute;
    top: 1px;
    z-index: 200;
  }
  #arrow-left {
    left: 180px;
  }
  #arrow-right {
    left: auto;
    right: -12px;
  }
}
</style>
