<template>
  <div class="py-2">
    <v-row>
      <v-col
        v-for="(data, key) in item.audit.data_validation"
        :key="key"
        cols="6"
      >
        <v-card class="elevation-0" outlined>
          <v-card-title class="text-uppercase">
            {{ getMonthName(key) }}
          </v-card-title>
          <v-card-text>
            <div class="d-flex images-wrapper">
              <v-img
                :src="data.rcd"
                max-height="100"
                max-width="100"
                class="cursor-pointer rounded-lg"
                @click="showDialog(data.rcd)"
              ></v-img>
              <v-icon>mdi-arrow-left-right</v-icon>
              <v-img
                :src="data.veetal"
                max-height="100"
                max-width="100"
                class="cursor-pointer rounded-lg"
                @click="showDialog(data.veetal)"
              ></v-img>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Dialogo para mostrar la imagen completa -->
    <v-dialog v-model="dialog" max-width="90%">
      <v-card>
        <v-card-text class="pa-0">
          <v-img :src="image_url" max-width="100%"></v-img>
          <v-btn
            color="black"
            min-width="0"
            rounded
            class="close pa-2"
            @click="dialog = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "a-hotel-data-validation",
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  data: () => ({
    image_url: "",
    dialog: false
  }),
  computed: {
    current_month() {
      return Vue.moment().format("MMMM YYYY");
    },
    next_month() {
      return Vue.moment()
        .add(1, "month")
        .format("MMMM YYYY");
    }
  },
  methods: {
    showDialog(image_url) {
      this.image_url = image_url;
      this.dialog = true;
    },
    getMonthName(key) {
      return key === "current" ? this.current_month : this.next_month;
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.close {
  position: absolute;
  top: 5px;
  right: 5px;
}
.images-wrapper {
  gap: 20px;
}
</style>
