<template>
  <div>
    <v-row class="mx-0">
      <v-col class="spacer pa-0"> </v-col>
      <v-col class="pa-0 ma-0 days-container">
        <v-row class="pa-0 ma-0 days-wrapper" :style="styleRow">
          <v-col
            v-for="(column, index) of columns"
            :key="column"
            class="pa-0 pt-1 kpi-cell d-flex align-center justify-center"
            :class="bgCellClass(index)"
          >
            <div>
              <span
                class="d-block day-name grey--text text--darken-3 text-uppercase"
              >
                {{ getFriendlyDateTop(column) }}
              </span>
              <span class="d-block grey--text text-capitalize">{{
                getFriendlyDateBottom(column)
              }}</span>
            </div>
            <div class="events">
              <v-tooltip
                color="white"
                bottom
                v-for="event in dayImpacts(column)"
                :key="event.impact"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    :color="event.color"
                    size="16"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                  >
                    <span
                      class="white--text"
                      style="font-size:9px;font-weight: 400;"
                    >
                      {{ event.count }}
                    </span>
                  </v-avatar>
                </template>
                <div class="pt-2 pb-1 ">
                  <h3
                    class="black--text mb-2 text-center text-uppercase"
                    v-html="label(column)"
                  ></h3>
                  <div
                    v-for="(event, index) in dayEvents(column)"
                    :key="index"
                    class="mb-1 d-flex justify-center"
                  >
                    <v-chip small :color="eventColor(event)">
                      <v-icon x-small class="mr-1">
                        {{ getEventTypeIcon(event.type) }}
                      </v-icon>
                      <b>{{ event.name }}</b>
                    </v-chip>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="total pa-0 pt-1 kpi-cell d-flex align-center justify-center"
      >
        <span class="d-block day-name grey--text text--darken-3 text-uppercase">
          {{ $t("total") }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as Utils from "@/utils.js";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "a-rms-strategy-availability-columns",
  components: {},
  props: {
    columns: Array,
    page: Number,
    days_per_screen: Number,
    total_days: Number
  },
  computed: {
    styleRow() {
      let width = (this.total_days / this.days_per_screen) * 100;
      if (width < 100) {
        width = 100;
      }
      return (
        "transform: translateX(calc(((-100%) * " +
        this.days_per_screen +
        " * " +
        this.page +
        ") / " +
        this.total_days +
        "));" +
        "width: " +
        width +
        "%"
      );
    },
    ...mapState({
      rms: state => state.rms,
      calendar_store: state => state.calendar
    })
  },
  methods: {
    isHovered(date) {
      return this.rms.strategy_date_hover === date;
    },
    getDayFromIndex(index) {
      return this.columns[index];
    },
    isWeekend(index) {
      return Vue.moment(this.getDayFromIndex(index)).isoWeekday() >= 5;
    },
    bgCellClass(index) {
      let classes = [];
      if (this.isWeekend(index)) {
        classes.push("weekend");
      }
      if (index === 0) {
        classes.push("first_column");
      }
      if (this.isHovered(index)) {
        classes.push("kpi-hover");
      }
      return classes.join(" ");
    },
    getFriendlyDateTop(date) {
      return date
        ? Vue.moment(date)
            .format("ddd D")
            .replace(".", "")
        : "";
    },
    getFriendlyDateBottom(date) {
      return date
        ? Vue.moment(date)
            .format("MMM 'YY")
            .replace(".", "")
        : "";
    },
    getFriendlyDay(date) {
      return date ? Utils.getFriendlyDay(date) : "";
    },
    dayEvents(date) {
      return date && this.calendar_store.hotel_events
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(date).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
    },
    dayImpacts(date) {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          color: i.color,
          count: this.dayEvents(date).filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    eventColor(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    label(date) {
      return Utils.getFriendlyDayStrategy(date, true);
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    }
  },
  mounted() {
    if (this.current_hotel) {
      this.$store
        .dispatch("getHotelEvents", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment().format("YYYY")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.spacer {
  min-width: 200px;
  max-width: 200px;
  z-index: 1;
}
.total {
  min-width: 100px;
  max-width: 100px;
  z-index: 1;
}
.kpi-cell {
  height: $minHeightColRow;
  border-top: $borderType $borderColorV;
  border-left: $borderType $borderColorV;
  border-right: $borderType $borderColorV;
  border-bottom: 3px solid transparent;
  display: block;
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  position: relative;

  &.weekend {
    background: rgba(219, 198, 77, 0.1);
  }
  &.kpi-hover {
    border-bottom: 3px solid #2d6ece;
  }
}
.days-container {
  overflow: hidden;
  .days-wrapper {
    transition: transform 0.2s;
    transition-timing-function: ease;
  }
}
.events {
  position: absolute;
  right: 5px;
  top: 12px;
}
</style>
