<template>
  <!-- RATE DATE TO COMPARE -->
  <div :id="$options.name">
    <v-row class="mt-n4">
      <!-- <a-card> yearly_calendar: {{ yearly_calendar }} </a-card> -->
      <v-col cols="12" class="pt-0">
        <a-rms-year-sales
          ref="a-rms-year-sales"
          :tab_view="tab_view"
        ></a-rms-year-sales>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aRmsYearSales from "@/components/rms/axel/aRmsYearSales";

export default {
  name: "rms-year-sales-app",
  components: {
    aRmsYearSales
  },
  props: {
    tab_view: String
  },
  data: vm => ({
    init_date: null
  }),
  watch: {
    current_hotel() {
      if (!this.extraction_id) {
        this.setDefault();
      }
    }
  },
  methods: {
    setDefault() {
      // Must set init_date as today using moment.js
      this.init_date = Vue.moment().format("YYYY-MM-DD");
    },
    ...mapActions([])
  },
  mounted() {},
  computed: {
    ...mapState({}),
    ...mapGetters({
      current_hotel: "current_hotel",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days"
    })
  }
};
</script>
<style scoped></style>
