<template>
  <div class="full-width d-flex justify-center mb-2">
    <vue-ellipse-progress
      :loading="loading"
      :progress="progress"
      :legendValue="legendValue"
      :color="color"
      :thickness="8"
      :empty-thickness="10"
      :size="size"
      empty-color="#dedede"
      dash="strict 25 0.95"
      lineMode="out -5"
      animation="loop 700 1000"
      :fontSize="font_size"
      letterSpacing="0px"
      fontWeight="500"
      font-color="#000000"
    >
      <template v-slot:default="{ counterTick: { currentValue } }">
        <v-icon class="title d-block mb-4" color="grey lighten-1" v-if="icon">
          {{ icon }}
        </v-icon>
        <template v-if="custom_value">
          <span
            :class="{ 'mt-6 d-inline-block': !icon }"
            v-html="custom_value"
          ></span>
        </template>
        <template v-else>
          <span :class="{ 'mt-6 d-inline-block': !icon }">
            {{ currentValue }}
          </span>
        </template>
        <span class="text-h5 grey--text text--darken-1 font-weight-regular">
          {{ text }}
        </span>
        <p v-if="increment !== 0" class="mb-0 mt-0 grey--text subtitle-1">
          <a-increment
            :value="vs_value"
            :kpi="kpi"
            :increment="increment"
            :increment_type="'percent'"
          ></a-increment>
        </p>
      </template>
      <!-- <template v-slot:legend-value="{ text }">
        <span class="text-h5 grey--text text--darken-1 font-weight-regular">
          {{ text }}
        </span>
      </template>
      <template v-slot:legend-caption>
        <a-increment
          :value="vs_value"
          :kpi="kpi"
          :increment="increment"
          :increment_type="'percent'"
        ></a-increment>
      </template> -->
    </vue-ellipse-progress>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aIncrement from "@/components/aIncrement";

export default {
  name: "a-ellipse",
  components: {
    aIncrement
  },
  computed: {
    size() {
      var size = 170;
      if (this.$vuetify.breakpoint.xsOnly) {
        size = 170;
      }
      if (this.$vuetify.breakpoint.smOnly) {
        size = 170;
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        size = 170;
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        size = 170;
      }
      if (this.$vuetify.breakpoint.xlOnly) {
        size = 170;
      }
      return size;
    },
    font_size() {
      var size = "1.5rem";
      if (this.$vuetify.breakpoint.xsOnly) {
        size = "1.5rem";
      }
      if (this.$vuetify.breakpoint.smOnly) {
        size = "1.5rem";
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        size = "1.5rem";
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        size = "2.125rem";
      }
      if (this.$vuetify.breakpoint.xlOnly) {
        size = "2.125rem";
      }
      return size;
    },
    progress() {
      return !isNaN(this.percent)
        ? this.percent > 100
          ? 200 - this.percent
          : this.percent
        : 0;
    },
    legendValue() {
      return !isNaN(this.done) ? this.done : 0;
    },
    ...mapState({
      app_width: state => state.app_width
    })
  },
  props: {
    kpi: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: ""
    },
    done: {
      type: Number,
      default: 0
    },
    percent: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: undefined
    },
    custom_value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    increment: {
      type: Number,
      default: 0
    },
    vs_value: {
      type: Number,
      default: 0
    }
  }
};
</script>
