<template>
  <div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          no_title
          class="a-rms-day pa-1 mb-1"
          :class="card_class(hover)"
          @click="getDayDetails"
        >
          <v-card-text class="pa-2 pb-0">
            <div
              class="a-rms-day-row-day d-flex align-center justify-start pb-2"
            >
              <span class="mr-2 a-rms-day-title">
                {{ getFriendlyDay }}
              </span>
              <v-icon v-if="has_comments" color="accent" class="mr-2" x-small
                >mdi-comment-outline</v-icon
              >
              <v-chip v-if="isToday" dark x-small color="blue" class="mr-2">{{
                $t("today")
              }}</v-chip>
              <a-weather-day></a-weather-day>
              <v-spacer></v-spacer>
              <div
                class="d-flex justify-center align-center pt-2 mt-n3"
                :class="events_class"
                v-if="calendar_store"
              >
                <v-tooltip
                  color="black"
                  bottom
                  v-for="event in events"
                  :key="event.name"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      x-small
                      text-color="white"
                      :color="eventColor(event)"
                      class="mr-1"
                    >
                      <v-icon x-small class="mr-1">
                        {{ getEventTypeIcon(event.type) }}
                      </v-icon>
                      <b>{{ event.alias.slice(0, 10) }}</b>
                    </v-chip>
                  </template>
                  <span>{{ event.alias }}</span>
                </v-tooltip>
              </div>
            </div>
            <v-row no-gutters class="py-0">
              <v-col cols="12" sm="3" class="text-center">
                <div class="rounded-lg grey lighten-4 pa-1">
                  <a-rms-day-header
                    :labels="[
                      'PVP',
                      'MKTD',
                      'RK',
                      day_data.otb.mlos > 0 ? 'MLOS' : null
                    ]"
                  ></a-rms-day-header>
                  <div class="a-rms-day-row mb-1" v-if="day_data.otb">
                    <v-row no-gutters>
                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.pvp"
                          kpi="pvp"
                          x_small
                        ></a-kpi>
                      </v-col>
                      <v-col cols="3">
                        <a-kpi
                          :class="mktd_class"
                          :value="day_data.otb.market_demand"
                          kpi="market_demand"
                          x_small
                          v-if="day_data.otb.market_demand > 0"
                        ></a-kpi>
                      </v-col>
                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.compset_ranking"
                          kpi="compset_ranking"
                          x_small
                        ></a-kpi>
                      </v-col>

                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.mlos"
                          kpi=".mlos"
                          x_small
                          v-if="day_data.otb.mlos > 0"
                        ></a-kpi>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="rounded-lg grey lighten-4  pa-1 mt-1 ">
                  <a-rms-day-header
                    :labels="['RN Disp', 'CUP', 'GPC', 'GPB']"
                  ></a-rms-day-header>
                  <div class="a-rms-day-row" v-if="day_data.otb">
                    <v-row no-gutters>
                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.free"
                          kpi="available"
                          x_small
                        ></a-kpi>
                      </v-col>
                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.cupos"
                          kpi="cupos"
                          x_small
                        ></a-kpi>
                      </v-col>
                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.groups_confirmed"
                          kpi="groups_confirmed"
                          x_small
                        ></a-kpi>
                      </v-col>
                      <v-col cols="3">
                        <a-kpi
                          class="font-weight-bold"
                          :value="day_data.otb.groups_locked"
                          kpi="groups_locked"
                          x_small
                        ></a-kpi>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="9">
                <div class="pl-3 d-flex">
                  <a-rms-strategy-column-labels></a-rms-strategy-column-labels>
                  <a-rms-strategy-column-values
                    period="otb"
                    :day_data="day_data"
                  ></a-rms-strategy-column-values>
                  <a-rms-strategy-column-values
                    v-for="period in periods"
                    :key="'period_' + period.code"
                    :period="period.code"
                    :day_data="day_data"
                  ></a-rms-strategy-column-values>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

var numeral = require("numeral");
import * as Utils from "@/utils.js";
import aRmsDayHeader from "@/components/rms/aRmsDayHeader";
import aRmsStrategyColumnLabels from "@/components/rms/aRmsStrategyColumnLabels";
import aRmsStrategyColumnValues from "@/components/rms/aRmsStrategyColumnValues";
import aKpi from "@/components/rms_ui/aKpi";
import aWeatherDay from "@/components/rms_ui/aWeatherDay";
import aRmsDayRow from "@/components/rms/hotelsdot/aRmsDayRow";
import aRmsDayRowPickup from "@/components/rms/hotelsdot/aRmsDayRowPickup";
import aRmsDayRowVs from "@/components/rms/hotelsdot/aRmsDayRowVs";

export default {
  name: "a-rms-day",
  components: {
    aRmsDayHeader,
    aRmsStrategyColumnLabels,
    aRmsStrategyColumnValues,
    aKpi,
    aWeatherDay
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    detail_days: {
      type: Array,
      default: () => []
    },
    event_period: {
      type: Array,
      default: () => []
    }
  },
  data: vm => ({
    title: "",
    subscribed_modules: ["rms_all_kpis_daily"]
  }),
  watch: {},
  methods: {
    getDayDetails() {
      this.$emit("getDayDetails", this.item.date);
    },
    hoverRow(index) {
      this.$emit("hoverRow", index);
    },
    eventColor(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    card_class(hover) {
      let classes = [];
      if (this.isToday) classes.push("selected");
      if (Vue.moment(this.item.date).isBefore(undefined, "day"))
        classes.push("previous");
      if (this.day_data.otb.occupancy > 99.9) classes.push("soldout");
      if (
        Vue.moment(this.item.date).isoWeekday() === 5 ||
        Vue.moment(this.item.date).isoWeekday() === 6 ||
        Vue.moment(this.item.date).isoWeekday() === 7
      )
        classes.push("weekend");

      classes.push(hover ? "hovered elevation-3" : "elevation-1");
      classes.push(this.color_class);
      return classes.join(" ");
    },
    ...mapActions([])
  },
  computed: {
    periods() {
      let periods = [];
      if (this.rms.show_bud) periods.push({ code: "bud" });
      if (this.rms.show_stly) periods.push({ code: "stly" });
      if (this.rms.show_ly) periods.push({ code: "ly" });
      if (this.rms.show_pickup_1) periods.push({ code: "pickup_1" });
      if (this.rms.show_pickup_2) periods.push({ code: "pickup_2" });
      if (this.rms.show_pickup_3) periods.push({ code: "pickup_3" });
      if (this.rms.show_pickup_4) periods.push({ code: "pickup_4" });
      if (this.rms.show_pickup_5) periods.push({ code: "pickup_5" });
      if (this.rms.show_pickup_6) periods.push({ code: "pickup_6" });
      return periods;
    },
    isToday() {
      return this.item.date === Vue.moment().format("YYYY-MM-DD");
    },
    mktd_class() {
      let clas = ["font-weight-bold"];
      if (this.day_data.otb.market_demand >= this.rms.mktd_max)
        clas.push("green--text");
      if (this.day_data.otb.market_demand <= this.rms.mktd_min)
        clas.push("red--text");
      return clas.join(" ");
    },
    events() {
      return this.item && this.item.date
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.item.date).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
    },
    isSelected() {
      return this.detail_days.indexOf(this.item.date) >= 0;
    },
    getFriendlyDay() {
      return this.item ? Utils.getFriendlyDay(this.item.date) : "";
    },
    day_data() {
      return this.item ? this.getRmsDayKpis(this.item.date) : {};
    },
    has_comments() {
      return this.day_data.otb.has_comments;
    },
    color_class() {
      if (!this.day_data || !this.day_data.otb || !this.day_data.bud)
        return "neutral";

      let diff = this.day_data.otb.occupancy - this.day_data.bud.occupancy;
      if (diff > 0) return "positive";
      if (diff < 0) return "negative";
      return "neutral";
    },
    events_class() {
      let classes = [];
      if (Vue.moment(this.item.date).isBefore(undefined, "day"))
        classes.push("previous");
      if (this.day_data.otb.occupancy > 99.9) classes.push("soldout");

      return classes.join(" ");
    },
    ...mapState({
      rms: state => state.rms,
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      getRmsDayKpis: "getRmsDayKpis",
      getDayEvents: "getDayEvents"
    })
  },
  mounted() {}
};
</script>

<style lang="scss">
.a-rms-day {
  border-left: 4px solid grey;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  transition: all 0.5s;

  &.weekend {
    background-color: #fffef4;
  }
  &:not(.hovered).previous,
  &:not(.hovered).soldout {
    opacity: 0.7;
    color: #666;
  }
  &.positive {
    border-left-color: rgb(23, 171, 112);
    // background-color: rgb(241, 255, 241);
  }
  &.negative {
    border-left-color: rgb(172, 0, 0);
    // background-color: rgb(254, 241, 241);
  }
  &.neutral {
    border-left-color: orange;
    // background-color: rgb(255, 249, 241);
  }
  &.selected {
    // border-top: 4px solid blue;
    border-right: 5px solid #2196f3 !important;
    // .a-rms-day-title {
    //   color: rgb(57, 60, 209);
    // }
  }
}
.a-rms-day-row-events {
  &.previous,
  &.soldout {
    opacity: 0.7;
  }
}
</style>
