<template>
  <div class="text-center d-flex justify-center">
    <v-icon class="text-h4 py-0" color="grey lighten-3"
      >mdi-comment-off-outline</v-icon
    >
    <p class="subtitle-2 px-6 py-2 mb-0 grey--text ">
      {{ $t("no_reviews") }}
    </p>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "a-reputation-reviews",
  props: {},
  data: () => ({}),
  methods: {}
};
</script>

<style scoped></style>
