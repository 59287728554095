<template>
  <div>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-card-text
          class="pl-0 d-flex special-rates-container"
          v-if="days && days.length > 0"
        >
          <div class="app-right-side-container">
            <v-card elevation="0">
              <div class="d-flex pb-6">
                <div class="spacer"></div>
                <v-menu
                  v-model="goto_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      depressed
                      class="mr-4"
                      right
                      rounded
                      outlined
                      color="info"
                      v-on="on"
                    >
                      <v-icon left>mdi-calendar-search-outline</v-icon>
                      <span class="flex-grow-1 text-left mr-5">
                        {{ $t("go_to_day") }}
                      </span>
                    </v-btn>
                  </template>
                  <v-date-picker
                    color="accent"
                    v-model="goto_date"
                    @change="changeGotoDate"
                    first-day-of-week="1"
                    :min="minDate"
                    :max="maxDate"
                  ></v-date-picker>
                </v-menu>
                <v-btn
                  depressed
                  class="mr-4"
                  right
                  rounded
                  outlined
                  color="info"
                  @click="openBulkModal"
                >
                  <v-icon left>mdi-pencil-outline</v-icon>
                  {{ $t("bulk_edit") }}
                </v-btn>
                <div class="spacer"></div>
                <v-btn
                  depressed
                  rounded
                  color="info"
                  @click="openStopSellModal"
                >
                  <v-icon left>mdi-lock-outline</v-icon>
                  {{ $t("open/close_rates") }}
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ml-4"
                      right
                      rounded
                      outlined
                      color="info"
                      @click="download"
                      icon
                      v-on="on"
                    >
                      <v-icon>mdi-download-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("download") }}
                </v-tooltip>
                <a-special-rates-bulk-edit></a-special-rates-bulk-edit>
                <a-special-rates-stop-sell-edit></a-special-rates-stop-sell-edit>
              </div>
            </v-card>
            <v-card elevation="0" class="pr-2">
              <a-special-rates-table
                :days="days"
                :rows="rows"
                :maxSlides="maxPages"
              ></a-special-rates-table>
              <v-btn
                depressed
                rounded
                color="info"
                class="mr-4 ml-4"
                @click="openAddModal"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("add_special_rate") }}
              </v-btn>
            </v-card>
          </div>
        </v-card-text>
      </template>
      <template v-slot:loading>
        <v-card class="mt-2" loading>
          <v-card-text>
            <v-skeleton-loader type="table" class="mx-auto">
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aSpecialRatesTable from "@/components/special-rates/aSpecialRatesTable.vue";
import aSpecialRatesBulkEdit from "@/components/special-rates/aSpecialRatesBulkEdit.vue";
import aSpecialRatesStopSellEdit from "@/components/special-rates/aSpecialRatesStopSellEdit.vue";

export default {
  name: "a-special-rates",
  components: {
    aBaseComponent,
    aSpecialRatesTable,
    aSpecialRatesBulkEdit,
    aSpecialRatesStopSellEdit
  },
  data: () => ({
    pagination: {
      page: 0
    },
    goto_menu: null,
    goto_date: null,
    subscribed_modules: ["specialrates_table_data"]
  }),
  computed: {
    page() {
      return (
        this.$store.state.specialrates && this.$store.state.specialrates.page
      );
    },
    maxDays() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2;
        case "sm":
        case "md":
          return 7;
        default:
          return 14;
      }
    },
    maxPages() {
      return Math.round(365 / this.maxDays);
    },
    days() {
      return this.$store.state.specialrates.specialRatesTableData.days;
    },
    rows() {
      return this.$store.state.specialrates.specialRatesTableData.rows;
    },
    hoveredLabel() {
      return (
        this.$store.state.specialrates &&
        this.$store.state.specialrates.hoveredRate
      );
    },
    hasChanged() {
      return this.specialRatesChangesPending;
    },
    specialRatesChangesPending() {
      return (
        this.$store.getters && this.$store.getters.specialRatesChangesPending
      );
    },
    minDate() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    maxDate() {
      return Vue.moment(this.minDate)
        .add(this.maxDays * this.maxPages, "days")
        .format("YYYY-MM-DD");
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    openBulkModal() {
      this.$store.dispatch("setSpecialRatesBulkFormType", "bulk");
      this.$store.dispatch("setSpecialRatesBulkFormOpen", true);
    },
    openAddModal() {
      this.$store.dispatch("setSpecialRatesBulkFormType", "add");
      this.$store.dispatch("setSpecialRatesBulkFormOpen", true);
    },
    openStopSellModal() {
      this.$store.dispatch("setSpecialRatesStopSellFormOpen", true);
    },
    loaded() {
      return true;
    },
    changeGotoDate() {
      this.goto_menu = false;
      const days = Vue.moment(this.goto_date).diff(
        Vue.moment(this.minDate),
        "days"
      );
      this.$store.dispatch(
        "updateSpecialRatesPage",
        Math.floor(days / this.maxDays)
      );
    },
    download() {
      this.$store.dispatch("downloadSpecialRates", {
        hotel_id: this.current_hotel.id,
        year: Vue.moment(this.minDate).format("YYYY")
      });
    }
  },
  watch: {
    goto_menu(newValue) {
      if (newValue) {
        this.goto_date = this.days[0];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$chartHeight: 60px;
$weekendBg: rgba(219, 198, 77, 0.1);
.v-messages {
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}

.special-rates-container {
  .spacer:first-child {
    max-width: 300px;
  }
  .col-weekend {
    background-color: $weekendBg;
    &:hover {
      background-color: #f5fdff;
    }
  }
  .app-left-side-container {
    width: $widthLeftSide;
    padding-top: $chartHeight;
  }
  .app-right-side-container {
    width: 100%;
  }
}
</style>
