var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-alert',{key:'a-hightlight-item' + _vm.hightlight.id,staticClass:"py-2",class:_vm.clas,attrs:{"color":_vm.getColorFromPriority(_vm.hightlight.priority),"elevation":"1","border":"left","colored-border":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0 shrink"},[_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":"","outlined":hover,"color":hover ? 'white' : 'grey lighten-3'},on:{"click":function($event){return _vm.seeHighlight(_vm.hightlight)}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDone(_vm.hightlight)),expression:"isDone(hightlight)"}],attrs:{"small":"","color":"green"}},[_vm._v("mdi-check")])],1)],1),_c('v-col',{staticClass:"py-0 grow body-2"},[_c('v-row',{attrs:{"align":"center"}},[(_vm.groupDisplay)?_c('v-col',{staticClass:"pa-0 shrink"},[_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"v-list-item__avatar subtitle-2 white--text text-uppercase justify-center mr-0",attrs:{"small":"","size":"30","depressed":"","color":_vm.getHotelById(_vm.hightlight.hotel_id).settings.color}},on),[_vm._v(_vm._s(_vm.getHotelById(_vm.hightlight.hotel_id).settings.abbreviation)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getHotelById(_vm.hightlight.hotel_id).name))]),_c('br'),_c('v-rating',{attrs:{"readonly":"","small":"","dense":"","value":_vm.getHotelById(_vm.hightlight.hotel_id).settings.stars}})],1)],1):_vm._e(),_c('v-col',{staticClass:"pa-0 grow body-2"},[(_vm.show_type)?_c('span',{staticClass:"grey--text overline"},[_vm._v(_vm._s(_vm.$t(_vm.hightlight.literal_type_name, _vm.hightlight.extra))+" ")]):_vm._e(),(_vm.show_type)?_c('br'):_vm._e(),_c('span',{class:{ 'grey--text': _vm.isDone(_vm.hightlight) },style:(_vm.isDone(_vm.hightlight) ? 'text-decoration: line-through' : '')},[_vm._v(_vm._s(_vm.$t(_vm.hightlight.literal_message, _vm.hightlight.extra))+" ")])])],1)],1),_c('v-col',{staticClass:"py-0 shrink"},[_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"info"},on:{"click":function($event){return _vm.handle_function_call(
                  _vm.hightlight.js_function,
                  _vm.hightlight.js_function_params,
                  _vm.hightlight
                )}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-crosshairs")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("see_detail")))])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }