<template>
  <a-page :image_source="image_source" :image_height="image_height">
    <div class="row">
      <v-col cols="12" md="12">
        survey
      </v-col>
    </div>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";

export default {
  components: { aPage },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 200 //Mandatory number
  }),
  methods: {},
  watch: {},
  computed: {
    ...mapState({
      //current_hotel: state => state.hotel.hotel,
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  created() {}
};
</script>
