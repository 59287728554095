<template>
  <div id="trial-management-app">
    <!-- PAGE HEADER -->
    <v-card id="trial-filter" dark class="elevation-0">
      <v-card-text class="pb-1 pt-0 px-0">
        <v-row>
          <v-col class="pb-4 pt-0 pt-sm2" cols="12" sm="12" lg="12">
            <v-btn
              v-if="$vuetify.breakpoint.xsOnly"
              small
              block
              rounded
              text
              color="white"
              @click="filters_opened = !filters_opened"
            >
              <template v-if="filters_opened">{{
                $t("hide_filters")
              }}</template>
              <template v-else>{{ $t("show_filters") }}</template>
              <v-spacer></v-spacer>
              <v-icon dark @click="filters_opened = false" v-if="filters_opened"
                >mdi-chevron-up</v-icon
              >
              <v-icon dark @click="filters_opened = true" v-else
                >mdi-chevron-down</v-icon
              >
            </v-btn>
            <div
              class="d-flex justify-space-between"
              style="flex-wrap: wrap"
              v-if="
                !only_trial && (filters_opened || $vuetify.breakpoint.smAndUp)
              "
            >
              <div class="pt-3">
                <div class="v-messages theme--dark ml-2">
                  <div class="v-messages__wrapper">
                    {{ $t("request_type") }}
                  </div>
                </div>
                <v-btn-toggle
                  v-model="filter_type"
                  color="secondary accent-3"
                  rounded
                  dense
                  block
                  outlined
                  background-color="transparent"
                  dark
                >
                  <v-tooltip
                    v-for="(type, code) in types"
                    :key="code"
                    color="black"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        outlined
                        dark
                        color="white"
                        :value="code"
                        class="p-0"
                        v-on="on"
                      >
                        <v-icon class="title">{{ type.icon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t(type.literal) }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
              <div class="pt-3">
                <div class="v-messages theme--dark ml-2">
                  <div class="v-messages__wrapper text-right">
                    {{ $t("fast_filters") }}
                  </div>
                </div>
                <v-btn-toggle
                  v-model="fast_filters"
                  color="secondary accent-3"
                  rounded
                  dense
                  block
                  outlined
                  background-color="transparent"
                  dark
                >
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        outlined
                        dark
                        color="white"
                        value="call_pending"
                        class="p-0"
                        v-on="on"
                      >
                        <v-icon class="title">mdi-phone</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("call_pending") }}</span>
                  </v-tooltip>
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        outlined
                        dark
                        color="white"
                        value="mirai_pending"
                        class="p-0"
                        v-on="on"
                      >
                        <v-icon class="title"
                          >mdi-alpha-m-circle-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>{{ $t("mirai_pending") }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- end PAGE HEADER -->

    <!-- LIST -->
    <v-card>
      <v-app-bar flat color="white">
        <v-toolbar-title class="title font-weight-bold">
          <template v-if="only_trial">{{ $t("trial_requests") }}</template>
          <template v-else-if="selected_type">{{
            $t(types[selected_type].literal)
          }}</template>
          <template v-else>{{ $t("web_requests") }}</template>
          <v-spacer></v-spacer>
        </v-toolbar-title>
      </v-app-bar>
      <v-card-text>
        <a-base-component :subscribed_modules="subscribed_modules">
          <template v-slot:default>
            <v-data-table
              :headers="headers"
              :items="filtered_requests"
              hide-default-footer
              disable-pagination
              class="elevation-0"
              :item-class="getDatatableRowClass"
              mobile-breakpoint="1400"
            >
              <template v-slot:[`item.type`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-badge
                        top
                        :color="itemColor(item)"
                        dot
                        offset-x="5"
                        offset-y="5"
                        v-if="showBadge(item)"
                      >
                        <v-icon>{{ types[item.type].icon }}</v-icon>
                      </v-badge>
                      <v-icon v-else>{{ types[item.type].icon }}</v-icon>
                    </div>
                  </template>
                  <span>{{ $t(types[item.type].literal) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.fullname`]="{ item }">
                <b>{{
                  item.fullname | truncate($vuetify.breakpoint.lgOnly ? 20 : 50)
                }}</b>
                <br />
                {{ item.username }} <br />
                {{ item.phone_number }}
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <template
                  >{{ item.date | formatDateFull }} <br />
                  {{ item.date | formatTime }}</template
                >
              </template>
              <template v-slot:[`item.validation`]="{ item }">
                <v-chip
                  small
                  color="secondary"
                  v-if="item.type == 'rate-trial' && itemValidated(item)"
                  >{{ $t("email_validated") }}
                </v-chip>
                <v-chip small v-else-if="item.type == 'rate-trial'"
                  >{{ $t("email_no_valididated") }}
                </v-chip>
              </template>
              <template v-slot:[`item.booking_engine`]="{ item }">
                {{ item.booking_engine }} <br />
                <v-chip small v-if="isMirai(item) && itemPendingMirai(item)"
                  >ID {{ $t("pending") }}
                </v-chip>
                <v-chip
                  small
                  v-else-if="isMirai(item) && item.external_id"
                  color="secondary"
                  >{{ item.external_id }}
                </v-chip>
              </template>
              <template v-slot:[`item.progress`]="{ item }">
                <v-stepper>
                  <v-stepper-header>
                    <!-- STEP 1 if TRIAL -->
                    <v-tooltip bottom v-if="item.type == 'rate-trial'">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-stepper-step
                            step="1"
                            color="secondary"
                            :complete="completedStep1(item)"
                            complete-icon="mdi-numeric-1"
                          >
                          </v-stepper-step>
                        </div>
                      </template>
                      <center>
                        <span>{{ $t("account_data") }}</span
                        ><br />
                        <span>{{ item.signup_datetime | formatDatetime }}</span>
                      </center>
                    </v-tooltip>
                    <v-divider v-if="item.type == 'rate-trial'"></v-divider>
                    <!-- STEP 2 if TRIAL -->
                    <v-tooltip
                      bottom
                      v-if="item.type == 'rate-trial' && item.call_me != 1"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-stepper-step
                            step="2"
                            color="secondary"
                            :complete="completedStep2(item)"
                            complete-icon="mdi-numeric-2"
                          >
                          </v-stepper-step>
                        </div>
                      </template>
                      <center>
                        <span>{{ $t("setup_your_hotel") }}</span
                        ><br />
                        <span>{{
                          item.hotel_conf_datetime | formatDatetime
                        }}</span>
                      </center>
                    </v-tooltip>
                    <v-divider
                      v-if="item.type == 'rate-trial' && item.call_me != 1"
                    ></v-divider>
                    <!-- STEP 3 if TRIAL -->
                    <v-tooltip
                      bottom
                      v-if="item.type == 'rate-trial' && item.call_me != 1"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-stepper-step
                            step="3"
                            color="secondary"
                            :complete="completedStep3(item)"
                            complete-icon="mdi-numeric-3"
                          >
                          </v-stepper-step>
                        </div>
                      </template>
                      <center>
                        <span>{{ $t("setup_competitive_set") }}</span
                        ><br />
                        <span>{{
                          item.compset_conf_datetime | formatDatetime
                        }}</span>
                      </center>
                    </v-tooltip>
                    <!-- CALL REQUEST -->
                    <v-tooltip bottom v-if="item.call_me == 1">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-stepper-step
                            step="1"
                            :color="item.contact_datetime ? 'secondary' : 'red'"
                            complete
                            complete-icon="mdi-phone"
                          >
                          </v-stepper-step>
                        </div>
                      </template>
                      <center>
                        <span>{{ $t("contact_request") }}</span
                        ><br />
                        <span>{{
                          item.contact_req_datetime | formatDatetime
                        }}</span>
                      </center>
                    </v-tooltip>
                    <v-divider v-if="item.call_me == 1"></v-divider>
                    <!-- CALL DONE -->
                    <v-tooltip bottom v-if="item.call_me == 1">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-stepper-step
                            step="2"
                            :color="item.contact_datetime ? 'green' : 'grey'"
                            complete
                            complete-icon="mdi-phone"
                          >
                          </v-stepper-step>
                        </div>
                      </template>
                      <center>
                        <span>{{
                          item.contact_datetime
                            ? $t("contact_done")
                            : $t("contact_pending")
                        }}</span
                        ><br />
                        <span>{{
                          item.contact_datetime | formatDatetime
                        }}</span>
                      </center>
                    </v-tooltip>
                  </v-stepper-header>
                </v-stepper>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                  <!-- Change external ID -->
                  <v-tooltip
                    bottom
                    v-if="isMirai(item) && item.type == 'rate-trial'"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="showChangeExternalId(item)"
                          icon
                          color="secondary"
                          ><v-icon small
                            >mdi-alpha-m-circle-outline</v-icon
                          ></v-btn
                        >
                      </div>
                    </template>
                    <span>{{ $t("mirai_configure") }}</span>
                  </v-tooltip>
                  <!-- Set contact done -->
                  <v-tooltip
                    bottom
                    v-if="!only_trial && itemPendingContact(item)"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="setContactDone(item)"
                          icon
                          color="secondary"
                          ><v-icon small>mdi-phone</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span>{{ $t("set_as") + " " + $t("contact_done") }}</span>
                  </v-tooltip>
                  <!-- Set contact pending -->
                  <v-tooltip bottom v-if="!only_trial && item.contact_datetime">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="setContactNotDone(item)"
                          icon
                          color="secondary"
                          ><v-icon small>mdi-phone-off</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span>{{
                      $t("set_as") + " " + $t("contact_pending")
                    }}</span>
                  </v-tooltip>
                  <!-- Configure -->
                  <v-tooltip
                    bottom
                    v-if="!only_trial && item.type == 'rate-trial'"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="showConfiguration(item)"
                          icon
                          color="secondary"
                          ><v-icon small>mdi-cog</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span>{{ $t("configure") }}</span>
                  </v-tooltip>
                  <!-- Change Comments -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="showChangeComments(item)"
                          icon
                          color="secondary"
                        >
                          <v-icon v-if="item.comments" small
                            >mdi-comment-text-outline</v-icon
                          >
                          <v-icon v-else small>mdi-comment-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("comment_trial") }}</span>
                  </v-tooltip>
                  <!-- Disable Trial -->
                  <v-tooltip bottom v-if="!only_trial && canDisable(item)">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="confirmDisableTrial(item)"
                          icon
                          color="secondary"
                          ><v-icon small>mdi-cancel</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span>{{ $t("disable_trial") }}</span>
                  </v-tooltip>
                  <!-- Enable Trial -->
                  <v-tooltip bottom v-if="!only_trial && canEnable(item)">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="confirmEnableTrial(item)"
                          icon
                          color="secondary"
                          ><v-icon small>mdi-play</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span>{{ $t("enable_trial") }}</span>
                  </v-tooltip>
                  <!-- Delete Trial -->
                  <v-tooltip bottom v-if="!only_trial && canDelete(item)">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click.stop="confirmDeleteTrial(item)"
                          icon
                          color="secondary"
                          ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span>{{ $t("delete_trial") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-tooltip bottom dark color="#333333" v-if="!only_trial">
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mt-5 mb-1"
                  v-show="!show_add"
                  @click="showAdd"
                  rounded
                  color="secondary"
                  v-on="on"
                >
                  <v-icon small>mdi-plus</v-icon>
                  {{ $t("add_environment") }}
                </v-btn>
              </template>
              <span>
                {{ $t("new_environment") }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:loading>
            <v-card class="mt-2">
              <v-card-text>
                <v-skeleton-loader
                  type="table-thead"
                  class="mx-auto"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="table-tbody"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </template>
        </a-base-component>
      </v-card-text>
    </v-card>
    <!-- CHANGE EXTERNAL ID -->
    <v-dialog
      v-model="show_change_external_id"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="460"
      scrollable
    >
      <v-card class="">
        <v-card-title class="headline justify-center" v-if="item_to_edit"
          >{{ item_to_edit.hotel_name }}
          <v-spacer></v-spacer>
          <v-btn
            style="position: absolute; top: 13px; right: 10px"
            icon
            @click="show_change_external_id = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="white">
          <v-form v-model="external_id_valid" ref="external_id_form">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <v-text-field
                  clearable
                  v-model="external_id"
                  :label="$t('mirai_id')"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4 px-6">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            :disabled="!external_id_valid"
            :loading="saving"
            @click="changeExternalId()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE COMMENTS -->
    <v-dialog
      v-model="show_change_comments"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="460"
      scrollable
    >
      <v-card class="">
        <v-card-title class="headline justify-center" v-if="item_to_edit"
          >{{ item_to_edit.hotel_name }}
          <v-spacer></v-spacer>
          <v-btn
            style="position: absolute; top: 13px; right: 10px"
            icon
            @click="show_change_comments = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="white">
          <v-form v-model="comments_valid" ref="comments_form">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <v-textarea
                  clearable
                  v-model="comments"
                  :label="$t('comment_trial')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4 px-6">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            :disabled="!comments_valid"
            :loading="saving"
            @click="changeComments()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CONFIGURE HOTEL -->
    <v-dialog
      v-model="show_configuration"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      scrollable
    >
      <v-card class="">
        <v-card-title class="headline justify-center" v-if="item_to_edit"
          >{{ item_to_edit.hotel_name }}
          <v-spacer></v-spacer>
          <v-btn
            style="position: absolute; top: 13px; right: 10px"
            icon
            @click="show_configuration = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="white">
          <v-form
            v-model="configuration_valid"
            lazy-validation
            ref="configuration_form"
          >
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <label for="">{{ $t("hotel").toUpperCase() }}</label>
                <a-booking-url-field
                  v-model="hotel_booking_url"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
              </v-col>
              <v-col cols="12" sm="12">
                <label for="">{{ $t("competitors") }}</label>
                <a-booking-url-field
                  v-for="i in 5"
                  :key="'competitor_booking_urls_' + (i - 1)"
                  v-model="competitor_booking_urls[i - 1]"
                  :label="$t('hotel_booking_url')"
                  hint="www.booking.com/hotel/es/nombre-hotel.es.html"
                ></a-booking-url-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4 px-6">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            :disabled="!configuration_valid"
            :loading="saving"
            @click="changeConfiguration()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD HOTEL -->
    <v-dialog
      v-model="show_add"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      scrollable
    >
      <v-card class="">
        <v-card-title class="headline justify-center"
          >{{ $t("add_environment") }}
          <v-spacer></v-spacer>
          <v-btn
            style="position: absolute; top: 13px; right: 10px"
            icon
            @click="show_add = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="white">
          <v-form v-model="add_valid" lazy-validation ref="add_form">
            <v-row class="mx-0">
              <v-col cols="12" sm="12">
                <label for="">{{ $t("fullname").toUpperCase() }}</label>
                <v-text-field
                  clearable
                  v-model="add_fullname"
                  :label="$t('fullname')"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <label for="">{{ $t("username").toUpperCase() }}</label>
                <v-text-field
                  clearable
                  v-model="add_email"
                  :label="$t('username')"
                  type="text"
                  :rules="email_rules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <label for="">{{ $t("hotel").toUpperCase() }}</label>
                <v-text-field
                  clearable
                  v-model="add_hotel_url"
                  :label="$t('hotel_url')"
                  type="text"
                  prefix="https://"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4 px-6">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            :disabled="!add_valid"
            :loading="saving"
            @click="add()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ENABLE/DISABLE TRIAL -->
    <v-dialog
      v-model="show_xable_trial"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit && show_xable_trial_action == 'enable'"
        >
          {{ $t("sure_enable_trial", { hotel_name: item_to_edit.hotel_name }) }}
        </v-card-text>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit && show_xable_trial_action == 'disable'"
        >
          {{
            $t("sure_disable_trial", { hotel_name: item_to_edit.hotel_name })
          }}
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="enableTrial(item_to_edit)"
            v-if="show_xable_trial_action == 'enable'"
          >
            {{ $t("enable") }}
          </v-btn>
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="disableTrial(item_to_edit)"
            v-if="show_xable_trial_action == 'disable'"
          >
            {{ $t("disable") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideXableTrial"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE TRIAL -->
    <v-dialog
      v-model="show_delete_trial"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          {{ $t("sure_delete_trial", { hotel_name: item_to_edit.hotel_name }) }}
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteTrial()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteTrial"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aBookingUrlField from "@/components/aBookingUrlField.vue";

export default {
  components: {
    aBaseComponent,
    aBookingUrlField
  },
  props: {
    only_trial: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    subscribed_modules: ["trial_requests"],
    types: {
      "rate-trial": {
        icon: "mdi-barcode-scan",
        literal: "rate_trial"
      },
      "bi-demo": {
        icon: "mdi-google-circles-extended",
        literal: "bi_demo"
      },
      "rate-demo": {
        icon: "mdi-tag-multiple-outline",
        literal: "rate_demo"
      },
      contact: {
        icon: "mdi-phone",
        literal: "contact_request"
      }
    },
    filter_type: null,
    fast_filters: null,
    filters_opened: false,
    show_change_external_id: false,
    show_change_comments: false,
    show_configuration: false,
    show_delete_trial: false,
    show_xable_trial: false,
    show_xable_trial_action: "",
    show_add: false,
    item_to_edit: null,
    external_id_valid: true,
    external_id: "",
    comments_valid: true,
    comments: "",
    saving: false,
    configuration_valid: true,
    add_valid: true,
    hotel_booking_url: "",
    competitor_booking_urls: [],
    add_email: "",
    add_fullname: "",
    add_hotel_url: ""
  }),
  methods: {
    getDatatableRowClass(item) {
      return this.itemFromQuery(item)
        ? "pulse a-datatable-row"
        : "a-datatable-row";
    },
    itemFromQuery(item) {
      return this.query_params.id && this.query_params.id == item.id;
    },
    itemPendingContact(item) {
      return item.call_me == 1 && !item.contact_datetime;
    },
    itemPendingMirai(item) {
      return (
        item.type == "rate-trial" &&
        (item.external_id == undefined || item.external_id == "")
      );
    },
    showBadge(item) {
      return (
        (item.type == "rate-trial" && item.status == 2) || item.status == 3
      );
    },
    itemColor(item) {
      if (item.status == 3) {
        return "success";
      }
      return "grey";
    },
    isMirai(item) {
      return item.booking_engine === "Mirai";
    },
    itemValidated(item) {
      // Email valid if status is VALIDATED, READY or DISABLED
      return item.status == 2 || item.status == 3 || item.status == -1;
    },
    canDisable(item) {
      // can disable is status is VALIDATED or READY
      return (
        item.type == "rate-trial" && (item.status == 2 || item.status == 3)
      );
    },
    canEnable(item) {
      // can enable if status is DISABLED
      return item.type == "rate-trial" && item.status == -1;
    },
    canDelete(item) {
      return item.type == "rate-trial" && item.status != 3;
    },
    completedStep1(item) {
      return item.signup_datetime !== null;
    },
    completedStep2(item) {
      return item.hotel_conf_datetime !== null;
    },
    completedStep3(item) {
      return item.compset_conf_datetime !== null;
    },
    setContactDone(item) {
      this.changeRequest(item, "setTrialContactDone");
    },
    setContactNotDone(item) {
      this.changeRequest(item, "setTrialContactPending");
    },
    enableTrial(item) {
      this.changeRequest(item, "setTrialEnabled");
      this.show_xable_trial = false;
      this.show_xable_trial_action = "";
    },
    disableTrial(item) {
      this.changeRequest(item, "setTrialDisabled");
      this.show_xable_trial = false;
      this.show_xable_trial_action = "";
    },
    changeRequest(item, method) {
      this.saving = true;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("wait_a_moment") + "...",
        text: this.$t("modifying_request"),
        color: "secondary"
      });
      const data = {
        id: item.id
      };

      this.$store
        .dispatch(method, data)
        .then(data => {
          this.saving = false;
          Vue.set(
            this.requests,
            this.requests.findIndex(i => i.id == item.id),
            data.data.request
          );
          this.$store.dispatch("setSnackbar", {
            title: this.$t("modified_request"),
            text: this.$t("modified_request_ok", {
              hotel_name: item.hotel_name
            }),
            color: "secondary"
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    },
    showChangeExternalId(item) {
      this.show_change_external_id = true;
      this.item_to_edit = item;
      this.external_id = item.external_id;
    },
    showChangeComments(item) {
      this.show_change_comments = true;
      this.item_to_edit = item;
      this.comments = item.comments;
    },
    showConfiguration(item) {
      this.show_configuration = true;
      this.item_to_edit = item;
      this.hotel_booking_url = item.hotel_booking_url;
      this.competitor_booking_urls = item.competitors_urls
        ? JSON.parse(item.competitors_urls)
        : [];
    },
    showAdd(item) {
      this.show_add = true;
      this.add_email = "";
      this.add_fullname = "";
      this.add_hotel_url = "";
    },
    confirmDeleteTrial(item) {
      this.show_delete_trial = true;
      this.item_to_edit = item;
    },
    hideDeleteTrial() {
      this.show_delete_trial = false;
      this.item_to_edit = {};
    },
    confirmEnableTrial(item) {
      this.show_xable_trial = true;
      this.show_xable_trial_action = "enable";
      this.item_to_edit = item;
    },
    confirmDisableTrial(item) {
      this.show_xable_trial = true;
      this.show_xable_trial_action = "disable";
      this.item_to_edit = item;
    },
    hideXableTrial() {
      this.show_xable_trial = false;
      this.show_xable_trial_action = "";
      this.item_to_edit = {};
    },
    changeExternalId() {
      if (this.external_id_valid) {
        this.saving = true;
        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("modifying_external_id"),
          color: "secondary"
        });
        const data = {
          id: this.item_to_edit.id,
          external_id: this.external_id
        };
        this.show_change_external_id = false;

        this.$store
          .dispatch("changeTrialExternalId", data)
          .then(data => {
            this.saving = false;
            Vue.set(
              this.requests,
              this.requests.findIndex(i => i.id == this.item_to_edit.id),
              data.data.request
            );

            this.external_id = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("modified_external_id"),
              text: this.$t("modified_external_id_ok", {
                hotel_name: this.item_to_edit.hotel_name
              }),
              color: "secondary"
            });
            this.item_to_edit = {};
          })
          .catch(() => {
            this.saving = false;
            this.external_id = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
            this.item_to_edit = {};
          });
      }
    },
    changeComments() {
      if (this.comments_valid) {
        this.saving = true;
        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("modifying_comments"),
          color: "secondary"
        });
        const data = {
          id: this.item_to_edit.id,
          comments: this.comments
        };
        this.show_change_comments = false;

        this.$store
          .dispatch("changeTrialComments", data)
          .then(data => {
            this.saving = false;
            Vue.set(
              this.requests,
              this.requests.findIndex(i => i.id == this.item_to_edit.id),
              data.data.request
            );

            this.comments = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("modified_comments"),
              text: this.$t("modified_comments_ok", {
                hotel_name: this.item_to_edit.hotel_name
              }),
              color: "secondary"
            });
            this.item_to_edit = {};
          })
          .catch(() => {
            this.saving = false;
            this.comments = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
            this.item_to_edit = {};
          });
      }
    },
    changeConfiguration() {
      if (this.configuration_valid) {
        this.saving = true;
        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("modifying_configuration"),
          color: "secondary"
        });
        const data = {
          id: this.item_to_edit.id,
          hotel_booking_url: this.hotel_booking_url,
          competitor_booking_urls: this.competitor_booking_urls
        };
        this.show_configuration = false;

        this.$store
          .dispatch("changeTrialConfiguration", data)
          .then(data => {
            this.saving = false;
            Vue.set(
              this.requests,
              this.requests.findIndex(i => i.id == this.item_to_edit.id),
              data.data.request
            );

            this.hotel_booking_url = "";
            this.competitor_booking_urls = [];
            this.$store.dispatch("setSnackbar", {
              title: this.$t("modified_configuration"),
              text: this.$t("modified_configuration_ok", {
                hotel_name: this.item_to_edit.hotel_name
              }),
              color: "secondary"
            });
            this.item_to_edit = {};
          })
          .catch(() => {
            this.saving = false;
            this.hotel_booking_url = "";
            this.competitor_booking_urls = [];
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
            this.item_to_edit = {};
          });
      }
    },
    add() {
      if (this.add_valid) {
        this.saving = true;
        this.$store.dispatch("setSnackbar", {
          title: this.$t("wait_a_moment") + "...",
          text: this.$t("adding"),
          color: "secondary"
        });
        const data = {
          fullname: this.add_fullname,
          email: this.add_email,
          hotel_url: this.add_hotel_url
        };
        this.show_add = false;

        this.$store
          .dispatch("addTrial", data)
          .then(data => {
            this.saving = false;
            Vue.set(this.requests, this.requests.length, data.data.request);

            this.add_email = "";
            this.add_hotel_url = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("added"),
              text: this.$t("added_ok", {
                hotel_name: this.add_fullname
              }),
              color: "secondary"
            });
            this.add_fullname = "";
          })
          .catch(() => {
            this.saving = false;
            this.add_fullname = "";
            this.add_email = "";
            this.add_hotel_url = "";
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
          });
      }
    },
    deleteTrial() {
      this.saving = true;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("wait_a_moment") + "...",
        text: this.$t("deleting_trial"),
        color: "secondary"
      });
      const data = {
        id: this.item_to_edit.id
      };
      this.show_delete_trial = false;

      this.$store
        .dispatch("deleteTrial", data)
        .then(data => {
          this.saving = false;
          this.requests.splice(
            this.requests.findIndex(i => i.id == this.item_to_edit.id),
            1
          );

          this.$store.dispatch("setSnackbar", {
            title: this.$t("deleted_trial"),
            text: this.$t("deleted_trial_ok", {
              hotel_name: this.item_to_edit.hotel_name
            }),
            color: "secondary"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    }
  },
  watch: {},
  computed: {
    headers() {
      return [
        {
          text: this.$t("type"),
          align: "center",
          sortable: true,
          value: "type"
        },
        {
          text: this.$t("contact_data"),
          align: "start",
          sortable: true,
          value: "fullname"
        },
        {
          text: this.$t("hotel_name"),
          align: "center",
          sortable: true,
          value: "hotel_name"
        },
        {
          text: this.$t("date"),
          align: "center",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("email_validation"),
          align: "center",
          sortable: true,
          value: "validation"
        },
        {
          text: this.$t("progress"),
          align: "center",
          sortable: true,
          value: "progress"
        },
        {
          text: this.$t("booking_engine"),
          align: "center",
          sortable: true,
          value: "booking_engine"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions"
        }
      ];
    },
    selected_type() {
      return this.only_trial ? "rate-trial" : this.filter_type;
    },
    filtered_requests() {
      let filtered_requests = this.requests;
      if (this.selected_type) {
        filtered_requests = filtered_requests.filter(
          item => item.type == this.selected_type
        );
      }
      if (this.only_trial) {
        filtered_requests = filtered_requests.filter(item =>
          this.isMirai(item)
        );
      }
      if (this.fast_filters == "call_pending") {
        filtered_requests = filtered_requests.filter(item =>
          this.itemPendingContact(item)
        );
      }
      if (this.fast_filters == "mirai_pending") {
        filtered_requests = filtered_requests.filter(
          item => this.isMirai(item) && this.itemPendingMirai(item)
        );
      }
      return filtered_requests;
    },
    contentStyle() {
      // Mandatory
      var margin_top = this.image_height - 40;
      return "position:relative; margin-top:-" + margin_top + "px";
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    user_last_seen(highlight) {
      // //We use 'user' for dispatching when this.current_user = user;
      // const user = JSON.parse(JSON.stringify(this.current_user));
      // //We verify it exists and it's an array
      // const last_seen =
      //   this.current_user.settings && this.current_user.settings.trial_last_seen
      //     ? JSON.parse(
      //         JSON.stringify(this.current_user.settings.trial_last_seen)
      //       )
      //     : [];
      // if (last_seen.length == 0) {
      //   // User first time
      //   last_seen.push(Vue.moment().format("YYYY-MM-DD HH:mm:ss"));
      //   return null;
      // } else {
      //   // User has entered before.
      //   if ()
      //   highlights_done.splice(highlights_done.indexOf(highlight.id), 1);
      //   user.settings.highlights_done = highlights_done;
      // }
      // // We update just current user, neither user list nor server data
      // this.current_user = user;
      // // We save it in the server, in background (no waiting time for user) and updates user list (NOT current user)
      // this.$store.dispatch("updateUser", user);
      // return last_seen;
      return null;
    },
    ...mapState({
      requests: state => state.trial.requests,
      snackbar: state => state.snackbar,
      query_params: state => state.route.query
    }),
    ...mapGetters({
      dialogHeight: "dialogHeight",
      booking_rules: "booking_rules"
    })
  }
};
</script>
<style scoped lang="sass">
.theme--light.v-stepper
  box-shadow: none
  background: transparent
#trial-filter
  margin: 0 auto
  background-color: transparent
.v-btn-toggle > .v-btn
  height: 40px !important
  opacity: 1
::v-deep .v-data-table__wrapper
  overflow: visible
::v-deep .pulse
  box-shadow: 0 0 0 rgba(21, 118, 199, 0.4)
  animation: pulse 2s infinite
::v-deep .pulse:hover
  animation: none
// @-webkit-keyframes pulse
//   0%
//     -webkit-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4)

//   70%
//     -webkit-box-shadow: 0 0 0 10px rgba(21, 118, 199, 0)

//   100%
//     -webkit-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0)

// @keyframes pulse
//   0%
//     -moz-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4)
//     box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4)
//   70%
//     -moz-box-shadow: 0 0 0 10px rgba(21, 118, 199, 0)
//     box-shadow: 0 0 0 10px rgba(21, 118, 199, 0)
//   100%
//     -moz-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0)
//     box-shadow: 0 0 0 0 rgba(21, 118, 199, 0)
</style>
