<template>
  <v-card
    class="mx-auto elevation-6"
    max-width="400"
    :disabled="disabled"
    :to="route_path"
  >
    <v-img
      class="white--text align-end justify-center"
      height="200px"
      :src="image_source"
      gradient="to bottom, rgba(100,115,201,.1), rgba(4,11,84,.8)"
    >
      <v-card-title class="center">{{ title }}</v-card-title>
    </v-img>

    <!-- <v-card-subtitle class="pb-">
    </v-card-subtitle> -->

    <v-card-text class="text--primary">
      <div>{{ description }}</div>
    </v-card-text>

    <v-card-actions>
      <v-btn color="secondary" text rounded :to="route_path">
        {{ $t("see") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import apiClient from "@/services/Environment";
import aPage from "@/components/aPage";
import aSettingsCard from "@/components/aSettingsCard";

export default {
  props: {
    image_source: {
      type: String,
      default: "/img/settings/team.jpg"
    },
    title: {
      type: String,
      default: "Sin título"
    },
    description: {
      type: String,
      default: "Sin descripción"
    },
    route_path: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  }
};
</script>
