<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "a-hotelsdot-year-sales-roomrevenue-grown-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Array,
      default: function() {
        return [];
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      return {
        type: "bar",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 20px 50px 70px"
        },
        plot: {
          barWidth: "90%",
          borderRadius: 20,
          rules: this.columnsColor
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineWidth: 0,
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.map(item => {
            return (
              Vue.moment(item)
                .format("MMMM")
                .substr(0, 1)
                .toUpperCase() +
              Vue.moment(item)
                .format("MMMM")
                .substr(1, 2)
            );
          })
        },
        scaleY: {
          lineWidth: 0,
          format: "%v%",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        series: [
          {
            values: this.series
          }
        ],
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    columnsColor() {
      let rules = [];
      this.series.forEach((value, i) => {
        if (value < 0) {
          rules.push({
            rule: `%i == ${i}`,
            backgroundColor: "#F44336"
            /* borderRadiusBottomLeft: 10,
            borderRadiusBottomRight: 10 */
          });
        } else {
          rules.push({
            rule: `%i == ${i}`,
            backgroundColor: "#4CAF50"
            /* borderRadiusTopLeft: 10,
            borderRadiusTopRight: 10 */
          });
        }
      });
      return rules;
    },
    /* graph_series() {
      let series = [];

      let values = [];
      this.series.forEach((value, i) => {
        let j = 0;
        let vals = [];
        while (j < this.series.length) {
          if (j == i) {
            vals.push(value);
          } else {
            vals.push(null);
          }
          j++;
        }
        values[this.labels[i]] = vals;
      });

      Object.keys(values).forEach(key => {
        let obj = {
          text: key,
          values: values[key],
          backgroundColor: "#0077b6"
        };
        let positive = true;
        values[key].forEach((value, i) => {
          if (value !== null && value < 0) {
            positive = false;
          }
        });
        if (!positive) {
          obj.borderRadiusTopLeft = 0;
          obj.borderRadiusTopRight = 0;
          obj.borderRadiusBottomLeft = 10;
          obj.borderRadiusBottomRight = 10;
        }
        series.push(obj);
      });

      return series;
    }, */
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
