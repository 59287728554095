<template>
  <div class="progress-bar">
    <div
      class="progress-bar-inner blue lighten-2"
      :style="{ width: progress + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
      default: 0
    }
  }
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-inner {
  height: 100%;
  //background-color: var(--v-primary-base);
  transition: width 0.3s ease;
}
</style>
