<template>
  <div>
    <v-tooltip color="white" bottom v-if="events.length">
      <template v-slot:activator="{ on, attrs }">
        <v-progress-linear
          v-bind="attrs"
          v-on="on"
          background-color="grey lighten-4"
          class="px-2 mt-2"
          rounded
          :value="getPercentEvents"
          :color="getPercentEventsColor"
        >
        </v-progress-linear>
      </template>
      <div class="pt-2 pb-1 ">
        <h3 class="black--text mb-2 text-center">{{ day_name }}</h3>
        <div
          v-for="(event, index) in events"
          :key="index"
          class="mb-1 d-flex justify-center"
        >
          <v-chip small :color="event_color(event)">
            <v-icon x-small class="mr-1">
              {{ getEventTypeIcon(event.type) }}
            </v-icon>
            <b>{{ event.alias }}</b>
          </v-chip>
        </div>
      </div>
    </v-tooltip>
    <v-tooltip color="white" bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-progress-linear
          v-bind="attrs"
          v-on="on"
          background-color="grey lighten-4"
          class="px-2 mt-2"
          rounded
          :value="100"
          :color="'white'"
        >
        </v-progress-linear>
      </template>
      <div class="pt-2 pb-1 ">
        <h3 class="black--text text-center mb-1">{{ day_name }}</h3>
        <div class="mb-1 black--text text-center">
          {{ $t("day_without_events") }}
        </div>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import * as Utils from "@/utils.js";

export default {
  name: "EventProgress",
  props: {
    date: {
      type: String,
      required: true
    },
    events: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({}),
  computed: {
    day_name() {
      return Vue.moment(this.date).format("dddd,D MMMM");
    },
    getPercentEvents() {
      return (this.events.length * 100) / 4;
    },
    getPercentEventsColor() {
      let color = "lime lighten-2";
      let num = this.getPercentEvents;
      if (num > 75) {
        color = "lime darken-3";
      } else if (num > 50) {
        color = "limen darken-2";
      } else if (num > 25) {
        color = "lime darken-1";
      }
      return color;
    },
    ...mapState({
      calendar_store: state => state.calendar
    })
  },
  methods: {
    event_color(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    }
  }
};
</script>

<style scoped>
.calendar_year {
  text-align: center;
}
.calendar_month {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px;
  min-height: 350px;
}
.calendar_month_name {
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto;
  color: #444;
  text-transform: lowercase;
}
.calendar_week_number {
  width: 30px;
  display: flex;
  align-items: center;
  background: #e5e5e5;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: default;
}
.calendar_week_number .calendar_day_content_number {
  color: #ffffff;
  line-height: 33px;
  cursor: default;
}
.calendar_week_header {
  display: flex;
  margin: 20px 0 10px 0;
}
.calendar_weekday_header:first-of-type {
  width: 30px;
  flex: none;
}
.calendar_weekday_header {
  flex: 1;
  color: #cacaca;
  font-weight: 500;
}
.calendar_week {
  margin-bottom: 10px;
  display: flex;
}
.calendar_day {
  flex: 1;
}
.calendar_day_invisible {
}
.calendar_day_content {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #4bd;
  color: white;
  margin: 0 auto;
  line-height: 30px;
  cursor: pointer;
  background: #4bd;
  font-size: 13px;
  font-weight: 500;
}
.calendar_day_content.blocked {
  cursor: not-allowed;
}
.calendar_day_content_number {
  width: 30px;
  height: 30px;
  border: none;
  color: #cacaca;
  margin: 0 auto;
  line-height: 25px;
  cursor: default;
  background: none;
}
.calendar_day_content:hover {
  border: 1px solid #4bd !important;
  background: #f5fdff;
  color: #4bd;
}
.calendar_day_content.closed {
  border: 1px solid #dedede;
  background: none;
  color: #333333;
}
</style>
