<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :color="bgcolor"
  >
    <template v-slot:header_options>
      <a-rms-range-selector
        ref="a-rms-range-selector"
        :event_period="[availability_from, availability_to]"
        @updatePeriod="updateRange"
        :max_days="max_days"
        class="mr-2"
        v-if="!isGroup"
      >
      </a-rms-range-selector>
      <a-rms-import-dates
        ref="rms_import_dates"
        v-if="!isGroup"
      ></a-rms-import-dates>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <v-card v-else>
      <rms-availability-app
        :date="availability_from"
        :max_days="max_days"
        ref="a-rms-day-availability"
      ></rms-availability-app>
    </v-card>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import rmsAvailabilityApp from "@/components/app/hotelsdot/rmsAvailabilityApp";
import aRmsRangeSelector from "@/components/rms/aRmsRangeSelector";
import aRmsImportDates from "@/components/rms/aRmsImportDates";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms";

export default {
  name: "rms-availability",
  components: {
    aPage,
    rmsAvailabilityApp,
    aRmsRangeSelector,
    aRmsImportDates,
    aHotelSelectorRms
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    max_days: 15
  }),
  methods: {
    updateRange(v) {
      let range = JSON.parse(JSON.stringify(v));
      this.availability_from = range[0];
      this.availability_to = range[1];
    }
  },
  watch: {},
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 150 : 200;
    },
    bgcolor() {
      return !this.isGroup ? "#f5f5f5" : "transparent";
    },
    availability_from: {
      get() {
        return this.$store.state.rms.availability_from;
      },
      set(value) {
        this.$store.dispatch("setAvailabilityFrom", value);
      }
    },
    availability_to: {
      get() {
        return this.$store.state.rms.availability_to;
      },
      set(value) {
        this.$store.dispatch("setAvailabilityTo", value);
      }
    },
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  mounted() {
    // Initialize selector
    this.availability_from = Vue.moment().format("YYYY-MM-DD");
    this.availability_to = Vue.moment()
      .add(15, "days")
      .format("YYYY-MM-DD");
  }
};
</script>
