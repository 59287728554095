<template>
  <div class="hotelsdot-year-sales-table">
    <a-rms-year-sales-columns :columns="columns"></a-rms-year-sales-columns>
    <a-rms-year-sales-row
      v-for="(row, index) in rows"
      :key="index"
      :columns="columns"
      :rowData="row"
      :rowIndex="index"
    ></a-rms-year-sales-row>
  </div>
</template>
<script>
import aRmsYearSalesColumns from "@/components/rms/hotelsdot/aRmsYearSalesColumns.vue";
import aRmsYearSalesRow from "@/components/rms/hotelsdot/aRmsYearSalesRow.vue";

export default {
  name: "a-rms-year-sales-table",
  components: {
    aRmsYearSalesColumns,
    aRmsYearSalesRow
  },
  props: {
    rows: Array
  },
  computed: {
    columns() {
      return [
        {
          title: "Rev",
          align: "left",
          sortable: true,
          value: "room_revenue"
        },
        {
          title: "Adr",
          align: "left",
          sortable: true,
          value: "adr"
        },
        {
          title: "Rn",
          align: "left",
          sortable: true,
          value: "rn"
        },
        {
          title: "Occ",
          align: "left",
          sortable: true,
          value: "occupancy"
        },
        {
          title: "Revpar",
          align: "left",
          sortable: true,
          value: "revpar"
        },
        {
          title: this.$t("intermediation"),
          align: "left",
          sortable: true,
          value: "intermediation"
        }
      ];
    }
  }
};
</script>
<style lang="scss" scoped></style>
