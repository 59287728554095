<template>
  <v-hover v-slot="{ hover }">
    <v-alert
      :key="'a-hightlight-item' + hightlight.id"
      :color="getColorFromPriority(hightlight.priority)"
      elevation="1"
      border="left"
      colored-border
      class="py-2"
      :class="clas"
    >
      <v-row align="center">
        <v-col class="py-0 shrink">
          <v-btn
            fab
            x-small
            depressed
            :outlined="hover"
            :color="hover ? 'white' : 'grey lighten-3'"
            @click="seeHighlight(hightlight)"
          >
            <v-icon v-show="isDone(hightlight)" small color="green"
              >mdi-check</v-icon
            >
          </v-btn>
        </v-col>
        <v-col class="py-0 grow body-2">
          <v-row align="center">
            <v-col class="pa-0 shrink" v-if="groupDisplay">
              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-on="on"
                    small
                    size="30"
                    depressed
                    :color="getHotelById(hightlight.hotel_id).settings.color"
                    class="v-list-item__avatar subtitle-2 white--text text-uppercase justify-center mr-0"
                    >{{
                      getHotelById(hightlight.hotel_id).settings.abbreviation
                    }}
                  </v-avatar>
                </template>
                <span>{{ getHotelById(hightlight.hotel_id).name }}</span
                ><br />
                <v-rating
                  readonly
                  small
                  dense
                  :value="getHotelById(hightlight.hotel_id).settings.stars"
                ></v-rating>
              </v-tooltip>
            </v-col>
            <v-col class="pa-0 grow body-2">
              <span class="grey--text overline" v-if="show_type"
                >{{ $t(hightlight.literal_type_name, hightlight.extra) }} </span
              ><br v-if="show_type" />
              <span
                :class="{ 'grey--text': isDone(hightlight) }"
                :style="
                  isDone(hightlight) ? 'text-decoration: line-through' : ''
                "
                >{{ $t(hightlight.literal_message, hightlight.extra) }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="py-0 shrink">
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                icon
                color="info"
                v-on="on"
                @click="
                  handle_function_call(
                    hightlight.js_function,
                    hightlight.js_function_params,
                    hightlight
                  )
                "
              >
                <v-icon small>mdi-crosshairs</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("see_detail") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-alert>
  </v-hover>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-highlight-item",
  props: {
    hightlight: {
      type: Object
    },
    show_type: {
      type: Boolean,
      default: true
    },
    clas: {
      type: String,
      default: ""
    }
  },
  components: {},
  data: vm => ({}),
  methods: {
    getColorFromPriority(priority) {
      var p = this.priorities.find(p => p.id == priority);
      return p ? p.color : null;
    },
    seeHighlight(highlight) {
      //We use 'user' for dispatching when this.current_user = user;
      var user = JSON.parse(JSON.stringify(this.current_user));

      //We verify it exists and it's an array
      var highlights_done =
        this.current_user.settings && this.current_user.settings.highlights_done
          ? JSON.parse(
              JSON.stringify(this.current_user.settings.highlights_done)
            )
          : [];
      if (Object.keys(user.settings).length === 0) {
        user.settings = {};
      }
      if (highlights_done.indexOf(highlight.id) < 0) {
        // We add it
        highlights_done.push(highlight.id);
        user.settings.highlights_done = highlights_done;
      } else {
        // We remove it
        highlights_done.splice(highlights_done.indexOf(highlight.id), 1);
        user.settings.highlights_done = highlights_done;
      }

      // We update just current user, neither user list nor server data
      this.current_user = user;

      // We save it in the server, in background (no waiting time for user) and updates user list (NOT current user)
      this.$store.dispatch("updateUser", user);
    },
    isDone(highlight) {
      return highlight &&
        this.current_user.settings &&
        this.current_user.settings.highlights_done &&
        this.current_user.settings.highlights_done.indexOf(highlight.id) >= 0
        ? true
        : false;
    },
    handle_function_call(function_name, function_params, highlight) {
      this.$emit(function_name, highlight, function_params);
    },
    ...mapActions(["showRateHighlight"])
  },
  computed: {
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    ...mapState({
      priorities: state => state.highlight_priorities,
      highlights: state => state.highlight.list
    }),
    ...mapGetters({
      getHotelById: "getHotelById"
    })
  }
};
</script>
<style scoped>
.v-alert:hover {
  background: #e4e8f5;
}
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
