<template>
  <div
    class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
    style="position:relative"
  >
    <div class="px-10 mx-5">
      <h3 class="text-h3 d-block">
        {{ $t("which_hotel_you_want_setup") }}
      </h3>
      <v-btn
        class="mt-10"
        color="secondary"
        rounded
        @click="open_hotel_selector"
        >{{ $t("select_hotel") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "aHotelSelectorPage",
  methods: {
    open_hotel_selector: function() {
      this.$store.dispatch("setShowHotelList", true);
    }
  }
};
</script>
