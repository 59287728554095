<template>
  <v-dialog
    v-model="show_modify_dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="750px"
  >
    <v-card>
      <v-card-title class="headline py-6 justify-space-between">
        <h5 v-if="kpi_to_edit != ''" class="modal-title" id="exampleModalLabel">
          {{ kpis[kpi_to_edit]["name"] }} |
          <small>{{ kpis[kpi_to_edit]["description"] }}</small>
        </h5>
        <v-btn class="" icon @click="kpi_to_edit = ''" :aria-label="$t('close')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <budget-massive-editor
          :kpi="kpis[kpi_to_edit]"
          :kpi_to_edit="kpi_to_edit"
        />
        <v-row v-if="kpi_to_edit != ''">
          <v-col cols="2" v-for="(month, index) in months" :key="index">
            <v-text-field
              :label="month.text"
              rounded
              outlined
              dense
              placeholder="0.00"
              v-on:keydown="correctValue"
              :value="editing_kpis[kpi_to_edit]['periodo' + (month.value + 1)]"
              @change="
                updateEditingKpis(
                  kpi_to_edit,
                  'periodo' + (month.value + 1),
                  $event
                )
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center py-5">
        <v-btn color="secondary" rounded depressed class="px-5" @click="setKpi">
          {{ $t("accept") }}
        </v-btn>
        <v-btn
          color=""
          text
          rounded
          depressed
          class="px-5"
          @click="kpi_to_edit = ''"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { cleanPunctuation, generateArrayMonths, justNumber } from "@/utils";
import { mapState } from "vuex";
import BudgetMassiveEditor from "./BudgetMassiveEditor.vue";
export default {
  data: () => ({
    months: generateArrayMonths("MMMM")
  }),
  components: { BudgetMassiveEditor },
  computed: {
    ...mapState({
      budget_store: state => state.budget,
      kpis: state => state.budget.kpis
    }),
    kpi_to_edit: {
      get() {
        return this.budget_store.kpi_to_edit;
      },
      set(value) {
        this.$store.dispatch("setKpiToEdit", value);
      }
    },
    show_modify_dialog() {
      return this.kpi_to_edit != "";
    },
    editing_kpis: {
      get() {
        return this.budget_store.editing_kpis;
      },
      set(value) {
        this.$store.dispatch("setEditingKpis", value);
      }
    }
  },
  methods: {
    setKpi() {
      this.$store.dispatch("setNewKpis", this.editing_kpis);
      this.kpi_to_edit = "";
    },
    correctValue: function(e) {
      if (
        (e.keyCode >= 60 && e.keyCode <= 90) ||
        (e.keyCode >= 145 &&
          e.keyCode != 190 &&
          e.keyCode !== 189 &&
          e.keyCode !== 173)
      ) {
        e.preventDefault();
      }
    },
    updateEditingKpis: function(kpiCode, kpiPeriod, value) {
      this.$store.dispatch("updateEditingKpi", {
        kpiCode,
        kpiPeriod,
        value: justNumber(cleanPunctuation(value))
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
