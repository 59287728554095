<template>
  <!-- RATE DATE TO COMPARE -->
  <div id="rate-highlight">
    <v-card v-if="groupDisplay">
      <v-card-title class="left">{{ $t("select_hotel") }}</v-card-title>
      <v-card-text class="pt-0 pb-5">
        <v-row>
          <v-col
            cols="6"
            md="4"
            v-for="hotel in hotels_to_show"
            :key="hotel.id"
          >
            <v-card @click="seeHotelHighlight(hotel.id)">
              <v-list-item two-line>
                <v-list-item-avatar
                  size="50"
                  :color="hotel.settings.color"
                  class="white--text title"
                  style="display:inline-block;"
                >
                  {{ getHotelHighlights(hotel.id).length }}<br />
                  <v-icon x-small color="white" style="display:inline-block;"
                    >mdi-star-four-points-outline</v-icon
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-card-title class="text-uppercase subtitle-2 pa-0">
                    {{ hotel.name }}
                  </v-card-title>
                  <v-rating
                    readonly
                    small
                    dense
                    :value="hotel.settings.stars"
                  ></v-rating>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <a-base-component
      :subscribed_modules="subscribed_modules"
      @loaded="loaded"
      v-else
    >
      <template v-slot:default>
        <v-card>
          <v-card-text class="mb-4">
            <v-row class="row pb-0 justify-end">
              <v-col cols="12" lg="10" class="py-0">
                <div class="v-messages theme--light text-uppercase">
                  <div class="v-messages__wrapper">
                    {{ $t("filter") }}
                  </div>
                </div>
                <v-row>
                  <v-col
                    cols="3"
                    class="pb-0"
                    v-if="rate_available_types.length > 0"
                  >
                    <v-select
                      v-model="rate_selected_types"
                      :items="rate_available_types"
                      item-text="text"
                      item-value="id"
                      :label="$t('rate')"
                      prepend-inner-icon="mdi-bed-king-outline"
                      multiple
                      outlined
                      rounded
                      dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle('rate')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                rate_selected_types.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon("rate") }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("select_all") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          v-if="rate_all_selected_types && index === 0"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span>
                            {{ $t("all") }}
                          </span>
                          <span class="grey--text caption">
                            ({{ rate_selected_types.length }})
                          </span>
                        </span>
                        <span
                          v-else-if="!rate_all_selected_types"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span v-if="index === 0">
                            {{ item.text }}
                          </span>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ rate_selected_types.length - 1 }}
                            {{ $t("more") }})
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pb-0"
                    v-if="show_rate_web && parity_available_types.length > 0"
                  >
                    <v-select
                      v-model="parity_selected_types"
                      :items="parity_available_types"
                      item-text="text"
                      item-value="id"
                      :label="$t('parity_web')"
                      prepend-inner-icon="mdi-content-duplicate"
                      multiple
                      outlined
                      rounded
                      dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle('parity')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                parity_selected_types.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon("parity") }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("select_all") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          v-if="parity_all_selected_types && index === 0"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span>
                            {{ $t("all") }}
                          </span>
                          <span class="grey--text caption">
                            ({{ parity_selected_types.length }})
                          </span>
                        </span>
                        <span
                          v-else-if="!parity_all_selected_types"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span v-if="index === 0">
                            {{ item.text }}
                          </span>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ parity_selected_types.length - 1 }}
                            {{ $t("more") }})
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pb-0"
                    v-if="
                      show_parity && paritychannel_available_types.length > 0
                    "
                  >
                    <v-select
                      v-model="paritychannel_selected_types"
                      :items="paritychannel_available_types"
                      item-text="text"
                      item-value="id"
                      :label="$t('google_hotel_ads')"
                      prepend-inner-icon="mdi-google"
                      multiple
                      outlined
                      rounded
                      dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle('paritychannel')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                paritychannel_selected_types.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon("paritychannel") }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("select_all") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          v-if="paritychannel_all_selected_types && index === 0"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span>
                            {{ $t("all") }}
                          </span>
                          <span class="grey--text caption">
                            ({{ paritychannel_selected_types.length }})
                          </span>
                        </span>
                        <span
                          v-else-if="!paritychannel_all_selected_types"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span v-if="index === 0">
                            {{ item.text }}
                          </span>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ paritychannel_selected_types.length - 1 }}
                            {{ $t("more") }})
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pb-0"
                    v-if="reputation_available_types.length > 0"
                  >
                    <v-select
                      v-model="reputation_selected_types"
                      :items="reputation_available_types"
                      item-text="text"
                      item-value="id"
                      :label="$t('reputation')"
                      prepend-inner-icon="mdi-comment-multiple-outline"
                      multiple
                      outlined
                      rounded
                      dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle('reputation')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                reputation_selected_types.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon("reputation") }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("select_all") }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span
                          v-if="reputation_all_selected_types && index === 0"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span>
                            {{ $t("all") }}
                          </span>
                          <span class="grey--text caption">
                            ({{ reputation_selected_types.length }})
                          </span>
                        </span>
                        <span
                          v-else-if="!reputation_all_selected_types"
                          class="v-select__selection v-select__selection--comma"
                        >
                          <span v-if="index === 0">
                            {{ item.text }}
                          </span>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ reputation_selected_types.length - 1 }}
                            {{ $t("more") }})
                          </span>
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="2" class="py-0">
                <div :class="{ 'float-right': $vuetify.breakpoint.lgAndUp }">
                  <div class="v-messages theme--light text-uppercase ml-2">
                    <div class="v-messages__wrapper">
                      {{ $t("priority") }}
                    </div>
                  </div>
                  <v-btn-toggle
                    class="mt-3"
                    v-model="priority"
                    color="secondary accent-3"
                    dense
                    rounded
                    outlined
                    block
                    multiple
                  >
                    <v-tooltip
                      color="black"
                      bottom
                      v-for="p in priorities"
                      :key="p.id"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :value="p.id" class="white">
                          <v-avatar size="16" :color="p.color"></v-avatar>
                          <span class="ml-1 overline">{{ $t(p.literal) }}</span>
                        </v-btn>
                      </template>
                      <span>{{ $t(p.literal) }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text style="height: 90%;" class="py-8">
            <v-row v-if="highlights.length == 0">
              <v-col class="py-0" cols="12">
                <v-card-title class="pa-0">
                  {{ $t("nothing_to_highlight") }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row
              v-else-if="priority.length > 0 && highlight_types_to_show == 0"
            >
              <v-col class="py-0" cols="12">
                <v-card-title class="pa-0">
                  {{ $t("nothing_to_highlight_priorities") }}
                </v-card-title>
                <v-btn
                  color="info"
                  class="text-center mt-3"
                  rounded
                  outlined
                  @click="priority = []"
                  >{{ $t("see_all_highlights") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-else-if="selected_types.length === 0">
              <v-col class="py-0" cols="12">
                <v-card-title class="pa-0">
                  {{ $t("nothing_to_highlight_types") }}
                </v-card-title>
                <v-btn
                  color="info"
                  class="text-center mt-3"
                  rounded
                  outlined
                  @click="loaded()"
                  >{{ $t("see_all_highlights") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-for="type in highlight_types_to_show" :key="type.name">
              <v-col
                class="py-0"
                cols="12"
                v-if="highlights_to_show(type.name).length"
              >
                <v-card-title class="pa-0 subtitle-2 text-uppercase">
                  <v-tooltip color="black" bottom :key="'circular' + type.name">
                    <template v-slot:activator="{ on }">
                      <v-progress-circular
                        v-on="on"
                        class="mr-2"
                        size="20"
                        :value="type_completition[type.name].percent"
                        color="info"
                      ></v-progress-circular>
                    </template>
                    <span
                      >{{ type_completition[type.name].done }}/{{
                        type_completition[type.name].total
                      }}</span
                    >
                  </v-tooltip>

                  {{ $t(type.name, type.extra) }}
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col
                    v-for="(h, index) in highlights_to_show(type.name)"
                    :key="index"
                    cols="12"
                    sm="6"
                    class="py-0"
                  >
                    <a-hightlight-item
                      :key="'highlight_' + index"
                      :hightlight="h"
                      :show_type="false"
                      @loadRate="loadRate"
                      @loadReputation="loadReputation"
                      @loadParity="loadParity"
                    ></a-hightlight-item>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:loading>
        <v-card>
          <v-card-text class="mb-4">
            <v-skeleton-loader class="my-6" type="text"></v-skeleton-loader>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text style="height: 90%;" class="py-8">
            <v-row>
              <v-col cols="6" class="px-8">
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="6" class="px-8">
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </a-base-component>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import aHightlightItem from "@/components/aHightlightItem";
import aBaseComponent from "@/components/aBaseComponent";

export default {
  name: "highlights-list",
  components: {
    aHightlightItem,
    aBaseComponent
  },
  props: [],
  data: vm => ({
    subscribed_modules: [
      "rate_available_rate_extractions",
      "highlight_list",
      "highlight_info"
    ],
    rate_selected_types: [],
    parity_selected_types: [],
    paritychannel_selected_types: [],
    reputation_selected_types: []
  }),
  watch: {
    current_hotel() {
      if (!this.extraction_id) {
        this.setDefault();
      }
    }
  },
  methods: {
    setDefault() {
      this.extraction_id = this.defaultExtractionId();
    },
    // SELECTS
    toggle(section) {
      this.$nextTick(() => {
        if (this.allSelected(section)) {
          this[`${section}_selected_types`] = [];
        } else {
          this[`${section}_selected_types`] = this.highlightTypesBySection(
            section
          ).map(type => type.id);
        }
      });
    },
    allSelected(section) {
      return (
        this[`${section}_selected_types`].length ===
        this.highlightTypesBySection(section).length
      );
    },
    someSelected(section) {
      return (
        this[`${section}_selected_types`].length > 0 &&
        !this.allSelected(section)
      );
    },
    icon(section) {
      if (this.allSelected(section)) return "mdi-close-box";
      if (this.someSelected(section)) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    // FILTERS
    extractionAllowedId(v) {
      return this.available_rate_import_ids &&
        this.available_rate_import_ids.find(id => id == v)
        ? true
        : false;
    },
    defaultExtractionId() {
      return this.available_rate_import_ids
        ? this.available_rate_import_ids[0]
        : "";
    },
    highlightTypesBySection(section) {
      return this.highlight_types_to_select.filter(
        type => type.section === section
      );
    },
    highlights_to_show(type_name) {
      var vm = this;
      return this.priority.length > 0 && this.getHighlightsByType(type_name)
        ? this.getHighlightsByType(type_name).filter(h =>
            vm.mustShowPriority(h.priority)
          )
        : this.getHighlightsByType(type_name);
    },
    mustShowPriority(priority) {
      return this.priority.indexOf(priority) >= 0;
    },
    isDone(highlight) {
      return this.current_user.settings &&
        this.current_user.settings.highlights_done &&
        this.current_user.settings.highlights_done.indexOf(highlight.id) >= 0
        ? true
        : false;
    },
    loadRate(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "rate",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    loadReputation(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "reputation",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    loadParity(highlight, function_params) {
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      const hotel = this.getHotelById(highlight.hotel_id);
      this.$router.push({
        name: "parity",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: function_params
      });
    },
    seeHighlight(highlight) {
      //We use 'user' for dispatching when this.current_user = user;
      var user = JSON.parse(JSON.stringify(this.current_user));

      //We verify it exists and it's an array
      var highlights_done =
        this.current_user.settings && this.current_user.settings.highlights_done
          ? JSON.parse(
              JSON.stringify(this.current_user.settings.highlights_done)
            )
          : [];
      if (Object.keys(user.settings).length === 0) {
        user.settings = {};
      }
      if (highlights_done.indexOf(highlight.id) < 0) {
        // We add it
        highlights_done.push(highlight.id);
        user.settings.highlights_done = highlights_done;
      } else {
        // We remove it
        highlights_done.splice(highlights_done.indexOf(highlight.id), 1);
        user.settings.highlights_done = highlights_done;
      }

      // We update it here, for UX improve
      this.current_user = user;

      // We save it in the server, in background (no waiting time for user)
      this.$store.dispatch("updateUser", this.current_user);
    },
    getHotelHighlights(hotel_id) {
      return this.highlights.filter(h => h.hotel_id == hotel_id);
    },
    getLastLevelChilds(hotel_id) {
      let last_level_childs = [];
      this.hotels
        .filter(hotel => hotel.father_id === hotel_id)
        .forEach(hotel => {
          if (hotel.children_number > 0) {
            last_level_childs = [
              ...last_level_childs,
              ...this.getLastLevelChilds(hotel.id)
            ];
          } else {
            last_level_childs.push(hotel.id);
          }
        });
      return last_level_childs;
    },
    seeHotelHighlight(hotel_id) {
      const hotel = this.getHotelById(hotel_id);
      this.$router.push({
        name: "highlights",
        params: {
          hotel_slug: hotel.settings.slug
        }
      });
    },
    loaded() {
      this.toggle("rate");
      this.toggle("parity");
      this.toggle("paritychannel");
      this.toggle("reputation");
    }
  },
  mounted() {
    this.$nextTick(function() {
      if (this.current_hotel) {
        this.setDefault();
      }
    });
  },
  computed: {
    // GENERAL
    sections() {
      let sections = [];
      if (
        this.current_user.permissions.reputation &&
        this.current_hotel.permissions.reputation
      ) {
        // sections.push("reputation");
      }
      if (
        this.current_user.permissions.rate &&
        this.current_hotel.permissions.rate
      ) {
        sections.push("rate");
      }
      if (
        this.current_user.permissions.rate_parity &&
        this.current_hotel.permissions.rate_parity
      ) {
        sections.push("parity");
      }
      if (
        this.current_user.permissions.parity &&
        this.current_hotel.permissions.parity
      ) {
        sections.push("paritychannel");
      }
      return sections;
    },
    selected_types() {
      return [
        ...this.rate_selected_types,
        ...this.parity_selected_types,
        ...this.paritychannel_selected_types,
        ...this.reputation_selected_types
      ];
    },
    rate_all_selected_types() {
      return this.allSelected("rate");
    },
    parity_all_selected_types() {
      return this.allSelected("parity");
    },
    paritychannel_all_selected_types() {
      return this.allSelected("paritychannel");
    },
    reputation_all_selected_types() {
      return this.allSelected("reputation");
    },
    rate_available_types() {
      return this.highlightTypesBySection("rate").map(type => {
        type.text += ` (${this.getHighlightsByType(type.name).length} ${this.$t(
          "highlights"
        )})`;
        return type;
      });
    },
    parity_available_types() {
      return this.highlightTypesBySection("parity").map(type => {
        type.text += ` (${this.getHighlightsByType(type.name).length} ${this.$t(
          "highlights"
        )})`;
        return type;
      });
    },
    paritychannel_available_types() {
      return this.highlightTypesBySection("paritychannel").map(type => {
        type.text += ` (${this.getHighlightsByType(type.name).length} ${this.$t(
          "highlights"
        )})`;
        return type;
      });
    },
    reputation_available_types() {
      return this.highlightTypesBySection("reputation").map(type => {
        type.text += ` (${this.getHighlightsByType(type.name).length} ${this.$t(
          "highlights"
        )})`;
        return type;
      });
    },
    highlight_types_to_select() {
      return this.highlight_types.filter(
        type => this.getHighlightsByType(type.name).length > 0
      );
    },
    highlight_types_to_show() {
      return this.highlight_types.filter(
        type =>
          this.highlights_to_show(type.name).length > 0 &&
          this.selected_types.length > 0 &&
          this.selected_types.includes(type.id)
      );
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    priority: {
      get() {
        return this.$store.state.rate.highlight_priority;
      },
      set(value) {
        this.$store.dispatch("setHighlightPriority", value);
      }
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    extraction_id: {
      get() {
        return this.$store.state.highlights_extraction_id;
      },
      set(value) {
        this.$store.dispatch("setHighlightsExtractionId", value);
      }
    },
    highlight_types() {
      return this.highlight_info.highlight_types.map(item => {
        return {
          id: item.id,
          text: this.$t(item.literal_type_name),
          name: item.literal_type_name,
          description: item.literal_type_description,
          icon: item.icon,
          section: item.section
        };
      });
    },
    type_completition() {
      var types = {};
      for (var type of this.highlight_types) {
        var highlights = this.getHighlightsByType(type.name);
        var item = {
          total: 0,
          done: 0,
          percent: 100
        };
        if (highlights) {
          var total = highlights.length;
          var done = 0;
          for (var h of highlights) {
            //Look if we have seen it
            if (this.isDone(h)) done++;
          }
          item = {
            total: total,
            done: done,
            percent: Math.round((done * 100) / total)
          };
        }
        types[type.name] = item;
      }
      return types;
    },
    highlights_shown() {
      var vm = this;
      return this.highlights.length > 0
        ? this.highlights.filter(h => vm.mustShowPriority(h.priority)).length
        : 0;
    },
    available_rate_import_ids() {
      // In this.current_hotel.settings.available_rate_extractions we have a list of [{date: "2020-01-01", extractions: {id: 1234, time: "12:01:01"}}, {...}]
      // From there we take a list of ids to make an easy validation
      let ids = [];
      if (this.available_rate_extractions) {
        ids = this.available_rate_extractions.reduce(
          (ids, cur) => ids.concat(cur.extractions.map(imp => imp.id)),
          []
        );
      }
      return ids;
    },
    hotels_to_show() {
      let last_level_childs = this.getLastLevelChilds(this.current_hotel.id);
      return this.hotels.filter(hotel => last_level_childs.includes(hotel.id));
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    show_parity() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.parity &&
            this.current_hotel.permissions.parity
        : false;
    },
    ...mapState({
      hotels: state => state.hotel.hotels,
      highlights: state => state.highlight.list,
      highlight_info: state => state.highlight.info,
      priorities: state => state.highlight_priorities,
      available_rate_extractions: state => state.rate.available_rate_extractions
    }),
    ...mapGetters({
      getHotelById: "getHotelById",
      getHighlightsByType: "getHighlightsByType",
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped lang="sass">
// ::v-deep .v-btn__content
//   padding: 10px
</style>
