<template>
  <v-card color="grey lighten-4 mb-5">
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="6" class="d-flex align-center" style="gap: 10px">
          <span
            class="text-uppercase font-weight-bold grey--text text--darken-3"
          >
            {{ $t("availability") }}
          </span>
          <div class="d-flex" style="gap: 5px">
            <v-chip
              color="red darken-2"
              small
              class="white--text text-capitalize"
            >
              {{ $t("occupancy") }}
              &lt;
              {{
                this.current_hotel.settings.ranges_occ_availability
                  ? this.current_hotel.settings.ranges_occ_availability.min
                  : 30
              }}%
            </v-chip>
            <v-chip color="green" small class="white--text text-capitalize">
              {{ $t("occupancy") }}
              &gt;
              {{
                this.current_hotel.settings.ranges_occ_availability
                  ? this.current_hotel.settings.ranges_occ_availability.max
                  : 70
              }}%
            </v-chip>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" class="d-flex align-center justify-end">
          <v-switch
            v-model="only_rooms"
            :label="$t('only_rooms')"
            class="mt-0 pt-0 mr-5"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="only_changes"
            :label="$t('only_changes')"
            class="mt-0 pt-0 mr-5"
            dense
            hide-details
          ></v-switch>
          <v-select
            v-model="vs_pickup"
            :items="vs_pickups"
            :label="$t('compare_with')"
            rounded
            outlined
            dense
            hide-details
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="white pa-0">
      <div :class="table_class">
        <v-btn
          v-show="page > 0"
          @click.prevent="moveDownSlider"
          id="arrow-left"
          class="arrow"
          rounded
          color="white"
          fab
          :loading="loading_page"
          small
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          v-show="hasMorePages"
          @click.prevent="moveUpSlider"
          id="arrow-right"
          class="arrow"
          rounded
          fab
          color="white"
          :loading="loading_page"
          small
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <a-rms-strategy-availability-columns
          :columns="filtered_columns"
          :page="page"
          :days_per_screen="days_per_screen"
          :total_days="total_days"
        ></a-rms-strategy-availability-columns>
        <a-rms-strategy-availability-row
          v-for="(item, index) in filtered_rows"
          :key="index"
          :label="labels[index]"
          :columns="filtered_columns"
          :rowData="item"
          :rowIndex="index"
          :vs_pickup="vs_pickup"
          :only_rooms="only_rooms"
          :page="page"
          :days_per_screen="days_per_screen"
          :total_days="total_days"
          :total="totals[index]"
        ></a-rms-strategy-availability-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import aRmsStrategyAvailabilityColumns from "@/components/rms/hotelsdot/aRmsStrategyAvailabilityColumns";
import aRmsStrategyAvailabilityRow from "@/components/rms/hotelsdot/aRmsStrategyAvailabilityRow";

export default {
  name: "a-rms-strategy-availability-table",
  components: {
    aRmsStrategyAvailabilityColumns,
    aRmsStrategyAvailabilityRow
  },
  props: {
    roomtypes_data: Object,
    totals_data: Object,
    kpi: String,
    columns: Array
  },
  data: () => ({
    only_rooms: false,
    only_changes: false,
    vs_pickup: null,
    loading_page: false,
    page: 0,
    days_per_screen: 14
  }),
  computed: {
    labels() {
      return Object.keys(this.roomtypes_data);
    },
    rows() {
      return Object.values(this.roomtypes_data);
    },
    totals() {
      return Object.values(this.totals_data);
    },
    vs_pickups() {
      return [
        { value: "pickup_1", text: this.getPickupLiteral("pickup_1") },
        { value: "pickup_2", text: this.getPickupLiteral("pickup_2") },
        { value: "pickup_3", text: this.getPickupLiteral("pickup_3") },
        { value: "pickup_4", text: this.getPickupLiteral("pickup_4") },
        { value: "pickup_5", text: this.getPickupLiteral("pickup_5") },
        { value: "pickup_6", text: this.getPickupLiteral("pickup_6") }
      ];
    },
    filtered_columns() {
      let cols = this.columns;
      if (this.only_changes) {
        cols = cols.filter((value, index) => {
          let visible = false;
          Object.keys(this.roomtypes_data).forEach(roomtype => {
            if (
              this.roomtypes_data[roomtype]["free"][this.vs_pickup][index] !== 0
            ) {
              visible = true;
            }
          });
          return visible;
        });
      }
      return cols;
    },
    filtered_rows() {
      let rows = [];
      const visible_indexs = [];
      if (this.only_changes) {
        this.columns.forEach((value, index) => {
          visible_indexs[index] = false;
          this.rows.forEach(roomtype => {
            if (roomtype["free"][this.vs_pickup][index] !== 0) {
              visible_indexs[index] = true;
            }
          });
        });
      }
      rows = this.rows.map(row => {
        if (this.only_changes) {
          let ro = {};
          Object.keys(row).map(kpi => {
            ro[kpi] = {
              otb: [],
              pickup_1: [],
              pickup_2: [],
              pickup_3: [],
              pickup_4: [],
              pickup_5: [],
              pickup_6: []
            };
            ro[kpi]["otb"] = row[kpi]["otb"].filter((value, index) => {
              return visible_indexs[index];
            });
            ro[kpi]["pickup_1"] = row[kpi]["pickup_1"].filter(
              (value, index) => {
                return visible_indexs[index];
              }
            );
            ro[kpi]["pickup_2"] = row[kpi]["pickup_2"].filter(
              (value, index) => {
                return visible_indexs[index];
              }
            );
            ro[kpi]["pickup_3"] = row[kpi]["pickup_3"].filter(
              (value, index) => {
                return visible_indexs[index];
              }
            );
            ro[kpi]["pickup_4"] = row[kpi]["pickup_4"].filter(
              (value, index) => {
                return visible_indexs[index];
              }
            );
            ro[kpi]["pickup_5"] = row[kpi]["pickup_5"].filter(
              (value, index) => {
                return visible_indexs[index];
              }
            );
            ro[kpi]["pickup_6"] = row[kpi]["pickup_6"].filter(
              (value, index) => {
                return visible_indexs[index];
              }
            );
          });
          return ro;
        }
        return row;
      });
      return rows;
    },
    table_class() {
      let clas = "table-wrap page_" + this.page;
      return clas;
    },
    total_days() {
      return this.filtered_columns.length;
    },
    pages() {
      return this.total_days / this.days_per_screen;
    },
    hasMorePages() {
      return this.page < this.pages - 1;
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    moveDownSlider() {
      if (this.page > 0) {
        this.loading_page = true;
        this.page--;
        setTimeout(() => {
          this.loading_page = false;
        }, 200);
      }
    },
    moveUpSlider() {
      if (this.page < this.pages) {
        this.loading_page = true;
        this.page++;
        setTimeout(() => {
          this.loading_page = false;
        }, 200);
      }
    },
    getPickupLiteral(period) {
      let pickups = {
        pickup_1:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_1"],
        pickup_2:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_3:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_4:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_5:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_6:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_6"]
      };
      if (pickups[period]) {
        return pickups[period];
      } else {
        return period.replace("_", " ");
      }
    }
  },
  mounted() {
    this.vs_pickup = this.vs_pickups[0].value;
  }
};
</script>

<style lang="scss" scoped>
.v-select {
  max-width: 200px;
}
.table-wrap {
  position: relative;
  .arrow {
    background: #fff;
    color: #aaaaaa;
    position: absolute;
    top: 1px;
    z-index: 200;
  }
  #arrow-left {
    left: 180px;
  }
  #arrow-right {
    left: auto;
    right: -12px;
  }
}
</style>
