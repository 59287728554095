<template>
  <div id="rms-availability-app">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-rms-strategy-availability-table
          ref="a-rms-strategy-availability-table"
          :columns="columns"
          :rows="rows"
        >
        </a-rms-strategy-availability-table>
      </template>
      <template v-slot:loading>
        <v-card loading="">
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table-heading"></v-skeleton-loader>
              <v-skeleton-loader type="table-head"></v-skeleton-loader>
              <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
              <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
              <v-skeleton-loader type="table-tfoot"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as Utils from "@/utils.js";
import aRmsStrategyAvailabilityTable from "@/components/rms/aRmsStrategyAvailabilityTable";
import aBaseComponent from "@/components/aBaseComponent";

export default {
  name: "a-rms-day-availability",
  components: {
    aRmsStrategyAvailabilityTable,
    aBaseComponent
  },
  props: {
    date: {
      type: String,
      default: "",
      mandatory: true
    }
  },
  data: () => ({
    title: "",
    subscribed_modules: ["rms_roomtype_availability", "roomtype_types_mapped"]
  }),
  methods: {
    getFriendlyDay(date) {
      return Utils.getFriendlyDay(date);
    }
  },
  computed: {
    labels() {
      return this.rms.roomtype_availability.labels;
    },
    roomtypes() {
      return this.rms.roomtype_availability.roomtypes;
    },
    columns() {
      const columns = {};
      for (let roomtype of Object.keys(this.roomtypes)) {
        columns[roomtype] = Object.keys(this.roomtypes[roomtype]);
      }
      return columns;
    },
    rows() {
      const rows = [];
      for (const index in this.labels) {
        const row = { [this.labels[index]]: {} };
        for (const roomtype in this.roomtypes) {
          if (this.roomtypes.hasOwnProperty(roomtype)) {
            row[this.labels[index]][roomtype] = {};
            for (const type in this.roomtypes[roomtype]) {
              if (this.roomtypes[roomtype].hasOwnProperty(type)) {
                row[this.labels[index]][roomtype][type] = {};
                for (const kpi in this.roomtypes[roomtype][type]) {
                  if (this.roomtypes[roomtype][type].hasOwnProperty(kpi)) {
                    row[this.labels[index]][roomtype][type] = {
                      ...row[this.labels[index]][roomtype][type],
                      [kpi]: this.roomtypes[roomtype][type][kpi][index]
                    };
                  }
                }
              }
            }
          }
        }
        rows.push(row);
      }
      return rows;
    },
    ...mapState({
      rms: state => state.rms
    })
  }
};
</script>
