<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="mb-0">{{ $t("migrate_hotelsdot_desc") }}.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="mb-2" v-if="hotelsdot_imports">
          {{
            $t("hotelsdot_available_imports", {
              count: hotelsdot_imports.length
            })
          }}
        </div>
        <div class="mb-2" v-if="already_imports">
          {{
            $t("hotelsdot_already_imports", {
              count: already_imports.length
            })
          }}
        </div>
        <v-skeleton-loader type="sentences" v-else></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-switch
          v-model="override"
          :label="$t('override_existing_data')"
          class="ma-0"
          hide-details
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          color="secondary"
          rounded
          depressed
          class="px-5"
          @click="startImport"
          :disabled="!can_import"
        >
          {{ $t("start_import") }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center text-body-2 text-wrap">
        * {{ $t("no_rollback") }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "hotelsdot-migrate-imports",
  components: {},
  data: () => ({
    override: false,
    can_import: true
  }),
  computed: {
    hotelsdot_imports: function() {
      return this.available_imports
        ? this.available_imports.hotelsdot_imports
        : null;
    },
    already_imports: function() {
      return this.available_imports
        ? this.available_imports.already_imports
        : null;
    },
    ...mapState({
      available_imports: state => state.dataimport.hotelsdot_available_imports
    }),
    ...mapGetters({
      current_user: "current_user",
      current_hotel: "current_hotel"
    })
  },
  methods: {
    startImport() {
      this.can_import = false;
      this.$store
        .dispatch("migrateHotelsdotImports", {
          hotel_id: this.current_hotel.id,
          override: this.override
        })
        .then(() => this.updateImportList());
    },
    updateImportList() {
      this.$store.dispatch("fetchDataimportRevenueLastImports");
      this.can_import = true;
    },
    getAvailableImports() {
      if (this.current_hotel) {
        this.$store.dispatch("fetchHotelsdotAvailableImports", {
          hotel_id: this.current_hotel.id
        });
      }
    }
  },
  watch: {
    current_hotel: function() {
      this.getAvailableImports();
    }
  },
  mounted() {
    this.getAvailableImports();
  }
};

import { mapGetters, mapState } from "vuex";
</script>

<style scoped></style>
