<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-tooltip bottom dark color="#333333" v-if="current_hotel">
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" color="white" outlined small>
            <v-icon small class="mr-1">mdi-clock-outline</v-icon>
            {{ current_hotel.last_import_date.rate | formatDatetime }}
          </v-chip>
        </template>
        <span>
          {{ $t("last_update") }}
        </span>
      </v-tooltip>
    </template>
    <component :is="app_name" :ref="app_name" />
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import highlightsApp from "@/components/app/highlightsApp";

export default {
  components: {
    aPage,
    highlightsApp
  },
  data: () => ({
    app_id: "highlights",
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 133, //Mandatory number,
    main_filters: true,
    app_name: "highlights-app"
  }),
  computed: {
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
