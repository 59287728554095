<template>
  <a-page
    :id="'page-' + app_name"
    :image_source="image_source"
    :image_height="image_height"
    :main_filters="main_filters"
    :title="ota"
  >
    <component :is="app_name" :ref="app_name" :ota="ota" />
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import reputationOtaApp from "@/components/app/reputationOtaApp";

export default {
  components: {
    aPage,
    reputationOtaApp
  },
  props: {
    ota: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 350, //Mandatory number,
    main_filters: true,
    app_name: "reputation-ota-app"
  })
};
</script>
