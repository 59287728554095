<template>
  <div class="a-reputation-reviews-graph">
    <div class="d-flex justify-center data-info">
      <div class="text-center black--text">
        <v-icon class="title d-block mb-3" color="grey lighten-1">
          {{ icon }}
        </v-icon>
        <span
          class="text-h4 d-block black--text font-weight-regular mb-1"
          style="font-family: 'Roboto Condensed' !important;"
        >
          {{ value }}
        </span>
        <a-increment
          :value="vs_value"
          :kpi="kpi"
          :increment="increment"
          :increment_type="'percent'"
        ></a-increment>
      </div>
    </div>
    <zingchart
      :height="188"
      :width="width"
      :data="graph_settings"
      :series="graph_series"
      class="graph"
    ></zingchart>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aIncrement from "@/components/aIncrement";

export default {
  name: "a-reputation-reviews-graph",
  components: {
    aIncrement
  },
  props: {
    height: {
      type: [String, Number],
      default: "170"
    },
    width: {
      type: [String, Number],
      default: "100%"
    },
    kpi: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    increment: {
      type: [String, Number],
      default: null
    },
    vs_value: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    graph_settings() {
      var tooltip = this.chart_settings.tooltip;
      tooltip["align"] = "center";
      tooltip["text"] =
        "<span style='font-family:\"Roboto Condensed\";font-size: 20px;color: %backgroundcolor;'>%v%</span><br><span style='font-size: 13px;font-family:\"Roboto Condensed\";'>%t</span>";
      return {
        type: "ring",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "transparent",
        plot: {
          pieTransform: "flow=99",
          valueBox: {
            visible: false
          },
          animation: {
            delay: 0,
            effect: "ANIMATION_EXPAND_VERTICAL",
            method: "ANIMATION_LINEAR",
            sequence: "ANIMATION_BY_PLOT",
            speed: "600"
          },
          refAngle: 270,
          slice: "90%",
          tooltip: tooltip
        },
        plotarea: {
          margin: "0px 0px 0px 0px"
        }
      };
    },
    graph_series() {
      var series = [];
      for (var emotion of this.emotions) {
        series.push({
          values: [
            this.bi_report.report["new_reviews_" + emotion.code + "_avg"]
          ],
          backgroundColor: emotion.color_hexa,
          text:
            this.$t("reviews") + " " + this.$t(emotion.code + "s").toLowerCase()
        });
      }
      return series;
    },
    ...mapState({
      emotions: state => state.emotions,
      chart_settings: state => state.chart_settings,
      bi_report: state => state.bi.report
    })
  }
};
</script>

<style scoped>
.a-reputation-reviews-graph {
  position: relative;
}
.data-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.graph {
  position: relative;
  margin: -10px 0;
}
</style>
