<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <div v-if="!is_all_hotels">
        <v-select
          v-model="budget_year"
          :items="available_years.reverse()"
          mandatory
          rounded
          outlined
          dense
          class="mt-8"
          style="max-width: 120px;"
          :disabled="editing"
        ></v-select>
      </div>
    </template>
    <div
      class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
      style="position:relative"
      v-if="is_all_hotels"
    >
      <div class="px-10 mx-5">
        <h3 class="text-h3 d-block">
          {{ $t("which_hotel_you_want_setup") }}
        </h3>
        <v-btn
          class="mt-10"
          color="secondary"
          rounded
          @click="open_hotel_selector"
          >{{ $t("select_hotel") }}</v-btn
        >
      </div>
    </div>
    <div v-else class="">
      <div class="white--text mt-4 mb-12" style="position:relative">
        <v-card v-if="!isReady">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-card>
        <v-card v-else>
          <v-card-title
            :class="{
              'justify-end': !is_saving_year_budget,
              'd-block': is_saving_year_budget
            }"
          >
            <div class="progress w-100" v-if="is_saving_year_budget">
              <v-row>
                <v-col cols="6" class="text-right">
                  <h2>{{ $t("generating_budget") }}...</h2>
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    rounded
                    color="secondary"
                    v-model="progress_value"
                    height="30"
                  >
                    <strong>
                      {{ progress_text }}
                    </strong>
                  </v-progress-linear>
                </v-col>
              </v-row>
            </div>
            <div class="buttons-container" v-else>
              <div class="buttons-for-editing" v-if="!editing">
                <v-btn
                  color="secondary"
                  :disabled="!isReady"
                  class="ml-4"
                  rounded
                  @click="editing = true"
                  ><v-icon class="mr-2">mdi-pencil</v-icon
                  >{{ $t("modify") }}</v-btn
                >
                <v-menu offset-y v-if="daily_budget_visible">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      :disabled="!isReady"
                      class="ml-4"
                      rounded
                      v-on="on"
                      v-bind="attrs"
                      ><v-icon class="mr-2">mdi-cloud-upload</v-icon
                      >{{ $t("upload_budget") }}</v-btn
                    >
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-0">
                      <v-btn
                        text
                        block
                        x-large
                        class="px-0"
                        @click="show_upload_dialog = true"
                        >{{ $t("monthly") }}</v-btn
                      >
                    </v-list-item>
                    <v-list-item class="px-0">
                      <v-btn
                        text
                        block
                        x-large
                        class="px-0"
                        @click="show_upload_daily_dialog = true"
                        >{{ $t("daily") }}</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  color="secondary"
                  :disabled="!isReady"
                  class="ml-4"
                  rounded
                  @click="show_upload_dialog = true"
                  v-else
                  ><v-icon class="mr-2">mdi-cloud-upload</v-icon
                  >{{ $t("upload_budget") }}</v-btn
                >
                <v-btn
                  color="secondary"
                  :disabled="!isReady"
                  class="ml-4"
                  rounded
                  @click="copyPreviousYearBudget"
                  v-if="has_previous_year_budget"
                >
                  <v-icon class="mr-2">mdi-timer-plus-outline</v-icon>
                  {{ $t("extend_from_year", { year: year_before }) }}
                </v-btn>
                <v-menu offset-y v-if="daily_budget_visible">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      :disabled="!isReady"
                      class="ml-4"
                      rounded
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon class="mr-2">mdi-tray-arrow-down</v-icon>
                      {{ $t("download_excel") }}
                    </v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-0">
                      <v-btn
                        text
                        block
                        x-large
                        class="px-0"
                        @click="downloadBudget('monthly')"
                        target="_blank"
                        >{{ $t("monthly") }}</v-btn
                      >
                    </v-list-item>
                    <v-list-item class="px-0">
                      <v-btn
                        text
                        block
                        x-large
                        class="px-0"
                        @click="downloadBudget('daily')"
                        target="_blank"
                        >{{ $t("daily") }}</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  color="secondary"
                  :disabled="!isReady"
                  class="ml-4"
                  rounded
                  @click="downloadBudget('monthly')"
                  v-else
                >
                  <v-icon class="mr-2">mdi-tray-arrow-down</v-icon>
                  {{ $t("download_excel") }}
                </v-btn>
              </div>
              <div class="buttons-while-editing" v-else>
                <v-btn
                  color="secondary"
                  class="ml-4"
                  rounded
                  @click="saveNewBudget"
                  :disabled="!has_changes"
                >
                  {{ $t("save") }}
                </v-btn>
                <v-btn
                  class="ml-4"
                  rounded
                  text
                  depressed
                  @click="discardNewBudget"
                >
                  {{ $t("cancel") }}
                </v-btn>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <budget-kpis />
          </v-card-text>
        </v-card>
      </div>
    </div>
    <budget-upload-dialog />
    <budget-upload-daily-dialog v-if="daily_budget_visible" />
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";
import aPage from "@/components/aPage";
import BudgetKpis from "@/components/budget/BudgetKpis";
import BudgetUploadDialog from "@/components/budget/BudgetUploadDialog.vue";
import BudgetUploadDailyDialog from "@/components/budget/BudgetUploadDailyDialog.vue";
import { showError } from "@/utils.js";
export default {
  components: {
    aPage,
    BudgetKpis,
    BudgetUploadDialog,
    BudgetUploadDailyDialog
  },
  data: () => ({
    app_id: "budget-app",
    image_source: "/img/header-2.jpg",
    image_height: 300
  }),
  computed: {
    ...mapState({
      budget_store: state => state.budget,
      progress: state => state.budget.progress
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      available_years: "getHotelAvailableYears",
      has_previous_year_budget: "hasPreviousYearBudget",
      has_changes: "hasChanges",
      is_saving_year_budget: "isSavingYearBudget"
    }),
    is_all_hotels: function() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    budget_year: {
      get() {
        return this.budget_store.budget_year;
      },
      set(value) {
        this.$store.dispatch("setBudgetYear", value);
      }
    },
    editing: {
      get() {
        return this.budget_store.editing;
      },
      set(value) {
        this.$store.dispatch("setEditing", value);
      }
    },
    show_upload_dialog: {
      get() {
        return this.budget_store.show_upload_dialog;
      },
      set(value) {
        this.$store.dispatch("showUploadDialog", value);
      }
    },
    show_upload_daily_dialog: {
      get() {
        return this.budget_store.show_upload_daily_dialog;
      },
      set(value) {
        this.$store.dispatch("showUploadDailyDialog", value);
      }
    },
    year: function() {
      const d = new Date();
      return d.getFullYear().toString();
    },
    year_before: function() {
      return this.budget_year - 1;
    },
    isReady() {
      return (
        this.budget_store.kpis && Object.keys(this.budget_store.kpis).length > 0
      );
    },
    ready() {
      return this.current_hotel && this.current_hotel.id && this.budget_year;
    },
    progress_value() {
      return this.progress[this.budget_year] || 0;
    },
    progress_text() {
      return this.progress_value < 10 ? `<10%` : `${this.progress_value}%`;
    },
    daily_budget_visible() {
      return (
        this.current_user.permissions &&
        this.current_user.permissions.budget_daily
      );
    }
  },
  methods: {
    open_hotel_selector: function() {
      this.$store.dispatch("setShowHotelList", true);
    },
    getBudgetKpis() {
      this.$store
        .dispatch("getBudgetKpis", {
          hotel_id: this.current_hotel.id,
          year: this.budget_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getAvailableYears() {
      this.$store
        .dispatch("getAvailableYears", {
          hotel_id: this.current_hotel.id
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    saveNewBudget() {
      this.$store
        .dispatch("saveNewBudget", {
          hotel_id: this.current_hotel.id,
          year: this.budget_year
        })
        .then(() => {
          Utils.showSuccess(this, "budget_saved_message", "budget_saved_title");
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelYearData() {
      this.$store
        .dispatch("pollSavingYears", {
          hotel_id: this.current_hotel.id
        })
        .then(() => {
          this.getBudgetKpis();
          this.getAvailableYears();
        });
    },
    discardNewBudget() {
      this.$store.dispatch("discardNewBudget");
    },
    downloadBudget(type) {
      this.$store.dispatch("downloadBudget", {
        hotel_id: this.current_hotel.id,
        year: this.budget_year,
        type: type,
        hotel_name: this.current_hotel.name,
        type_name: this.$t(type)
      });
    },
    pollSavingYears() {
      this.$store.dispatch("pollSavingYears", {
        hotel_id: this.current_hotel.id
      });
    },
    copyPreviousYearBudget() {
      this.$store
        .dispatch("copyPreviousYearBudget", {
          hotel_id: this.current_hotel.id,
          year: this.year_before
        })
        .catch(() => {
          showError(this, "something_went_wrong");
        });
    }
  },
  watch: {
    ready() {
      this.getHotelYearData();
    }
  },
  mounted() {
    this.budget_year = Vue.moment()
      .year()
      .toString();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store && vm.current_hotel && vm.current_hotel.id) {
        vm.pollSavingYears();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearPollSavingYears");
    next();
  }
};
</script>
