<template>
  <div>
    <v-text-field
      class=""
      block
      v-model="search"
      rounded
      outlined
      dense
      clearable
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('search_calendar')"
    ></v-text-field>
    <v-data-table
      v-if="calendar_store"
      :headers="hotel_calendar_subscriptions_headers"
      :items="filteredCalendars"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`item.editable`]="{ item }">
        <v-chip v-if="item.editable" dark small color="primary"
          ><b>Si</b></v-chip
        >
        <v-chip v-else small><b>No</b></v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-switch
          :input-value="isSubscribed(item.id)"
          inset
          @change="toggleSubscription(item.id)"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import * as Utils from "@/utils.js";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    search: ""
  }),
  filters: {},
  watch: {},
  methods: {
    getCalendars() {
      this.$emit("getCalendars");
    },
    isSubscribed(calendar_id) {
      return this.getHotelCalendars.find(c => c.id === calendar_id)
        ? true
        : false;
    },
    toggleSubscription(calendar_id) {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_hotel_subscription"),
        color: "secondary"
      });
      if (this.isSubscribed(calendar_id)) {
        this.$store
          .dispatch("unsubscribeHotelCalendar", {
            calendar_id: calendar_id,
            hotel_id: this.current_hotel.id
          })
          .then(() => {
            this.$store.dispatch("setSnackbar", {
              title: this.$t("hotel_unsubscribed"),
              color: "accent"
            });
            this.getCalendars();
          })
          .catch(() => {
            Utils.showError(this, "suscription_not_modified");
          });
      } else {
        this.$store
          .dispatch("subscribeHotelCalendar", {
            calendar_id: calendar_id,
            hotel_id: this.current_hotel.id
          })
          .then(() => {
            this.$store.dispatch("setSnackbar", {
              title: this.$t("hotel_subscribed"),
              color: "accent"
            });
            this.getCalendars();
          })
          .catch(() => {
            Utils.showError(this, "suscription_not_modified");
          });
      }
    }
  },
  computed: {
    filteredCalendars() {
      let items = this.calendar_store.calendars;
      if (this.search && this.search.length > 0) {
        items = items.filter(i =>
          i.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return items;
    },
    hotel_calendar_subscriptions_headers() {
      return [
        {
          text: this.$t("calendar"),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("events_count"),
          align: "start",
          sortable: true,
          value: "events_count"
        },
        {
          text: this.$t("years_with_events"),
          align: "center",
          sortable: true,
          value: "available_years"
        },
        {
          text: this.$t("editable"),
          align: "center",
          sortable: true,
          value: "editable"
        },
        {
          text: null,
          align: "left",
          sortable: false,
          value: "actions"
        }
      ];
    },
    ...mapState({
      calendar_store: state => state.calendar,
      hotel_events: state => state.calendar.hotel_events
    }),
    ...mapGetters({
      getHotelCalendars: "getHotelCalendars",
      current_hotel: "current_hotel"
    })
  }
};
</script>
