<template>
  <div class="">
    <v-row @mouseleave="reportLocation(undefined)" class="bottom-shadow mx-0">
      <v-col class="spacer pa-0"> </v-col>
      <v-col class="pa-0 ma-0">
        <v-window v-model="current_slide">
          <v-window-item v-for="(item, itemKey) of windowItems" :key="itemKey">
            <v-row class="pa-0 ma-0">
              <v-col
                v-for="day of days"
                class="special-rates-day-column simple_view pa-0"
                :class="{
                  'bg-weekend': isWeekend(day),
                  'border-highlight': beingHovered(day)
                }"
                :key="day"
              >
                <div class="cell d-flex justify-center align-center day-cell">
                  <div class="day-info">
                    <span class="day-heading grey--text text--darken-3">
                      {{ getDayName(day) }} {{ getDay(day) }}
                    </span>
                    <span class="grey--text">
                      {{ getMonthShortName(day) }} '{{ getShortYear(day) }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>

    <div class="d-block absolute full-width">
      <v-btn
        class="absolute left-arrow-nav-btn"
        :small="$vuetify.breakpoint.xsOnly"
        rounded
        fab
        color="white"
        :loading="loading_page"
        v-if="page > 0"
        @click="page--"
      >
        <v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-btn
        class="absolute right-arrow-nav-btn"
        :small="$vuetify.breakpoint.xsOnly"
        rounded
        fab
        color="white"
        :loading="loading_page"
        v-if="page < maxSlides"
        @click="page++"
      >
        <v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  name: "a-special-rates-table-row-days",
  props: {
    days: {
      type: Array,
      default: () => []
    },
    maxSlides: {
      type: Number
    }
  },
  data: () => ({
    loading_page: false,
    current_slide: 0
  }),
  mounted() {
    this.windowItems.push(this.days);
  },
  watch: {
    slide_status(newValue, oldValue) {
      if (newValue === 2) {
        this.loading_page = false;
        this.current_slide = this.page;
      }
      if (newValue === 0) {
        this.loading_page = true;
      }
    }
  },
  computed: {
    slide_status() {
      return this.$store.state.specialrates.slide_status;
    },
    page: {
      get() {
        return this.$store.state.specialrates.page;
      },
      async set(value) {
        await this.$store.dispatch("updateSpecialRatesPage", value);
      }
    },
    windowItems() {
      return this.$store.state.specialrates.windowItems;
    }
  },
  methods: {
    beingHovered(day) {
      return (
        this.$store.state.specialrates.hoveredRate &&
        this.$store.state.specialrates.hoveredRate.date === day
      );
    },
    isWeekend(day) {
      return Vue.moment(day).isoWeekday() >= 5;
    },
    dayFormatted(day) {
      return Vue.moment(day).format;
    },
    getDayName(day) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(day).format("dddd")
        : Vue.moment(day).format("dd");
    },
    getDay(day) {
      return Vue.moment(day).format("D");
    },
    getMonthShortName(day) {
      return Vue.moment(day)
        .format("MMM")
        .replace(".", "");
    },
    getShortYear(day) {
      return Vue.moment(day).format("YY");
    },
    reportLocation(event) {
      this.$store.dispatch("setSpecialRatesHoveredRate", event);
    },
    async click(event) {
      this.page = event;
    }
  }
};
</script>
<style lang="scss" scoped>
.spacer {
  min-width: $widthLeftSide;
  max-width: $widthLeftSide;
  z-index: 1;
}
.special-rates-day-column {
  width: 100%;
  &:hover .cell:has(.day-info) {
    border-bottom: 3px solid $highlightColRowColor;
  }
  .cell {
    height: $minHeightColRow;
    text-align: center;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    border-bottom: $borderType $borderColor;
    border-top: $borderType $borderColor;
    // padding: 2px 1px;
    span {
      width: 100%;
      display: block;
      line-height: 20px;
      font-size: 12px;
    }
    &:has(.room-info span.room-availability) {
      background-color: $bgColorRoom;
    }
    &.day-cell {
      border-bottom: 3px solid transparent;
      border-top: $borderType transparent;
      border-right: $borderType $borderColor;
      border-left: $borderType $borderColor;
    }
  }
  .day-heading {
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
    font-weight: 600;
  }
  .rate-wrapper {
    &:hover {
      background-color: #f5fdff;
    }
  }
}
::v-deep .border-highlight {
  .cell {
    border-bottom: 3px solid $highlightColRowColor !important;
  }
}
.bg-weekend {
  background-color: $weekendBgColor;
}
.bottom-shadow {
  box-shadow: 0px 10px 10px 0 rgb(0 0 0 / 14%);
}
.absolute {
  position: absolute;
  .right-arrow-nav-btn {
    top: -50px;
    right: -20px;
    z-index: 1;
  }
  .left-arrow-nav-btn {
    top: -50px;
    left: 250px;
    z-index: 1;
  }
}
::v-deep .v-window__container {
  height: $minHeightColRow;
}
</style>
