<template>
  <a-card title="what_does_guest_comment">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-reputation-daily-reviews-graph
          :ota="ota"
          :height="250"
          v-if="bi_report.report[`${ota}_new_reviews`]"
        ></a-reputation-daily-reviews-graph>
        <a-reputation-no-reviews v-else></a-reputation-no-reviews>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import aReputationDailyReviewsGraph from "@/components/reputation/aReputationDailyReviewsGraph.vue";
import aReputationNoReviews from "@/components/reputation/aReputationNoReviews.vue";

export default {
  name: "a-reputation-reviews",
  components: {
    aCard,
    aReputationDailyReviewsGraph,
    aReputationNoReviews,
    aBaseComponent
  },
  props: {
    kpi: {
      type: String,
      default: null
    },
    ota: {
      type: String,
      default: null
    }
  },
  data: () => ({
    subscribed_modules: ["bi_report", "bi_daily_report"]
  }),
  methods: {
    isVeetal(item) {
      return item.name === "veetal";
    }
  },

  computed: {
    items() {
      var items = [];
      items.push({
        main: true,
        name: "accumulated",
        logo: "/img/logo/veetal-imagotipo-sma.png",
        value: parseFloat(this.bi_report.report.new_reviews) || null,
        vs_value:
          parseFloat(this.bi_report.data_to_compare.new_reviews) || null,
        increment: parseFloat(this.bi_report.increments.new_reviews) || null
      });
      for (var ota of this.otas) {
        items.push({
          name: ota.code,
          logo: ota.logo,
          value:
            parseFloat(this.bi_report.report[ota.code + "_new_reviews"]) ||
            null,
          vs_value:
            parseFloat(
              this.bi_report.data_to_compare[ota.code + "_new_reviews"]
            ) || null,
          increment:
            parseFloat(this.bi_report.increments[ota.code + "_new_reviews"]) ||
            null
        });
      }

      return this.bi_report ? items : [];
    },
    ...mapState({
      bi_report: state => state.bi.report,
      otas: state => state.otas
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped>
.v-list-item:hover:not(.no-click) {
  background: #e4e8f5 !important;
}
</style>
