<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <v-data-table
        :headers="headers"
        :items="imports"
        hide-default-footer
        disable-pagination
        @click:row="showImportReport"
        :item-class="getDatatableRowClass"
        :no-data-text="$t('no_imports_yet')"
      >
        <template v-slot:[`item.import_date`]="{ item }">
          <b class="mr-1">{{ item.import_date | formatDate }}</b>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | formatDateFull }} {{ $t("at") }}
          {{ item.date | formatTime }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ $t(item.type) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip bottom dark color="#333333" max-width="200">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon v-if="item.status === 'pending'"
                  >mdi-progress-clock</v-icon
                >
                <v-icon v-if="item.status === 'processed'">mdi-check</v-icon>
                <v-icon v-if="item.status === 'error'"
                  >mdi-alert-circle-outline</v-icon
                >
                <v-icon v-if="item.status === 'deleted'"
                  >mdi-trash-can-outline</v-icon
                >
              </span>
            </template>
            <span>
              {{ item.status }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.file`]="{ item }">
          {{ item.file }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom dark color="#333333" v-if="item.file">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="downloadImportFile(item)"
                icon
                color="secondary"
                v-on="on"
              >
                <v-icon small>mdi-file-download-outline</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("download") }}
            </span>
          </v-tooltip>
          <v-tooltip
            bottom
            dark
            color="#333333"
            v-if="item.status === 'processed'"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="showDeleteImport(item)"
                icon
                color="secondary"
                v-on="on"
              >
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("delete") }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- DELETE IMPORT -->
      <v-dialog
        v-model="show_delete_import"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="400"
      >
        <v-card>
          <v-card-title center class="headline justify-center"
            >{{ $t("are_you_sure") }}
          </v-card-title>
          <v-card-text
            class="text-center justify-center"
            v-if="item_to_edit"
            v-html="$t('sure_delete_import')"
          >
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color="red"
              rounded
              depressed
              text
              class="pl-5 pr-5"
              @click="deleteImport()"
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              color=""
              text
              rounded
              depressed
              class="pl-5 pr-5"
              @click="
                show_delete_import = false;
                item_to_edit = null;
              "
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- SHOW IMPORT REPORT -->
      <v-dialog
        v-model="show_import_report"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="450"
      >
        <v-card>
          <v-card-title center class="headline justify-center py-6">
            {{ $t("import_report") }}
          </v-card-title>
          <v-card-actions
            v-if="item_to_edit && item_to_edit.status === 'pending'"
          >
            <v-progress-linear
              color="secondary"
              indeterminate
            ></v-progress-linear>
          </v-card-actions>
          <v-card-text class="text-center justify-center" v-if="item_to_edit">
            <v-list-item v-for="step in show_steps" :key="step.step">
              <v-list-item-icon>
                <v-progress-circular
                  v-if="stepStatus(step) === 'pending'"
                  indeterminate
                  :size="15"
                  :width="1"
                ></v-progress-circular>
                <v-icon v-else-if="stepStatus(step) === 'success'"
                  >mdi-check
                </v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="stepStatus(step) !== 'success'"
                  >{{ $t(step.title) }}
                </v-list-item-title>
                <v-list-item-title v-else
                  >{{ $t(step.title_done) }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="item_to_edit.report[step.name + '_rows']"
                  >{{ item_to_edit.report[step.name + "_rows"] }}
                  {{ $t("rows") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="item_to_edit.report[step.name + '_message']"
                  >{{ item_to_edit.report[step.name + "_message"] }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col cols="12" sm="12" class="py-0">
                <div
                  v-if="
                    item_to_edit.report &&
                      item_to_edit.report.log &&
                      item_to_edit.report.log.length > 0
                  "
                >
                  <v-card-title class="text-center"
                    >{{ $t("log_incidences") }}
                  </v-card-title>
                  <v-list-item
                    v-for="(log, index) in item_to_edit.report.log"
                    :key="index"
                  >
                    {{ log }}
                  </v-list-item>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color=""
              text
              rounded
              depressed
              class="pl-5 pr-5"
              @click="
                show_import_report = false;
                item_to_edit = null;
              "
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:loading>
      <v-card class="mt-2">
        <v-card-text>
          <v-skeleton-loader
            type="table-thead"
            class="mx-auto"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="table-tbody"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </template>
  </a-base-component>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ABaseComponent from "@/components/aBaseComponent.vue";

export default {
  components: { ABaseComponent },
  data: () => ({
    subscribed_modules: ["dataimport_revenue_last_imports"],
    item_to_edit: null,
    show_import_report: false,
    show_delete_import: false,
    import_steps: [
      {
        title: "checking_file",
        title_done: "checked_file",
        step: 1,
        name: "verify"
      },
      {
        title: "importing_data",
        title_done: "imported_data",
        step: 2,
        name: "import"
      },
      {
        title: "checking_import",
        title_done: "checked_import",
        step: 3,
        name: "check"
      }
    ],
    interval: null
  }),
  props: {},
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    showImportReport(item) {
      this.item_to_edit = item;
      this.show_import_report = true;
    },
    showDeleteImport(item) {
      this.item_to_edit = item;
      this.show_delete_import = true;
    },
    deleteImport() {
      this.$store
        .dispatch("deleteImport", {
          hotel_id: this.current_hotel.id,
          import_task_id: this.item_to_edit.import_task_id
        })
        .then(() => {
          this.show_delete_import = false;
          this.$store.dispatch("fetchDataimportRevenueLastImports");
        });
    },
    downloadImportFile(item) {
      this.$store.dispatch("downloadImportFile", {
        hotel_id: this.current_hotel.id,
        import_task_id: item.import_task_id,
        file: item.file
      });
    },
    stepStatus(step) {
      if (this.item_to_edit.report[step.name + "_status"]) {
        return this.item_to_edit.report[step.name + "_status"] === "ok"
          ? "success"
          : this.item_to_edit.report[step.name + "_status"] === "pending"
          ? "pending"
          : "error";
      } else {
        return null;
      }
    },
    updateImportList() {
      if (this.pending_imports.length > 0) {
        setTimeout(() => {
          this.$store.dispatch("fetchDataimportRevenueLastImports");
        }, 5000);
      }
    }
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("data_of"),
          align: "left",
          sortable: true,
          value: "import_date"
        },
        {
          text: this.$t("imported_on"),
          align: "left",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("type"),
          align: "left",
          sortable: true,
          value: "type"
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: true,
          value: "status"
        },
        {
          text: this.$t("file"),
          align: "left",
          sortable: true,
          value: "file"
        }
      ];
      headers.push({
        text: null,
        align: "right",
        sortable: false,
        value: "actions",
        width: "120px"
      });
      return headers;
    },
    show_steps() {
      const show_steps = [];
      for (let step of this.import_steps) {
        if (this.stepStatus(step) === null) {
          continue;
        }
        show_steps.push(step);
        if (this.stepStatus(step) === "error") {
          break;
        }
      }
      return show_steps;
    },
    pending_imports() {
      return this.imports.filter(item => item.status === "pending");
    },
    ...mapState({
      imports: state => state.dataimport.revenue_last_imports
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  watch: {
    pending_imports: function(val) {
      if (val.length > 0) {
        this.updateImportList();
      }
    }
  },
  mounted() {}
};
</script>
