<template>
  <v-row>
    <v-col cols="12">
      <v-card color="grey lighten-4" outlined>
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3"
        >
          Pickup
        </v-card-title>
        <v-card-text class="white pa-0">
          <a-rms-day-general-pickup-columns
            :columns="columns_pickup"
            :dense="dense"
          ></a-rms-day-general-pickup-columns>
          <a-rms-day-general-pickup-row
            v-for="(row, index) in rows_pickups"
            :key="index"
            :columns="columns_pickup"
            :rowData="row"
            :rowIndex="index"
            :dense="dense"
          ></a-rms-day-general-pickup-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aRmsDayGeneralPickupColumns from "@/components/rms/hotelsdot/aRmsDayGeneralPickupColumns.vue";
import aRmsDayGeneralPickupRow from "@/components/rms/hotelsdot/aRmsDayGeneralPickupRow.vue";

export default {
  name: "a-rms-day-general-pickup",
  components: {
    aRmsDayGeneralPickupColumns,
    aRmsDayGeneralPickupRow
  },
  props: {
    date: String,
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rows_kpis: [],
    rows_pickups: []
  }),
  computed: {
    columns_pickup() {
      return [
        {
          title: "Rev",
          align: "left",
          sortable: true,
          value: "room_revenue"
        },
        {
          title: "Adr",
          align: "left",
          sortable: true,
          value: "adr"
        },
        {
          title: "Rn",
          align: "left",
          sortable: true,
          value: "rn"
        },
        {
          title: "Occ",
          align: "left",
          sortable: true,
          value: "occupancy"
        },
        {
          title: "Revpar",
          align: "left",
          sortable: true,
          value: "revpar"
        }
      ];
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    getKpisDay(date) {
      this.$store
        .dispatch("fetchRmsKpisDay", date)
        .then(response => {
          this.rows_kpis = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getKpisDayPickups(date) {
      this.$store
        .dispatch("fetchRmsKpisDayPickups", date)
        .then(response => {
          this.rows_pickups = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    async getHotelComparativeDates() {
      await this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: Vue.moment(this.date)
            .startOf("year")
            .format("YYYY-MM-DD"),
          to_date: Vue.moment(this.date)
            .endOf("year")
            .format("YYYY-MM-DD")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }
  },
  mounted() {
    this.getKpisDay(this.date);
    this.getKpisDayPickups(this.date);
    this.getHotelComparativeDates();
  }
};
</script>
