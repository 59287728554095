<template>
  <v-row class="kpi-row mx-0" :class="dense ? 'dense' : ''">
    <v-col class="kpi-label-wrapper pa-0">
      <div class="kpi-label d-flex align-center">
        <a-period-compare
          :periods="periods(rowData['spot'])"
          dense
        ></a-period-compare>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="(col, index) of columns"
          :key="index"
          class="pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center"
          @mouseover="kpi_hover = col.value"
          @mouseleave="kpi_hover = ''"
        >
          <!-- <a-kpi
            :kpi="col.value"
            :value="rowData[col.value]"
            :vs_values="vs_values(col)"
            :vs_periods="['otb']"
            x_small
          ></a-kpi> -->
          <a-kpi-columns :kpi="col.value" :value="rowData[col.value]" x_small>
            <template v-slot:right>
              <a-increment
                v-if="vs_values(col)"
                :value="vs_values(col)"
                :vs_value="rowData[col.value]"
                :kpi="col.value"
                :hide_tooltip="true"
              >
              </a-increment>
            </template>
          </a-kpi-columns>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import aIncrement from "@/components/rms_ui/aIncrement.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aKpiColumns from "@/components/rms_ui/aKpiColumns.vue";

export default {
  name: "a-rms-day-general-kpi-row",
  components: {
    aKpiColumns,
    aIncrement,
    aPeriodCompare
  },
  props: {
    columns: Array,
    rowData: Object,
    rowIndex: Number,
    dense: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    kpi_hover: {
      get() {
        return this.$store.state.rms.day_general_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsDayGeneralKpiHover", value);
      }
    }
  },
  methods: {
    vs_values(col) {
      if (col.value + "_vs" in this.rowData) {
        return this.rowData[col.value + "_vs"];
      }
      return null;
    },
    periods(spot) {
      if (spot === "otb") {
        return [this.rowData["spot"]];
      } else {
        return [this.rowData["spot"], "otb"];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.kpi-row {
  &:hover {
    .kpi-label-wrapper {
      .kpi-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  &.dense {
    .kpi-label-wrapper {
      .kpi-label {
        min-height: 40px;
      }
    }
    .kpi-cell {
      height: 40px;
    }
  }
  .kpi-label-wrapper {
    min-width: 150px;
    max-width: 150px;
    .kpi-label {
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
</style>
