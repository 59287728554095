<template>
  <div id="parity-screenshots" class="d-flex rate-data-row">
    <div class="item rate-labels semibold grey lighten-5">
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class=""
        style="min-height: 30px; height: 30px"
      >
        <div
          id="as_seen_on_googlehotelad"
          class="d-flex align-center py-1 pl-3 font-weight-bold grey--text text--darken-2 grey lighten-5"
        >
          <span>{{ $t("as_seen_on") }}</span>
          <v-img
            :max-width="100"
            :max-height="20"
            contain
            class="mx-1"
            src="/img/otas/googlehotelads.png"
          />
          <span>:</span>
        </div>
      </div>
    </div>
    <div class="item days grey lighten-5">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="(day, index) in days"
          :key="'screenshot-' + day"
          class="day grey lighten-5 pa-0"
          style="min-height: 30px; height: 30px"
          :class="getDayClass(day)"
          @mouseover="
            date_hover = day;
            day_hover = index;
          "
          @mouseleave="
            date_hover = '';
            day_hover = '';
          "
        >
          <div
            v-if="mustRender(day)"
            class="semibold grey lighten-5 visible-day d-flex align-center justify-center"
            :class="'visible-day-' + index"
            style="min-height: 30px; height: 30px"
          >
            <v-tooltip color="black" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  x-small
                  color="grey "
                  icon
                  @click="
                    show_screenshot = true;
                    screenshot_to_show =
                      daily_screenshots[day].urls_screenshots[0];
                  "
                >
                  <v-icon>mdi-camera-outline</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("see_screenshot") }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      id="rate-screenshot-detail"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show_screenshot"
    >
      <v-card id="rate-highlight">
        <v-card-title class="headline justify-center text-capitalize">
          <span class="title text-uppercase ml-1">
            {{ $t("screenshot") }}
            <span class="subtitle-2 grey--text ml-1">
              {{ $t("screenshot_taken_on") }} {{ datetime }}
            </span>
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            color="secondary"
            rounded
            @click="downloadScreenshot"
          >
            <v-icon class="mr-1">mdi-download-outline</v-icon>
            {{ $t("download") }}</v-btn
          >
          <v-btn icon @click="show_screenshot = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="grey lighten-5">
          <v-img :src="screenshot_to_show" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-parity-min-price",
  components: {},
  props: [
    "days",
    "days_per_screen",
    "days_to_render",
    "daily_screenshots",
    "datetime"
  ],
  data: vm => ({
    hover: false,
    show_screenshot: false,
    screenshot_to_show: ""
  }),
  methods: {
    getDayClass(day) {
      var clas = "";
      clas += this.is_weekend(day) ? " weekend" : "";
      return clas;
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    downloadScreenshot() {
      window.open(this.screenshot_to_show + "?download=1", "_blank");
    },
    // changeChannelVisibility(channel) {
    //   if (this.isInvisible(channel.id)) {
    //     this.$store.dispatch("removeVisibleChannel", channel.id);
    //   } else {
    //     this.$store.dispatch("addVisibleChannel", channel.id);
    //   }
    // },
    // showDate(date, channel_id) {
    //   if (this.groupDisplay) {
    //     const c = this.getCompetitorById(channel_id);

    //     this.$store.dispatch("setCurrentHotelById", c.hotel_id);
    //     this.$router.push({ name: "rate" });
    //   } else {
    //     this.show_rate_date_to_compare = true;
    //     this.$store.dispatch("setComparedCompetitors", [channel_id]);
    //     this.$store.dispatch("setRateDateToCompare", date);
    //   }
    // },
    // getDOW(date) {
    //   return Vue.moment(date).format("d");
    // },
    // getDayName(date) {
    //   return this.$vuetify.breakpoint.xlOnly
    //     ? Vue.moment(date).format("dddd")
    //     : Vue.moment(date).format("dd");
    // },
    // getDay(date) {
    //   return Vue.moment(date).format("D");
    // },
    // getDayShortName(date) {
    //   return Vue.moment(date).format("dd");
    // },
    // getMonth(date) {
    //   return Vue.moment(date).format("MM");
    // },
    // getMonthShortName(date) {
    //   return Vue.moment(date)
    //     .format("MMM")
    //     .replace(".", "");
    // },
    // getMonthName(date) {
    //   return Vue.moment(date).format("MMMM");
    // },
    // getShortYear(date) {
    //   return Vue.moment(date).format("YY");
    // },
    // isHover(date) {
    //   return this.date_hover == date;
    // },
    mustRender(date) {
      // Must render if date inside days_per_screen
      return (
        this.days_to_render.indexOf(date) >= 0 && this.daily_screenshots[date]
      );
    }
    // isInvisible(id) {
    //   return this.invisible_channels.indexOf(id) >= 0;
    // }
  },
  computed: {
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },

    ...mapState({
      channels: state => state.parity.channels,
      invisible_channels: state => state.parity.invisible_channels,
      parity_colors: state => state.parity_colors
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getCompetitorById: "getCompetitorById"
    })
  }
};
</script>
<style>
.a-rate-tooltip {
  margin-top: 10px;
  background: white;
  z-index: 310 !important;
  color: black !important;
  opacity: 1;
  font-weight: 500;
  border: 2px solid #dedede;
  text-align: center;
}
.a-rate-position.increment {
  background: green;
}
.a-rate-position.decrement {
  background: rgb(155, 9, 9);
}
.a-rate-position {
  position: absolute;
  top: -8px;
  right: -5px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: #999999;
  color: white;
  font-weight: 500;
  font-size: 10px;
  z-index: 320;
  line-height: 25px;
}
.rate_data_main > div {
  position: relative;
}

.channel {
  cursor: pointer;
}
.invisible-channel {
  opacity: 0.5;
}
</style>
