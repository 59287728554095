<template>
  <v-card color="grey lighten-4">
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      {{ $t("comments") }}
    </v-card-title>
    <div class="table-header px-4 d-flex">
      <v-text-field
        class=""
        block
        v-model="search"
        rounded
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search_comment')"
        background-color="white"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-tooltip bottom dark color="#333333">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-1"
            @click="showAddComment"
            rounded
            color="secondary"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            {{ $t("add_comment") }}
          </v-btn>
        </template>
        <span>
          {{ $t("create_comment") }}
        </span>
      </v-tooltip>
    </div>
    <v-card-text class="white ">
      <v-data-table
        :headers="comments_headers"
        :items="filteredComments"
        hide-default-footer
        disable-pagination
        :item-class="getDatatableRowClass"
        @click:row="showEditComment"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ getFriendlyDate(item.date) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom dark color="#333333">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="showDeleteComment(item)"
                icon
                color="secondary"
                v-on="on"
                ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
              >
            </template>
            <span>
              {{ $t("delete") }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- DELETE COMMENT -->
      <v-dialog
        v-model="show_delete_comment"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="400"
      >
        <v-card>
          <v-card-title center class="headline justify-center">{{
            $t("are_you_sure")
          }}</v-card-title>
          <v-card-text
            class="text-center justify-center"
            v-if="item_to_edit"
            v-html="$t('sure_delete_comment')"
          >
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color="red"
              rounded
              depressed
              text
              class="pl-5 pr-5"
              @click="deleteComment()"
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              color=""
              text
              rounded
              depressed
              class="pl-5 pr-5"
              @click="hideDeleteComment"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- CREATE/EDIT COMMENT -->
      <v-dialog
        v-model="show_edit_comment"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="450"
      >
        <v-card>
          <v-card-title
            center
            class="headline justify-center py-6"
            v-if="item_to_edit.id"
            >{{ $t("edit_comment") }}
          </v-card-title>
          <v-card-title center class="headline justify-center py-6" v-else
            >{{ $t("new_comment") }}
          </v-card-title>
          <v-card-text class="text-center justify-center" v-if="item_to_edit">
            <v-form ref="edit_comment_form" v-model="edit_comment_form">
              <v-row>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field
                    :label="$t('date')"
                    outlined
                    rounded
                    dense
                    readonly
                    disabled
                    :value="getFriendlyDate(item_to_edit.date)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    v-model="item_to_edit.comment"
                    maxlength="255"
                    counter
                    :label="$t('comment')"
                    :rules="[v => !!v || $t('required')]"
                    :placeholder="$t('write_your_comment')"
                    outlined
                    rounded
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color="secondary"
              rounded
              depressed
              class="pl-5 pr-5"
              @click="setComment()"
              :disabled="!edit_comment_form"
              v-if="item_to_edit.id"
            >
              {{ $t("set_comment") }}
            </v-btn>
            <v-btn
              v-else
              color="secondary"
              rounded
              depressed
              class="pl-5 pr-5"
              @click="addComment()"
              :disabled="!edit_comment_form"
            >
              {{ $t("add_comment") }}
            </v-btn>
            <v-btn
              color=""
              text
              rounded
              depressed
              class="pl-5 pr-5"
              @click="hideEditComment"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import * as Utils from "@/utils.js";

export default {
  name: "a-rms-day-comments",
  props: {
    date: String
  },
  data: () => ({
    comments: [],
    item_to_edit: {},
    show_delete_comment: false,
    show_edit_comment: false,
    edit_comment_form: false,
    search: ""
  }),
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    async getHotelComments() {
      await this.$store
        .dispatch("getHotelComments", {
          hotel_id: this.current_hotel.id
        })
        .then(response => {
          this.comments = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    clearEditForm() {
      if (this.$refs.edit_comment_form) {
        this.$refs.edit_comment_form.resetValidation();
      }
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date, true);
    },
    showDeleteComment(comment) {
      if (!comment) {
        return;
      }
      this.show_delete_comment = true;
      this.item_to_edit = comment;
    },
    hideDeleteComment() {
      this.show_delete_comment = false;
      this.item_to_edit = {};
    },
    deleteComment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_comment"),
        color: "secondary"
      });
      this.$store
        .dispatch("deleteComment", { id: this.item_to_edit.id })
        .then(() => {
          this.show_delete_comment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comment_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_comment = false;
          this.item_to_edit = {};
          Utils.showError(this, "comment_not_deleted");
        });
    },
    showAddComment() {
      this.clearEditForm();
      this.show_edit_comment = true;
      this.item_to_edit = {
        id: null,
        hotel_id: this.current_hotel.id,
        date: Vue.moment().format("YYYY-MM-DD")
      };
    },
    addComment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_comment"),
        color: "secondary"
      });
      this.$store
        .dispatch("addComment", this.item_to_edit)
        .then(() => {
          this.show_edit_comment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comment_added"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_comment = false;
          this.item_to_edit = {};
          Utils.showError(this, "comment_not_added");
        });
    },
    showEditComment(comment) {
      if (!comment) {
        return;
      }
      this.clearEditForm();
      this.show_edit_comment = true;
      this.item_to_edit = JSON.parse(JSON.stringify(comment));
    },
    hideEditComment() {
      this.show_edit_comment = false;
      this.item_to_edit = {};
    },
    setComment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_comment"),
        color: "secondary"
      });
      this.$store
        .dispatch("setComment", this.item_to_edit)
        .then(() => {
          this.show_edit_comment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comment_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_comment = false;
          this.item_to_edit = {};
          Utils.showError(this, "comment_not_modified");
        });
    }
  },
  computed: {
    comments_headers() {
      return [
        {
          text: this.$t("day"),
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("user"),
          align: "left",
          sortable: true,
          value: "user_full_name"
        },
        {
          text: this.$t("comment"),
          align: "left",
          sortable: true,
          value: "comment",
          width: "50%"
        },
        {
          text: this.$t("writed_at"),
          align: "left",
          sortable: true,
          value: "creation_datetime"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions"
        }
      ];
    },
    filteredComments() {
      let items = this.comments;
      items = items.filter(i => i.date == this.date);
      if (this.search && this.search.length > 0) {
        items = items.filter(i =>
          i.comment.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return items;
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  mounted() {
    this.getHotelComments();
  }
};
</script>
