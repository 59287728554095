<template>
  <v-row class="mx-0">
    <v-col cols="12" class="pa-0">
      <v-row class="row-item px-3">
        <v-col class="item-wrapper pa-0" :class="bgCellClass('total')">
          <div class="item-label d-flex align-center">
            <div class="grey--text text--darken-3 text-uppercase">
              {{ getFriendlyDateTop(Object.keys(this.rowData)[0]) }}
            </div>
            <div class="grey--text text-capitalize">
              {{ getFriendlyDateBottom(Object.keys(this.rowData)[0]) }}
            </div>
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 roomtypes-container">
          <v-row class="pa-0 ma-0 roomtypes-wrapper" :style="styleRow">
            <v-col v-for="(column, index) of columns" :key="index" class="pa-0">
              <v-row class="roomtype-cell-row">
                <v-col
                  v-for="col of column"
                  :key="col"
                  :class="bgCellClass(index)"
                  @mouseover="kpi_hover = index + '_' + col"
                  @mouseleave="kpi_hover = ''"
                  class="pa-0 pt-1 kpi-cell d-flex align-center justify-center"
                >
                  <template v-if="col === 'free' && isVisibleItem('free')">
                    <a-kpi
                      class="mr-1"
                      kpi="rn"
                      :value="rowValues[index][col]['otb']"
                      :vs_values="[
                        rowValues[index][col]['otb'] -
                          rowValues[index][col][vs_pickup]
                      ]"
                      :vs_periods="[vs_pickup]"
                      x_small
                      absolute
                    ></a-kpi>
                  </template>
                  <template v-if="col === 'locked' && isVisibleItem('locked')">
                    <a-kpi
                      class="mr-1"
                      :kpi="'rn'"
                      :value="rowValues[index][col]['otb']"
                      :vs_values="[
                        rowValues[index][col]['otb'] -
                          rowValues[index][col][vs_pickup]
                      ]"
                      :vs_periods="[vs_pickup]"
                      x_small
                      absolute
                    ></a-kpi>
                  </template>
                  <template v-if="col === 'mlos' && isVisibleItem('mlos')">
                    <template
                      v-if="highlightMlos(rowValues[index][col]['otb'])"
                    >
                      <v-avatar color="orange" size="30">
                        <a-kpi
                          class="white--text"
                          :kpi="'mlos'"
                          :value="rowValues[index][col]['otb']"
                          x_small
                        ></a-kpi>
                      </v-avatar>
                      <a-kpi
                        class="mr-1"
                        :kpi="'mlos'"
                        :value="rowValues[index][col]['otb']"
                        :vs_values="[
                          rowValues[index][col]['otb'] -
                            rowValues[index][col][vs_pickup]
                        ]"
                        :vs_periods="[vs_pickup]"
                        hide_kpi
                        x_small
                      ></a-kpi>
                    </template>
                    <template v-else>
                      <a-kpi
                        class="mr-1"
                        :kpi="'mlos'"
                        :value="rowValues[index][col]['otb']"
                        :vs_values="[
                          rowValues[index][col]['otb'] -
                            rowValues[index][col][vs_pickup]
                        ]"
                        :vs_periods="[vs_pickup]"
                        x_small
                      ></a-kpi>
                    </template>
                  </template>
                  <template
                    v-if="col === 'occupancy' && isVisibleItem('occupancy')"
                  >
                    <a-kpi
                      class="mr-1 green--text"
                      :class="occupancyColor(rowValues[index][col]['otb'])"
                      :kpi="'occupancy'"
                      :value="rowValues[index][col]['otb']"
                      :vs_values="[
                        rowValues[index][col]['otb'] -
                          rowValues[index][col][vs_pickup]
                      ]"
                      :vs_periods="[vs_pickup]"
                      x_small
                      absolute
                    ></a-kpi>
                  </template>
                  <template v-if="col === 'pvp' && isVisibleItem('pvp')">
                    <a-kpi
                      class="mr-1"
                      :kpi="'pvp'"
                      :value="rowValues[index][col]['otb']"
                      :vs_values="[
                        rowValues[index][col]['otb'] -
                          rowValues[index][col][vs_pickup]
                      ]"
                      :vs_periods="[vs_pickup]"
                      x_small
                      absolute
                    ></a-kpi>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import aKpi from "@/components/rms_ui/aKpi.vue";

export default {
  name: "a-rms-strategy-availability-row",
  components: {
    aKpi
  },
  props: {
    columns: Object,
    rowData: Object,
    rowIndex: Number,
    label: String,
    vs_pickup: String,
    only_rooms: Boolean,
    page: Number,
    roomtypes_per_screen: Number,
    roomtypes_total: Number
  },
  data: () => ({
    min_occupancy: 30,
    max_occupancy: 70,
    limit_mlos: 2
  }),
  computed: {
    rowValues() {
      return Object.values(this.rowData)[0];
    },
    kpi_hover: {
      get() {
        return this.$store.state.rms.availability_roomtype_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsAvailabilityRoomtypeKpiHover", value);
      }
    },
    styleRow() {
      let width = (this.roomtypes_total / this.roomtypes_per_screen) * 100;
      if (width < 100) {
        width = 100;
      }
      return (
        "transform: translateX(calc(((-100%) * " +
        this.roomtypes_per_screen +
        " * " +
        this.page +
        ") / " +
        this.roomtypes_total +
        "));" +
        "width: " +
        width +
        "%"
      );
    }
  },
  methods: {
    getFriendlyDateTop(date) {
      return date
        ? Vue.moment(date)
            .format("ddd D")
            .replace(".", "")
        : "";
    },
    getFriendlyDateBottom(date) {
      return date
        ? Vue.moment(date)
            .format("MMM 'YY")
            .replace(".", "")
        : "";
    },
    isWeekend() {
      return Vue.moment(Object.keys(this.rowData)[0]).isoWeekday() >= 5;
    },
    isClosed(value) {
      return value <= 0;
    },
    bgCellClass(roomtype) {
      let classes = [];
      if (this.isWeekend()) {
        classes.push("weekend");
      }
      if (this.isClosed(this.rowValues[roomtype]["free"]["otb"])) {
        classes.push("closed");
      }
      return classes.join(" ");
    },
    highlightMlos(v) {
      return v >= this.limit_mlos;
    },
    occupancyColor(value) {
      return value < this.min_occupancy
        ? "red--text text--darken-2"
        : value > this.max_occupancy
        ? "green--text"
        : "";
    },
    isVisibleItem(kpi) {
      if (this.only_rooms && (kpi === "free" || kpi === "locked")) {
        return true;
      } else if (!this.only_rooms) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.row-item {
  &:hover {
    .item-wrapper {
      .item-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  .item-wrapper {
    min-width: 150px;
    max-width: 150px;
    .item-label {
      border-left: $borderType $borderColorV;
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
    }

    &.weekend {
      background: rgba(219, 198, 77, 0.1);
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &.weekend {
      background: rgba(219, 198, 77, 0.1);
    }
    &.closed {
      background: rgba(219, 98, 77, 0.1);
    }
    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
.roomtypes-container {
  overflow: hidden;
  .roomtypes-wrapper {
    transition: transform 0.2s;
    transition-timing-function: ease;
    .roomtype-cell-row {
      margin: 0;
      .kpi-cell {
        &:last-child {
          border-right: $borderType #c5c5c5;
        }
      }
    }
  }
}
</style>
