var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"row-item px-3"},[_c('v-col',{staticClass:"item-wrapper pa-0",class:_vm.bgCellClass('total')},[_c('div',{staticClass:"item-label d-flex align-center"},[_c('div',{staticClass:"grey--text text--darken-3 text-uppercase"},[_vm._v(" "+_vm._s(_vm.getFriendlyDateTop(Object.keys(this.rowData)[0]))+" ")]),_c('div',{staticClass:"grey--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.getFriendlyDateBottom(Object.keys(this.rowData)[0]))+" ")])])]),_c('v-col',{staticClass:"pa-0 ma-0 roomtypes-container"},[_c('v-row',{staticClass:"pa-0 ma-0 roomtypes-wrapper",style:(_vm.styleRow)},_vm._l((_vm.columns),function(column,index){return _c('v-col',{key:index,staticClass:"pa-0"},[_c('v-row',{staticClass:"roomtype-cell-row"},_vm._l((column),function(col){return _c('v-col',{key:col,staticClass:"pa-0 pt-1 kpi-cell d-flex align-center justify-center",class:_vm.bgCellClass(index),on:{"mouseover":function($event){_vm.kpi_hover = index + '_' + col},"mouseleave":function($event){_vm.kpi_hover = ''}}},[(col === 'free' && _vm.isVisibleItem('free'))?[_c('a-kpi',{staticClass:"mr-1",attrs:{"kpi":"rn","value":_vm.rowValues[index][col]['otb'],"vs_values":[
                      _vm.rowValues[index][col]['otb'] -
                        _vm.rowValues[index][col][_vm.vs_pickup]
                    ],"vs_periods":[_vm.vs_pickup],"x_small":"","absolute":""}})]:_vm._e(),(col === 'locked' && _vm.isVisibleItem('locked'))?[_c('a-kpi',{staticClass:"mr-1",attrs:{"kpi":'rn',"value":_vm.rowValues[index][col]['otb'],"vs_values":[
                      _vm.rowValues[index][col]['otb'] -
                        _vm.rowValues[index][col][_vm.vs_pickup]
                    ],"vs_periods":[_vm.vs_pickup],"x_small":"","absolute":""}})]:_vm._e(),(col === 'mlos' && _vm.isVisibleItem('mlos'))?[(_vm.highlightMlos(_vm.rowValues[index][col]['otb']))?[_c('v-avatar',{attrs:{"color":"orange","size":"30"}},[_c('a-kpi',{staticClass:"white--text",attrs:{"kpi":'mlos',"value":_vm.rowValues[index][col]['otb'],"x_small":""}})],1),_c('a-kpi',{staticClass:"mr-1",attrs:{"kpi":'mlos',"value":_vm.rowValues[index][col]['otb'],"vs_values":[
                        _vm.rowValues[index][col]['otb'] -
                          _vm.rowValues[index][col][_vm.vs_pickup]
                      ],"vs_periods":[_vm.vs_pickup],"hide_kpi":"","x_small":""}})]:[_c('a-kpi',{staticClass:"mr-1",attrs:{"kpi":'mlos',"value":_vm.rowValues[index][col]['otb'],"vs_values":[
                        _vm.rowValues[index][col]['otb'] -
                          _vm.rowValues[index][col][_vm.vs_pickup]
                      ],"vs_periods":[_vm.vs_pickup],"x_small":""}})]]:_vm._e(),(col === 'occupancy' && _vm.isVisibleItem('occupancy'))?[_c('a-kpi',{staticClass:"mr-1 green--text",class:_vm.occupancyColor(_vm.rowValues[index][col]['otb']),attrs:{"kpi":'occupancy',"value":_vm.rowValues[index][col]['otb'],"vs_values":[
                      _vm.rowValues[index][col]['otb'] -
                        _vm.rowValues[index][col][_vm.vs_pickup]
                    ],"vs_periods":[_vm.vs_pickup],"x_small":"","absolute":""}})]:_vm._e(),(col === 'pvp' && _vm.isVisibleItem('pvp'))?[_c('a-kpi',{staticClass:"mr-1",attrs:{"kpi":'pvp',"value":_vm.rowValues[index][col]['otb'],"vs_values":[
                      _vm.rowValues[index][col]['otb'] -
                        _vm.rowValues[index][col][_vm.vs_pickup]
                    ],"vs_periods":[_vm.vs_pickup],"x_small":"","absolute":""}})]:_vm._e()],2)}),1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }