<template>
  <v-card color="grey lighten-4 mb-2 mt-0" id="rms-year-sales-chart">
    <v-card-text class="white px-0 pt-0 pb-6">
      <v-row>
        <v-col cols="12" class="">
          <v-toolbar-title
            class="px-3 font-weight-bold text-uppercase subtitle-2 mr-2 d-flex align-center"
          >
            <span>{{ $t("year_evolution") }}</span>
            &nbsp;
            <span class="pl-1 secondary--text"> {{ year }} </span>
          </v-toolbar-title>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="3" class="offset-3 text-center">
          <a-label class="mb-0">
            {{ $t("occupancy") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare :periods="['otb', 'stly']"></a-period-compare>
          </div>
          <a-kpi
            kpi="occupancy"
            :value="yearValues.otb.occupancy"
            :vs_values="[yearValues.stly.occupancy]"
            :vs_periods="['stly']"
            large
          ></a-kpi>
        </v-col>
        <v-col cols="3" class="text-center">
          <a-label class="mb-0">
            {{ $t("adr") }}
          </a-label>
          <div class="mb-3">
            <a-period-compare :periods="['otb', 'stly']"></a-period-compare>
          </div>
          <a-kpi
            kpi="adr"
            :value="yearValues.otb.adr"
            :vs_values="[yearValues.stly.adr]"
            :vs_periods="['stly']"
            large
          ></a-kpi>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="12" class="col-xs-12 col-sm-12 col-md-6 pr-3">
          <a-year-sales-occupation-adr-chart
            height="330px"
            :id="'a-occupation-adr-chart-monthly'"
            :series="monthsGraphData.kpis"
            :labels="monthsGraphData.labels"
            :totals="totals"
          ></a-year-sales-occupation-adr-chart>
        </v-col>
        <v-col cols="12" class="col-xs-12 col-sm-12 col-md-6 pr-3">
          <a-year-sales-occupation-adr-chart
            height="330px"
            :id="'a-occupation-adr-chart-quarterly'"
            :series="quartersGraphData.kpis"
            :labels="quartersGraphData.labels"
            :totals="totals"
          ></a-year-sales-occupation-adr-chart>
        </v-col>
      </v-row> </v-card-text
  ></v-card>
</template>

<script>
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aLabel from "@/components/aLabel.vue";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aYearSalesOccupationAdrChart from "@/components/chart/aYearSalesOccupationAdrChart";
import { mapState } from "vuex";
export default {
  name: "a-rms-year-sales-charts",
  components: {
    aLabel,
    aKpi,
    aPeriodCompare,
    aYearSalesOccupationAdrChart
  },
  props: {
    monthsGraphData: Object,
    quartersGraphData: Object,
    yearValues: Object
  },
  data: () => ({}),
  computed: {
    year() {
      return this.rms.year_sales_year;
    },
    totals() {
      return {
        occupancy: {
          otb: this.yearValues.otb.occupancy,
          stly: this.yearValues.stly.occupancy
        },
        adr: {
          otb: this.yearValues.otb.adr,
          stly: this.yearValues.stly.adr
        }
      };
    },
    ...mapState({
      rms: "rms"
    })
  }
};
</script>

<style lang="scss" scoped></style>
