<template>
  <div id="rate-graph-bg" class="d-flex">
    <div class="item rate-labels semibold"></div>
    <div class="item days">
      <div class="days-wrapper no-flex" :class="'x' + days_per_screen">
        <div class="d-flex">
          <div
            v-for="day in days"
            :key="'rate-graph-' + day"
            class="day"
            :class="{ weekend: is_weekend(day) }"
            @mouseover="date_hover = day"
            @mouseout="date_hover = ''"
          >
            <div class="day-graph-bg"></div>
          </div>
        </div>
        <div class="rate-graph-wrapper" :class="'x' + days_per_screen">
          <zingchart
            height="250px"
            width="100%"
            :data="graph_data"
            :series="graph_series"
          ></zingchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import * as Utils from "@/utils.js";

// Global wrapper
if (!window.customFncs) {
  window.customFncs = {};
}

/*
 * Function under our ZC wrapper. Will
 * be fired when the node is hovered over
 *
 * @param {object} p ZingChart callback argument.
 * is associated to various graph properties
 */
var tooltips = [];
window.customFncs.formatCrosshairParity = function(p) {
  var text = tooltips[p.key];
  return {
    text: text,
    backgroundColor: "white",
    borderRadius: "3px",
    borderColor: "#cacaca",
    borderWidth: 2,
    lineHeight: "10px",
    htmlMode: true,
    fontFamily: "Roboto Condensed"
  };
};
function comparePrice(a, b) {
  if (a.min_price < b.min_price) {
    return -1;
  }
  if (a.min_price > b.min_price) {
    return 1;
  }
  return 0;
}

export default {
  name: "a-parity-graph-bg",
  components: {},
  props: [
    "days",
    "days_per_screen",
    "min_graph_value",
    "max_graph_value",
    "visible_channels",
    "daily_parities"
  ],
  data: vm => ({}),
  methods: {
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    }
  },
  computed: {
    graph_data() {
      return {
        type: "line",
        gui: {
          contextMenu: {
            empty: true
          }
        },
        plot: {
          aspect: "spline",
          fontFamily: "Roboto Condensed"
        },
        backgroundColor: "none", // This is in the root
        plotarea: {
          backgroundColor: "transparent",
          margin: "5px"
        },
        scaleX: {
          visible: false,
          lineColor: "none",
          item: {
            visible: false
          },
          tick: {
            lineColor: "none"
          }
        },
        scaleY: {
          minValue: this.min_graph_value - 10,
          maxValue:
            this.max_graph_value == this.min_graph_value
              ? this.max_graph_value + 10
              : this.max_graph_value,
          visible: false,
          lineColor: "none",
          item: {
            visible: false
          },
          tick: {
            lineColor: "none"
          }
        },
        tooltip: {
          jsRule: "window.customFncs.formatCrosshairParity()"
        }
      };
    },
    graph_series() {
      tooltips = [];
      var series = [];

      // Channels
      for (const channel of this.visible_channels) {
        var values = [];
        var index = 0;
        for (const day of this.days) {
          if (
            this.daily_rates.report &&
            this.daily_rates.report["competitor::" + channel.id]
          ) {
            values.push(
              this.daily_rates.report["competitor::" + channel.id][day]
                ? this.daily_rates.report["competitor::" + channel.id][day][0]
                    .min_price
                : null
            );
            index++;
          }
        }
        var item = {
          values: values,
          lineColor: channel.color,
          lineWidth: "1px",
          lineStyle: "dashed",
          marker: {
            "background-color": channel.color
          }
        };

        series.push(item);
      }

      // MainStrategy Line
      var hotel_values = [];
      for (const day of this.days) {
        if (
          this.daily_rates.report &&
          this.daily_rates.report["competitor::" + this.hotel_competitor.id]
        ) {
          hotel_values.push(
            this.daily_rates.report["competitor::" + this.hotel_competitor.id][
              day
            ]
              ? this.daily_rates.report[
                  "competitor::" + this.hotel_competitor.id
                ][day][0].min_price
              : null
          );
        }
      }
      series.push({
        values: hotel_values,
        lineColor: "black",
        lineWidth: "2px",
        marker: {
          "background-color": "black"
        }
      });

      // Web Line
      // MainStrategy Line
      var web_values = [];
      if (this.hotel_web) {
        for (const day of this.days) {
          if (
            this.daily_rates.report &&
            this.daily_rates.report["competitor::" + this.hotel_web.id]
          ) {
            web_values.push(
              this.daily_rates.report["competitor::" + this.hotel_web.id][day]
                ? this.daily_rates.report["competitor::" + this.hotel_web.id][
                    day
                  ][0].min_price
                : null
            );
          }
        }
      }
      series.push({
        values: web_values,
        lineColor: "red",
        lineWidth: "2px",
        marker: {
          "background-color": "red"
        }
      });

      //Creamos el tooltip
      for (const day of this.days) {
        var hotel_price = this.daily_rates.report[
          "competitor::" + this.hotel_competitor.id
        ][day]
          ? this.daily_rates.report["competitor::" + this.hotel_competitor.id][
              day
            ][0].min_price
          : null;

        var t = "";
        t =
          t +
          "<div style='text-align:left;font-family:\"Roboto Condensed\"'><span style='text-align:left; color: #333333; font-size:13px; font-weight:700;font-family:\"Roboto Condensed\";line-height:30px;'>" +
          Vue.moment(day).format("dddd, D MMMM YYYY") +
          "</span><br>";
        t =
          t +
          "<table width='170px' cellspacing='0' cellpadding='0' style='padding:0'><tr><td align='left' style='padding:0;line-height:14px;'><span style='padding:0 5px; border-radius:10px;color: white; background-color: #000000; font-size:11px; line-height:14px; font-family:\"Roboto Condensed\";font-weight:600'> " +
          this.$t("_rate") +
          " </span></td><td></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: #333333; font-size:11px; line-height:14px; font-weight:700;font-family:\"Roboto Condensed\"'>" +
          Utils.formatMoneyPlain(hotel_price, this.l) +
          "</span></td></tr>";

        if (this.hotel_web) {
          var web_price = this.daily_rates.report[
            "competitor::" + this.hotel_competitor.id
          ][day]
            ? this.daily_rates.report["competitor::" + this.hotel_web.id][
                day
              ][0].min_price
            : null;
          var web_parity_code = this.daily_rates.report[
            "competitor::" + this.hotel_competitor.id
          ][day]
            ? this.daily_rates.report["competitor::" + this.hotel_web.id][
                day
              ][0].parity_code
            : null;
          var web_parity = this.daily_rates.report[
            "competitor::" + this.hotel_competitor.id
          ][day]
            ? this.daily_rates.report["competitor::" + this.hotel_web.id][
                day
              ][0].parity - 100
            : null;

          t =
            t +
            "<tr><td align='left' style='padding:0;line-height:14px;'><span style='padding:0 5px; border-radius:10px;color: white; background-color: red; font-size:11px; line-height:14px; font-family:\"Roboto Condensed\";font-weight:600'> WEB </span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: #333333; font-size:11px; line-height:14px; font-weight:700;font-family:\"Roboto Condensed\"'>" +
            (web_parity > 0 ? "+" : "") +
            Utils.formatPercentPlain(web_parity, this.l) +
            "</span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: " +
            this.parity_colors[web_parity_code] +
            '; font-size:11px; line-height:14px; font-weight:700;font-family:"Roboto Condensed"\'>' +
            Utils.formatMoneyPlain(web_price, this.l) +
            "</span></td></tr></table><br>";
        }

        var competitors = [];
        for (const channel of this.visible_channels) {
          if (
            this.daily_rates.report &&
            this.daily_rates.report["competitor::" + channel.id]
          ) {
            var c = {
              name: channel.name,
              color: channel.color,
              ranking: this.daily_rates.report["competitor::" + channel.id][day]
                ? this.daily_rates.report["competitor::" + channel.id][day][0]
                    .ranking
                : 100,
              min_price: this.daily_rates.report["competitor::" + channel.id][
                day
              ]
                ? this.daily_rates.report["competitor::" + channel.id][day][0]
                    .min_price
                : null,
              parity: this.daily_rates.report["competitor::" + channel.id][day]
                ? this.daily_rates.report["competitor::" + channel.id][day][0]
                    .parity - 100
                : null,
              parity_code: this.daily_rates.report["competitor::" + channel.id][
                day
              ]
                ? this.daily_rates.report["competitor::" + channel.id][day][0]
                    .parity_code
                : null,
              self: this.daily_rates.report["competitor::" + channel.id][day]
                ? this.daily_rates.report["competitor::" + channel.id][day][0]
                    .self
                : null
            };
            if (
              c.parity_code &&
              (c.parity_code == "critical" || c.parity_code == "low")
            ) {
              competitors.push(c);
            }
          }
        }
        var ordered_competitors = competitors.sort(comparePrice);
        t =
          t +
          "<table width='170px' cellspacing='0' cellpadding='0' style='padding:0'>";
        var cont = 1;
        for (const competitor of ordered_competitors) {
          if (competitor.min_price > 0) {
            var fontWeight = competitor.self
              ? "font-weight:600;"
              : "font-weight:500;";
            var number = Utils.formatMoneyPlain(competitor.min_price, this.l);
            var parity_number = Utils.formatPercentPlain(
              competitor.parity,
              this.l
            );
            t =
              t +
              "<tr><td align='left' style='padding:0;line-height:14px;'><span style='color: " +
              competitor.color +
              '; font-size:11px; line-height:14px; font-family:"Roboto Condensed";' +
              fontWeight +
              "'> " +
              competitor.name +
              "</span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: #333333; font-size:11px; line-height:14px; font-weight:700;font-family:\"Roboto Condensed\"'>" +
              (competitor.parity > 0 ? "+" : "") +
              parity_number +
              "</span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: " +
              this.parity_colors[competitor.parity_code] +
              '; font-size:11px; line-height:14px; font-weight:700;font-family:"Roboto Condensed"\'>' +
              number +
              "</span></td></tr>";
            cont++;
          }
        }
        t = t + "</table>";
        t = t + "</div>";

        tooltips.push(t);
      }

      return series;
    },
    hotel_competitor() {
      return this.competitors.find(c => c.self == 1);
    },
    hotel_web() {
      return this.webs.length ? this.webs[0] : null;
    },
    date_hover: {
      get() {
        return this.$store.state.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    ...mapState({
      parity_colors: state => state.parity_colors,
      webs: state => state.parity.webs,
      competitors: state => state.rate.competitors,
      daily_rates: state => state.rate.daily_rates
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language"
    })
  }
};
</script>
