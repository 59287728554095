<template>
  <div
    class="a-rms-day-row"
    @mouseover="hoverRow(index)"
    @mouseleave="hoverRow(null)"
  >
    <v-row no-gutters>
      <v-col cols="3">
        <a-kpi
          :class="getKpiClass(values[0])"
          :value="values[0]"
          :kpi="kpis[0]"
          xx_small
          right
        ></a-kpi>
      </v-col>
      <v-col cols="2">
        <a-rms-day-pickup-tooltip
          :date="date"
          :spot="spot"
          v-if="show_pickup_tooltip"
        >
          <v-icon v-show="hover" x-small class="tooltip-launcher"
            >mdi-arrow-top-right-thick</v-icon
          >
          <a-kpi
            :class="getKpiClass(values[1])"
            :value="values[1]"
            :kpi="kpis[1]"
            xx_small
            right
          ></a-kpi>
        </a-rms-day-pickup-tooltip>
        <template v-else>
          <a-kpi
            :class="getKpiClass(values[1])"
            :value="values[1]"
            :kpi="kpis[1]"
            xx_small
            right
          ></a-kpi>
        </template>
      </v-col>
      <v-col cols="3">
        <a-kpi
          :class="getKpiClass(values[2])"
          :value="values[2]"
          :kpi="kpis[2]"
          xx_small
          right
        ></a-kpi>
      </v-col>
      <v-col cols="4">
        <a-kpi
          :class="getKpiClass(values[3])"
          :value="values[3]"
          :kpi="kpis[3]"
          xx_small
          right
        ></a-kpi
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aKpi from "@/components/rms_ui/aKpi";
import ARmsDayPickupTooltip from "@/components/rms/hotelsdot/aRmsDayPickupTooltip.vue";

export default {
  name: "a-rms-day-row-pickup",
  components: { ARmsDayPickupTooltip, aKpi },
  props: {
    periods: {
      type: Array,
      default: () => ["otb", "ly"]
    },
    day_data: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      nullable: true,
      default: null
    },
    text_class: {
      type: String,
      nullable: true,
      default: null
    },
    date: {
      type: String,
      nullable: true,
      default: null
    },
    spot: {
      type: Number,
      nullable: true,
      default: null
    },
    hover: {
      type: Boolean,
      nullable: true,
      default: false
    }
  },
  data: vm => ({
    kpis: ["occupancy", "rn", "adr", "room_revenue"]
  }),
  watch: {},
  methods: {
    getKpiClass(v) {
      let clas = [this.text_class];
      if (v <= -1) clas.push("red--text");
      return clas.join(" ");
    },
    hoverRow(v) {
      this.$emit("hoverRow", v);
    },
    getValue(index, kpi) {
      return this.day_data[this.periods[index]] &&
        this.day_data[this.periods[index]][kpi]
        ? this.day_data[this.periods[index]][kpi]
        : 0;
    },
    ...mapActions([])
  },
  computed: {
    values() {
      if (this.day_data == undefined) return [null, null, null, null];
      let values = [];
      for (let kpi of this.kpis) {
        if (this.periods[1]) {
          values.push(this.getValue(1, kpi));
        } else {
          values.push(this.getValue(1, kpi));
        }
      }
      return values;
    },
    has_pickup() {
      return (
        this.rms.all_kpis_daily_pickup &&
        this.rms.all_kpis_daily_pickup[this.date] &&
        this.rms.all_kpis_daily_pickup[this.date][this.spot] &&
        this.rms.all_kpis_daily_pickup[this.date][this.spot].length > 0
      );
    },
    show_pickup_tooltip() {
      return this.values[1] !== 0 && this.has_pickup;
    },
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({})
  },
  mounted() {}
};
</script>
