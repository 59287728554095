<template>
  <a-card :title="title">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-ellipse-kpi ref="a-ellipse-hawg-rating" :kpi="kpi"></a-ellipse-kpi>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          class="mx-auto"
          height="170"
          max-width="200"
          type="image"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto d-flex justify-center mt-2"
          max-width="200"
          type="heading"
        ></v-skeleton-loader>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import { mapState } from "vuex";
import aEllipseKpi from "@/components/aEllipseKpi";

export default {
  name: "a-reputation-global-rating",
  props: {
    ota: {
      type: String,
      default: null
    }
  },
  components: {
    aCard,
    aEllipseKpi,
    aBaseComponent
  },
  data: vm => ({
    subscribed_modules: ["bi_report"]
  }),
  mounted() {},
  computed: {
    title() {
      return this.ota ? this.ota + "_rating" : "veetal_rating";
    },
    kpi() {
      return this.ota ? this.ota + "_rating" : "hawg_rating";
    },
    ...mapState({
      bi_report: state => state.bi.report
    })
  }
};
</script>
<style scoped></style>
