<template>
  <div>
    <v-toolbar color="white" light flat>
      <v-toolbar-title>{{ $t("comparative_dates") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectHotels.length > 0"
        class="mr-3"
        @click="showCopyToOtherHotel"
        rounded
        outlined
        color="secondary"
      >
        <v-icon class="mr-1" small>mdi-content-copy</v-icon>
        {{ $t("copy_to_other_hotel") }}
      </v-btn>
      <v-btn
        v-if="yearsAvailable.length > 0"
        @click="showRestoreDate"
        rounded
        outlined
        color="secondary"
      >
        <v-icon class="mr-1" small>mdi-restore</v-icon>
        {{ $t("restore") }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="table-header mb-4">
        <v-tabs
          class="px-1"
          background-color="transparent"
          slider-size="2"
          v-model="tab"
          light
          grow
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="month in monthsYear" :key="month.month_num">
            {{ month.month_name }}
          </v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="month in monthsYear" :key="month.month_num">
          <v-data-table
            :headers="hotel_comparative_headers"
            :items="hotelDates"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.date`]="{ item }">
              <b>{{ getFriendlyDate(item.date) }}</b>
              <template v-if="item.events.length > 0">
                <div class="caption">
                  <div
                    v-for="(event, index) in item.events"
                    :key="index"
                    color="white"
                  >
                    <v-chip
                      x-small
                      text-color="white"
                      :color="eventColor(event)"
                    >
                      <v-icon x-small class="mr-1">
                        {{ getEventTypeIcon(event.type) }}
                      </v-icon>
                      <b>{{ event.alias }}</b>
                    </v-chip>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="caption">
                  <span color="white">
                    <v-chip x-small>
                      <b>{{ $t("no_events") }}</b>
                    </v-chip>
                  </span>
                </div>
              </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom dark color="#333333">
                <template v-slot:activator="{ on: onMenu }">
                  <v-menu min-width="auto" v-on="onMenu">
                    <template v-slot:activator="{ on: onDate }">
                      <v-icon
                        color="secondary"
                        small
                        v-on="{ ...onMenu, ...onDate }"
                        >mdi-link
                      </v-icon>
                    </template>
                    <v-date-picker
                      no-title
                      scrollable
                      v-model="item.date_vs"
                      @change="setHotelDate(item)"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <span>
                  {{ $t("change_comparative_date") }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.date_vs`]="{ item }">
              <b>{{ getFriendlyDate(item.date_vs) }}</b>
              <template v-if="item.events_vs.length > 0">
                <div class="caption">
                  <div
                    v-for="(event, index) in item.events_vs"
                    :key="index"
                    color="white"
                  >
                    <v-chip
                      x-small
                      text-color="white"
                      :color="eventColor(event)"
                    >
                      <v-icon x-small class="mr-1">
                        {{ getEventTypeIcon(event.type) }}
                      </v-icon>
                      <b>{{ event.alias }}</b>
                    </v-chip>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="caption">
                  <span color="white">
                    <v-chip x-small>
                      <b>{{ $t("no_events") }}</b>
                    </v-chip>
                  </span>
                </div>
              </template>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>

      <!-- Copy to other hotel -->
      <v-dialog
        v-model="show_copy_to_other_hotel"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="400"
      >
        <v-card>
          <v-card-title center class="headline justify-center">
            {{ $t("copy_to_other_hotel") }}
          </v-card-title>
          <v-card-text class="text-center justify-center">
            <v-form ref="edit_copy_to_hotel_form" v-model="can_copy_to_hotel">
              <v-row>
                <v-col cols="12" sm="12" class="py-0">
                  <v-autocomplete
                    v-model="hotel_copy_to_id"
                    :items="selectHotels"
                    :rules="[v => !!v || $t('required')]"
                    :label="$t('hotel')"
                    item-text="name"
                    item-value="id"
                    mandatory
                    rounded
                    outlined
                    dense
                    class="mt-7"
                  >
                    <template v-slot:[`item`]="{ item }">
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color="secondary"
              rounded
              depressed
              class="pl-5 pr-5"
              @click="copyToOtherHotel()"
              :disabled="!can_copy_to_hotel"
            >
              {{ $t("copy") }}
            </v-btn>
            <v-btn
              color=""
              rounded
              depressed
              class="pl-5 pr-5"
              @click="hideCopyToOtherHotel()"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Restore date -->
      <v-dialog
        v-model="show_restore_date"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="400"
      >
        <v-card>
          <v-card-title center class="headline justify-center">
            {{ $t("restore_dates") }}
          </v-card-title>
          <v-card-text class="text-center justify-center">
            <v-form ref="edit_restore_date_form">
              <v-row>
                <v-col cols="12" sm="12" class="py-0">
                  <p
                    v-html="
                      $t('compare_dates_with_year', {
                        year: this.calendar_store.calendar_year
                      })
                    "
                  ></p>
                  <v-select
                    v-model="restore_year"
                    :items="yearsAvailable"
                    :placeholder="$t('select_year')"
                    mandatory
                    rounded
                    outlined
                    dense
                    class="mt-3"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color="secondary"
              rounded
              depressed
              class="pl-5 pr-5"
              @click="restoreDate()"
              :disabled="!restore_year"
            >
              {{ $t("restore") }}
            </v-btn>
            <v-btn
              color=""
              rounded
              depressed
              class="pl-5 pr-5"
              @click="hideRestoreDate()"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import { getFriendlyDay } from "@/utils.js";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    can_copy_to_hotel: null,
    show_copy_to_other_hotel: false,
    hotel_copy_to_id: null,
    show_restore_date: false,
    restore_year: null,
    tab: 0
  }),
  methods: {
    clearCopyForm() {
      if (this.$refs.edit_copy_to_hotel_form) {
        this.$refs.edit_copy_to_hotel_form.resetValidation();
      }
    },
    showCopyToOtherHotel() {
      this.clearCopyForm();
      this.show_copy_to_other_hotel = true;
    },
    hideCopyToOtherHotel() {
      this.show_copy_to_other_hotel = false;
      this.hotel_copy_to_id = null;
    },
    copyToOtherHotel() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("copying_to_other_hotel"),
        color: "secondary"
      });
      this.$store
        .dispatch("copyToOtherHotel", {
          from_hotel_id: this.current_hotel.id,
          to_hotel_id: this.hotel_copy_to_id,
          year: parseInt(this.calendar_store.calendar_year)
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("copied_to_other_hotel"),
            color: "accent"
          });
          this.hotel_copy_to_id = null;
          this.hideCopyToOtherHotel();
        })
        .catch(() => {
          Utils.showError(this, "copy_not_worked");
        });
    },
    clearRestoreForm() {
      if (this.$refs.edit_restore_date_form) {
        this.$refs.edit_restore_date_form.resetValidation();
      }
    },
    showRestoreDate() {
      this.clearRestoreForm();
      this.show_restore_date = true;
    },
    hideRestoreDate() {
      this.show_restore_date = false;
      this.restore_year = null;
    },
    restoreDate() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("restoring_dates"),
        color: "secondary"
      });
      this.$store
        .dispatch("restoreHotelDates", {
          hotel_id: this.current_hotel.id,
          year: parseInt(this.calendar_store.calendar_year),
          year_vs: parseInt(this.restore_year)
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("date_restored"),
            color: "accent"
          });
          this.restore_year = null;
          this.hideRestoreDate();
        })
        .catch(() => {
          Utils.showError(this, "date_not_restored");
        });
    },
    setHotelDate(item) {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("changing_comparative_date"),
        color: "secondary"
      });
      this.$store
        .dispatch("setHotelDate", {
          hotel_id: this.current_hotel.id,
          date: item.date,
          date_vs: item.date_vs
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comparative_date_changed"),
            color: "accent"
          });
        })
        .catch(() => {
          Utils.showError(this, "comparative_date_not_changed");
        });
    },
    eventColor(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDay(date, true);
    },
    monthSelectedDefault() {
      let month = null;
      if (Vue.moment().format("Y") === this.calendar_store.calendar_year) {
        month = Vue.moment().month();
      } else {
        month = 0;
      }
      return month;
    }
  },
  computed: {
    selectHotels() {
      let hotels = this.hotels.filter(h => {
        return h.children_number === 0 && h.id !== this.current_hotel.id;
      });
      return hotels;
    },
    hotelDates() {
      let hotel_dates = [];
      if (this.tab >= 0) {
        hotel_dates = this.hotel_comparative_dates(this.tab);
      }
      return hotel_dates;
    },
    yearsAvailable() {
      let years = [];
      if (this.available_years) {
        years = this.available_years.filter(y => {
          return y < Vue.moment().format("Y");
        });
      }
      years = years.reverse();
      return years;
    },
    monthsYear() {
      let months = [];
      if (this.calendar_store.calendar_year) {
        for (let i = 0; i < 12; i++) {
          months.push({
            month_num: i + 1,
            month_name: Vue.moment()
              .month(i)
              .format("MMMM")
          });
        }
      }
      return months;
    },
    hotel_comparative_headers() {
      return [
        {
          text: this.$t("origin_date"),
          align: "right",
          sortable: true,
          value: "date",
          width: "50%"
        },
        {
          text: null,
          align: "left",
          sortable: false,
          value: "actions"
        },
        {
          text: this.$t("comparative_date"),
          align: "start",
          sortable: true,
          value: "date_vs",
          width: "50%"
        }
      ];
    },
    ...mapState({
      calendar_store: state => state.calendar,
      hotels: state => state.hotel.hotels
    }),
    ...mapGetters({
      available_years: "getHotelAvailableYears",
      current_hotel: "current_hotel",
      hotel_comparative_dates: "getComparativeDates"
    })
  },
  mounted() {
    this.tab = this.monthSelectedDefault();
    this.restore_year = parseInt(
      this.calendar_store.calendar_year - 1
    ).toString();
  }
};
</script>
