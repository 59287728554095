<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options></template>
    <template v-if="is_all_hotels">
      <a-hotel-selector-page></a-hotel-selector-page>
    </template>
    <template v-else>
      <template v-if="!loaded">
        <v-card class="mt-2">
          <v-card-text>
            <v-skeleton-loader
              type="table-thead"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="table-tbody"
              class="mx-auto"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </template>
      <segments-channels-related-app
        ref="segments-channels-related-app"
        @getSegments="getSegments"
        v-else-if="is_related"
      ></segments-channels-related-app>
      <segments-channels-tabs-app ref="segments-channels-tabs-app" v-else>
      </segments-channels-tabs-app>
    </template>
  </a-page>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";
import aPage from "@/components/aPage";
import SegmentsChannelsRelatedApp from "@/components/app/settings/segmentsChannelsRelatedApp.vue";
import aHotelSelectorPage from "@/components/selector/aHotelSelectorPage.vue";
import SegmentsChannelsTabsApp from "@/components/app/settings/segmentsChannelsTabsApp.vue";

export default {
  name: "SegmentsChannels",
  components: {
    SegmentsChannelsTabsApp,
    aHotelSelectorPage,
    SegmentsChannelsRelatedApp,
    aPage
  },
  data: () => ({
    app_id: "segments-app",
    image_source: "/img/header-2.jpg", // Mandatory path
    image_height: 300, // Mandatory number
    loading_channels: false
  }),
  computed: {
    ready() {
      return this.current_hotel && this.current_hotel.id;
    },
    loaded() {
      return this.current_hotel && this.segments_store;
    },
    is_all_hotels: function() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    is_related() {
      if (!this.current_hotel) {
        return false;
      }
      const channels = this.current_hotel.settings.channels_in_day_kpis;
      const segments = this.current_hotel.settings.segments_in_day_kpis;
      return !segments && !channels;
    },
    ...mapState({
      segments_store: state => state.segmentschannels
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    getSegments() {
      if (!this.current_hotel || this.current_hotel.id == null) {
        return;
      }
      this.$store
        .dispatch("getSegments", {
          hotel_id: this.current_hotel.id
        })
        .then(() => {
          this.getSegmentChannels();
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getSegmentChannels() {
      if (!this.current_hotel || this.current_hotel.id == null) {
        return;
      }
      this.loading_channels = true;
      this.$store
        .dispatch("getSegmentChannels", {
          hotel_id: this.current_hotel.id
        })
        .then(() => {
          this.loading_channels = false;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }
  },
  watch: {
    ready(v) {
      if (v) {
        this.getSegments();
      }
    }
  },
  mounted() {
    this.getSegments();
  }
};
</script>

<style lang="scss" scoped></style>
