<template>
  <zingchart
    :height="height"
    :width="width"
    :data="graph_settings"
    :series="graph_series"
  ></zingchart>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-reputation-daily-reviews-graph",
  components: {},
  props: {
    height: {
      type: [String, Number],
      default: "100%"
    },
    width: {
      type: String,
      Number,
      default: "auto"
    },
    ota: {
      type: String,
      default: ""
    }
  },
  data: vm => ({}),
  methods: {},
  computed: {
    prefix() {
      return this.ota ? this.ota + "_" : "";
    },
    max_value() {
      var all_values = [];
      for (var emotion of this.emotions) {
        all_values = [...this.emotions_values[emotion.code]];
      }
      return Math.max(...all_values);
    },
    min_value() {
      return 0;
    },
    emotions_values() {
      var emotions_values = {};
      for (var emotion of this.emotions) {
        var values = [];
        for (var day_report of this.bi_daily_report.daily_list) {
          var v = parseFloat(
            day_report[this.prefix + "new_reviews_" + emotion.code]
          );
          if (!Number.isNaN(v)) {
            values.push(v);
          }
        }
        emotions_values[emotion.code] = values;
      }
      return emotions_values;
    },
    graph_settings() {
      var tooltip = this.chart_settings.tooltip;
      tooltip.align = "left";
      tooltip.lineHeight = "21px";
      return {
        type: "mixed",
        backgroundColor: "transparent",
        tooltip: {
          visible: false
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        scaleX: {
          visible: false,
          guide: { visible: false },
          values: this.current_period_items
        },
        scaleY: {
          visible: false,
          guide: { visible: false }
          // values: this.min_value + ":" + this.max_value
        },
        plot: {
          aspect: "spline"
        },
        plotarea: {
          margin: "20px 0px 20px 0px"
        },
        crosshairX: {
          plotLabel: tooltip,
          lineColor: "#cacaca",
          lineWidth: 2,
          lineStyle: "dashed",
          marker: {
            backgroundColor: "#ffffff",
            size: 4,
            borderWidth: 2,
            borderColor: "#444444",
            opacity: "0.8",
            shadow: 2
          }
        }
      };
    },
    graph_series() {
      var items = [];
      var first = true;

      var all_values = [];
      for (var emotion of this.emotions) {
        var text =
          "<span style='font-size:13px;font-weight:bold;background-color:%color; color:white;border-radius:15px; padding: 2px 5px;'>%v</span> <span style='font-size:13px; color: #333333;'>" +
          this.$t(emotion.code + "s") +
          "</span>";
        if (first) {
          first = false;
          text =
            "<span style='color: #666666;font-size:13px;font-weight:bold;'>" +
            this.$t("reviews") +
            " %kt </span><br><span style='font-size:13px;font-weight:bold;background-color:%color; color:white;border-radius:15px; padding: 2px 5px;'>%v</span> <span style='font-size:13px; color: #333333;'>" +
            this.$t(emotion.code + "s") +
            "</span>";
        }
        items.push({
          scales: "scale-x,scale-y",
          type: "bar",
          aspect: "spline",
          stacked: true,
          stackType: "normal",
          values: this.emotions_values[emotion.code],
          text: "Promotores",
          backgroundColor: emotion.color_hexa,
          lineColor: emotion.color_hexa,
          shadow: 0,
          marker: {
            visible: "false"
          },
          guideLabel: {
            text: text,
            thousandsSeparator: ".",
            decimalsSeparator: ","
          }
        });
      }
      return items;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      emotions: state => state.emotions,
      bi_daily_report: state => state.bi.daily_report
    }),
    ...mapGetters({
      current_period_items: "current_period_items",
      get_kpi: "get_kpi"
    })
  }
};
</script>
