<template>
  <div id="rate-app">
    <v-card id="rate-filter" dark class="elevation-0 mb-4" v-if="!groupDisplay">
      <v-card-text class="pb-1 pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="6" lg="3" class="pb-0">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper">
                {{ $t("extraction_date") }}
              </div>
            </div>
            <v-menu
              v-model="extraction_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  block
                  rounded
                  outlined
                  dark
                  large
                  background-color="transparent"
                  style="border-color: rgba(250,250,250,0.2)"
                  v-on="on"
                >
                  <span class="flex-grow-1 text-left">
                    {{ formatedExtractionDate }}
                    <template v-show="extraction_time">
                      <small class="ml-2"> a las {{ extraction_time }} </small>
                    </template>
                  </span>
                  <v-icon class="align-self-right"
                    >mdi-calendar-month-outline</v-icon
                  >
                </v-btn>
              </template>
              <v-date-picker
                color="accent"
                v-model="extraction_date"
                @change="changeExtractionDate"
                :allowed-dates="extractionAllowedDate"
                first-day-of-week="1"
              ></v-date-picker>
              <v-list>
                <v-list-item-group v-model="extraction_id">
                  <v-list-item
                    color="accent"
                    v-for="(item,
                    i) in extraction_date_available_rate_extractions"
                    :key="'extraction_date_available_rate_extractions_' + i"
                    @change="extraction_menu = false"
                    :value="item.id"
                    :disabled="item.id == extraction_id"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-center"
                        v-text="'a las ' + item.time"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="pb-0">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper">
                {{ $t("vs_date") }}
              </div>
            </div>
            <v-menu
              v-model="vs_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  block
                  rounded
                  outlined
                  dark
                  large
                  background-color="transparent"
                  style="border-color: rgba(250,250,250,0.2)"
                  v-on="on"
                >
                  <span class="flex-grow-1 text-left">
                    {{ formatedVsDate }}
                    <template v-show="vs_time">
                      <small class="ml-2"> a las {{ vs_time }} </small>
                    </template>
                  </span>
                  <v-icon class="align-self-right"
                    >mdi-calendar-month-outline</v-icon
                  >
                </v-btn>
              </template>
              <v-date-picker
                color="accent"
                v-model="vs_date"
                @change="changeVsDate"
                :allowed-dates="vsAllowedDate"
                first-day-of-week="1"
              ></v-date-picker>
              <v-list>
                <v-list-item-group v-model="vs_id">
                  <v-list-item
                    v-for="(item, i) in vs_date_available_rate_extractions"
                    :color="item.id >= extraction_id ? 'grey' : 'accent'"
                    :key="'vs_date_available_rate_extractions_' + i"
                    @change="vs_menu = false"
                    :value="item.id"
                    :disabled="item.id == vs_id || item.id >= extraction_id"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-center"
                        v-text="'a las ' + item.time"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="pb-0">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper">
                {{ $t("filters") }}
              </div>
            </div>
            <v-btn
              text
              block
              rounded
              outlined
              dark
              large
              background-color="transparent"
              style="border-color: rgba(250,250,250,0.2)"
              @click="filters_opened = !filters_opened"
            >
              <span class="flex-grow-1 text-left">
                {{ adults
                }}<v-icon medium class="title ml-0 mr-3">mdi-account</v-icon>
                <template v-if="childs">
                  {{ childs
                  }}<v-icon class="title ml-0 mr-4" medium
                    >mdi-account-child</v-icon
                  >
                </template>
                <template v-else>
                  0<v-icon class="title ml-0 mr-4" medium
                    >mdi-account-child</v-icon
                  >
                </template>

                <template v-if="regime == 'alo'">
                  <!-- <span>{{ $t("alo_abr") }}</span> -->
                  <v-icon class="title mr-4">mdi-bed-king-outline</v-icon>
                </template>
                <template v-if="regime == 'ad'">
                  <!-- <span>{{ $t("ad_abr") }}</span> -->
                  <v-icon class="title mr-4">mdi-coffee-outline</v-icon>
                </template>
                <template v-if="regime == 'mp'">
                  <!-- <span>{{ $t("mp_abr") }}</span> -->
                  <v-icon class="title mr-4">mdi-silverware-variant</v-icon>
                </template>
                <template v-if="regime == 'pc'">
                  <!-- <span>{{ $t("pc_abr") }}</span> -->
                  <v-icon class="title mr-4">mdi-silverware</v-icon>
                </template>
                <template v-if="regime == 'ti'">
                  <!-- <span>{{ $t("ti_abr") }}</span> -->
                  <v-icon class="title mr-4">mdi-star-outline</v-icon>
                </template>

                <template v-if="cancel_policy == 'free-cancellation'">
                  <v-icon class="title mr-4">mdi-credit-card-refund</v-icon>
                </template>
                <template v-if="cancel_policy == 'cancellation-with-cost'">
                  <v-icon class="title mr-4">mdi-credit-card-settings</v-icon>
                </template>
                <template v-if="cancel_policy == 'no-refundable'">
                  <v-icon class="title mr-4">mdi-credit-card-remove</v-icon>
                </template>

                <template v-if="genius">
                  <v-avatar
                    color="blue darken-4 text-lowercase"
                    rounded
                    size="22"
                    class="mr-4"
                    >.g</v-avatar
                  >
                </template>

                <template v-if="mobile_device">
                  <v-icon class="title mr-4">mdi-cellphone</v-icon>
                </template>
                <template v-else>
                  <v-icon class="title mr-4">mdi-desktop-mac</v-icon>
                </template>
              </span>
              <v-icon v-if="!filters_opened" class="justify-self-end"
                >mdi-chevron-down</v-icon
              >
              <v-icon v-else class="justify-self-end">mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="pb-0 d-none d-sm-block">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper" v-html="'&nbsp;'"></div>
            </div>
            <v-btn
              color="info"
              class="text-center"
              block
              rounded
              large
              @click="updateRate"
              >{{ $t("update") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      id="rate-filter"
      dark
      class="elevation-0 mt-6"
      v-if="!groupDisplay && current_hotel"
      v-show="filters_opened"
    >
      <v-divider></v-divider>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
            <div class="d-none d-md-flex align-center">
              <v-icon
                x-large
                class="mt-1"
                style="color: rgba(250,250,250,0.4); font-size: 82px;"
                >mdi-account</v-icon
              >
            </div>
            <div class="flex-grow-1">
              <div class="v-messages theme--dark ml-2">
                <div class="v-messages__wrapper">
                  {{ $t("number_of_adults") }}
                </div>
              </div>
              <v-btn-toggle
                v-model="adults"
                background-color="transparent"
                rounded
                dense
                outlined
                dark
              >
                <v-btn :value="2" outlined dark>2</v-btn>
                <v-btn :value="3" outlined dark>3</v-btn>
                <v-btn :value="4" outlined dark>4</v-btn>
                <v-btn :value="5" outlined dark>5</v-btn>
                <v-btn :value="6" outlined dark>6</v-btn>
                <v-btn :value="7" outlined dark>7</v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
            <div class="d-none d-md-flex align-center">
              <v-icon
                x-large
                class="mt-1"
                style="color: rgba(250,250,250,0.4); font-size: 82px;"
                >mdi-account-child</v-icon
              >
            </div>
            <div class="flex-grow-1">
              <div class="v-messages theme--dark ml-2">
                <div class="v-messages__wrapper">
                  {{ $t("number_of_childs") }}
                </div>
              </div>
              <v-btn-toggle
                v-model="childs"
                rounded
                dense
                block
                outlined
                background-color="transparent"
                dark
              >
                <v-btn outlined dark :value="1">1 </v-btn>
                <v-btn outlined dark :value="2">2 </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
            <div class="d-none d-md-flex align-center">
              <v-icon
                x-large
                class="mt-1"
                style="color: rgba(250,250,250,0.4); font-size: 82px;"
                >mdi-silverware-variant</v-icon
              >
            </div>
            <div class="flex-grow-1">
              <div class="v-messages theme--dark ml-2">
                <div class="v-messages__wrapper">
                  {{ $t("regime") }}
                </div>
              </div>
              <v-btn-toggle
                v-model="regime"
                color="secondary accent-3"
                rounded
                dense
                block
                outlined
                background-color="transparent"
                dark
              >
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined dark color="white" value="alo" v-on="on">
                      {{ $t("alo_abr") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("only_room") }}</span>
                </v-tooltip>
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined dark color="white" value="ad" v-on="on">
                      {{ $t("ad_abr") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("ad") }}</span>
                </v-tooltip>
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined dark color="white" value="mp" v-on="on">
                      {{ $t("mp_abr") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("mp") }}</span>
                </v-tooltip>
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined dark color="white" value="pc" v-on="on">
                      {{ $t("pc_abr") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("pc") }}</span>
                </v-tooltip>
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined dark color="white" value="ti" v-on="on">
                      {{ $t("ti_abr") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("ti") }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
            <div class="d-none d-md-flex align-center">
              <v-icon
                x-large
                class="mt-1"
                style="color: rgba(250,250,250,0.4); font-size: 82px;"
                >mdi-credit-card-remove</v-icon
              >
            </div>
            <div class="flex-grow-1">
              <div class="v-messages theme--dark ml-2">
                <div class="v-messages__wrapper">
                  {{ $t("refund_policy") }}
                </div>
              </div>
              <v-btn-toggle
                v-model="cancel_policy"
                color="secondary accent-3"
                rounded
                dense
                block
                outlined
                background-color="transparent"
                dark
              >
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      dark
                      v-on="on"
                      value="free-cancellation"
                      color="white"
                    >
                      <span>{{ $t("free_cancellation_abr") }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t("free_cancellation") }}</span>
                </v-tooltip>
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      dark
                      v-on="on"
                      color="white"
                      value="cancellation-with-cost"
                    >
                      <span>{{ $t("cancellation_with_cost_abr") }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t("cancellation_with_cost") }}</span>
                </v-tooltip>
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      dark
                      v-on="on"
                      value="no-refundable"
                      color="white"
                    >
                      <span>{{ $t("no_refundable_abr") }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t("no_refundable") }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
            <div class="d-none d-md-flex align-center">
              <v-icon
                x-large
                class="mt-1"
                style="color: rgba(250,250,250,0.4); font-size: 82px;"
                >mdi-shield-account</v-icon
              >
            </div>
            <div class="flex-grow-1">
              <div class="v-messages theme--dark ml-2">
                <div class="v-messages__wrapper">
                  {{ $t("booking_user_type") }}
                </div>
              </div>
              <v-btn-toggle
                v-model="genius"
                background-color="transparent"
                rounded
                outlined
                dark
                mandatory
              >
                <v-btn
                  :value="false"
                  :disabled="!current_hotel.settings.booking_genius_enabled"
                  outlined
                  dark
                  >{{ $t("normal") }}</v-btn
                >
                <v-btn
                  :value="true"
                  :disabled="!current_hotel.settings.booking_genius_enabled"
                  outlined
                  dark
                  >.genius</v-btn
                >
              </v-btn-toggle>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
            <div class="d-none d-md-flex align-center">
              <v-icon
                x-large
                class="mt-1"
                style="color: rgba(250,250,250,0.4); font-size: 82px;"
                >mdi-cellphone</v-icon
              >
            </div>
            <div class="flex-grow-1">
              <div class="v-messages theme--dark ml-2">
                <div class="v-messages__wrapper">
                  {{ $t("mobile") }}
                </div>
              </div>
              <v-btn-toggle
                v-model="mobile_device"
                background-color="transparent"
                rounded
                outlined
                dark
                mandatory
              >
                <v-btn
                  :value="true"
                  :disabled="!current_hotel.settings.booking_mobile_enabled"
                  outlined
                  dark
                  >{{ $t("yes") }}</v-btn
                >
                <v-btn
                  :value="false"
                  :disabled="!current_hotel.settings.booking_mobile_enabled"
                  outlined
                  dark
                  >{{ $t("no") }}</v-btn
                >
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="pb-0 d-block d-sm-none px-1">
      <div class="v-messages theme--dark ml-2">
        <div class="v-messages__wrapper" v-html="'&nbsp;'"></div>
      </div>
      <v-btn
        color="info"
        class="text-center"
        block
        rounded
        large
        @click="updateRate"
        >{{ $t("update") }}</v-btn
      >
    </div>
    <a-base-component :subscribed_modules="subscribed_modules" @loaded="loaded">
      <template v-slot:default>
        <v-card :class="{ 'group-display': groupDisplay }">
          <v-card-text class="pl-0 pl-sm-2 pl-md-4">
            <div class="d-flex justify-end">
              <div>
                <v-btn-toggle
                  v-model="mode"
                  color="info"
                  rounded
                  dense
                  block
                  mandatory
                  outlined
                  class="mr-1"
                >
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        value="rate"
                        outlined
                        color="info"
                        style="
                          border-top: none !important;
                          border-bottom: none !important;
                          height: 34px;
                        "
                      >
                        <v-icon>mdi-cash</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("rate") }}</span>
                  </v-tooltip>
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        value="availability"
                        outlined
                        color="info"
                        style="
                          border-top: none !important;
                          border-bottom: none !important;
                          height: 34px;
                        "
                      >
                        <v-icon>mdi-bed-king-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("room_availability") }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
              <v-tooltip
                color="black"
                bottom
                v-if="$vuetify.breakpoint.smAndUp"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    depressed
                    class=""
                    right
                    rounded
                    outlined
                    color="info"
                    @click="downloadRate"
                  >
                    <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                    <template>{{ $t("export") }}</template>
                  </v-btn>
                </template>
                <span>{{ $t("download_min_price_table_data") }}</span>
              </v-tooltip>
              <v-tooltip color="black" bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    depressed
                    class=""
                    fab
                    small
                    icon
                    color="info"
                    :loading="loading"
                    @click="downloadRate"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("download_min_price_table_data") }}</span>
              </v-tooltip>
            </div>
          </v-card-text>
          <v-card-text class="pl-0">
            <div id="rate-daily" :class="'show-day-' + day_hover">
              <div class="rate-wrapper">
                <div></div>
                <div
                  id="rate-days-wrapper"
                  :class="'page_' + page + ' block_' + block"
                  class="m-portlet radius0"
                  v-if="daily_rates"
                >
                  <rate-ranking-graph
                    :class="{ 'opacity0 ': sticky_header }"
                    :days="all_days"
                    :days_per_screen="days_per_screen"
                    :days_to_render="rendered_days"
                    :total_days="total_days"
                    v-if="!groupDisplay"
                    @goToDay="goToDay"
                  >
                  </rate-ranking-graph>

                  <div style="position: relative">
                    <v-btn
                      v-show="page > 0"
                      @click.prevent="moveDownSlider"
                      id="arrow-left"
                      rounded
                      color="white"
                      fab
                      :loading="loading_page"
                      :small="$vuetify.breakpoint.xsOnly"
                      ><v-icon>mdi-chevron-left</v-icon></v-btn
                    >
                    <v-btn
                      :small="$vuetify.breakpoint.xsOnly"
                      v-show="hasMorePages"
                      @click.prevent="moveUpSlider"
                      id="arrow-right"
                      rounded
                      fab
                      color="white"
                      :loading="loading_page"
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          v-on="on"
                          v-show="isLastPage"
                          @click.prevent="plusInfo()"
                          id="plus-info"
                          rounded
                          fab
                          color="info"
                          :loading="plus_loading"
                          ><v-icon>mdi-plus</v-icon></v-btn
                        >
                      </template>
                      <span>{{ $t("load_more_data") }}</span>
                    </v-tooltip>

                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :small="$vuetify.breakpoint.xsOnly"
                          v-on="on"
                          v-show="showMinusInfo"
                          @click.prevent="minusInfo()"
                          :loading="minus_loading"
                          id="minus-info"
                          rounded
                          fab
                          color="info"
                          ><v-icon>mdi-minus</v-icon></v-btn
                        >
                      </template>
                      <span>{{ $t("load_more_data") }}</span>
                    </v-tooltip>

                    <div :class="{ 'shadow-before': page > 0 }"></div>
                    <div
                      :class="{
                        'shadow-after': page < total_days / days_per_screen - 1
                      }"
                    ></div>
                    <rate-graph-bg
                      :class="{ 'opacity0 ': sticky_header }"
                      :days="days"
                      :days_per_screen="days_per_screen"
                      :min_graph_value="min_graph_value"
                      :max_graph_value="max_graph_value"
                      v-if="!groupDisplay"
                    >
                    </rate-graph-bg>
                    <rate-updated
                      :class="{ 'opacity0 ': sticky_header }"
                      :language="current_user.language"
                      :days="days"
                      :days_per_screen="days_per_screen"
                      :extraction_id="extraction_id"
                      v-if="!groupDisplay"
                    >
                    </rate-updated>
                    <rate-day
                      :class="{ 'opacity0 ': sticky_header }"
                      :language="current_user.language"
                      :days="days"
                      :days_per_screen="days_per_screen"
                    >
                    </rate-day>
                    <rate-web-price
                      v-if="hotel_web && show_rate_web"
                      :key="'web_price_competitor_' + hotel_web.id"
                      :class="{ 'opacity0 ': sticky_header }"
                      :days_to_render="rendered_days"
                      :days="days"
                      :mode="mode"
                      :competitor="hotel_web"
                      :filters="filters"
                      :daily_rates="
                        daily_rates.report
                          ? daily_rates.report['competitor::' + hotel_web.id]
                          : []
                      "
                      :daily_availability="
                        daily_availability.report
                          ? daily_availability.report[
                              'competitor::' + hotel_web.id
                            ]
                          : []
                      "
                      :increments="
                        daily_rates.report
                          ? daily_rates.increments[
                              'competitor::' + hotel_web.id
                            ]
                          : []
                      "
                      :daily_rates_vs="
                        daily_rates.report
                          ? daily_rates.data_to_compare[
                              'competitor::' + hotel_web.id
                            ]
                          : []
                      "
                      :ota_daily_rates="
                        daily_rates.report
                          ? daily_rates.report[
                              'competitor::' + hotel_competitor.id
                            ]
                          : []
                      "
                      :ota_daily_availability="
                        daily_availability.report
                          ? daily_availability.report[
                              'competitor::' + hotel_competitor.id
                            ]
                          : []
                      "
                      :days_per_screen="days_per_screen"
                    >
                    </rate-web-price>
                    <rate-min-price
                      v-for="competitor in competitors"
                      :key="'competitor_' + competitor.id"
                      :class="{ 'opacity0 ': sticky_header }"
                      :days_to_render="rendered_days"
                      :days="days"
                      :mode="mode"
                      :competitor="competitor"
                      :filters="filters"
                      :groupDisplay="groupDisplay"
                      :daily_rates="
                        daily_rates.report
                          ? daily_rates.report['competitor::' + competitor.id]
                          : []
                      "
                      :daily_availability="
                        daily_availability.report
                          ? daily_availability.report[
                              'competitor::' + competitor.id
                            ]
                          : []
                      "
                      :increments="
                        daily_rates.report
                          ? daily_rates.increments[
                              'competitor::' + competitor.id
                            ]
                          : []
                      "
                      :daily_rates_vs="
                        daily_rates.report
                          ? daily_rates.data_to_compare[
                              'competitor::' + competitor.id
                            ]
                          : []
                      "
                      :days_per_screen="days_per_screen"
                      :pulse="
                        query_params &&
                          query_params.competitor_id == competitor.id
                      "
                    >
                    </rate-min-price>
                    <rate-min-price-competitors
                      v-if="mode == 'rate'"
                      :key="'rate-min-price-competitors'"
                      :days_to_render="rendered_days"
                      :days="days"
                      :groupDisplay="groupDisplay"
                      :daily_rates="daily_rates"
                      :days_per_screen="days_per_screen"
                    ></rate-min-price-competitors>
                    <rate-avg-min-price
                      v-if="mode == 'rate'"
                      :key="'rate-avg-min-price'"
                      :days_to_render="rendered_days"
                      :days="days"
                      :groupDisplay="groupDisplay"
                      :daily_rates="daily_rates"
                      :days_per_screen="days_per_screen"
                    ></rate-avg-min-price>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:loading>
        <v-card class="mt-2" loading>
          <v-card-text>
            <v-row class="mx-0">
              <v-col cols="12" md="2"></v-col>
              <v-col cols="12" md="10">
                <v-skeleton-loader
                  type="image"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-skeleton-loader
              type="table-thead"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="table-tbody"
              class="mx-auto"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </template>
    </a-base-component>
    <rate-day-detail ref="rate-day-detail"></rate-day-detail>
    <rate-highlight
      :extraction_id="extraction_id"
      @updateFilters="updateFilters"
      @gotoDay="goToDay"
      ref="rate-highlight"
    ></rate-highlight>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import rateDay from "@/components/rateDay";
import rateUpdated from "@/components/rateUpdated";
import rateRankingGraph from "@/components/rateRankingGraph";
import rateGraphBg from "@/components/rateGraphBg";
import rateMinPrice from "@/components/rateMinPrice";
import rateWebPrice from "@/components/rateWebPrice";
import rateAvgMinPrice from "@/components/rateAvgMinPrice";
import rateMinPriceCompetitors from "@/components/rateMinPriceCompetitors";
import rateDayDetail from "@/components/rateDayDetail";
import rateHighlight from "@/components/rateHighlight";
import aBaseComponent from "@/components/aBaseComponent";
import $ from "jquery";
import axios from "axios";
import { environment } from "@/services/Environment";

const DEFAULT_DAYS_PER_SCREEN = 14;

export default {
  name: "rate-app",
  components: {
    rateDay,
    rateUpdated,
    rateGraphBg,
    rateMinPrice,
    rateWebPrice,
    rateDayDetail,
    rateHighlight,
    rateRankingGraph,
    rateAvgMinPrice,
    rateMinPriceCompetitors,
    aBaseComponent
  },
  data: vm => ({
    subscribed_modules: [
      "rate_available_rate_extractions",
      "rate_daily_rates",
      "rate_daily_availability",
      "rate_competitors",
      "rate_available_dates",
      "parity_webs"
    ],
    //Filters
    old_filters: {
      hotel_id: null,
      extraction_date: null,
      extraction_id: null,
      vs_date: null,
      vs_id: null,
      pax: null,
      childs: 0,
      regime: null,
      cancel_policy: null,
      genius: false,
      mobile_device: false
    },
    old_extraction_date: null,
    filters_opened: false,
    extraction_date: null,
    extraction_id: null,
    date: null,
    vs_date: null,
    vs_id: null,
    adults: 2,
    childs: 0,
    regime: [],
    cancel_policy: [],
    genius: false,
    mobile_device: false,
    extraction_menu: null,
    init_date: null, // first day of the block
    current_init_date: null, // first day of the top slider
    init_menu: null,
    vs_menu: null,
    loading_page: false,
    plus_loading: false,
    minus_loading: false,
    mode: "rate", // 'rate' or 'availability'
    // DAILY RATES
    total_days: 126,
    page: null,
    days_per_screen: DEFAULT_DAYS_PER_SCREEN, // set on mounted vue event, depending on userscreen resolution,
    sticky_header: false
  }),
  methods: {
    goToPage(page, init_date) {
      this.loading_page = true;
      setTimeout(() => {
        this.page = page;
        if (this.init_date != init_date) {
          this.init_date = init_date;
        }
      }, 100);

      setTimeout(() => {
        this.loading_page = false;
      }, 1500);
    },
    goToDay(date) {
      var index = this.all_days.indexOf(date);
      if (index >= 0) {
        // Calculamos block_init_date, page, day
        var block = parseInt(index / this.total_days);
        var block_init_date = this.all_days[block * this.total_days];
        var index_in_block = index % this.total_days;
        var page = parseInt(index_in_block / this.days_per_screen);
        this.goToPage(page, block_init_date);
      }
    },
    getYear(date) {
      return Vue.moment(date).format("YYYY");
    },
    isHover(date) {
      return this.date_hover == date ? true : false;
    },
    moveDownSlider() {
      if (this.page > 0) {
        this.loading_page = true;
        setTimeout(() => {
          this.page--;
        }, 100);

        setTimeout(() => {
          this.loading_page = false;
        }, 1500);
      }
    },
    moveUpSlider() {
      if (this.page < this.total_days / this.days_per_screen) {
        this.loading_page = true;
        setTimeout(() => {
          this.page++;
        }, 100);

        setTimeout(() => {
          this.loading_page = false;
        }, 1500);
      }
    },
    minusInfo() {
      // Move init_date to last date of rendered_days
      this.minus_loading = true;
      setTimeout(() => {
        var new_init_date = Vue.moment(this.init_date).subtract(
          this.total_days,
          "days"
        );
        this.init_date = new_init_date.format("Y-MM-DD");
        this.page = this.lastPage;
      }, 500);
      setTimeout(() => {
        this.minus_loading = false;
      }, 1000);
    },
    plusInfo() {
      // Move init_date to last date of rendered_days
      this.plus_loading = true;
      setTimeout(() => {
        var new_init_date = Vue.moment(this.rendered_days.slice(-1).pop()).add(
          1,
          "days"
        );
        this.init_date = new_init_date.format("Y-MM-DD");
        this.page = 0;
      }, 500);
      setTimeout(() => {
        this.plus_loading = false;
      }, 1000);
    },

    calcDaysPerScreen() {
      var width = window.innerWidth;
      var days = 14;

      if (width < 1600) {
        days = 9;
      }
      if (width < 1256) {
        days = 7;
      }
      if (width < 600) {
        days = 2;
      }
      this.days_per_screen = days;
    },
    initDaysPerScreen() {
      this.calcDaysPerScreen();
      var vm = this;
      window.addEventListener("resize", this.calcDaysPerScreen);
    },

    // FILTERS
    extractionAllowedId(v) {
      return this.available_rate_extractions &&
        this.available_rate_extractions
          .reduce(
            (ids, cur) => ids.concat(cur.extractions.map(imp => imp.id)),
            []
          )
          .find(ids => ids == v)
        ? true
        : false;
    },
    defaultExtractionId() {
      return this.current_hotel && this.available_rate_extractions
        ? this.available_rate_extractions[0].extractions[0].id
        : "";
    },
    extractionAllowedDate(v) {
      return this.available_rate_extractions &&
        this.available_rate_extractions.find(node => node.date == v)
        ? true
        : false;
    },
    defaultExtractionDate() {
      return this.current_hotel && this.available_rate_extractions
        ? this.available_rate_extractions[0].date
        : "";
    },
    changeExtractionDate() {
      // set default value
      this.extraction_id = undefined;
      if (this.extraction_date_available_rate_extractions.length == 1) {
        this.extraction_id = this.extraction_date_available_rate_extractions[0].id;
        this.extraction_menu = false;
      }
    },
    initAllowedDate(v) {
      // Must return if equal or bigger than selected extraction_date
      var date = Vue.moment(v);
      var extraction_date = Vue.moment(this.extraction_date);
      return date.diff(extraction_date, "days") >= 0 ? true : false;
    },
    defaultInitDate() {
      return this.extraction_date;
    },
    vsAllowedId(v) {
      // Must return if lower than selected extraction_id and extractionAllowedId(v)
      return v < this.extraction_id && this.extractionAllowedId(v)
        ? true
        : false;
    },
    defaultVsId() {
      // Must return max from available_rate_extractions and lower than extraction_id
      var vm = this;
      return this.available_rate_extractions &&
        this.available_rate_extractions.find(date =>
          date.extractions.find(imp => vm.vsAllowedId(imp.id))
        )
        ? this.available_rate_extractions
            .find(date => date.extractions.find(imp => vm.vsAllowedId(imp.id)))
            .extractions.find(imp => vm.vsAllowedId(imp.id)).id
        : "";
    },
    vsAllowedDate(v) {
      // Must return if lower or equal than selected extraction_date and extractionAllowedDate(v)
      var date = Vue.moment(v);
      var extraction_date = Vue.moment(this.extraction_date);

      // Depending if extraction_id is the last of the day, we use < or <=
      if (
        this.extraction_date_available_rate_extractions[
          this.extraction_date_available_rate_extractions.length - 1
        ].id == this.extraction_id
      ) {
        return date.diff(extraction_date, "days") < 0 &&
          this.extractionAllowedDate(v)
          ? // && this.firstExtractionDate !== date.format("Y-MM-DD")
            true
          : false;
      }
      return date.diff(extraction_date, "days") <= 0 &&
        this.extractionAllowedDate(v)
        ? // && this.firstExtractionDate !== date.format("Y-MM-DD")
          true
        : false;
    },
    defaultVsDate() {
      // Must return max from available_rate_extractions and lower/equal than extraction_date
      var vm = this;
      return this.available_rate_extractions &&
        this.available_rate_extractions.find(date =>
          vm.vsAllowedDate(date.date)
        )
        ? this.available_rate_extractions.find(date =>
            vm.vsAllowedDate(date.date)
          ).date
        : "";
    },
    changeVsDate() {
      this.vs_id = undefined;
      if (this.vs_date_available_rate_extractions.length == 1) {
        this.vs_id = this.vs_date_available_rate_extractions[0].id;
        this.vs_menu = false;
      }
    },
    getDateFromId(id) {
      return (
        this.available_rate_extractions &&
        this.available_rate_extractions.find(node =>
          node.extractions.find(node => node.id == id)
        ).date
      );
    },
    formatDate(date) {
      return date
        ? Vue.moment(date)
            .format("D MMM YYYY")
            .replace(".", "")
            .toUpperCase()
        : "";
    },
    downloadRate() {
      axios({
        url: environment.api_url + "/export-rates-excel",
        data: this.filters,
        method: "POST",
        responseType: "blob",
        headers: {
          Authorization: localStorage.getItem("credentials")
            ? "Bearer " + JSON.parse(localStorage.getItem("credentials")).token
            : ""
        }
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "Tarifas " + this.current_hotel.name + ".xlsx"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    updateRate() {
      if (this.current_hotel && this.filters.hotel_id) {
        this.date = null;
        this.goToRate();
      }
    },
    goToRate() {
      this.old_extraction_date = this.old_filters.extraction_date;
      this.current_init_date = this.extraction_date;
      this.old_filters = JSON.parse(JSON.stringify(this.filters));
      var vm = this;
      this.$store.dispatch("setRateFilters", this.filters);
    },
    setDefault() {
      /* CHECK EACH PARAM */
      this.extraction_id =
        this.query_params.extraction_id &&
        this.extractionAllowedId(this.query_params.extraction_id)
          ? this.query_params.extraction_id
          : this.defaultExtractionId();
      this.extraction_date =
        this.query_params.extraction_id &&
        this.extractionAllowedId(this.query_params.extraction_id)
          ? this.getDateFromId(this.query_params.extraction_id)
          : this.defaultExtractionDate();

      this.init_date =
        this.query_params.init_date &&
        this.initAllowedDate(this.query_params.init_date)
          ? this.query_params.init_date
          : this.defaultInitDate();

      this.vs_id =
        this.query_params.vs_id && this.vsAllowedId(this.query_params.vs_id)
          ? this.query_params.vs_id
          : this.defaultVsId();
      this.vs_date =
        this.query_params.vs_id && this.vsAllowedId(this.query_params.vs_id)
          ? this.getDateFromId(this.query_params.vs_id)
          : this.defaultVsDate();

      this.page =
        this.query_params.page && parseInt(this.query_params.page) >= 0
          ? parseInt(this.query_params.page)
          : 0;

      this.mode =
        this.query_params.mode &&
        (this.query_params.mode == "rate" ||
          this.query_params.mode == "availability")
          ? this.query_params.mode
          : "rate";

      this.adults =
        this.query_params.adults &&
        [0, 2, 3, 4, 5, 6, 7].indexOf(parseInt(this.query_params.adults)) >= 0
          ? parseInt(this.query_params.adults)
          : this.adults;

      this.childs =
        this.query_params.childs &&
        [0, 1, 2].indexOf(parseInt(this.query_params.childs)) >= 0
          ? parseInt(this.query_params.childs)
          : this.childs;

      this.regime = this.query_params.regime
        ? JSON.parse(this.query_params.regime.slice(1, -1))
        : this.regime;

      this.cancel_policy = this.query_params.cancel_policy
        ? JSON.parse(this.query_params.cancel_policy.slice(1, -1))
        : this.cancel_policy;

      this.genius = this.query_params.genius ? true : false;

      this.mobile_device = this.query_params.mobile_device ? true : false;

      if (
        this.query_params.highlights === true ||
        this.query_params.highlights == "true"
      ) {
        this.showRateHighlight(true);
      }

      if (this.query_params.highlight_priority) {
        this.setHighlightPriority(
          JSON.parse(this.query_params.highlight_priority)
        );
      }

      this.date = this.query_params.date ? this.query_params.date : this.date;

      this.$store.dispatch("emptyInvisibleCompetitors");

      this.goToRate();
    },
    updateFilters(filters) {
      (this.extraction_date = filters.extraction_date),
        (this.extraction_id = filters.extraction_id),
        (this.init_date = filters.extraction_date),
        (this.vs_date = filters.vs_date),
        (this.vs_id = filters.vs_id),
        (this.date = filters.date),
        (this.pax = filters.pax),
        (this.childs = filters.childs),
        (this.regime = filters.regime),
        (this.genius = filters.genius),
        (this.mobile_device = filters.mobile_device),
        (this.cancel_policy = filters.cancel_policy),
        this.goToRate();
    },
    loaded() {
      // 1. If we have date param, we must goto this date
      if (this.filters.date) {
        this.goToDay(this.filters.date);
      }
      // 2. If the extraction date has changed, we have to go to the beginning (extraction date)
      else if (this.old_extraction_date !== this.extraction_date) {
        this.date = this.extraction_date;
        this.goToDay(this.filters.date);
      }
      // 3. Else, don't move
    },
    ...mapActions(["showRateHighlight", "setHighlightPriority"])
  },
  watch: {
    available_rate_extractions() {
      // Only when mounted was'nt executed because no current_hotel
      if (!this.extraction_id) {
        this.setDefault();
      }
    },
    extraction_id() {
      // Test if vs_date is valid. If not, set it!
      if (this.extraction_id != undefined && !this.vsAllowedId(this.vs_id)) {
        this.vs_date = this.defaultVsDate();
        this.vs_id = this.defaultVsId();
      }
    },
    extraction_menu() {
      // If user closes datepicker without selecting one import, set the first
      if (this.extraction_menu == false && this.extraction_id == undefined) {
        this.extraction_id = this.extraction_date_available_rate_extractions[0].id;
      }
    },
    vs_menu() {
      // If user closes datepicker without selecting one import, set the first
      if (this.vs_menu == false && this.vs_id == undefined) {
        this.vs_id = this.vsAllowedId(
          this.vs_date_available_rate_extractions[0].id
        )
          ? this.vs_date_available_rate_extractions[0].id
          : this.vs_date_available_rate_extractions[1].id;
      }
    }
  },
  computed: {
    hotel_competitor() {
      return this.competitors.find(c => c.self == 1);
    },
    hotel_web() {
      return this.webs.length ? this.webs[0] : null;
    },
    min_graph_value() {
      return Math.min(...this.visible_values);
    },
    max_graph_value() {
      return Math.max(...this.visible_values);
    },
    visible_values() {
      var visible_values = [];
      for (let c of this.visible_competitors) {
        for (let d of this.rendered_days) {
          if (
            this.daily_rates.report["competitor::" + c.id] &&
            this.daily_rates.report["competitor::" + c.id][d]
          ) {
            visible_values.push(
              this.daily_rates.report["competitor::" + c.id][d][0].min_price
            );
          }
        }
      }
      return visible_values;
    },
    visible_competitors() {
      return this.competitors.filter(
        c => this.invisible_competitors.indexOf(c.id) < 0
      );
    },
    rendered_days() {
      var min_page = this.page;
      var max_page = this.page + 1;
      var min_index = min_page * this.days_per_screen;
      var max_index = max_page * this.days_per_screen;
      return this.days.slice(min_index, max_index);
    },
    block() {
      var index = this.all_days.indexOf(this.init_date);
      var block = parseInt(index / this.total_days);
      return block;
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    filters() {
      return {
        hotel_id: this.current_hotel.id,
        extraction_date: this.extraction_date,
        extraction_id: this.extraction_id,
        vs_date: this.vs_date,
        vs_id: this.vs_id,
        date: this.date,
        pax: this.adults,
        childs: this.childs,
        regime: this.regime,
        genius: this.genius,
        mobile_device: this.mobile_device,
        cancel_policy: this.cancel_policy
      };
    },
    lastPage() {
      return parseInt(this.total_days / this.days_per_screen) - 1;
    },
    isLastPage() {
      return this.page == this.lastPage;
    },
    hasMorePages() {
      return this.lastDay < this.full_days;
    },
    lastDay() {
      return (
        this.block * this.total_days + (this.page + 1) * this.days_per_screen
      );
    },
    showMinusInfo() {
      return (
        this.old_filters &&
        this.init_date != this.old_filters.extraction_date &&
        this.page == 0
      );
    },
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },
    all_days() {
      var days = [];
      if (this.current_init_date) {
        var init_date = Vue.moment(this.current_init_date);
        for (var offset = 0; offset < this.full_days; offset++) {
          var date = init_date.clone().add(offset, "days");
          days.push(date.format("Y-MM-DD"));
        }
      }
      return days;
    },
    full_days() {
      return Object.keys(this.available_dates.dates).filter(date =>
        Vue.moment(date).isAfter()
      ).length;
    },
    days() {
      var days = [];
      if (this.init_date) {
        var init_date = Vue.moment(this.init_date);
        for (var offset = 0; offset < this.total_days; offset++) {
          var date = init_date.clone().add(offset, "days");
          days.push(date.format("Y-MM-DD"));
        }
      }
      return days;
    },
    formatedExtractionDate() {
      return this.formatDate(this.extraction_date);
    },
    formatedInitDate() {
      return this.formatDate(this.init_date);
    },
    formatedVsDate() {
      return this.formatDate(this.vs_date);
    },
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    extraction_date_available_rate_extractions() {
      return this.extraction_date && this.available_rate_extractions
        ? this.available_rate_extractions.find(
            node => node.date == this.extraction_date
          ).extractions
        : [];
    },
    extraction_time() {
      return this.extraction_date_available_rate_extractions.find(
        node => node.id == this.extraction_id
      )
        ? this.extraction_date_available_rate_extractions.find(
            node => node.id == this.extraction_id
          ).time
        : undefined;
    },
    vs_date_available_rate_extractions() {
      return this.vs_date && this.available_rate_extractions
        ? this.available_rate_extractions.find(
            node => node.date == this.vs_date
          ).extractions
        : [];
    },
    vs_time() {
      return this.vs_date_available_rate_extractions.find(
        node => node.id == this.vs_id
      )
        ? this.vs_date_available_rate_extractions.find(
            node => node.id == this.vs_id
          ).time
        : undefined;
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    ...mapState({
      competitors: state => state.rate.competitors,
      webs: state => state.parity.webs,
      invisible_competitors: state => state.rate.invisible_competitors,
      daily_rates: state => state.rate.daily_rates,
      daily_availability: state => state.rate.daily_availability,
      rate_date_to_compare: state => state.rate.rate_date_to_compare,
      query_params: state => state.route.query,
      available_dates: state => state.rate.available_dates,
      available_rate_extractions: state => state.rate.available_rate_extractions
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  updated() {},
  mounted() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.initDaysPerScreen();
      if (this.current_hotel) {
        this.setDefault();
      }
    });
  }
};
</script>

<style>
#rate-daily {
  width: 100%;
  min-height: 100px;
}

#rate-filter {
  margin: 0 auto;
  background-color: transparent;
}
#rate-filter .v-btn-toggle > .v-btn {
  height: 40px !important;
  opacity: 1;
}
#rate-filter .v-messages {
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}
#rate-filter .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 16px;
}
#rate-filter .v-messages__message {
  padding-left: 4px;
}
#rate-filter .v-text-field input,
#rate-highlight .v-text-field input {
  max-height: 50px !important;
  font-weight: 500;
  font-size: 14px;
}
#rate-filter .v-text-field--outlined fieldset {
  border-color: rgba(255, 255, 255, 0.14);
}
#rate-filter .v-btn:not(.v-btn--round).v-size--default {
  min-width: 45px;
}
.v-btn-toggle {
  border: 1px solid #2196f3 !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
  color: inherit !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn .v-icon {
  color: inherit !important;
}
.v-btn--fab.v-size--small {
  width: 36px;
  height: 36px;
}
.v-btn--fab.v-size--x-small {
  width: 24px;
  height: 24px;
}

/* DAILY RATES */

.day-graph-bg {
  height: 250px;
  width: 100%;
}
.rate-graph-wrapper {
  width: calc(100% - (100% / 126));
  height: 250px;
  margin-top: -250px;
  margin-bottom: 0;
  margin-left: calc(100% / (126 * 2));
  margin-right: calc(100% / (126 * 2));
}
/* 2 days per page */
.days-wrapper.x2 {
  width: calc((126 / 2) * 100%);
}
#rate-days-wrapper.page_0 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 0) / 126));
}
#rate-days-wrapper.page_1 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 1) / 126));
}
#rate-days-wrapper.page_2 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 2) / 126));
}
#rate-days-wrapper.page_3 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 3) / 126));
}
#rate-days-wrapper.page_4 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 4) / 126));
}
#rate-days-wrapper.page_5 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 5) / 126));
}
#rate-days-wrapper.page_6 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 6) / 126));
}
#rate-days-wrapper.page_7 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 7) / 126));
}
#rate-days-wrapper.page_8 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 8) / 126));
}
#rate-days-wrapper.page_9 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 9) / 126));
}
#rate-days-wrapper.page_10 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 10) / 126));
}
#rate-days-wrapper.page_11 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 11) / 126));
}
#rate-days-wrapper.page_12 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 12) / 126));
}
#rate-days-wrapper.page_13 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 13) / 126));
}
#rate-days-wrapper.page_14 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 14) / 126));
}
#rate-days-wrapper.page_15 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 15) / 126));
}
#rate-days-wrapper.page_16 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 16) / 126));
}
#rate-days-wrapper.page_17 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 17) / 126));
}
#rate-days-wrapper.page_18 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 18) / 126));
}
#rate-days-wrapper.page_19 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 19) / 126));
}
#rate-days-wrapper.page_20 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 20) / 126));
}
#rate-days-wrapper.page_21 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 21) / 126));
}
#rate-days-wrapper.page_22 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 22) / 126));
}
#rate-days-wrapper.page_23 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 23) / 126));
}
#rate-days-wrapper.page_24 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 24) / 126));
}
#rate-days-wrapper.page_25 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 25) / 126));
}
#rate-days-wrapper.page_26 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 26) / 126));
}
#rate-days-wrapper.page_27 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 27) / 126));
}
#rate-days-wrapper.page_28 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 28) / 126));
}
#rate-days-wrapper.page_29 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 29) / 126));
}
#rate-days-wrapper.page_30 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 30) / 126));
}
#rate-days-wrapper.page_31 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 31) / 126));
}
#rate-days-wrapper.page_32 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 32) / 126));
}
#rate-days-wrapper.page_33 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 33) / 126));
}
#rate-days-wrapper.page_34 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 34) / 126));
}
#rate-days-wrapper.page_35 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 35) / 126));
}
#rate-days-wrapper.page_36 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 36) / 126));
}
#rate-days-wrapper.page_37 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 37) / 126));
}
#rate-days-wrapper.page_38 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 38) / 126));
}
#rate-days-wrapper.page_39 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 39) / 126));
}
#rate-days-wrapper.page_40 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 40) / 126));
}
#rate-days-wrapper.page_41 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 41) / 126));
}
#rate-days-wrapper.page_42 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 42) / 126));
}
#rate-days-wrapper.page_43 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 43) / 126));
}
#rate-days-wrapper.page_44 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 44) / 126));
}
#rate-days-wrapper.page_45 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 45) / 126));
}
#rate-days-wrapper.page_46 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 46) / 126));
}
#rate-days-wrapper.page_47 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 47) / 126));
}
#rate-days-wrapper.page_48 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 48) / 126));
}
#rate-days-wrapper.page_49 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 49) / 126));
}
#rate-days-wrapper.page_50 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 50) / 126));
}
#rate-days-wrapper.page_51 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 51) / 126));
}
#rate-days-wrapper.page_52 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 52) / 126));
}
#rate-days-wrapper.page_53 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 53) / 126));
}
#rate-days-wrapper.page_54 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 54) / 126));
}
#rate-days-wrapper.page_55 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 55) / 126));
}
#rate-days-wrapper.page_56 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 56) / 126));
}
#rate-days-wrapper.page_57 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 57) / 126));
}
#rate-days-wrapper.page_58 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 58) / 126));
}
#rate-days-wrapper.page_59 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 59) / 126));
}
#rate-days-wrapper.page_60 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 60) / 126));
}
#rate-days-wrapper.page_61 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 61) / 126));
}
#rate-days-wrapper.page_62 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 62) / 126));
}
#rate-days-wrapper.page_63 .days-wrapper.x2 {
  transform: translateX(calc(((-100%) * 2 * 63) / 126));
}
/* 7 days per page */
.days-wrapper.x7 {
  width: calc((126 / 7) * 100%);
}
#rate-days-wrapper.page_0 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 0) / 126));
}
#rate-days-wrapper.page_1 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 1) / 126));
}
#rate-days-wrapper.page_2 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 2) / 126));
}
#rate-days-wrapper.page_3 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 3) / 126));
}
#rate-days-wrapper.page_4 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 4) / 126));
}
#rate-days-wrapper.page_5 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 5) / 126));
}
#rate-days-wrapper.page_6 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 6) / 126));
}
#rate-days-wrapper.page_7 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 7) / 126));
}
#rate-days-wrapper.page_8 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 8) / 126));
}
#rate-days-wrapper.page_9 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 9) / 126));
}
#rate-days-wrapper.page_10 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 10) / 126));
}
#rate-days-wrapper.page_11 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 11) / 126));
}
#rate-days-wrapper.page_12 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 12) / 126));
}
#rate-days-wrapper.page_13 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 13) / 126));
}
#rate-days-wrapper.page_14 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 14) / 126));
}
#rate-days-wrapper.page_15 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 15) / 126));
}
#rate-days-wrapper.page_16 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 16) / 126));
}
#rate-days-wrapper.page_17 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 17) / 126));
}
#rate-days-wrapper.page_18 .days-wrapper.x7 {
  transform: translateX(calc(((-100%) * 7 * 18) / 126));
}
/* 9 days per page */
.days-wrapper.x9 {
  width: calc((126 / 9) * 100%);
}
#rate-days-wrapper.page_0 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 0) / 126));
}
#rate-days-wrapper.page_1 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 1) / 126));
}
#rate-days-wrapper.page_2 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 2) / 126));
}
#rate-days-wrapper.page_3 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 3) / 126));
}
#rate-days-wrapper.page_4 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 4) / 126));
}
#rate-days-wrapper.page_5 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 5) / 126));
}
#rate-days-wrapper.page_6 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 6) / 126));
}
#rate-days-wrapper.page_7 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 7) / 126));
}
#rate-days-wrapper.page_8 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 8) / 126));
}
#rate-days-wrapper.page_9 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 9) / 126));
}
#rate-days-wrapper.page_10 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 10) / 126));
}
#rate-days-wrapper.page_11 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 11) / 126));
}
#rate-days-wrapper.page_12 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 12) / 126));
}
#rate-days-wrapper.page_13 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 13) / 126));
}
#rate-days-wrapper.page_14 .days-wrapper.x9 {
  transform: translateX(calc(((-100%) * 9 * 14) / 126));
}
/* 14 days per page */
.days-wrapper.x14 {
  width: calc((126 / 14) * 100%);
}
#rate-days-wrapper.page_0 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 0) / 126));
}
#rate-days-wrapper.page_1 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 1) / 126));
}
#rate-days-wrapper.page_2 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 2) / 126));
}
#rate-days-wrapper.page_3 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 3) / 126));
}
#rate-days-wrapper.page_4 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 4) / 126));
}
#rate-days-wrapper.page_5 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 5) / 126));
}
#rate-days-wrapper.page_6 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 6) / 126));
}
#rate-days-wrapper.page_7 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 7) / 126));
}
#rate-days-wrapper.page_8 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 8) / 126));
}
#rate-days-wrapper.page_9 .days-wrapper.x14 {
  transform: translateX(calc(((-100%) * 14 * 9) / 126));
}

/* Elements to show/hide in each day*/
.flex-container {
  display: flex;
  background: none;
  align-items: center;
  transition: 0.2s all;
}
.rate-data-row {
  display: flex;
  background: none;
  align-items: center;
  border-top: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
  position: relative;
}

#rate-sticky-header {
  background: #fff;
  position: fixed;
  top: 75px;
  z-index: 1500000;
  box-shadow: 0 7px 17px -8px #777;
  margin-right: 42px;
}
#rate-sticky-header #rate-events-vs {
  border-bottom: 0;
}
.item {
  background-color: #ffffff;
  margin: 0px;
  text-align: center;
  font-size: 30px;
}

.rate-labels {
  width: 200px;
  border-right: 3px solid #ffffff;
  background: none;
  text-align: left;
  color: #555;
  font-family: "Roboto Condensed";
  font-size: 0.8rem;
  font-weight: bold;
}

@media (max-width: 600px) {
  .v-btn:not(.v-btn--round).v-size--default.info {
    width: 100% !important;
    padding: 0 5px !important;
  }
  .rate-labels {
    width: 50px !important;
  }
  .rate-wrapper .shadow-before {
    left: 50px !important;
  }
  #arrow-left {
    left: 20px !important;
  }
  #minus-info {
    left: 20px !important;
  }
  #arrow-right {
    right: -19px !important;
  }
  #plus-info {
    right: -19px !important;
  }
}
.rate-data-row:hover .rate-labels {
  border-right: 3px solid #2d6ece;
}
.rate-labels .simple_view {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rate-labels .expanded_view {
  padding-left: 10px;
}
.simple_view .rate_data_item {
  font-size: 1rem;
  height: 40px;
  /* overflow: hidden; */
}
.simple_view .rate_data_item.rate_data_item_simple {
  font-size: 1rem;
  height: 60px;
}

.simple_view .rate_data_item .rate_data_main,
.expanded_view .level_1 .rate_data_main {
  font-size: 13px;
  font-weight: 500;
}
.simple_view .rate_data_item .rate_data_vs {
  font-size: 11px;
  font-weight: 500;
  color: #555555;
}
.matched_date {
  position: absolute;
  top: -2px;
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: orange;
  font-size: 10px;
  border: 1px solid transparent;
  transform: rotate(-45deg);
}
/*
.matched_date:hover{
    background: white;
    border: 1px solid;
    transform: rotate(-45deg);
}
*/
.matched_date.moved {
  color: #999999;
  transform: rotate(45deg);
}

.arrow-buttons {
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: center;
  margin: 10px;
}
.days {
  flex: 1;
  background: none;
  padding: 0px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  position: relative;
}
.rate-wrapper {
  background: none;
}
.rate-wrapper > div {
  position: relative;
}
.rate-wrapper .shadow-before {
  content: "";
  position: absolute;
  left: 200px;
  top: 0px;
  height: calc(100%);
  width: 6px;
  z-index: 10;

  background: -webkit-linear-gradient(
    left,
    rgba(200, 200, 200, 0.7),
    rgba(200, 200, 200, 0)
  ); /*Safari 5.1-6*/
  background: -o-linear-gradient(
    right,
    rgba(200, 200, 200, 0.7),
    rgba(200, 200, 200, 0)
  ); /*Opera 11.1-12*/
  background: -moz-linear-gradient(
    right,
    rgba(200, 200, 200, 0.7),
    rgba(200, 200, 200, 0)
  ); /*Fx 3.6-15*/
  background: linear-gradient(
    to right,
    rgba(200, 200, 200, 0.7),
    rgba(200, 200, 200, 0)
  ); /*Standard*/
}
.rate-wrapper .shadow-after {
  content: "";
  position: absolute;
  right: 0;
  top: 0px;
  height: calc(100%);
  width: 6px;
  z-index: 10;

  background: -webkit-linear-gradient(
    left,
    rgba(200, 200, 200, 0),
    rgba(200, 200, 200, 0.7)
  ); /*Safari 5.1-6*/
  background: -o-linear-gradient(
    right,
    rgba(200, 200, 200, 0),
    rgba(200, 200, 200, 0.7)
  ); /*Opera 11.1-12*/
  background: -moz-linear-gradient(
    right,
    rgba(200, 200, 200, 0),
    rgba(200, 200, 200, 0.7)
  ); /*Fx 3.6-15*/
  background: linear-gradient(
    to right,
    rgba(200, 200, 200, 0),
    rgba(200, 200, 200, 0.7)
  ); /*Standard*/
}
.graph-header .day {
  border-right: 0;
  padding: 3px 1px;
}
.graph-header-month {
  display: flex;
  height: 16px;
}
.graph-header-month > div {
  text-align: center;
  background: #ffffff;
  font-weight: 600;
  color: #000000;
  padding: 1px 0 0px 0;
}
.graph-header-month .month.x5 {
  flex: 5;
}
.graph-header-month .month.x6 {
  flex: 6;
}
.graph-header-month .month.x7 {
  flex: 7;
}
.graph-header-month .month.x8 {
  flex: 8;
}
.graph-header-month .month.x28 {
  flex: 28;
}
.graph-header-month .month.x29 {
  flex: 29;
}
.graph-header-month .month.x30 {
  flex: 30;
}
.graph-header-month .month.x31 {
  flex: 31;
}
.graph-header-month .month {
  color: #aaaaaa;
}
.graph-header-month .month-2 {
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  color: #000000;
}
#arrow-left {
  position: absolute;
  left: 160px;
  top: 260px;
  z-index: 200;
  background: #fff;
  color: #aaaaaa;
}
#arrow-right {
  position: absolute;
  right: -30px;
  top: 260px;
  z-index: 200;
  background: #fff;
  color: #aaaaaa;
}
#minus-info {
  position: absolute;
  left: 160px;
  top: 260px;
  z-index: 200;
}
#plus-info {
  position: absolute;
  right: -30px;
  top: 260px;
  z-index: 200;
}

.group-display #arrow-left,
.group-display #arrow-right,
.group-display #minus-info,
.group-display #plus-info {
  top: 0px !important;
}

.arrow-open {
  position: absolute !important;
  left: 162px;
  top: 14px;
  z-index: 150;
  background: #ffffff;
}
.arrow-close {
  position: absolute !important;
  left: 162px;
  top: 18px;
  z-index: 150;
  background: #ffffff;
}
#tab_bookingpace,
#tab_channelreservation,
#tab_antecedence,
#tab_cancellation {
  height: 330px;
  overflow: hidden;
}
/*
.arrow-close{
  position: absolute !important;
  left: calc(50% - 14px);
  bottom: -16px;
  z-index: 200;
}*/
.days-wrapper {
  padding: 0;
  background: transparent;
  display: flex;
  transition: transform 0.5s;
  transition-timing-function: ease;
}
.days-wrapper.no-flex {
  display: block;
}
.day {
  flex: 1;
  line-height: 20px;
  overflow: hidden;
  background: #ffffff;
  padding: 2px 1px;
  color: #000000;
  width: 100%;
  min-height: 40px;
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  font-weight: 400;
  text-align: center;
}
.day .day-date {
  cursor: pointer !important;
}
#rate-days .day {
  border-bottom: 3px solid #ffffff;
}
#rate-days .day.day-hover {
  border-bottom: 3px solid #2d6ece !important;
}
#rate-days .day.weekend {
  border-bottom: 3px solid rgba(219, 198, 77, 0.1);
}

#rate-days-vs .day {
  padding: 12px 1px 5px 1px !important;
  min-height: 0px !important;
  height: 40px;
  cursor: pointer;
}
#rate-days .day {
  padding: 5px 1px !important;
  min-height: 0px !important;
  height: 50px;
}
#rate-updated .day {
  overflow: visible;
  padding: 4px 0 3px 4px;
  min-height: 0px;
}
#rate-updated .day.weekend {
  border-bottom: none;
}
#rate-updated .v-avatar {
  position: absolute;
  top: 0;
}

.day progress {
  display: inline-block;
  width: 70%;
  margin: 0px 5px -2px 5px;
}

.day:hover .event-day {
  height: 100%;
}
.day.weekend {
  background: rgba(219, 198, 77, 0.1);
}
.graph-header {
  display: flex;
  height: 20px;
}
.graph-header .day.weekend {
  border-top-color: #ffffff;
  color: #c5b247;
}
#rate-events-vs {
  border-bottom: 1px solid #dedede;
}

#rate-events .labels,
#rate-events-vs .labels {
  padding: 0;
}

#rate-days .labels {
  font-size: 1.1rem;
  font-weight: 600;
  padding-left: 10px;
}
#rate-days-vs .labels {
  font-size: 1rem;
  font-weight: 500;
  padding-left: 10px;
}
.labels .level_1 i,
.labels .level_2 i,
.labels .level_3 i {
  font-size: 0.8rem !important;
  font-weight: 600;
  color: #cccccc;
  margin-right: 5px;
}

.labels div:last-child,
.days div:last-child {
  border-bottom: none;
}

.labels div.active,
.days div.active {
  /*border-bottom: 1px dashed #b8d8f3 !important;
    color: #167bfe;*/
  font-weight: 600;
}
.labels .level_1 {
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 0 0;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px dashed #eeeeee;
  overflow: hidden;
}
.labels .level_2 {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 0 0 0.5rem;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px dashed #eeeeee;
  margin-left: 0.1rem;
  overflow: hidden;
}
.labels .level_3 {
  font-weight: 400;
  margin-left: 0.9rem;
  padding: 0 0 0 0.5rem;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px dashed #eeeeee;
  overflow: hidden;
}

.days .level_1 {
  text-transform: uppercase;
  height: 50px;
  padding: 5px 0;
  border-bottom: 1px dashed #eeeeee;
}
.days .level_2 {
  text-transform: uppercase;
  font-weight: 400;
  height: 50px;
  padding: 5px 0;
  border-bottom: 1px dashed #eeeeee;
}
.days .level_3 {
  font-weight: 400;
  height: 50px;
  padding: 5px 0;
  border-bottom: 1px dashed #eeeeee;
}
.event.x2 {
  width: calc(200% + 4px);
}
.event.x3 {
  width: calc(300% + 6px);
}
.event.x4 {
  width: calc(400% + 8px);
}
.event.x5 {
  width: calc(500% + 10px);
}
.event.x6 {
  width: calc(600% + 12px);
}
.event.x7 {
  width: calc(700% + 14px);
}
.event.x8 {
  width: calc(800% + 16px);
}
.event.x9 {
  width: calc(900% + 18px);
}
.event.x10 {
  width: calc(1000% + 20px);
}
.event.x11 {
  width: calc(1000% + 22px);
}
.event.x12 {
  width: calc(1000% + 24px);
}
.event.x13 {
  width: calc(1000% + 26px);
}
.event.x14 {
  width: calc(1000% + 28px);
}
.event.x15 {
  width: calc(1000% + 30px);
}
.event.x16 {
  width: calc(1000% + 32px);
}
.event.x17 {
  width: calc(1000% + 34px);
}
.event.x18 {
  width: calc(1000% + 36px);
}
.event.x19 {
  width: calc(1000% + 38px);
}
.event.x20 {
  width: calc(1000% + 40px);
}
.day:hover {
  background: #f5fdff;
}
#rate-events-vs .day:hover,
#rate-events .day:hover,
#rate-days .day:hover,
#rate-updated .day:hover {
  background: #ffffff;
}
#rate-events-vs .day.weekend:hover,
#rate-events .day.weekend:hover,
#rate-days .day.weekend:hover,
#rate-updated .day.weekend:hover {
  background: rgba(219, 198, 77, 0.1);
}
.day-name {
  font-size: 12px;
  color: #aaa;
  padding-right: 2px;
  text-transform: uppercase;
  font-weight: 600;
}
.semibold .day-name {
  color: #666666;
}
.day-data {
  font-size: 12px;
  color: #000000;
}
.rate-evolution-wrapper {
  display: flex;
}
.add_event {
  opacity: 0;
  z-index: 1 !important;
}
.day:hover .add_event {
  opacity: 1;
}
.add_event i {
  font-size: 0.6rem !important;
}
.event {
  z-index: 2;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 0.6rem;
  border-radius: 15px;
  padding: 0;
  position: relative;
  background: rgba(255, 219, 56, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 219, 56, 1) 0%,
    rgba(240, 163, 40, 1) 43%,
    rgba(240, 163, 40, 1) 60%,
    rgba(255, 219, 56, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 219, 56, 1)),
    color-stop(43%, rgba(240, 163, 40, 1)),
    color-stop(60%, rgba(240, 163, 40, 1)),
    color-stop(100%, rgba(255, 219, 56, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 219, 56, 1) 0%,
    rgba(240, 163, 40, 1) 43%,
    rgba(240, 163, 40, 1) 60%,
    rgba(255, 219, 56, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 219, 56, 1) 0%,
    rgba(240, 163, 40, 1) 43%,
    rgba(240, 163, 40, 1) 60%,
    rgba(255, 219, 56, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 219, 56, 1) 0%,
    rgba(240, 163, 40, 1) 43%,
    rgba(240, 163, 40, 1) 60%,
    rgba(255, 219, 56, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 219, 56, 1) 0%,
    rgba(240, 163, 40, 1) 43%,
    rgba(240, 163, 40, 1) 60%,
    rgba(255, 219, 56, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdb38', endColorstr='#ffdb38', GradientType=1 );
  transition: all 0.5s;
  cursor: pointer;
}
.event:hover {
  box-shadow: 0 3px 7px -4px #000;
}
/* Modal form */
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-row > label {
  flex: 1;
  text-align: left;
}

.form-row > input,
.form-row > span {
  flex: 5;
  text-align: center;
}

/* Hacks */
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.wrapper {
  @include flexbox();
}

.item {
  @include flex(1 200px);
  @include order(2);
}

.m-btn--icon.m-btn--icon-only > i {
  left: 40% !important;
}

#period_selector,
#vs_selector {
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.7);
  transform: translateY(-2px);
}
#rate_selector {
  border-radius: 10px;
  position: relative;
  transform: translateY(-2px);
  margin: 15px;
  background: #fff;
  border-radius: 30px;
  padding: 0;
  overflow: hidden;
  height: 60px;
}
#sentence_selector {
  text-align: center;
  padding: 20px;
  border: 2px solid #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#sentence_selector > span {
  padding: 20px 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: #ffffff;
}
#sentence_selector > .selector-data {
  cursor: pointer;
  transition: all 0.2s;
  color: #81f2ff;
  border-radius: 25px;
  padding: 10px 0px;
}
#sentence_selector > .selector-data:hover,
#sentence_selector > .selector-data.open {
  background: rgba(255, 255, 255);
  padding: 10px 10px;
  margin: 0 5px;
  color: #195bf5;
  border-radius: 25px;
}

#rate_selector > div {
  position: relative;
}
#rate_selector .shadow-left {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(230, 230, 230, 0.8);
  height: calc(100%);
}
#rate_selector .shadow-right {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(230, 230, 230, 0.8);
  height: calc(100%);
}
#rate_selector::after {
  content: " ";
  width: 1px;
  height: 50px;
  border-right: 3px dashed #ffffff;
  position: absolute;
  left: 50%;
  bottom: -50px;
}

.chart-wrapper {
  width: 300%;
  position: absolute;
  z-index: 1;
  transition: 0.1s all;
}
#rate_graph {
  height: 150px;
  width: 100%;
  min-height: 50px;
  background: none;
}
#rate_graph_evolution_sentence {
  text-align: center;
  padding: 20px;
  font-size: 1.5rem;
  font-weight: 400;
}
#rate_graph_evolution {
  height: 210px;
  width: 100%;
  min-height: 50px;
  background: #ffffff;
}

#rate_graph_occupation {
  height: 250px;
  width: 100%;
  min-width: 450px;
  background: #ffffff;
  margin: 0px auto 0px auto;
}
#rate_graph_roomnights {
  height: 190px;
  width: 100%;
  min-width: 450px;
  background: #ffffff;
  margin: 20px auto 0px auto;
}
#rate_graph_antecedence,
#rate_graph_cancellation {
  height: 210px;
  width: 100%;
  background: #ffffff;
  margin: 0px auto;
}
#rate_graph_antecedence_ly,
#rate_graph_cancellation_ly {
  height: 210px;
  width: 100%;
  background: #ffffff;
  margin: 0px auto;
}
#rate_graph_antecedence_ly_nodata,
#rate_graph_cancellation_ly_nodata {
  text-align: center;
  height: 210px;
  line-height: 210px;
}
.rate_graph_roomtype {
  height: 140px;
  width: 100%;
  background: transparent;
  margin: 0px auto 0px auto;
}
.rate_graph_channel {
  height: 140px;
  width: 100%;
  background: transparent;
  margin: 0px auto 0px auto;
}
.rate_graph_country {
  height: 140px;
  width: 100%;
  background: transparent;
  margin: 0px auto 0px auto;
}
.rate_day_occupation_evolution {
  height: 170px;
  width: 100%;
  background: transparent;
}
.rate_day_adr_evolution {
  padding: 10px 0;
  height: 150px;
  width: 100%;
  background: transparent;
}
.rate_day_revpar_evolution {
  padding: 10px 0;
  height: 150px;
  width: 100%;
  background: transparent;
}
.rate_graph_day_evolution {
  height: 150px;
  padding: 10px 10px;
  width: 100%;
  background: transparent;
}
#myChannels {
  height: 110px;
  width: 100%;
  min-height: 50px;
  background: none;
}
#slider {
  height: 13px;
  background: none;
  border: none;
  box-shadow: none;
}
#slider:hover .noUi-connects {
  background: rgba(230, 230, 230, 0.9);
}
.noUi-base {
  overflow: visible;
}
.noUi-connects {
  /*background: linear-gradient(90deg, #eee 13%, rgb(210, 231, 255) 15%, rgb(210, 231, 255) 85%, #eee 88%);*/
  background: rgb(230, 230, 230, 0.3);
  overflow: visible !important;
  border: none;
  transition: all 0.5s;
}
.noUi-target .noUi-connect {
  box-shadow: 0 0 8px #666 !important;
}
.noUi-target.noUi-horizontal .noUi-handle {
  background: #22b9ff !important;
}

#rate-app .increment.positive {
  color: #a9d605 !important;
  padding: 1px 2px;
}
#rate-app .increment.negative {
  color: #ffffff !important;
  background: #ce4848;
  padding: 1px 3px;
  line-height: 1.2em;
  position: relative;
  top: -2px;
}
#rate-app .increment i {
  font-size: 0.6em !important;
  margin-left: 2px;
  margin-right: 1px;
}
@media (max-width: 1600px) {
  html {
  }
}

/* Loading */
.kpi_accumulative.is_loading .m-widget_content-items span {
  color: transparent !important;
  border-radius: 3px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f5f5f5;
  background: linear-gradient(to right, #eee 8%, #dddddd 18%, #eee 33%);
  background-size: 200% 100%;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

#rate-daily.is_loading .days {
  min-height: 35px;
  color: transparent !important;
  border-radius: 3px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f5f5f5;
  background: linear-gradient(to right, #eee 8%, #dddddd 18%, #eee 33%);
  background-size: 200% 100%;
}
.rate_monhtly_resume .m-portlet:hover {
  box-shadow: 0 0 9px 1px #999999;
  outline: 1px solid #ffffff;
}
.rate_monthly_resume .m-portlet {
  cursor: pointer;
  transition: all 0.5s;
}

/* Datepicker */
.vdp-datepicker__calendar header span,
.vdp-datepicker__calendar .cell {
  border-radius: 20px !important;
  font-weight: 500 !important;
}
.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .next {
  border-radius: 50% !important;
}
.vdp-datepicker__calendar header .next::after {
  border-left-color: #888888 !important;
}
.vdp-datepicker__calendar header .prev::after {
  border-right-color: #888888 !important;
}
.vdp-datepicker__calendar .cell.day {
  display: inline-block;
  padding: 0 4px;
  width: 13.4%;
  height: 40px;
  line-height: 39px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  min-height: 0;
  border-radius: 50%;
  margin: 0.4%;
}
.vdp-datepicker__calendar {
  margin: auto;
  border-radius: 5px;
  padding: 10px;
  width: 320px !important;
  border: none !important;
}

.m-widget29 .m-widget_content .m-widget_content-title {
  font-size: 1rem;
}
.m-widget29
  .m-widget_content
  .m-widget_content-items
  .m-widget_content-item
  > span {
  font-size: 1.1rem !important;
}

/* Copied from widget14 */
.m-widget29 .m-widget29__header {
  padding: 1.1rem 0;
  margin-bottom: 0.5rem;
}
.m-widget29 .m-widget29__header .m-widget29__title {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0 !important;
  color: #575962;
}
.m-widget29 .m-widget29__header .m-widget29__desc {
  display: inline-block;
  margin-top: 0.4rem;
  font-size: 1rem;
  color: #9699a2;
}

/* ROLLOVER RANKING STYLES */
#rate-daily .visible-day .a-rate-position {
  display: none;
}
#rate-daily.show-day-0 .visible-day-0 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-1 .visible-day-1 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-2 .visible-day-2 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-3 .visible-day-3 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-4 .visible-day-4 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-5 .visible-day-5 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-6 .visible-day-6 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-7 .visible-day-7 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-8 .visible-day-8 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-9 .visible-day-9 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-10 .visible-day-10 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-11 .visible-day-11 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-12 .visible-day-12 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-13 .visible-day-13 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-14 .visible-day-14 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-15 .visible-day-15 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-16 .visible-day-16 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-17 .visible-day-17 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-18 .visible-day-18 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-19 .visible-day-19 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-20 .visible-day-20 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-21 .visible-day-21 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-22 .visible-day-22 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-23 .visible-day-23 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-24 .visible-day-24 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-25 .visible-day-25 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-26 .visible-day-26 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-27 .visible-day-27 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-28 .visible-day-28 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-29 .visible-day-29 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-30 .visible-day-30 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-31 .visible-day-31 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-32 .visible-day-32 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-33 .visible-day-33 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-34 .visible-day-34 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-35 .visible-day-35 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-36 .visible-day-36 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-37 .visible-day-37 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-38 .visible-day-38 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-39 .visible-day-39 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-40 .visible-day-40 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-41 .visible-day-41 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-42 .visible-day-42 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-43 .visible-day-43 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-44 .visible-day-44 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-45 .visible-day-45 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-46 .visible-day-46 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-47 .visible-day-47 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-48 .visible-day-48 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-49 .visible-day-49 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-50 .visible-day-50 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-51 .visible-day-51 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-52 .visible-day-52 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-53 .visible-day-53 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-54 .visible-day-54 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-55 .visible-day-55 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-56 .visible-day-56 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-57 .visible-day-57 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-58 .visible-day-58 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-59 .visible-day-59 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-60 .visible-day-60 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-61 .visible-day-61 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-62 .visible-day-62 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-63 .visible-day-63 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-64 .visible-day-64 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-65 .visible-day-65 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-66 .visible-day-66 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-67 .visible-day-67 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-68 .visible-day-68 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-69 .visible-day-69 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-70 .visible-day-70 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-71 .visible-day-71 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-72 .visible-day-72 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-73 .visible-day-73 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-74 .visible-day-74 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-75 .visible-day-75 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-76 .visible-day-76 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-77 .visible-day-77 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-78 .visible-day-78 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-79 .visible-day-79 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-80 .visible-day-80 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-81 .visible-day-81 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-82 .visible-day-82 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-83 .visible-day-83 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-84 .visible-day-84 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-85 .visible-day-85 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-86 .visible-day-86 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-87 .visible-day-87 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-88 .visible-day-88 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-89 .visible-day-89 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-90 .visible-day-90 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-91 .visible-day-91 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-92 .visible-day-92 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-93 .visible-day-93 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-94 .visible-day-94 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-95 .visible-day-95 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-96 .visible-day-96 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-97 .visible-day-97 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-98 .visible-day-98 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-99 .visible-day-99 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-100 .visible-day-100 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-101 .visible-day-101 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-102 .visible-day-102 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-103 .visible-day-103 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-104 .visible-day-104 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-105 .visible-day-105 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-106 .visible-day-106 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-107 .visible-day-107 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-108 .visible-day-108 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-109 .visible-day-109 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-110 .visible-day-110 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-111 .visible-day-111 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-112 .visible-day-112 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-113 .visible-day-113 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-114 .visible-day-114 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-115 .visible-day-115 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-116 .visible-day-116 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-117 .visible-day-117 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-118 .visible-day-118 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-119 .visible-day-119 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-120 .visible-day-120 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-121 .visible-day-121 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-122 .visible-day-122 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-123 .visible-day-123 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-124 .visible-day-124 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-125 .visible-day-125 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-126 .visible-day-126 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-127 .visible-day-127 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-128 .visible-day-128 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-129 .visible-day-129 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-130 .visible-day-130 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-131 .visible-day-131 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-132 .visible-day-132 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-133 .visible-day-133 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-134 .visible-day-134 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-135 .visible-day-135 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-136 .visible-day-136 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-137 .visible-day-137 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-138 .visible-day-138 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-139 .visible-day-139 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-140 .visible-day-140 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-141 .visible-day-141 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-142 .visible-day-142 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-143 .visible-day-143 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-144 .visible-day-144 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-145 .visible-day-145 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-146 .visible-day-146 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-147 .visible-day-147 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-148 .visible-day-148 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-149 .visible-day-149 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-150 .visible-day-150 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-151 .visible-day-151 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-152 .visible-day-152 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-153 .visible-day-153 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-154 .visible-day-154 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-155 .visible-day-155 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-156 .visible-day-156 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-157 .visible-day-157 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-158 .visible-day-158 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-159 .visible-day-159 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-160 .visible-day-160 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-161 .visible-day-161 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-162 .visible-day-162 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-163 .visible-day-163 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-164 .visible-day-164 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-165 .visible-day-165 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-166 .visible-day-166 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-167 .visible-day-167 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-168 .visible-day-168 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-169 .visible-day-169 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-170 .visible-day-170 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-171 .visible-day-171 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-172 .visible-day-172 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-173 .visible-day-173 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-174 .visible-day-174 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-175 .visible-day-175 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-176 .visible-day-176 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-177 .visible-day-177 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-178 .visible-day-178 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-179 .visible-day-179 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-180 .visible-day-180 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-181 .visible-day-181 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-182 .visible-day-182 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-183 .visible-day-183 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-184 .visible-day-184 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-185 .visible-day-185 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-186 .visible-day-186 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-187 .visible-day-187 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-188 .visible-day-188 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-189 .visible-day-189 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-190 .visible-day-190 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-191 .visible-day-191 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-192 .visible-day-192 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-193 .visible-day-193 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-194 .visible-day-194 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-195 .visible-day-195 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-196 .visible-day-196 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-197 .visible-day-197 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-198 .visible-day-198 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-199 .visible-day-199 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-200 .visible-day-200 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-201 .visible-day-201 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-202 .visible-day-202 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-203 .visible-day-203 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-204 .visible-day-204 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-205 .visible-day-205 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-206 .visible-day-206 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-207 .visible-day-207 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-208 .visible-day-208 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-209 .visible-day-209 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-210 .visible-day-210 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-211 .visible-day-211 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-212 .visible-day-212 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-213 .visible-day-213 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-214 .visible-day-214 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-215 .visible-day-215 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-216 .visible-day-216 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-217 .visible-day-217 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-218 .visible-day-218 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-219 .visible-day-219 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-220 .visible-day-220 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-221 .visible-day-221 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-222 .visible-day-222 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-223 .visible-day-223 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-224 .visible-day-224 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-225 .visible-day-225 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-226 .visible-day-226 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-227 .visible-day-227 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-228 .visible-day-228 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-229 .visible-day-229 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-230 .visible-day-230 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-231 .visible-day-231 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-232 .visible-day-232 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-233 .visible-day-233 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-234 .visible-day-234 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-235 .visible-day-235 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-236 .visible-day-236 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-237 .visible-day-237 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-238 .visible-day-238 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-239 .visible-day-239 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-240 .visible-day-240 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-241 .visible-day-241 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-242 .visible-day-242 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-243 .visible-day-243 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-244 .visible-day-244 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-245 .visible-day-245 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-246 .visible-day-246 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-247 .visible-day-247 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-248 .visible-day-248 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-249 .visible-day-249 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-250 .visible-day-250 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-251 .visible-day-251 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-252 .visible-day-252 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-253 .visible-day-253 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-254 .visible-day-254 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-255 .visible-day-255 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-256 .visible-day-256 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-257 .visible-day-257 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-258 .visible-day-258 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-259 .visible-day-259 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-260 .visible-day-260 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-261 .visible-day-261 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-262 .visible-day-262 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-263 .visible-day-263 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-264 .visible-day-264 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-265 .visible-day-265 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-266 .visible-day-266 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-267 .visible-day-267 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-268 .visible-day-268 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-269 .visible-day-269 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-270 .visible-day-270 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-271 .visible-day-271 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-272 .visible-day-272 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-273 .visible-day-273 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-274 .visible-day-274 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-275 .visible-day-275 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-276 .visible-day-276 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-277 .visible-day-277 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-278 .visible-day-278 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-279 .visible-day-279 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-280 .visible-day-280 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-281 .visible-day-281 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-282 .visible-day-282 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-283 .visible-day-283 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-284 .visible-day-284 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-285 .visible-day-285 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-286 .visible-day-286 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-287 .visible-day-287 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-288 .visible-day-288 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-289 .visible-day-289 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-290 .visible-day-290 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-291 .visible-day-291 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-292 .visible-day-292 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-293 .visible-day-293 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-294 .visible-day-294 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-295 .visible-day-295 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-296 .visible-day-296 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-297 .visible-day-297 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-298 .visible-day-298 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-299 .visible-day-299 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-300 .visible-day-300 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-301 .visible-day-301 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-302 .visible-day-302 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-303 .visible-day-303 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-304 .visible-day-304 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-305 .visible-day-305 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-306 .visible-day-306 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-307 .visible-day-307 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-308 .visible-day-308 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-309 .visible-day-309 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-310 .visible-day-310 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-311 .visible-day-311 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-312 .visible-day-312 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-313 .visible-day-313 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-314 .visible-day-314 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-315 .visible-day-315 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-316 .visible-day-316 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-317 .visible-day-317 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-318 .visible-day-318 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-319 .visible-day-319 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-320 .visible-day-320 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-321 .visible-day-321 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-322 .visible-day-322 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-323 .visible-day-323 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-324 .visible-day-324 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-325 .visible-day-325 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-326 .visible-day-326 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-327 .visible-day-327 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-328 .visible-day-328 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-329 .visible-day-329 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-330 .visible-day-330 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-331 .visible-day-331 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-332 .visible-day-332 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-333 .visible-day-333 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-334 .visible-day-334 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-335 .visible-day-335 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-336 .visible-day-336 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-337 .visible-day-337 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-338 .visible-day-338 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-339 .visible-day-339 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-340 .visible-day-340 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-341 .visible-day-341 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-342 .visible-day-342 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-343 .visible-day-343 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-344 .visible-day-344 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-345 .visible-day-345 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-346 .visible-day-346 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-347 .visible-day-347 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-348 .visible-day-348 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-349 .visible-day-349 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-350 .visible-day-350 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-351 .visible-day-351 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-352 .visible-day-352 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-353 .visible-day-353 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-354 .visible-day-354 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-355 .visible-day-355 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-356 .visible-day-356 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-357 .visible-day-357 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-358 .visible-day-358 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-359 .visible-day-359 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-360 .visible-day-360 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-361 .visible-day-361 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-362 .visible-day-362 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-363 .visible-day-363 .a-rate-position {
  display: block !important;
}
#rate-daily.show-day-364 .visible-day-364 .a-rate-position {
  display: block !important;
}
.v-picker {
  display: block !important;
}
</style>
