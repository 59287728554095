<template>
  <div
    class="a-rms-day-row"
    @mouseover="hoverRow(index)"
    @mouseleave="hoverRow(null)"
  >
    <v-row no-gutters>
      <v-col cols="3">
        <a-kpi
          :class="getOccClass(values[0])"
          :value="values[0]"
          :kpi="kpis[0]"
          xx_small
          right
        ></a-kpi>
      </v-col>
      <v-col cols="2">
        <a-kpi
          :class="text_class"
          :value="values[1]"
          :kpi="kpis[1]"
          xx_small
          right
        ></a-kpi>
      </v-col>
      <v-col cols="3">
        <a-kpi
          :class="text_class"
          :value="values[2]"
          :kpi="kpis[2]"
          xx_small
          right
        ></a-kpi>
      </v-col>
      <v-col cols="4">
        <a-kpi
          :class="text_class"
          :value="values[3]"
          :kpi="kpis[3]"
          xx_small
          right
        ></a-kpi
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aKpi from "@/components/rms_ui/aKpi";

export default {
  name: "a-rms-day-row",
  components: { aKpi },
  props: {
    periods: {
      type: Array,
      default: () => ["otb", "ly"]
    },
    day_data: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      nullable: true,
      default: null
    },
    text_class: {
      type: String,
      nullable: true,
      default: null
    }
  },
  data: vm => ({
    kpis: ["occupancy", "rn", "adr", "room_revenue"]
  }),
  watch: {},
  methods: {
    getOccClass(v) {
      let clas = [this.text_class];
      if (v >= this.rms.occupancy_max) clas.push("green--text");
      if (v <= this.rms.occupancy_min) clas.push("red--text");
      return clas.join(" ");
    },
    hoverRow(v) {
      this.$emit("hoverRow", v);
    },
    getValue(index, kpi) {
      return this.day_data[this.periods[index]] &&
        this.day_data[this.periods[index]][kpi]
        ? this.day_data[this.periods[index]][kpi]
        : 0;
    },
    ...mapActions([])
  },
  computed: {
    values() {
      if (this.day_data == undefined) return [null, null, null, null];
      let values = [];
      for (let kpi of this.kpis) {
        if (this.periods[1]) {
          values.push(this.getValue(0, kpi) - this.getValue(1, kpi));
        } else {
          values.push(this.getValue(0, kpi));
        }
      }
      return values;
    },
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({})
  },
  mounted() {}
};
</script>
