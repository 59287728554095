<template>
  <div id="rate-disparities" class="d-flex">
    <div class="item rate-labels semibold"></div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="day in days"
          :key="'rate-graph-' + day"
          class="day d-flex justify-center"
          :class="{ weekend: is_weekend(day), 'day-hover': date_hover == day }"
          @mouseover="date_hover = day"
          @mouseout="date_hover = ''"
        >
          <div
            class="day-date semibold align-center justify-center d-flex"
            v-if="daily_parities[day]"
          >
            <div class="mx-1">
              <v-tooltip color="green" bottom>
                <template v-slot:activator="{ on }">
                  <v-progress-circular
                    v-on="on"
                    :rotate="-90"
                    :size="25"
                    :value="
                      daily_parities[day].ok.percent +
                        daily_parities[day].impaired.percent
                    "
                    :color="parity_colors['ok']"
                  >
                    {{
                      daily_parities[day].ok.value +
                        daily_parities[day].impaired.value
                    }}
                  </v-progress-circular>
                </template>
                <span>
                  {{ $t("day_parity") }}: <br /><b
                    class="subtitle-1 text-center d-block"
                    >{{
                      daily_parities[day].ok.percent +
                        daily_parities[day].impaired.percent
                    }}%</b
                  >
                </span>
              </v-tooltip>
            </div>
            <div class="mx-1">
              <v-avatar :size="25" :color="parity_colors['low']">
                <span class="white--text">{{
                  daily_parities[day].low.value
                }}</span>
              </v-avatar>
            </div>
            <div class="mx-1">
              <v-avatar :size="25" :color="parity_colors['critical']">
                <span class="white--text">{{
                  daily_parities[day].critical.value
                }}</span>
              </v-avatar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  name: "rate-day",
  components: {},
  props: [
    "days",
    "wrapper_position",
    "days_per_screen",
    "language",
    "daily_parities"
  ],
  data: vm => ({}),
  watch: {},
  methods: {
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    }
  },
  computed: {
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    ...mapState({
      parity_colors: state => state.parity_colors
    })
  }
};
</script>
