<template>
  <v-row class="row-group mx-0">
    <v-col class="special-rates-group-labels pa-0">
      <div class="label-wrapper d-flex align-center">
        {{ getName }}
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-window v-model="current_slide">
        <v-window-item v-for="(item, itemKey) of windowItems" :key="itemKey">
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(day, index) of getDays"
              :key="index"
              class="pa-0 d-flex justify-center cell-group"
              :class="{ 'bg-weekend': isWeekend(day) }"
              @mouseenter="
                setSpecialRatesHoveredRate({
                  rate_id: rowData.rate_id,
                  date: day,
                  row_index: rowIndex
                })
              "
            >
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";

export default {
  name: "a-special-rates-table-row-group",
  props: {
    rowData: Object,
    rowIndex: Number
  },
  data: () => ({
    current_slide: 0
  }),
  methods: {
    isWeekend(day) {
      return Vue.moment(day).isoWeekday() >= 5;
    },
    setSpecialRatesHoveredRate(event) {
      this.$store.dispatch("setSpecialRatesHoveredRate", event);
    }
  },
  watch: {
    slide_status(newValue, oldValue) {
      if (newValue === 2) {
        this.current_slide = this.page;
      }
    }
  },
  computed: {
    slide_status() {
      return this.$store.state.specialrates.slide_status;
    },
    getName() {
      return this.rowData && this.rowData.isGroup
        ? this.rowData.group_name
        : "";
    },
    getDays() {
      return this.rowData && Object.keys(this.rowData.days);
    },
    page() {
      return this.$store.state.specialrates.page;
    },
    windowItems() {
      return this.$store.state.specialrates.windowItems;
    }
  }
};
</script>
<style lang="scss" scoped>
$labelFontWeight: 700;
$labelFontSize: 0.8rem;
$labelColor: #555;
$bgColorHotel: #ddd;
.row-group {
  background-color: $bgColorRoom;
  &:hover {
    .label-wrapper {
      border-right: 3px solid $highlightColRowColor;
    }
  }
  .cell-group {
    height: 44px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }
}
.special-rates-group-labels {
  max-width: $widthLeftSide;
  .label-wrapper {
    border-top: $borderType $borderColor;
    border-bottom: $borderType $borderColor;
    height: $minHeightColRow;
    border-right: 3px solid transparent;
    padding-left: 10px;
    width: $widthLeftSide;
    font-size: $labelFontSize;
    font-weight: $labelFontWeight;
    color: $labelColor;
    justify-content: space-between;
  }
}
.bg-weekend {
  background-color: $weekendBgColor;
}
::v-deep .v-window__container {
  height: $minHeightColRow;
}
</style>
