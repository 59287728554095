<template>
  <zingchart
    :height="height"
    :width="width"
    :data="graph_settings"
    :series="graph_series"
    v-if="values.length > 0 || vs_values.length > 0"
  ></zingchart>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-header-chart",
  props: {
    height: {
      type: [String, Number],
      default: "150px"
    },
    width: {
      type: [String, Number],
      default: "auto"
    },
    label_text: {
      type: String,
      default: ""
    },
    kpi: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    }
  },
  data: vm => ({
    subscribed_modules: ["bi_report", "bi_daily_report"]
  }),
  methods: {},
  computed: {
    max_value() {
      return this.max !== null
        ? this.max
        : Math.max(...this.values, ...this.vs_values) + 1;
    },
    min_value() {
      return this.min !== null
        ? this.min
        : Math.min(...this.values, ...this.vs_values);
    },
    graph_settings() {
      var labels = [];
      var margin = "10px 0px 10px 0px";
      if (this.is_current_period) {
        labels.push({
          text: this.values[this.current_period_index] + " " + this.label_text,
          padding: "5px 10px",
          backgroundColor: "transparent",
          borderRadius: "3px",
          borderColor: "#fff",
          borderWidth: "1px",
          callout: true,
          calloutHeight: "5px",
          calloutWidth: "10px",
          fontColor: "#fff",
          fontSize: "13px",
          fontFamily: "Roboto Condensed",
          hook: "node:plot=0,index=" + this.current_period_index,
          offsetY: "-30px",
          underline: false
        });
        if (parseInt(Vue.moment().format("D")) > 25) {
          margin = "10px 55px 10px 0px";
        }
        if (parseInt(Vue.moment().format("D")) <= 4) {
          margin = "10px 0px 10px 55px";
        }
      }
      var tooltip = this.chart_settings.tooltip;
      tooltip["align"] = "center";
      tooltip["lineHeight"] = "23px";
      tooltip["fontSize"] = "18px";
      return {
        type: "mixed",
        backgroundColor: "transparent",
        tooltip: {
          visible: false
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        scaleX: {
          visible: false,
          values: this.current_period_items
        },
        scaleY: {
          visible: false,
          values: this.min_value + ":" + this.max_value
        },
        plot: {
          aspect: "spline"
        },
        plotarea: {
          margin: margin
        },
        labels: labels,
        crosshairX: {
          plotLabel: tooltip,
          lineColor: "#cacaca",
          lineWidth: 2,
          lineStyle: "dashed",
          marker: {
            backgroundColor: "#ffffff",
            size: 4,
            borderWidth: 2,
            borderColor: "#444444",
            opacity: "0.8",
            shadow: 2
          }
        }
      };
    },
    graph_series() {
      return [
        {
          scales: "scale-x,scale-y",
          type: "area",
          values: this.values,
          backgroundColor: "white transparent",
          lineColor: "white",
          lineWidth: 2,
          alpha: "1",
          guideLabel: {
            text:
              "<span style='font-size:13px;font-weight:bold;color:#333333;'>" +
              this.$t(this.kpi) +
              "</span><br><span style='font-size:13px;font-weight:bold;background-color:#333333; color:white;border-radius:15px; padding: 2px 5px;'>%v</span> <span style='color: #666666;font-size:13px;font-weight:bold;'>%kt </span>",
            thousandsSeparator: ".",
            decimalsSeparator: ","
          },
          marker: {
            visible: false
          }
        },
        {
          scales: "scale-x,scale-y",
          type: "area",
          values: this.vs_values,
          backgroundColor: "#ffc107 transparent",
          lineColor: "#ffc107",
          lineWidth: 2,
          alpha: "0.6",
          "data-date-values": this.vs_period_items,
          guideLabel: {
            text:
              "<span style='font-size:13px;font-weight:bold;background-color:%color; color:white;border-radius:15px; padding: 2px 5px;'>%v</span> <span style='color: #666666;font-size:13px;font-weight:bold;'>%data-date-values</span>",
            thousandsSeparator: ".",
            decimalsSeparator: ","
          },
          marker: {
            visible: false
          }
        }
      ];
    },
    values() {
      var values = [];
      for (var day_report of this.bi_daily_report.daily_list) {
        var v = parseFloat(day_report[this.kpi]);
        if (!Number.isNaN(v)) {
          values.push(v);
        }
      }
      return values;
    },
    vs_values() {
      var values = [];
      for (var day_report of this.bi_daily_report.daily_list_to_compare) {
        var v = parseFloat(day_report[this.kpi]);
        if (!Number.isNaN(v)) {
          values.push(v);
        }
      }
      return values;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      bi_daily_report: state => state.bi.daily_report
    }),
    ...mapGetters({
      current_period_items: "current_period_items",
      vs_period_items: "vs_period_items",
      current_period_index: "current_period_index",
      is_current_period: "is_current_period"
    })
  }
};
</script>
