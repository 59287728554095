<template>
  <!-- <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default> -->
  <v-row>
    <v-col cols="12" md="3">
      <a-card-kpi
        :value="rms.strategy_range_kpis.otb.room_revenue"
        :vs_values="[
          rms.strategy_range_kpis.stly.room_revenue,
          rms.strategy_range_kpis.bud.room_revenue,
          rms.strategy_range_kpis.ly.room_revenue
        ]"
        :periods="['otb', 'stly', 'bud', 'ly']"
        kpi="room_revenue"
        label="revenue"
      ></a-card-kpi>
    </v-col>
    <v-col cols="12" md="3">
      <a-card-kpi
        :value="rms.strategy_range_kpis.otb.occupancy"
        :vs_values="[
          rms.strategy_range_kpis.stly.occupancy,
          rms.strategy_range_kpis.bud.occupancy,
          rms.strategy_range_kpis.ly.occupancy
        ]"
        :periods="['otb', 'stly', 'bud', 'ly']"
        kpi="occupancy"
        label="occupancy"
      ></a-card-kpi>
    </v-col>
    <v-col cols="12" md="3">
      <a-card-kpi
        :value="rms.strategy_range_kpis.otb.adr"
        :vs_values="[
          rms.strategy_range_kpis.stly.adr,
          rms.strategy_range_kpis.bud.adr,
          rms.strategy_range_kpis.ly.adr
        ]"
        :periods="['otb', 'stly', 'bud', 'ly']"
        kpi="adr"
        label="adr"
      ></a-card-kpi>
    </v-col>
    <v-col cols="12" md="3">
      <a-card-kpi
        :value="rms.strategy_range_kpis.otb.revpar"
        :vs_values="[
          rms.strategy_range_kpis.stly.revpar,
          rms.strategy_range_kpis.bud.revpar,
          rms.strategy_range_kpis.ly.revpar
        ]"
        :periods="['otb', 'stly', 'bud', 'ly']"
        kpi="revpar"
        label="revpar"
      ></a-card-kpi>
    </v-col>
  </v-row>
  <!-- </template>
  </a-base-component> -->
</template>

<script>
import { mapState } from "vuex";
//import aBaseComponent from "@/components/aBaseComponent";
import aCardKpi from "@/components/rms_ui/aCardKpi";

export default {
  name: "a-rms-strategy-weeks-resume-kpis",
  components: {
    //aBaseComponent,
    aCardKpi
  },
  /* data: () => ({
    subscribed_modules: ["rms_strategy_range_kpis"]
  }), */
  computed: {
    ...mapState({
      rms: state => state.rms
    })
  }
};
</script>
