<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :mobile_image_height="mobile_image_height"
  >
    <template v-slot:header_options>
      <div class="d-flex mt-3 align-center">
        <v-select
          v-model="ly_year"
          :items="ly_years"
          item-text="name"
          item-value="id"
          :label="$t('ly_year')"
          :rules="[v => !!v || $t('required')]"
          class="mr-2"
          color="#fff"
          rounded
          outlined
          dense
          hide-details
          v-if="!isGroup && show_ly_years"
        >
          <template v-slot:[`item`]="{ item }">
            <span>
              {{ item.name }}
            </span>
          </template>
        </v-select>
        <v-select
          v-model="commission"
          :items="commission_types"
          item-text="name"
          item-value="id"
          :label="$t('billing_type')"
          :rules="[v => !!v || $t('required')]"
          class="mr-2"
          color="#fff"
          rounded
          outlined
          dense
          hide-details
          v-if="!isGroup && show_production_mode"
        >
          <template v-slot:[`item`]="{ item }">
            <span>
              {{ item.name }}
            </span>
          </template>
        </v-select>
        <a-rms-import-dates
          ref="rms_import_dates"
          v-if="!isGroup"
        ></a-rms-import-dates>
      </div>
    </template>
    <a-hotel-selector-rms v-if="isGroup"></a-hotel-selector-rms>
    <rms-year-sales-app
      v-else
      ref="rms-year-sales-hotelsdot-app"
    ></rms-year-sales-app>
  </a-page>
</template>

<script>
import aPage from "@/components/aPage";
import rmsYearSalesApp from "@/components/app/default/rmsYearSalesApp.vue";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms";
import aRmsImportDates from "@/components/rms/aRmsImportDates";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";

export default {
  name: "rms-year-sales",
  components: {
    aPage,
    rmsYearSalesApp,
    aHotelSelectorRms,
    aRmsImportDates
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    mobile_image_height: 1200 // Optional number,
  }),
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 700 : 200;
    },
    ly_years() {
      const ly_years = [];
      Object.keys(this.available_years).forEach(year => {
        if (year < Vue.moment().format("YYYY")) {
          ly_years.push({ id: parseInt(year), name: year });
        }
      });
      return ly_years;
    },
    show_ly_years() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rms_compare_prev_years &&
            this.current_hotel.permissions.rms_compare_prev_years
        : false;
    },
    show_production_mode() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rms_production_mode &&
            this.current_hotel.permissions.rms_production_mode
        : false;
    },
    commission: {
      get() {
        return this.$store.state.rms.year_sales_commission;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesCommission", value);
      }
    },
    ly_year: {
      get() {
        return this.$store.state.rms.year_sales_ly_year;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesLyYear", value);
      }
    },
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      commission_types: "commission_types"
    }),
    ...mapState({
      available_years: state => state.hotel.hotel_available_years_data
    })
  },
  mounted() {
    this.$store.dispatch("setRmsDashboardYear", Vue.moment().format("YYYY"));
    this.ly_year = Vue.moment().format("YYYY") - 1;
  }
};
</script>
