<template>
  <div class="grey--text subtitle-2 text-uppercase">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "a-label"
};
</script>
