<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    main_filters
  >
    <!-- <v-row>
      <v-col cols="6" v-for="(test, index) in aIncrement_tests" :key="index">
        <v-card style="position:relative;">
          <v-card-text>
            <v-row class="align-center">
              <v-col cols="12" md="3"> VALUE:<br />{{ test.value }} </v-col>
              <v-col cols="12" md="3">
                VS VALUE:<br />{{ test.vs_value }}
              </v-col>
              <v-col cols="12" md="3">
                Mostrar:<br />
                <template v-if="test.absolute">
                  <span class="body-1 font-weight-bold">€</span>
                </template>
                <template v-else>
                  <span class="body-1 font-weight-bold">%</span>
                </template>
              </v-col>
              <v-col cols="12" md="3">
                <aIncrement
                  :value="test.value"
                  :vs_value="test.vs_value"
                  :absolute="test.absolute"
                  kpi="revenue"
                  period="ly"
                ></aIncrement>
                <v-icon
                  v-if="!test.status"
                  style="position:absolute; right: 30px; top:40%;"
                  color="red"
                  >mdi-alert-outline</v-icon
                >
                <v-icon
                  v-else
                  style="position:absolute; right: 30px; top:40%;"
                  color="green"
                  >mdi-check</v-icon
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="6" v-for="(test, index) in aIncrement_tests" :key="index">
        <v-card style="position:relative;">
          <v-card-text>
            <v-row class="align-center">
              <v-col cols="12" md="2"> VALUE:<br />{{ test.value }} </v-col>
              <v-col cols="12" md="2">
                VS VALUE:<br />{{ test.vs_value }}
              </v-col>
              <v-col cols="12" md="2">
                Mostrar:<br />
                <template v-if="test.absolute">
                  <span class="body-1 font-weight-bold">€</span>
                </template>
                <template v-else>
                  <span class="body-1 font-weight-bold">%</span>
                </template>
              </v-col>
              <v-col cols="12" md="3">
                <a-kpi-columns
                  :value="test.value"
                  :vs_values="test.vs_values"
                  :vs_periods="test.vs_periods"
                  :absolute="test.absolute"
                  kpi="revenue"
                  small
                  right
                ></a-kpi-columns>
              </v-col>
              <v-col cols="12" md="3">
                <v-icon
                  v-if="!test.status"
                  style="position:absolute; right: 30px; top:40%;"
                  color="red"
                  >mdi-alert-outline</v-icon
                >
                <v-icon
                  v-else
                  style="position:absolute; right: 30px; top:40%;"
                  color="green"
                  >mdi-check</v-icon
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import aKpi from "@/components/rms_ui/aKpi";
import aKpiColumns from "@/components/rms_ui/aKpiColumns";
import aIncrement from "@/components/rms_ui/aIncrement";
import aCard from "@/components/aCard";
import aReputationList from "@/components/reputation/aReputationList";
import aHotelSelector from "@/components/selector/aHotelSelector";
//import aBoxSamples from "@/components/examples/aBoxSamples";
import { fabric } from "fabric";

export default {
  components: {
    aPage,
    // aReputationList,
    // aHotelSelector,
    // aBoxSamples,
    // aCard,
    // aKpi,
    aKpiColumns
    //aIncrement
  },
  data: () => ({
    aIncrement_tests: [
      {
        value: 100,
        vs_values: [90],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 100,
        vs_values: [90, 100],
        vs_periods: ["ly", "bud"],
        absolute: true,
        status: true
      },
      {
        value: 100,
        vs_values: [90, 100, -30],
        vs_periods: ["stly", "bud", "ly"],
        absolute: true,
        status: true
      },
      {
        value: 100,
        vs_values: [90],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 90,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 90,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 90,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 90,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: -90,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: -90,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 0,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 0,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 100,
        vs_values: [0],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 100,
        vs_values: [0],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 0,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 0,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: -100,
        vs_values: [0],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: -100,
        vs_values: [0],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 0,
        vs_values: [0],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 0,
        vs_values: [0],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 100,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 100,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: -100,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: -100,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: null,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: null,
        vs_values: [-100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: null,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: null,
        vs_values: [100],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: -100,
        vs_values: [null],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: -100,
        vs_values: [null],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: 100,
        vs_values: [null],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: 100,
        vs_values: [null],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      },
      {
        value: null,
        vs_values: [null],
        vs_periods: ["ly"],
        absolute: true,
        status: true
      },
      {
        value: null,
        vs_values: [null],
        vs_periods: ["ly"],
        absolute: false,
        status: true
      }
    ],
    aKpi_tests: [
      {
        value: 100,
        vs_value: 90,
        absolute: true,
        status: true
      }
    ],
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 100, //Mandatory number
    current_hotel_id: 1,
    show_hotel_vs_selector: false,
    canvas: null,
    available_pencil_color: [
      {
        code: "orange",
        color: "orange"
      },
      {
        code: "red",
        color: "red"
      },
      {
        code: "blue",
        color: "blue"
      },
      {
        code: "green",
        color: "green"
      },
      {
        code: "black",
        color: "black"
      }
    ],
    pencil_color: "red",
    available_pencil_width: [4, 8, 15, 20],
    pencil_width: "5px"
  }),
  methods: {
    setHotel(hotel) {
      this.current_hotel_id = hotel.id;
    },
    openHotelSelector() {
      this.show_hotel_vs_selector = true;
    },
    closeHotelSelector() {
      this.show_hotel_vs_selector = false;
    },
    changePencilWidth(width) {
      this.canvas.freeDrawingBrush.width = parseInt(width, 10) || 1;
    },
    changePencilColor(color) {
      var brush = this.canvas.freeDrawingBrush;
      brush.color = color;
      if (brush.getPatternSrc) {
        brush.source = brush.getPatternSrc.call(brush);
      }
    },
    clear() {
      this.canvas.clear();
    }
  },
  mounted() {
    const ref = this.$refs.canvas;
    this.canvas = new fabric.Canvas(ref, {
      isDrawingMode: true
    });
    this.changePencilColor("blue");
    this.changePencilWidth("15");
    var imgElement = document.getElementsByClassName("imgSrc")[0];
    var imgInstance = new fabric.Image(imgElement);
    this.canvas.add(imgInstance);
  },
  watch: {
    pencil_color() {
      this.changePencilColor(this.pencil_color);
    },
    pencil_width() {
      this.changePencilWidth(this.pencil_width);
    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters({})
  },
  created() {}
};
</script>
