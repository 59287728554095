<template>
  <div class="a-rms-day-rk-tooltip">
    <v-tooltip content-class="compset-tooltip-content" bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on"><slot></slot></span>
      </template>
      <span class="text-center justify-center align-center compset-tooltip">
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3 white text-subtitle-1"
        >
          COMPSET | {{ date }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :item-class="getDatatableRowClass"
          sort-by="min_price"
          hide-default-footer
          disable-pagination
          dense
          :hide-default-header="!pickup"
        >
          <template v-slot:[`item.competitor_name`]="{ item }">
            {{ item.competitor_name }}
          </template>
          <template v-slot:[`item.competitor_ranking`]="{ item }">
            {{ item.competitor_ranking }}
          </template>
          <template v-slot:[`item.regime`]="{ item }">
            {{ item.regime }}
          </template>
          <template v-slot:[`item.min_price`]="{ item }">
            {{ item.min_price }}
            <template v-if="item.currency === 'EUR'">
              <span class="currency-symbol">€</span>
            </template>
          </template>
          <template v-slot:[`item.is_genius_offer`]="{ item }">
            <template v-if="item.is_genius_offer">
              <v-btn color="blue" fab x-small depressed>
                <div class="white--text">g</div>
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.is_mobile_offer`]="{ item }">
            <template v-if="item.is_mobile_offer">
              <v-btn color="green" fab x-small depressed>
                <div class="white--text">m</div>
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.min_price_1`]="{ item }">
            <span
              :class="
                item.min_price_1 < item.min_price
                  ? 'green--text'
                  : item.min_price_1 > item.min_price
                  ? 'red--text'
                  : 'black--text'
              "
            >
              {{ item.min_price - item.min_price_1 }}
              <template v-if="item.currency === 'EUR'">
                <span class="currency-symbol">€</span>
              </template>
            </span>
          </template>
          <template v-slot:[`item.min_price_7`]="{ item }">
            <span
              :class="
                item.min_price_7 < item.min_price
                  ? 'green--text'
                  : item.min_price_7 > item.min_price
                  ? 'red--text'
                  : 'black--text'
              "
            >
              {{ item.min_price - item.min_price_7 }}
              <template v-if="item.currency === 'EUR'">
                <span class="currency-symbol">€</span>
              </template>
            </span>
          </template>
          <template v-slot:[`item.min_price_14`]="{ item }">
            <span
              :class="
                item.min_price_14 < item.min_price
                  ? 'green--text'
                  : item.min_price_14 > item.min_price
                  ? 'red--text'
                  : 'black--text'
              "
            >
              {{ item.min_price - item.min_price_14 }}
              <template v-if="item.currency === 'EUR'">
                <span class="currency-symbol">€</span>
              </template>
            </span>
          </template>
        </v-data-table>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "a-rms-day-rk-tooltip",
  props: {
    date: {
      type: String,
      mandatory: false,
      default: ""
    },
    pickup: {
      type: Boolean,
      mandatory: false,
      default: false
    }
  },
  computed: {
    headers() {
      const headers = [
        {
          align: "start",
          sortable: true,
          value: "competitor_name"
        },
        {
          align: "center",
          sortable: true,
          value: "competitor_rating"
        },
        {
          align: "center",
          sortable: true,
          value: "regime"
        },
        {
          align: "center",
          sortable: true,
          value: "min_price"
        },
        {
          align: "center",
          sortable: true,
          value: "is_genius_offer"
        },
        {
          align: "center",
          sortable: true,
          value: "is_mobile_offer"
        }
      ];
      if (this.pickup) {
        headers.push(
          { align: "center", sortable: true, value: "min_price_1", text: "1D" },
          { align: "center", sortable: true, value: "min_price_7", text: "7D" },
          {
            align: "center",
            sortable: true,
            value: "min_price_14",
            text: "14D"
          }
        );
      }
      return headers;
    },
    items() {
      return this.all_kpis_daily_compset[this.date];
    },
    ...mapState({
      all_kpis_daily_compset: state => state.rms.all_kpis_daily_compset
    })
  },
  methods: {
    getDatatableRowClass(item) {
      let classes = "a-datatable-row";
      if (item.self) {
        classes += " blue lighten-5";
      }
      return classes;
    }
  }
};
</script>

<style lang="scss">
.compset-tooltip-content {
  background-color: white;
  border: 1px solid #000;
  border-radius: 0;
  padding: 0 !important;
  opacity: 1 !important;

  .v-card__title {
    padding: 5px 14px !important;
  }

  .v-data-table {
    border-radius: 0 !important;

    .v-data-table__wrapper {
      padding: 0 12px !important;

      .a-datatable-row {
        td {
          padding: 5px !important;

          &:first-child {
            color: inherit !important;
          }

          .currency-symbol {
            margin-left: -5px;
          }

          .v-btn--fab.v-size--x-small {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
