<template>
  <v-row>
    <v-col
      class="text-h3 text--darken-1 font-weight-regular text-right"
      :class="color"
    >
      <slot>{{ formatted_value }}</slot>
    </v-col>
    <v-col class="text-left">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-icon :class="color">{{ arrow }}</v-icon>
            <span v-if="dif !== 0"><br />{{ formatted_dif }} %</span>
          </div>
        </template>
        {{ formatted_vs }}
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
import { format_kpi_value } from "@/utils";
import { mapGetters } from "vuex";

export default {
  name: "a-metric",
  props: {
    value: {
      type: Number,
      required: true
    },
    vs: {
      type: Number,
      required: false,
      default: null
    },
    warning_less_than: {
      type: Number,
      required: false,
      default: null
    },
    warning_greater_than: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    formatted_value() {
      return format_kpi_value(this.value, "number", this.l);
    },
    formatted_vs() {
      return this.vs ? format_kpi_value(this.vs, "number", this.l) : "";
    },
    color() {
      if (this.warning) {
        return "warning--text";
      } else {
        return "grey--text";
      }
    },
    warning() {
      if (this.warning_less_than && this.warning_greater_than) {
        return this.warning_less_than < this.value < this.warning_greater_than;
      } else if (this.warning_less_than) {
        return this.value < this.warning_less_than;
      } else if (this.warning_greater_than) {
        return this.value > this.warning_greater_than;
      } else {
        return false;
      }
    },
    arrow() {
      if (this.value > this.vs) {
        return this.value >= this.vs * 2 ? "mdi-arrow-up-bold" : "mdi-arrow-up";
      } else if (this.value < this.vs) {
        return this.value <= this.vs / 2
          ? "mdi-arrow-down-bold"
          : "mdi-arrow-down";
      } else {
        return "mdi-equal";
      }
    },
    dif() {
      // increment between value and vs
      if (this.vs) {
        return this.value > this.vs
          ? ((this.value - this.vs) / this.vs) * 100
          : ((this.vs - this.value) / this.vs) * 100;
      } else {
        return 0;
      }
    },
    formatted_dif() {
      return format_kpi_value(this.dif, "number", this.l);
    },
    ...mapGetters({
      l: "current_hotel_language"
    })
  }
};
</script>
