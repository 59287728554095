<template>
  <div>
    <v-card-subtitle class="text-right grey--text text-uppercase pb-0">
      {{ $t(kpi) }}
    </v-card-subtitle>
    <v-list flat :dense="$vuetify.breakpoint.lgAndDown">
      <v-list-item
        v-if="getMainItem"
        :key="getMainItem.name"
        class="rounded no-click"
      >
        <v-list-item-avatar tile size="35">
          <v-img :alt="$t(getMainItem.name)" :src="getMainItem.logo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="font-weight-bold"
            v-text="$t(getMainItem.name)"
          >
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-list-item-title>
            <a-kpi
              :kpi="kpi"
              :value="getMainItem.value"
              increment
              :vs_value="getMainItem.vs_value"
              :increment_value="getMainItem.increment"
              class="font-weight-bold"
            ></a-kpi>
          </v-list-item-title>
        </v-list-item-icon>
      </v-list-item>

      <v-divider
        v-if="getMainItem"
        :key="'divider_reputation_list'"
        class="my-2 "
      ></v-divider>

      <v-list-item
        v-for="item in getOtaItems"
        :key="item.name"
        @click="seeOta(item.name)"
        class="rounded"
      >
        <v-list-item-avatar size="35">
          <v-img :alt="$t(item.name)" :src="item.logo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            v-text="$t(item.name)"
            v-if="
              $vuetify.breakpoint.xsOnly ||
                $vuetify.breakpoint.mdOnly ||
                $vuetify.breakpoint.xlOnly
            "
          ></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <!-- <v-progress-circular
            v-if="kpi == 'rating' && $vuetify.breakpoint.smAndUp"
            :rotate="-90"
            width="5"
            class="mr-2"
            color="secondary"
            :value="item.value"
          ></v-progress-circular> -->
          <v-list-item-title>
            <a-kpi
              :kpi="kpi"
              :value="item.value"
              increment
              :vs_value="item.vs_value"
              :increment_value="item.increment"
            ></a-kpi>
          </v-list-item-title>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";

export default {
  name: "a-reputation-list",
  components: {
    aKpi
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    kpi: {
      type: String,
      default: null
    }
  },
  data: () => ({}),
  methods: {
    seeOta(ota) {
      this.$router.push({
        name: "ota",
        params: {
          hotel_slug: this.current_hotel.settings.slug,
          ota: ota
        }
      });
    },
    isMain(item) {
      return item.main;
    }
  },

  computed: {
    getMainItem() {
      return this.items.find(o => o.main);
    },
    getOtaItems() {
      return this.items.filter(o => !o.main);
    },
    ...mapState({}),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped>
.v-list-item:hover:not(.no-click) {
  background: #e4e8f5 !important;
}
</style>
