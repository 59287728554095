<template>
  <v-row class="channel-row mx-0">
    <v-col class="channel-wrapper pa-0">
      <div class="label-wrapper d-flex align-center simple_view pl-6 pr-1">
        <div
          class="flex-grow-1 text-overflow"
          :class="{ 'grey--text lighten-2': rowData.parent_rate_id }"
        >
          <v-tooltip right v-if="!rowData.enabled">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-alert-outline
              </v-icon>
            </template>
            <span class="d-block pre-line">
              {{ rowData.disabled_reason || $t("not_enabled") }}
            </span>
          </v-tooltip>

          {{ rowData.channel_name }}
          <span class="text-muted">
            - {{ rowData.mapped_channel_room_rate_description }}</span
          >
        </div>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-window v-model="current_slide">
        <v-window-item v-for="(item, itemKey) of windowItems" :key="itemKey">
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(day, index) of getDays"
              :key="index"
              class="pa-0 channel-manager-day-column"
              :class="{
                'bg-weekend': isWeekend(day),
                'grey--text lighten-2': rowData.days[day].closed
              }"
            >
              <div
                class="channel-wrapper"
                @mouseenter="
                  reportLocation({
                    rate_id: rowData.rate_id,
                    date: day,
                    row_index: rowIndex
                  })
                "
              >
                <div class="cell">
                  <a-chm-editable-price
                    v-model="rowData.days[day]"
                    :info="rowData"
                    v-if="
                      rowData.enabled &&
                        !rowData.days[day].closed &&
                        !rowData.parent_rate_id
                    "
                  >
                    <template v-slot:activator>
                      <a-chm-price-cell
                        :rate="getRate(rowData, day)"
                        :row_key="rowIndex"
                        class="pointer"
                        v-if="rowData.days[day]"
                      ></a-chm-price-cell>
                    </template>
                  </a-chm-editable-price>
                  <a-chm-price-cell
                    :rate="getRate(rowData, day)"
                    :row_key="rowIndex"
                    v-else
                  ></a-chm-price-cell>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import aChmEditablePrice from "@/components/channel-manager/aChmEditablePrice.vue";
import AChmPriceCell from "@/components/channel-manager/aChmPriceCell.vue";
export default {
  components: { aChmEditablePrice, AChmPriceCell },
  props: {
    rowData: Object,
    rowIndex: Number,
    showMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    current_slide: 0
  }),
  watch: {
    slide_status(newValue, oldValue) {
      if (newValue === 2) {
        this.current_slide = this.page;
      }
    }
  },
  computed: {
    slide_status() {
      return this.$store.state.channelmanager.slide_status;
    },
    getDays() {
      return this.rowData && Object.keys(this.rowData.days);
    },
    windowItems() {
      return this.$store.state.channelmanager.windowItems;
    },
    page() {
      return this.$store.state.channelmanager.page;
    }
  },
  methods: {
    isWeekend(day) {
      return Vue.moment(day).isoWeekday() >= 5;
    },
    reportLocation(event) {
      this.$emit("mouseRateHovering", event);
      this.$store.dispatch("setHoveredRate", event);
    },
    getRate(rowData, day) {
      const data = { ...rowData };
      const channelRateDayInfo = { ...data.days[day] };
      delete data.days;
      return { ...channelRateDayInfo, ...data, date: day };
    }
  }
};
</script>
<style lang="scss" scoped>
$labelFontWeight: 700;
$labelFontSize: 0.8rem;
$labelColor: #555;
$bgColorHotel: #ddd;
.channel-wrapper,
.channel-availability-wrapper {
  max-width: $widthLeftSide;
  .label-wrapper {
    //background-color: #fff;
    color: #444;
    &:hover,
    &.highlighted {
      border-right: 3px solid $highlightColRowColor;
    }
  }
  .master-channel {
    cursor: pointer;
  }
  cursor: default !important;
}
.channel-manager-day-column {
  width: 100%;
  &:hover .cell:has(.day-info) {
    border-bottom: 3px solid $highlightColRowColor;
  }

  .cell {
    height: $minHeightColRow;
    text-align: center;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    border-bottom: $borderType $borderColor;
    border-top: $borderType $borderColor;
    // padding: 2px 1px;
    span {
      width: 100%;
      display: block;
      line-height: 20px;
      font-size: 12px;
    }
    &:has(.room-info span.room-availability) {
      background-color: $bgColorRoom;
    }
  }
  .day-heading {
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
    font-weight: 600;
  }
  .channel-wrapper {
    &:hover {
      background-color: #f5fdff;
    }
  }
}
.label-wrapper {
  border-top: $borderType $borderColor;
  border-bottom: $borderType $borderColor;
  height: $minHeightColRow;
  border-right: 3px solid transparent;
  padding-left: 10px;
  width: $widthLeftSide;
  font-size: $labelFontSize;
  //   font-weight: $labelFontWeight;
  color: $labelColor;
  justify-content: space-between;
  .text-muted {
    font-weight: 400;
  }
  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.bg-weekend {
  background-color: $weekendBgColor;
}
.channel-row {
  &:hover {
    .label-wrapper {
      border-right: 3px solid $highlightColRowColor;
    }
  }
}
.pre-line {
  white-space: pre-line;
}
</style>
