var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"row-data"},[_c('td',{staticClass:"day-data sticky-columns-shadow",class:[_vm.color_class],staticStyle:{"width":"200px"},on:{"click":function($event){return _vm.$emit('clickOnDateCell', _vm.date)}}},[_c('div',{staticClass:"cell justify-start"},[_c('div',{staticClass:"d-flex justify-start pl-5"},[_c('span',[_vm._v(_vm._s(_vm.label))])])])]),_vm._l((_vm.nonStickyColumnHeaders),function(column){return _c('td',{key:(_vm.date + "-" + (column.group) + "-" + (column.column)),class:[
      {
        'sticky-columns-shadow': column.column === _vm.lastStickyColumn.column
      },
      _vm.getClass(
        _vm.configYearSalesTable[column.group].columns[column.column].kpi,
        _vm.getValue(column)
      ),
      { 'br-bolder': column.column === _vm.lastColumnOfEachGroup[column.group] },
      { summary: _vm.isSummary }
    ],on:{"mouseover":function($event){return _vm.$emit('hoveredKpi', column)},"mouseleave":function($event){return _vm.$emit('hoveredKpi', '')}}},[_c('div',{staticStyle:{"position":"relative","height":"100%"}},[_c('div',{staticClass:"cell"},[(
            column.group === 'otb' ||
              !_vm.getValue(column) ||
              _vm.kpiMode === 'hide_vs'
          )?_c('a-kpi',{staticClass:"pt-4",attrs:{"kpi":_vm.configYearSalesTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column),"x_small":"","vertical":""}}):(column.group === 'forecast')?[_c('a-kpi',{attrs:{"kpi":_vm.configYearSalesTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column),"vs_values":[_vm.getValue(column, 'bud')],"vs_periods":['bud'],"x_small":"","vertical":""}})]:[_c('a-kpi',{attrs:{"kpi":_vm.configYearSalesTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column),"x_small":"","vertical":""}}),_c('div',{staticClass:"increment-wrapper"},[_c('a-increment',{staticClass:"pt-2",attrs:{"kpi":_vm.configYearSalesTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column, 'otb'),"vs_value":_vm.getValue(column),"period":column.group,"hide_tooltip":true,"absolute":_vm.kpiMode === 'absolute'}})],1)]],2),(
          _vm.showProgressPercent(
            _vm.configYearSalesTable[column.group].columns[column.column].kpi
          )
        )?_c('div',{staticClass:"percent-progress",style:(_vm.getPercentStyle(_vm.getValue(column)))}):_vm._e()])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }