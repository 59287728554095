<template>
  <v-card color="grey lighten-4 mb-2">
    <v-card-text class="white pa-0">
      <div class="grid-container" id="rms-strategy-table">
        <table v-if="group_of_columns">
          <thead>
            <tr class="group-row">
              <th
                v-for="group in group_names"
                :key="group"
                :colspan="group_of_columns[group].length"
                :class="[
                  {
                    'sticky-columns-shadow': group === last_sticky_group_column
                  },
                  {
                    'br-bolder': group !== last_sticky_group_column
                  }
                ]"
              >
                <template v-if="group">
                  <div class="column-group">
                    <v-tooltip
                      bottom
                      v-if="group_of_columns[group].length === 1"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="white" small>
                          {{ configStrategyTable[group].icon }}
                        </v-icon>
                      </template>
                      <span>{{ $t(group) }}</span>
                    </v-tooltip>
                    <template v-else>
                      <v-icon color="white" small>
                        {{ configStrategyTable[group].icon }}
                      </v-icon>
                      <span class="pl-2">
                        <template
                          v-if="
                            group.includes('pickup_') && group !== 'pickup_0'
                          "
                        >
                          Pickup
                          {{ current_hotel.settings.pickup_spots[group] }}
                        </template>
                        <template v-else>
                          {{ $t(group) }}
                        </template>
                      </span>
                    </template>
                  </div>
                </template>
              </th>
            </tr>
            <tr class="header-row">
              <th
                v-for="column_header in column_headers"
                :key="`${column_header.group}-${column_header.column}`"
                :class="[
                  {
                    'sticky-columns-shadow':
                      column_header.column === last_sticky_group_column
                  },
                  {
                    'br-bolder':
                      last_column_of_each_group[column_header.group] ===
                        column_header.column &&
                      column_header.group !== last_sticky_group_column
                  }
                ]"
                :style="{
                  width: column_header.column == 'date' ? '200px' : '100px'
                }"
              >
                <div
                  class="cell"
                  :class="{
                    'kpi-row': !sticky_columns.includes(column_header.column),
                    'kpi-hovered':
                      kpi_hover.column === column_header.column &&
                      kpi_hover.group === column_header.group
                  }"
                  :style="{
                    width: column_header.column == 'date' ? '200px' : '100px'
                  }"
                >
                  <span
                    class="d-block day-name grey--text text--darken-3 text-uppercase"
                  >
                    <template v-if="column_header.group === 'segment'">
                      {{
                        segments.find(
                          segment => segment.code === column_header.column
                        ).name
                      }}
                    </template>
                    <template v-else-if="column_header.group === 'roomtype'">
                      <div class="text-truncate" style="width: 80px">
                        {{
                          roomTypes.find(
                            roomType => roomType.code === column_header.column
                          ).name
                        }}
                      </div>
                      <v-chip x-small>{{
                        roomTypes.find(
                          roomType => roomType.code === column_header.column
                        ).available_rooms
                      }}</v-chip>
                    </template>
                    <template v-else>
                      {{ $t(column_header.column) }}
                    </template>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <aRmsStrategyDayTableRow
              v-for="day in days"
              :key="`${day}`"
              class="row-data"
              :date="day"
              :row="columns[day]"
              :nonStickyColumnHeaders="non_sticky_column_headers"
              :lastStickyColumn="last_sticky_column"
              :configStrategyTable="configStrategyTable"
              :lastColumnOfEachGroup="last_column_of_each_group"
              @clickOnDateCell="getDayDetails(day)"
              @hoveredKpi="kpi_hover = $event"
              :kpiMode="kpiMode"
            />
            <aRmsStrategyDayTableRow
              class="row-data summary"
              :date="null"
              :row="totalPeriod"
              :nonStickyColumnHeaders="non_sticky_column_headers"
              :lastStickyColumn="last_sticky_column"
              :configStrategyTable="configStrategyTable"
              :lastColumnOfEachGroup="last_column_of_each_group"
              isSummary
              @hoveredKpi="kpi_hover = $event"
              :kpiMode="kpiMode"
            />
          </tbody>
        </table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import aRmsStrategyDayTableRow from "@/components/rms/axel/aRmsStrategyDayTableRow";
import { getFriendlyMonthStrategy, showError } from "@/utils.js";
import Vue from "vue";
import { mapGetters } from "vuex";

let default_sticky_group_columns = [""];

export default {
  name: "a-rms-strategy-day-table",
  components: { aRmsStrategyDayTableRow },
  props: {
    configStrategyTable: Object,
    columns: Object,
    totalPeriod: Object,
    days: Array,
    roomTypes: Array,
    segments: Array,
    kpiMode: String
  },
  emits: ["getDayDetails"],
  data: () => ({
    table_class: "rms-strategy-day-table",
    filtered_rows: [],
    filtered_columns: [],
    kpi_hover: ""
  }),
  methods: {
    getDayDetails(v) {
      this.$emit("getDayDetails", v);
    },
    getFriendlyMonthStrategy(date) {
      return getFriendlyMonthStrategy(date, true);
    },
    getHotelComparativeDates() {
      this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: Vue.moment(this.date)
            .startOf("year")
            .format("YYYY-MM-DD"),
          to_date: Vue.moment(this.date)
            .endOf("year")
            .format("YYYY-MM-DD")
        })
        .catch(() => {
          showError(this, "something_went_wrong");
        });
    }
  },
  mounted() {
    this.getHotelComparativeDates();
  },
  computed: {
    group_names() {
      return this.configStrategyTable && Object.keys(this.configStrategyTable);
    },
    group_of_columns() {
      return (
        (this.configStrategyTable &&
          Object.keys(this.configStrategyTable).reduce((prev, curr) => {
            prev[curr] = Object.keys(this.configStrategyTable[curr].columns);
            return prev;
          }, {})) ||
        undefined
      );
    },
    column_headers() {
      const groups_and_columns = [];
      if (this.group_of_columns) {
        for (const group in this.group_of_columns) {
          this.group_of_columns[group].forEach(column => {
            groups_and_columns.push({ group, column });
          });
        }
      }
      return groups_and_columns;
    },
    last_column_of_each_group() {
      const column_headers = [...this.column_headers].reverse();
      return column_headers.reduce((prev, curr) => {
        if (!prev[curr.group]) {
          prev[curr.group] = curr.column;
        }
        return prev;
      }, {});
    },
    non_sticky_column_headers() {
      return (
        this.column_headers &&
        this.column_headers.filter(
          column =>
            !this.sticky_columns.find(
              sticky_column =>
                column.group === sticky_column.group &&
                column.column === sticky_column.column
            )
        )
      );
    },
    sticky_group_columns() {
      return default_sticky_group_columns;
    },
    last_sticky_group_column() {
      return this.sticky_group_columns[this.sticky_group_columns.length - 1];
    },
    sticky_columns() {
      return (
        this.column_headers &&
        this.column_headers.filter(column =>
          this.group_of_columns[this.last_sticky_group_column].includes(
            column.column
          )
        )
      );
    },
    last_sticky_column() {
      return this.sticky_columns[this.sticky_columns.length - 1];
    },
    non_sticky_group_columns() {
      return this.group_columns.filter(
        group => !this.sticky_group_columns.includes(group)
      );
    },
    // returns an object of year-month: [days]
    unique_years_months() {
      return this.days.reduce((prev, curr) => {
        const year_month = curr
          .split("-")
          .slice(0, 2)
          .join("-");
        if (!prev[year_month]) {
          prev[year_month] = [];
        }
        prev[year_month].push(curr);
        return prev;
      }, {});
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style lang="scss" scoped>
.grid-container {
  overflow: auto;
  max-width: 100%;
  max-height: calc(100vh - 160px);

  .sticky-columns-shadow {
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.15),
      2px 0px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.08);
  }

  .cell {
    height: $minHeightColRow;
    // border-top: $borderType $borderColorV;
    border-left: $borderType $borderColorV;
    border-right: 3px solid transparent;
    border-bottom: $borderType $borderColorV;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    min-width: 100%;
    text-wrap: wrap;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    &.kpi-row {
      border-right: $borderType $borderColorV;
      border-bottom: 3px solid transparent;
    }
    &.kpi-hovered {
      border-bottom: 3px solid #2d6ece;
    }
  }

  table {
    border-spacing: 0;
  }

  th {
    // border-left: none;
    border: none;
    border-right: $borderType $borderColorV;
    border-bottom: $borderType $borderColorV;
    // padding: 5px;
    width: 100px;
    min-width: 100px;
    position: sticky;
    top: 0;
    background: white;
    color: #a0a0a0;
    font-weight: normal;
    height: 44px;
    z-index: 1;
    &:nth-child(1) {
      position: sticky;
      left: 0;
      background: white;
      color: #e0e0e0;
      font-weight: normal;
      z-index: 2;
      // border-bottom: $borderType $borderColorV;
      // border-right: $borderType $borderColorV;
    }
  }
  .header-row {
    th {
      border-right: none;
      border-bottom: none;
      top: 44px;
      box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 1px 0px 0px rgba(0, 0, 0, 0.15), 2px 0px 0px 0px rgba(0, 0, 0, 0.11),
        0px 2px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.07),
        0px 4px 0px 0px rgba(0, 0, 0, 0.08);
      // &:nth-child(2) {
      //   position: sticky;
      //   left: 100px;
      //   box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
      //     0px 1px 0px 0px rgba(0, 0, 0, 0.15),
      //     2px 0px 0px 0px rgba(0, 0, 0, 0.11),
      //     0px 2px 0px 0px rgba(0, 0, 0, 0.12),
      //     4px 0px 0px 0px rgba(0, 0, 0, 0.07),
      //     0px 4px 0px 0px rgba(0, 0, 0, 0.08);
      // }
      &:nth-child(1) {
        z-index: 2;
        border-bottom: $borderType $borderColorV;
      }
    }
  }

  td {
    border: none;
    // border-left: none;
    // border-right: $borderType $borderColorV;
    // border-bottom: $borderType $borderColorV;
    // padding: 0 5px;
    width: 100px;
    min-width: 100px;
    background-color: white;
    height: 44px;

    &:nth-child(1) {
      position: sticky;
      left: 0;
      background: white;
      color: #e0e0e0;
      font-weight: normal;
    }
    // &:nth-child(2) {
    //   position: sticky;
    //   left: 100px;
    //   width: 100px;
    //   min-width: 100px;
    // }
    .cell {
      &:hover {
        background-color: #f5fdff;
      }
    }
  }

  tr.row-data {
    td.day-data {
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 200px;
      text-transform: uppercase;
      cursor: pointer;

      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 3px;
        height: calc(100% - 8px);
        width: 4px;
        background-color: grey;
        z-index: 10;
        border-radius: 5px;
        margin: 4px 0;
      }
      &.positive::after {
        background-color: rgb(23, 171, 112);
      }
      &.negative::after {
        background-color: rgb(172, 0, 0);
      }
      &.neutral::after {
        background-color: orange;
      }
    }
    &:hover {
      td.sticky-columns-shadow {
        .cell {
          border-right: 3px solid #2d6ece;
        }
      }
    }
    &:last-child {
      position: sticky;
      bottom: 0;
      z-index: 2;
    }
  }

  .column-group {
    padding: 3px 0;
    background-color: #1576c7;
    color: white;
    border-radius: 25px;
    margin: 0 1px;
  }
}

.br-bolder {
  border-right: 2px solid #ccc !important;
}
</style>
