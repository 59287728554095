<template>
  <div class="d-flex" :class="clase">
    <slot name="prepend"> </slot>
    <span class="a-kpi" v-html="format(value)"></span>
    <a-increment
      v-if="increment"
      :value="vs_value"
      :kpi="kpi"
      :simple="simple"
      :absolute="absolute"
      :increment="increment_value"
      :increment_type="increment_type"
    ></a-increment>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aIncrement from "@/components/aIncrement";
import { format_kpi_value } from "@/utils.js";

export default {
  name: "a-kpi",
  props: {
    kpi: String,
    value: Number,
    increment_value: Number,
    increment_type: String,
    increment: Boolean,
    simple: Boolean,
    absolute: Boolean,
    clas: String,
    vs_value: Number,
    position: String
  },
  methods: {
    format(v) {
      return format_kpi_value(v, this.type, this.l);
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    aIncrement
  },
  data: () => ({}),
  computed: {
    type() {
      return this.get_kpi(this.kpi).data_format;
    },
    clase() {
      return (
        (this.clas ? this.clas : "") +
        " " +
        this.position_class +
        " align-center"
      );
    },
    position_class() {
      let suffix = "center";
      if (this.position == "left") {
        suffix = "start";
      } else if (this.position == "right") {
        suffix = "end";
      }
      return "justify-" + suffix;
    },
    ...mapState({
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language",
      get_kpi: "get_kpi"
    })
  }
};
</script>

<style>
.a-kpi {
  display: inline-block;
}
.symbol {
  font-size: 0.85em;
}
</style>
