<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
  </a-page>
</template>

<script>
export default {
  data: () => ({
    app_id: "accounting-app", //Mandatory path
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 300 //Mandatory number,
  })
};
</script>
