<template>
  <div id="rate-min-prices" class="d-flex rate-data-row ">
    <div class="item rate-labels semibold">
      <div class="simple_view text-uppercase" v-if="!groupDisplay">
        {{ $t("avg_price_competitive_set") }}
      </div>
      <div class="simple_view text-uppercase" v-else>
        {{ $t("avg_price") }}
      </div>
    </div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="(day, index) in days"
          :key="'rate-avg-price-min-' + day"
          class="day"
          :class="{ weekend: is_weekend(day) }"
          @mouseover="
            date_hover = day;
            day_hover = index;
          "
          @mouseleave="
            date_hover = '';
            day_hover = '';
          "
        >
          <div
            v-if="mustRender(day)"
            class="day-date semibold"
            :class="'visible-day visible-day-' + index"
          >
            <div
              class="simple_view justify-center secondary--text subtitle-2"
              style="line-height: 37px;"
              v-if="daily_rates"
            >
              <a-kpi
                :kpi="'min_price'"
                :value="getAvgValueFromDate(day)"
                :increment="false"
              ></a-kpi>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";

export default {
  name: "rate-avg-min-price",
  components: {
    aKpi
  },
  props: [
    "days",
    "days_per_screen",
    "competitor",
    "daily_rates",
    "increments",
    "daily_rates_vs",
    "filters",
    "days_to_render",
    "groupDisplay"
  ],
  data: vm => ({
    hover: false
  }),
  methods: {
    getVisibleValuesFromDate(date) {
      var visible_values = [];
      for (let c of this.visible_competitors) {
        if (
          this.daily_rates.report["competitor::" + c.id] &&
          this.daily_rates.report["competitor::" + c.id][date]
        ) {
          visible_values.push(
            this.daily_rates.report["competitor::" + c.id][date][0].min_price
          );
        }
      }
      return visible_values;
    },
    getAvgValueFromDate(date) {
      const arrAvg =
        this.getVisibleValuesFromDate(date).reduce((a, b) => a + b, 0) /
        this.getVisibleValuesFromDate(date).length;
      return arrAvg;
    },
    showDate(date, competitor_id) {
      if (this.groupDisplay) {
        const c = this.getCompetitorById(competitor_id);

        this.$store.dispatch("setCurrentHotelById", c.hotel_id);
      } else {
        this.show_rate_date_to_compare = true;
        this.$store.dispatch("setComparedCompetitors", [competitor_id]);
        this.$store.dispatch("setRateDateToCompare", date);
      }
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    },
    isHover(date) {
      return this.date_hover == date;
    },
    rankingClass(date) {
      return this.increments[date][0].ranking > 0
        ? "increment"
        : this.increments[date][0].ranking < 0
        ? "decrement"
        : "";
    },
    mustRender(date) {
      // Must render if date inside days_per_screen
      return this.days_to_render.indexOf(date) >= 0;
    },
    avg_min_price(date) {
      var prices = [];
      for (const competitor of this.competitors) {
        if (
          this.daily_rates.report &&
          this.daily_rates.report["competitor::" + competitor.id] &&
          this.daily_rates.report["competitor::" + competitor.id][date] &&
          (!competitor.self || this.groupDisplay)
        ) {
          prices.push(
            this.daily_rates.report["competitor::" + competitor.id][date][0]
              .min_price
          );
        }
      }
      var total = 0;
      prices.forEach(function(a) {
        total += a;
      });
      return parseFloat(total / prices.length);
    }
  },
  computed: {
    visible_competitors() {
      return this.getOnlyCompetitors.filter(
        c => this.invisible_competitors.indexOf(c.id) < 0
      );
    },
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },
    ...mapState({
      competitors: state => state.rate.competitors,
      invisible_competitors: state => state.rate.invisible_competitors
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getCompetitorById: "getCompetitorById",
      getOnlyCompetitors: "getOnlyCompetitors"
    })
  }
};
</script>
<style>
.a-rate-tooltip {
  margin-top: 10px;
  background: white;
  z-index: 310 !important;
  color: black !important;
  opacity: 1;
  font-weight: 500;
  border: 2px solid #dedede;
  text-align: center;
}
.a-rate-position.increment {
  background: green;
}
.a-rate-position.decrement {
  background: rgb(155, 9, 9);
}
.a-rate-position {
  position: absolute;
  top: -8px;
  right: -5px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: #999999;
  color: white;
  font-weight: 500;
  font-size: 10px;
  z-index: 320;
  line-height: 25px;
}
.rate_data_main > div {
  position: relative;
}
</style>
