<template>
  <div id="kpi-table">
    <div>
      <i v-show="!editing && !hasYearError">
        <template v-if="!is_saving_year_budget && !getYearLastUpdate">
          {{ $t("budget_never_updated") }}
        </template>
        <template v-if="!is_saving_year_budget && getYearLastUpdate">
          {{ $t("last_update") }}: {{ getYearLastUpdate }}
          {{
            getYearCacheRefreshed
              ? ""
              : ". " + $t("updates_on_inicrements_will_be_shown_tomorrow")
          }}
        </template>
      </i>
      <i v-show="!editing && hasYearError"
        >{{ $t("error_while_importing") }}: {{ error_text }}</i
      >
    </div>
    <div class="kpi-table-row kpi-table-th">
      <div class="kpi-table-label">
        <span>{{ $t("kpi") }}</span>
      </div>
      <div class="kpi-table-months">
        <div class="kpi-table-months-wrapper">
          <div
            class="kpi-table-month"
            v-for="(month, key) in months"
            :key="key"
          >
            <span>{{ month.text }}</span>
          </div>
        </div>
      </div>
      <div class="kpi-table-anual" v-if="!editing">
        <span> {{ $t("annual") }}</span>
      </div>
    </div>
    <div
      v-for="(kpi, kpi_name) in kpis"
      :key="kpi_name"
      class="kpi-table-row "
      :class="[
        { is_dimensioned: kpi.is_dimensioned && !editing },
        kpi.section,
        { 'kpi-table-row-editing': !kpi.editable && editing }
      ]"
    >
      <template v-if="kpi.editable || (!kpi.editable && !editing)">
        <div
          v-show="kpi.editable && editing"
          class="kpi-table-cover"
          v-on:click="editKpi(kpi_name)"
        >
          <span
            ><v-icon class="white--text">mdi-pencil</v-icon> {{ $t("modify") }}
            {{ kpi.name }}</span
          >
        </div>
        <div class="kpi-table-label">
          <span>{{ kpi.name }}</span>
          <!--<span v-show="kpi.editable && editing" class="m-badge m-badge--default"><i class="flaticon-edit-1"></i></span>-->
          <span
            v-show="!kpi.editable && !editing"
            class="m-badge m-badge--default"
            v-bind:title="$t('it_is_computed_automatically')"
            ><v-icon>mdi-cog</v-icon>
          </span>
        </div>
        <div
          class="kpi-table-months"
          :class="{ not_calculated: is_saving_year_budget && !kpi.editable }"
        >
          <div class="kpi-table-months-wrapper">
            <div
              class="kpi-table-month"
              v-for="(month, key) of months"
              :key="key"
            >
              <span
                v-html="
                  parseValue(
                    new_kpis[kpi_name][`periodo${month.value + 1}`],
                    kpi.type
                  )
                "
              >
              </span>
              <span
                class="old_value"
                v-if="
                  new_kpis[kpi_name][`periodo${month.value + 1}`] !=
                    kpi[`periodo${month.value + 1}`]
                "
                v-html="parseValue(kpi[`periodo${month.value + 1}`], kpi.type)"
              >
              </span>
            </div>
          </div>
        </div>
        <div
          class="kpi-table-anual"
          v-if="!editing"
          :class="{ not_calculated: is_saving_year_budget }"
        >
          <span v-html="parseValue(kpi.anual, kpi.type)"></span>
        </div>
      </template>
    </div>
    <div class="kpi-table-legend d-flex mt-5">
      <v-badge inline color="success" :content="$t('revenue')" class="mr-2" />
      <v-badge inline color="error" :content="$t('expense')" class="mr-2" />
      <v-badge
        inline
        color="info"
        :content="$t('profitability')"
        class="mr-2"
      />
      <v-badge
        inline
        color="warning"
        :content="$t('reputation')"
        class="mr-2"
      />
    </div>
    <budget-modify-dialog />
  </div>
</template>
<script>
import * as Utils from "@/utils.js";
import { mapGetters, mapState } from "vuex";
import BudgetModifyDialog from "./BudgetModifyDialog.vue";
export default {
  components: { BudgetModifyDialog },
  data: () => ({
    months: Utils.generateArrayMonths()
  }),
  computed: {
    ...mapState({
      budget_store: state => state.budget,
      kpis: state => state.budget.kpis,
      new_kpis: state => state.budget.new_kpis
    }),
    ...mapGetters({
      hasYearError: "hasYearError",
      error_text: "getYearErrorText",
      getYearLastUpdate: "getYearLastUpdate",
      getYearCacheRefreshed: "getYearCacheRefreshed",
      is_saving_year_budget: "isSavingYearBudget",
      l: "current_hotel_language"
    }),
    editing: {
      get() {
        return this.budget_store.editing;
      },
      set(value) {
        this.$store.dispatch("setEditing", value);
      }
    },
    kpi_to_edit: {
      get() {
        return this.budget_store.kpi_to_edit;
      },
      set(value) {
        this.$store.dispatch("setKpiToEdit", value);
      }
    },
    editing_kpis: {
      get() {
        return this.budget_store.editing_kpis;
      },
      set(value) {
        this.$store.dispatch("setEditingKpis", value);
      }
    }
  },
  methods: {
    editKpi(kpi_name) {
      this.kpi_to_edit = kpi_name;
      this.editing_kpis = JSON.parse(JSON.stringify(this.new_kpis));
    },
    parseValue(value, type) {
      return Utils.format_kpi_value(value, type, this.l);
    }
  }
};
</script>
<style lang="scss" scoped>
.budget_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -11rem;
  margin-bottom: 5rem;
  position: relative;
}
.budget_button_container .budget_button {
  flex: 1;
  max-width: 200px;
  margin: 0 10px;
}
.budget_button_container .budget_button a i {
  display: block;
  margin-bottom: 10px;
  font-size: 3rem !important;
  line-height: initial !important;
  color: #ffffff;
}
.budget_button_container .budget_button a,
.budget_button_container .budget_button a:focus {
  color: #ffffff;
  border: 1px solid #9ea7c0;
  padding: 20px 30px;
  width: 100%;
  text-transform: initial;
}
.budget_button_container .budget_button a:hover span,
.budget_button_container .budget_button a:hover i {
  color: blueviolet;
}
.budget_button_container .budget_button a span {
  font-weight: 600;
}
.budget_button_container .btn.btn-secondary,
.budget_button_container .btn.btn-secondary:focus {
  background: transparent;
}

.kpi-table-label {
  width: 200px;
  text-align: left;
  padding-left: 10px;
}
.kpi-table-row.is_dimensioned .kpi-table-label {
  width: 180px;
  text-align: left;
  margin-left: 20px;
}
.kpi-table-row.is_dimensioned .kpi-table-label span {
  color: #999999 !important;
}
.kpi-table-row.kpi-table-row-editing {
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
}

.kpi-table-label span.m-badge {
  padding: 0px 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-top: 15px;
}
.kpi-table-label span.m-badge i {
  font-size: 0.8rem !important;
}
.kpi-table-label span {
  color: #555555 !important;
  font-weight: 500 !important;
}
.kpi-table-months {
  flex: 1;
  transition: all 1s;
}
#modal_edit_kpi .kpi-table-month {
  margin: 0 3px;
}
#modal_edit_kpi label {
  margin-bottom: 0 !important;
}
.kpi-table-month input {
  border: 0;
  border: 1px solid #ffffff;
  font-weight: 600;
}
.kpi-table-month input:hover {
  background: #f5f5f5;
}
.kpi-table-month input:focus {
  background: #ffffff;
  box-shadow: 0 0 10px -3px #4f9de8;
  border-width: 1px;
  border-style: solid;
}
.kpi-table-month {
  flex: 1;
}
.kpi-table-months-wrapper {
  display: flex;
}
.kpi-table-anual span {
  color: #333333 !important;
}
.old_value {
  text-decoration: line-through !important;
  color: #f4516c !important;
  font-weight: bold;
  padding-left: 2px !important;
}
.kpi-table-anual {
  width: 100px;
}
.kpi-table-cover {
  transition: all 0.1s ease-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 36, 109, 0.7);
  border-radius: 2px;
  cursor: pointer;
}
.kpi-table-row:hover .kpi-table-cover {
  opacity: 1;
}

.kpi-table-cover span {
  position: absolute;
  top: calc(50% - 28px);
  color: #fff !important;
  font-weight: 600 !important;
  position: relative;
}
.kpi-table-row {
  position: relative;
  text-align: center;
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid #f5f5f5;
}
#modal_edit_kpi .kpi-table-row {
  border: 0 !important;
}
.kpi-table-row .not_calculated {
  filter: blur(3px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.kpi-table-row.revenue,
.kpi-table-row.revenuerestaurant {
  border-left: 3px solid #34bfa3;
}
.kpi-table-row.expense {
  border-left: 3px solid #f4516c;
}
.kpi-table-row.reputation {
  border-left: 3px solid #ffb822;
}
.kpi-table-row.accounting {
  border-left: 3px solid #36a3f7;
}
.kpi-table-th span {
  font-weight: 600 !important;
}
.kpi-table-row span {
  padding-top: 1.21rem;
  padding-bottom: 0.3rem;
  padding-left: 0;
  padding-right: 0rem;
  vertical-align: top;
  border-top: 0;
  font-size: 0.9rem;
  font-weight: 400;
  color: #a6a7c1;
  display: inline-block;
}
.m-widget27 .m-widget27__pic .m-widget27__title {
  top: 40%;
}
#progress_bar_container.m-widget27__title {
  top: auto;
  bottom: 8%;
  width: 50%;
  border-radius: 20px;
}
#progress_bar_container.m-widget27__title #progress_bar {
  border-radius: 20px;
  background: #22b9ff;
}
#progress_bar_container.m-widget27__title span {
  font-size: 11px;
}
/* Massive paste from excel */
.massive_paste {
  display: flex;
  width: 100%;
}
.massive_paste_textarea {
  flex: 5;
}
.massive_paste_textarea textarea {
  height: 110px;
}
.massive_paste_selector {
  flex: 3;
  padding-left: 20px;
}
/* custom.css contradictions*/
p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.btn:not(.md-skip) {
  text-transform: initial;
}

/* style.bundle.css hacks */
.bootstrap-select > .dropdown-toggle::after {
  right: 1rem !important;
}
.bootstrap-select.btn-group .dropdown-toggle .bs-caret {
  display: none;
}
</style>
