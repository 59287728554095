<template>
  <div class="py-2">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>
            {{ $t("ranges_occupancy_screen_availability") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <a-hotel-configuration-ranges-occ-availability
              :item="item"
            ></a-hotel-configuration-ranges-occ-availability>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>
            {{ $t("pickup_intervals") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <a-hotel-configuration-pickup-intervals
              :item="item"
            ></a-hotel-configuration-pickup-intervals>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import aHotelConfigurationPickupIntervals from "@/components/settings/aHotelConfigurationPickupIntervals.vue";
import aHotelConfigurationRangesOccAvailability from "@/components/settings/aHotelConfigurationRangesOccAvailability.vue";

export default {
  name: "a-hotel-configuration",
  components: {
    aHotelConfigurationPickupIntervals,
    aHotelConfigurationRangesOccAvailability
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  }
};
</script>
