<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <div
      class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
      style="position:relative"
      v-if="is_group"
    >
      <div class="px-10 mx-5">
        <h3 class="text-h3 d-block">
          {{ $t("which_hotel_you_want_setup") }}
        </h3>
        <v-btn
          class="mt-10"
          color="secondary"
          rounded
          @click="openHotelSelector"
          >{{ $t("select_hotel") }}
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <import-dates-status ref="import-dates-status"></import-dates-status>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-show="show_new_import">
          <v-card>
            <!--            <new-ulyses-import-->
            <!--              ref="new-ulyses-import"-->
            <!--              v-if="current_hotel.pms === 'ulyses'"-->
            <!--            ></new-ulyses-import>-->
            <new-manual-import ref="new-manual-import"></new-manual-import>
          </v-card>
        </v-col>
        <v-col :cols="cols">
          <v-card>
            <v-toolbar color="white" light flat>
              <v-toolbar-title>{{ $t("imports_historic") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <new-automatic-import
                ref="new-automatic-import"
              ></new-automatic-import>
              <revenue-import-list
                ref="revenue-import-list"
              ></revenue-import-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </a-page>
</template>

<script>
import aPage from "@/components/aPage";
import newManualImport from "@/components/revenue-import/NewManualImport";
// import newUlysesImport from "@/components/revenue-import/NewUlysesImport";
import revenueImportList from "@/components/revenue-import/RevenueImportList";
import importDatesStatus from "@/components/revenue-import/ImportDatesStatus.vue";
import { mapGetters } from "vuex";
import NewAutomaticImport from "@/components/revenue-import/NewAutomaticImport.vue";

export default {
  name: "revenue-import",
  components: {
    NewAutomaticImport,
    aPage,
    newManualImport,
    // newUlysesImport,
    revenueImportList,
    importDatesStatus
  },
  data: () => ({
    app_id: "revenue-import-app",
    image_source: "/img/header-2.jpg" // Mandatory path
  }),
  computed: {
    image_height: function() {
      return this.is_group ? 300 : 240;
    },
    is_group: function() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    show_new_import() {
      return this.current_user && this.current_hotel
        ? (this.current_user.permissions.reservations_import_manual &&
            this.current_hotel.permissions.reservations_import_manual) ||
            (this.current_user.permissions.daily_import_manual &&
              this.current_hotel.permissions.daily_import_manual) ||
            (this.current_user.permissions.channel_import_manual &&
              this.current_hotel.permissions.channel_import_manual) ||
            (this.current_user.permissions.rms_hotelsdot &&
              this.current_hotel.permissions.rms_hotelsdot)
        : false;
    },
    cols() {
      return this.show_new_import ? 9 : 12;
    },
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  methods: {
    openHotelSelector: function() {
      this.$store.dispatch("setShowHotelList", true);
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped></style>
