<template>
  <v-form v-model="isValid">
    <v-card>
      <v-card-title>
        <div class="d-block full-width">
          <div class="d-flex justify-space-between">
            <span class="text-h5"> {{ titleModal }}</span>
            <v-btn @click="cancel" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row v-if="isAdd || isSingle">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="form.offer_name"
              :counter="50"
              :label="$t('name')"
              :rules="rulesRequired"
              dense
              rounded
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
              v-model="form.offer_description"
              :label="$t('description')"
              dense
              rounded
              outlined
            ></v-text-field>
          </v-col>
          <v-col :cols="isSingle ? 12 : 6" class="pt-0">
            <v-select
              v-model="form.group_id"
              :items="groups"
              :label="$t('type')"
              required
              rounded
              outlined
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col v-if="isAdd" cols="6" class="pt-0">
            <v-select
              v-model="form.offer_mode"
              :items="offersMode"
              :label="$t('mode')"
              required
              rounded
              outlined
              dense
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="!isSingle && !isAdd">
          <v-col cols="12" class="pb-0">
            <div class="title-label">{{ $t("rates") }}</div>
            <a-offers-autocomplete-rates
              v-model="form.selectedRates"
              required
              :offers="offers"
              :prependIcon="null"
            >
            </a-offers-autocomplete-rates>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-menu
              v-model="menuPeriod"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  text
                  block
                  rounded
                  outlined
                  large
                  :label="$t('period')"
                  background-color="transparent"
                  v-on="on"
                  append-icon="mdi-calendar-month-outline"
                  dense
                  :value="formatedDatesRange"
                  hide-details
                >
                </v-text-field>
              </template>
              <v-date-picker
                class="d-block"
                :min="minDate"
                color="accent"
                v-model="datesRange"
                @input="autoSelectDays"
                range
                :title-date-format="() => formatedDatesRange"
                first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <div class="title-label">{{ $t("Days") }}</div>
            <v-card outlined rounded>
              <v-card-text class="pt-0 pb-0">
                <v-chip-group
                  v-model="daysSelected"
                  active-class="white"
                  multiple
                >
                  <v-chip
                    outlined
                    v-for="(day, key) of days"
                    :key="key"
                    :value="key"
                  >
                    {{ day }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!isAdd">
          <v-col cols="12">
            <v-card outlined>
              <v-row no-gutters class="options-rate">
                <v-col cols="6" class="pa-0">
                  <v-card flat @click="updateRateForm('absolute')">
                    <v-list
                      class="py-0 option-rate"
                      :class="{
                        'info selected': form.type_rate_change === 'absolute'
                      }"
                    >
                      <v-list-item class="px-2">
                        <v-list-item-avatar size="30" color="white">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-body-2 font-weight-bold"
                          >
                            {{ $t("Redefine_value") }} ({{ offerModeIcon }})
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <v-card flat @click="updateRateForm('relative_currency')">
                    <v-list
                      class="py-0 option-rate"
                      :class="{
                        'info selected':
                          form.type_rate_change === 'relative_currency'
                      }"
                    >
                      <v-list-item class="px-2">
                        <v-list-item-avatar
                          size="30"
                          color="white"
                          style="margin-right: 8px !important;"
                        >
                          <v-icon>mdi-swap-vertical-bold</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-body-2 font-weight-bold"
                          >
                            {{ $t("Increase/decrease_value") }} ({{
                              offerModeIcon
                            }})
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              :label="isAdd ? $t('new_offer') : $t('modify_offer')"
              type="number"
              v-model="form.rate_value"
              required
              :rules="rulesPrice"
              outlined
              rounded
              dense
            >
              <template #append>
                <v-icon v-if="form.offer_mode === 'absolute'">
                  mdi-currency-eur
                </v-icon>
                <v-icon v-else>
                  mdi-percent
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
              :label="$t('release_days')"
              type="number"
              v-model="form.release_value"
              :rules="rulesRelease"
              outlined
              rounded
              dense
              append-icon="mdi-calendar-check-outline"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="summaryAction">
          <v-col>
            <v-alert type="info" dense>{{ summaryAction }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn
          depressed
          rounded
          @click="save"
          color="info"
          class="px-12"
          :disabled="!formIsValid"
        >
          {{ $t("save_changes") }}
        </v-btn>
        <v-btn depressed rounded outlined @click="cancel" class="px-12">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { offersCellOperations } from "@/utils";
import pencil from "@/assets/imgs/pencil.png";
import euro from "@/assets/imgs/euro.png";
import percent from "@/assets/imgs/percent.png";
import aOffersAutocompleteRates from "@/components/offers/aOffersAutocompleteRates.vue";

const days = [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek => {
  // keep this date as it will produce lun, mar, mie, jue, vie, sab, dom
  return Vue.moment()
    .weekday(dayOfWeek)
    .format("dddd")
    .substring(0, 3);
});
const enumerateDaysBetweenDates = function(startDate, endDate) {
  let dates = [];
  let currDate = Vue.moment(startDate).startOf("day");
  let lastDate = Vue.moment(endDate).startOf("day");
  dates.push(currDate.clone());
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.clone());
  }
  return dates;
};
const getUTCDaysBetweenDates = function(datesRange) {
  return Array.from(
    new Set(
      enumerateDaysBetweenDates(...datesRange).map(e => Vue.moment(e).weekday())
    )
  );
};

export default {
  name: "a-offers-bulk-edit-form",
  components: {
    aOffersAutocompleteRates
  },
  data: () => ({
    euro,
    pencil,
    percent,
    isValid: false,
    refreshKey: 0,
    days,
    daysSelected: [],
    tab: null,
    menuPeriod: false,
    datesRange: [],
    form: {
      offer_name: "",
      offer_description: "",
      offer_mode: "absolute",
      group_id: null,
      type_rate_change: "absolute",
      rate_value: null,
      release_value: null,
      selectedRates: []
    },
    operations: offersCellOperations()
  }),
  mounted() {
    const displayedDays =
      this.$store.state.offers && this.$store.state.offers.offersTableData.days;

    let period = [
      { date: displayedDays[0] },
      { date: displayedDays[displayedDays.length - 1] }
    ];
    this.datesRange = period.map(e => e.date).sort((a, b) => a - b);
    this.daysSelected = getUTCDaysBetweenDates(this.datesRange);

    // get offer info if single offer
    if (this.isSingle && this.$store.state.offers.clicked_rate) {
      let offer = this.$store.state.offers.clicked_rate;
      this.form.offer_name = offer.offer_name;
      this.form.offer_description = offer.offer_description;
      this.form.offer_mode = offer.offer_mode;
      this.form.group_id = offer.group_id;
      this.form.selectedRates = [offer.offer_id];
    }

    if (!this.isSingle && !this.isAdd) {
      this.form.selectedRates.push(this.firstRate());
    }

    if (this.isAdd) {
      this.form.type_rate_change = "absolute";
    }

    if (this.$store.state.offers.groups.length === 0) {
      this.$store.dispatch("fetchOffersGroups");
    }
  },
  computed: {
    currencyIcon() {
      return `mdi-currency-${this.currency.toLowerCase()}`;
    },
    titleModal() {
      if (this.isAdd) {
        return this.$t("add_offer");
      } else if (this.isSingle) {
        return this.$t("edit_offer");
      }
      return this.$t("edit_offers");
    },
    groups() {
      return this.$store.state.offers.groups.map(e => {
        return {
          text: e.group_name,
          value: e.group_id
        };
      });
    },
    offersMode() {
      return [
        {
          text: this.$t("absolute") + " (" + this.currency + ")",
          value: "absolute"
        },
        {
          text: this.$t("percentage") + " (%)",
          value: "percent"
        }
      ];
    },
    offers() {
      return (
        this.$store.state.offers &&
        this.$store.state.offers.offersTableData.hotelLabels.map(e => ({
          ...e,
          disabled: e.isGroup,
          value: e.offer_id
        }))
      );
    },
    clickedRateName() {
      return this.$store.state.offers.clicked_rate.offer_name;
    },
    minDate() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    formatedDatesRange() {
      if (this.datesRange.length > 1) {
        const [monthIni, monthEnd] = [
          Vue.moment(this.datesRange[0]).format("MMMM"),
          Vue.moment(this.datesRange[1]).format("MMMM")
        ];
        return monthIni !== monthEnd
          ? this.$i18n.t("from_to_dates", {
              from: Vue.moment(this.datesRange[0]).format("D MMMM"),
              to: Vue.moment(this.datesRange[1]).format("D MMMM")
            })
          : this.$i18n.t("from_day_to_day_of_month", {
              from: Vue.moment(this.datesRange[0]).format("D"),
              to: Vue.moment(this.datesRange[1]).format("D"),
              month: monthIni
            });
      }
      return "";
    },
    formIsValid() {
      if (this.isAdd) {
        return (
          this.isValid &&
          this.form.offer_name &&
          this.form.group_id &&
          this.datesRange.length === 2 &&
          this.days.length > 0 &&
          this.form.rate_value
        );
      }
      if (this.isSingle) {
        return (
          this.isValid &&
          this.form.offer_name &&
          this.form.group_id &&
          this.datesRange.length === 2 &&
          this.days.length > 0 &&
          (this.form.rate_value || this.form.release_value)
        );
      }
      if (
        this.isValid &&
        this.datesRange.length === 2 &&
        this.days.length > 0 &&
        (this.form.rate_value || this.form.release_value)
      ) {
        return true;
      }
      return false;
    },
    summaryAction() {
      if (
        (this.form.offer_mode === "absolute" && this.form.rate_value > 0) ||
        (this.form.offer_mode === "percent" &&
          this.form.rate_value &&
          this.form.rate_value !== 0)
      ) {
        let key = `summary_action_rates_${this.form.type_rate_change}`;
        let rates_str = this.$store.getters.offersTableHotelLabels
          .filter(e => e.isRate && this.form.selectedRates.includes(e.offer_id))
          .map(e => `${e.offer_name} (${e.group_name})`)
          .join(", ");

        if (this.isAdd || this.isSingle) {
          rates_str =
            this.form.offer_name +
            " (" +
            this.groups.filter(e => e.value === this.form.group_id)[0].text +
            ")";
        }

        return this.$i18n.t(key, {
          value: this.form.rate_value,
          sign: this.form.rate_value >= 0 ? "+" : "",
          currency: this.form.offer_mode === "absolute" ? this.currency : "%",
          from_to_str: this.formatedDatesRange,
          days_of_week: this.daysSelectedSorted.join(", "),
          rates_str: rates_str
        });
      }
      return "";
    },
    daysSelectedSorted() {
      return this.daysSelected
        .map(e => this.days[e])
        .sort((a, b) => this.days.indexOf(a) - this.days.indexOf(b));
    },
    operation() {
      return this.operations[this.form.type_rate_change];
    },
    rulesRequired() {
      return [v => !!v || this.$i18n.t("required_field")];
    },
    rulesPrice() {
      const arrRules = [];
      if (this.form.type_rate_change === "absolute") {
        arrRules.push(v => v > 0 || this.$i18n.t("must_be_positive"));
      }
      return arrRules;
    },
    rulesRelease() {
      return [...[v => v >= 0 || this.$i18n.t("must_be_positive")]];
    },
    isAdd() {
      return this.$store.state.offers.bulk_form_type === "add";
    },
    isSingle() {
      return this.$store.state.offers.bulk_form_type === "single";
    },
    offerModeIcon() {
      return this.form.offer_mode === "absolute" ? this.currency : "%";
    },
    ...mapState({
      currency: state => state.currency.currency
    })
  },
  methods: {
    updateRateForm(value) {
      this.form.rate_value = null;
      this.form.type_rate_change = value;
    },
    save() {
      let payload = {};
      if (this.isAdd || this.isSingle) {
        payload.offer_id = this.form.selectedRates[0];
        payload.offer_name = this.form.offer_name;
        payload.offer_description = this.form.offer_description;
        payload.offer_mode = this.form.offer_mode;
        payload.group_id = this.form.group_id;
      }
      this.savePrices();
      this.saveRelease();
      this.$store.dispatch("setOffersChanges", payload);
      this.cancel();
    },
    savePrices() {
      if (this.form.rate_value) {
        this.$store.dispatch("applyOffersPriceChanges", {
          daysSelected: this.daysSelected,
          datesRange: this.datesRange,
          operation: this.operation,
          form: {
            entity_ids: this.form.selectedRates,
            type_rate_change: this.form.type_rate_change,
            value: this.form.rate_value,
            entity_type: "offer"
          }
        });
      }
    },
    saveRelease() {
      if (this.form.release_value) {
        this.$store.dispatch("applyOffersReleaseChanges", {
          daysSelected: this.daysSelected,
          datesRange: this.datesRange,
          form: {
            entity_ids: this.form.selectedRates,
            value: this.form.release_value
          }
        });
      }
    },
    cancel() {
      this.$store.dispatch("setOffersBulkFormOpen", false);
      this.resetStoreValues();
    },
    resetStoreValues() {
      this.$store.dispatch("setOffersClickedRate", null);
    },
    autoSelectDays(e) {
      if (e.length === 2) {
        this.daysSelected = getUTCDaysBetweenDates(e);
      }
    },
    firstRate() {
      const hotelLables = this.$store.state.offers.offersTableData.hotelLabels;
      for (let label in hotelLables) {
        if (hotelLables[label].isOffer) {
          return hotelLables[label].offer_id;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.title-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  font-weight: 400;
  margin-bottom: -12px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  display: table;
  padding: 0 5px;
  transform: scale(0.75) translateX(28px);
  transform-origin: top left;
}
.v-chip {
  flex: 1;
  justify-content: center;
}
.v-card {
  &.rounded {
    border-radius: 28px !important;
    border-color: rgba(0, 0, 0, 0.38);
  }
}
.options-rate {
  .v-card,
  .v-list,
  .v-list-item {
    height: 100%;
  }
  .option-rate {
    cursor: pointer;
    &.selected {
      & div:hover * {
        color: black !important;
      }
      ::v-deep .v-list-item__content {
        color: white !important;
      }
      ::v-deep .v-list-item__subtitle {
        color: white !important;
      }
    }
  }
}
</style>
