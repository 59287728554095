<template>
  <div>
    <a-offers-table-row-days
      :days="days"
      :maxSlides="maxSlides"
    ></a-offers-table-row-days>
    <v-virtual-scroll
      :items="rows"
      :item-height="44"
      :height="500"
      class="overflow-x-hidden"
    >
      <template v-slot:default="{ item, index }">
        <a-offers-table-row-group
          :rowData="item"
          :rowIndex="index"
          v-if="item.isGroup"
        ></a-offers-table-row-group>
        <a-offers-table-row-rate
          :rowData="item"
          :rowIndex="index"
          v-if="item.isOffer"
        ></a-offers-table-row-rate>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
import aOffersTableRowDays from "@/components/offers/aOffersTableRowDays.vue";
import aOffersTableRowGroup from "@/components/offers/aOffersTableRowGroup.vue";
import aOffersTableRowRate from "@/components/offers/aOffersTableRowRate.vue";

export default {
  name: "a-offers-table",
  components: {
    aOffersTableRowDays,
    aOffersTableRowGroup,
    aOffersTableRowRate
  },
  props: {
    days: Array,
    rows: Array,
    maxSlides: Number
  }
};
</script>
<style lang="scss" scoped>
.overflow-x-hidden {
  overflow-x: hidden;
}
::v-deep .channel-row {
  background-color: #e5f2f6;
}
</style>
