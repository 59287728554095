var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"kpi-table"}},[_c('div',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editing && !_vm.hasYearError),expression:"!editing && !hasYearError"}]},[(!_vm.is_saving_year_budget && !_vm.getYearLastUpdate)?[_vm._v(" "+_vm._s(_vm.$t("budget_never_updated"))+" ")]:_vm._e(),(!_vm.is_saving_year_budget && _vm.getYearLastUpdate)?[_vm._v(" "+_vm._s(_vm.$t("last_update"))+": "+_vm._s(_vm.getYearLastUpdate)+" "+_vm._s(_vm.getYearCacheRefreshed ? "" : ". " + _vm.$t("updates_on_inicrements_will_be_shown_tomorrow"))+" ")]:_vm._e()],2),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editing && _vm.hasYearError),expression:"!editing && hasYearError"}]},[_vm._v(_vm._s(_vm.$t("error_while_importing"))+": "+_vm._s(_vm.error_text))])]),_c('div',{staticClass:"kpi-table-row kpi-table-th"},[_c('div',{staticClass:"kpi-table-label"},[_c('span',[_vm._v(_vm._s(_vm.$t("kpi")))])]),_c('div',{staticClass:"kpi-table-months"},[_c('div',{staticClass:"kpi-table-months-wrapper"},_vm._l((_vm.months),function(month,key){return _c('div',{key:key,staticClass:"kpi-table-month"},[_c('span',[_vm._v(_vm._s(month.text))])])}),0)]),(!_vm.editing)?_c('div',{staticClass:"kpi-table-anual"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("annual")))])]):_vm._e()]),_vm._l((_vm.kpis),function(kpi,kpi_name){return _c('div',{key:kpi_name,staticClass:"kpi-table-row ",class:[
      { is_dimensioned: kpi.is_dimensioned && !_vm.editing },
      kpi.section,
      { 'kpi-table-row-editing': !kpi.editable && _vm.editing }
    ]},[(kpi.editable || (!kpi.editable && !_vm.editing))?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(kpi.editable && _vm.editing),expression:"kpi.editable && editing"}],staticClass:"kpi-table-cover",on:{"click":function($event){return _vm.editKpi(kpi_name)}}},[_c('span',[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("modify"))+" "+_vm._s(kpi.name))],1)]),_c('div',{staticClass:"kpi-table-label"},[_c('span',[_vm._v(_vm._s(kpi.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!kpi.editable && !_vm.editing),expression:"!kpi.editable && !editing"}],staticClass:"m-badge m-badge--default",attrs:{"title":_vm.$t('it_is_computed_automatically')}},[_c('v-icon',[_vm._v("mdi-cog")])],1)]),_c('div',{staticClass:"kpi-table-months",class:{ not_calculated: _vm.is_saving_year_budget && !kpi.editable }},[_c('div',{staticClass:"kpi-table-months-wrapper"},_vm._l((_vm.months),function(month,key){return _c('div',{key:key,staticClass:"kpi-table-month"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.parseValue(
                  _vm.new_kpis[kpi_name][("periodo" + (month.value + 1))],
                  kpi.type
                )
              )}}),(
                _vm.new_kpis[kpi_name][("periodo" + (month.value + 1))] !=
                  kpi[("periodo" + (month.value + 1))]
              )?_c('span',{staticClass:"old_value",domProps:{"innerHTML":_vm._s(_vm.parseValue(kpi[("periodo" + (month.value + 1))], kpi.type))}}):_vm._e()])}),0)]),(!_vm.editing)?_c('div',{staticClass:"kpi-table-anual",class:{ not_calculated: _vm.is_saving_year_budget }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseValue(kpi.anual, kpi.type))}})]):_vm._e()]:_vm._e()],2)}),_c('div',{staticClass:"kpi-table-legend d-flex mt-5"},[_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","color":"success","content":_vm.$t('revenue')}}),_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","color":"error","content":_vm.$t('expense')}}),_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","color":"info","content":_vm.$t('profitability')}}),_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","color":"warning","content":_vm.$t('reputation')}})],1),_c('budget-modify-dialog')],2)}
var staticRenderFns = []

export { render, staticRenderFns }