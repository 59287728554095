<template>
  <div class="a-rms-vs-selector-single">
    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div class="grey--text">
          <v-btn
            depressed
            rounded
            v-bind="attrs"
            v-on="on"
            light
            color="white"
            :dark="dark"
            :outlined="dark"
            :large="large"
          >
            <b class="text-capitalize font-weight-bold">
              {{ $t("comparative_columns") }}
            </b>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list>
        <v-list-item v-for="(value, index) in values_vs" :key="index">
          <template>
            <v-list-item-action class="mr-0">
              <v-checkbox
                :input-value="value"
                @change="updateVs(index)"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ getPeriodLiteral(index) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "a-rms-vs-selector-multiple",
  props: {
    values_vs: {
      type: Object,
      default: () => []
    },
    dark: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPeriodLiteral(period) {
      if (period.split("_")[0] === "pickup") {
        return "Pickup " + this.current_hotel.settings.pickup_spots[period];
      }
      return this.$t(period);
    },
    updateVs(vs) {
      this.$emit("updateVs", vs);
    }
  },
  computed: {
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
