var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4 mb-2"}},[_c('v-card-text',{staticClass:"white pa-0"},[_c('div',{staticClass:"grid-container"},[(_vm.group_of_columns)?_c('table',[_c('thead',[_c('tr',{staticClass:"group-row"},_vm._l((_vm.group_names),function(group){return _c('th',{key:group,class:[
                {
                  'sticky-columns-shadow': group === _vm.last_sticky_group_column
                },
                {
                  'br-bolder': group !== _vm.last_sticky_group_column
                }
              ],attrs:{"colspan":_vm.group_of_columns[group].length}},[(group)?[_c('div',{staticClass:"column-group"},[(_vm.group_of_columns[group].length === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white","small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.configStrategyTable[group].icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(group)))])]):[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.configStrategyTable[group].icon)+" ")]),_c('span',{staticClass:"pl-2"},[(
                          group.includes('pickup_') && group !== 'pickup_0'
                        )?[_vm._v(" Pickup "+_vm._s(_vm.current_hotel.settings.pickup_spots[group])+" ")]:[_vm._v(" "+_vm._s(_vm.$t(group))+" ")]],2)]],2)]:_vm._e()],2)}),0),_c('tr',{staticClass:"header-row"},_vm._l((_vm.column_headers),function(column_header){return _c('th',{key:((column_header.group) + "-" + (column_header.column)),class:[
                {
                  'sticky-columns-shadow':
                    column_header.column === _vm.last_sticky_group_column
                },
                {
                  'br-bolder':
                    _vm.last_column_of_each_group[column_header.group] ===
                      column_header.column &&
                    column_header.group !== _vm.last_sticky_group_column
                }
              ],style:({
                width: column_header.column == 'date' ? '200px' : '100px'
              })},[_c('div',{staticClass:"cell",class:{
                  'kpi-row': !_vm.sticky_columns.includes(column_header.column),
                  'kpi-hovered':
                    _vm.kpi_hover.column === column_header.column &&
                    _vm.kpi_hover.group === column_header.group
                },style:({
                  width: column_header.column == 'date' ? '200px' : '100px'
                })},[_c('span',{staticClass:"d-block day-name grey--text text--darken-3 text-uppercase"},[(column_header.group === 'segment')?[_vm._v(" "+_vm._s(_vm.segments.find( function (segment) { return segment.code === column_header.column; } ).name)+" ")]:(column_header.group === 'roomtype')?[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80px"}},[_vm._v(" "+_vm._s(_vm.roomTypes.find( function (roomType) { return roomType.code === column_header.column; } ).name)+" ")]),_c('v-chip',{attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.roomTypes.find( function (roomType) { return roomType.code === column_header.column; } ).available_rooms))])]:[_vm._v(" "+_vm._s(_vm.$t(column_header.column))+" ")]],2)])])}),0)]),_c('tbody',[_vm._l((_vm.days),function(day){return _c('aRmsStrategyDayTableRow',{key:("" + day),staticClass:"row-data",attrs:{"date":day,"row":_vm.columns[day],"nonStickyColumnHeaders":_vm.non_sticky_column_headers,"lastStickyColumn":_vm.last_sticky_column,"configStrategyTable":_vm.configStrategyTable,"lastColumnOfEachGroup":_vm.last_column_of_each_group,"kpiMode":_vm.kpiMode},on:{"clickOnDateCell":function($event){return _vm.getDayDetails(day)},"hoveredKpi":function($event){_vm.kpi_hover = $event}}})}),_c('aRmsStrategyDayTableRow',{staticClass:"row-data summary",attrs:{"date":null,"row":_vm.totalPeriod,"nonStickyColumnHeaders":_vm.non_sticky_column_headers,"lastStickyColumn":_vm.last_sticky_column,"configStrategyTable":_vm.configStrategyTable,"lastColumnOfEachGroup":_vm.last_column_of_each_group,"isSummary":"","kpiMode":_vm.kpiMode},on:{"hoveredKpi":function($event){_vm.kpi_hover = $event}}})],2)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }