<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-row
        class="kpi-row mx-0"
        :class="hover ? color_class : color_class"
        @click="getDayDetails"
      >
        <v-col class="kpi-label-wrapper pa-0">
          <div class="kpi-label d-flex align-center">
            <div style="width: 100%;">
              <div class="d-flex justify-start align-center">
                <div v-html="label"></div>
                <v-chip v-if="isToday" dark x-small color="blue" class="ml-2">{{
                  $t("today")
                }}</v-chip>
                <v-spacer></v-spacer>
                <a-rms-strategy-day-events
                  v-for="impact in impacts"
                  :key="impact.impact"
                  :impact="impact"
                  :events="events"
                  :date="date"
                />
              </div>
              <div
                class="d-flex justify-start align-center grey--text"
                v-if="
                  rms.strategy_vs_spot === 'stly' ||
                    rms.strategy_vs_spot === 'ly'
                "
              >
                <div v-html="label_vs"></div>
                <v-spacer></v-spacer>
                <a-rms-strategy-day-events
                  v-for="impact_vs in impacts_vs"
                  :key="impact_vs.impact"
                  :impact="impact_vs"
                  :events="events_vs"
                  :date="date_vs"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="pa-0 ma-0">
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(col, index) of columns"
              :key="index"
              class="pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center"
              :class="getClass(col.kpi, day_data.otb[col.kpi])"
              @mouseover="kpi_hover = col.kpi"
              @mouseleave="kpi_hover = ''"
            >
              <template v-if="day_data[rms.strategy_vs_spot][col.kpi]">
                <template v-if="rms.strategy_vs_spot.startsWith('pickup_')">
                  <a-kpi
                    :kpi="col.kpi"
                    :value="day_data.otb[col.kpi]"
                    :vs_values="[
                      day_data.otb[col.kpi] -
                        day_data[rms.strategy_vs_spot][col.kpi]
                    ]"
                    :vs_periods="[rms.strategy_vs_spot]"
                    x_small
                    vertical
                  >
                  </a-kpi>
                </template>
                <template v-else>
                  <a-kpi
                    :kpi="col.kpi"
                    :value="day_data.otb[col.kpi]"
                    :vs_values="[day_data[rms.strategy_vs_spot][col.kpi]]"
                    :vs_periods="[rms.strategy_vs_spot]"
                    x_small
                    vertical
                  >
                  </a-kpi>
                </template>
                <div
                  v-if="showProgressPercent(col.kpi)"
                  class="percent-progress"
                  :style="getPercentStyle(day_data.otb[col.kpi])"
                ></div>
              </template>
              <template v-else>
                <a-kpi
                  :kpi="col.kpi"
                  :value="day_data.otb[col.kpi]"
                  x_small
                  vertical
                >
                </a-kpi>
              </template>
              <!-- <a-kpi
                v-if="day_data[rms.strategy_vs_spot][col.kpi]"
                :kpi="col.kpi"
                :value="day_data.otb[col.kpi]"
                :vs_values="[day_data[rms.strategy_vs_spot][col.kpi]]"
                :vs_periods="[rms.strategy_vs_spot]"
                x_small
                vertical
              >
              </a-kpi>
              <a-kpi
                v-else
                :kpi="col.kpi"
                :value="day_data.otb[col.kpi]"
                x_small
                vertical
              >
              </a-kpi> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-hover>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi.vue";
// import aIncrement from "@/components/rms_ui/aIncrement.vue";
import aRmsStrategyDayEvents from "@/components/rms/aRmsStrategyDayEvents.vue";
import * as Utils from "@/utils.js";

export default {
  name: "a-rms-strategy-day-row",
  components: {
    aKpi,
    aRmsStrategyDayEvents
    // aIncrement
  },
  props: {
    columns: Array,
    rowIndex: Number,
    date: String,
    vs_pickup: String,
    only_rooms: Boolean,
    page: Number,
    days_per_screen: Number,
    total_days: Number
  },
  data: vm => ({
    min_occupancy: 30,
    max_occupancy: 70,
    limit_mlos: 2
  }),
  computed: {
    color_class() {
      if (!this.day_data || !this.day_data.otb || !this.day_data.bud)
        return "neutral";

      let diff = this.day_data.otb.occupancy - this.day_data.bud.occupancy;
      if (diff > 0) return "positive";
      if (diff < 0) return "negative";
      return "neutral";
    },
    isToday() {
      return this.date === Vue.moment().format("YYYY-MM-DD");
    },
    periods() {
      let periods = [
        { code: "bud" },
        { code: "stly" },
        { code: "ly" },
        { code: "pickup_1" },
        { code: "pickup_2" },
        { code: "pickup_3" },
        { code: "pickup_4" },
        { code: "pickup_5" },
        { code: "pickup_6" }
      ];

      return periods;
    },
    day_data() {
      return this.date ? this.getRmsDayKpis(this.date) : {};
    },
    events() {
      let events = this.date
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.date).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
      return events;
    },
    events_vs() {
      let events = this.date
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.date_vs).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
      return events;
    },
    impacts() {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          color: i.color,
          count: this.events.filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    impacts_vs() {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          count: this.events_vs.filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    date_vs() {
      let date_vs = {};
      let items = this.calendar_store.hotel_comparative_dates;
      if (Object.keys(items).length > 0 && items.hasOwnProperty(this.date)) {
        date_vs = items[this.date].date_vs;
      }
      return date_vs;
    },
    label() {
      return Utils.getFriendlyDayStrategy(this.date, true);
    },
    label_vs() {
      return Utils.getFriendlyDayStrategy(this.date_vs, true);
    },
    styleRow() {
      let width = (this.total_days / this.days_per_screen) * 100;
      if (width < 100) {
        width = 100;
      }
      return (
        "transform: translateX(calc(((-100%) * " +
        this.days_per_screen +
        " * " +
        this.page +
        ") / " +
        this.total_days +
        "));" +
        "width: " +
        width +
        "%"
      );
    },
    kpi_hover: {
      get() {
        return this.$store.state.rms.strategy_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsStrategyKpiHover", value);
      }
    },
    ...mapState({
      calendar_store: state => state.calendar,
      rms: state => state.rms
    }),
    ...mapGetters({
      getRmsDayKpis: "getRmsDayKpis",
      getHotelById: "getHotelById",
      get_kpi: "get_kpi"
    })
  },
  methods: {
    getPercentStyle(value) {
      return "width:" + value + "%;";
    },
    showProgressPercent(kpi) {
      let type = this.get_kpi(kpi).data_format;
      if (type === "percent1" || type === "percent2") {
        return true;
      } else {
        return false;
      }
    },
    getClass(kpi, value) {
      // If type of kpi is percent we must add a class to the cell named  kpi-percent-range-0-20, kpi-percent-range-20-40, kpi-percent-range-40-60, kpi-percent-range-60-80, kpi-percent-range-80-100
      let type = this.get_kpi(kpi).data_format;
      if (value === null) return "";
      if (type === "percent1" || type === "percent2") {
        if (value > 0 && value < 20) {
          return "kpi-percent-range-0-20";
        } else if (value >= 20 && value < 40) {
          return "kpi-percent-range-20-40";
        } else if (value >= 40 && value < 60) {
          return "kpi-percent-range-40-60";
        } else if (value >= 60 && value < 80) {
          return "kpi-percent-range-60-80";
        } else if (value >= 80 && value <= 100) {
          return "kpi-percent-range-80-100";
        }
      } else {
        return "";
      }
    },
    getDayDetails() {
      this.$emit("getDayDetails", this.date);
    },
    getDayFromIndex(index) {
      return this.columns[index];
    },
    isWeekend(index) {
      return Vue.moment(this.getDayFromIndex(index)).isoWeekday() >= 5;
    },
    isClosed(index) {
      return this.rowAvailable && this.rowAvailable.otb[index] <= 0;
    },
    bgCellClass(index) {
      let classes = [];
      if (this.isWeekend(index)) {
        classes.push("weekend");
      }
      if (this.isClosed(index)) {
        classes.push("closed");
      }
      return classes.join(" ");
    },
    highlightMlos(v) {
      return v >= this.limit_mlos;
    },
    occupancyColor(index) {
      return this.rowOccupancy.otb[index] < this.min_occupancy
        ? "red--text text--darken-2"
        : this.rowOccupancy.otb[index] > this.max_occupancy
        ? "green--text"
        : "";
    },
    vs_values(col) {
      return col.vs.map(v => this.day_data[v]);
    },
    classCol(col) {
      let hide = false;
      let clas = "pa-0 kpi-cell kpi-cell-row align-center justify-center";
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (col.value == "pickup_1" || col.value == "pickup_2") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        if (col.value == "pickup_3") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.xs) {
        if (col.value == "pickup_4") {
          hide = true;
        }
      }
      if (hide) {
        clas += " d-none";
      } else {
        clas += " d-flex";
      }
      return clas;
    },
    isVisibleRowItem(kpi) {
      if (this.only_rooms && (kpi === "rn" || kpi === "locked")) {
        return true;
      } else if (!this.only_rooms) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
$minHeightColRow: 60px;
.kpi-row {
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 3px;
    height: calc(100% - 8px);
    width: 4px;
    background-color: grey;
    z-index: 10;
    border-radius: 5px;
    margin: 4px 0;
  }
  &.positive::after {
    background-color: rgb(23, 171, 112);
  }
  &.negative::after {
    background-color: rgb(172, 0, 0);
  }
  &.neutral::after {
    background-color: orange;
  }
  &:hover {
    .kpi-label-wrapper {
      .kpi-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }

  .kpi-label-wrapper {
    flex-grow: 0;
    flex-basis: 200px;

    .kpi-label {
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding: 0 5px 0 20px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
      text-transform: uppercase;
      .weekday {
        color: grey !important;
      }
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;
    position: relative;

    .percent-progress {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 3px;
      width: calc(100% - 6px);
      height: 4px;
      border-radius: 5px;
    }
    &.kpi-percent-range-0-20 {
      background-color: rgba(181, 68, 3, 0.2);
      .percent-progress {
        background-color: rgba(181, 68, 3, 1);
      }
    }
    &.kpi-percent-range-20-40 {
      background-color: rgba(219, 131, 0, 0.2);
      .percent-progress {
        background-color: rgba(219, 131, 0, 1);
      }
    }
    &.kpi-percent-range-40-60 {
      background-color: rgba(246, 185, 0, 0.2);
      .percent-progress {
        background-color: rgba(246, 185, 0, 1);
      }
    }
    &.kpi-percent-range-60-80 {
      background-color: rgba(158, 143, 2, 0.2);
      .percent-progress {
        background-color: rgba(158, 143, 2, 1);
      }
    }
    &.kpi-percent-range-80-100 {
      background-color: rgba(79, 131, 2, 0.2);
      .percent-progress {
        background-color: rgba(79, 131, 2, 1);
      }
    }

    &.first-column {
      flex-grow: 0;
      flex-basis: 200px;
    }

    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
</style>
