var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-base-component',{attrs:{"subscribed_modules":_vm.subscribed_modules},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_view === 'table'),expression:"tab_view === 'table'"}]},[_c('div',{staticClass:"d-flex justify-end align-center mb-4 mt-4",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"rounded-pill white d-flex align-center justify-space-between py-1 px-1",staticStyle:{"height":"100%","position":"relative"}},[_c('a-label',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$t("comparative"))+":")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","rounded":"","fab":"","color":_vm.kpi_mode === 'relative' ? 'secondary' : 'white'},on:{"click":function($event){_vm.kpi_mode = 'relative'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.kpi_mode === 'relative' ? 'white' : 'black'}},[_vm._v(" mdi-percent-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("percentage")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","rounded":"","fab":"","color":_vm.kpi_mode === 'absolute' ? 'secondary' : 'white'},on:{"click":function($event){_vm.kpi_mode = 'absolute'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.kpi_mode === 'absolute' ? 'white' : 'black'}},[_vm._v(" mdi-counter ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("absolute")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","rounded":"","fab":"","color":_vm.kpi_mode === 'hide_vs' ? 'secondary' : 'white'},on:{"click":function($event){_vm.kpi_mode = 'hide_vs'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.kpi_mode === 'hide_vs' ? 'white' : 'black'}},[_vm._v(" mdi-circle-off-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("hide_vs")))])])],1),_c('div',{staticClass:"mr-0 ml-2"},[_c('div',{staticClass:"rounded-pill white d-flex align-center justify-space-between py-1 px-1",staticStyle:{"height":"100%","position":"relative"}},[_c('a-label',{staticClass:"px-4"},[_vm._v("Vista:")]),_vm._l((_vm.year_sales_groups_names),function(group,index){return _c('v-tooltip',{key:'group_' + index,attrs:{"bottom":""},scopedSlots:_vm._u([(group)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-2 my-2",attrs:{"small":"","color":group.isActive ? 'secondary' : 'transparent',"text-color":group.isActive ? 'white' : 'black'},on:{"click":function($event){group.isActive = !group.isActive}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(group.group)+" ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(group.group)))])])})],2)])]),_c('a-rms-year-sales-table',{attrs:{"configYearSalesTable":_vm.configYearSalesTable,"monthsValues":_vm.monthsValues,"quartersValues":_vm.quartersValues,"yearValues":_vm.yearValues,"kpiMode":_vm.kpi_mode}})],1),_c('a-rms-year-sales-charts',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_view === 'chart'),expression:"tab_view === 'chart'"}],attrs:{"monthsGraphData":_vm.monthsGraphData,"quartersGraphData":_vm.quartersGraphData,"yearValues":_vm.yearValues}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-card',[_c('v-card-text',_vm._l((12),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"elevation-0 mb-2",attrs:{"type":"card","height":"70"}})}),1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }