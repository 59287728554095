var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"color":"white","light":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("comparative_dates")))]),_c('v-spacer'),(_vm.selectHotels.length > 0)?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","outlined":"","color":"secondary"},on:{"click":_vm.showCopyToOtherHotel}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_vm._v(" "+_vm._s(_vm.$t("copy_to_other_hotel"))+" ")],1):_vm._e(),(_vm.yearsAvailable.length > 0)?_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"secondary"},on:{"click":_vm.showRestoreDate}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-restore")]),_vm._v(" "+_vm._s(_vm.$t("restore"))+" ")],1):_vm._e()],1),_c('v-card-text',[_c('div',{staticClass:"table-header mb-4"},[_c('v-tabs',{staticClass:"px-1",attrs:{"background-color":"transparent","slider-size":"2","light":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.monthsYear),function(month){return _c('v-tab',{key:month.month_num},[_vm._v(" "+_vm._s(month.month_name)+" ")])})],2)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.monthsYear),function(month){return _c('v-tab-item',{key:month.month_num},[_c('v-data-table',{attrs:{"headers":_vm.hotel_comparative_headers,"items":_vm.hotelDates,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.getFriendlyDate(item.date)))]),(item.events.length > 0)?[_c('div',{staticClass:"caption"},_vm._l((item.events),function(event,index){return _c('div',{key:index,attrs:{"color":"white"}},[_c('v-chip',{attrs:{"x-small":"","text-color":"white","color":_vm.eventColor(event)}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.getEventTypeIcon(event.type))+" ")]),_c('b',[_vm._v(_vm._s(event.alias))])],1)],1)}),0)]:[_c('div',{staticClass:"caption"},[_c('span',{attrs:{"color":"white"}},[_c('v-chip',{attrs:{"x-small":""}},[_c('b',[_vm._v(_vm._s(_vm.$t("no_events")))])])],1)])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","dark":"","color":"#333333"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-menu',_vm._g({attrs:{"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDate = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"secondary","small":""}},Object.assign({}, onMenu, onDate)),[_vm._v("mdi-link ")])]}}],null,true)},onMenu),[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){return _vm.setHotelDate(item)}},model:{value:(item.date_vs),callback:function ($$v) {_vm.$set(item, "date_vs", $$v)},expression:"item.date_vs"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("change_comparative_date"))+" ")])])]}},{key:"item.date_vs",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.getFriendlyDate(item.date_vs)))]),(item.events_vs.length > 0)?[_c('div',{staticClass:"caption"},_vm._l((item.events_vs),function(event,index){return _c('div',{key:index,attrs:{"color":"white"}},[_c('v-chip',{attrs:{"x-small":"","text-color":"white","color":_vm.eventColor(event)}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.getEventTypeIcon(event.type))+" ")]),_c('b',[_vm._v(_vm._s(event.alias))])],1)],1)}),0)]:[_c('div',{staticClass:"caption"},[_c('span',{attrs:{"color":"white"}},[_c('v-chip',{attrs:{"x-small":""}},[_c('b',[_vm._v(_vm._s(_vm.$t("no_events")))])])],1)])]]}}],null,true)})],1)}),1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"400"},model:{value:(_vm.show_copy_to_other_hotel),callback:function ($$v) {_vm.show_copy_to_other_hotel=$$v},expression:"show_copy_to_other_hotel"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center",attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.$t("copy_to_other_hotel"))+" ")]),_c('v-card-text',{staticClass:"text-center justify-center"},[_c('v-form',{ref:"edit_copy_to_hotel_form",model:{value:(_vm.can_copy_to_hotel),callback:function ($$v) {_vm.can_copy_to_hotel=$$v},expression:"can_copy_to_hotel"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('v-autocomplete',{staticClass:"mt-7",attrs:{"items":_vm.selectHotels,"rules":[function (v) { return !!v || _vm.$t('required'); }],"label":_vm.$t('hotel'),"item-text":"name","item-value":"id","mandatory":"","rounded":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.hotel_copy_to_id),callback:function ($$v) {_vm.hotel_copy_to_id=$$v},expression:"hotel_copy_to_id"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"ps-5 pb-5 justify-center"},[_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"secondary","rounded":"","depressed":"","disabled":!_vm.can_copy_to_hotel},on:{"click":function($event){return _vm.copyToOtherHotel()}}},[_vm._v(" "+_vm._s(_vm.$t("copy"))+" ")]),_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"","rounded":"","depressed":""},on:{"click":function($event){return _vm.hideCopyToOtherHotel()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"400"},model:{value:(_vm.show_restore_date),callback:function ($$v) {_vm.show_restore_date=$$v},expression:"show_restore_date"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center",attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.$t("restore_dates"))+" ")]),_c('v-card-text',{staticClass:"text-center justify-center"},[_c('v-form',{ref:"edit_restore_date_form"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(
                    _vm.$t('compare_dates_with_year', {
                      year: this.calendar_store.calendar_year
                    })
                  )}}),_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.yearsAvailable,"placeholder":_vm.$t('select_year'),"mandatory":"","rounded":"","outlined":"","dense":""},model:{value:(_vm.restore_year),callback:function ($$v) {_vm.restore_year=$$v},expression:"restore_year"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"ps-5 pb-5 justify-center"},[_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"secondary","rounded":"","depressed":"","disabled":!_vm.restore_year},on:{"click":function($event){return _vm.restoreDate()}}},[_vm._v(" "+_vm._s(_vm.$t("restore"))+" ")]),_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"color":"","rounded":"","depressed":""},on:{"click":function($event){return _vm.hideRestoreDate()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }