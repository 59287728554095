<template>
  <div id="a-ellipse-kpi">
    <a-ellipse
      :done="bi_report.report[kpi] ? parseFloat(bi_report.report[kpi]) : 0"
      :percent="bi_report.report[kpi] ? parseFloat(bi_report.report[kpi]) : 0"
      :custom_value="kpi_value"
      :increment="
        bi_report.increments[kpi] ? parseFloat(bi_report.increments[kpi]) : 0
      "
      :vs_value="
        bi_report.data_to_compare[kpi]
          ? parseFloat(bi_report.data_to_compare[kpi])
          : 0
      "
      :kpi="kpi"
      :icon="kpi_icon ? kpi_icon : 'mdi-podium-silver'"
    ></a-ellipse>
    <a-label class="mt-2 text-center">
      {{ $t(this.get_kpi(this.kpi).code) }}
    </a-label>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aEllipse from "@/components/aEllipse";
import aBaseComponent from "@/components/aBaseComponent";
import aLabel from "@/components/aLabel";
import * as Utils from "@/utils.js";

export default {
  name: "a-ellipse-kpi",
  props: {
    kpi: {
      type: String,
      default: null
    }
  },
  components: {
    aEllipse,
    aLabel
  },
  data: vm => ({}),
  mounted() {},
  computed: {
    kpi_icon() {
      return this.get_kpi(this.kpi).icon;
    },
    kpi_type() {
      return this.get_kpi(this.kpi).data_format;
    },
    kpi_value() {
      return Utils.format_kpi_value(
        this.bi_report.report[this.kpi],
        this.kpi_type,
        this.l
      );
    },
    ...mapState({
      bi_report: state => state.bi.report,
      report_status: state => state.bi.report_status,
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      l: "current_hotel_language",
      get_kpi: "get_kpi"
    })
  }
};
</script>
<style scoped></style>
