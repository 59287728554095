<template>
  <v-row class="row-availability mx-0">
    <v-col class="channel-manager-rooms-labels pa-0">
      <div class="room-wrapper">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="label-wrapper d-flex align-center simple_view"
              v-bind="attrs"
              v-on="on"
            >
              <span class="relative">{{ getName }}</span>
              <span>
                <v-icon small>
                  mdi-bed-outline
                </v-icon>
              </span>
            </div>
          </template>
          <span>{{ getName }}</span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-window v-model="current_slide">
        <v-window-item v-for="(item, itemKey) of windowItems" :key="itemKey">
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(day, index) of getDays"
              :key="index"
              class="pa-0 d-flex justify-center cell-availability"
              :class="{ 'bg-weekend': isWeekend(day) }"
              @mouseenter="
                reportLocation({
                  rate_id: rowData.rate_id,
                  date: day,
                  row_index: rowIndex
                })
              "
            >
              <div class="cell d-flex align-center">
                <div class="room-info">
                  <a-chm-editable-availability
                    v-model="rowData.days[day]"
                    :info="{ ...getInfo, day }"
                  ></a-chm-editable-availability>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-col>
    <!-- <v-col
      v-for="(day, index) of getDays"
      :key="index"
      class="pa-0 d-flex justify-center cell-availability"
      :class="{ 'bg-weekend': isWeekend(day) }"
      @mouseenter="
        reportLocation({
          rate_id: rowData.rate_id,
          date: day,
          row_index: rowIndex
        })
      "
    >
      <div class="cell d-flex align-center">
        <div class="room-info">
          <a-chm-editable-availability
            v-model="rowData.days[day]"
            :info="{ ...getInfo, day }"
          ></a-chm-editable-availability>
        </div>
      </div>
    </v-col> -->
  </v-row>
</template>
<script>
import aChmEditableAvailability from "@/components/channel-manager/aChmEditableAvailability.vue";
import Vue from "vue";
export default {
  components: { aChmEditableAvailability },
  props: {
    rowData: Object,
    rowIndex: Number
  },
  data: () => ({
    current_slide: 0
  }),
  methods: {
    isWeekend(day) {
      return Vue.moment(day).isoWeekday() >= 5;
    },
    reportLocation(event) {
      this.$store.dispatch("setHoveredRate", event);
    }
  },
  watch: {
    slide_status(newValue, oldValue) {
      if (newValue === 2) {
        this.current_slide = this.page;
      }
    }
  },
  computed: {
    slide_status() {
      return this.$store.state.channelmanager.slide_status;
    },
    getName() {
      return this.rowData && this.rowData.isRoom ? this.rowData.room_name : "";
    },
    getInfo() {
      if (this.rowData) {
        let { days, ...info } = this.rowData;
        return info;
      }
      return {};
    },
    getDays() {
      return this.rowData && Object.keys(this.rowData.days);
    },
    page() {
      return this.$store.state.channelmanager.page;
    },
    windowItems() {
      return this.$store.state.channelmanager.windowItems;
    }
  }
};
</script>
<style lang="scss" scoped>
$labelFontWeight: 700;
$labelFontSize: 0.8rem;
$labelColor: #555;
$bgColorHotel: #ddd;
.row-availability {
  background-color: $bgColorRoom;
  &:hover {
    .label-wrapper {
      border-right: 3px solid $highlightColRowColor;
    }
  }
  .cell-availability {
    height: 44px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }
}
.channel-manager-rooms-labels {
  max-width: $widthLeftSide;
  .hotel-wrapper {
    .label-wrapper {
      //background-color: $bgColorHotel;
      border-top: 0px solid;
    }
  }
  //   .room-wrapper {
  //     .label-wrapper {
  //       background-color: $bgColorRoom;
  //     }
  //   }
  // .rate-availability-wrapper {
  //   background-color: $bgColorRoom;
  // }
  .rate-wrapper,
  .rate-availability-wrapper {
    .label-wrapper {
      background-color: #fff;
      &:hover,
      &.highlighted {
        border-right: 3px solid $highlightColRowColor;
      }
    }
    .master-rate {
      cursor: pointer;
    }
    cursor: default !important;
  }
  .label-wrapper {
    border-top: $borderType $borderColor;
    border-bottom: $borderType $borderColor;
    height: $minHeightColRow;
    border-right: 3px solid transparent;
    padding-left: 10px;
    width: $widthLeftSide;
    font-size: $labelFontSize;
    font-weight: $labelFontWeight;
    color: $labelColor;
    justify-content: space-between;
  }
  .relative {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.bg-weekend {
  background-color: $weekendBgColor;
}
::v-deep .v-window__container {
  height: $minHeightColRow;
}
</style>
