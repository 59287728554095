<template>
  <a-page
    id="metrics-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-select
        v-model="import_task_id"
        :items="available_imports"
        item-text="import_datetime"
        item-value="import_task_id"
        mandatory
        rounded
        outlined
        dense
        class="mt-8"
        style="max-width: 300px;"
      ></v-select>
      <div class="pt-2 mx-5">vs</div>
      <v-select
        v-model="vs_import_task_id"
        :items="available_imports"
        item-text="import_datetime"
        item-value="import_task_id"
        mandatory
        rounded
        outlined
        dense
        class="mt-8"
        style="max-width: 300px;"
      ></v-select>
    </template>
    <!-- METRICS -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-app-bar flat color="white">
            <v-toolbar-title class="title font-weight-bold">
              {{ $t("booking_rates") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-card-text>
            <v-row>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-count')"
                  :vs="vs_metric('rate-booking-urls-count')"
                  :warning_less_than="vs_metric('rate-booking-urls-count')"
                ></a-metric>
                <a-label>Peticiones</a-label>
              </v-col>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-ok')"
                  :vs="vs_metric('rate-booking-urls-ok')"
                  :warning_less_than="vs_metric('rate-booking-urls-ok')"
                ></a-metric>
                <a-label>Peticiones correctas</a-label>
              </v-col>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-retry')"
                  :vs="vs_metric('rate-booking-urls-retry')"
                  :warning_greater_than="vs_metric('rate-booking-urls-retry')"
                ></a-metric>
                <a-label>Peticiones reintentadas</a-label>
              </v-col>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-discard')"
                  :vs="vs_metric('rate-booking-urls-discard')"
                  :warning_greater_than="vs_metric('rate-booking-urls-discard')"
                ></a-metric>
                <a-label>Peticiones descartadas</a-label>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-desktop')"
                  :vs="vs_metric('rate-booking-urls-desktop')"
                  :warning_less_than="vs_metric('rate-booking-urls-desktop')"
                ></a-metric>
                <a-label>Peticiones en desktop</a-label>
              </v-col>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-mobile')"
                  :vs="vs_metric('rate-booking-urls-mobile')"
                  :warning_less_than="vs_metric('rate-booking-urls-mobile')"
                ></a-metric>
                <a-label>Peticiones en mobile</a-label>
              </v-col>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-genius')"
                  :vs="vs_metric('rate-booking-urls-genius')"
                  :warning_less_than="
                    vs_metric('rate-booking-urls-genius-desktop')
                  "
                ></a-metric>
                <a-label>Peticiones en Genius Desktop</a-label>
              </v-col>
              <v-col class="text-center">
                <a-metric
                  :value="metric('rate-booking-urls-genius')"
                  :vs="vs_metric('rate-booking-urls-genius')"
                  :warning_less_than="
                    vs_metric('rate-booking-urls-genius-mobile')
                  "
                ></a-metric>
                <a-label>Peticiones en Genius Mobile</a-label>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!--      <v-col cols="12">-->
      <!--        <v-card>-->
      <!--          <v-app-bar flat color="white">-->
      <!--            <v-toolbar-title class="title font-weight-bold">-->
      <!--              {{ $t("booking_reputation") }}-->
      <!--            </v-toolbar-title>-->
      <!--            <v-spacer></v-spacer>-->
      <!--          </v-app-bar>-->
      <!--          <v-card-text>-->
      <!--            <v-row>-->
      <!--              <v-col class="text-center">-->
      <!--                <a-metric-->
      <!--                  :value="metric('reputation-booking-urls-count')"-->
      <!--                  :vs="vs_metric('reputation-booking-urls-count')"-->
      <!--                  :warning_less_than="-->
      <!--                    vs_metric('reputation-booking-urls-count')-->
      <!--                  "-->
      <!--                ></a-metric>-->
      <!--                <a-label>Peticiones</a-label>-->
      <!--              </v-col>-->
      <!--              <v-col class="text-center">-->
      <!--                <a-metric-->
      <!--                  :value="metric('reputation-booking-urls-count')"-->
      <!--                ></a-metric>-->
      <!--                <a-label>tiempo medio petición</a-label>-->
      <!--              </v-col>-->
      <!--              <v-col class="text-center">-->
      <!--                <a-metric-->
      <!--                  :value="metric('reputation-booking-urls-proxy-count')"-->
      <!--                  :warning_greater_than="0"-->
      <!--                ></a-metric>-->
      <!--                <a-label>Peticiones usando Proxy</a-label>-->
      <!--              </v-col>-->
      <!--              <v-col class="text-center">-->
      <!--                <a-metric-->
      <!--                  :value="metric('reputation-booking-urls-proxy-time')"-->
      <!--                  :warning_greater_than="0"-->
      <!--                ></a-metric>-->
      <!--                <a-label>Tiempo medio Petción con Proxy</a-label>-->
      <!--              </v-col>-->
      <!--              <v-col class="text-center">-->
      <!--                <a-metric-->
      <!--                  :value="metric('reputation-booking-reviews-count')"-->
      <!--                  :warning_less_than="1"-->
      <!--                ></a-metric>-->
      <!--                <a-label>Reviews importadas</a-label>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-card-text>-->
      <!--        </v-card>-->
      <!--      </v-col>-->
    </v-row>
  </a-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import aLabel from "@/components/aLabel";
import aMetric from "@/components/aMetric";
import * as Utils from "@/utils";

export default {
  components: {
    aPage,
    aLabel,
    aMetric
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 140 //Mandatory number
  }),
  methods: {
    metric(code) {
      return this.metrics && this.metrics.find(m => m.code === code)
        ? this.metrics.find(m => m.code === code).value
        : 0;
    },
    vs_metric(code) {
      return this.vs_metrics && this.vs_metrics.find(m => m.code === code)
        ? this.vs_metrics.find(m => m.code === code).value
        : 0;
    }
  },
  computed: {
    import_task_id: {
      get() {
        return this.metrics_store.import_task_id;
      },
      set(value) {
        this.$store.dispatch("setMetricsImportTaskId", value);
      }
    },
    vs_import_task_id: {
      get() {
        return this.metrics_store.vs_import_task_id;
      },
      set(value) {
        this.$store.dispatch("setMetricsVsImportTaskId", value);
      }
    },
    ready() {
      return this.current_hotel && this.current_hotel.id;
    },
    metrics() {
      return this.metrics_store.metrics;
    },
    vs_metrics() {
      return this.metrics_store.vs_metrics;
    },
    ...mapState({
      snackbar: state => state.snackbar,
      metrics_store: state => state.metrics
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      available_imports: "getMetricsAvailableImports"
    })
  },
  watch: {
    import_task_id(v) {
      if (v) {
        this.$store.dispatch("fetchMetrics");
      }
    },
    vs_import_task_id(v) {
      if (v) {
        this.$store.dispatch("fetchVsMetrics");
      }
    },
    available_imports(v) {
      if (!this.import_task_id) {
        this.import_task_id = v[0].import_task_id;
      }
      if (!this.vs_import_task_id) {
        this.vs_import_task_id = v[v.length === 1 ? 0 : 1].import_task_id;
      }
    },
    ready(v) {
      if (v) {
        this.$store.dispatch("fetchAvailableImports").catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
      }
    }
  },
  mounted() {}
};
</script>
<style>
.hotel_selected {
  background-color: #0084c8 !important;
}
</style>
