<template>
  <div v-if="forecast">
    <v-icon x-small>{{ icon }}</v-icon>
    <span style="color: grey; font-size: 10px;"
      >{{ temp_min }}° / {{ temp_max }}°</span
    >
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-card-kpi",
  props: {
    date: {
      type: String,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    forecast() {
      return this.weather && this.weather[this.date]
        ? this.weather[this.date]
        : null;
    },
    icon() {
      const weatherIcons = {
        "Blowing Snow": "mdi-weather-snowy-heavy",
        Breezy: "mdi-weather-windy",
        clear: "mdi-weather-sunny",
        Cloudy: "mdi-weather-cloudy",
        humid: "mdi-weather-rainy",
        ishower: "mdi-weather-partly-rainy",
        lightrain: "mdi-weather-rainy",
        lightsnow: "mdi-weather-snowy",
        mcloudy: "mdi-weather-partly-cloudy",
        "Mostly Cloudy": "mdi-weather-cloudy",
        "Mostly Sunny": "mdi-weather-partly-cloudy",
        oshower: "mdi-weather-rainy",
        "Partly Cloudy": "mdi-weather-partly-cloudy",
        pcloudy: "mdi-weather-partly-cloudy",
        Rain: "mdi-weather-pouring",
        "Rain And Snow": "mdi-weather-snowy-rainy",
        rainsnow: "mdi-weather-snowy-rainy",
        "Scattered Showers": "mdi-weather-lightning-rainy",
        "Scattered Thunderstorms": "mdi-weather-lightning-rainy",
        Showers: "mdi-weather-rainy",
        snow: "mdi-weather-snowy",
        "Snow Showers": "mdi-weather-snowy",
        Sunny: "mdi-weather-sunny",
        Thunderstorms: "mdi-weather-lightning",
        ts: "mdi-weather-lightning",
        tsrain: "mdi-weather-lightning-rainy",
        Windy: "mdi-weather-windy"
      };

      return weatherIcons[this.forecast.weather] || "mdi-weather-cloudy";
    },
    temp_min() {
      return this.forecast ? this.forecast.min_temp : 0;
    },
    temp_max() {
      return this.forecast ? this.forecast.max_temp : 0;
    },
    ...mapState({
      weather: state => state.rms.weather_daily
    }),
    ...mapGetters({})
  }
};
</script>

<style lang="scss"></style>
