<template>
  <div class="a-rms-day-pickup-tooltip">
    <v-tooltip content-class="pickup-tooltip-content" bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on"><slot></slot></span>
      </template>
      <span class="text-center justify-center align-center pickup-tooltip">
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3 white text-subtitle-1"
        >
          {{ $t("pickup_" + this.spot) }} | {{ date }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :item-class="getDatatableRowClass"
          sort-by="rn"
          hide-default-footer
          disable-pagination
          dense
        >
          <template v-slot:[`item.intermediate_name`]="{ item }">
            {{ item.intermediate_name }}
          </template>
          <template v-slot:[`item.rn`]="{ item }">
            {{ item.rn }}
          </template>
          <template v-slot:[`item.room_revenue`]="{ item }">
            {{ item.room_revenue }}
            <span class="currency-symbol">€</span>
          </template>
        </v-data-table>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "a-rms-day-pickup-tooltip",
  props: {
    date: {
      type: String,
      mandatory: true
    },
    spot: {
      type: Number,
      mandatory: true
    }
  },
  computed: {
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "intermediate_name"
        },
        {
          text: this.$t("rn_dense"),
          align: "center",
          sortable: true,
          value: "rn"
        },
        {
          text: this.$t("room_revenue_dense"),
          align: "center",
          sortable: true,
          value: "room_revenue"
        }
      ];
    },
    items() {
      return this.all_kpis_daily_pickup && this.all_kpis_daily_pickup[this.date]
        ? this.all_kpis_daily_pickup[this.date][this.spot]
        : [];
    },
    ...mapState({
      all_kpis_daily_pickup: state => state.rms.all_kpis_daily_pickup
    })
  },
  methods: {
    getDatatableRowClass(item) {
      let classes = "a-datatable-row";
      return classes;
    }
  }
};
</script>

<style lang="scss">
.pickup-tooltip-content {
  background-color: white;
  border: 1px solid #000;
  border-radius: 0;
  padding: 0 !important;
  opacity: 1 !important;
  .v-card__title {
    padding: 5px 14px !important;
  }
  .v-data-table {
    border-radius: 0 !important;
    .v-data-table__wrapper {
      padding: 0 12px !important;
      .a-datatable-row {
        td {
          padding: 5px !important;
          &:first-child {
            color: inherit !important;
          }
          .currency-symbol {
            margin-left: -5px;
          }
          .v-btn--fab.v-size--x-small {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
