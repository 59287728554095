<template>
  <div id="rms-year-sales">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-card class="mt-5 mb-2" :title="$t('Accumulative')">
          <v-list nav class="mb-0 pb-0">
            <v-list-item-group
              v-model="selectedKpi"
              color="secondary"
              class="d-flex"
            >
              <v-list-item
                class="mx-2 text-center mb-0 "
                v-for="(item, i) in kpis"
                :key="i"
              >
                <v-list-item-content class="ml-0 pr-3">
                  <a-label class="mb-0">
                    {{ $t(item.label) }}
                  </a-label>
                  <div class="mb-3">
                    <a-period-compare
                      :periods="[year_period, 'stly']"
                    ></a-period-compare>
                  </div>
                  <a-kpi
                    v-if="rms.dashboard_annual_kpis"
                    :kpi="item.kpi"
                    :value="rms.dashboard_annual_kpis['otb'][item.kpi]"
                    :vs_values="[rms.dashboard_annual_kpis.stly[item.kpi]]"
                    :vs_periods="['stly']"
                    large
                  ></a-kpi>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <template v-if="kpi_graph === 'room_revenue'">
            <v-row>
              <v-col cols="12">
                <a-hotelsdot-year-sales-roomrevenue-monthly-chart
                  :series="year_sales_monthly_graph.kpis.room_revenue"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-roomrevenue-monthly-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph === 'adr'">
            <v-row>
              <v-col cols="12">
                <a-hotelsdot-year-sales-adr-monthly-chart
                  :series="year_sales_monthly_graph.kpis.adr"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-adr-monthly-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph === 'rn'">
            <v-row>
              <v-col cols="12">
                <a-hotelsdot-year-sales-rn-monthly-chart
                  :series="year_sales_monthly_graph.kpis.rn"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-rn-monthly-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph === 'occupancy'">
            <v-row>
              <v-col cols="12">
                <a-hotelsdot-year-sales-occupancy-monthly-chart
                  :series="year_sales_monthly_graph.kpis.occupancy"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-occupancy-monthly-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph === 'revpar'">
            <v-row>
              <v-col cols="12">
                <a-hotelsdot-year-sales-revpar-monthly-chart
                  :series="year_sales_monthly_graph.kpis.revpar"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-revpar-monthly-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph === 'intermediation'">
            <v-row>
              <v-col cols="12">
                <a-hotelsdot-year-sales-intermediation-monthly-chart
                  :series="year_sales_monthly_graph.kpis.intermediation"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-intermediation-monthly-chart>
              </v-col>
            </v-row>
          </template>
        </a-card>
        <a-card :title="$t('full_year')" class="mb-2">
          <v-row>
            <v-col cols="12">
              <a-rms-year-sales-table
                :rows="year_sales_table"
              ></a-rms-year-sales-table>
            </v-col>
          </v-row>
        </a-card>
        <v-card color="grey lighten-4 mb-2 mt-0">
          <v-card-text class="white px-0 pt-0 pb-6">
            <v-row>
              <v-col cols="12" class="">
                <v-toolbar-title
                  class="px-3 font-weight-bold text-uppercase subtitle-2 mr-2 d-flex align-center"
                >
                  <span>{{ $t("year_evolution") }}</span>
                  &nbsp;
                  <span class="pl-1 secondary--text"> {{ year }} </span>
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col cols="3" class="offset-3 text-center">
                <a-label class="mb-0">
                  {{ $t("occupancy") }}
                </a-label>
                <div class="mb-3">
                  <a-period-compare
                    :periods="['otb', 'stly']"
                  ></a-period-compare>
                </div>
                <a-kpi
                  kpi="occupancy"
                  :value="yearValues.otb.occupancy"
                  :vs_values="[yearValues.stly.occupancy]"
                  :vs_periods="['stly']"
                  large
                ></a-kpi>
              </v-col>
              <v-col cols="3" class="text-center">
                <a-label class="mb-0">
                  {{ $t("adr") }}
                </a-label>
                <div class="mb-3">
                  <a-period-compare
                    :periods="['otb', 'stly']"
                  ></a-period-compare>
                </div>
                <a-kpi
                  kpi="adr"
                  :value="yearValues.otb.adr"
                  :vs_values="[yearValues.stly.adr]"
                  :vs_periods="['stly']"
                  large
                ></a-kpi>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col cols="12" class="col-xs-12 col-sm-12 col-md-6 pr-3">
                <a-year-sales-occupation-adr-chart
                  height="330px"
                  :id="'a-occupation-adr-chart-monthly'"
                  :series="monthsGraphData.kpis"
                  :labels="monthsGraphData.labels"
                  :totals="totals"
                ></a-year-sales-occupation-adr-chart>
              </v-col>
              <v-col cols="12" class="col-xs-12 col-sm-12 col-md-6 pr-3">
                <a-year-sales-occupation-adr-chart
                  height="330px"
                  :id="'a-occupation-adr-chart-quarterly'"
                  :series="quartersGraphData.kpis"
                  :labels="quartersGraphData.labels"
                  :totals="totals"
                ></a-year-sales-occupation-adr-chart>
              </v-col>
            </v-row> </v-card-text
        ></v-card>
      </template>
      <template v-slot:loading>
        <a-card>
          <v-row class="pt-5">
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
          </v-row>
        </a-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aCard from "@/components/aCard.vue";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aRmsYearSalesTable from "@/components/rms/hotelsdot/aRmsYearSalesTable.vue";
import aHotelsdotYearSalesRoomrevenueMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRoomrevenueMonthlyChart.vue";
import aHotelsdotYearSalesAdrMonthlyChart from "@/components/chart/hotelsdot/aYearSalesAdrMonthlyChart.vue";
import aHotelsdotYearSalesRnMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRnMonthlyChart.vue";
import aHotelsdotYearSalesOccupancyMonthlyChart from "@/components/chart/hotelsdot/aYearSalesOccupancyMonthlyChart.vue";
import aHotelsdotYearSalesRevparMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRevparMonthlyChart.vue";
import aHotelsdotYearSalesIntermediationMonthlyChart from "@/components/chart/hotelsdot/aYearSalesIntermediationMonthlyChart.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aLabel from "@/components/aLabel.vue";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aYearSalesOccupationAdrChart from "@/components/chart/aYearSalesOccupationAdrChart.vue";
import Vue from "vue";

export default {
  name: "rms-year-sales-hotelsdot-app",
  components: {
    aYearSalesOccupationAdrChart,
    aKpi,
    aLabel,
    aPeriodCompare,
    aCard,
    aBaseComponent,
    aRmsYearSalesTable,
    aHotelsdotYearSalesRoomrevenueMonthlyChart,
    aHotelsdotYearSalesAdrMonthlyChart,
    aHotelsdotYearSalesRnMonthlyChart,
    aHotelsdotYearSalesOccupancyMonthlyChart,
    aHotelsdotYearSalesRevparMonthlyChart,
    aHotelsdotYearSalesIntermediationMonthlyChart
  },
  data: () => ({
    subscribed_modules: [
      "rms_year_sales_monthly_graph",
      "rms_year_sales_period_graph",
      "rms_year_sales_monthly_grown_graph",
      "rms_year_sales_table",
      "rms_year_sales_period_table",
      "rms_dashboard_annual_kpis",
      "rms_kpis_year_sales_months",
      "rms_kpis_year_sales_quarters",
      "rms_kpis_year_sales_year"
    ],
    year_period: "ytd",
    selectedKpi: 0,
    kpis: [
      { id: "room_revenue", kpi: "room_revenue", label: "room_revenue" },
      { id: "adr", kpi: "adr", label: "adr" },
      { id: "rn", kpi: "rn", label: "rn" },
      { id: "occupancy", kpi: "occupancy", label: "occupancy" },
      { id: "revpar", kpi: "revpar", label: "revpar" }
    ]
  }),
  computed: {
    kpi_graph: {
      get() {
        return this.$store.state.rms.year_sales_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiGraph", value);
      }
    },
    monthsValues() {
      return this.rms.kpis_year_sales_months;
    },
    quartersValues() {
      const quarters = {
        q1: this.rms.kpis_year_sales_q1,
        q2: this.rms.kpis_year_sales_q2,
        q3: this.rms.kpis_year_sales_q3,
        q4: this.rms.kpis_year_sales_q4
      };
      return { ...quarters };
    },
    yearValues() {
      return this.rms.kpis_year_sales_year;
    },
    monthsGraphData: {
      get() {
        return this.rms.kpis_year_sales_months_graph;
      },
      set(value) {
        this.$store.commit("GET_DATASET", {
          dataset: "kpis_year_sales_months_graph",
          data: value
        });
      }
    },
    quartersGraphData: {
      get() {
        return this.rms.kpis_year_sales_quarters_graph;
      },
      set(value) {
        this.$store.commit("GET_DATASET", {
          dataset: "kpis_year_sales_quarters_graph",
          data: value
        });
      }
    },
    series_monthly() {
      return this.keys_monthly.reduce((acc, key) => {
        Object.keys(this.monthsValues[key].otb).forEach(kpi => {
          if (!acc[kpi]) {
            acc[kpi] = {
              otb: [],
              stly: []
            };
          }
          acc[kpi].otb.push(this.monthsValues[key].otb[kpi]);
          acc[kpi].stly.push(this.monthsValues[key].stly[kpi]);
        });
        return acc;
      }, {});
    },
    series_quarterly() {
      return this.labels_quarterly.reduce((acc, key) => {
        Object.keys(this.quartersValues[key].otb).forEach(kpi => {
          if (!acc[kpi]) {
            acc[kpi] = {
              otb: [],
              stly: []
            };
          }
          acc[kpi].otb.push(this.quartersValues[key].otb[kpi]);
          acc[kpi].stly.push(this.quartersValues[key].stly[kpi]);
        });
        return acc;
      }, {});
    },
    year: {
      get() {
        return this.$store.state.rms.year_sales_year;
      },
      set(value) {
        this.$store.commit("SET_YEAR_SALES_YEAR", value);
      }
    },
    totals() {
      return {
        occupancy: {
          otb: this.yearValues.otb.occupancy,
          stly: this.yearValues.stly.occupancy
        },
        adr: {
          otb: this.yearValues.otb.adr,
          stly: this.yearValues.stly.adr
        }
      };
    },
    keys_monthly() {
      return Object.keys(this.monthsValues);
    },
    labels_monthly() {
      return this.keys_monthly.map(key =>
        Vue.moment(`${key}-01`).format("MMM")
      );
    },
    labels_quarterly() {
      return Object.keys(this.quartersValues);
    },
    ...mapState({
      rms: state => state.rms,
      year_sales_monthly_graph: state => state.rms.year_sales_monthly_graph,
      year_sales_period_graph: state => state.rms.year_sales_period_graph,
      year_sales_monthly_grown_graph: state =>
        state.rms.year_sales_monthly_grown_graph,
      year_sales_table: state => state.rms.year_sales_table,
      year_sales_period_table: state => state.rms.year_sales_period_table
    })
  },
  watch: {
    selectedKpi() {
      this.kpi_graph = this.kpis[this.selectedKpi].id;
    },
    monthsValues() {
      this.monthsGraphData = {
        kpis: this.series_monthly,
        labels: this.labels_monthly
      };
    },
    quartersValues() {
      this.quartersGraphData = {
        kpis: this.series_quarterly,
        labels: this.labels_quarterly
      };
    }
  },
  created() {
    this.kpi_graph = "room_revenue";
    this.year = Vue.moment().format("YYYY") * 1;
  }
};
</script>

<style lang="scss" scoped>
.title-table {
  margin-left: 100px;
}
</style>
