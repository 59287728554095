<template>
  <v-row class="kpi-row mx-0" :class="classRow" @click="clickRow(rowData)">
    <v-col class="kpi-label-wrapper pa-0">
      <div class="kpi-label d-flex align-center">
        <template v-if="!rowData['expand']">
          <a-period-compare :periods="periods()" dense></a-period-compare>
        </template>
        <template v-if="rowData['expand']">
          <v-avatar size="10" class="mr-1" :color="row_color"></v-avatar>
          <b>{{ row_title }}</b>
        </template>
        <template v-if="hasChilds">
          <v-spacer></v-spacer>
          <template v-if="row_active">
            <v-btn fab x-small depressed>
              <v-icon small>mdi-chevron-up</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn fab x-small depressed>
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </template>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="(col, index) of columns"
          :key="index"
          class="pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center"
          @mouseover="kpi_hover = col.value"
          @mouseleave="kpi_hover = ''"
        >
          <template v-if="!rowData['expand']">
            <a-kpi-columns
              :kpi="col.value"
              :value="rowData[col.value]"
              :vs_values="vs_values(col)"
              :vs_periods="vs_periods()"
              :absolute="increment_mode === 'absolute'"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-else>
            <a-kpi :kpi="col.value" :value="rowData[col.value]" x_small></a-kpi>
          </template>
        </v-col>
        <v-col
          class="pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center"
          @mouseover="kpi_hover = 'adr_avg'"
          @mouseleave="kpi_hover = ''"
        >
          <template v-if="!rowData['expand']">
            <a-kpi-columns
              kpi="adr"
              :value="adr_avg"
              :vs_values="[adr_avg_vs]"
              :vs_periods="vs_periods()"
              :absolute="increment_mode === 'absolute'"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-else>
            <a-kpi :kpi="adr" :value="adr_avg" x_small></a-kpi>
          </template>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import * as Utils from "@/utils.js";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aKpiColumns from "@/components/rms_ui/aKpiColumns.vue";

export default {
  name: "a-rms-strategy-weeks-detail-kpis-pickups-row",
  components: {
    aKpiColumns,
    aKpi,
    aPeriodCompare
  },
  props: {
    columns: Array,
    rowData: Object,
    rowIndex: Number,
    increment_mode: {
      type: String,
      default: "percent"
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    row_active: false
  }),
  computed: {
    kpi_hover: {
      get() {
        return this.$store.state.rms.strategy_kpi_pickups_table_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsStrategyKpiPickupsTableHover", value);
      }
    },
    classRow() {
      let clas = "";
      if (this.rowData["expand"]) {
        clas += " kpi-row-child";
      } else {
        clas += " kpi-row-parent";
      }
      if (this.dense) {
        clas += " dense";
      }
      return clas;
    },
    row_title() {
      if (this.rowData["expand"] === "segment") {
        return Utils.getDimensionName(
          this.segments,
          this.rowData.spot.split(" - ")[1]
        );
      } else {
        return this.rowData.spot.split(" - ")[1];
      }
    },
    row_color() {
      if (this.rowData["expand"] === "segment") {
        return Utils.getDimensionColor(
          this.segments,
          this.rowData.spot.split(" - ")[1]
        );
      } else {
        return "grey";
      }
    },
    hasChilds() {
      if (
        this.rms.strategy_range_kpis_pickups_table.some(object => {
          if (object.spot.startsWith(this.rowData.spot + " - ")) return true;
        })
      ) {
        return true;
      }
      return false;
    },
    adr_avg() {
      return this.rowData["room_revenue"] && this.rowData["rn"]
        ? this.rowData["room_revenue"] / this.rowData["rn"]
        : null;
    },
    adr_avg_vs() {
      return this.rowData["room_revenue_vs"] && this.rowData["rn_vs"]
        ? this.rowData["room_revenue_vs"] / this.rowData["rn_vs"]
        : null;
    },
    ...mapState({
      rms: state => state.rms,
      segments: state => state.segmentschannels.segments
    })
  },
  methods: {
    vs_values(col) {
      if (col.value + "_vs" in this.rowData) {
        return [this.rowData[col.value + "_vs"]];
      }
      return [];
    },
    periods() {
      return [
        this.rowData["spot"],
        this.rowData["spot"].split("_")[0] +
          "_stly_" +
          this.rowData["spot"].split("_")[1]
      ];
    },
    vs_periods() {
      if (this.rowData["expand"]) {
        return [this.rowData["spot"]];
      } else {
        return [
          this.rowData["spot"].split("_")[0] +
            "_stly_" +
            this.rowData["spot"].split("_")[1] +
            "_dense"
        ];
      }
    },
    clickRow(v) {
      this.row_active = !this.row_active;
      this.$emit("clickRow", v, this.row_active);
    }
  }
};
</script>

<style lang="scss" scoped>
.kpi-row {
  &:hover {
    .kpi-label-wrapper {
      .kpi-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  &.kpi-row-parent {
    .kpi-label-wrapper {
      .kpi-label {
        cursor: pointer;
      }
    }
  }
  &.kpi-row-child {
    .kpi-label-wrapper {
      .kpi-label {
        padding-left: 40px;
      }
    }
  }
  &.dense {
    .kpi-label-wrapper {
      .kpi-label {
        min-height: 40px;
      }
    }
    .kpi-cell {
      height: 40px;
    }
  }
  .kpi-label-wrapper {
    min-width: 200px;
    max-width: 200px;
    .kpi-label {
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
      padding-right: 5px;
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
</style>
