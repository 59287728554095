<template>
  <v-tooltip color="white" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        :color="impact.color"
        size="16"
        v-bind="attrs"
        v-on="on"
        class="ml-1"
      >
        <span class="white--text" style="font-size:9px;font-weight: 400;">
          {{ impact.count }}
        </span>
      </v-avatar>
    </template>
    <div class="pt-2 pb-1 ">
      <h3
        class="black--text mb-2 text-center text-uppercase"
        v-html="label"
      ></h3>
      <div
        v-for="(event, index) in events"
        :key="index"
        class="mb-1 d-flex justify-center"
      >
        <v-chip small :color="event_color(event)" text-color="white">
          <v-icon x-small class="mr-1">
            {{ getEventTypeIcon(event.type) }}
          </v-icon>
          <b>{{ event.name }}</b>
        </v-chip>
      </div>
    </div>
  </v-tooltip>
</template>
<script>
import { getEventTypeIcon, getFriendlyDayStrategy } from "@/utils.js";
import { mapState } from "vuex";
export default {
  name: "a-rms-strategy-day-events",
  props: {
    date: String,
    impact: Object,
    events: Array
  },
  methods: {
    event_color(event) {
      return this.calendar_store.impacts.find(i => i.value === event.impact)
        .color;
    },
    getEventTypeIcon(type) {
      return getEventTypeIcon(type);
    }
  },
  computed: {
    label() {
      return getFriendlyDayStrategy(this.date, true);
    },
    ...mapState({
      calendar_store: state => state.calendar
    })
  }
};
</script>
