<template>
  <div
    id="rate-ranking-graph"
    class="d-flex rate-data-row"
    :class="{ opened: opened }"
  >
    <div class="item rate-labels semibold">
      <div class="simple_view mt-1" style="height: 46px">
        <v-icon class="mt-n2 mr-1" small>mdi-podium-silver</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">{{
          $t("ranking")
        }}</template>
        <v-btn class="float-right mr-1 mt-1" icon @click="opened = !opened">
          <v-icon v-if="opened">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <div class="simple_view mt-1" style="height: 46px" v-if="show_rate_web">
        <v-icon class="mt-n1" small>mdi-content-duplicate</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">{{
          $t("parity")
        }}</template>
      </div>
    </div>
    <div class="item days">
      <div class="rate-ranking-graph-months-wrapper">
        <div
          class="ranking-month grey lighten-5"
          v-for="(month, index) in graph_months"
          :key="'ranking_month_' + index"
          :style="'min-width:0;flex:' + month.number_of_days + ';'"
        >
          <span class="day-name grey--text darken-3--text py-1">{{
            getMonthName(month.month)
          }}</span>
          <div class="rate-ranking-month-min-price-avg mb-2" v-show="opened">
            <div class="d-flex justify-center white py-1">
              <v-icon
                color="grey darken-2"
                x-small
                class="ml-n1 mr-1"
                style="margin-top: -1px"
                >mdi-alpha-h-circle-outline</v-icon
              >
              <a-kpi
                :kpi="'min_price'"
                :value="month.min_price_avg ? month.min_price_avg : null"
                :increment="false"
              ></a-kpi>
            </div>
            <div class="d-flex justify-center white py-1">
              <v-icon
                color="grey darken-2"
                x-small
                class="ml-n1 mr-1"
                style="margin-top: -1px"
                >mdi-alpha-c-circle-outline</v-icon
              >
              <a-kpi
                :kpi="'min_price'"
                :value="
                  month.competitors_min_price_avg
                    ? month.competitors_min_price_avg
                    : null
                "
                :increment="false"
              ></a-kpi>
            </div>
          </div>
        </div>
      </div>
      <div class="rate-ranking-graph-wrapper">
        <div
          class="ranking-day grey lighten-5"
          v-for="(day, index) in graph_values"
          :key="'ranking_day_' + day.date"
          @click="selectDay(day.date)"
        >
          <v-tooltip bottom content-class="a-rate-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="ranking-day-wrapper d-flex align-end"
                :class="
                  'ranking-day-data-in-page-' +
                    getPageFromDay(index) +
                    ' ranking-day-data-in-block-' +
                    getBlockFromDay(index)
                "
              >
                <div class="ranking-day-data" :class="day.ranking_class"></div>
              </div>
            </template>
            <span>
              <b class="title text-center" v-if="day.ranking > 0"
                >{{ day.ranking }}º</b
              >
              <b class="title text-center" v-else
                >- {{ $t("no_available_rooms") }} -</b
              >
              <br />
              {{ day.date | formatDateFull }}
            </span>
          </v-tooltip>
          <v-tooltip bottom content-class="a-rate-tooltip" v-if="show_rate_web">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                style="border-bottom: 0 !important"
                class="ranking-day-wrapper d-flex align-start"
                :class="
                  'ranking-day-data-in-page-' +
                    getPageFromDay(index) +
                    ' ranking-day-data-in-block-' +
                    getBlockFromDay(index)
                "
              >
                <div
                  class="parity-day-data"
                  :style="parity_styles[day.parity_code]"
                ></div>
              </div>
            </template>
            <span>
              <b
                class="title text-center"
                v-if="day.parity_code != null"
                :style="
                  parity_styles[day.parity_code] +
                    'color:white; border-radius:15px; padding: 2px 5px;'
                "
                v-html="format_kpi_value(day.parity - 100)"
              ></b>
              <b class="title text-center" v-else
                >- {{ $t("no_available_rooms") }} -</b
              >
              <br />
              {{ day.date | formatDateFull }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
var numeral = require("numeral");
import * as Utils from "@/utils.js";

export default {
  name: "rate-ranking-graph",
  components: {
    aKpi
  },
  props: [
    "days",
    "total_days",
    "days_per_screen",
    "rendered_days",
    "days_to_render"
  ],
  data: vm => ({
    opened: false
  }),
  methods: {
    format_kpi_value(v) {
      return Utils.format_kpi_value(v, "percent", this.l);
    },
    getPageFromDay(index) {
      var index_in_block = index % this.total_days;
      var page = parseInt(index_in_block / this.days_per_screen);
      return page;
    },
    getBlockFromDay(index) {
      var block = parseInt(index / this.total_days);
      return block;
    },
    selectDay(date) {
      this.$emit("goToDay", date);
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    isRenderedDay(date) {
      return this.days_to_render.indexOf(date) >= 0;
    },
    getMonthName(month_number) {
      const year = Vue.moment().format("Y");
      // date in YYYY-MM-DD
      const date =
        year +
        "-" +
        (String(month_number).length === 1
          ? "0" + month_number
          : month_number) +
        "-01";
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("MMMM")
        : this.$vuetify.breakpoint.xsOnly
        ? Vue.moment(date)
            .format("MMM")
            .substring(0, 1)
        : Vue.moment(date).format("MMM");
    },
    getMonthShortName(month_number) {
      const year = Vue.moment().format("Y");
      const date =
        year +
        "-" +
        (String(month_number).length === 1
          ? "0" + month_number
          : month_number) +
        "-01";
      return Vue.moment(date).format("MMM");
    },
    calcRankingClassNumber(ranking) {
      switch (this.competitor_count) {
        case 1:
          // always green
          return 1;
        case 2:
          // green or red
          return ranking === 1 ? 1 : 10;
        case 3:
          // green, yellow or red
          return ranking === 1 ? 1 : ranking * 3;
        case 4:
          // green, blue, yellow or red
          return ranking === 1 ? 1 : ranking * 2;
        default:
          return Math.max(
            1,
            Math.floor((ranking * 11) / this.competitor_count)
          );
      }
    }
  },
  computed: {
    graph_months() {
      return this.daily_rates && this.daily_rates.extras
        ? this.daily_rates.extras.monthly_avgs
        : null;
    },
    report() {
      return this.daily_rates.report &&
        this.daily_rates.report[
          "competitor::" + this.getHotelFromCompetitors.id
        ]
        ? this.daily_rates.report[
            "competitor::" + this.getHotelFromCompetitors.id
          ]
        : undefined;
    },
    web_report() {
      return this.hotel_web &&
        this.daily_rates.report &&
        this.daily_rates.report["competitor::" + this.hotel_web.id]
        ? this.daily_rates.report["competitor::" + this.hotel_web.id]
        : undefined;
    },
    competitor_count() {
      return this.competitors.length;
    },
    graph_values() {
      var values = [];
      for (let day of this.days) {
        if (this.report) {
          values.push({
            date: day,
            ranking: this.report[day] ? this.report[day][0].ranking : 0,
            ranking_class:
              "ranking_" +
              (this.report[day]
                ? this.calcRankingClassNumber(this.report[day][0].ranking)
                : 0),
            web_price:
              this.hotel_web && this.web_report && this.web_report[day]
                ? this.web_report[day][0].min_price
                : null,
            parity_code:
              this.hotel_web &&
              this.web_report &&
              this.web_report[day] &&
              this.report[day]
                ? this.web_report[day][0].parity_code
                : null,
            parity:
              this.hotel_web &&
              this.web_report &&
              this.web_report[day] &&
              this.report[day]
                ? this.web_report[day][0].parity
                : null
          });
        } else {
          values.push({ date: day, ranking: null });
        }
      }
      return values;
    },
    show_rate_web() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rate_web &&
            this.current_hotel.permissions.rate_web
        : false;
    },
    date_hover: {
      get() {
        return this.$store.state.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    hotel_web() {
      return this.webs.length ? this.webs[0] : null;
    },

    ...mapState({
      competitors: state => state.rate.competitors,
      webs: state => state.parity.webs,
      daily_rates: state => state.rate.daily_rates,
      chain: state => state.chain.chain,
      parity_colors: state => state.parity_colors,
      parity_styles: state => state.parity_styles
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      l: "current_hotel_language",
      getHotelFromCompetitors: "getHotelFromCompetitors"
    })
  }
};
</script>
<style>
.rate-ranking-graph-months-wrapper {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  display: flex;
  width: 100%;
}
.rate-ranking-graph-months-wrapper .day-name {
  display: block;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#rate-ranking-graph.rate-data-row:hover .rate-labels {
  border-right: 3px solid #ffffff !important;
}
.day-ranking-graph {
  width: 100%;
}
.rate-ranking-graph-wrapper {
  height: 90px;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.ranking-day {
  flex: 1;
  margin: auto;
  height: 90px;
}

.rate-ranking-month-min-price-avg {
  line-height: 19px;
}

.ranking-day-wrapper:hover {
  background: #b1cdff !important;
  border-bottom: 3px solid #999999;
}
.ranking-day-wrapper:hover .ranking-day-data {
  background: mediumblue !important;
}
.ranking-day-wrapper {
  cursor: pointer;
  width: 100%;
  height: 50%;
  padding: 0 0;
  border-bottom: 3px solid #cacaca;
}
.ranking-day-data {
  flex: 1;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin-right: 1px;
}
.parity-day-data {
  flex: 1;
  cursor: pointer;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.ranking-day-data.ranking_1 {
  height: calc(100% - ((1 - 1) * (100% / 12)));
  background: #63a47c;
}
.ranking-day-data.ranking_2 {
  height: calc(100% - ((2 - 1) * (100% / 12)));
  background: #63a47c;
}
.ranking-day-data.ranking_3 {
  height: calc(100% - ((3 - 1) * (100% / 12)));
  background: #318b89;
}
.ranking-day-data.ranking_4 {
  height: calc(100% - ((4 - 1) * (100% / 12)));
  background: #318b89;
}
.ranking-day-data.ranking_5 {
  height: calc(100% - ((5 - 1) * (100% / 12)));
  background: #e69812;
}
.ranking-day-data.ranking_6 {
  height: calc(100% - ((6 - 1) * (100% / 12)));
  background: #e69812;
}
.ranking-day-data.ranking_7 {
  height: calc(100% - ((7 - 1) * (100% / 12)));
  background: #93344a;
}
.ranking-day-data.ranking_8 {
  height: calc(100% - ((8 - 1) * (100% / 12)));
  background: #93344a;
}
.ranking-day-data.ranking_9 {
  height: calc(100% - ((9 - 1) * (100% / 12)));
  background: #93344a;
}
.ranking-day-data.ranking_10 {
  height: calc(100% - ((10 - 1) * (100% / 12)));
  background: #93344a;
}
.ranking-day-data.ranking_11 {
  height: calc(100% - ((11 - 1) * (100% / 12)));
  background: #93344a;
}

/* PAGE */
#rate-days-wrapper.page_0.block_0
  .ranking-day-data-in-page-0.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_1.block_0
  .ranking-day-data-in-page-1.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_2.block_0
  .ranking-day-data-in-page-2.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_3.block_0
  .ranking-day-data-in-page-3.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_4.block_0
  .ranking-day-data-in-page-4.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_5.block_0
  .ranking-day-data-in-page-5.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_6.block_0
  .ranking-day-data-in-page-6.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_7.block_0
  .ranking-day-data-in-page-7.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_8.block_0
  .ranking-day-data-in-page-8.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_9.block_0
  .ranking-day-data-in-page-9.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_10.block_0
  .ranking-day-data-in-page-10.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_11.block_0
  .ranking-day-data-in-page-11.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_12.block_0
  .ranking-day-data-in-page-12.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_13.block_0
  .ranking-day-data-in-page-13.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_14.block_0
  .ranking-day-data-in-page-14.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_15.block_0
  .ranking-day-data-in-page-15.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_16.block_0
  .ranking-day-data-in-page-16.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_17.block_0
  .ranking-day-data-in-page-17.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_18.block_0
  .ranking-day-data-in-page-18.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_19.block_0
  .ranking-day-data-in-page-19.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_20.block_0
  .ranking-day-data-in-page-20.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_21.block_0
  .ranking-day-data-in-page-21.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_22.block_0
  .ranking-day-data-in-page-22.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_23.block_0
  .ranking-day-data-in-page-23.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_24.block_0
  .ranking-day-data-in-page-24.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_25.block_0
  .ranking-day-data-in-page-25.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_26.block_0
  .ranking-day-data-in-page-26.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_27.block_0
  .ranking-day-data-in-page-27.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_28.block_0
  .ranking-day-data-in-page-28.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_29.block_0
  .ranking-day-data-in-page-29.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_30.block_0
  .ranking-day-data-in-page-30.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_31.block_0
  .ranking-day-data-in-page-31.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_32.block_0
  .ranking-day-data-in-page-32.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_33.block_0
  .ranking-day-data-in-page-33.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_34.block_0
  .ranking-day-data-in-page-34.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_35.block_0
  .ranking-day-data-in-page-35.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_36.block_0
  .ranking-day-data-in-page-36.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_37.block_0
  .ranking-day-data-in-page-37.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_38.block_0
  .ranking-day-data-in-page-38.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_39.block_0
  .ranking-day-data-in-page-39.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_40.block_0
  .ranking-day-data-in-page-40.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_41.block_0
  .ranking-day-data-in-page-41.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_42.block_0
  .ranking-day-data-in-page-42.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_43.block_0
  .ranking-day-data-in-page-43.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_44.block_0
  .ranking-day-data-in-page-44.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_45.block_0
  .ranking-day-data-in-page-45.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_46.block_0
  .ranking-day-data-in-page-46.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_47.block_0
  .ranking-day-data-in-page-47.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_48.block_0
  .ranking-day-data-in-page-48.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_49.block_0
  .ranking-day-data-in-page-49.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_50.block_0
  .ranking-day-data-in-page-50.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_51.block_0
  .ranking-day-data-in-page-51.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_52.block_0
  .ranking-day-data-in-page-52.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_53.block_0
  .ranking-day-data-in-page-53.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_54.block_0
  .ranking-day-data-in-page-54.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_55.block_0
  .ranking-day-data-in-page-55.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_56.block_0
  .ranking-day-data-in-page-56.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_57.block_0
  .ranking-day-data-in-page-57.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_58.block_0
  .ranking-day-data-in-page-58.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_59.block_0
  .ranking-day-data-in-page-59.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_60.block_0
  .ranking-day-data-in-page-60.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_61.block_0
  .ranking-day-data-in-page-61.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_62.block_0
  .ranking-day-data-in-page-62.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_63.block_0
  .ranking-day-data-in-page-63.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_64.block_0
  .ranking-day-data-in-page-64.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_65.block_0
  .ranking-day-data-in-page-65.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_66.block_0
  .ranking-day-data-in-page-66.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_67.block_0
  .ranking-day-data-in-page-67.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_68.block_0
  .ranking-day-data-in-page-68.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_69.block_0
  .ranking-day-data-in-page-69.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_70.block_0
  .ranking-day-data-in-page-70.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_71.block_0
  .ranking-day-data-in-page-71.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_72.block_0
  .ranking-day-data-in-page-72.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_73.block_0
  .ranking-day-data-in-page-73.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_74.block_0
  .ranking-day-data-in-page-74.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_75.block_0
  .ranking-day-data-in-page-75.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_76.block_0
  .ranking-day-data-in-page-76.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_77.block_0
  .ranking-day-data-in-page-77.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_78.block_0
  .ranking-day-data-in-page-78.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_79.block_0
  .ranking-day-data-in-page-79.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_80.block_0
  .ranking-day-data-in-page-80.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_81.block_0
  .ranking-day-data-in-page-81.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_82.block_0
  .ranking-day-data-in-page-82.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_83.block_0
  .ranking-day-data-in-page-83.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_84.block_0
  .ranking-day-data-in-page-84.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_85.block_0
  .ranking-day-data-in-page-85.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_86.block_0
  .ranking-day-data-in-page-86.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_87.block_0
  .ranking-day-data-in-page-87.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_88.block_0
  .ranking-day-data-in-page-88.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_89.block_0
  .ranking-day-data-in-page-89.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_90.block_0
  .ranking-day-data-in-page-90.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_91.block_0
  .ranking-day-data-in-page-91.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_92.block_0
  .ranking-day-data-in-page-92.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_93.block_0
  .ranking-day-data-in-page-93.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_94.block_0
  .ranking-day-data-in-page-94.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_95.block_0
  .ranking-day-data-in-page-95.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_96.block_0
  .ranking-day-data-in-page-96.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_97.block_0
  .ranking-day-data-in-page-97.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_98.block_0
  .ranking-day-data-in-page-98.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_99.block_0
  .ranking-day-data-in-page-99.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_100.block_0
  .ranking-day-data-in-page-100.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_101.block_0
  .ranking-day-data-in-page-101.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_102.block_0
  .ranking-day-data-in-page-102.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_103.block_0
  .ranking-day-data-in-page-103.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_104.block_0
  .ranking-day-data-in-page-104.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_105.block_0
  .ranking-day-data-in-page-105.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_106.block_0
  .ranking-day-data-in-page-106.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_107.block_0
  .ranking-day-data-in-page-107.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_108.block_0
  .ranking-day-data-in-page-108.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_109.block_0
  .ranking-day-data-in-page-109.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_110.block_0
  .ranking-day-data-in-page-110.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_111.block_0
  .ranking-day-data-in-page-111.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_112.block_0
  .ranking-day-data-in-page-112.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_113.block_0
  .ranking-day-data-in-page-113.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_114.block_0
  .ranking-day-data-in-page-114.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_115.block_0
  .ranking-day-data-in-page-115.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_116.block_0
  .ranking-day-data-in-page-116.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_117.block_0
  .ranking-day-data-in-page-117.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_118.block_0
  .ranking-day-data-in-page-118.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_119.block_0
  .ranking-day-data-in-page-119.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_120.block_0
  .ranking-day-data-in-page-120.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_121.block_0
  .ranking-day-data-in-page-121.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_122.block_0
  .ranking-day-data-in-page-122.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_123.block_0
  .ranking-day-data-in-page-123.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_124.block_0
  .ranking-day-data-in-page-124.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_125.block_0
  .ranking-day-data-in-page-125.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_126.block_0
  .ranking-day-data-in-page-126.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_127.block_0
  .ranking-day-data-in-page-127.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_128.block_0
  .ranking-day-data-in-page-128.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_129.block_0
  .ranking-day-data-in-page-129.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_130.block_0
  .ranking-day-data-in-page-130.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_131.block_0
  .ranking-day-data-in-page-131.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_132.block_0
  .ranking-day-data-in-page-132.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_133.block_0
  .ranking-day-data-in-page-133.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_134.block_0
  .ranking-day-data-in-page-134.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_135.block_0
  .ranking-day-data-in-page-135.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_136.block_0
  .ranking-day-data-in-page-136.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_137.block_0
  .ranking-day-data-in-page-137.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_138.block_0
  .ranking-day-data-in-page-138.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_139.block_0
  .ranking-day-data-in-page-139.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_140.block_0
  .ranking-day-data-in-page-140.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_141.block_0
  .ranking-day-data-in-page-141.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_142.block_0
  .ranking-day-data-in-page-142.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_143.block_0
  .ranking-day-data-in-page-143.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_144.block_0
  .ranking-day-data-in-page-144.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_145.block_0
  .ranking-day-data-in-page-145.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_146.block_0
  .ranking-day-data-in-page-146.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_147.block_0
  .ranking-day-data-in-page-147.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_148.block_0
  .ranking-day-data-in-page-148.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_149.block_0
  .ranking-day-data-in-page-149.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_150.block_0
  .ranking-day-data-in-page-150.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_151.block_0
  .ranking-day-data-in-page-151.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_152.block_0
  .ranking-day-data-in-page-152.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_153.block_0
  .ranking-day-data-in-page-153.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_154.block_0
  .ranking-day-data-in-page-154.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_155.block_0
  .ranking-day-data-in-page-155.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_156.block_0
  .ranking-day-data-in-page-156.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_157.block_0
  .ranking-day-data-in-page-157.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_158.block_0
  .ranking-day-data-in-page-158.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_159.block_0
  .ranking-day-data-in-page-159.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_160.block_0
  .ranking-day-data-in-page-160.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_161.block_0
  .ranking-day-data-in-page-161.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_162.block_0
  .ranking-day-data-in-page-162.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_163.block_0
  .ranking-day-data-in-page-163.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_164.block_0
  .ranking-day-data-in-page-164.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_165.block_0
  .ranking-day-data-in-page-165.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_166.block_0
  .ranking-day-data-in-page-166.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_167.block_0
  .ranking-day-data-in-page-167.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_168.block_0
  .ranking-day-data-in-page-168.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_169.block_0
  .ranking-day-data-in-page-169.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_170.block_0
  .ranking-day-data-in-page-170.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_171.block_0
  .ranking-day-data-in-page-171.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_172.block_0
  .ranking-day-data-in-page-172.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_173.block_0
  .ranking-day-data-in-page-173.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_174.block_0
  .ranking-day-data-in-page-174.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_175.block_0
  .ranking-day-data-in-page-175.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_176.block_0
  .ranking-day-data-in-page-176.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_177.block_0
  .ranking-day-data-in-page-177.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_178.block_0
  .ranking-day-data-in-page-178.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_179.block_0
  .ranking-day-data-in-page-179.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_180.block_0
  .ranking-day-data-in-page-180.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_181.block_0
  .ranking-day-data-in-page-181.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_182.block_0
  .ranking-day-data-in-page-182.ranking-day-data-in-block-0 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_0.block_1
  .ranking-day-data-in-page-0.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_1.block_1
  .ranking-day-data-in-page-1.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_2.block_1
  .ranking-day-data-in-page-2.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_3.block_1
  .ranking-day-data-in-page-3.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_4.block_1
  .ranking-day-data-in-page-4.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_5.block_1
  .ranking-day-data-in-page-5.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_6.block_1
  .ranking-day-data-in-page-6.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_7.block_1
  .ranking-day-data-in-page-7.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_8.block_1
  .ranking-day-data-in-page-8.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_9.block_1
  .ranking-day-data-in-page-9.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_10.block_1
  .ranking-day-data-in-page-10.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_11.block_1
  .ranking-day-data-in-page-11.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_12.block_1
  .ranking-day-data-in-page-12.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_13.block_1
  .ranking-day-data-in-page-13.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_14.block_1
  .ranking-day-data-in-page-14.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_15.block_1
  .ranking-day-data-in-page-15.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_16.block_1
  .ranking-day-data-in-page-16.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_17.block_1
  .ranking-day-data-in-page-17.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_18.block_1
  .ranking-day-data-in-page-18.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_19.block_1
  .ranking-day-data-in-page-19.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_20.block_1
  .ranking-day-data-in-page-20.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_21.block_1
  .ranking-day-data-in-page-21.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_22.block_1
  .ranking-day-data-in-page-22.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_23.block_1
  .ranking-day-data-in-page-23.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_24.block_1
  .ranking-day-data-in-page-24.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_25.block_1
  .ranking-day-data-in-page-25.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_26.block_1
  .ranking-day-data-in-page-26.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_27.block_1
  .ranking-day-data-in-page-27.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_28.block_1
  .ranking-day-data-in-page-28.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_29.block_1
  .ranking-day-data-in-page-29.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_30.block_1
  .ranking-day-data-in-page-30.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_31.block_1
  .ranking-day-data-in-page-31.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_32.block_1
  .ranking-day-data-in-page-32.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_33.block_1
  .ranking-day-data-in-page-33.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_34.block_1
  .ranking-day-data-in-page-34.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_35.block_1
  .ranking-day-data-in-page-35.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_36.block_1
  .ranking-day-data-in-page-36.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_37.block_1
  .ranking-day-data-in-page-37.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_38.block_1
  .ranking-day-data-in-page-38.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_39.block_1
  .ranking-day-data-in-page-39.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_40.block_1
  .ranking-day-data-in-page-40.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_41.block_1
  .ranking-day-data-in-page-41.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_42.block_1
  .ranking-day-data-in-page-42.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_43.block_1
  .ranking-day-data-in-page-43.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_44.block_1
  .ranking-day-data-in-page-44.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_45.block_1
  .ranking-day-data-in-page-45.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_46.block_1
  .ranking-day-data-in-page-46.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_47.block_1
  .ranking-day-data-in-page-47.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_48.block_1
  .ranking-day-data-in-page-48.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_49.block_1
  .ranking-day-data-in-page-49.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_50.block_1
  .ranking-day-data-in-page-50.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_51.block_1
  .ranking-day-data-in-page-51.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_52.block_1
  .ranking-day-data-in-page-52.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_53.block_1
  .ranking-day-data-in-page-53.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_54.block_1
  .ranking-day-data-in-page-54.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_55.block_1
  .ranking-day-data-in-page-55.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_56.block_1
  .ranking-day-data-in-page-56.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_57.block_1
  .ranking-day-data-in-page-57.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_58.block_1
  .ranking-day-data-in-page-58.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_59.block_1
  .ranking-day-data-in-page-59.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_60.block_1
  .ranking-day-data-in-page-60.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_61.block_1
  .ranking-day-data-in-page-61.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_62.block_1
  .ranking-day-data-in-page-62.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_63.block_1
  .ranking-day-data-in-page-63.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_64.block_1
  .ranking-day-data-in-page-64.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_65.block_1
  .ranking-day-data-in-page-65.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_66.block_1
  .ranking-day-data-in-page-66.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_67.block_1
  .ranking-day-data-in-page-67.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_68.block_1
  .ranking-day-data-in-page-68.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_69.block_1
  .ranking-day-data-in-page-69.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_70.block_1
  .ranking-day-data-in-page-70.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_71.block_1
  .ranking-day-data-in-page-71.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_72.block_1
  .ranking-day-data-in-page-72.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_73.block_1
  .ranking-day-data-in-page-73.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_74.block_1
  .ranking-day-data-in-page-74.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_75.block_1
  .ranking-day-data-in-page-75.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_76.block_1
  .ranking-day-data-in-page-76.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_77.block_1
  .ranking-day-data-in-page-77.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_78.block_1
  .ranking-day-data-in-page-78.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_79.block_1
  .ranking-day-data-in-page-79.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_80.block_1
  .ranking-day-data-in-page-80.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_81.block_1
  .ranking-day-data-in-page-81.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_82.block_1
  .ranking-day-data-in-page-82.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_83.block_1
  .ranking-day-data-in-page-83.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_84.block_1
  .ranking-day-data-in-page-84.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_85.block_1
  .ranking-day-data-in-page-85.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_86.block_1
  .ranking-day-data-in-page-86.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_87.block_1
  .ranking-day-data-in-page-87.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_88.block_1
  .ranking-day-data-in-page-88.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_89.block_1
  .ranking-day-data-in-page-89.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_90.block_1
  .ranking-day-data-in-page-90.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_91.block_1
  .ranking-day-data-in-page-91.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_92.block_1
  .ranking-day-data-in-page-92.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_93.block_1
  .ranking-day-data-in-page-93.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_94.block_1
  .ranking-day-data-in-page-94.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_95.block_1
  .ranking-day-data-in-page-95.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_96.block_1
  .ranking-day-data-in-page-96.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_97.block_1
  .ranking-day-data-in-page-97.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_98.block_1
  .ranking-day-data-in-page-98.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_99.block_1
  .ranking-day-data-in-page-99.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_100.block_1
  .ranking-day-data-in-page-100.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_101.block_1
  .ranking-day-data-in-page-101.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_102.block_1
  .ranking-day-data-in-page-102.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_103.block_1
  .ranking-day-data-in-page-103.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_104.block_1
  .ranking-day-data-in-page-104.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_105.block_1
  .ranking-day-data-in-page-105.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_106.block_1
  .ranking-day-data-in-page-106.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_107.block_1
  .ranking-day-data-in-page-107.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_108.block_1
  .ranking-day-data-in-page-108.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_109.block_1
  .ranking-day-data-in-page-109.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_110.block_1
  .ranking-day-data-in-page-110.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_111.block_1
  .ranking-day-data-in-page-111.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_112.block_1
  .ranking-day-data-in-page-112.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_113.block_1
  .ranking-day-data-in-page-113.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_114.block_1
  .ranking-day-data-in-page-114.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_115.block_1
  .ranking-day-data-in-page-115.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_116.block_1
  .ranking-day-data-in-page-116.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_117.block_1
  .ranking-day-data-in-page-117.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_118.block_1
  .ranking-day-data-in-page-118.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_119.block_1
  .ranking-day-data-in-page-119.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_120.block_1
  .ranking-day-data-in-page-120.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_121.block_1
  .ranking-day-data-in-page-121.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_122.block_1
  .ranking-day-data-in-page-122.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_123.block_1
  .ranking-day-data-in-page-123.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_124.block_1
  .ranking-day-data-in-page-124.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_125.block_1
  .ranking-day-data-in-page-125.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_126.block_1
  .ranking-day-data-in-page-126.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_127.block_1
  .ranking-day-data-in-page-127.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_128.block_1
  .ranking-day-data-in-page-128.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_129.block_1
  .ranking-day-data-in-page-129.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_130.block_1
  .ranking-day-data-in-page-130.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_131.block_1
  .ranking-day-data-in-page-131.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_132.block_1
  .ranking-day-data-in-page-132.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_133.block_1
  .ranking-day-data-in-page-133.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_134.block_1
  .ranking-day-data-in-page-134.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_135.block_1
  .ranking-day-data-in-page-135.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_136.block_1
  .ranking-day-data-in-page-136.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_137.block_1
  .ranking-day-data-in-page-137.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_138.block_1
  .ranking-day-data-in-page-138.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_139.block_1
  .ranking-day-data-in-page-139.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_140.block_1
  .ranking-day-data-in-page-140.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_141.block_1
  .ranking-day-data-in-page-141.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_142.block_1
  .ranking-day-data-in-page-142.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_143.block_1
  .ranking-day-data-in-page-143.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_144.block_1
  .ranking-day-data-in-page-144.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_145.block_1
  .ranking-day-data-in-page-145.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_146.block_1
  .ranking-day-data-in-page-146.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_147.block_1
  .ranking-day-data-in-page-147.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_148.block_1
  .ranking-day-data-in-page-148.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_149.block_1
  .ranking-day-data-in-page-149.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_150.block_1
  .ranking-day-data-in-page-150.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_151.block_1
  .ranking-day-data-in-page-151.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_152.block_1
  .ranking-day-data-in-page-152.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_153.block_1
  .ranking-day-data-in-page-153.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_154.block_1
  .ranking-day-data-in-page-154.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_155.block_1
  .ranking-day-data-in-page-155.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_156.block_1
  .ranking-day-data-in-page-156.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_157.block_1
  .ranking-day-data-in-page-157.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_158.block_1
  .ranking-day-data-in-page-158.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_159.block_1
  .ranking-day-data-in-page-159.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_160.block_1
  .ranking-day-data-in-page-160.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_161.block_1
  .ranking-day-data-in-page-161.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_162.block_1
  .ranking-day-data-in-page-162.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_163.block_1
  .ranking-day-data-in-page-163.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_164.block_1
  .ranking-day-data-in-page-164.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_165.block_1
  .ranking-day-data-in-page-165.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_166.block_1
  .ranking-day-data-in-page-166.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_167.block_1
  .ranking-day-data-in-page-167.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_168.block_1
  .ranking-day-data-in-page-168.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_169.block_1
  .ranking-day-data-in-page-169.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_170.block_1
  .ranking-day-data-in-page-170.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_171.block_1
  .ranking-day-data-in-page-171.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_172.block_1
  .ranking-day-data-in-page-172.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_173.block_1
  .ranking-day-data-in-page-173.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_174.block_1
  .ranking-day-data-in-page-174.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_175.block_1
  .ranking-day-data-in-page-175.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_176.block_1
  .ranking-day-data-in-page-176.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_177.block_1
  .ranking-day-data-in-page-177.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_178.block_1
  .ranking-day-data-in-page-178.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_179.block_1
  .ranking-day-data-in-page-179.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_180.block_1
  .ranking-day-data-in-page-180.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_181.block_1
  .ranking-day-data-in-page-181.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_182.block_1
  .ranking-day-data-in-page-182.ranking-day-data-in-block-1 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_0.block_2
  .ranking-day-data-in-page-0.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_1.block_2
  .ranking-day-data-in-page-1.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_2.block_2
  .ranking-day-data-in-page-2.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_3.block_2
  .ranking-day-data-in-page-3.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_4.block_2
  .ranking-day-data-in-page-4.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_5.block_2
  .ranking-day-data-in-page-5.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_6.block_2
  .ranking-day-data-in-page-6.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_7.block_2
  .ranking-day-data-in-page-7.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_8.block_2
  .ranking-day-data-in-page-8.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_9.block_2
  .ranking-day-data-in-page-9.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_10.block_2
  .ranking-day-data-in-page-10.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_11.block_2
  .ranking-day-data-in-page-11.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_12.block_2
  .ranking-day-data-in-page-12.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_13.block_2
  .ranking-day-data-in-page-13.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_14.block_2
  .ranking-day-data-in-page-14.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_15.block_2
  .ranking-day-data-in-page-15.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_16.block_2
  .ranking-day-data-in-page-16.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_17.block_2
  .ranking-day-data-in-page-17.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_18.block_2
  .ranking-day-data-in-page-18.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_19.block_2
  .ranking-day-data-in-page-19.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_20.block_2
  .ranking-day-data-in-page-20.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_21.block_2
  .ranking-day-data-in-page-21.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_22.block_2
  .ranking-day-data-in-page-22.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_23.block_2
  .ranking-day-data-in-page-23.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_24.block_2
  .ranking-day-data-in-page-24.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_25.block_2
  .ranking-day-data-in-page-25.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_26.block_2
  .ranking-day-data-in-page-26.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_27.block_2
  .ranking-day-data-in-page-27.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_28.block_2
  .ranking-day-data-in-page-28.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_29.block_2
  .ranking-day-data-in-page-29.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_30.block_2
  .ranking-day-data-in-page-30.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_31.block_2
  .ranking-day-data-in-page-31.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_32.block_2
  .ranking-day-data-in-page-32.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_33.block_2
  .ranking-day-data-in-page-33.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_34.block_2
  .ranking-day-data-in-page-34.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_35.block_2
  .ranking-day-data-in-page-35.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_36.block_2
  .ranking-day-data-in-page-36.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_37.block_2
  .ranking-day-data-in-page-37.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_38.block_2
  .ranking-day-data-in-page-38.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_39.block_2
  .ranking-day-data-in-page-39.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_40.block_2
  .ranking-day-data-in-page-40.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_41.block_2
  .ranking-day-data-in-page-41.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_42.block_2
  .ranking-day-data-in-page-42.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_43.block_2
  .ranking-day-data-in-page-43.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_44.block_2
  .ranking-day-data-in-page-44.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_45.block_2
  .ranking-day-data-in-page-45.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_46.block_2
  .ranking-day-data-in-page-46.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_47.block_2
  .ranking-day-data-in-page-47.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_48.block_2
  .ranking-day-data-in-page-48.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_49.block_2
  .ranking-day-data-in-page-49.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_50.block_2
  .ranking-day-data-in-page-50.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_51.block_2
  .ranking-day-data-in-page-51.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_52.block_2
  .ranking-day-data-in-page-52.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_53.block_2
  .ranking-day-data-in-page-53.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_54.block_2
  .ranking-day-data-in-page-54.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_55.block_2
  .ranking-day-data-in-page-55.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_56.block_2
  .ranking-day-data-in-page-56.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_57.block_2
  .ranking-day-data-in-page-57.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_58.block_2
  .ranking-day-data-in-page-58.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_59.block_2
  .ranking-day-data-in-page-59.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_60.block_2
  .ranking-day-data-in-page-60.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_61.block_2
  .ranking-day-data-in-page-61.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_62.block_2
  .ranking-day-data-in-page-62.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_63.block_2
  .ranking-day-data-in-page-63.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_64.block_2
  .ranking-day-data-in-page-64.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_65.block_2
  .ranking-day-data-in-page-65.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_66.block_2
  .ranking-day-data-in-page-66.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_67.block_2
  .ranking-day-data-in-page-67.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_68.block_2
  .ranking-day-data-in-page-68.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_69.block_2
  .ranking-day-data-in-page-69.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_70.block_2
  .ranking-day-data-in-page-70.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_71.block_2
  .ranking-day-data-in-page-71.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_72.block_2
  .ranking-day-data-in-page-72.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_73.block_2
  .ranking-day-data-in-page-73.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_74.block_2
  .ranking-day-data-in-page-74.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_75.block_2
  .ranking-day-data-in-page-75.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_76.block_2
  .ranking-day-data-in-page-76.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_77.block_2
  .ranking-day-data-in-page-77.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_78.block_2
  .ranking-day-data-in-page-78.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_79.block_2
  .ranking-day-data-in-page-79.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_80.block_2
  .ranking-day-data-in-page-80.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_81.block_2
  .ranking-day-data-in-page-81.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_82.block_2
  .ranking-day-data-in-page-82.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_83.block_2
  .ranking-day-data-in-page-83.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_84.block_2
  .ranking-day-data-in-page-84.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_85.block_2
  .ranking-day-data-in-page-85.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_86.block_2
  .ranking-day-data-in-page-86.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_87.block_2
  .ranking-day-data-in-page-87.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_88.block_2
  .ranking-day-data-in-page-88.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_89.block_2
  .ranking-day-data-in-page-89.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_90.block_2
  .ranking-day-data-in-page-90.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_91.block_2
  .ranking-day-data-in-page-91.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_92.block_2
  .ranking-day-data-in-page-92.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_93.block_2
  .ranking-day-data-in-page-93.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_94.block_2
  .ranking-day-data-in-page-94.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_95.block_2
  .ranking-day-data-in-page-95.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_96.block_2
  .ranking-day-data-in-page-96.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_97.block_2
  .ranking-day-data-in-page-97.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_98.block_2
  .ranking-day-data-in-page-98.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_99.block_2
  .ranking-day-data-in-page-99.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_100.block_2
  .ranking-day-data-in-page-100.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_101.block_2
  .ranking-day-data-in-page-101.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_102.block_2
  .ranking-day-data-in-page-102.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_103.block_2
  .ranking-day-data-in-page-103.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_104.block_2
  .ranking-day-data-in-page-104.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_105.block_2
  .ranking-day-data-in-page-105.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_106.block_2
  .ranking-day-data-in-page-106.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_107.block_2
  .ranking-day-data-in-page-107.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_108.block_2
  .ranking-day-data-in-page-108.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_109.block_2
  .ranking-day-data-in-page-109.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_110.block_2
  .ranking-day-data-in-page-110.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_111.block_2
  .ranking-day-data-in-page-111.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_112.block_2
  .ranking-day-data-in-page-112.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_113.block_2
  .ranking-day-data-in-page-113.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_114.block_2
  .ranking-day-data-in-page-114.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_115.block_2
  .ranking-day-data-in-page-115.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_116.block_2
  .ranking-day-data-in-page-116.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_117.block_2
  .ranking-day-data-in-page-117.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_118.block_2
  .ranking-day-data-in-page-118.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_119.block_2
  .ranking-day-data-in-page-119.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_120.block_2
  .ranking-day-data-in-page-120.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_121.block_2
  .ranking-day-data-in-page-121.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_122.block_2
  .ranking-day-data-in-page-122.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_123.block_2
  .ranking-day-data-in-page-123.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_124.block_2
  .ranking-day-data-in-page-124.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_125.block_2
  .ranking-day-data-in-page-125.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_126.block_2
  .ranking-day-data-in-page-126.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_127.block_2
  .ranking-day-data-in-page-127.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_128.block_2
  .ranking-day-data-in-page-128.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_129.block_2
  .ranking-day-data-in-page-129.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_130.block_2
  .ranking-day-data-in-page-130.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_131.block_2
  .ranking-day-data-in-page-131.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_132.block_2
  .ranking-day-data-in-page-132.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_133.block_2
  .ranking-day-data-in-page-133.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_134.block_2
  .ranking-day-data-in-page-134.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_135.block_2
  .ranking-day-data-in-page-135.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_136.block_2
  .ranking-day-data-in-page-136.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_137.block_2
  .ranking-day-data-in-page-137.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_138.block_2
  .ranking-day-data-in-page-138.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_139.block_2
  .ranking-day-data-in-page-139.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_140.block_2
  .ranking-day-data-in-page-140.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_141.block_2
  .ranking-day-data-in-page-141.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_142.block_2
  .ranking-day-data-in-page-142.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_143.block_2
  .ranking-day-data-in-page-143.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_144.block_2
  .ranking-day-data-in-page-144.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_145.block_2
  .ranking-day-data-in-page-145.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_146.block_2
  .ranking-day-data-in-page-146.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_147.block_2
  .ranking-day-data-in-page-147.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_148.block_2
  .ranking-day-data-in-page-148.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_149.block_2
  .ranking-day-data-in-page-149.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_150.block_2
  .ranking-day-data-in-page-150.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_151.block_2
  .ranking-day-data-in-page-151.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_152.block_2
  .ranking-day-data-in-page-152.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_153.block_2
  .ranking-day-data-in-page-153.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_154.block_2
  .ranking-day-data-in-page-154.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_155.block_2
  .ranking-day-data-in-page-155.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_156.block_2
  .ranking-day-data-in-page-156.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_157.block_2
  .ranking-day-data-in-page-157.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_158.block_2
  .ranking-day-data-in-page-158.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_159.block_2
  .ranking-day-data-in-page-159.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_160.block_2
  .ranking-day-data-in-page-160.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_161.block_2
  .ranking-day-data-in-page-161.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_162.block_2
  .ranking-day-data-in-page-162.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_163.block_2
  .ranking-day-data-in-page-163.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_164.block_2
  .ranking-day-data-in-page-164.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_165.block_2
  .ranking-day-data-in-page-165.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_166.block_2
  .ranking-day-data-in-page-166.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_167.block_2
  .ranking-day-data-in-page-167.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_168.block_2
  .ranking-day-data-in-page-168.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_169.block_2
  .ranking-day-data-in-page-169.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_170.block_2
  .ranking-day-data-in-page-170.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_171.block_2
  .ranking-day-data-in-page-171.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_172.block_2
  .ranking-day-data-in-page-172.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_173.block_2
  .ranking-day-data-in-page-173.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_174.block_2
  .ranking-day-data-in-page-174.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_175.block_2
  .ranking-day-data-in-page-175.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_176.block_2
  .ranking-day-data-in-page-176.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_177.block_2
  .ranking-day-data-in-page-177.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_178.block_2
  .ranking-day-data-in-page-178.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_179.block_2
  .ranking-day-data-in-page-179.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_180.block_2
  .ranking-day-data-in-page-180.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_181.block_2
  .ranking-day-data-in-page-181.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_182.block_2
  .ranking-day-data-in-page-182.ranking-day-data-in-block-2 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_0.block_3
  .ranking-day-data-in-page-0.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_1.block_3
  .ranking-day-data-in-page-1.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_2.block_3
  .ranking-day-data-in-page-2.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_3.block_3
  .ranking-day-data-in-page-3.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_4.block_3
  .ranking-day-data-in-page-4.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_5.block_3
  .ranking-day-data-in-page-5.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_6.block_3
  .ranking-day-data-in-page-6.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_7.block_3
  .ranking-day-data-in-page-7.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_8.block_3
  .ranking-day-data-in-page-8.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_9.block_3
  .ranking-day-data-in-page-9.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_10.block_3
  .ranking-day-data-in-page-10.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_11.block_3
  .ranking-day-data-in-page-11.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_12.block_3
  .ranking-day-data-in-page-12.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_13.block_3
  .ranking-day-data-in-page-13.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_14.block_3
  .ranking-day-data-in-page-14.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_15.block_3
  .ranking-day-data-in-page-15.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_16.block_3
  .ranking-day-data-in-page-16.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_17.block_3
  .ranking-day-data-in-page-17.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_18.block_3
  .ranking-day-data-in-page-18.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_19.block_3
  .ranking-day-data-in-page-19.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_20.block_3
  .ranking-day-data-in-page-20.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_21.block_3
  .ranking-day-data-in-page-21.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_22.block_3
  .ranking-day-data-in-page-22.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_23.block_3
  .ranking-day-data-in-page-23.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_24.block_3
  .ranking-day-data-in-page-24.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_25.block_3
  .ranking-day-data-in-page-25.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_26.block_3
  .ranking-day-data-in-page-26.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_27.block_3
  .ranking-day-data-in-page-27.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_28.block_3
  .ranking-day-data-in-page-28.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_29.block_3
  .ranking-day-data-in-page-29.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_30.block_3
  .ranking-day-data-in-page-30.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_31.block_3
  .ranking-day-data-in-page-31.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_32.block_3
  .ranking-day-data-in-page-32.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_33.block_3
  .ranking-day-data-in-page-33.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_34.block_3
  .ranking-day-data-in-page-34.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_35.block_3
  .ranking-day-data-in-page-35.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_36.block_3
  .ranking-day-data-in-page-36.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_37.block_3
  .ranking-day-data-in-page-37.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_38.block_3
  .ranking-day-data-in-page-38.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_39.block_3
  .ranking-day-data-in-page-39.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_40.block_3
  .ranking-day-data-in-page-40.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_41.block_3
  .ranking-day-data-in-page-41.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_42.block_3
  .ranking-day-data-in-page-42.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_43.block_3
  .ranking-day-data-in-page-43.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_44.block_3
  .ranking-day-data-in-page-44.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_45.block_3
  .ranking-day-data-in-page-45.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_46.block_3
  .ranking-day-data-in-page-46.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_47.block_3
  .ranking-day-data-in-page-47.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_48.block_3
  .ranking-day-data-in-page-48.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_49.block_3
  .ranking-day-data-in-page-49.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_50.block_3
  .ranking-day-data-in-page-50.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_51.block_3
  .ranking-day-data-in-page-51.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_52.block_3
  .ranking-day-data-in-page-52.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_53.block_3
  .ranking-day-data-in-page-53.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_54.block_3
  .ranking-day-data-in-page-54.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_55.block_3
  .ranking-day-data-in-page-55.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_56.block_3
  .ranking-day-data-in-page-56.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_57.block_3
  .ranking-day-data-in-page-57.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_58.block_3
  .ranking-day-data-in-page-58.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_59.block_3
  .ranking-day-data-in-page-59.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_60.block_3
  .ranking-day-data-in-page-60.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_61.block_3
  .ranking-day-data-in-page-61.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_62.block_3
  .ranking-day-data-in-page-62.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_63.block_3
  .ranking-day-data-in-page-63.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_64.block_3
  .ranking-day-data-in-page-64.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_65.block_3
  .ranking-day-data-in-page-65.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_66.block_3
  .ranking-day-data-in-page-66.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_67.block_3
  .ranking-day-data-in-page-67.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_68.block_3
  .ranking-day-data-in-page-68.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_69.block_3
  .ranking-day-data-in-page-69.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_70.block_3
  .ranking-day-data-in-page-70.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_71.block_3
  .ranking-day-data-in-page-71.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_72.block_3
  .ranking-day-data-in-page-72.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_73.block_3
  .ranking-day-data-in-page-73.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_74.block_3
  .ranking-day-data-in-page-74.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_75.block_3
  .ranking-day-data-in-page-75.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_76.block_3
  .ranking-day-data-in-page-76.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_77.block_3
  .ranking-day-data-in-page-77.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_78.block_3
  .ranking-day-data-in-page-78.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_79.block_3
  .ranking-day-data-in-page-79.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_80.block_3
  .ranking-day-data-in-page-80.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_81.block_3
  .ranking-day-data-in-page-81.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_82.block_3
  .ranking-day-data-in-page-82.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_83.block_3
  .ranking-day-data-in-page-83.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_84.block_3
  .ranking-day-data-in-page-84.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_85.block_3
  .ranking-day-data-in-page-85.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_86.block_3
  .ranking-day-data-in-page-86.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_87.block_3
  .ranking-day-data-in-page-87.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_88.block_3
  .ranking-day-data-in-page-88.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_89.block_3
  .ranking-day-data-in-page-89.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_90.block_3
  .ranking-day-data-in-page-90.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_91.block_3
  .ranking-day-data-in-page-91.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_92.block_3
  .ranking-day-data-in-page-92.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_93.block_3
  .ranking-day-data-in-page-93.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_94.block_3
  .ranking-day-data-in-page-94.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_95.block_3
  .ranking-day-data-in-page-95.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_96.block_3
  .ranking-day-data-in-page-96.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_97.block_3
  .ranking-day-data-in-page-97.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_98.block_3
  .ranking-day-data-in-page-98.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_99.block_3
  .ranking-day-data-in-page-99.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_100.block_3
  .ranking-day-data-in-page-100.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_101.block_3
  .ranking-day-data-in-page-101.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_102.block_3
  .ranking-day-data-in-page-102.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_103.block_3
  .ranking-day-data-in-page-103.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_104.block_3
  .ranking-day-data-in-page-104.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_105.block_3
  .ranking-day-data-in-page-105.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_106.block_3
  .ranking-day-data-in-page-106.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_107.block_3
  .ranking-day-data-in-page-107.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_108.block_3
  .ranking-day-data-in-page-108.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_109.block_3
  .ranking-day-data-in-page-109.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_110.block_3
  .ranking-day-data-in-page-110.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_111.block_3
  .ranking-day-data-in-page-111.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_112.block_3
  .ranking-day-data-in-page-112.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_113.block_3
  .ranking-day-data-in-page-113.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_114.block_3
  .ranking-day-data-in-page-114.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_115.block_3
  .ranking-day-data-in-page-115.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_116.block_3
  .ranking-day-data-in-page-116.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_117.block_3
  .ranking-day-data-in-page-117.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_118.block_3
  .ranking-day-data-in-page-118.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_119.block_3
  .ranking-day-data-in-page-119.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_120.block_3
  .ranking-day-data-in-page-120.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_121.block_3
  .ranking-day-data-in-page-121.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_122.block_3
  .ranking-day-data-in-page-122.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_123.block_3
  .ranking-day-data-in-page-123.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_124.block_3
  .ranking-day-data-in-page-124.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_125.block_3
  .ranking-day-data-in-page-125.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_126.block_3
  .ranking-day-data-in-page-126.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_127.block_3
  .ranking-day-data-in-page-127.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_128.block_3
  .ranking-day-data-in-page-128.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_129.block_3
  .ranking-day-data-in-page-129.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_130.block_3
  .ranking-day-data-in-page-130.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_131.block_3
  .ranking-day-data-in-page-131.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_132.block_3
  .ranking-day-data-in-page-132.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_133.block_3
  .ranking-day-data-in-page-133.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_134.block_3
  .ranking-day-data-in-page-134.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_135.block_3
  .ranking-day-data-in-page-135.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_136.block_3
  .ranking-day-data-in-page-136.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_137.block_3
  .ranking-day-data-in-page-137.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_138.block_3
  .ranking-day-data-in-page-138.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_139.block_3
  .ranking-day-data-in-page-139.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_140.block_3
  .ranking-day-data-in-page-140.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_141.block_3
  .ranking-day-data-in-page-141.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_142.block_3
  .ranking-day-data-in-page-142.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_143.block_3
  .ranking-day-data-in-page-143.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_144.block_3
  .ranking-day-data-in-page-144.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_145.block_3
  .ranking-day-data-in-page-145.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_146.block_3
  .ranking-day-data-in-page-146.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_147.block_3
  .ranking-day-data-in-page-147.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_148.block_3
  .ranking-day-data-in-page-148.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_149.block_3
  .ranking-day-data-in-page-149.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_150.block_3
  .ranking-day-data-in-page-150.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_151.block_3
  .ranking-day-data-in-page-151.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_152.block_3
  .ranking-day-data-in-page-152.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_153.block_3
  .ranking-day-data-in-page-153.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_154.block_3
  .ranking-day-data-in-page-154.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_155.block_3
  .ranking-day-data-in-page-155.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_156.block_3
  .ranking-day-data-in-page-156.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_157.block_3
  .ranking-day-data-in-page-157.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_158.block_3
  .ranking-day-data-in-page-158.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_159.block_3
  .ranking-day-data-in-page-159.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_160.block_3
  .ranking-day-data-in-page-160.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_161.block_3
  .ranking-day-data-in-page-161.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_162.block_3
  .ranking-day-data-in-page-162.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_163.block_3
  .ranking-day-data-in-page-163.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_164.block_3
  .ranking-day-data-in-page-164.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_165.block_3
  .ranking-day-data-in-page-165.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_166.block_3
  .ranking-day-data-in-page-166.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_167.block_3
  .ranking-day-data-in-page-167.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_168.block_3
  .ranking-day-data-in-page-168.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_169.block_3
  .ranking-day-data-in-page-169.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_170.block_3
  .ranking-day-data-in-page-170.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_171.block_3
  .ranking-day-data-in-page-171.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_172.block_3
  .ranking-day-data-in-page-172.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_173.block_3
  .ranking-day-data-in-page-173.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_174.block_3
  .ranking-day-data-in-page-174.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_175.block_3
  .ranking-day-data-in-page-175.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_176.block_3
  .ranking-day-data-in-page-176.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_177.block_3
  .ranking-day-data-in-page-177.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_178.block_3
  .ranking-day-data-in-page-178.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_179.block_3
  .ranking-day-data-in-page-179.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_180.block_3
  .ranking-day-data-in-page-180.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_181.block_3
  .ranking-day-data-in-page-181.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_182.block_3
  .ranking-day-data-in-page-182.ranking-day-data-in-block-3 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_0.block_4
  .ranking-day-data-in-page-0.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_1.block_4
  .ranking-day-data-in-page-1.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_2.block_4
  .ranking-day-data-in-page-2.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_3.block_4
  .ranking-day-data-in-page-3.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_4.block_4
  .ranking-day-data-in-page-4.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_5.block_4
  .ranking-day-data-in-page-5.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_6.block_4
  .ranking-day-data-in-page-6.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_7.block_4
  .ranking-day-data-in-page-7.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_8.block_4
  .ranking-day-data-in-page-8.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_9.block_4
  .ranking-day-data-in-page-9.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_10.block_4
  .ranking-day-data-in-page-10.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_11.block_4
  .ranking-day-data-in-page-11.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_12.block_4
  .ranking-day-data-in-page-12.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_13.block_4
  .ranking-day-data-in-page-13.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_14.block_4
  .ranking-day-data-in-page-14.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_15.block_4
  .ranking-day-data-in-page-15.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_16.block_4
  .ranking-day-data-in-page-16.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_17.block_4
  .ranking-day-data-in-page-17.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_18.block_4
  .ranking-day-data-in-page-18.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_19.block_4
  .ranking-day-data-in-page-19.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_20.block_4
  .ranking-day-data-in-page-20.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_21.block_4
  .ranking-day-data-in-page-21.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_22.block_4
  .ranking-day-data-in-page-22.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_23.block_4
  .ranking-day-data-in-page-23.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_24.block_4
  .ranking-day-data-in-page-24.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_25.block_4
  .ranking-day-data-in-page-25.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_26.block_4
  .ranking-day-data-in-page-26.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_27.block_4
  .ranking-day-data-in-page-27.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_28.block_4
  .ranking-day-data-in-page-28.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_29.block_4
  .ranking-day-data-in-page-29.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_30.block_4
  .ranking-day-data-in-page-30.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_31.block_4
  .ranking-day-data-in-page-31.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_32.block_4
  .ranking-day-data-in-page-32.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_33.block_4
  .ranking-day-data-in-page-33.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_34.block_4
  .ranking-day-data-in-page-34.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_35.block_4
  .ranking-day-data-in-page-35.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_36.block_4
  .ranking-day-data-in-page-36.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_37.block_4
  .ranking-day-data-in-page-37.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_38.block_4
  .ranking-day-data-in-page-38.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_39.block_4
  .ranking-day-data-in-page-39.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_40.block_4
  .ranking-day-data-in-page-40.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_41.block_4
  .ranking-day-data-in-page-41.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_42.block_4
  .ranking-day-data-in-page-42.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_43.block_4
  .ranking-day-data-in-page-43.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_44.block_4
  .ranking-day-data-in-page-44.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_45.block_4
  .ranking-day-data-in-page-45.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_46.block_4
  .ranking-day-data-in-page-46.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_47.block_4
  .ranking-day-data-in-page-47.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_48.block_4
  .ranking-day-data-in-page-48.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_49.block_4
  .ranking-day-data-in-page-49.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_50.block_4
  .ranking-day-data-in-page-50.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_51.block_4
  .ranking-day-data-in-page-51.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_52.block_4
  .ranking-day-data-in-page-52.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_53.block_4
  .ranking-day-data-in-page-53.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_54.block_4
  .ranking-day-data-in-page-54.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_55.block_4
  .ranking-day-data-in-page-55.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_56.block_4
  .ranking-day-data-in-page-56.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_57.block_4
  .ranking-day-data-in-page-57.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_58.block_4
  .ranking-day-data-in-page-58.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_59.block_4
  .ranking-day-data-in-page-59.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_60.block_4
  .ranking-day-data-in-page-60.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_61.block_4
  .ranking-day-data-in-page-61.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_62.block_4
  .ranking-day-data-in-page-62.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_63.block_4
  .ranking-day-data-in-page-63.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_64.block_4
  .ranking-day-data-in-page-64.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_65.block_4
  .ranking-day-data-in-page-65.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_66.block_4
  .ranking-day-data-in-page-66.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_67.block_4
  .ranking-day-data-in-page-67.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_68.block_4
  .ranking-day-data-in-page-68.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_69.block_4
  .ranking-day-data-in-page-69.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_70.block_4
  .ranking-day-data-in-page-70.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_71.block_4
  .ranking-day-data-in-page-71.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_72.block_4
  .ranking-day-data-in-page-72.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_73.block_4
  .ranking-day-data-in-page-73.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_74.block_4
  .ranking-day-data-in-page-74.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_75.block_4
  .ranking-day-data-in-page-75.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_76.block_4
  .ranking-day-data-in-page-76.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_77.block_4
  .ranking-day-data-in-page-77.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_78.block_4
  .ranking-day-data-in-page-78.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_79.block_4
  .ranking-day-data-in-page-79.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_80.block_4
  .ranking-day-data-in-page-80.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_81.block_4
  .ranking-day-data-in-page-81.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_82.block_4
  .ranking-day-data-in-page-82.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_83.block_4
  .ranking-day-data-in-page-83.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_84.block_4
  .ranking-day-data-in-page-84.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_85.block_4
  .ranking-day-data-in-page-85.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_86.block_4
  .ranking-day-data-in-page-86.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_87.block_4
  .ranking-day-data-in-page-87.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_88.block_4
  .ranking-day-data-in-page-88.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_89.block_4
  .ranking-day-data-in-page-89.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_90.block_4
  .ranking-day-data-in-page-90.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_91.block_4
  .ranking-day-data-in-page-91.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_92.block_4
  .ranking-day-data-in-page-92.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_93.block_4
  .ranking-day-data-in-page-93.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_94.block_4
  .ranking-day-data-in-page-94.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_95.block_4
  .ranking-day-data-in-page-95.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_96.block_4
  .ranking-day-data-in-page-96.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_97.block_4
  .ranking-day-data-in-page-97.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_98.block_4
  .ranking-day-data-in-page-98.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_99.block_4
  .ranking-day-data-in-page-99.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_100.block_4
  .ranking-day-data-in-page-100.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_101.block_4
  .ranking-day-data-in-page-101.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_102.block_4
  .ranking-day-data-in-page-102.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_103.block_4
  .ranking-day-data-in-page-103.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_104.block_4
  .ranking-day-data-in-page-104.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_105.block_4
  .ranking-day-data-in-page-105.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_106.block_4
  .ranking-day-data-in-page-106.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_107.block_4
  .ranking-day-data-in-page-107.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_108.block_4
  .ranking-day-data-in-page-108.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_109.block_4
  .ranking-day-data-in-page-109.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_110.block_4
  .ranking-day-data-in-page-110.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_111.block_4
  .ranking-day-data-in-page-111.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_112.block_4
  .ranking-day-data-in-page-112.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_113.block_4
  .ranking-day-data-in-page-113.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_114.block_4
  .ranking-day-data-in-page-114.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_115.block_4
  .ranking-day-data-in-page-115.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_116.block_4
  .ranking-day-data-in-page-116.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_117.block_4
  .ranking-day-data-in-page-117.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_118.block_4
  .ranking-day-data-in-page-118.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_119.block_4
  .ranking-day-data-in-page-119.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_120.block_4
  .ranking-day-data-in-page-120.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_121.block_4
  .ranking-day-data-in-page-121.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_122.block_4
  .ranking-day-data-in-page-122.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_123.block_4
  .ranking-day-data-in-page-123.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_124.block_4
  .ranking-day-data-in-page-124.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_125.block_4
  .ranking-day-data-in-page-125.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_126.block_4
  .ranking-day-data-in-page-126.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_127.block_4
  .ranking-day-data-in-page-127.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_128.block_4
  .ranking-day-data-in-page-128.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_129.block_4
  .ranking-day-data-in-page-129.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_130.block_4
  .ranking-day-data-in-page-130.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_131.block_4
  .ranking-day-data-in-page-131.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_132.block_4
  .ranking-day-data-in-page-132.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_133.block_4
  .ranking-day-data-in-page-133.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_134.block_4
  .ranking-day-data-in-page-134.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_135.block_4
  .ranking-day-data-in-page-135.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_136.block_4
  .ranking-day-data-in-page-136.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_137.block_4
  .ranking-day-data-in-page-137.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_138.block_4
  .ranking-day-data-in-page-138.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_139.block_4
  .ranking-day-data-in-page-139.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_140.block_4
  .ranking-day-data-in-page-140.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_141.block_4
  .ranking-day-data-in-page-141.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_142.block_4
  .ranking-day-data-in-page-142.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_143.block_4
  .ranking-day-data-in-page-143.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_144.block_4
  .ranking-day-data-in-page-144.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_145.block_4
  .ranking-day-data-in-page-145.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_146.block_4
  .ranking-day-data-in-page-146.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_147.block_4
  .ranking-day-data-in-page-147.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_148.block_4
  .ranking-day-data-in-page-148.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_149.block_4
  .ranking-day-data-in-page-149.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_150.block_4
  .ranking-day-data-in-page-150.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_151.block_4
  .ranking-day-data-in-page-151.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_152.block_4
  .ranking-day-data-in-page-152.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_153.block_4
  .ranking-day-data-in-page-153.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_154.block_4
  .ranking-day-data-in-page-154.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_155.block_4
  .ranking-day-data-in-page-155.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_156.block_4
  .ranking-day-data-in-page-156.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_157.block_4
  .ranking-day-data-in-page-157.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_158.block_4
  .ranking-day-data-in-page-158.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_159.block_4
  .ranking-day-data-in-page-159.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_160.block_4
  .ranking-day-data-in-page-160.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_161.block_4
  .ranking-day-data-in-page-161.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_162.block_4
  .ranking-day-data-in-page-162.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_163.block_4
  .ranking-day-data-in-page-163.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_164.block_4
  .ranking-day-data-in-page-164.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_165.block_4
  .ranking-day-data-in-page-165.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_166.block_4
  .ranking-day-data-in-page-166.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_167.block_4
  .ranking-day-data-in-page-167.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_168.block_4
  .ranking-day-data-in-page-168.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_169.block_4
  .ranking-day-data-in-page-169.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_170.block_4
  .ranking-day-data-in-page-170.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_171.block_4
  .ranking-day-data-in-page-171.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_172.block_4
  .ranking-day-data-in-page-172.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_173.block_4
  .ranking-day-data-in-page-173.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_174.block_4
  .ranking-day-data-in-page-174.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_175.block_4
  .ranking-day-data-in-page-175.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_176.block_4
  .ranking-day-data-in-page-176.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_177.block_4
  .ranking-day-data-in-page-177.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_178.block_4
  .ranking-day-data-in-page-178.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_179.block_4
  .ranking-day-data-in-page-179.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_180.block_4
  .ranking-day-data-in-page-180.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_181.block_4
  .ranking-day-data-in-page-181.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
#rate-days-wrapper.page_182.block_4
  .ranking-day-data-in-page-182.ranking-day-data-in-block-4 {
  border-bottom: 3px solid #1576c7;
}
</style>
