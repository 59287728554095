<template>
  <v-dialog
    v-model="isOpenBulkForm"
    :fullscreen="$vuetify.breakpoint.mobile"
    :hide-overlay="$vuetify.breakpoint.mobile"
    persistent
    scrollable
    max-width="500px"
  >
    <a-special-rates-bulk-edit-form
      v-if="isOpenBulkForm"
    ></a-special-rates-bulk-edit-form>
  </v-dialog>
</template>

<script>
import aSpecialRatesBulkEditForm from "@/components/special-rates/aSpecialRatesBulkEditForm.vue";

export default {
  name: "a-special-rates-bulk-edit",
  components: {
    aSpecialRatesBulkEditForm
  },
  props: {
    rateId: {
      type: Number,
      default: null
    }
  },
  computed: {
    isOpenBulkForm() {
      return this.$store.state.specialrates.bulk_form_open;
    }
  }
};
</script>
