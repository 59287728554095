<template>
  <div>
    <v-row class="px-4">
      <v-col cols="12" sm="2">
        <v-text-field
          :label="$t('new_availability')"
          type="number"
          v-model="availabilityForm.value"
          required
          :rules="rules"
          outlined
          rounded
          dense
        >
          <template #append>
            <v-icon>
              mdi-bed
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="10">
        <autocomplete-rates
          v-model="availabilityForm.selectedRates"
          required
          :rates="rates"
          :label="$t('rates_or_rooms_to_modify')"
        >
        </autocomplete-rates>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import autocompleteRates from "@/components/autocompleteRates.vue";
export default {
  components: { autocompleteRates },
  //   props: {
  //     rates: {
  //       type: [Object],
  //       default: () => ({})
  //     }
  //   },
  props: {
    availabilityForm: {
      type: Object,
      default: () => ({
        value: undefined,
        selectedRates: []
      })
    }
  },
  data: () => ({
    // toggle_exclusive: false,
    // value: undefined,
    // selectedRates: []
  }),
  emits: ["update:value", "update:selectedRates"],
  computed: {
    rates() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.channelManagerTableData.hotelLabels
          // .filter(e => !e.isHotel)
          // .filter(e => !e.isRate || (e.isRate && !e.parent_rate_id))
          .filter(e => e.isRoom || e.isRateAvailability)
          .map(e => ({
            ...e,
            value: e.isRoom ? `room_id_${e.room_id}` : `rate_id_${e.rate_id}`
          }))
      );
    },
    rules() {
      const arrRules = [v => !!v || this.$i18n.t("required_field")];
      if (this.availabilityForm.type_rate_change === "absolute") {
        arrRules.push(v => v >= 0 || this.$i18n.t("must_be_positive"));
      }
      return arrRules;
    }
  }
};
</script>
<style lang="scss" scoped></style>
