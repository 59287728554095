<template>
  <v-row>
    <v-col
      v-for="(i, index) in item.settings.pickup_spots"
      :key="index"
      cols="2"
      class="py-0"
    >
      <v-text-field
        v-model="
          item.settings.pickup_spots[
            item.settings.pickup_spots.length - 1 - index
          ]
        "
        @input="
          item.settings.pickup_spots[
            item.settings.pickup_spots.length - 1 - index
          ] = parseInt(
            item.settings.pickup_spots[
              item.settings.pickup_spots.length - 1 - index
            ]
          )
        "
        :label="$t('pickup') + ' ' + (index + 1)"
        :rules="rules"
        type="number"
        outlined
        rounded
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "a-hotel-configuration-pickup-intervals",
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  computed: {
    rules() {
      return [v => (v && v > 0) || this.$t("validation_number_must_positive")];
    }
  },
  beforeMount() {
    if (!this.item.settings.pickup_spots) {
      this.item.settings.pickup_spots = [60, 30, 15, 7, 3, 1];
    }
  }
};
</script>
