<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "a-hotelsdot-year-sales-revpar-monthly-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      return {
        type: "line",
        plot: {
          aspect: "spline",
          lineWidth: 3,
          marker: {
            size: 5,
            borderWidth: 1,
            shadow: 1
          }
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 50px 50px 80px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.map(item => {
            return (
              Vue.moment(item)
                .format("MMMM")
                .substr(0, 1)
                .toUpperCase() +
              Vue.moment(item)
                .format("MMMM")
                .substr(1, 2)
            );
          })
        },
        scaleY: {
          lineWidth: 0,
          values: this.scaleValues,
          format: "%v€",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        series.push({
          text: serie,
          values: this.series[serie],
          backgroundColor: this.hotelsdot.series_ui[serie].color,
          lineColor: this.hotelsdot.series_ui[serie].color,
          marker: {
            backgroundColor: this.hotelsdot.series_ui[serie].color
          }
        });
      }
      return series;
    },
    // date_hover: {
    //   get() {
    //     return this.$store.state.date_hover;
    //   },
    //   set(value) {
    //     this.$store.dispatch("setRateDateHover", value);
    //   }
    // },
    max_value() {
      let max = 0;
      for (let serie in this.series) {
        let serie_max = Math.max(...this.series[serie]);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      return parseInt(max + 5);
    },
    min_value() {
      let min = this.max_value;
      for (let serie in this.series) {
        let serie_min = Math.min(...this.series[serie]);
        if (serie_min < min) {
          min = serie_min;
        }
      }

      return parseInt(min - 5 > 0 ? min - 5 : 0);
    },
    scaleValues() {
      return (
        this.min_value +
        ":" +
        this.max_value +
        ":" +
        parseInt((this.max_value - this.min_value) / 4)
      );
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
