<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :mobile_image_height="mobile_image_height"
    :color="bgcolor"
  >
    <template v-slot:header_options>
      <div>
        <v-tooltip v-if="current_hotel && !isGroup" bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" color="white" outlined small>
              <v-icon small class="mr-1">mdi-clock-outline</v-icon>
              {{
                current_hotel.last_import_date.channelmanager | formatDatetime
              }}
            </v-chip>
          </template>
          <span>
            {{ $t("last_update") }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <channel-manager-app
      ref="channel-manager-app>"
      v-else
    ></channel-manager-app>
  </a-page>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import aPage from "@/components/aPage";
import ChannelManagerApp from "@/components/app/channelManagerApp.vue";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms.vue";

export default {
  name: "rms-strategy",
  components: {
    aHotelSelectorRms,
    ChannelManagerApp,
    aPage
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    mobile_image_height: 1200 // Optional number
  }),
  methods: {
    ...mapActions(["showRateHighlight"])
  },
  watch: {},
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 700 : 200;
    },
    bgcolor() {
      return !this.isGroup ? "#f5f5f5" : "transparent";
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    ...mapState({}),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  mounted() {
    if (this.current_hotel && !this.current_hotel.permissions.channelmanager) {
      this.$router.push({
        name: "dashboard",
        params: {
          hotel_slug: this.current_user.default_hotel_slug
        }
      });
    }
  }
};
</script>
