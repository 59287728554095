<template>
  <div id="rms-availability-app">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-rms-strategy-availability-table
          :columns="rms.roomtype_availability.labels"
          :roomtypes_data="rms.roomtype_availability.roomtypes"
          :totals_data="rms.roomtype_availability.totals"
          ref="a-rms-strategy-availability-table"
        >
        </a-rms-strategy-availability-table>
      </template>
      <template v-slot:loading>
        <v-card loading="">
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table-heading"></v-skeleton-loader>
              <v-skeleton-loader type="table-head"></v-skeleton-loader>
              <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
              <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
              <v-skeleton-loader type="table-tfoot"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as Utils from "@/utils.js";
import aRmsStrategyAvailabilityTable from "@/components/rms/hotelsdot/aRmsStrategyAvailabilityTable";
import aBaseComponent from "@/components/aBaseComponent";

export default {
  name: "rms-availability-app",
  components: {
    aRmsStrategyAvailabilityTable,
    aBaseComponent
  },
  props: {
    date: {
      type: String,
      default: "",
      mandatory: true
    },
    max_days: {
      type: Number,
      default: 7,
      mandatory: false
    }
  },
  data: () => ({
    title: "",
    subscribed_modules: ["rms_roomtype_availability", "roomtype_types_mapped"]
  }),
  methods: {
    getFriendlyDay(date) {
      return Utils.getFriendlyDay(date);
    }
  },
  computed: {
    dates() {
      return Utils.getDates(this.date, this.max_days);
    },
    ...mapState({
      rms: state => state.rms
    })
  }
};
</script>
