<template>
  <v-dialog
    v-model="isOpenStopSellForm"
    :fullscreen="$vuetify.breakpoint.mobile"
    :hide-overlay="$vuetify.breakpoint.mobile"
    persistent
    scrollable
    max-width="500px"
  >
    <a-offers-stop-sell-edit-form
      v-if="isOpenStopSellForm"
    ></a-offers-stop-sell-edit-form>
  </v-dialog>
</template>

<script>
import aOffersStopSellEditForm from "@/components/offers/aOffersStopSellEditForm.vue";

export default {
  name: "a-offers-stop-sell-edit",
  components: {
    aOffersStopSellEditForm
  },
  props: {
    rateId: {
      type: Number,
      default: null
    }
  },
  computed: {
    isOpenStopSellForm() {
      return this.$store.state.offers.stop_sell_form_open;
    }
  }
};
</script>
