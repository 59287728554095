<template>
  <v-card color="grey lighten-4 mb-5">
    <v-card-text class="white pa-0">
      <div :class="table_class">
        <a-rms-strategy-day-header
          :columns="filtered_columns"
        ></a-rms-strategy-day-header>
        <a-rms-strategy-day-row
          v-for="(date, index) in filtered_rows"
          :key="index"
          :date="date"
          :columns="filtered_columns"
          :rowIndex="index"
          @getDayDetails="getDayDetails"
        ></a-rms-strategy-day-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import * as Utils from "@/utils.js";
import { mapState, mapGetters } from "vuex";
import aRmsStrategyDayHeader from "@/components/rms/aRmsStrategyDayHeader";
import aRmsStrategyDayRow from "@/components/rms/aRmsStrategyDayRow";

export default {
  name: "a-rms-strategy-day-table",
  components: {
    aRmsStrategyDayHeader,
    aRmsStrategyDayRow
  },
  props: {
    rows: Array,
    kpi: String,
    columns: Array
  },
  data: () => ({
    only_rooms: false,
    only_changes: false,
    vs_pickup: null,
    loading_page: false,
    page: 0,
    days_per_screen: 14
  }),
  computed: {
    vs_pickups() {
      return [
        { value: "pickup_1", text: this.getPickupLiteral("pickup_1") },
        { value: "pickup_2", text: this.getPickupLiteral("pickup_2") },
        { value: "pickup_3", text: this.getPickupLiteral("pickup_3") },
        { value: "pickup_4", text: this.getPickupLiteral("pickup_4") },
        { value: "pickup_5", text: this.getPickupLiteral("pickup_5") },
        { value: "pickup_6", text: this.getPickupLiteral("pickup_6") }
      ];
    },
    filtered_columns() {
      let cols = this.columns;
      return cols;
    },
    filtered_rows() {
      let rows = this.rows;
      return rows;
    },
    table_class() {
      let clas = "table-wrap page_" + this.page;
      return clas;
    },
    total_days() {
      return this.filtered_columns.length;
    },
    pages() {
      return this.total_days / this.days_per_screen;
    },
    hasMorePages() {
      return this.page < this.pages - 1;
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    getDayDetails(v) {
      this.$emit("getDayDetails", v);
    },
    // moveDownSlider() {
    //   if (this.page > 0) {
    //     this.loading_page = true;
    //     this.page--;
    //     setTimeout(() => {
    //       this.loading_page = false;
    //     }, 200);
    //   }
    // },
    // moveUpSlider() {
    //   if (this.page < this.pages) {
    //     this.loading_page = true;
    //     this.page++;
    //     setTimeout(() => {
    //       this.loading_page = false;
    //     }, 200);
    //   }
    // },
    getHotelComparativeDates() {
      this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: Vue.moment(this.date)
            .startOf("year")
            .format("YYYY-MM-DD"),
          to_date: Vue.moment(this.date)
            .endOf("year")
            .format("YYYY-MM-DD")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getPickupLiteral(period) {
      let pickups = {
        pickup_1: this.$t("pickup_1"),
        pickup_2:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_3:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_4:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_5:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_6:
          this.$t("pickup") +
          " " +
          this.current_hotel.settings.pickup_spots["pickup_6"]
      };
      if (pickups[period]) {
        return pickups[period];
      } else {
        return period.replace("_", " ");
      }
    }
  },
  mounted() {
    this.vs_pickup = this.vs_pickups[0].value;
    this.getHotelComparativeDates();
  }
};
</script>

<style lang="scss" scoped>
.v-select {
  max-width: 200px;
}
.table-wrap {
  position: relative;
  .arrow {
    background: #fff;
    color: #aaaaaa;
    position: absolute;
    top: 1px;
    z-index: 200;
  }
  #arrow-left {
    left: 180px;
  }
  #arrow-right {
    left: auto;
    right: -12px;
  }
}
</style>
