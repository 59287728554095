<template>
  <div class="py-2">
    <v-row>
      <v-col v-for="(value, key) in items" :key="key" cols="3">
        <v-card class="elevation-0" outlined style="height: 100%;">
          <v-card-title>{{ $t(key) }}</v-card-title>
          <v-card-text>
            <template v-if="key === 'pickup_intervals'">
              <div v-for="(v, k) in value" :key="k">
                {{ $t("pickup") + " -" + v }}
              </div>
            </template>
            <template v-else-if="key === 'currency'">
              <v-icon> mdi-currency-{{ value }} </v-icon>
            </template>
            <template v-else-if="key === 'data_history_start'">
              {{ getFriendlyDate(value) }}
            </template>
            <template v-else-if="typeof value === 'object'">
              <div v-for="(v, k) in value" :key="k" class="d-flex mt-2">
                <v-icon v-if="v === true" color="green" class="mr-2">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon v-else color="red" class="mr-2">
                  mdi-close-circle-outline
                </v-icon>
                <span>{{ $t(k) }}</span>
              </div>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as Utils from "@/utils.js";

export default {
  name: "a-hotel-audit",
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  computed: {
    items() {
      const { city, rate, data_validation, ...filteredItems } = this.item.audit;
      return filteredItems;
    }
  },
  methods: {
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date, true);
    }
  }
};
</script>
