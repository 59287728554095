<template>
  <v-form v-model="isValid">
    <v-card>
      <v-card-title class="image-bg pb-0 px-4 white--text">
        <div class="d-block full-width">
          <div class="d-flex justify-space-between">
            <span class="text-h5"> {{ $t("bulk_edit") }}</span>
            <v-btn @click="cancel" icon dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <!-- <v-row>
          <v-col>
            <v-select
              v-model="typeOfEdit"
              :items="selectOptions"
              :label="$t('select')"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              :label="typeOfEdit"
              type="number"
              :prepend-icon="currencyIcon"
              v-model="value"
              required
              v-if="editingRates"
            ></v-text-field>
            <v-text-field
              :label="typeOfEdit"
              type="number"
              v-model="value"
              required
              v-if="editingNumbers"
            ></v-text-field>
            <v-menu
              ref="menuStopSell"
              v-model="menuStopSell"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-if="editingStopSell"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesStopSellText"
                  label="StopSell"
                  prepend-icon="mdi-calendar"
                  readonly
                  required
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="minDate"
                range
                show-adjacent-months
                v-model="datesStopSell"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row> -->

          <v-row>
            <v-col cols="12" sm="6" lg="6">
              <h4>{{ $t("period") }}</h4>
              <v-menu
                v-model="menuPeriod"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    block
                    rounded
                    outlined
                    dark
                    large
                    background-color="transparent"
                    style="border-color: rgba(250,250,250,0.2)"
                    v-on="on"
                  >
                    <span class="flex-grow-1 text-left">
                      {{ formatedDatesRange }}
                    </span>
                    <v-icon class="align-self-right"
                      >mdi-calendar-month-outline</v-icon
                    >
                  </v-btn>
                </template>
                <v-date-picker
                  class="d-block"
                  :min="minDate"
                  color="accent"
                  v-model="datesRange"
                  @input="autoSelectDays"
                  range
                  :title-date-format="() => formatedDatesRange"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <h4>{{ $t("days") }}</h4>
              <v-chip-group
                v-model="daysSelected"
                active-class="white"
                multiple
              >
                <v-chip
                  outlined
                  class="white--text"
                  v-for="(day, key) of days"
                  :key="key"
                  :value="key"
                  >{{ day }}</v-chip
                >
              </v-chip-group>
            </v-col>
          </v-row>

          <v-tabs
            v-model="tab"
            background-color="transparent"
            @change="changeTab"
          >
            <v-tab
              v-for="(tab, key) of tabs"
              :key="key"
              class="white--text"
              active-class="active-tab-bg"
            >
              {{ $t(tab) }}
            </v-tab>
          </v-tabs>
        </div>
      </v-card-title>
      <v-card-text class="px-4">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="tabs.indexOf('price')">
            <a-chm-bulk-edit-rates-form
              :rateForm="rateForm"
            ></a-chm-bulk-edit-rates-form>
          </v-tab-item>
          <v-tab-item :value="tabs.indexOf('availability')">
            <a-chm-bulk-edit-availability-form
              :availabilityForm="availabilityForm"
            ></a-chm-bulk-edit-availability-form>
          </v-tab-item>
          <v-tab-item :value="tabs.indexOf('min_max_nights')">
            <a-chm-bulk-edit-min-max-nights-form
              :minMaxNightsForm="minMaxNightsForm"
            ></a-chm-bulk-edit-min-max-nights-form>
          </v-tab-item>
        </v-tabs-items>

        <v-row>
          <v-col class="py-0">
            <a-chm-bulk-edit-graph
              :series="seriesData"
              :daysSelected="daysSelected"
              :new_value="rateForm.value"
              :operation="operation"
              :graphValue="tabs[tab]"
              v-if="tabs[tab] === 'price'"
              :overlay="overlayGraph"
            ></a-chm-bulk-edit-graph>
            <a-chm-bulk-edit-graph
              :series="seriesData"
              :daysSelected="daysSelected"
              :new_value="availabilityForm.value"
              :graphValue="tabs[tab]"
              title="availability"
              v-if="tabs[tab] === 'availability'"
              :overlay="overlayGraph"
            ></a-chm-bulk-edit-graph>
          </v-col>
        </v-row>
        <v-row v-if="summaryAction">
          <v-col>
            <v-alert type="info" dense>{{ summaryAction }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn
          depressed
          rounded
          @click="save"
          color="info"
          class="px-12"
          :disabled="!formIsValid"
          >{{ $t("update") }}</v-btn
        >
        <v-btn depressed rounded outlined @click="cancel" class="px-12">{{
          $t("cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Vue from "vue";
import aChmBulkEditRatesForm from "@/components/channel-manager/aChmBulkEditRatesForm.vue";
import aChmBulkEditAvailabilityForm from "@/components/channel-manager/aChmBulkEditAvailabilityForm.vue";
import aChmBulkEditMinMaxNightsForm from "@/components/channel-manager/aChmBulkEditMinMaxNightsForm.vue";
import aChmBulkEditGraph from "@/components/channel-manager/aChmBulkEditGraph.vue";
import { channelManagerCellOperations } from "@/utils";
import { mapGetters } from "vuex";
const siteMinderTypeEditOptions = [
  "Rates",
  "Availability",
  "StopSell",
  "MinStay",
  "MaxStay"
];
const days = [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek => {
  // keep this date as it will produce lun, mar, mie, jue, vie, sab, dom
  return Vue.moment()
    .weekday(dayOfWeek)
    .format("dddd")
    .substring(0, 3);
});
const enumerateDaysBetweenDates = function(startDate, endDate) {
  let dates = [];

  let currDate = Vue.moment(startDate).startOf("day");
  let lastDate = Vue.moment(endDate).startOf("day");

  dates.push(currDate.clone());
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.clone());
  }

  return dates;
};

const getUTCDaysBetweenDates = function(datesRange) {
  return Array.from(
    new Set(
      enumerateDaysBetweenDates(...datesRange).map(e => Vue.moment(e).weekday())
    )
  );
};
export default {
  components: {
    aChmBulkEditRatesForm,
    aChmBulkEditAvailabilityForm,
    aChmBulkEditMinMaxNightsForm,
    aChmBulkEditGraph
  },
  props: {
    currency: {
      type: String,
      default: "eur"
    }
  },

  data: () => ({
    overlayGraph: false,
    isValid: false,
    refreshKey: 0,
    days,
    daysSelected: [],
    tab: null,
    menuPeriod: false,
    datesRange: [],

    rateForm: {
      type_rate_change: "relative_percentage",
      value: undefined,
      selectedRates: []
    },
    availabilityForm: {
      value: undefined,
      selectedRates: []
    },
    minMaxNightsForm: {
      min_nights_value: undefined,
      max_nights_value: undefined,
      selectedRates: []
    },

    selectOptions: siteMinderTypeEditOptions,
    typeOfEdit: "Rates",
    value: undefined,
    menuStopSell: false,
    datesStopSell: [],
    operations: channelManagerCellOperations()
  }),
  mounted() {
    const clickedRateId =
      this.$store.state.channelmanager &&
      this.$store.state.channelmanager.clickedRateId;
    let metaClickedRates =
      this.$store.state.channelmanager &&
      this.$store.state.channelmanager.metaClickedRates;
    const displayedDays =
      this.$store.state.channelmanager &&
      this.$store.state.channelmanager.channelManagerTableData.days;

    // it's a label click
    let period = metaClickedRates;
    if (clickedRateId) {
      this.rateForm.selectedRates.push(clickedRateId);
      // this.availabilityForm.selectedRates.push(`rate_id_${clickedRateId}`);
      this.minMaxNightsForm.selectedRates.push(clickedRateId);

      period = [
        {
          date: displayedDays[0]
        },
        {
          date: displayedDays[displayedDays.length - 1]
        }
      ];
    }
    if (period.length === 0) {
      period = [
        {
          date: displayedDays[0]
        },
        {
          date: displayedDays[displayedDays.length - 1]
        }
      ];
    }
    if (
      this.rateForm.selectedRates.length === 0 &&
      metaClickedRates.length === 0
    ) {
      this.rateForm.selectedRates.push(this.firstRate());
      this.availabilityForm.selectedRates.push(this.firstRoom());
      this.minMaxNightsForm.selectedRates.push(this.firstRate());
    }

    if (period.length === 2) {
      this.datesRange = period.map(e => e.date).sort((a, b) => a - b);
      this.daysSelected = getUTCDaysBetweenDates(this.datesRange);
    }

    // it's a click + shift click
    if (metaClickedRates.length === 2) {
      const selectedRatesIds = this.$store.getters.filteredRows
        .slice(metaClickedRates[0].row_index, metaClickedRates[1].row_index + 1)
        .map(e => e.rate_id);

      this.rateForm.selectedRates.push(...selectedRatesIds);
      // this.availabilityForm.selectedRates.push(
      //   ...selectedRatesIds.map(e => `rate_id_${e}`)
      // );
      this.minMaxNightsForm.selectedRates.push(...selectedRatesIds);
    }
  },
  watch: {
    shouldRequestGraphData(newValue) {
      if (newValue !== false) {
        this.fetchGraph();
      }
    }
  },
  computed: {
    currencyIcon() {
      return `mdi-currency-${this.currency.toLowerCase()}`;
    },
    datesStopSellText() {
      return this.datesStopSell.join(" - ");
    },
    editingRates() {
      return this.typeOfEdit === "Rates";
    },
    editingStopSell() {
      return this.typeOfEdit === "StopSell";
    },
    editingNumbers() {
      return ["Availability", "MinStay", "MaxStay"].includes(this.typeOfEdit);
    },
    minDate() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    formatedDatesRange() {
      if (this.datesRange.length > 1) {
        const [monthIni, monthEnd] = [
          Vue.moment(this.datesRange[0]).format("MMMM"),
          Vue.moment(this.datesRange[1]).format("MMMM")
        ];
        return monthIni !== monthEnd
          ? this.$i18n.t("from_to_dates", {
              from: Vue.moment(this.datesRange[0]).format("D MMMM"),
              to: Vue.moment(this.datesRange[1]).format("D MMMM")
            })
          : this.$i18n.t("from_day_to_day_of_month", {
              from: Vue.moment(this.datesRange[0]).format("D"),
              to: Vue.moment(this.datesRange[1]).format("D"),
              month: monthIni
            });
      }
      return "";
    },
    formIsValid() {
      return (
        this.isTabValid() &&
        this.datesRange.length === 2 &&
        this.days.length > 0
      );
    },
    shouldRequestGraphData() {
      const shouldRequest =
        this.datesRange.length === 2 &&
        ((this.tabs[this.tab] === "price" &&
          this.rateForm.selectedRates &&
          this.rateForm.selectedRates.length > 0) ||
          (this.tabs[this.tab] === "availability" &&
            this.availabilityForm.selectedRates &&
            this.availabilityForm.selectedRates.length > 0));
      // console.log("computing shouldRequestGraphData", shouldRequest);
      if (shouldRequest) {
        return Math.random();
      }
      return shouldRequest;
    },
    seriesData() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.channelManagerGraphData.map(e => ({
          id: e.id,
          name: e.name,
          values: e.values.map(value => [
            //new Date(value[0]).getTime(),
            Vue.moment(value[0] + " +0000", "YYYY-MM-DD Z").valueOf(),
            value[1]
          ])
        }))
      );
    },
    summaryAction() {
      if (this.formIsValid) {
        let key;
        if (this.tabs[this.tab] === "price") {
          key = `summary_action_rates_${this.rateForm.type_rate_change}`;
          return this.$i18n.t(key, {
            value: this.rateForm.value,
            sign: this.rateForm.value >= 0 ? "+" : "",
            currency: "€",
            from_to_str: this.formatedDatesRange,
            days_of_week: this.daysSelectedSorted.join(", "),
            rates_str: this.$store.getters.tableHotelLabels
              .filter(
                e => e.isRate && this.rateForm.selectedRates.includes(e.rate_id)
              )
              .map(e => `${e.rate_name} (${e.room_name})`)
              .join(", ")
          });
        }
      }
      return "";
    },
    daysSelectedSorted() {
      return this.daysSelected
        .map(e => this.days[e])
        .sort((a, b) => this.days.indexOf(a) - this.days.indexOf(b));
    },
    operation() {
      return this.operations[this.rateForm.type_rate_change];
    },
    tabs() {
      const tabs = [];
      tabs.push("price");
      if (this.current_hotel.settings.siteminder_edit_availability) {
        tabs.push("availability");
      }
      tabs.push("min_max_nights");
      return tabs;
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    async changeTab() {
      await this.$store.dispatch("resetChannelManagerGraphData");
      // silly reassignment to force shouldRequestGraphData
      //this.refreshKey++;
    },
    async fetchGraph() {
      const [roomsIds, ratesIds] = [
        this.availabilityForm.selectedRates
          .map(e => e.split("room_id_")[1] * 1)
          .filter(e => e),
        this.availabilityForm.selectedRates
          .map(e => e.split("rate_id_")[1] * 1)
          .filter(e => e)
      ];
      this.overlayGraph = true;
      await this.$store.dispatch("fetchChannelManagerGraphData", {
        start_date: this.datesRange[0],
        end_date: this.datesRange[1],
        action_type: this.tabs[this.tab],
        entities: {
          rate_ids:
            this.tabs[this.tab] === "price"
              ? this.rateForm.selectedRates
              : ratesIds,
          room_ids: this.tabs[this.tab] === "availability" ? roomsIds : []
        }
      });
      this.overlayGraph = false;
    },
    save() {
      switch (this.tabs[this.tab]) {
        case "price":
          this.savePrices();
          break;
        case "availability":
          this.saveAvailability();
          break;
        case "min_max_nights":
          this.saveMinMaxNights();
          break;
      }
      this.$emit("formSubmitted");
      this.resetStoreValues();
    },
    savePrices() {
      this.$store.dispatch("applyRatesPriceChanges", {
        form: {
          entity_ids: this.rateForm.selectedRates,
          type_rate_change: this.rateForm.type_rate_change,
          value: this.rateForm.value,
          entity_type: "rate"
        },
        daysSelected: this.daysSelected,
        operation: this.operation,
        datesRange: this.datesRange
      });
    },
    saveAvailability() {
      const [roomsIds, ratesIds] = [
        this.availabilityForm.selectedRates
          .map(e => e.split("room_id_")[1] * 1)
          .filter(e => e),
        this.availabilityForm.selectedRates
          .map(e => e.split("rate_id_")[1] * 1)
          .filter(e => e)
      ];
      if (roomsIds.length > 0) {
        this.$store.dispatch("applyAvailabilityChanges", {
          form: {
            entity_ids: roomsIds,
            value: this.availabilityForm.value,
            entity_type: "room"
          },
          daysSelected: this.daysSelected,
          operation: this.operations.absolute,
          datesRange: this.datesRange
        });
      }
      if (ratesIds.length > 0) {
        this.$store.dispatch("applyAvailabilityChanges", {
          form: {
            entity_ids: ratesIds,
            value: this.availabilityForm.value,
            entity_type: "rate"
          },
          daysSelected: this.daysSelected,
          operation: this.operations.absolute,
          datesRange: this.datesRange
        });
      }
      // this.$store.dispatch("applyAvailabilityChanges", {
      //   roomsIds,
      //   ratesIds,
      //   availabilityForm: this.availabilityForm,
      //   daysSelected: this.daysSelected,
      //   datesRange: this.datesRange
      // });
    },
    saveMinMaxNights() {
      if (this.minMaxNightsForm.min_nights_value) {
        this.$store.dispatch("applyMinNightsChanges", {
          form: {
            entity_ids: this.minMaxNightsForm.selectedRates,
            value: this.minMaxNightsForm.min_nights_value,
            entity_type: "rate"
          },
          daysSelected: this.daysSelected,
          operation: this.operations.absolute,
          datesRange: this.datesRange
        });
      }
      if (this.minMaxNightsForm.max_nights_value) {
        this.$store.dispatch("applyMaxNightsChanges", {
          form: {
            entity_ids: this.minMaxNightsForm.selectedRates,
            value: this.minMaxNightsForm.max_nights_value,
            entity_type: "rate"
          },
          daysSelected: this.daysSelected,
          operation: this.operations.absolute,
          datesRange: this.datesRange
        });
      }
    },
    isTabValid() {
      switch (this.tabs[this.tab]) {
        case "price":
          return this.isPricesValid();
        case "availability":
          return this.isAvailabilityValid();
        case "min_max_nights":
          return this.isMinMaxNightsValid();
      }
    },
    isPricesValid() {
      return (
        this.rateForm.selectedRates.length > 0 &&
        this.rateForm.type_rate_change &&
        this.rateForm.value
      );
    },
    isAvailabilityValid() {
      return (
        this.availabilityForm.selectedRates.length > 0 &&
        this.availabilityForm.value
      );
    },
    isMinMaxNightsValid() {
      return (
        this.minMaxNightsForm.selectedRates.length > 0 &&
        (this.minMaxNightsForm.min_nights_value ||
          this.minMaxNightsForm.max_nights_value)
      );
    },
    cancel() {
      // console.log("Form cancelled");
      this.$emit("formCancel");
      this.resetStoreValues();
    },
    resetStoreValues() {
      this.$store.dispatch("resetMetaClickedRate");
      this.$store.dispatch("setClickedRateId", null);
    },
    autoSelectDays(e) {
      if (e.length === 2) {
        this.daysSelected = getUTCDaysBetweenDates(e);
      }
    },
    firstRate() {
      const hotelLables = this.$store.state.channelmanager
        .channelManagerTableData.hotelLabels;
      for (let label in hotelLables) {
        if (hotelLables[label].isRate) {
          return hotelLables[label].rate_id;
        }
      }
    },
    firstRoom() {
      const hotelLables = this.$store.state.channelmanager
        .channelManagerTableData.hotelLabels;
      for (let label in hotelLables) {
        if (hotelLables[label].isRoom) {
          return "room_id_" + hotelLables[label].room_id;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.active-tab-bg {
  background-color: transparent;
  font-weight: bold;
  color: white;
}
.image-bg {
  background-image: url("/img/header.jpg");
}
*::v-deep .v-tabs-slider {
  background-color: white;
  color: white;
}
</style>
