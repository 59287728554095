<template>
  <v-row>
    <v-col cols="3" class="py-0">
      <v-text-field
        v-model="item.settings.ranges_occ_availability.min"
        @input="
          item.settings.ranges_occ_availability.min = parseInt(
            item.settings.ranges_occ_availability.min
          )
        "
        :label="$t('min_color_red_label')"
        :rules="rules"
        type="number"
        outlined
        rounded
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="3" class="py-0">
      <v-text-field
        v-model="item.settings.ranges_occ_availability.max"
        @input="
          item.settings.ranges_occ_availability.max = parseInt(
            item.settings.ranges_occ_availability.max
          )
        "
        :label="$t('max_color_green_label')"
        :rules="rules"
        type="number"
        outlined
        rounded
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "a-hotel-configuration-ranges-occ-availability",
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  computed: {
    rules() {
      return [v => (v && v >= 0 && v <= 100) || this.$t("validation_percent")];
    }
  },
  beforeMount() {
    if (!this.item.settings.ranges_occ_availability) {
      this.item.settings.ranges_occ_availability = {
        min: 30,
        max: 70
      };
    }
  }
};
</script>
