<template>
  <v-row v-if="available[permission]">
    <v-col cols="12">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="d-flex justify-start align-center"
                >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-avatar
                        :outlined="!item.permissions[permission]"
                        :color="
                          item.permissions[permission]
                            ? 'secondary'
                            : 'grey lighten-3'
                        "
                      >
                        <v-icon :dark="item.permissions[permission]">
                          {{ icon }}
                        </v-icon>
                        <v-fade-transition>
                          <v-overlay
                            v-if="
                              hover &&
                                item.permissions[permission] &&
                                !mandatory
                            "
                            absolute
                            color="primary"
                            opacity="1"
                          >
                            <v-btn
                              icon
                              color="secondary"
                              @click="toggle(permission)"
                              rounded
                              ><v-icon color="white">mdi-close</v-icon></v-btn
                            >
                          </v-overlay>
                        </v-fade-transition>
                      </v-avatar>
                    </template>
                  </v-hover>
                  <h2
                    class="ml-4 text-h6 section-title"
                    :class="
                      item.permissions[permission]
                        ? 'primary--text'
                        : 'black--text'
                    "
                  >
                    {{ $t("permission_" + permission) }}
                  </h2>
                </v-col>
                <v-col cols="12" md="9">
                  <v-row class="section_options">
                    <permissions-editor-switch
                      v-for="p in section_visible_permissions"
                      :key="p"
                      :label="'permission_' + p"
                      :permission="p"
                      :available="available"
                      :item="item"
                      :disabled="!item.permissions[permission]"
                      @changedPermission="changedPermission"
                    ></permissions-editor-switch>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Travel to the best outdoor experience on planet Earth. A vacation you will never forget! -->
            </v-card-text>

            <v-fade-transition>
              <v-overlay
                v-if="hover && !item.permissions[permission]"
                absolute
                color="primary"
              >
                <v-btn
                  color="secondary"
                  @click="toggle(permission)"
                  rounded
                  prepend-icon="mdi-check"
                  >{{ $t("enable") }}</v-btn
                >
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import PermissionsEditorSwitch from "@/components/permissions/PermissionsEditorSwitch.vue";
import { mapGetters } from "vuex";
export default {
  components: { PermissionsEditorSwitch },
  data: () => ({}),
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    permission: {
      type: String,
      required: true
    },
    available: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    section_permissions: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    superuser_permissions: {
      type: Array,
      default: function() {
        return [];
      },
      required: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  watch: {},
  methods: {
    toggle(permission) {
      this.item.permissions[permission] = !this.item.permissions[permission];
    },
    changedPermission(permission) {
      if (this.single) {
        for (let p of this.section_permissions) {
          if (p !== permission) {
            this.item.permissions[p] = false;
          }
        }
      }
    }
  },
  computed: {
    section_visible_permissions() {
      let permissions = [];
      for (let p of this.section_permissions) {
        if (
          this.current_user.superuser ||
          !this.superuser_permissions.includes(p)
        ) {
          permissions.push(p);
        }
      }
      return permissions;
    },
    ...mapGetters({
      current_user: "current_user"
    })
  }
};
</script>
<style scoped>
/* .section-title{
    flex-basis: 350px;
  } */
</style>
