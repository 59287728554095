var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-page',{attrs:{"id":"hotel-management-app","image_source":_vm.image_source,"image_height":_vm.image_height}},[(_vm.show_editing)?_c('div',{staticClass:"d-flex justify-center align-center text-center white--text mb-6",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"px-10 py-12"},[_c('span',{staticClass:"text-h5 d-block"},[_vm._v(_vm._s(_vm.$t("modifying_hotel")))])])]):_vm._e(),(!_vm.show_editing)?_c('div',{staticClass:"d-flex justify-center align-center text-center white--text mb-6",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"px-10"},[_c('span',{staticClass:"text-h1 d-block"},[_vm._v(_vm._s(_vm.hotels_count))]),_c('span',{staticClass:"text-h5 d-block"},[_vm._v(_vm._s(_vm.$t("hotels")))])]),_c('div',{staticClass:"px-10"},[_c('span',{staticClass:"text-h1 d-block"},[_vm._v(_vm._s(_vm.active_hotels_count))]),_c('span',{staticClass:"text-h5 d-block"},[_vm._v(_vm._s(_vm.$t("actives")))])])]):_vm._e(),(!_vm.show_editing)?_c('v-card',{staticClass:"mb-5"},[_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("hotels"))+" ")]),_c('v-spacer'),_c('v-text-field',{ref:"search_hotels",staticStyle:{"max-width":"300px"},attrs:{"block":"","rounded":"","outlined":"","dense":"","clearable":"","prepend-inner-icon":"mdi-magnify","placeholder":_vm.$t('search_hotel'),"hide-details":""},on:{"click:clear":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filtered_hotels,"item-class":_vm.getDatatableRowClass,"no-data-text":_vm.$t('no_hotels_found'),"hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.showModifyHotel},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("ml-" + (_vm.getLevels(item) * 5))}),(parseInt(item.is_group) === 1)?_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16","color":"accent"}},[_vm._v(" mdi-home-city ")]):_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16","color":"primary"}},[_vm._v(" mdi-office-building ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [(parseInt(item.is_group) === 0)?[_vm._v(" "+_vm._s(item.audit.city)+" ")]:_vm._e()]}},{key:"item.pms",fn:function(ref){
var item = ref.item;
return [(parseInt(item.is_group) === 0)?[_vm._v(" "+_vm._s(item.audit.pms)+" ")]:_vm._e()]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [(parseInt(item.is_group) === 0)?[_vm._v(" "+_vm._s(item.audit.channel_manager)+" ")]:_vm._e()]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [(parseInt(item.is_group) === 0)?[(item.audit.rate)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle-outline ")])]:_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(parseInt(item.active))?[_c('v-chip',{attrs:{"small":"","color":"secondary"}},[_vm._v(_vm._s(_vm.$t("active")))])]:[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t("unactive")))])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(
              parseInt(item.active) &&
                parseInt(item.id) !== parseInt(_vm.current_hotel.id)
            )?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.deactivateHotel(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-off-outline")])],1):_vm._e(),(
              !parseInt(item.active) &&
                parseInt(item.id) !== parseInt(_vm.current_hotel.id)
            )?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.activateHotel(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1):_vm._e()]}}],null,true)})],1)],1):_vm._e(),(_vm.show_editing)?_c('v-card',{staticClass:"mb-5"},[_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"title font-weight-light"},[_c('v-btn',{staticClass:"mb-1",attrs:{"icon":"","fab":""},on:{"click":_vm.hideModifyHotel}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('span',[_vm._v(_vm._s(_vm.$t("you_are_editing")))]),_c('span',{staticClass:"font-weight-bold ml-2"},[_vm._v(_vm._s(_vm.item_to_edit.name))]),(parseInt(_vm.item_to_edit.is_group) === 1)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s("(" + _vm.$t("group") + ")")+" ")]):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"secondary","disabled":!_vm.valid_edit,"loading":_vm.saving},on:{"click":_vm.setHotel}},[_vm._v(_vm._s(_vm.$t("save")))])],1),_c('v-card-text',{staticClass:"pt-0 pl-16"},[_c('v-form',{model:{value:(_vm.valid_edit),callback:function ($$v) {_vm.valid_edit=$$v},expression:"valid_edit"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"maxlength":"50","counter":"","rules":_vm.name_rules,"label":_vm.$t('name')},model:{value:(_vm.item_to_edit.name),callback:function ($$v) {_vm.$set(_vm.item_to_edit, "name", $$v)},expression:"item_to_edit.name"}})],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-tabs',{attrs:{"rounded":""},model:{value:(_vm.tab_config),callback:function ($$v) {_vm.tab_config=$$v},expression:"tab_config"}},[_c('v-tabs-slider'),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      _vm.item_to_edit.permissions.rms_hotelsdot
                  )?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("audit"))+" ")]):_vm._e(),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      (_vm.item_to_edit.permissions.rms ||
                        _vm.item_to_edit.permissions.rms_hotelsdot ||
                        _vm.item_to_edit.permissions.rms_axel ||
                        _vm.item_to_edit.permissions.rms_clicktotravel)
                  )?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("configuration"))+" ")]):_vm._e(),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      _vm.item_to_edit.permissions.rate
                  )?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("compset"))+" ")]):_vm._e(),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("visibilities"))+" ")]),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      _vm.item_to_edit.permissions.rms_hotelsdot &&
                      Object.keys(_vm.item_to_edit.audit.data_validation).length >
                        0
                  )?_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("data_validation"))+" ")]):_vm._e()],1),_c('v-tabs-items',{staticClass:"px-4",model:{value:(_vm.tab_config),callback:function ($$v) {_vm.tab_config=$$v},expression:"tab_config"}},[(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      _vm.item_to_edit.permissions.rms_hotelsdot
                  )?_c('v-tab-item',[_c('a-hotel-audit',{attrs:{"item":_vm.item_to_edit}})],1):_vm._e(),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      (_vm.item_to_edit.permissions.rms ||
                        _vm.item_to_edit.permissions.rms_hotelsdot ||
                        _vm.item_to_edit.permissions.rms_axel ||
                        _vm.item_to_edit.permissions.rms_clicktotravel)
                  )?_c('v-tab-item',[_c('a-hotel-configuration',{attrs:{"item":_vm.item_to_edit}})],1):_vm._e(),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      _vm.item_to_edit.permissions.rate
                  )?_c('v-tab-item',[_c('a-hotel-compset',{attrs:{"item":_vm.item_to_edit}})],1):_vm._e(),_c('v-tab-item',[_c('permissions-editor',{attrs:{"item":_vm.item_to_edit}})],1),(
                    parseInt(_vm.item_to_edit.is_group) === 0 &&
                      _vm.item_to_edit.permissions.rms_hotelsdot &&
                      Object.keys(_vm.item_to_edit.audit.data_validation).length >
                        0
                  )?_c('v-tab-item',[_c('a-hotel-data-validation',{attrs:{"item":_vm.item_to_edit}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }