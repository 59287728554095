<template>
  <div
    class="cell-padding"
    :class="{
      'grey--text text--lighten-1': rate.parent_rate_id && !rate.edited,
      ...bgColor,
      'info--text': rate.edited,
      // 'info--text text--lighten-2 text-decoration-line-through': rate.edited && rate.parent_rate_id
      'info--text text--lighten-2 ': rate.edited && rate.parent_rate_id
    }"
  >
    <v-row class="h-half pa-0 ma-0">
      <v-col
        class="pa-0"
        :style="{
          'font-weight:500;font-size: 13px;':
            !rate.isChannel || !rate.parent_rate_id
        }"
      >
        <!-- <span>{{ rate.price }} {{ rate.currencySymbol }}</span> -->
        <a-kpi
          :kpi="'price'"
          :value="rate[objKey]"
          :increment="true"
          :vs_value="initialValue"
          :increment_value="initialValue && rate[objKey] - initialValue"
          :simple="true"
        >
        </a-kpi>
      </v-col>
    </v-row>
    <v-row class="h-half text-center pa-0 ma-0">
      <v-col class="pa-0">
        <v-tooltip v-if="rate.stop_sell" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="12"
              v-bind="attrs"
              v-on="on"
              :class="{
                'text--lighten-2': rate.parent_rate_id,
                'text--lighten-1': !rate.parent_rate_id,
                'grey--text': !rate.edited,
                'info--text': rate.edited
              }"
              >mdi-hand-back-right-off</v-icon
            >
          </template>
          <span class="px-2">{{ $t("stop_sell") }}</span>
        </v-tooltip>
        <span v-else> {{ " " }} </span>
      </v-col>
      <v-col class="pa-0">
        <v-tooltip v-if="rate.minStay" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="16"
              v-bind="attrs"
              v-on="on"
              :class="{
                'text--lighten-2': rate.parent_rate_id,
                'text--lighten-1': !rate.parent_rate_id,
                'grey--text':
                  !rate.edited && rate.minStay === rate.original_minStay,
                'info--text':
                  rate.edited && rate.minStay === rate.original_minStay,
                'green--text': rate.minStay !== rate.original_minStay
              }"
              >mdi-numeric-{{ rate.minStay }}-circle-outline</v-icon
            >
          </template>
          <span class="px-2">{{ $t("min_nights") }}</span>
        </v-tooltip>
        <span v-else>&nbsp;</span>
      </v-col>
      <v-col class="pa-0">
        <v-tooltip v-if="rate.maxStay" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="16"
              v-bind="attrs"
              v-on="on"
              :class="{
                'text--lighten-2': rate.parent_rate_id,
                'text--lighten-1': !rate.parent_rate_id,
                'grey--text': !rate.edited,
                'info--text': rate.edited
              }"
              >mdi-numeric-{{ rate.maxStay }}-circle</v-icon
            >
          </template>
          <span class="px-2">{{ $t("max_nights") }}</span>
        </v-tooltip>
        <span v-else>&nbsp;</span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import aKpi from "@/components/aKpi.vue";

function rateBelongsToRange(
  currentRate,
  currentKey,
  rateFirstSelected,
  rateLastSelected
) {
  if (!rateLastSelected) {
    return false;
  }

  let [dateCurrentRate, dateFirstSelected, dateLastSelected] = [
    currentRate && Vue.moment(currentRate.date),
    rateFirstSelected && Vue.moment(rateFirstSelected.date),
    rateLastSelected && Vue.moment(rateLastSelected.date)
  ];

  let [rowIndexCurrentRate, rowIndexFirstSelected, rowIndexLastSelected] = [
    currentKey,
    rateFirstSelected && rateFirstSelected.row_index,
    rateLastSelected && rateLastSelected.row_index
  ];

  let x_direction = dateLastSelected - dateFirstSelected > 0 ? 1 : -1;
  let y_direction = rowIndexLastSelected - rowIndexFirstSelected > 0 ? 1 : -1;

  const belongsToSelectedRange =
    x_direction * (dateLastSelected - dateCurrentRate) >= 0 &&
    x_direction * (dateCurrentRate - dateFirstSelected) >= 0 &&
    y_direction * (rowIndexLastSelected - rowIndexCurrentRate) >= 0 &&
    y_direction * (rowIndexCurrentRate - rowIndexFirstSelected) >= 0;

  return belongsToSelectedRange;
}

export default {
  components: { aKpi },
  // data: () => ({
  //   initialValue: undefined
  // }),
  mounted() {
    // this.initialValue = !this.initialValue
    //   ? this.rate.price
    //   : this.initialValue;
  },
  props: {
    rate: {
      type: Object,
      default: () => ({})
    },
    objKey: {
      type: String,
      default: "price"
    },
    row_key: {
      type: Number,
      default: null
    }
  },
  computed: {
    originalObjKey() {
      return `original_${this.objKey}`;
    },
    initialValue() {
      return this.rate && this.rate[this.originalObjKey]
        ? this.rate[this.originalObjKey]
        : 0;
    },
    bgColor() {
      const classObj = {};

      if (this.rate) {
        // modified cell
        classObj["modified-cell"] =
          this.rate[this.objKey] !== this.rate[this.originalObjKey] ||
          this.rate.available_of_rooms !==
            this.rate.original_available_of_rooms;
      }

      if (
        this.$store.state &&
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.metaClickedRates.length > 0
      ) {
        const hasBeenMetaClicked = !!this.$store.state.channelmanager.metaClickedRates.find(
          rate =>
            this.rate.isRate &&
            rate.rate_id === this.rate.rate_id &&
            rate.date === this.rate.date
        );
        if (hasBeenMetaClicked) {
          // classObj["success lighten-4"] = true;
          classObj["selected-cell"] = true;
        } else {
          const storeHoveredRate = this.$store.state.channelmanager.hoveredRate;
          const [
            firstMetaClickedRate,
            lastMetaClickedRate
          ] = this.$store.state.channelmanager.metaClickedRates;

          // classObj["success lighten-5"] = rateBelongsToRange(
          //   this.rate,
          //   this.row_key,
          //   firstMetaClickedRate,
          //   lastMetaClickedRate || storeHoveredRate
          // );
          classObj["selected-cell"] = rateBelongsToRange(
            this.rate,
            this.row_key,
            firstMetaClickedRate,
            lastMetaClickedRate || storeHoveredRate
          );
        }
      }

      return classObj;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/main.sass";

div.grey--text {
  &::v-deep .v-icon {
    color: rgba(0, 0, 0, 0.24);
  }
}
* {
  line-height: 1.215rem;
  .h-half {
    height: $minHeightColRow / 2 - 2px;
  }
}
.selected-cell {
  background-color: #e4e8f5 !important;
}
.modified-cell {
  background-color: map-get($green, "lighten-5");
}
.cell-padding {
  padding-top: 2px;
  padding-bottom: 2px;
}
.v-application .grey--text.text--lighten-1 {
  color: #999 !important;
  caret-color: #999 !important;
}
.v-application .grey--text.text--lighten-2 {
  color: #aaa !important;
  caret-color: #aaa !important;
}
</style>
