<template>
  <v-row class="row-rate mx-0" :class="{ 'expanded-channels': isExpanded }">
    <v-col class="rate-wrapper pa-0">
      <div class="label-wrapper d-flex align-center simple_view">
        <div
          class="flex-grow-1"
          :class="{ 'grey--text lighten-2': rowData.parent_rate_id }"
        >
          <template v-if="rowData.parent_rate_id">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex align-center justify-space-between"
                >
                  <span class="mr-4 d-flex align-start">
                    <!-- <v-icon size="16"> mdi-arrow-right-bottom </v-icon -->
                    {{ rowData.rate_name }}
                    {{
                      rowData.rate_description
                        ? `(${rowData.rate_description})`
                        : ""
                    }}</span
                  >

                  <v-btn
                    color="info"
                    class="px-2"
                    x-small
                    @click="loadChannels(rowData, rowIndex)"
                    v-if="rowData.channel_ids"
                    :loading="isLoadingChannels"
                    outlined
                    rounded
                  >
                    <b>{{ rowData.channel_ids.length }}</b>
                    <v-icon x-small class="ml-1">
                      <template v-if="isExpanded">
                        mdi-chevron-up
                      </template>
                      <template v-else>
                        mdi-chevron-down
                      </template>
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>
                <b
                  >{{ rowData.parent_rate_name }}
                  {{
                    rowData.parent_rate_description
                      ? `(${rowData.parent_rate_description})`
                      : ""
                  }}
                </b>
                {{ getLabelIncrement(rowData) }}
              </span>
            </v-tooltip>
          </template>
          <div
            v-else
            @click="markClickedRates(rowData, rowIndex)"
            class="d-flex master-rate justify-space-between align-center"
          >
            <span>
              {{ rowData.rate_name }}
              {{
                rowData.rate_description ? `(${rowData.rate_description})` : ""
              }}
            </span>
            <v-btn
              color="info"
              class="px-2"
              x-small
              @click="loadChannels(rowData, rowIndex)"
              v-if="rowData.channel_ids"
              :loading="isLoadingChannels"
              outlined
              rounded
            >
              <b>{{ rowData.channel_ids.length }}</b>
              <v-icon x-small class="ml-1">
                <template v-if="isExpanded">
                  mdi-chevron-up
                </template>
                <template v-else>
                  mdi-chevron-down
                </template>
              </v-icon>
            </v-btn>
            <v-icon class="" small>mdi-flash-outline</v-icon>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-window v-model="current_slide">
        <v-window-item v-for="(item, itemKey) of windowItems" :key="itemKey">
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(day, index) of getDays"
              :key="index"
              class="pa-0 channel-manager-day-column"
              :class="{ 'bg-weekend': isWeekend(day) }"
            >
              <div
                class="rate-wrapper"
                @mouseenter="
                  reportLocation({
                    rate_id: rowData.rate_id,
                    date: day,
                    row_index: rowIndex
                  })
                "
              >
                <div class="cell rate_data_main">
                  <div class="room-info">
                    <span class="" v-if="showMobile">
                      {{ rowData.days[day].price_mobile }}
                    </span>
                    <div
                      class=""
                      @click.shift.left.exact="
                        reportMetaClickedLocation({
                          rate_id: rowData.rate_id,
                          date: day,
                          row_index: rowIndex
                        })
                      "
                      v-else
                    >
                      <a-chm-editable-price
                        v-model="rowData.days[day]"
                        :info="{ ...rowData, date: day }"
                        v-if="
                          rowData.days[day].price !== undefined &&
                            rowData.days[day].price !== null &&
                            !rowData.parent_rate_id
                        "
                      >
                        <template v-slot:activator>
                          <a-chm-price-cell
                            :rate="{
                              ...rowData.days[day],
                              ...rowData,
                              date: day
                            }"
                            :row_key="rowIndex"
                            class="pointer"
                            v-if="rowData.days[day]"
                          ></a-chm-price-cell>
                        </template>
                      </a-chm-editable-price>
                      <a-chm-price-cell
                        :rate="{ ...rowData.days[day], ...rowData, date: day }"
                        :row_key="rowIndex"
                        v-else
                      ></a-chm-price-cell>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import aChmPriceCell from "@/components/channel-manager/aChmPriceCell.vue";
import aChmEditablePrice from "@/components/channel-manager/aChmEditablePrice.vue";
export default {
  components: { aChmPriceCell, aChmEditablePrice },
  props: {
    rowData: Object,
    rowIndex: Number,
    showMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    current_slide: 0,
    isLoadingChannels: false
  }),
  watch: {
    slide_status(newValue, oldValue) {
      if (newValue === 2) {
        this.current_slide = this.page;
      }
    },
    loadingChannelData(newValue) {
      if (newValue === false) {
        this.isLoadingChannels = false;
      }
    }
  },
  computed: {
    slide_status() {
      return this.$store.state.channelmanager.slide_status;
    },
    isExpanded() {
      return this.$store.state.channelmanager.expandedRateIds.includes(
        this.rowData.rate_id
      );
    },
    getDays() {
      return this.rowData && Object.keys(this.rowData.days);
    },
    getRate() {
      if (this.rowData) {
        let { days, ...rate } = this.rowData;
        return rate;
      }
      return {};
    },
    page() {
      return this.$store.state.channelmanager.page;
    },
    windowItems() {
      return this.$store.state.channelmanager.windowItems;
    },
    loadingChannelData() {
      return this.$store.state.channelmanager.loadingChannelData;
    }
  },
  methods: {
    isWeekend(day) {
      return Vue.moment(day).isoWeekday() >= 5;
    },
    markClickedRates(label, key) {
      const [dateMin, dateMax] = [
        this.$store.state.channelmanager.channelManagerTableData.days[0],
        this.$store.state.channelmanager.channelManagerTableData.days.at(-1)
      ];
      this.$store.dispatch("resetMetaClickedRate");
      this.$store.dispatch("setMetaClickedRate", {
        date: dateMin,
        row_index: key,
        rate_id: label.rate_id
      });
      this.$store.dispatch("setMetaClickedRate", {
        date: dateMax,
        row_index: key,
        rate_id: label.rate_id
      });
    },
    getLabelIncrement(label) {
      let increment = "";
      if (label.parent_rate_amount > 0 || label.parent_rate_percentage > 0) {
        increment += "+";
      }
      if (label.parent_rate_amount < 0 || label.parent_rate_percentage < 0) {
        increment += "-";
      }
      if (label.parent_rate_amount) {
        increment += `${label.parent_rate_amount}€`;
      } else if (label.parent_rate_percentage) {
        increment += `${label.parent_rate_percentage}%`;
      } else {
        increment = "=";
      }
      return increment;
    },
    reportLocation(event) {
      this.$emit("mouseRateHovering", event);
      this.$store.dispatch("setHoveredRate", event);
    },
    reportMetaClickedLocation(rate) {
      this.$emit("metaClickedRate", rate);
      this.$store.dispatch("setMetaClickedRate", rate);
    },
    async loadChannels(rowData, rowIndex) {
      const payload = {
        rate_id: rowData.rate_id,
        dates: Object.keys(rowData.days),
        rowIndex,
        applyChangesHistory: true
      };
      this.isLoadingChannels = true;
      if (!this.isExpanded) {
        await this.$store.dispatch("addExpandedRateId", rowData.rate_id);
        this.$store.dispatch(
          "fetchChannelManagerRateChannelsSubtable",
          payload
        );
      } else {
        // disable button or remove elements
        await this.$store.dispatch("removeExpandedRateId", rowData.rate_id);
        this.$store.dispatch(
          "removeChannelManagerRateChannelsSubtable",
          payload
        );
        this.isLoadingChannels = false;
      }
    }
  },
  emits: ["mouseRateHovering", "metaClickedRate"]
};
</script>
<style lang="scss" scoped>
$labelFontWeight: 700;
$labelFontSize: 0.8rem;
$labelColor: #555;
$bgColorHotel: #ddd;
.bg-weekend {
  background-color: $weekendBgColor;
}
.channel-manager-day-column {
  width: 100%;
  &:hover .cell:has(.day-info) {
    border-bottom: 3px solid $highlightColRowColor;
  }

  .cell {
    height: $minHeightColRow;
    text-align: center;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    border-bottom: $borderType $borderColor;
    border-top: $borderType $borderColor;
    // padding: 2px 1px;
    span {
      width: 100%;
      display: block;
      line-height: 20px;
      font-size: 12px;
    }
    &:has(.room-info span.room-availability) {
      background-color: $bgColorRoom;
    }
  }
  .day-heading {
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
    font-weight: 600;
  }
  .rate-wrapper {
    &:hover {
      background-color: #f5fdff;
    }
  }
}
.rate-wrapper,
.rate-availability-wrapper {
  max-width: $widthLeftSide;
  .label-wrapper {
    //background-color: #fff;
    &:hover,
    &.highlighted {
      border-right: 3px solid $highlightColRowColor;
    }
  }
  .master-rate {
    cursor: pointer;
  }
  cursor: default !important;
}
.label-wrapper {
  border-top: $borderType $borderColor;
  border-bottom: $borderType $borderColor;
  height: $minHeightColRow;
  border-right: 3px solid transparent;
  padding-left: 10px;
  width: $widthLeftSide;
  font-size: $labelFontSize;
  font-weight: $labelFontWeight;
  color: $labelColor;
  justify-content: space-between;
}
.relative {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.row-rate {
  &:hover {
    .label-wrapper {
      border-right: 3px solid $highlightColRowColor;
    }
  }
}
</style>
