<template>
  <v-card color="grey lighten-4" outlined>
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      {{ $t("rates") }}
    </v-card-title>
    <v-card-text style="height: 90%;" class="white pa-3">
      <v-data-table
        v-if="daily_rates.report"
        :headers="rateDayDetailHeaders"
        :items="comparisonItems"
        item-key="id"
        hidden-default-footer
      >
        <template v-slot:item.label="{ item }">
          <span
            class="caption font-weight-medium d-flex"
            :class="{ 'text-uppercase': item.self == 1 }"
          >
            <span :class="item.self ? 'font-weight-bold' : ''">{{
              item.label
            }}</span>
          </span>
        </template>
        <template
          v-slot:item.min_price="{ item }"
          v-if="daily_rates.data_to_compare"
        >
          <a-kpi
            v-if="item.min_price"
            :class="item.self ? 'font-weight-bold' : ''"
            class="subtitle-2"
            :kpi="'min_price'"
            :value="item.min_price"
            :vs_value="
              daily_rates.data_to_compare[
                'competitor::' + item.competitor_id
              ] &&
              daily_rates.data_to_compare['competitor::' + item.competitor_id][
                rate_date_to_compare
              ]
                ? [
                    daily_rates.data_to_compare[
                      'competitor::' + item.competitor_id
                    ][rate_date_to_compare][0].min_price
                  ]
                : null
            "
          >
            <template v-slot:prepend>
              <v-tooltip bottom content-class="a-rate-tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    v-if="item.is_mobile_offer"
                    color="yellow darken-2"
                    x-small
                    style="margin-bottom: 1px;"
                    class="mr-2"
                    >mdi-cellphone</v-icon
                  >
                </template>
                <span
                  class="text-center black--text"
                  style="font-size:13px;line-height:11px;"
                  v-html="$t('is_mobile_offer')"
                ></span>
              </v-tooltip>
              <v-tooltip bottom content-class="a-rate-tooltip">
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    class="blue--text text--darken-4 mt-n1 mr-2 "
                    v-if="item.is_genius_offer"
                    style="font-size:13px;font-weight:600;"
                    >.g</span
                  >
                </template>
                <span
                  class="text-center black--text"
                  style="font-size:13px;line-height:11px;"
                  v-html="$t('is_genius_offer')"
                ></span>
              </v-tooltip>
            </template>
          </a-kpi>
          <v-chip v-else outlined color="red" small dark> SOLD OUT</v-chip>
        </template>
        <template v-slot:item.regime="{ item }">
          <v-icon color="grey darken-2" small v-if="item.regime === 'alo'"
            >mdi-bed-king-outline</v-icon
          >
          <v-icon color="grey darken-2" small v-if="item.regime === 'ad'"
            >mdi-coffee-outline</v-icon
          >
          <v-icon color="grey darken-2" small v-if="item.regime === 'mp'"
            >mdi-silverware-variant</v-icon
          >
          <v-icon color="grey darken-2" small v-if="item.regime === 'pc'"
            >mdi-silverware</v-icon
          >
          <span
            :class="item.self ? 'font-weight-bold' : ''"
            class="caption ml-1"
            >{{ $t(item.regime) }}</span
          >
        </template>
        <template v-slot:item.room_type_name="{ item }">
          <span
            class="caption font-weight-medium"
            :class="item.self ? 'font-weight-bold' : ''"
            >{{ item.room_type_name }}</span
          >
        </template>
        <template v-slot:item.refund_policy="{ item }">
          <v-chip
            v-if="item.free_cancelation"
            color="green"
            outlined
            small
            :class="item.self ? 'font-weight-bold' : ''"
          >
            <v-icon left small
              >mdi-numeric-{{ item.free_cancelation_days }}-circle</v-icon
            >
            {{ $t("free_cancelation") }}
          </v-chip>
          <v-chip
            v-if="item.no_refundable"
            color="red darken-3"
            outlined
            small
            :class="item.self ? 'font-weight-bold' : ''"
          >
            {{ $t("no_refundable") }}
          </v-chip>
          <v-chip
            v-if="
              item.min_price && !item.no_refundable && !item.free_cancelation
            "
            color="yellow darken-3"
            outlined
            small
            :class="item.self ? 'font-weight-bold' : ''"
          >
            {{ $t("partial_refundable") }}
          </v-chip>
        </template>
        <template v-slot:item.minimum_nights="{ item }">
          <span :class="item.self ? 'font-weight-bold' : ''">
            {{ item.minimum_nights }}
          </span>
        </template>
        <template v-slot:item.capacity="{ item }">
          <v-icon
            class="ml-n1"
            v-for="i in item.room_type_adults"
            :key="
              'type_adults_' +
                i +
                '_' +
                item.competitor_id +
                '_' +
                rate_date_to_compare
            "
            color="grey darken-2"
            small
            >mdi-account</v-icon
          >
          <span class="pa-1"></span>
          <v-icon
            class="ml-n1 mt-1"
            v-for="i in item.room_type_childs"
            :key="
              'type_childs_' +
                i +
                '_' +
                item.competitor_id +
                '_' +
                rate_date_to_compare
            "
            color="grey darken-2"
            x-small
            >mdi-account</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi";
var numeral = require("numeral");

export default {
  name: "a-rms-day-detail",
  components: {
    aKpi
  },
  props: [],
  data: vm => ({
    subscribed_modules: ["rate_day_rates"],
    sortBy: "price",
    kpis: [
      "min_price",
      "minimum_nights",
      "regime",
      "refund_policy",
      "room_type_name",
      "capacity"
    ],
    type_kpis: [
      "room_type_name",
      "capacity",
      "price",
      "minimum_nights",
      "regime",
      "refund_policy",
      "available_rooms"
    ],
    tab: 0,
    loading_rates: false
  }),
  watch: {},
  methods: {
    competitorData(competitor) {
      var item = {};
      if (
        this.daily_rates &&
        this.daily_rates.report &&
        this.daily_rates.report["competitor::" + competitor.id] &&
        this.daily_rates.report["competitor::" + competitor.id][
          this.rate_date_to_compare
        ]
      ) {
        for (const kpi of Object.keys(
          this.daily_rates.report["competitor::" + competitor.id][
            this.rate_date_to_compare
          ][0]
        )) {
          item[kpi] = this.daily_rates.report["competitor::" + competitor.id][
            this.rate_date_to_compare
          ][0][kpi];
        }
      }
      item["label"] = item["self"] == 2 ? this.$t("your_web") : competitor.name;
      return item;
    }
  },
  computed: {
    comparisonItems() {
      var items = [];
      // Insert hotel
      var main_item = {};
      if (this.hotel_competitor && this.day_rates.report) {
        main_item["label"] = this.hotel_competitor.name;
        if (
          this.day_rates &&
          this.day_rates.report &&
          this.daily_rates.report["competitor::" + this.hotel_competitor.id] &&
          this.daily_rates.report["competitor::" + this.hotel_competitor.id][
            this.rate_date_to_compare
          ]
        ) {
          for (const kpi of Object.keys(
            this.daily_rates.report["competitor::" + this.hotel_competitor.id][
              this.rate_date_to_compare
            ][0]
          )) {
            main_item[kpi] = this.daily_rates.report[
              "competitor::" + this.hotel_competitor.id
            ][this.rate_date_to_compare][0][kpi];
          }
        }
        items.push(main_item);
      }

      // Insert hotel competitors
      for (const competitor of this.competitors) {
        // if (this.compared_competitors.indexOf(competitor.id) >= 0) {
        items.push(this.competitorData(competitor));
        // }
      }
      for (const web of this.webs) {
        if (this.compared_competitors.indexOf(web.id) >= 0) {
          items.push(this.competitorData(web));
        }
      }

      return items.sort((a, b) => {
        let result = 0;
        if (b.min_price === undefined || a.min_price < b.min_price) result = -1;
        if (a.min_price === undefined || a.min_price > b.min_price) result = 1;
        // console.log(a.min_price, b.min_price, result);
        return result;
      });
    },
    rateDayDetailHeaders() {
      var headers = [
        {
          text: "",
          value: "label",
          align: "start",
          sortable: false
        }
      ];
      for (const kpi of this.kpis) {
        headers.push({
          text: this.$t(kpi),
          value: kpi,
          align: "center",
          sortable: false
        });
      }

      return headers;
    },
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    compared_competitors: {
      get() {
        return this.$store.state.rate.compared_competitors;
      },
      set(value) {
        this.$store.dispatch("setComparedCompetitors", value);
      }
    },
    ...mapState({
      rate_date_to_compare: state => state.rate_date_to_compare,
      daily_rates: state => state.rate.daily_rates,
      day_rates: state => state.rate.day_rates,
      webs: state => state.parity.webs
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      competitors: "getOnlyCompetitors",
      getCompetitorById: "getCompetitorById",
      hotel_competitor: "getHotelFromCompetitors",
      l: "current_hotel_language"
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("fetchRateCompetitors");
      this.$store.dispatch("fetchRateDailyRates");
      this.$store.dispatch("fetchRateDayRates");
      this.compared_competitors = [];
    });
  }
};
</script>
<style lang="scss" scoped>
.active-tab-bg {
  background-color: transparent;
  font-weight: bold;
  color: white;
}
.image-bg {
  background-image: url("/img/header.jpg");
}
*::v-deep .v-tabs-slider {
  background-color: white;
  color: white;
}
</style>
