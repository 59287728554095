<template>
  <div>
    <v-row>
      <v-col class="px-12">
        <!-- <v-btn-toggle
          v-model="rateForm.type_rate_change"
          @change="rateForm.value = undefined"
          mandatory
        >
          <v-btn large outlined rounded value="absolute">
            <v-icon>
              mdi-currency-eur
            </v-icon>
            {{ $t("assign_new_price") }}
          </v-btn>
          <v-btn large outlined rounded value="relative_currency">
            <v-icon>
              mdi-menu-swap
            </v-icon>
            <v-icon>
              mdi-currency-eur
            </v-icon>
            {{ $t("increase_decrease_current_price") }}
          </v-btn>
          <v-btn large outlined rounded value="relative_percentage">
            <v-icon>
              mdi-menu-swap
            </v-icon>
            <v-icon>
              mdi-percent
            </v-icon>
            {{ $t("increase_decrease_current_price_percentage") }}
          </v-btn>
        </v-btn-toggle> -->
        <v-card
          outlined
          rounded="lg"
          style="max-width:60%; overflow:hidden; margin:auto"
        >
          <v-row no-gutters>
            <v-col cols="4" class="pa-0">
              <v-card flat tile @click="updateRateForm('absolute')">
                <v-list
                  class="py-0 option-rate"
                  :class="{
                    'info selected': rateForm.type_rate_change === 'absolute'
                  }"
                >
                  <v-list-item class="px-2">
                    <v-list-item-avatar
                      size="60"
                      color="white"
                      style="margin-right: 8px;"
                    >
                      <v-img :src="pencil" contain max-height="45"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2 font-weight-bold">
                        {{ $t("assign_new_price") }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-body-2 text-wrap"
                        style="font-size:11px !important;"
                        >{{ $t("assign_new_price_sub") }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="4" class="pa-0">
              <v-card flat tile @click="updateRateForm('relative_currency')">
                <v-list
                  class="py-0 option-rate"
                  :class="{
                    'info selected':
                      rateForm.type_rate_change === 'relative_currency'
                  }"
                >
                  <v-list-item class="px-2">
                    <v-list-item-avatar
                      size="60"
                      color="white"
                      style="margin-right: 8px;"
                    >
                      <v-img :src="euro" contain max-height="45"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2 font-weight-bold">
                        {{ $t("increase_decrease_current_price") }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-body-2 text-wrap"
                        style="font-size:11px !important;"
                        >{{
                          $t("increase_decrease_current_price_sub")
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="4" class="pa-0">
              <v-card flat tile @click="updateRateForm('relative_percentage')">
                <v-list
                  class="py-0 option-rate"
                  :class="{
                    'info selected':
                      rateForm.type_rate_change === 'relative_percentage'
                  }"
                >
                  <v-list-item class="px-2">
                    <v-list-item-avatar
                      size="60"
                      color="white"
                      style="margin-right: 8px;"
                    >
                      <v-img :src="percent" contain max-height="45"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2 font-weight-bold">
                        {{ $t("increase_decrease_current_price_percentage") }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-body-2 text-wrap"
                        style="font-size:11px !important;"
                        >{{
                          $t("increase_decrease_current_price_percentage_sub")
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" sm="2" class="pb-0">
        <v-text-field
          :label="
            rateForm.type_rate_change === 'absolute'
              ? $t('new_rate')
              : $t('modify_rate')
          "
          type="number"
          v-model="rateForm.value"
          required
          :rules="rules"
          outlined
          rounded
          dense
        >
          <template #append>
            <v-icon v-if="rateForm.type_rate_change !== 'relative_percentage'">
              mdi-currency-eur
            </v-icon>
            <v-icon v-if="rateForm.type_rate_change === 'relative_percentage'">
              mdi-percent
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="10" class="pb-0">
        <autocomplete-rates
          v-model="rateForm.selectedRates"
          required
          :rates="rates"
          :prependIcon="null"
        >
        </autocomplete-rates>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import autocompleteRates from "@/components/autocompleteRates.vue";
import pencil from "@/assets/imgs/pencil.png";
import euro from "@/assets/imgs/euro.png";
import percent from "@/assets/imgs/percent.png";
import { mapGetters } from "vuex";

export default {
  components: { autocompleteRates },
  //   props: {
  //     rates: {
  //       type: [Object],
  //       default: () => ({})
  //     }
  //   },
  props: {
    rateForm: {
      type: Object,
      default: () => ({
        type_rate_change: "relative_percentage",
        value: undefined,
        selectedRates: []
      })
    }
  },
  data: () => ({
    // toggle_exclusive: false,
    // value: undefined,
    // selectedRates: [],
    euro,
    pencil,
    percent
  }),
  emits: ["update:value", "update:selectedRates"],
  mounted() {
    if (!this.table_hotel_labels) {
      this.$store.dispatch("fetchChannelmanagerTableData", {
        page: 0,
        maxDays: 1
      });
    }
  },
  computed: {
    rates() {
      return (
        this.table_hotel_labels &&
        this.table_hotel_labels
          .filter(e => !e.isHotel)
          .filter(e => !e.isRate || (e.isRate && !e.parent_rate_id))
          .map(e => ({ ...e, disabled: e.isRoom, value: e.rate_id }))
      );
    },
    rules() {
      const arrRules = [v => !!v || this.$i18n.t("required_field")];
      if (this.rateForm.type_rate_change === "absolute") {
        arrRules.push(v => v > 0 || this.$i18n.t("must_be_positive"));
      }
      return arrRules;
    },
    ...mapGetters({
      table_hotel_labels: "tableHotelLabels"
    })
  },
  methods: {
    updateRateForm(value) {
      // console.log("Updating rateForm", value);
      this.rateForm.value = undefined;
      this.rateForm.type_rate_change = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.option-rate {
  cursor: pointer;
  div:hover {
    color: black !important;
  }
  &.selected {
    & div:hover * {
      color: black !important;
    }
    ::v-deep .v-list-item__content {
      color: white !important;
    }
    ::v-deep .v-list-item__subtitle {
      color: white !important;
    }
  }
}
</style>
