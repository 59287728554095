<template>
  <div
    id="rate-min-prices"
    class="d-flex rate-data-row"
    :class="{
      'invisible-channel': isInvisible(channel.id)
    }"
  >
    <div class="item rate-labels semibold">
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class="simple_view competitor"
        @click="changeChannelVisibility(channel)"
      >
        <v-avatar tile class="mt-n1" size="16">
          <v-img :src="channel.logo" />
        </v-avatar>
        {{ channel.name }}
        <v-chip
          class="right"
          v-if="channel.type == 'meta_search'"
          color="grey"
          outlined
          x-small
          >meta</v-chip
        >
      </div>
      <div v-else class="simple_view py-0 pl-2">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              x-small
              depressed
              :color="channel.color"
              class="ma-0 v-list-item__avatar subtitle-2 white--text text-uppercase justify-center"
              >{{ channel.abbreviation }}
            </v-btn>
          </template>
          <span>{{ channel.name }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="(day, index) in days"
          :key="'rate-price-min-' + day + '-channel-' + channel.id"
          class="day"
          :class="getDayClass(day)"
          @mouseover="
            date_hover = day;
            day_hover = index;
          "
          @mouseleave="
            date_hover = '';
            day_hover = '';
          "
        >
          <div
            v-if="mustRender(day)"
            class="semibold"
            :class="'visible-day visible-day-' + index"
          >
            <div class="simple_view" v-if="daily_rates && daily_rates[day]">
              <!-- && hotel_daily_rates[day] -->
              <div class="rate_data_item pointer" @click="openLink(day)">
                <span class="block rate_data_main">
                  <v-tooltip
                    left
                    content-class="a-rate-tooltip"
                    v-if="daily_rates[day][0]"
                    disabled
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <a-kpi
                          class="font-weight-bold"
                          :class="
                            parity_colors[daily_rates[day][0].parity_code] +
                              '--text'
                          "
                          :kpi="'min_price'"
                          :value="daily_rates[day][0].min_price"
                          :increment="true"
                          :vs_value="
                            daily_rates_vs && daily_rates_vs[day]
                              ? daily_rates_vs[day][0].min_price
                              : null
                          "
                          :increment_value="
                            increments && increments[day]
                              ? increments[day][0].min_price
                              : null
                          "
                          :simple="true"
                        >
                          <template v-slot:prepend>
                            <v-tooltip bottom content-class="a-rate-tooltip">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  v-if="
                                    daily_rates[day][0].parity_code ==
                                      'impaired'
                                  "
                                  color="red darken-1"
                                  x-small
                                  style="margin-bottom: 1px;"
                                  >mdi-exclamation-thick</v-icon
                                >
                              </template>
                              <span
                                class="text-center red--text text--darken-1"
                                style="font-size:13px;line-height:11px;"
                                v-html="$t('warning_impaired')"
                              ></span>
                            </v-tooltip>
                          </template>
                        </a-kpi>
                      </div>
                    </template>
                    <span
                      class="text-center"
                      style="font-size:13px;line-height:11px;"
                    >
                      {{ $t("capacity") }}:
                      <br />
                      <v-icon
                        class="ml-n1"
                        v-for="i in daily_rates[day][0].room_type_adults"
                        :key="'adults_' + i + '_' + channel.id + '_' + day"
                        color="primary"
                        small
                        >mdi-account</v-icon
                      >
                    </span>
                  </v-tooltip>
                </span>
                <span class="block rate_data_vs">
                  <v-row class="mx-0">
                    <v-col class="pa-0" cols="4">
                      <!-- Free cancellation -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="daily_rates[day][0].free_cancelation"
                            color="grey lighten-1"
                            small
                            >mdi-alpha-c-circle</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size:13px;line-height:11px;"
                          >{{ $t("free_cancellation") }}<br />(
                          <b>{{ daily_rates[day][0].free_cancelation_days }}</b>
                          {{ $t("daysb") }} )</span
                        >
                      </v-tooltip>
                    </v-col>
                    <v-col class="pa-0" cols="4">
                      <!-- Minimum days -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="daily_rates[day][0].minimum_nights > 1"
                            color="grey lighten-1"
                            small
                            >mdi-numeric-{{
                              daily_rates[day][0].minimum_nights
                            }}-circle</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size:13px;line-height:11px;"
                          >{{ $t("minimum_stay") }}: <br />
                          <b>{{ daily_rates[day][0].minimum_nights }}</b>
                          {{ $t("days") }}</span
                        >
                      </v-tooltip>

                      <!-- Adults -->
                      <!-- <v-icon class="ml-n1" v-for="i in daily_rates[day][0].room_type_adults" :key="'adults_' + i + '_' + channel.id + '_' + day" color="grey lighten-2" small>mdi-account</v-icon>
                      <v-icon class="ml-n1" v-for="i in daily_rates[day][0].room_type_childs" :key="'childs_' + i + '_' + channel.id + '_' + day" color="grey lighten-2" x-small>mdi-account</v-icon> -->
                    </v-col>
                    <v-col class="pa-0" cols="4" v-if="0">
                      <!-- Discount (NO VISIBLE DE MOMENTO v-if="0") -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="grey lighten-1"
                            v-if="daily_rates[day][0].discount > 0"
                            style="font-size:19px;"
                            >mdi-sale</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size:13px;line-height:11px;"
                        >
                          {{ $t("discount") }}:
                          <!-- -{{ $t(daily_rates[day][0].discount) }}% -->
                          14%
                        </span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="pa-0" cols="4">
                      <!-- Price for more -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="red darken-1"
                            v-if="
                              daily_rates[day][0].room_type_adults != filter_pax
                            "
                            style="font-size:19px;"
                            >mdi-account</v-icon
                          >
                        </template>
                        <span
                          class="text-center red--text text--darken-1"
                          style="font-size:13px;line-height:11px;"
                          v-html="
                            $t('warning_price_for', {
                              pax: daily_rates[day][0].room_type_adults
                            })
                          "
                        ></span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
import * as Utils from "@/utils.js";

export default {
  name: "a-parity-min-price",
  components: {
    aKpi
  },
  props: [
    "days",
    "days_per_screen",
    "channel",
    "daily_rates",
    "daily_availability",
    "increments",
    "daily_rates_vs",
    "filters",
    "days_to_render",
    "groupDisplay",
    "hotel_daily_rates",
    "hotel_daily_availability",
    "links"
  ],
  data: vm => ({
    hover: false
  }),
  methods: {
    getDayClass(day) {
      var clas = "";
      if (this.daily_rates[day] && this.daily_rates[day][0]) {
        clas +=
          this.parity_colors[this.daily_rates[day][0].parity_code] + "_bg";
        clas += this.is_weekend(day) ? " weekend" : "";
      }
      return clas;
    },
    changeChannelVisibility(channel) {
      if (this.isInvisible(channel.id)) {
        this.$store.dispatch("removeVisibleChannel", channel.id);
      } else {
        this.$store.dispatch("addVisibleChannel", channel.id);
      }
    },
    showDate(date, channel_id) {
      if (this.groupDisplay) {
        const c = this.getCompetitorById(channel_id);

        this.$store.dispatch("setCurrentHotelById", c.hotel_id);
        this.$router.push({ name: "rate" });
      } else {
        this.show_rate_date_to_compare = true;
        this.$store.dispatch("setComparedCompetitors", [channel_id]);
        this.$store.dispatch("setRateDateToCompare", date);
      }
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    },
    isHover(date) {
      return this.date_hover == date;
    },
    mustRender(date) {
      // Must render if date inside days_per_screen
      return this.days_to_render.indexOf(date) >= 0;
    },
    isInvisible(id) {
      return this.invisible_channels.indexOf(id) >= 0;
    },
    openLink(day) {
      const link = this.links.filter(link => link.date === day);
      if (link.length === 1) {
        window.open(link[0].link, "_blank");
      }
    }
  },
  computed: {
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },

    ...mapState({
      channels: state => state.parity.channels,
      invisible_channels: state => state.parity.invisible_channels,
      parity_colors: state => state.parity_colors,
      filter_pax: state => state.pax
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getCompetitorById: "getCompetitorById"
    })
  }
};
</script>
<style>
.a-rate-tooltip {
  margin-top: 10px;
  background: white;
  z-index: 310 !important;
  color: black !important;
  opacity: 1;
  font-weight: 500;
  border: 2px solid #dedede;
  text-align: center;
}
.a-rate-position.increment {
  background: green;
}
.a-rate-position.decrement {
  background: rgb(155, 9, 9);
}
.a-rate-position {
  position: absolute;
  top: -8px;
  right: -5px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: #999999;
  color: white;
  font-weight: 500;
  font-size: 10px;
  z-index: 320;
  line-height: 25px;
}
.rate_data_main > div {
  position: relative;
}

.pointer {
  cursor: pointer;
}
.invisible-channel {
  opacity: 0.5;
}
</style>
