<template>
  <a-page
    id="hotel-management-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <!-- PAGE HEADER -->
    <!-- EDIT HOTEL MESSAGE -->
    <div
      v-if="show_editing"
      class="d-flex justify-center align-center text-center white--text mb-6"
      style="position:relative"
    >
      <div class="px-10 py-12">
        <span class="text-h5 d-block">{{ $t("modifying_hotel") }}</span>
      </div>
    </div>
    <!-- KPIS -->
    <div
      v-if="!show_editing"
      class="d-flex justify-center align-center text-center white--text mb-6"
      style="position:relative"
    >
      <div class="px-10">
        <span class="text-h1 d-block">{{ hotels_count }}</span>
        <span class="text-h5 d-block">{{ $t("hotels") }}</span>
      </div>
      <div class="px-10">
        <span class="text-h1 d-block">{{ active_hotels_count }}</span>
        <span class="text-h5 d-block">{{ $t("actives") }}</span>
      </div>
    </div>
    <!-- end PAGE HEADER -->

    <!-- LIST -->
    <v-card v-if="!show_editing" class="mb-5">
      <v-app-bar flat color="white">
        <v-toolbar-title class="title font-weight-bold">
          {{ $t("hotels") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          ref="search_hotels"
          block
          rounded
          outlined
          dense
          clearable
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('search_hotel')"
          @click:clear="search = ''"
          hide-details
          style="max-width:300px"
        ></v-text-field>
      </v-app-bar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filtered_hotels"
          :item-class="getDatatableRowClass"
          :no-data-text="$t('no_hotels_found')"
          hide-default-footer
          disable-pagination
          class="elevation-0"
          @click:row="showModifyHotel"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span :class="`ml-${getLevels(item) * 5}`"></span>
            <v-icon
              v-if="parseInt(item.is_group) === 1"
              size="16"
              class="mr-1"
              color="accent"
            >
              mdi-home-city
            </v-icon>
            <v-icon v-else size="16" class="mr-1" color="primary">
              mdi-office-building
            </v-icon>
            <!-- <span class="grey--text caption">
              {{ fathersName(item.father_id) }}
            </span> -->
            {{ item.name }}
          </template>
          <template v-slot:[`item.city`]="{ item }">
            <template v-if="parseInt(item.is_group) === 0">
              {{ item.audit.city }}
            </template>
          </template>
          <template v-slot:[`item.pms`]="{ item }">
            <template v-if="parseInt(item.is_group) === 0">
              {{ item.audit.pms }}
            </template>
          </template>
          <template v-slot:[`item.channel`]="{ item }">
            <template v-if="parseInt(item.is_group) === 0">
              {{ item.audit.channel_manager }}
            </template>
          </template>
          <template v-slot:[`item.rate`]="{ item }">
            <template v-if="parseInt(item.is_group) === 0">
              <v-icon v-if="item.audit.rate" color="green">
                mdi-check-circle-outline
              </v-icon>
              <v-icon v-else color="red">
                mdi-close-circle-outline
              </v-icon>
            </template>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <template v-if="parseInt(item.active)">
              <v-chip small color="secondary">{{ $t("active") }}</v-chip>
            </template>
            <template v-else>
              <v-chip small>{{ $t("unactive") }}</v-chip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click.stop="deactivateHotel(item.id)"
              v-if="
                parseInt(item.active) &&
                  parseInt(item.id) !== parseInt(current_hotel.id)
              "
              icon
              color="secondary"
              ><v-icon small>mdi-eye-off-outline</v-icon></v-btn
            >
            <v-btn
              @click.stop="activateHotel(item.id)"
              v-if="
                !parseInt(item.active) &&
                  parseInt(item.id) !== parseInt(current_hotel.id)
              "
              icon
              color="secondary"
              ><v-icon small>mdi-eye-outline</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- EDIT HOTEL FORM -->
    <v-card v-if="show_editing" class="mb-5">
      <v-app-bar flat color="white">
        <v-toolbar-title class="title font-weight-light">
          <v-btn icon fab @click="hideModifyHotel" class="mb-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>{{ $t("you_are_editing") }}</span>
          <span class="font-weight-bold ml-2">{{ item_to_edit.name }}</span>
          <span v-if="parseInt(item_to_edit.is_group) === 1" class="ml-2">
            {{ "(" + $t("group") + ")" }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="secondary"
          :disabled="!valid_edit"
          @click="setHotel"
          :loading="saving"
          >{{ $t("save") }}</v-btn
        >
      </v-app-bar>
      <v-card-text class="pt-0 pl-16">
        <v-form v-model="valid_edit">
          <v-row>
            <v-col cols="12" md="12">
              <!-- <v-card-title class="pl-0">{{ $t("general") }}</v-card-title> -->
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="item_to_edit.name"
                    maxlength="50"
                    counter
                    :rules="name_rules"
                    :label="$t('name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card class="mt-4">
                <v-tabs v-model="tab_config" rounded>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        item_to_edit.permissions.rms_hotelsdot
                    "
                  >
                    {{ $t("audit") }}
                  </v-tab>
                  <v-tab
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        (item_to_edit.permissions.rms ||
                          item_to_edit.permissions.rms_hotelsdot ||
                          item_to_edit.permissions.rms_axel ||
                          item_to_edit.permissions.rms_clicktotravel)
                    "
                  >
                    {{ $t("configuration") }}
                  </v-tab>
                  <v-tab
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        item_to_edit.permissions.rate
                    "
                  >
                    {{ $t("compset") }}
                  </v-tab>
                  <v-tab>
                    {{ $t("visibilities") }}
                  </v-tab>
                  <v-tab
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        item_to_edit.permissions.rms_hotelsdot &&
                        Object.keys(item_to_edit.audit.data_validation).length >
                          0
                    "
                  >
                    {{ $t("data_validation") }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab_config" class="px-4">
                  <v-tab-item
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        item_to_edit.permissions.rms_hotelsdot
                    "
                  >
                    <a-hotel-audit :item="item_to_edit"></a-hotel-audit>
                  </v-tab-item>
                  <v-tab-item
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        (item_to_edit.permissions.rms ||
                          item_to_edit.permissions.rms_hotelsdot ||
                          item_to_edit.permissions.rms_axel ||
                          item_to_edit.permissions.rms_clicktotravel)
                    "
                  >
                    <a-hotel-configuration
                      :item="item_to_edit"
                    ></a-hotel-configuration>
                  </v-tab-item>
                  <v-tab-item
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        item_to_edit.permissions.rate
                    "
                  >
                    <a-hotel-compset :item="item_to_edit"></a-hotel-compset>
                  </v-tab-item>
                  <v-tab-item>
                    <permissions-editor
                      :item="item_to_edit"
                    ></permissions-editor>
                  </v-tab-item>
                  <v-tab-item
                    v-if="
                      parseInt(item_to_edit.is_group) === 0 &&
                        item_to_edit.permissions.rms_hotelsdot &&
                        Object.keys(item_to_edit.audit.data_validation).length >
                          0
                    "
                  >
                    <a-hotel-data-validation
                      :item="item_to_edit"
                    ></a-hotel-data-validation>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </a-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import PermissionsEditor from "@/components/permissions/PermissionsEditor";
import aHotelCompset from "@/components/settings/aHotelCompset";
import aHotelAudit from "@/components/settings/aHotelAudit";
import aHotelDataValidation from "@/components/settings/aHotelDataValidation";
import aHotelConfiguration from "@/components/settings/aHotelConfiguration";
import * as Utils from "@/utils.js";
import { search } from "core-js/fn/symbol";

export default {
  name: "settings-hotel",
  components: {
    aPage,
    PermissionsEditor,
    aHotelCompset,
    aHotelAudit,
    aHotelDataValidation,
    aHotelConfiguration
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 280, //Mandatory number
    item_to_edit: {},
    show_editing: false,
    saving: false,
    valid_edit: true,
    tab_config: 0,
    search: ""
  }),
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    showModifyHotel(hotel) {
      this.show_editing = true;
      this.item_to_edit = JSON.parse(JSON.stringify(hotel));
    },
    hideModifyHotel() {
      this.show_editing = false;
      this.item_to_edit = {};
    },
    deactivateHotel: function(hotel_id) {
      const vm = this;
      if (this.current_hotel.id === parseInt(hotel_id)) {
        this.$store.dispatch("setSnackbar", {
          title: vm.$t("cannot_deactivate_hotel"),
          color: "error"
        });
        return;
      }

      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("deactivating_hotel"),
        color: "secondary"
      });

      this.$store
        .dispatch("deactivateHotel", hotel_id)
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("deactivated_hotel"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    activateHotel: function(hotel_id) {
      const vm = this;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("activating_hotel"),
        color: "secondary"
      });

      this.$store
        .dispatch("activateHotel", hotel_id)
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("activated_hotel"),
            // text: this.$t("modified_hotel_ok", { name : this.item_to_edit.name }),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    setHotel: function() {
      const vm = this;
      this.saving = true;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("modifying_hotel"),
        color: "secondary"
      });
      this.$store
        .dispatch("updateHotel", this.item_to_edit)
        .then(() => {
          //Save modifications
          this.show_editing = false;
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("modified_hotel"),
            text: this.$t("modified_hotel_ok", {
              name: this.item_to_edit.name
            }),
            color: "accent"
          });

          // We update current_hotel if needed
          if (this.current_hotel.id === parseInt(this.item_to_edit.id)) {
            this.$store.dispatch("fetchHotels");
          }

          // Go to list
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_editing = false;
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
          this.item_to_edit = {};
        });
    },
    fathersName(id) {
      if (id === null) {
        return "";
      }
      const father = this.hotels.find(hotel => hotel.id === id);
      if (father) {
        return this.fathersName(father.father_id) + father.name + " > ";
      }
      return "";
    },
    getLevels(hotel) {
      let levels = [];
      let father = this.hotels.find(h => h.id === hotel.father_id);
      while (father) {
        levels.push(father);
        father = this.hotels.find(h => h.id === father.father_id);
      }
      return levels.length;
    },
    handleKeydown(event) {
      const search = this.$refs.search_hotels;
      const validKey = /^[a-zA-Z0-9]$/;
      if (
        document.activeElement !== search.$el.querySelector("input") &&
        validKey.test(event.key) &&
        !event.metaKey
      ) {
        search.focus();
        this.search += event.key;
        event.preventDefault();
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("city"),
          align: "center",
          sortable: true,
          value: "city"
        },
        {
          text: "PMS",
          align: "center",
          sortable: true,
          value: "pms"
        },
        {
          text: this.$t("channel"),
          align: "center",
          sortable: true,
          value: "channel"
        },
        {
          text: this.$t("rate"),
          align: "center",
          sortable: true,
          value: "rate"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions"
        }
      ];
    },
    filtered_hotels() {
      let hotels = [];
      let fathers = this.hotels.filter(h => parseInt(h.is_group) === 1);
      fathers = fathers.sort(
        (a, b) => parseInt(a.father_id) - parseInt(b.father_id)
      );
      fathers.forEach(father => {
        hotels.push(father);
        hotels = hotels.concat(
          this.hotels.filter(h => {
            return (
              parseInt(h.father_id) === parseInt(father.id) &&
              parseInt(h.is_group) === 0
            );
          })
        );
      });

      let hotels_visible = [];
      let filtered_hotels = hotels;

      if (this.search !== "" && this.search !== null) {
        filtered_hotels = [];
        const search = this.search.toLowerCase();

        hotels.forEach(hotel => {
          const match =
            hotel.name.toLowerCase().includes(search) ||
            hotel.audit.city.toLowerCase().includes(search) ||
            hotel.audit.pms.toLowerCase().includes(search) ||
            hotel.audit.channel_manager.toLowerCase().includes(search);
          hotels_visible[hotel.id] = match;
        });

        hotels.forEach(hotel => {
          if (hotels_visible[hotel.id]) {
            // Mirar si es grupo entonces hacer visibles todos los hijos
            if (parseInt(hotel.is_group) === 1) {
              hotels.forEach(h => {
                if (parseInt(h.father_id) === parseInt(hotel.id)) {
                  hotels_visible[h.id] = true;
                }
              });
            } else {
              // Si no es grupo, hacer visible el padre
              hotels_visible[hotel.father_id] = true;
            }
          }
        });

        filtered_hotels = hotels.filter(hotel => hotels_visible[hotel.id]);
      }

      return filtered_hotels;
    },
    active_hotels() {
      return this.hotels.filter(hotel => parseInt(hotel.active));
    },
    hotels_count() {
      return this.hotels.length;
    },
    active_hotels_count() {
      return this.active_hotels.length;
    },
    can_submit() {
      return true;
    },
    ...mapState({
      hotels: state => state.hotel.all_hotels,
      hotel: state => state.hotel.hotel,
      new_hotel_model: state => state.hotel.new_hotel_model,
      available_permissions: state => state.hotel.available_permissions,
      snackbar: state => state.snackbar,
      show_hotel_list: state => state.hotel.show_hotel_list
    }),
    ...mapGetters({
      name_rules: "name_rules",
      current_hotel: "current_hotel"
    })
  },
  watch: {
    show_editing: {
      handler: function(val) {
        if (!val) {
          window.addEventListener("keydown", this.handleKeydown);
        } else {
          window.removeEventListener("keydown", this.handleKeydown);
        }
      },
      immediate: true
    },
    show_hotel_list: {
      handler: function(val) {
        if (!val) {
          window.addEventListener("keydown", this.handleKeydown);
        } else {
          window.removeEventListener("keydown", this.handleKeydown);
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$store.dispatch("fetchAllHotels").catch(() => {
      Utils.showError(this, "something_went_wrong");
    });
  }
};
</script>
<style></style>
