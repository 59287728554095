<template>
  <div class="a-rms-day-column-labels">
    <div class="mb-1"></div>
    <div class="px-3">{{ $t("occupancy") }}</div>
    <div class="px-3">{{ $t("rn") }}</div>
    <div class="px-3">{{ $t("adr") }}</div>
    <div class="px-3">{{ $t("revenue") }}</div>
  </div>
</template>

<script>
export default {
  name: "a-rms-strategy-column-labels",
  components: {},
  props: {
    labels: {
      type: Array,
      mandatory: true,
      default: () => []
    }
  },
  data: vm => ({}),
  watch: {},
  methods: {},
  computed: {}
};
</script>
<style lang="scss">
.a-rms-day-column-labels {
  flex-grow: 0;

  div {
    display: flex;
    color: #666;
    text-align: left;
    align-items: center;
    height: 20px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>
