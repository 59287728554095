<template>
  <a-card title="reviews">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-sheet
          color="grey lighten-4"
          tile
          class="rounded"
          v-if="bi_report.report[`${ota}_new_reviews`]"
        >
          <v-card-subtitle class="text-center grey--text text-uppercase pb-0">
            <v-avatar color="secondary" size="23" class="white--text">{{
              ota_reviews.length
            }}</v-avatar>
            {{ $t("reviews  ") }}
            <v-btn
              class="mt-n1 ml-2"
              icon
              outlined
              @click="cycle_reviews = !cycle_reviews"
              color="secondary"
              x-small
            >
              <v-icon x-small v-if="cycle_reviews">
                mdi-pause
              </v-icon>
              <v-icon x-small v-else>
                mdi-play
              </v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-carousel
            light
            :cycle="cycle_reviews"
            hide-delimiter-background
            height="auto"
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(page, index) in ota_reviews_pages"
              :key="index"
            >
              <v-sheet
                color="grey lighten-4"
                min-height="300"
                tile
                class="d-flex flex-wrap justify-space-around align-center px-12 pb-10 pt-3 rounded"
              >
                <v-row>
                  <v-col cols="12" lg="6">
                    <a-reputation-review
                      v-if="page[0]"
                      :review="page[0]"
                      class="mx-10 mt-2 mb-6"
                    ></a-reputation-review>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <a-reputation-review
                      v-if="page[1]"
                      :review="page[1]"
                      class="mx-10 mt-2 mb-6"
                    ></a-reputation-review>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <a-reputation-review
                      v-if="page[2]"
                      :review="page[2]"
                      class="mx-10 mt-2 mb-6"
                    ></a-reputation-review>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <a-reputation-review
                      v-if="page[3]"
                      :review="page[3]"
                      class="mx-10 mt-2 mb-6"
                    ></a-reputation-review>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-sheet>
        <a-reputation-no-reviews v-else></a-reputation-no-reviews>
      </template>
      <template v-slot:loading>
        <v-row>
          <v-col cols="5" class="py-0">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-skeleton-loader
                  class="mx-auto"
                  height="170"
                  max-width="200"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="mx-auto d-flex justify-center mt-2"
                  max-width="200"
                  type="heading"
                ></v-skeleton-loader>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-skeleton-loader
                  class="mt-4"
                  type="list-item-avatar"
                ></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" class="py-0"></v-col>
          <v-col cols="6" class="py-0">
            <v-skeleton-loader
              class="mt-10"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </a-base-component>
  </a-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aCard from "@/components/aCard";
import aIncrement from "@/components/aIncrement";
import aReputationReview from "@/components/reputation/aReputationReview";
import aReputationAnswerTimeGraph from "@/components/reputation/aReputationAnswerTimeGraph";
import aReputationNoReviews from "@/components/reputation/aReputationNoReviews.vue";
import aBaseComponent from "@/components/aBaseComponent";
import aLabel from "@/components/aLabel";

export default {
  name: "a-reputation-answer",
  components: {
    aCard,
    aBaseComponent,
    aReputationNoReviews,
    aReputationReview
  },
  props: {
    ota: {
      type: String,
      default: null
    }
  },
  data: () => ({
    subscribed_modules: [
      "bi_report",
      "bi_reputation_answered_by_time",
      "bi_reputation_reviews"
    ],
    cycle_reviews: true
  }),
  methods: {},
  mounted() {},
  computed: {
    ota_reviews_pages() {
      var pages = [];
      var i,
        j,
        temparray,
        chunk = 4;
      for (i = 0, j = this.ota_reviews.length; i < j; i += chunk) {
        pages.push(this.ota_reviews.slice(i, i + chunk));
      }
      return pages;
    },
    ota_reviews() {
      return this.bi_reputation_reviews.filter(r => r.ota == this.ota);
    },
    getQuickAnswerColor() {
      var value = this.bi_reputation_answered_by_time.data.quick_answers_avg;
      var color = "green";
      if (value <= 50) {
        color = "red";
      } else if (value <= 80) {
        color = "orange";
      }
      return color;
    },
    ...mapState({
      bi_report: state => state.bi.report,
      bi_reputation_answered_by_time: state =>
        state.bi.reputation_answered_by_time,
      bi_reputation_reviews: state => state.bi.reputation_reviews,
      emotions: state => state.emotions,
      times_to_answer: state => state.times_to_answer
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>

<style scoped>
.v-list-item:hover:not(.no-click) {
  background: #e4e8f5 !important;
}
</style>
