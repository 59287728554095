<template>
  <div id="year-sales-app">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <a-card class="mt-5 mb-2" no_title>
          <template v-if="kpi_graph == 'room_revenue'">
            <v-row>
              <v-col cols="6">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("room_revenue") }}
                </v-card-title>
                <a-hotelsdot-year-sales-roomrevenue-monthly-chart
                  :series="year_sales_monthly_graph.kpis.room_revenue"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-roomrevenue-monthly-chart>
              </v-col>
              <v-col cols="2">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("accumulated") }}
                </v-card-title>
                <a-hotelsdot-year-sales-roomrevenue-period-chart
                  :series="year_sales_period_graph.kpis.room_revenue"
                  :labels="year_sales_period_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-roomrevenue-period-chart>
              </v-col>
              <v-col cols="4">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("growth") }}
                </v-card-title>
                <a-hotelsdot-year-sales-roomrevenue-grown-chart
                  :series="year_sales_monthly_grown_graph.kpis.room_revenue"
                  :labels="year_sales_monthly_grown_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-roomrevenue-grown-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph == 'adr'">
            <v-row>
              <v-col cols="6">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("adr") }}
                </v-card-title>
                <a-hotelsdot-year-sales-adr-monthly-chart
                  :series="year_sales_monthly_graph.kpis.adr"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-adr-monthly-chart>
              </v-col>
              <v-col cols="2">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("accumulated") }}
                </v-card-title>
                <a-hotelsdot-year-sales-adr-period-chart
                  :series="year_sales_period_graph.kpis.adr"
                  :labels="year_sales_period_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-adr-period-chart>
              </v-col>
              <v-col cols="4">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("growth") }}
                </v-card-title>
                <a-hotelsdot-year-sales-adr-grown-chart
                  :series="year_sales_monthly_grown_graph.kpis.adr"
                  :labels="year_sales_monthly_grown_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-adr-grown-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph == 'rn'">
            <v-row>
              <v-col cols="6">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("rn") }}
                </v-card-title>
                <a-hotelsdot-year-sales-rn-monthly-chart
                  :series="year_sales_monthly_graph.kpis.rn"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-rn-monthly-chart>
              </v-col>
              <v-col cols="2">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("accumulated") }}
                </v-card-title>
                <a-hotelsdot-year-sales-rn-period-chart
                  :series="year_sales_period_graph.kpis.rn"
                  :labels="year_sales_period_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-rn-period-chart>
              </v-col>
              <v-col cols="4">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("growth") }}
                </v-card-title>
                <a-hotelsdot-year-sales-rn-grown-chart
                  :series="year_sales_monthly_grown_graph.kpis.rn"
                  :labels="year_sales_monthly_grown_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-rn-grown-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph == 'occupancy'">
            <v-row>
              <v-col cols="6">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("occupancy") }}
                </v-card-title>
                <a-hotelsdot-year-sales-occupancy-monthly-chart
                  :series="year_sales_monthly_graph.kpis.occupancy"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-occupancy-monthly-chart>
              </v-col>
              <v-col cols="2">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("accumulated") }}
                </v-card-title>
                <a-hotelsdot-year-sales-occupancy-period-chart
                  :series="year_sales_period_graph.kpis.occupancy"
                  :labels="year_sales_period_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-occupancy-period-chart>
              </v-col>
              <v-col cols="4">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("growth") }}
                </v-card-title>
                <a-hotelsdot-year-sales-occupancy-grown-chart
                  :series="year_sales_monthly_grown_graph.kpis.occupancy"
                  :labels="year_sales_monthly_grown_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-occupancy-grown-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph == 'revpar'">
            <v-row>
              <v-col cols="6">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("revpar") }}
                </v-card-title>
                <a-hotelsdot-year-sales-revpar-monthly-chart
                  :series="year_sales_monthly_graph.kpis.revpar"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-revpar-monthly-chart>
              </v-col>
              <v-col cols="2">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("accumulated") }}
                </v-card-title>
                <a-hotelsdot-year-sales-revpar-period-chart
                  :series="year_sales_period_graph.kpis.revpar"
                  :labels="year_sales_period_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-revpar-period-chart>
              </v-col>
              <v-col cols="4">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("growth") }}
                </v-card-title>
                <a-hotelsdot-year-sales-revpar-grown-chart
                  :series="year_sales_monthly_grown_graph.kpis.revpar"
                  :labels="year_sales_monthly_grown_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-revpar-grown-chart>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="kpi_graph == 'intermediation'">
            <v-row>
              <v-col cols="6">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("intermediation") }}
                </v-card-title>
                <a-hotelsdot-year-sales-intermediation-monthly-chart
                  :series="year_sales_monthly_graph.kpis.intermediation"
                  :labels="year_sales_monthly_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-intermediation-monthly-chart>
              </v-col>
              <v-col cols="2">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("accumulated") }}
                </v-card-title>
                <a-hotelsdot-year-sales-intermediation-period-chart
                  :series="year_sales_period_graph.kpis.intermediation"
                  :labels="year_sales_period_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-intermediation-period-chart>
              </v-col>
              <v-col cols="4">
                <v-card-title class="justify-center text-capitalize">
                  {{ $t("growth") }}
                </v-card-title>
                <a-hotelsdot-year-sales-intermediation-grown-chart
                  :series="year_sales_monthly_grown_graph.kpis.intermediation"
                  :labels="year_sales_monthly_grown_graph.labels"
                  height="450"
                ></a-hotelsdot-year-sales-intermediation-grown-chart>
              </v-col>
            </v-row>
          </template>
        </a-card>
        <a-card :title="$t('full_year')" class="mb-2">
          <v-row>
            <v-col cols="12">
              <a-rms-year-sales-table
                :rows="year_sales_table_filtered"
              ></a-rms-year-sales-table>
            </v-col>
          </v-row>
        </a-card>
        <a-card :title="$t('Accumulative')" class="mb-5">
          <v-row>
            <v-col cols="12">
              <a-rms-year-sales-table
                :rows="year_sales_period_table_filtered"
              ></a-rms-year-sales-table>
            </v-col>
          </v-row>
        </a-card>
      </template>
      <template v-slot:loading>
        <a-card>
          <v-row class="pt-5">
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
          </v-row>
        </a-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aCard from "@/components/aCard.vue";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aRmsYearSalesTable from "@/components/rms/hotelsdot/aRmsYearSalesTable.vue";
import aHotelsdotYearSalesRoomrevenueMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRoomrevenueMonthlyChart.vue";
import aHotelsdotYearSalesRoomrevenuePeriodChart from "@/components/chart/hotelsdot/aYearSalesRoomrevenuePeriodChart.vue";
import aHotelsdotYearSalesRoomrevenueGrownChart from "@/components/chart/hotelsdot/aYearSalesRoomrevenueGrownChart.vue";
import aHotelsdotYearSalesAdrMonthlyChart from "@/components/chart/hotelsdot/aYearSalesAdrMonthlyChart.vue";
import aHotelsdotYearSalesAdrPeriodChart from "@/components/chart/hotelsdot/aYearSalesAdrPeriodChart.vue";
import aHotelsdotYearSalesAdrGrownChart from "@/components/chart/hotelsdot/aYearSalesAdrGrownChart.vue";
import aHotelsdotYearSalesRnMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRnMonthlyChart.vue";
import aHotelsdotYearSalesRnPeriodChart from "@/components/chart/hotelsdot/aYearSalesRnPeriodChart.vue";
import aHotelsdotYearSalesRnGrownChart from "@/components/chart/hotelsdot/aYearSalesRnGrownChart.vue";
import aHotelsdotYearSalesOccupancyMonthlyChart from "@/components/chart/hotelsdot/aYearSalesOccupancyMonthlyChart.vue";
import aHotelsdotYearSalesOccupancyPeriodChart from "@/components/chart/hotelsdot/aYearSalesOccupancyPeriodChart.vue";
import aHotelsdotYearSalesOccupancyGrownChart from "@/components/chart/hotelsdot/aYearSalesOccupancyGrownChart.vue";
import aHotelsdotYearSalesRevparMonthlyChart from "@/components/chart/hotelsdot/aYearSalesRevparMonthlyChart.vue";
import aHotelsdotYearSalesRevparPeriodChart from "@/components/chart/hotelsdot/aYearSalesRevparPeriodChart.vue";
import aHotelsdotYearSalesRevparGrownChart from "@/components/chart/hotelsdot/aYearSalesRevparGrownChart.vue";
import aHotelsdotYearSalesIntermediationMonthlyChart from "@/components/chart/hotelsdot/aYearSalesIntermediationMonthlyChart.vue";
import aHotelsdotYearSalesIntermediationPeriodChart from "@/components/chart/hotelsdot/aYearSalesIntermediationPeriodChart.vue";
import aHotelsdotYearSalesIntermediationGrownChart from "@/components/chart/hotelsdot/aYearSalesIntermediationGrownChart.vue";

export default {
  name: "rms-year-sales-hotelsdot-app",
  components: {
    aCard,
    aBaseComponent,
    aRmsYearSalesTable,
    aHotelsdotYearSalesRoomrevenueGrownChart,
    aHotelsdotYearSalesRoomrevenueMonthlyChart,
    aHotelsdotYearSalesRoomrevenuePeriodChart,
    aHotelsdotYearSalesAdrMonthlyChart,
    aHotelsdotYearSalesAdrPeriodChart,
    aHotelsdotYearSalesAdrGrownChart,
    aHotelsdotYearSalesRnMonthlyChart,
    aHotelsdotYearSalesRnPeriodChart,
    aHotelsdotYearSalesRnGrownChart,
    aHotelsdotYearSalesOccupancyMonthlyChart,
    aHotelsdotYearSalesOccupancyPeriodChart,
    aHotelsdotYearSalesOccupancyGrownChart,
    aHotelsdotYearSalesRevparMonthlyChart,
    aHotelsdotYearSalesRevparPeriodChart,
    aHotelsdotYearSalesRevparGrownChart,
    aHotelsdotYearSalesIntermediationMonthlyChart,
    aHotelsdotYearSalesIntermediationPeriodChart,
    aHotelsdotYearSalesIntermediationGrownChart
  },
  data: () => ({
    subscribed_modules: [
      "rms_year_sales_monthly_graph",
      "rms_year_sales_period_graph",
      "rms_year_sales_monthly_grown_graph",
      "rms_year_sales_table",
      "rms_year_sales_period_table"
    ]
  }),
  computed: {
    kpi_graph: {
      get() {
        return this.$store.state.rms.year_sales_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiGraph", value);
      }
    },
    year_sales_table_filtered() {
      return this.year_sales_table.filter(row => row.spot !== "bud_ly");
    },
    year_sales_period_table_filtered() {
      return this.year_sales_period_table.filter(row => row.spot !== "bud_ly");
    },
    ...mapState({
      year_sales_monthly_graph: state => state.rms.year_sales_monthly_graph,
      year_sales_period_graph: state => state.rms.year_sales_period_graph,
      year_sales_monthly_grown_graph: state =>
        state.rms.year_sales_monthly_grown_graph,
      year_sales_table: state => state.rms.year_sales_table,
      year_sales_period_table: state => state.rms.year_sales_period_table
    })
  },
  created() {
    this.kpi_graph = "room_revenue";
  }
};
</script>

<style lang="scss" scoped>
.title-table {
  margin-left: 100px;
}
.title-graph {
  margin-left: 100px;
}
</style>
