var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('a-card-kpi-icon',{attrs:{"value":_vm.rms.strategy_range_kpis.otb.room_revenue,"vs_values":[
        _vm.rms.strategy_range_kpis.stly.room_revenue,
        _vm.rms.strategy_range_kpis.ly.room_revenue
      ],"periods":['otb', 'stly', 'ly'],"kpi":"room_revenue","label":"revenue","icon":"mdi-cash-multiple","icon_circle":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('a-card-kpi-icon',{attrs:{"value":_vm.rms.strategy_range_kpis.otb.occupancy,"vs_values":[
        _vm.rms.strategy_range_kpis.stly.occupancy,
        _vm.rms.strategy_range_kpis.bud.occupancy
      ],"periods":['otb', 'stly', 'bud'],"kpi":"occupancy","label":"occupancy","icon":"mdi-home-percent-outline","icon_circle":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('a-card-kpi-icon',{attrs:{"value":_vm.rms.strategy_range_kpis.otb.adr,"vs_values":[
        _vm.rms.strategy_range_kpis.stly.adr,
        _vm.rms.strategy_range_kpis.bud.adr
      ],"periods":['otb', 'stly', 'bud'],"kpi":"adr","label":"adr","icon":"mdi-tag-outline","icon_circle":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('a-card-kpi-icon',{attrs:{"value":_vm.rms.strategy_range_kpis.otb.revpar,"vs_values":[
        _vm.rms.strategy_range_kpis.stly.revpar,
        _vm.rms.strategy_range_kpis.bud.revpar
      ],"periods":['otb', 'stly', 'bud'],"kpi":"revpar","label":"revpar","icon":"mdi-hand-coin-outline","icon_circle":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }