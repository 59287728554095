<template>
  <a-card no_title class="a-card-kpi">
    <v-card-text>
      <a-label class="mb-0">
        {{ $t(label) }}
      </a-label>
      <slot name="periods">
        <a-period-compare
          v-if="!hide_periods"
          :periods="periods"
          dense
        ></a-period-compare>
      </slot>
      <slot name="kpi">
        <a-kpi
          :kpi="kpi"
          :value="value"
          :vs_values="vs_values"
          :vs_periods="periods.slice(1)"
          large
        ></a-kpi>
      </slot>
    </v-card-text>
  </a-card>
</template>

<script>
import aKpi from "@/components/rms_ui/aKpi";
import aCard from "@/components/aCard";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare";
import aLabel from "@/components/rms_ui/aLabel";

export default {
  name: "a-card-kpi",
  components: {
    aLabel,
    aPeriodCompare,
    aKpi,
    aCard
  },
  props: {
    kpi: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false
    },
    vs_values: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    periods: {
      type: Array,
      required: false
    },
    hide_periods: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss">
.a-card-kpi {
  .v-card__text.pt-1 {
    padding: 5px !important;
    height: 100%;
    .v-card__text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
