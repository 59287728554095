<template>
  <div
    class="cell-padding"
    :class="{
      ...bgColor,
      'info--text': rate.edited
    }"
  >
    <v-row class="h-half pa-0 ma-0">
      <v-col class="pa-0">
        <a-kpi
          :kpi="kpiType"
          :value="rate[objKey]"
          :increment="true"
          :vs_value="initialValue"
          :increment_value="initialValue && rate[objKey] - initialValue"
          :simple="true"
        >
        </a-kpi>
      </v-col>
    </v-row>
    <v-row class="h-half text-center pa-0 ma-0">
      <v-col class="pa-0">
        <v-tooltip v-if="rate.stop_sell" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="12"
              v-bind="attrs"
              v-on="on"
              class="text--lighten-1"
              :class="{
                'grey--text': !rate.edited,
                'info--text': rate.edited
              }"
            >
              mdi-hand-back-right-off
            </v-icon>
          </template>
          <span class="px-2">{{ $t("stop_sell") }}</span>
        </v-tooltip>
        <span v-else> {{ " " }} </span>
      </v-col>
      <v-col class="pa-0">
        <v-tooltip v-if="rate.release" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="16"
              v-bind="attrs"
              v-on="on"
              class="text--lighten-1"
              :class="{
                'grey--text':
                  !rate.edited && rate.release === rate.original_release,
                'info--text':
                  rate.edited && rate.release === rate.original_release,
                'green--text': rate.release !== rate.original_release
              }"
            >
              mdi-numeric-{{ rate.release }}-circle-outline
            </v-icon>
          </template>
          <span class="px-2">{{ $t("release_days") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import aKpi from "@/components/aKpi.vue";

export default {
  name: "a-offers-price-cell",
  components: {
    aKpi
  },
  props: {
    rate: {
      type: Object,
      default: () => ({})
    },
    objKey: {
      type: String,
      default: "offer"
    },
    row_key: {
      type: Number,
      default: null
    }
  },
  computed: {
    originalObjKey() {
      return `original_${this.objKey}`;
    },
    initialValue() {
      return this.rate && this.rate[this.originalObjKey]
        ? this.rate[this.originalObjKey]
        : 0;
    },
    bgColor() {
      const classObj = {};
      if (this.rate) {
        classObj["modified-cell"] =
          this.rate[this.objKey] !== this.rate[this.originalObjKey];
      }
      return classObj;
    },
    kpiType() {
      return this.rate.offer_mode === "absolute" ? "price" : "avg_charge";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/main.sass";

div.grey--text {
  &::v-deep .v-icon {
    color: rgba(0, 0, 0, 0.24);
  }
}
* {
  line-height: 1.215rem;
  .h-half {
    height: $minHeightColRow / 2 - 2px;
  }
}
.modified-cell {
  background-color: map-get($green, "lighten-5");
}
.cell-padding {
  padding-top: 2px;
  padding-bottom: 2px;
}
.v-application .grey--text.text--lighten-1 {
  color: #999 !important;
  caret-color: #999 !important;
}
.v-application .grey--text.text--lighten-2 {
  color: #aaa !important;
  caret-color: #aaa !important;
}
</style>
