<template>
  <div>
    <div class="table-header d-flex">
      <v-text-field
        class=""
        block
        v-model="search"
        rounded
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search_channel')"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-tooltip bottom dark color="#333333" v-if="is_manual">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-1"
            @click="showAddChannel"
            rounded
            color="secondary"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            {{ $t("add_channel") }}
          </v-btn>
        </template>
        <span>
          {{ $t("create_channel") }}
        </span>
      </v-tooltip>
      <div v-else>
        <v-icon>mdi-information-outline</v-icon>
        {{ $t("pms_generated_channels") }}
      </div>
    </div>
    <v-data-table
      v-if="filtered_channels && filtered_channels.length > 0"
      :headers="channels_headers"
      :items="filtered_channels"
      hide-default-footer
      disable-pagination
      @click:row="showEditChannel"
      :item-class="getDatatableRowClass"
      class="channels-table"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-avatar size="10" class="mr-2" :color="item.color"></v-avatar>
        <b class="mr-1">{{ item.name }}</b>
      </template>
      <template v-slot:[`item.commission`]="{ item }">
        <v-tooltip bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-icon small color="secondary" class="mr-1" v-on="on">
              <template v-if="!item.commission_included">
                mdi-transition-masked
              </template>
              <template v-else>
                mdi-radiobox-marked
              </template>
            </v-icon>
            <span v-on="on">{{ formatCommission(item.commission) }}</span>
          </template>
          <template v-if="item.commission_included">
            <span>
              {{ $t("commission_included_from") }}
              {{ formatCommission(item.commission) }}
            </span>
          </template>
          <template v-else>
            <span>
              {{ $t("commission_not_included_from") }}
              {{ formatCommission(item.commission) }}
            </span>
          </template>
        </v-tooltip>
      </template>
      <template v-slot:[`item.observations`]="{ item }">
        <template
          v-if="item.observations !== null && item.observations.length <= 40"
        >
          {{ item.observations }}
        </template>
        <template v-else>
          <v-tooltip bottom dark color="#333333" max-width="200">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ getObservationsMini(item.observations) }}
              </span>
            </template>
            <span>
              {{ item.observations }}
            </span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="item.can_delete && is_manual"
          bottom
          dark
          color="#333333"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="showDeleteChannel(item)"
              icon
              color="secondary"
              v-on="on"
              ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
            >
          </template>
          <span>
            {{ $t("delete") }}
          </span>
        </v-tooltip>
        <v-tooltip v-else-if="!item.can_delete" bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">mdi-lock-outline</v-icon>
          </template>
          <span>
            {{ $t("channel_locked") }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- DELETE CHANNEL -->
    <v-dialog
      v-model="show_delete_channel"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_channel', { channel: item_to_edit.name })"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteChannel()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteChannel"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE COMMISSION PERIOD -->
    <v-dialog
      v-model="show_delete_commission_period"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">
          {{ $t("are_you_sure") }}
        </v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="period_to_edit"
          v-html="
            $t('sure_delete_commission_period', {
              period: period_to_delete_dates
            })
          "
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteCommissionPeriod()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteCommissionPeriod"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CREATE/EDIT CHANNEL -->
    <v-dialog
      v-model="show_edit_channel"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
    >
      <v-card :loading="loading_periods">
        <v-card-title
          center
          class="headline justify-center py-6"
          v-if="item_to_edit.id"
        >
          {{ $t("edit_channel") }}
        </v-card-title>
        <v-card-title center class="headline justify-center py-6" v-else>
          {{ $t("new_channel") }}
        </v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-tabs
            class="mb-4"
            background-color="transparent"
            slider-size="2"
            v-model="tab"
            light
            grow
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              {{ $t("general") }}
            </v-tab>
            <v-tab>
              {{ $t("periods_commission") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-form ref="edit_channel_form" v-model="can_set_channel">
                <v-row>
                  <v-col cols="12" sm="12" class="py-0">
                    <v-combobox
                      v-model="autocomplete_iatas"
                      :items="channel_iatas"
                      :filter="filterCodeChannel"
                      :return-object="true"
                      :rules="[v => !!v || $t('required')]"
                      :label="$t('name')"
                      item-text="name"
                      maxlength="50"
                      mandatory
                      rounded
                      outlined
                      dense
                      counter
                    >
                      <template v-slot:[`item`]="{ item }">
                        {{ item.name }} ({{ item.code }})
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col :cols="isAdd ? 12 : 8" class="py-0">
                    <v-text-field
                      v-model="item_to_edit.code"
                      maxlength="50"
                      counter
                      :label="$t('code')"
                      :rules="[v => !!v || $t('required')]"
                      :placeholder="$t('code')"
                      outlined
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!isAdd" cols="4" class="py-0 text-left">
                    <label class="d-block label-input">
                      {{ $t("color") }}
                    </label>
                    <v-menu
                      v-model="channel_color_menu"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                          class="swatch-style"
                          :style="{ backgroundColor: item_to_edit.color }"
                          v-on="on"
                        ></div>
                      </template>
                      <v-color-picker
                        v-model="item_to_edit.color"
                        mode="hexa"
                      ></v-color-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="py-0 text-left">
                    <v-select
                      v-model="item_to_edit.commission_included"
                      :items="commission_included_options"
                      item-text="name"
                      item-value="value"
                      :label="$t('commission_included')"
                      :placeholder="$t('commission_included')"
                      rounded
                      outlined
                      dense
                    >
                      <template v-slot:[`item`]="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" class="py-0 text-left">
                    <v-select
                      v-model="item_to_edit.commission_from_gross"
                      :items="commission_from_gross_options"
                      item-text="name"
                      item-value="value"
                      :label="$t('commission_from_gross')"
                      :placeholder="$t('commission_from_gross')"
                      rounded
                      outlined
                      dense
                    >
                      <template v-slot:[`item`]="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" class="py-0 text-left">
                    <v-text-field
                      v-model="item_to_edit.formatted_commission"
                      :label="$t('commission')"
                      :placeholder="$t('commission')"
                      :rules="rulesCommission"
                      outlined
                      rounded
                      dense
                      min="0"
                      max="100"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 text-left">
                    <v-textarea
                      v-model="item_to_edit.observations"
                      counter
                      :label="$t('observations')"
                      :placeholder="$t('observations')"
                      outlined
                      rounded
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="commission_periods_headers"
                :items="commission_periods"
                hide-default-footer
                disable-pagination
                :item-class="getDatatablePeriodsClass"
                class="periods-table"
                :no-data-text="$t('no_commission_periods')"
              >
                <template v-slot:[`item.dates`]="{ item }">
                  <v-tooltip bottom dark color="#333333">
                    <template v-slot:activator="{ on: onMenu }">
                      <v-menu
                        :close-on-content-click="false"
                        min-width="auto"
                        v-model="item.menu_opened"
                      >
                        <template v-slot:activator="{ on: onDate }">
                          <div
                            v-on="{ ...onMenu, ...onDate }"
                            @click="setPeriodToEdit(item.range)"
                          >
                            <v-icon color="secondary" class="mr-2" small>
                              mdi-calendar-month-outline
                            </v-icon>
                            <b>{{ item.dates }}</b>
                          </div>
                        </template>
                        <v-date-picker
                          scrollable
                          range
                          v-model="item.range"
                          :allowed-dates="allowedPeriodsDates"
                          @change="setCommissionPeriod(item)"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                    <span>
                      {{ $t("change_dates") }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.formatted_commission`]="{ item }">
                  <div class="d-flex align-center">
                    <v-text-field
                      v-model="item.formatted_commission"
                      :rules="rulesCommission"
                      outlined
                      rounded
                      dense
                      class="flex-grow-0"
                      min="0"
                      max="100"
                      type="number"
                      @change="setCommissionPeriod(item)"
                    ></v-text-field>
                    <v-icon color="secondary" class="ml-1" small>
                      mdi-percent
                    </v-icon>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    @click.stop="showDeleteCommissionPeriod(item)"
                    icon
                    color="secondary"
                    v-on="on"
                  >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-btn
                outlined
                rounded
                color="secondary"
                @click="addCommissionPeriod()"
                class="mt-5"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t("add_period") }}
              </v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setChannel()"
            :disabled="!can_set_channel"
            v-if="item_to_edit.id"
          >
            {{ $t("set_channel") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addChannel()"
            :disabled="!can_set_channel"
          >
            {{ $t("add_channel") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditChannel"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import Vue from "vue";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    item_to_edit: {},
    show_delete_channel: false,
    show_edit_channel: false,
    show_delete_commission_period: false,
    original_segment_id: null,
    can_set_channel: null,
    autocomplete_iatas: null,
    loading_periods: false,
    period_to_edit: [],
    period_to_delete_dates: null,
    channel_color_menu: false,
    isAdd: false,
    search: "",
    tab: 0
  }),
  props: {
    channels: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    }
  },
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row channel-row";
    },
    getDatatablePeriodsClass() {
      return "a-datatable-row period-row";
    },
    formatCommission(commission) {
      return Utils.formatPercentPlain(commission * 100, this.l);
    },
    getObservationsMini(observations) {
      const max = 40;
      if (observations !== null && observations.length > max) {
        return observations.substring(0, max) + "...";
      }
      return observations;
    },
    getChannelIatas() {
      this.$store
        .dispatch("getChannelIatas")
        .then(() => {})
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    filterCodeChannel(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    clearEditForm() {
      if (this.$refs.edit_channel_form) {
        this.$refs.edit_channel_form.resetValidation();
      }
    },
    showAddChannel() {
      this.clearEditForm();
      this.isAdd = true;
      this.show_edit_channel = true;
      this.autocomplete_iatas = null;
      this.item_to_edit = {
        id: null,
        segment_name: null,
        segment_id: null,
        hotel_id: this.current_hotel.id ? this.current_hotel.id : null,
        commission: 0,
        formatted_commission: 0,
        commission_included: 0,
        commission_periods: []
      };
    },
    showEditChannel(channel) {
      if (!channel) {
        return;
      }
      this.isAdd = false;
      if (!this.item_to_edit.hasOwnProperty("commission_periods")) {
        this.loading_periods = true;
        this.$store
          .dispatch("getChannelCommissionPeriods", {
            hotel_id: this.current_hotel.id,
            channel_id: channel.id
          })
          .then(() => {
            this.loading_periods = false;
            this.initShowEditChannel(channel);
          })
          .catch(() => {
            Utils.showError(this, "something_went_wrong");
          });
      } else {
        this.initShowEditChannel(channel);
      }
    },
    initShowEditChannel(channel) {
      this.clearEditForm();
      this.item_to_edit = JSON.parse(JSON.stringify(channel));
      this.item_to_edit.segment_name = null;
      this.autocomplete_iatas = channel.name;
      let formatted_commission = this.item_to_edit.commission * 100;
      this.item_to_edit["formatted_commission"] = formatted_commission.toFixed(
        2
      );
      this.show_edit_channel = true;
    },
    hideEditChannel() {
      this.show_edit_channel = false;
      this.tab = 0;
      this.item_to_edit = {};
    },
    setChannel: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_channel"),
        color: "secondary"
      });

      this.item_to_edit.segment_id = null;
      this.item_to_edit.commission =
        this.item_to_edit.formatted_commission / 100;

      this.$store
        .dispatch("setChannel", this.item_to_edit)
        .then(() => {
          if (this.item_to_edit.commission_periods.length > 0) {
            this.fetchSetPeriods(
              this.item_to_edit.commission_periods,
              this.item_to_edit.id
            );
          }
          this.show_edit_channel = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("channel_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getSegments();
        })
        .catch(() => {
          this.show_edit_channel = false;
          this.item_to_edit = {};
          Utils.showError(this, "channel_not_modified");
        });
    },
    addChannel: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_channel"),
        color: "secondary"
      });

      this.item_to_edit.segment_id = null;
      this.item_to_edit.commission =
        this.item_to_edit.formatted_commission / 100;

      this.$store
        .dispatch("setChannel", this.item_to_edit)
        .then(response => {
          if (this.item_to_edit.commission_periods.length > 0) {
            this.fetchSetPeriods(
              this.item_to_edit.commission_periods,
              response.id
            );
          }
          this.show_edit_channel = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("channel_added"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getSegments();
        })
        .catch(() => {
          this.show_edit_channel = false;
          this.item_to_edit = {};
          Utils.showError(this, "channel_not_modified");
        });
    },
    showDeleteChannel(channel) {
      if (!channel) {
        return;
      }
      this.show_delete_channel = true;
      this.item_to_edit = channel;
    },
    hideDeleteChannel() {
      this.show_delete_channel = false;
      this.item_to_edit = {};
    },
    deleteChannel: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_channel"),
        color: "secondary"
      });
      this.$store
        .dispatch("deleteChannel", {
          hotel_id: this.current_hotel.id,
          id: this.item_to_edit.id
        })
        .then(() => {
          this.show_delete_channel = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("channel_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getSegments();
        })
        .catch(() => {
          this.show_delete_channel = false;
          this.item_to_edit = {};
          Utils.showError(this, "channel_not_deleted");
        });
    },
    addCommissionPeriod() {
      if (!this.item_to_edit.commission_periods) {
        this.item_to_edit.commission_periods = [];
      }
      let last_date = Vue.moment().format("YYYY-MM-DD");
      if (this.item_to_edit.commission_periods.length > 0) {
        this.item_to_edit.commission_periods.sort((a, b) => {
          return Vue.moment(a.end_date).diff(Vue.moment(b.end_date));
        });
        last_date = this.item_to_edit.commission_periods[
          this.item_to_edit.commission_periods.length - 1
        ].end_date;
      }
      let start_date = Vue.moment(last_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
      last_date = Vue.moment(last_date)
        .add(7, "days")
        .format("YYYY-MM-DD");
      let period = {
        start_date: start_date,
        end_date: last_date,
        commission: this.item_to_edit.commission
      };
      this.item_to_edit.commission_periods.push(period);
    },
    setPeriodToEdit(period) {
      this.period_to_edit = period;
    },
    setCommissionPeriod(item) {
      if (!item) {
        return false;
      }
      this.item_to_edit.commission_periods.filter(p => {
        if (
          p.start_date === item.range_original[0] &&
          p.end_date === item.range_original[1]
        ) {
          p.commission = item.formatted_commission / 100;
          p.start_date = item.range[0];
          p.end_date = item.range[1];
        }
      });
      item.menu_opened = false;
    },
    showDeleteCommissionPeriod(item) {
      if (!item) {
        return false;
      }
      this.show_delete_commission_period = true;
      this.period_to_edit = item.range;
      this.period_to_delete_dates = item.dates;
    },
    hideDeleteCommissionPeriod() {
      this.show_delete_commission_period = false;
      this.period_to_edit = [];
      this.period_to_delete_dates = null;
    },
    deleteCommissionPeriod() {
      if (!this.period_to_edit) {
        return false;
      }
      this.item_to_edit.commission_periods = this.item_to_edit.commission_periods.filter(
        p =>
          p.start_date !== this.period_to_edit[0] &&
          p.end_date !== this.period_to_edit[1]
      );
      this.hideDeleteCommissionPeriod();
    },
    async fetchSetPeriods(periods, channel_id) {
      await this.$store
        .dispatch("setChannelCommissionPeriod", {
          hotel_id: this.current_hotel.id,
          channel_id: channel_id,
          commission_periods: periods
        })
        .then(() => {
          this.show_edit_channel = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("channel_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          Utils.showError(this, "commission_period_not_added");
        });
    },
    allowedPeriodsDates(date) {
      if (!this.item_to_edit.commission_periods || !this.period_to_edit) {
        return true;
      }
      let periods = this.item_to_edit.commission_periods;
      periods = periods.filter(
        p =>
          p.start_date !== this.period_to_edit[0] &&
          p.end_date !== this.period_to_edit[1]
      );
      let allowed = true;
      periods.forEach(p => {
        if (date >= p.start_date && date <= p.end_date) {
          allowed = false;
        }
      });
      return allowed;
    },
    getSegments() {
      this.$emit("getSegments");
    }
  },
  computed: {
    filtered_channels() {
      let channels = this.channels;
      if (this.segment_id > 0) {
        channels = channels.filter(c => c.segment_id === this.segment_id);
      }
      if (this.search && this.search.length > 0) {
        channels = channels.filter(c =>
          c.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return channels;
    },
    commission_periods() {
      if (!this.item_to_edit.commission_periods) {
        return [];
      }
      let commission_periods = this.item_to_edit.commission_periods.map(p => {
        let dates = Utils.getFriendlyPeriod(p.start_date, p.end_date);
        let formatted_commission = p.commission * 100;
        return {
          dates: dates,
          range: [p.start_date, p.end_date],
          range_original: [p.start_date, p.end_date],
          commission: p.commission,
          formatted_commission: formatted_commission.toFixed(2),
          menu_opened: false
        };
      });
      return commission_periods;
    },
    commission_included_options() {
      return [
        { name: this.$t("commission_included_yes"), value: 1 },
        { name: this.$t("commission_included_no"), value: 0 }
      ];
    },
    commission_from_gross_options() {
      return [
        { name: this.$t("commission_from_gross_yes"), value: 1 },
        { name: this.$t("commission_from_gross_no"), value: 0 }
      ];
    },
    channel_iatas() {
      let iatas = this.segments_store.channel_iatas;
      let channels_codes = this.channels.map(c => c.code);
      iatas = iatas.filter(i => !channels_codes.includes(i.code));
      return iatas;
    },
    rulesCommission() {
      return [
        v => v >= 0 || this.$t("commission_must_be_positive"),
        v => v <= 100 || this.$t("commission_must_be_less_than_100")
      ];
    },
    channels_headers() {
      let headers = [
        {
          text: this.$t("name"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("commission"),
          align: "left",
          sortable: true,
          value: "commission"
        },
        {
          text: this.$t("observations"),
          align: "start",
          sortable: true,
          value: "observations"
        },
        {
          text: null,
          align: "left",
          sortable: false,
          value: "actions",
          width: "120px"
        }
      ];
      return headers;
    },
    commission_periods_headers() {
      return [
        {
          text: this.$t("dates"),
          align: "left",
          sortable: true,
          value: "dates"
        },
        {
          text: this.$t("commission"),
          align: "left",
          sortable: true,
          value: "formatted_commission"
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "actions"
        }
      ];
    },
    is_manual() {
      return (
        this.current_hotel &&
        this.current_hotel.settings &&
        this.current_hotel.settings.pms &&
        this.current_hotel.settings.pms === "manual"
      );
    },
    ...mapState({
      segments_store: state => state.segmentschannels
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language"
    })
  },
  watch: {
    autocomplete_iatas(newVal, oldVal) {
      if (newVal && newVal.code) {
        this.item_to_edit.code = newVal.code;
        this.item_to_edit.name = newVal.name;
        this.item_to_edit.commission = newVal.commission;
        this.item_to_edit.observations = newVal.observations;
        this.item_to_edit.commission_included = newVal.commission_included;
        this.item_to_edit.formatted_commission = newVal.commission * 100;
        if (this.segment_id === -1) {
          let segments = this.segments_store.segments.filter(
            s => s.code === newVal.segment_code
          );
          if (segments.length > 0) {
            this.item_to_edit.segment_id = segments[0].id;
          }
        }
      } else {
        this.item_to_edit.name = newVal;
      }
    }
  },
  mounted() {
    this.getChannelIatas();
  }
};
</script>

<style lang="scss" scoped>
.channels-table .channel-row > td:last-child {
  display: flex;
  align-items: center;
  padding-right: 55px;
  justify-content: flex-end;
}
.periods-table .period-row > td {
  padding: 10px !important;
}
.periods-table
  .period-row
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 10px;
  width: 75px;
  min-height: 10px;
  margin-bottom: 0;
}
.periods-table
  .period-row
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  padding: 0;
  margin: 0;
  min-height: 0;
  width: 75px;
}
.periods-table
  .period-row
  .v-text-field.v-text-field--enclosed
  .v-text-field__details
  .v-messages {
  min-height: 0;
}
.periods-table
  .period-row
  .v-text-field.v-text-field--enclosed
  .v-text-field__details
  .v-messages__message {
  font-size: 10px;
}
.swatch-style {
  margin-top: -16px;
  border-radius: 7px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.label-input {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  transform: translateY(-16px) translateX(-16px) scale(0.75);
}
</style>
