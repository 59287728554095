<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
    :main_filters="main_filters"
  >
    <component :is="app_name" :ref="app_name" />
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import reputationApp from "@/components/app/reputationApp";

export default {
  components: {
    aPage,
    reputationApp
  },
  data: () => ({
    app_id: "reputation_overview",
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 350, //Mandatory number,
    main_filters: true,
    app_name: "reputation-app"
  })
};
</script>
