<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="750px"
  >
    <form enctype="multipart/form-data" novalidate>
      <v-card>
        <v-card-title class="headline py-6 justify-space-between">
          <h5 class="modal-title" id="budget-upload-dialog-title">
            {{ $t("bulk_import_pvp_mlos") }}
          </h5>
          <v-btn class="" icon @click="close" :aria-label="$t('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="mb-0">
                {{ $t("bulk_import_pvp_mlos_description") }}
              </p>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn rounded outlined color="info" @click="download">
                <v-icon class="mr-2">mdi-cloud-download-outline</v-icon>
                {{ $t("download_current_data") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                accept=".csv"
                :label="$t('pvp_mlos_file')"
                rounded
                outlined
                depressed
                show-size
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center py-5">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            @click="startImport"
          >
            {{ $t("start_import") }}
          </v-btn>
          <v-btn color="" text rounded depressed class="px-5" @click="close">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Vue from "vue";

export default {
  name: "a-chm-bulk-upload-dialog",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null
    };
  },
  computed: {
    ...mapState({
      budget: state => state.budget,
      budget_year: state => state.budget.budget_year
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    startImport() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("hotel_id", this.current_hotel.id);

      this.$store.dispatch("bulkUploadPvpMlos", {
        formData: formData,
        hotel_id: this.current_hotel.id
      });
      this.close();
    },
    close() {
      this.$emit("close");
    },
    download() {
      this.$store.dispatch("downloadChannelManagerPricing", {
        hotel_id: this.current_hotel.id,
        year: Vue.moment(this.minDate).format("YYYY")
      });
    }
  }
};
</script>
