<template>
  <v-card flat :style="style" light max-width="800">
    <v-card-text>
      <div class="d-flex">
        <div class="flex-grow-0 pl-2 pr-6 text-center">
          <v-avatar size="50">
            <v-img cover :alt="$t(review.ota)" :src="logo"></v-img>
          </v-avatar>
          <div class="mt-1 grey--text text--darken-2">
            {{ review.author }}
          </div>
          <div class="mt-4" v-if="review.answerable">
            <v-tooltip content-class="text-center" bottom>
              <template v-slot:activator="{ on }">
                <v-avatar
                  v-on="on"
                  dark
                  :color="review.answered ? 'green' : 'red'"
                  size="30"
                >
                  <v-icon color="white" small v-if="review.answered"
                    >mdi-comment-check-outline</v-icon
                  >
                  <v-icon color="white" small v-else
                    >mdi-comment-remove-outline</v-icon
                  >
                </v-avatar>
              </template>
              <span
                v-html="review.answered ? $t('answered') : $t('not_answered')"
              ></span>
            </v-tooltip>
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="headline mb-1">
            {{ review.title }}
          </div>
          <v-rating
            readonly
            small
            dense
            half-increments
            :value="star_rating"
          ></v-rating>
          <template v-if="review.review_positive || review.review_negative">
            <div
              class="d-flex mt-2 align-center grey--text text--darken-3"
              v-if="review.review_positive"
            >
              <div class="flex-grow-0 pr-4">
                <v-icon small color="green">mdi-thumb-up-outline</v-icon>
              </div>
              <div class="flex-grow-1">
                {{ review.review_positive }}
              </div>
            </div>
            <div
              class="d-flex mt-2 align-center grey--text text--darken-3"
              v-if="review.review_negative"
            >
              <div class="flex-grow-0 pr-4">
                <v-icon small color="red">mdi-thumb-down-outline</v-icon>
              </div>
              <div class="flex-grow-1">
                {{ review.review_negative }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mt-2 grey--text text--darken-3" v-if="review.review">
              {{ review.review }}
            </div>
          </template>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="pl-12">
      <v-chip
        small
        class=" ml-12 mr-2 body-2 grey--text text--darken-2"
        color="grey lighten-4"
      >
        <v-icon class="ml-0 mr-1" small left color="accent">
          mdi-calendar-outline
        </v-icon>
        {{ review.date | formatDateFull }}
      </v-chip>
      <v-chip
        small
        class="mr-2 body-2 grey--text text--darken-2"
        color="grey lighten-4"
      >
        <v-icon class="ml-0 mr-1" small left color="accent">
          mdi-map-marker-outline
        </v-icon>
        {{ hotel.name }}
      </v-chip>
      <v-avatar
        size="20"
        dark
        outlined
        class="white--text mr-2"
        style="border:1px solid #999999;"
      >
        <flag :iso="review.language" style="font-size:25px;" />
      </v-avatar>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-reputation-review",
  components: {},
  props: {
    review: {
      type: Object,
      default: () => {
        return {
          id: "2134",
          ota: "booking",
          date: "2020-10-10",
          title: "No me jodas Rafa!",
          author: "Pedrito",
          rating: 88,
          review_positive:
            "Una maravillosa experiencia. Todo el parque es bellísimo y excelente para pasear. Sus vistas son hermosas y ya sea que quieras relajarte o hacer ejercicio todo está increíble",
          review_negative:
            "Una maravillosa experiencia. Todo el parque es bellísimo y excelente para pasear. Sus vistas son hermosas y ya sea que quieras relajarte o hacer ejercicio todo está increíble",
          review: null,
          language: "es",
          answered: false,
          answerable: true
        };
      }
    }
  },
  data: () => ({}),
  methods: {},
  mounted() {},
  computed: {
    hotel() {
      return this.getHotelById(this.review.hotel_id);
    },
    star_rating() {
      return parseFloat((this.review.rating / 20).toFixed(2));
    },
    color() {
      return this.otas.find(ota => ota.code == this.review.ota).color;
    },
    style() {
      return "border-left: 5px solid " + this.color;
    },
    logo() {
      return this.otas.find(ota => ota.code == this.review.ota).logo;
    },
    ...mapState({
      bi_report: state => state.bi.report,
      emotions: state => state.emotions,
      otas: state => state.otas
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getHotelById: "getHotelById"
    })
  }
};
</script>
