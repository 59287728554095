var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"transition-swing mb-3",attrs:{"elevation":hover ? 3 : 0,"flat":""},on:{"click":function($event){$event.stopPropagation();return _vm.setCurrentHotel(_vm.hotel)}}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.hotel.name))]),_c('v-card-text',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-rating',{attrs:{"value":_vm.hotel.settings.stars,"color":"primary","dense":"","half-increments":"","readonly":"","size":"14"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[(Object.keys(_vm.hotel_parities).length)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.next_days),function(day){return _c('div',{key:'ranking_day_' + _vm.hotel.id + '_' + day,staticClass:"mr-1",staticStyle:{"flex":"1"}},[_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"d-flex justify-center pb-2"},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":25,"value":_vm.hotel_parities[day]
                          ? _vm.hotel_parities[day].ok.percent +
                            _vm.hotel_parities[day].impaired.percent
                          : 0,"color":_vm.parity_colors['ok']}})],1),(_vm.hotel_parities[day])?_c('div',{staticStyle:{"height":"50px","overflow":"hidden","border-top-right-radius":"5px","border-top-left-radius":"5px"}},_vm._l((Object.keys(
                        _vm.hotel_parities[day]
                      ).reverse()),function(parity_code){return _c('div',{key:'percent_' + parity_code,staticStyle:{"width":"100%"},style:('height:' +
                          _vm.hotel_parities[day][parity_code].percent +
                          '%; background-color:' +
                          _vm.parity_colors[parity_code] +
                          ';')})}),0):_c('div',{staticStyle:{"height":"50px","overflow":"hidden","border-top-right-radius":"5px","border-top-left-radius":"5px","background-color":"lightgray"}}),_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"grey--text darken-3--text text-uppercase font-weight-bold uppercase"},[_vm._v(" "+_vm._s(_vm.getDay(day))+" ")])])])]}}],null,true)},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("parity"))+" "+_vm._s(_vm.getFullDay(day))+": "),_c('br'),_c('b',{staticClass:"subtitle-1 d-block"},[_vm._v(_vm._s(_vm.hotel_parities[day] ? _vm.format_percent( _vm.hotel_parities[day].ok.percent + _vm.hotel_parities[day].impaired.percent ) : _vm.$t("no_available_rooms")))])])])],1)}),0):_c('div',{staticClass:"d-flex align-center justify-center pa-8 headline grey--text",staticStyle:{"border":"2px dashed #cacaca","border-radius":"5px"}},[_c('v-icon',{staticClass:"d-block headline"},[_vm._v("mdi-progress-clock")]),_c('p',{staticClass:"subtitle-1 ml-2 my-0"},[_vm._v(_vm._s(_vm.$t("empty_data")))])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }