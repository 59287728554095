<template>
  <div :id="$options.name">
    <v-row class="mt-n4">
      <v-col cols="12" class="pt-0">
        <a-rms-strategy-weeks
          ref="a-rms-strategy-weeks"
          :event_period="event_period"
          :tab_view="tab_view"
        ></a-rms-strategy-weeks>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import aRmsStrategyWeeks from "@/components/rms/clicktotravel/aRmsStrategyWeeks";

export default {
  name: "rms-strategy-app",
  components: {
    aRmsStrategyWeeks
  },
  props: {
    event_period: {
      type: Array,
      default: () => []
    },
    tab_view: {
      type: String,
      default: "table"
    }
  },
  data: () => ({
    init_date: null
  }),
  watch: {
    current_hotel() {
      if (!this.extraction_id) {
        this.setDefault();
      }
    }
  },
  methods: {
    setDefault() {
      this.init_date = Vue.moment().format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      current_hotel: "current_hotel",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days"
    })
  }
};
</script>
