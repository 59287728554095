<template>
  <div class="text-center mb-3" style="position:relative;">
    <template v-if="is_running_import">
      <p class="">{{ $t("automatic_import_running") }}</p>
      <v-progress-linear
        indeterminate
        color="secondary"
        height="2"
        class="mb-3"
      ></v-progress-linear>
    </template>
    <template v-else-if="current_hotel">
      <v-btn small rounded outlined color="info" @click="show_confirm = true">
        <v-icon small class="mr-2">mdi-rocket-launch-outline</v-icon>
        {{ $t("start_automatic_import") }}
      </v-btn>
    </template>
    <!-- Dialeg per confirmar si estar segur -->
    <v-dialog
      v-model="show_confirm"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title>{{ $t("start_automatic_import") }}</v-card-title>
        <v-card-text>
          {{ $t("start_automatic_import_confirmation") }}
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="startAutomaticImport()"
          >
            {{ $t("start") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="show_confirm = false"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "new-automatic-import",
  data() {
    return {
      show_confirm: false
    };
  },
  methods: {
    startAutomaticImport() {
      this.$store.dispatch("startAutomaticImport");
      this.show_confirm = false;
    }
  },
  computed: {
    is_running_import() {
      return (
        this.current_hotel &&
        this.current_hotel.settings &&
        this.current_hotel.settings.running_import
      );
    },
    ...mapGetters(["current_hotel"])
  }
};
</script>

<style scoped lang="scss"></style>
