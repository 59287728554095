<template>
  <!-- RATE DATE TO COMPARE -->
  <div id="highlights">
    <highlights-list ref="highlights-list"></highlights-list>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import highlightsList from "@/components/highlights/highlightsList";
var numeral = require("numeral");

export default {
  name: "dashboard-rate-app",
  components: { highlightsList },
  data: vm => ({}),
  watch: {},
  methods: {},
  mounted() {},
  computed: {}
};
</script>
