<template>
  <div>
    <v-row class="mx-0">
      <v-col class="spacer pa-0"> </v-col>
      <v-col class="pa-0 ma-0 roomtypes-container">
        <v-row class="pa-0 ma-0 roomtypes-wrapper" :style="styleRow">
          <v-col
            v-for="(column, index) of columns"
            :key="index"
            class="pa-0 text-center flex-shrink-0"
          >
            <v-row class="roomtype-cell-row">
              <v-col
                cols="12"
                class="pa-0 pt-2 pb-2 roomtype-title grey"
                :class="index === 'total' ? 'lighten-1' : 'lighten-3'"
              >
                <span
                  class="grey--text text--darken-3 text-uppercase d-flex align-center justify-center"
                >
                  <v-avatar
                    v-if="index !== 'total'"
                    size="10"
                    class="avatar"
                    :color="roomtype_color(index)"
                  ></v-avatar>
                  <b>{{ roomtype_title(index) }}</b>
                  <v-chip v-if="index !== 'total'" x-small class="ml-2">{{
                    roomtype_rooms(index)
                  }}</v-chip>
                </span>
              </v-col>
            </v-row>
            <v-row class="roomtype-cell-row">
              <v-col
                v-for="col of column"
                :key="col"
                class="pa-0 pt-1 kpi-cell d-flex align-center justify-center grey lighten-5"
                :class="bgCellClass(index, col)"
              >
                <span class="grey--text text--darken-3 text-uppercase">
                  {{ $t(col) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";

export default {
  name: "a-rms-strategy-availability-columns",
  components: {},
  props: {
    columns: Object,
    page: Number,
    roomtypes_per_screen: Number,
    roomtypes_total: Number
  },
  computed: {
    styleRow() {
      let width = (this.roomtypes_total / this.roomtypes_per_screen) * 100;
      if (width < 100) {
        width = 100;
      }
      return (
        "transform: translateX(calc(((-100%) * " +
        this.roomtypes_per_screen +
        " * " +
        this.page +
        ") / " +
        this.roomtypes_total +
        "));" +
        "width: " +
        width +
        "%"
      );
    },
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({
      roomtypes: "pmsRoomTypes"
    })
  },
  methods: {
    isHovered(roomtype, kpi) {
      return this.rms.availability_roomtype_kpi_hover === roomtype + "_" + kpi;
    },
    bgCellClass(roomtype, kpi) {
      let classes = [];
      if (this.isHovered(roomtype, kpi)) {
        classes.push("kpi-hover");
      }
      return classes.join(" ");
    },
    isVisibleItem(kpi) {
      if (this.only_rooms && (kpi === "free" || kpi === "locked")) {
        return true;
      } else if (!this.only_rooms) {
        return true;
      }
      return false;
    },
    roomtype_title(code) {
      if (code === "total") {
        return this.$t("total");
      }
      return Utils.getDimensionName(this.roomtypes, code);
    },
    roomtype_rooms(code) {
      let s = this.roomtypes.filter(s => s.code === String(code));
      if (s.length > 0) {
        return s[0].available_rooms;
      }
      return null;
    },
    roomtype_color(code) {
      return Utils.getDimensionColor(this.roomtypes, code);
    }
  }
};
</script>
<style lang="scss" scoped>
.spacer {
  min-width: 150px;
  max-width: 150px;
  z-index: 1;
}
.roomtypes-container {
  overflow: hidden;
  .roomtypes-wrapper {
    transition: transform 0.2s;
    transition-timing-function: ease;
    .kpi-cell {
      height: $minHeightColRow;
      border-top: $borderType $borderColorV !important;
      border-left: $borderType $borderColorV !important;
      border-right: $borderType $borderColorV !important;
      border-bottom: 3px solid transparent !important;
      display: block;
      line-height: 16px;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      width: 100%;

      &.weekend {
        background: rgba(219, 198, 77, 0.1);
      }
      &.kpi-hover {
        border-bottom: 3px solid #2d6ece !important;
      }
      &:last-child {
        border-right: $borderType #c5c5c5 !important;
      }
    }
    .roomtype-cell-row {
      margin: 0;
      .roomtype-title {
        border-right: $borderType #c5c5c5 !important;
        line-height: 16px;
        font-size: 12px;
        font-weight: 600;
        .avatar {
          margin-right: 5px;
          margin-bottom: 1px;
        }
      }
    }
    > .kpi-cell {
      &:first-child {
        border-left: $borderType $borderColorV;
      }
    }
  }
}
</style>
