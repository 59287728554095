<template>
  <!-- RATE DATE TO COMPARE -->
  <v-dialog
    id="rate-highlight"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show_rate_highlight"
  >
    <v-card id="rate-highlight">
      <v-card-title class="headline justify-center text-capitalize">
        {{ $t("to_highlight") }}
        <v-spacer></v-spacer>
        <v-btn
          style="position:absolute; top:13px; right:10px;"
          icon
          @click="showRateHighlight(false)"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <a-base-component :subscribed_modules="subscribed_modules">
        <template v-slot:default>
          <v-card-text
            style="height: 90%;"
            class="grey lighten-5 py-8"
            v-if="groupDisplay"
          >
            <v-row>
              <v-col
                cols="6"
                md="2"
                v-for="competitor in competitors"
                :key="competitor.id"
              >
                <v-card @click="seeHotelHighlight(competitor.hotel_id)">
                  <v-list-item two-line>
                    <v-list-item-avatar
                      size="50"
                      :color="competitor.color"
                      class="white--text title"
                      style="display:inline-block;"
                    >
                      {{ getHotelHighlights(competitor.hotel_id).length }}<br />
                      <v-icon
                        x-small
                        color="white"
                        style="display:inline-block;"
                        >mdi-star-four-points-outline</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-card-title class="text-uppercase subtitle-2 pa-0">
                        {{ competitor.name }}
                      </v-card-title>
                      <v-rating
                        readonly
                        small
                        dense
                        :value="competitor.stars"
                      ></v-rating>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="grey lighten-5 py-8" v-else>
            <v-row class="row pb-6 justify-end">
              <v-col cols="12" sm="6" class="py-0">
                <div class="v-messages theme--light text-uppercase ml-2">
                  <div class="v-messages__wrapper">
                    {{ $t("extraction_date") }}
                  </div>
                </div>
                <v-menu
                  v-model="extraction_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedMyExtractionDate"
                      flat
                      readonly
                      dense
                      solo
                      rounded
                      outlined
                      background-color="white"
                      append-icon="mdi-calendar-month-outline"
                      v-on="on"
                      style="width:290px; flex: 0 0 auto;display: inline-flex; cursor:pointer"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="my_extraction_date"
                    @change="changeExtractionDate"
                    :allowed-dates="extractionAllowedDate"
                    first-day-of-week="1"
                  ></v-date-picker>

                  <v-list>
                    <v-list-item-group v-model="highlights_extraction_id">
                      <v-list-item
                        v-for="(item,
                        i) in extraction_date_available_rate_extractions"
                        :key="'extraction_date_available_rate_extractions_' + i"
                        @change="extraction_menu = false"
                        :value="item.id"
                        :disabled="item.id == highlights_extraction_id"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-center"
                            v-text="'a las ' + item.time"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div :class="{ 'float-right': $vuetify.breakpoint.smAndUp }">
                  <div class="v-messages theme--light text-uppercase ml-2">
                    <div class="v-messages__wrapper">
                      {{ $t("priority") }}
                    </div>
                  </div>
                  <v-btn-toggle
                    v-model="priority"
                    color="secondary accent-3"
                    dense
                    rounded
                    outlined
                    block
                    multiple
                  >
                    <v-tooltip
                      color="black"
                      bottom
                      v-for="p in priorities"
                      :key="p.id"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :value="p.id" class="white">
                          <v-avatar size="16" :color="p.color"></v-avatar>
                          <span class="ml-1 overline">{{ $t(p.literal) }}</span>
                        </v-btn>
                      </template>
                      <span>{{ $t(p.literal) }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="highlights.length == 0">
              <v-col class="py-0" cols="12">
                <v-card-title class="pa-0">
                  {{ $t("nothing_to_highlight") }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row v-else-if="priority.length > 0 && highlights_shown == 0">
              <v-col class="py-0" cols="12">
                <v-card-title class="pa-0">
                  {{ $t("nothing_to_highlight_priorities") }}
                </v-card-title>
                <v-btn
                  color="info"
                  class="text-center mt-3"
                  rounded
                  outlined
                  @click="priority = []"
                  >{{ $t("see_all_highlights") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-for="type in highlight_types" :key="type.name">
              <v-col
                class="py-0"
                cols="12"
                v-if="highlights_to_show(type.name).length"
              >
                <v-card-title class="pa-0 subtitle-2 text-uppercase">
                  <v-tooltip color="black" bottom :key="'circular' + type.name">
                    <template v-slot:activator="{ on }">
                      <v-progress-circular
                        v-on="on"
                        class="mr-2"
                        size="20"
                        :value="type_completition[type.name].percent"
                        color="info"
                      ></v-progress-circular>
                    </template>
                    <span
                      >{{ type_completition[type.name].done }}/{{
                        type_completition[type.name].total
                      }}</span
                    >
                  </v-tooltip>

                  {{ $t(type.name, type.extra) }}
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col
                    v-for="(h, index) in highlights_to_show(type.name)"
                    :key="index"
                    cols="12"
                    sm="6"
                    class="py-0"
                  >
                    <a-hightlight-item
                      :key="'highlight_' + index"
                      :hightlight="h"
                      :show_type="false"
                      @loadParity="loadParity"
                    ></a-hightlight-item>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-slot:loading>
          <div class="my-6">
            <v-skeleton-loader
              class="my-6 px-6"
              type="text"
            ></v-skeleton-loader>
          </div>
          <div style="height: 90%;" class="py-8">
            <v-row>
              <v-col cols="6" class="px-8">
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="6" class="px-8">
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
        </template>
      </a-base-component>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
var numeral = require("numeral");
import aHightlightItem from "@/components/aHightlightItem";
import aBaseComponent from "@/components/aBaseComponent";

export default {
  name: "a-parity-highlight",
  components: {
    aHightlightItem,
    aBaseComponent
  },
  props: ["extraction_id"],
  data: vm => ({
    highlight_date: null,
    my_extraction_date: null,
    // priority: [],
    extraction_menu: null,
    subscribed_modules: ["rate_available_rate_extractions", "highlight_list"]
  }),
  watch: {
    extraction_id() {
      this.highlights_extraction_id = this.extraction_id;
      this.my_extraction_date = this.getDateFromId(this.extraction_id);
    },
    extraction_menu() {
      // If user closes datepicker without selecting one import, set the first
      if (
        this.extraction_menu == false &&
        this.highlights_extraction_id == undefined
      ) {
        this.highlights_extraction_id = this.extraction_date_available_rate_extractions[0].id;
      }
    }
  },
  methods: {
    seeHotelHighlight(hotel_id) {
      const hotel = this.getHotelById(hotel_id);
      this.$router.push({
        name: "parity",
        params: {
          hotel_slug: hotel.settings.slug
        },
        query: {
          highlights: true
        }
      });
    },
    getHotelHighlights(hotel_id) {
      return this.highlights.filter(
        h => h.hotel_id == hotel_id && h.section == "paritychannel"
      );
    },
    isDone(highlight) {
      return this.current_user.settings &&
        this.current_user.settings.highlights_done &&
        this.current_user.settings.highlights_done.indexOf(highlight.id) >= 0
        ? true
        : false;
    },
    seeHighlight(highlight) {
      //We use 'user' for dispatching when this.current_user = user;
      var user = JSON.parse(JSON.stringify(this.current_user));

      //We verify it exists and it's an array
      var highlights_done =
        this.current_user.settings && this.current_user.settings.highlights_done
          ? JSON.parse(
              JSON.stringify(this.current_user.settings.highlights_done)
            )
          : [];
      if (Object.keys(user.settings).length === 0) {
        user.settings = {};
      }
      if (highlights_done.indexOf(highlight.id) < 0) {
        // We add it
        highlights_done.push(highlight.id);
        user.settings.highlights_done = highlights_done;
      } else {
        // We remove it
        highlights_done.splice(highlights_done.indexOf(highlight.id), 1);
        user.settings.highlights_done = highlights_done;
      }

      // We update it here, for UX improve
      this.current_user = user;

      // We save it in the server, in background (no waiting time for user)
      this.$store
        .dispatch("updateUser", this.current_user)
        .then(() => {
          // this.$store.dispatch("setSnackbar", {
          //   title: this.$t("modified_user"),
          //   color: "secondary"
          // });
        })
        .catch(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    },
    handle_function_call(function_name, function_params, highlight) {
      this[function_name](highlight, function_params);
    },
    loadParity(highlight, data) {
      // By default we set it as done, when we look it
      if (!this.isDone(highlight)) {
        this.seeHighlight(highlight);
      }
      if (data.hotel_id) {
        this.show_rate_highlight = false;
        this.$emit("updateFilters", data);
      }
    },
    extractionAllowedDate(v) {
      return this.available_rate_extractions &&
        this.available_rate_extractions.find(node => node.date == v)
        ? true
        : false;
    },
    getDateFromId(id) {
      return (
        id &&
        this.available_rate_extractions &&
        this.available_rate_extractions.find(node =>
          node.extractions.find(node => node.id == id)
        ).date
      );
    },
    changeExtractionDate() {
      // set default value
      this.highlights_extraction_id = undefined;
      if (this.extraction_date_available_rate_extractions.length == 1) {
        this.highlights_extraction_id = this.extraction_date_available_rate_extractions[0].id;
        this.extraction_menu = false;
      }
    },
    getColorFromPriority(priority) {
      var p = this.priorities.find(p => p.id == priority);
      return p ? p.color : null;
    },
    getIconFromPriority(priority) {
      var p = this.priorities.find(p => p.id == priority);
      return p ? p.icon : null;
    },
    mustShowPriority(priority) {
      return this.priority.indexOf(priority) >= 0;
    },
    highlights_to_show(type_name) {
      var vm = this;
      return this.priority.length > 0 && this.getHighlightsByType(type_name)
        ? this.getHighlightsByType(type_name).filter(h =>
            vm.mustShowPriority(h.priority)
          )
        : this.getHighlightsByType(type_name);
    },
    formatDate(date) {
      return date
        ? Vue.moment(date)
            .format("D MMM YYYY")
            .replace(".", "")
            .toUpperCase()
        : "";
    },
    changeUserData() {
      if (this.valid) {
        this.$store
          .dispatch("modifyUser", this.user)
          .then(() => {
            this.user.token = this.current_user.token;
            this.$store
              .dispatch("updateCredentials", this.current_user)
              .then(() => {
                this.$store.dispatch("setSnackbar", {
                  title: this.$t("modified_user"),
                  color: "secondary"
                });
              });
          })
          .catch(() => {
            this.$store.dispatch("setSnackbar", {
              title: this.$t("something_went_wrong"),
              color: "error"
            });
          });
      }
    },
    ...mapActions(["showRateHighlight"])
  },
  computed: {
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    priority: {
      get() {
        return this.$store.state.rate.highlight_priority;
      },
      set(value) {
        this.$store.dispatch("setHighlightPriority", value);
      }
    },
    show_rate_highlight: {
      get() {
        return this.$store.state.rate.show_rate_highlight;
      },
      set(value) {
        this.$store.dispatch("showRateHighlight", value);
      }
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    highlights_extraction_id: {
      get() {
        return this.$store.state.highlights_extraction_id;
      },
      set(value) {
        this.$store.dispatch("setHighlightsExtractionId", value);
      }
    },
    formatedMyExtractionDate() {
      return (
        this.formatDate(this.my_extraction_date) +
        " " +
        this.$t("at") +
        " " +
        this.extraction_time
      );
    },
    extraction_date_available_rate_extractions() {
      return this.my_extraction_date && this.available_rate_extractions
        ? this.available_rate_extractions.find(
            node => node.date == this.my_extraction_date
          ).extractions
        : [];
    },
    extraction_time() {
      return this.extraction_date_available_rate_extractions.find(
        node => node.id == this.highlights_extraction_id
      )
        ? this.extraction_date_available_rate_extractions.find(
            node => node.id == this.highlights_extraction_id
          ).time
        : undefined;
    },
    highlights_shown() {
      var vm = this;
      return this.highlights.length > 0
        ? this.highlights.filter(h => vm.mustShowPriority(h.priority)).length
        : 0;
    },
    type_completition() {
      var types = {};
      for (var type of this.highlight_types) {
        var highlights = this.getHighlightsByType(type.name);
        var item = {
          total: 0,
          done: 0,
          percent: 100
        };
        if (highlights) {
          var total = highlights.length;
          var done = 0;
          for (var h of highlights) {
            //Look if we have seen it
            if (this.isDone(h)) done++;
          }
          item = {
            total: total,
            done: done,
            percent: Math.round((done * 100) / total)
          };
        }
        types[type.name] = item;
      }
      return types;
    },
    highlight_types() {
      return this.getHighlightTypes.filter(
        type => type.section == "paritychannel"
      );
    },
    ...mapState({
      highlights: state => state.highlight.list,
      priorities: state => state.highlight_priorities,
      competitors: state => state.rate.competitors,
      available_rate_extractions: state => state.rate.available_rate_extractions
    }),
    ...mapGetters({
      getHotelById: "getHotelById",
      getHighlightTypes: "getHighlightTypes",
      getHighlightsByType: "getHighlightsByType",
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style></style>
