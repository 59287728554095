import { render, staticRenderFns } from "./aRmsYearSales.vue?vue&type=template&id=4fbf5c9f&scoped=true"
import script from "./aRmsYearSales.vue?vue&type=script&lang=js"
export * from "./aRmsYearSales.vue?vue&type=script&lang=js"
import style0 from "./aRmsYearSales.vue?vue&type=style&index=0&id=4fbf5c9f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fbf5c9f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VCol,VList,VListItem,VListItemContent,VListItemGroup,VRow,VSkeletonLoader,VToolbarTitle})
