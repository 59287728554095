var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white--text",staticStyle:{"position":"relative"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("new_import"))+" ")]),_c('v-card-text',[_c('v-btn-toggle',{staticClass:"b-transparent v-sheet",attrs:{"mandatory":""},model:{value:(_vm.import_type),callback:function ($$v) {_vm.import_type=$$v},expression:"import_type"}},[(_vm.show_import_daily)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-avatar',{attrs:{"size":"45"}},[_c('v-icon',[_vm._v("mdi-calendar-plus")])],1)],1)]}}],null,false,624905543)},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("daily_import"))+" ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2 text-wrap",staticStyle:{"font-size":"11px !important"}},[_vm._v(_vm._s(_vm.$t("daily_import_sub")))])],1)],1):_vm._e(),(_vm.show_import_channel)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-avatar',{attrs:{"size":"45"}},[_c('v-icon',[_vm._v("mdi-graph-outline")])],1)],1)]}}],null,false,3184831715)},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("channel_import"))+" ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2 text-wrap",staticStyle:{"font-size":"11px !important"}},[_vm._v(_vm._s(_vm.$t("channel_import_sub")))])],1)],1):_vm._e(),(_vm.show_import_hotesldot)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-avatar',{attrs:{"size":"45"}},[_c('v-img',{attrs:{"src":_vm.hotelsdot_logo,"contain":"","max-height":"25"}})],1)],1)]}}],null,false,2804804120)},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("migrate_hotelsdot"))+" ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2 text-wrap",staticStyle:{"font-size":"11px !important"}},[_vm._v(_vm._s(_vm.$t("migrate_hotelsdot_sub")))])],1)],1):_vm._e(),(_vm.show_import_reservations)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-avatar',{attrs:{"size":"45"}},[_c('v-icon',[_vm._v("mdi-book-open-variant-outline")])],1)],1)]}}],null,false,3615505221)},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("reservation_import"))+" ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2 text-wrap",staticStyle:{"font-size":"11px !important"}},[_vm._v(_vm._s(_vm.$t("reservation_import_sub")))])],1)],1):_vm._e()],1),(_vm.import_type_key === 'hotelsdot')?_c('hotelsdot-migrate-imports'):[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("select_revenue_file_to_import"))+". "+_vm._s(_vm.$t("download_an_example_if_not_know_format"))+" ")])]),(_vm.import_type_key !== 'reservation')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","rounded":"","outlined":"","color":"info"},on:{"click":_vm.downloadTemplate}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cloud-download-outline")]),_vm._v(" "+_vm._s(_vm.$t("download_template_example"))+" ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("import_date")))]),_c('v-menu',{attrs:{"close-on-content-click":true,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"value":_vm.friendly_import_date,"flat":"","readonly":"","dense":"","solo":"","rounded":"","outlined":"","background-color":"white","append-icon":"mdi-calendar-month-outline"}},on))]}}])},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDate,"first-day-of-week":"1"},model:{value:(_vm.import_date),callback:function ($$v) {_vm.import_date=$$v},expression:"import_date"}})],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("file_to_import")))]),_c('v-file-input',{attrs:{"accept":".xlsx,.csv,.xls","label":_vm.input_label,"flat":"","dense":"","solo":"","rounded":"","outlined":"","show-size":""},model:{value:(_vm.import_file),callback:function ($$v) {_vm.import_file=$$v},expression:"import_file"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('import-columns-config',{directives:[{name:"show",rawName:"v-show",value:(_vm.import_type_key === 'daily'),expression:"import_type_key === 'daily'"}],ref:"import-columns-config"}),_c('import-reservation-columns-config',{directives:[{name:"show",rawName:"v-show",value:(_vm.import_type_key === 'reservation'),expression:"import_type_key === 'reservation'"}],ref:"import-reservation-columns-config"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-5",attrs:{"color":"secondary","rounded":"","depressed":"","disabled":!_vm.can_import},on:{"click":_vm.startImport}},[_vm._v(" "+_vm._s(_vm.$t("start_import"))+" ")])],1),(_vm.alias_check)?_c('v-col',{staticClass:"text-center text-body-2 text-wrap",attrs:{"cols":"12"}},[_vm._v(" * "+_vm._s(_vm.$t("alias_check"))+" "),_c('b',[_vm._v(_vm._s(_vm.alias_check))])]):_vm._e()],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }