<template>
  <div class="edit-dialog" @click.left.exact="open">
    <v-menu
      rounded-lg
      transition="slide-x-reverse-transition"
      origin="top left"
      v-model="menu"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-on="on" v-bind="attrs"></slot>
      </template>
      <div class="px-4 white">
        <h5 class="pt-1 grey--text darken-4 uppercase">
          {{ getDayName }} {{ getDay }} {{ getMonthShortName }}
          {{ getShortYear }}
        </h5>
        <small> {{ info.rate_name }}</small>
        <v-text-field
          class="mt-2"
          style="width:150px;"
          outlined
          rounded
          type="number"
          small
          dense
          :value="internalValue"
          @input="update"
          :label="info.rate_name"
          single-line
          :rules="rules"
          @update:error="updateError"
          :append-icon="currencyIcon"
          @keypress.enter="save"
          @keypress.esc="cancel"
          autofocus
        >
        </v-text-field>
      </div>
    </v-menu>
  </div>
</template>
<script>
import Vue from "vue";
import { channelManagerCellOperations } from "@/utils";

const operations = channelManagerCellOperations();
export default {
  props: {
    rate: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => [
        v => !!v || this.$t("cant_be_empty"),
        v => v > 0 || this.$t("greater_zero")
      ]
    },
    currency: {
      type: String,
      default: "eur"
    }
  },
  model: {
    prop: "rate",
    event: "input"
  },
  data: function() {
    return {
      menu: false,
      hasError: false,
      internalValue: undefined
    };
  },
  methods: {
    log(event) {
      // console.log(event);
      event.preventDefault();
    },
    updateError(e) {
      // console.log("updateError", e);
      this.hasError = e;
    },
    update(e) {
      this.internalValue = e * 1;
    },
    save() {
      if (!this.hasError) {
        // TODO update channelManager's changesHistory
        const data = {
          action: "applyRatesPriceChanges",
          action_type: "price",
          payload: {
            strict: true,
            form: {
              entity_ids: [
                this.info.isRate ? this.info.rate_id : this.info.channel_rate_id
              ],
              type_rate_change: "absolute",
              value: this.internalValue,
              entity_type: "rate"
            },
            // daysSelected: [parseInt(this.getDayOfWeek)],
            daysSelected: [0, 1, 2, 3, 4, 5, 6],
            operation: operations.absolute,
            datesRange: [this.info.date, this.info.date]
          }
        };
        this.$store.dispatch("updateChangesHistory", data);
        this.rate.price = this.internalValue;
        this.$emit("input:rate:price", this.internalValue);
        this.close();
      }
    },
    close() {
      // console.log("close", this.price);
      this.menu = false;
    },

    //USELESS
    open() {
      // console.log("open");
      this.menu = true;
      this.internalValue = this.rate.price;
    },
    cancel() {
      this.menu = false;
      // console.log("cancel");
    }
  },
  computed: {
    currencyIcon() {
      return `mdi-currency-${this.currency.toLowerCase()}`;
    },
    getDayName() {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(this.info.date).format("dddd")
        : Vue.moment(this.info.date).format("dd");
    },
    getDay() {
      return Vue.moment(this.info.date).format("D");
    },
    getMonthShortName() {
      return Vue.moment(this.info.date)
        .format("MMM")
        .replace(".", "");
    },
    getShortYear() {
      return Vue.moment(this.info.date).format("YY");
    },
    getDayOfWeek() {
      return Vue.moment(this.info.date).format("d");
    }
  }
};
</script>
<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase;
}

.edit-dialog {
  & ::v-deep .v-small-dialog__activator__content {
    display: block;
  }
}
</style>
