<template>
  <div id="special-rates-app">
    <v-tabs
      background-color="transparent"
      slider-size="2"
      v-model="tab"
      dark
      grow
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        {{ $t("special_rates") }}
      </v-tab>
      <v-tab>
        {{ $t("offers") }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item eager>
          <!-- Special rates -->
          <a-special-rates ref="a-special-rates"></a-special-rates>
        </v-tab-item>
        <v-tab-item eager>
          <!-- Offers -->
          <a-offers ref="a-offers"></a-offers>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import aSpecialRates from "@/components/special-rates/aSpecialRates.vue";
import aOffers from "@/components/offers/aOffers.vue";

export default {
  components: {
    aSpecialRates,
    aOffers
  },
  name: "rms-special-rates-app",
  data: () => ({
    tab: 0
  })
};
</script>
