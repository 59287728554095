<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="id"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import colors from "@/constants/colors";
import { mapState } from "vuex";

export default {
  name: "a-pickup-booking-pace-weeks-chart",
  components: {},
  props: {
    id: {
      type: String,
      default: "a-pickup-booking-pace-weeks-chart"
    },
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      return {
        type: "mixed",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 30px 25px 50px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: this.chart_settings.globals.scaleColor,
          item: {
            paddingTop: "10px",
            color: this.chart_settings.globals.scaleColor
          },
          tick: {
            size: 0
          },
          labels: this.labels
        },
        scaleY: {
          values: "-20:100:20",
          format: "%v%",
          lineWidth: "0",
          item: {
            color: this.chart_settings.globals.scaleColor
          },
          guide: {
            visible: false
          },
          tick: {
            visible: false
          }
        },
        crosshairX: {
          lineColor: this.chart_settings.crosshair_settings.lineColor,
          lineWidth: this.chart_settings.crosshair_settings.lineWidth,
          lineStyle: this.chart_settings.crosshair_settings.lineStyle,
          marker: this.chart_settings.crosshair_settings.marker,
          scaleLabel: this.chart_settings.crosshair_settings.scaleLabel,
          plotLabel: {
            shadow: true,
            "shadow-alpha": 0.3,
            "shadow-distance": "4px",
            "shadow-color": "#666666",
            "border-color": "#999999",
            "border-width": 1,
            "font-family": "Roboto Condensed",
            "background-color": "#ffffff",
            "font-color": "#333333",
            "font-size:": "13px",
            padding: "15px",
            "border-radius": "2px",
            "text-align": "left"
          }
        }
      };
    },
    graph_series() {
      let series = [];
      let text =
        "<span style='color: %color;font-size:15px;'>%data-guide:</span>" +
        "<span style='font-size:15px; font-weight:bold;'>%v%</span>";

      series.push({
        type: "line",
        values: this.series.current,
        "data-guide": this.series.current_dates.map(date =>
          Vue.moment(date).format("DD/MM/YYYY")
        ),
        color: "#5489d0",
        lineColor: "#5489d0",
        lineWidth: "3px",
        aspect: "spline",
        marker: {
          borderWidth: 0,
          borderColor: "#5489d0",
          backgroundColor: "#5489d0"
        },
        guideLabel: {
          text: text,
          decimalsSeparator: ","
        }
      });

      this.series.vs.forEach((serie, index) => {
        let color = colors[index + 20];
        series.push({
          type: "line",
          values: serie,
          "data-guide": this.series.vs_dates[index].map(date =>
            Vue.moment(date).format("DD/MM/YYYY")
          ),
          color: color,
          lineColor: color,
          lineWidth: "3px",
          aspect: "spline",
          marker: {
            borderWidth: 0,
            borderColor: color,
            backgroundColor: color
          },
          guideLabel: {
            text: text,
            decimalsSeparator: ","
          }
        });
      });

      series.push({
        type: "bar",
        stacked: true,
        values: this.series.rn,
        "data-guide": this.$t("rn"),
        backgroundColor: "#66BB6A",
        borderRadiusBottomLeft: 0,
        borderRadiusBottomRight: 0,
        borderRadiusTopLeft: this.chart_settings.globals.borderRadiusBars,
        borderRadiusTopRight: this.chart_settings.globals.borderRadiusBars,
        guideLabel: {
          text: text,
          decimalsSeparator: ","
        }
      });

      series.push({
        type: "bar",
        stacked: true,
        values: this.series.cancellations.map(v => -v),
        "data-guide": this.$t("cancellation"),
        backgroundColor: "#E53935",
        borderRadiusBottomLeft: this.chart_settings.globals.borderRadiusBars,
        borderRadiusBottomRight: this.chart_settings.globals.borderRadiusBars,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        guideLabel: {
          text: text,
          decimalsSeparator: ","
        }
      });

      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings
    })
  }
};
</script>
