<template>
  <div>
    <a-special-rates-table-row-days
      :days="days"
      :maxSlides="maxSlides"
    ></a-special-rates-table-row-days>
    <v-virtual-scroll
      :items="rows"
      :item-height="44"
      :height="500"
      class="overflow-x-hidden"
    >
      <template v-slot:default="{ item, index }">
        <a-special-rates-table-row-group
          :rowData="item"
          :rowIndex="index"
          v-if="item.isGroup"
        ></a-special-rates-table-row-group>
        <a-special-rates-table-row-rate
          :rowData="item"
          :rowIndex="index"
          v-if="item.isRate"
        ></a-special-rates-table-row-rate>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
import aSpecialRatesTableRowDays from "@/components/special-rates/aSpecialRatesTableRowDays.vue";
import aSpecialRatesTableRowGroup from "@/components/special-rates/aSpecialRatesTableRowGroup.vue";
import aSpecialRatesTableRowRate from "@/components/special-rates/aSpecialRatesTableRowRate.vue";

export default {
  name: "a-special-rates-table",
  components: {
    aSpecialRatesTableRowDays,
    aSpecialRatesTableRowGroup,
    aSpecialRatesTableRowRate
  },
  props: {
    days: Array,
    rows: Array,
    maxSlides: Number
  }
};
</script>
<style lang="scss" scoped>
.overflow-x-hidden {
  overflow-x: hidden;
}
::v-deep .channel-row {
  background-color: #e5f2f6;
}
</style>
