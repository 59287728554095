<template>
  <v-row>
    <v-col cols="12">
      <v-card color="grey lighten-4" outlined>
        <v-card-title class="grey--text text--darken-3 white">
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <div class="text-uppercase font-weight-bold">
              {{ $t("general") }}
            </div>
            <div class="d-flex align-center">
              <div
                class="rounded-pill grey lighten-4 d-flex align-center justify-space-between mr-2 pa-1"
              >
                <a-label class="px-4 caption">
                  {{ $t("comparative") }}:
                </a-label>
                <v-btn
                  x-small
                  rounded
                  fab
                  :text="mode_general !== 'percent'"
                  :color="mode_general === 'percent' ? 'secondary' : 'black'"
                  @click="mode_general = 'percent'"
                >
                  <v-icon
                    small
                    :color="mode_general === 'percent' ? 'white' : 'black'"
                  >
                    mdi-percent-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  x-small
                  rounded
                  fab
                  :text="mode_general !== 'absolute'"
                  :color="mode_general === 'absolute' ? 'secondary' : 'black'"
                  @click="mode_general = 'absolute'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="mode_general === 'absolute' ? 'white' : 'black'"
                  >
                    mdi-counter
                  </v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="isVisibleExpandGeneral"
                @click="expandGeneral"
                rounded
                depressed
                outlined
                color="info"
              >
                {{ $t("expand_all") }}
              </v-btn>
              <v-btn
                v-if="isVisibleCollapseGeneral"
                @click="collapseGeneral"
                rounded
                depressed
                outlined
                color="info"
                class="ml-2"
              >
                {{ $t("collapse_all") }}
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text class="white pa-0">
          <a-rms-strategy-weeks-detail-kpis-columns
            :columns="columns"
            :columns_no_vs="columns_no_vs"
          ></a-rms-strategy-weeks-detail-kpis-columns>
          <a-rms-strategy-weeks-detail-kpis-row
            v-for="(row, index) in general_table"
            :key="index"
            :columns="columns"
            :columns_no_vs="columns_no_vs"
            :rowData="row"
            :rowIndex="index"
            :increment_mode="mode_general"
            @clickRow="clickGeneralRow"
          ></a-rms-strategy-weeks-detail-kpis-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="grey lighten-4" outlined>
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3 white"
        >
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <div>{{ $t("pickup") }}</div>
            <div class="d-flex align-center">
              <div
                class="rounded-pill grey lighten-4 d-flex align-center justify-space-between mr-2 pa-1"
              >
                <a-label class="px-4 caption">
                  {{ $t("comparative") }}:
                </a-label>
                <v-btn
                  x-small
                  rounded
                  fab
                  :text="mode_pickups !== 'percent'"
                  :color="mode_pickups === 'percent' ? 'secondary' : 'black'"
                  @click="mode_pickups = 'percent'"
                >
                  <v-icon
                    small
                    :color="mode_pickups === 'percent' ? 'white' : 'black'"
                  >
                    mdi-percent-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  x-small
                  rounded
                  fab
                  :text="mode_pickups !== 'absolute'"
                  :color="mode_pickups === 'absolute' ? 'secondary' : 'black'"
                  @click="mode_pickups = 'absolute'"
                  class="ml-2"
                >
                  <v-icon
                    small
                    :color="mode_pickups === 'absolute' ? 'white' : 'black'"
                  >
                    mdi-counter
                  </v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="isVisibleExpandPickups"
                @click="expandPickups"
                rounded
                depressed
                outlined
                color="info"
              >
                {{ $t("expand_all") }}
              </v-btn>
              <v-btn
                v-if="isVisibleCollapsePickups"
                @click="collapsePickups"
                rounded
                depressed
                outlined
                color="info"
              >
                {{ $t("collapse_all") }}
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text class="white pa-0">
          <a-rms-strategy-weeks-detail-kpis-pickups-columns
            :columns="pickup_columns"
          ></a-rms-strategy-weeks-detail-kpis-pickups-columns>
          <a-rms-strategy-weeks-detail-kpis-pickups-row
            v-for="(row, index) in pickups_table"
            :key="index"
            :columns="pickup_columns"
            :rowData="row"
            :rowIndex="index"
            :increment_mode="mode_pickups"
            @clickRow="clickPickupsRow"
          ></a-rms-strategy-weeks-detail-kpis-pickups-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import aLabel from "@/components/aLabel.vue";
import aRmsStrategyWeeksDetailKpisColumns from "@/components/rms/aRmsStrategyWeeksDetailKpisColumns.vue";
import aRmsStrategyWeeksDetailKpisRow from "@/components/rms/aRmsStrategyWeeksDetailKpisRow.vue";
import aRmsStrategyWeeksDetailKpisPickupsColumns from "@/components/rms/aRmsStrategyWeeksDetailKpisPickupsColumns.vue";
import aRmsStrategyWeeksDetailKpisPickupsRow from "@/components/rms/aRmsStrategyWeeksDetailKpisPickupsRow.vue";

export default {
  name: "a-rms-strategy-weeks-detail-kpis",
  components: {
    aLabel,
    aRmsStrategyWeeksDetailKpisColumns,
    aRmsStrategyWeeksDetailKpisRow,
    aRmsStrategyWeeksDetailKpisPickupsColumns,
    aRmsStrategyWeeksDetailKpisPickupsRow
  },
  data: () => ({
    general_table_original: [],
    general_table_clicked: [],
    pickups_table_original: [],
    pickups_table_clicked: [],
    mode_general: "percent",
    mode_pickups: "percent"
  }),
  computed: {
    columns() {
      return [
        { value: "room_revenue" },
        { value: "occupancy" },
        { value: "rn" },
        { value: "adr" },
        { value: "revpar" },
        { value: "intermediation" }
      ];
    },
    columns_no_vs() {
      return [
        { value: "free" },
        { value: "locked" },
        { value: "house_use" },
        { value: "cupos" },
        { value: "groups_locked" },
        { value: "groups_confirmed" }
      ];
    },
    pickup_columns() {
      return [
        { value: "room_revenue" },
        { value: "occupancy" },
        { value: "rn" },
        { value: "adr" },
        { value: "revpar" }
      ];
    },
    general_table() {
      return this.general_table_original.filter(item =>
        this.isRowOpen(item, this.general_table_clicked)
      );
    },
    pickups_table() {
      return this.pickups_table_original.filter(item =>
        this.isRowOpen(item, this.pickups_table_clicked)
      );
    },
    isVisibleExpandGeneral() {
      return this.isVisibleExpand(
        this.general_table_clicked,
        this.general_table_original
      );
    },
    isVisibleCollapseGeneral() {
      return this.isVisibleCollapse(this.general_table_clicked);
    },
    isVisibleExpandPickups() {
      return this.isVisibleExpand(
        this.pickups_table_clicked,
        this.pickups_table_original
      );
    },
    isVisibleCollapsePickups() {
      return this.isVisibleCollapse(this.pickups_table_clicked);
    },
    ...mapState({
      rms: state => state.rms
    })
  },
  methods: {
    isRowOpen(rowData, table) {
      let is_open = false;
      if (!("expand" in rowData)) {
        is_open = true;
      }
      if (table.includes(rowData.parent)) {
        is_open = true;
      }
      return is_open;
    },
    clickRow(rowData, table) {
      if (table.includes(rowData.spot)) {
        let rows_to_delete = table.filter(
          spot => spot === rowData.spot || spot.startsWith(rowData.spot)
        );
        rows_to_delete.forEach(spot => {
          let index = table.findIndex(sp => sp === spot);
          table.splice(index, 1);
        });
      } else {
        table.push(rowData.spot);
      }
    },
    clickGeneralRow(rowData) {
      this.clickRow(rowData, this.general_table_clicked);
    },
    clickPickupsRow(rowData) {
      this.clickRow(rowData, this.pickups_table_clicked);
    },
    expandGeneral() {
      this.general_table_clicked = this.general_table_original.map(
        item => item.spot
      );
    },
    collapseGeneral() {
      this.general_table_clicked = [];
    },
    expandPickups() {
      this.pickups_table_clicked = this.pickups_table_original.map(
        item => item.spot
      );
    },
    collapsePickups() {
      this.pickups_table_clicked = [];
    },
    isVisibleExpand(table_clicked, table_original) {
      return (
        table_original.filter(item => "expand" in item).length > 0 &&
        table_clicked.length < table_original.length
      );
    },
    isVisibleCollapse(table_clicked) {
      return table_clicked.length > 0;
    },
    putParent(item) {
      if (!("expand" in item)) {
        return { ...item };
      } else {
        return {
          ...item,
          parent: item.spot
            .split(" - ")
            .slice(0, -1)
            .join(" - ")
        };
      }
    }
  },
  mounted() {
    // General
    this.general_table_original = this.rms.strategy_range_kpis_table.map(
      item => {
        return this.putParent(item);
      }
    );
    //Pickups
    this.pickups_table_original = this.rms.strategy_range_kpis_pickups_table.map(
      item => {
        return this.putParent(item);
      }
    );
  }
};
</script>
