<template>
  <v-row>
    <v-col cols="12">
      <v-card color="grey lighten-4" outlined>
        <v-card-title
          class="text-uppercase font-weight-bold grey--text text--darken-3"
        >
          general
        </v-card-title>
        <v-card-text class="white pa-0">
          <a-rms-day-general-kpi-columns
            :columns="columns_kpis"
            :dense="dense"
          ></a-rms-day-general-kpi-columns>
          <a-rms-day-general-kpi-row
            v-for="(row, index) in rows_kpis"
            :key="index"
            :columns="columns_kpis"
            :rowData="row"
            :rowIndex="index"
            :dense="dense"
          ></a-rms-day-general-kpi-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aRmsDayGeneralKpiColumns from "@/components/rms/hotelsdot/aRmsDayGeneralKpiColumns.vue";
import aRmsDayGeneralKpiRow from "@/components/rms/hotelsdot/aRmsDayGeneralKpiRow.vue";

export default {
  name: "a-rms-day-general",
  components: {
    aRmsDayGeneralKpiColumns,
    aRmsDayGeneralKpiRow
  },
  props: {
    date: String,
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rows_kpis: [],
    rows_pickups: []
  }),
  computed: {
    columns_kpis() {
      return [
        {
          title: "Rev",
          align: "left",
          sortable: true,
          value: "room_revenue"
        },
        {
          title: "Adr",
          align: "left",
          sortable: true,
          value: "adr"
        },
        {
          title: "Rn",
          align: "left",
          sortable: true,
          value: "rn"
        },
        {
          title: "Occ",
          align: "left",
          sortable: true,
          value: "occupancy"
        },
        {
          title: "Revpar",
          align: "left",
          sortable: true,
          value: "revpar"
        },
        {
          title: this.$t("intermediation"),
          align: "left",
          sortable: true,
          value: "intermediation"
        },
        {
          title: this.$t("groups_confirmed"),
          align: "left",
          sortable: true,
          value: "groups_confirmed"
        },
        {
          title: this.$t("house_use"),
          align: "left",
          sortable: true,
          value: "house_use"
        },
        {
          title: this.$t("locked"),
          align: "left",
          sortable: true,
          value: "locked"
        }
      ];
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    getKpisDay(date) {
      this.$store
        .dispatch("fetchRmsKpisDay", date)
        .then(response => {
          this.rows_kpis = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getKpisDayPickups(date) {
      this.$store
        .dispatch("fetchRmsKpisDayPickups", date)
        .then(response => {
          this.rows_pickups = response;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    async getHotelComparativeDates() {
      await this.$store
        .dispatch("getHotelComparativeDates", {
          hotel_id: this.current_hotel.id,
          year: Vue.moment(this.date).format("YYYY"),
          from_date: Vue.moment(this.date)
            .startOf("year")
            .format("YYYY-MM-DD"),
          to_date: Vue.moment(this.date)
            .endOf("year")
            .format("YYYY-MM-DD")
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }
  },
  mounted() {
    this.getKpisDay(this.date);
    this.getKpisDayPickups(this.date);
    this.getHotelComparativeDates();
  }
};
</script>
