<template>
  <div class="a-rms-day-row-header">
    <v-row no-gutters>
      <v-col cols="3">
        {{ labels[0] }}
      </v-col>
      <v-col cols="3">
        {{ labels[1] }}
      </v-col>
      <v-col cols="3">
        {{ labels[2] }}
      </v-col>
      <v-col cols="3">
        {{ labels[3] }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "a-rms-day-row-header",
  components: {},
  props: {
    labels: {
      type: Array,
      mandatory: true,
      default: () => []
    }
  },
  data: vm => ({}),
  watch: {},
  methods: {},
  computed: {}
};
</script>
<style scoped>
.a-rms-day-row-header {
  font-size: 12px;
}
</style>
