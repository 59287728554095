<template>
  <v-row
    class="kpi-row mx-0"
    :class="classRow"
    @click="row_active = !row_active"
  >
    <v-col class="kpi-label-wrapper pa-0">
      <div class="kpi-label d-flex align-center">
        <template v-if="!rowData['expand']">
          <a-period-compare :periods="periods()" dense></a-period-compare>
          <v-spacer></v-spacer>
          <template v-if="row_active">
            <v-btn fab x-small depressed>
              <v-icon small>mdi-chevron-up</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn fab x-small depressed>
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </template>
        <template v-else>
          <v-avatar
            size="10"
            class="avatar mr-1"
            :color="roomtype_color(room_title(rowData['spot']))"
          ></v-avatar>
          <b>{{ roomtype_title(room_title(rowData["spot"])) }}</b>
        </template>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="(col, index) of columns"
          :key="index"
          class="pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center"
          @mouseover="kpi_hover = col.value"
          @mouseleave="kpi_hover = ''"
        >
          <template v-if="!rowData['expand']">
            <a-kpi
              :kpi="col.value"
              :value="rowData[col.value]"
              :vs_values="vs_values(col)"
              :vs_periods="vs_periods()"
              x_small
            ></a-kpi>
          </template>
          <template v-else>
            <a-kpi :kpi="col.value" :value="rowData[col.value]" x_small></a-kpi>
          </template>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";

export default {
  name: "a-rms-day-general-pickup-row",
  components: {
    aKpi,
    aPeriodCompare
  },
  props: {
    columns: Array,
    rowData: Object,
    rowIndex: Number,
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    row_active: false
  }),
  computed: {
    kpi_hover: {
      get() {
        return this.$store.state.rms.day_general_pickup_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsDayGeneralPickupHover", value);
      }
    },
    classRow() {
      let clas = "";
      if (this.rowData["expand"]) {
        clas += " kpi-row-child";
      } else {
        clas += " kpi-row-parent";
      }
      if (this.row_active) {
        clas += " kpi-row-active";
      }
      if (this.dense) {
        clas += " dense";
      }
      return clas;
    },
    ...mapGetters({
      roomtypes: "pmsRoomTypes"
    })
  },
  methods: {
    vs_values(col) {
      if (col.value + "_vs" in this.rowData) {
        return [this.rowData[col.value + "_vs"]];
      }
      return [];
    },
    periods() {
      if (this.rowData["expand"]) {
        return [this.rowData["spot"]];
      } else {
        return [
          this.rowData["spot"],
          this.rowData["spot"].split("_")[0] +
            "_stly_" +
            this.rowData["spot"].split("_")[1]
        ];
      }
    },
    vs_periods() {
      if (this.rowData["expand"]) {
        return [this.rowData["spot"]];
      } else {
        return [
          this.rowData["spot"].split("_")[0] +
            "_stly_" +
            this.rowData["spot"].split("_")[1]
        ];
      }
    },
    room_title(spot) {
      return spot.split(" - ")[1];
    },
    roomtype_title(code) {
      return Utils.getDimensionName(this.roomtypes, code);
    },
    roomtype_color(code) {
      return Utils.getDimensionColor(this.roomtypes, code);
    }
  }
};
</script>

<style lang="scss" scoped>
.kpi-row {
  &:hover {
    .kpi-label-wrapper {
      .kpi-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  &.kpi-row-parent {
    .kpi-label-wrapper {
      .kpi-label {
        cursor: pointer;
      }
    }
    &.kpi-row-active {
      & + .kpi-row-child,
      & + .kpi-row-child + .kpi-row-child,
      & + .kpi-row-child + .kpi-row-child + .kpi-row-child,
      & + .kpi-row-child + .kpi-row-child + .kpi-row-child + .kpi-row-child,
      &
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child,
      &
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child,
      &
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child
        + .kpi-row-child {
        display: flex;
      }
    }
  }
  &.kpi-row-child {
    display: none;
    .kpi-label-wrapper {
      .kpi-label {
        padding-left: 40px;
      }
    }
  }
  &.dense {
    .kpi-label-wrapper {
      .kpi-label {
        min-height: 40px;
      }
    }
    .kpi-cell {
      height: 40px;
    }
  }
  .kpi-label-wrapper {
    min-width: 250px;
    max-width: 250px;
    .kpi-label {
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
      padding-right: 5px;
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
</style>
