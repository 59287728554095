<template>
  <div class="edit-dialog">
    <v-edit-dialog
      @save="save"
      @cancel="cancel"
      @open="open"
      @close="close"
      v-if="current_hotel.settings.siteminder_edit_availability"
    >
      <span class="room-availability" :class="{ edited: cell.edited }">
        <v-avatar size="30" :color="getColor">
          {{ cell.available_of_rooms }}
        </v-avatar>
      </span>

      <template v-slot:input>
        <h5 class="pt-1 grey--text darken-4 uppercase">
          {{ getDayName }} {{ getDay }} {{ getMonthShortName }}
          {{ getShortYear }}
        </h5>
        <h5 class="text-body-1" v-if="info.room_name">
          <small>{{ info.room_name }}</small>
        </h5>
        <h5 class="text-body-2" v-if="info.rate_name">
          <small>{{ info.rate_name }}</small>
        </h5>
        <v-text-field
          type="number"
          :value="internalValue"
          @input="update"
          label="Edit"
          single-line
          :rules="rules"
          @update:error="updateError"
          prepend-icon="mdi-bed"
        >
        </v-text-field>
      </template>
    </v-edit-dialog>
    <div v-else>
      <span class="room-availability">
        <v-avatar size="30" :color="getColor">
          {{ cell.available_of_rooms }}
        </v-avatar>
      </span>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { channelManagerCellOperations } from "@/utils";
import { mapGetters } from "vuex";
const operations = channelManagerCellOperations();

export default {
  // components: { aKpi },
  props: {
    cell: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => [
        v => !!v || parseInt(v) === 0 || "Is required",
        v => v >= 0 || "It should be greater or equal than 0"
      ]
    }
  },
  model: {
    prop: "cell",
    event: "input"
  },
  data: function() {
    return {
      hasError: false,
      internalValue: undefined,
      initialValue: undefined,
      operations
    };
  },
  methods: {
    updateError(e) {
      // console.log("updateError", e);
      this.hasError = e;
    },
    update(e) {
      // console.log("update", e);
      this.internalValue = e * 1;
    },
    save() {
      if (!this.hasError) {
        // console.log(this.info);
        const data = {
          action: "applyAvailabilityChanges",
          action_type: "availability",
          payload: {
            strict: true,
            form: {
              entity_ids: [this.info.room_id],
              type_rate_change: "absolute",
              value: this.internalValue,
              entity_type: "room"
            },
            // daysSelected: [parseInt(this.getDayOfWeek)],
            daysSelected: [0, 1, 2, 3, 4, 5, 6],
            operation: this.operations.absolute,
            datesRange: [this.info.day, this.info.day]
          }
        };
        this.$store.dispatch("updateChangesHistory", data);
        this.cell.available_of_rooms = this.internalValue;
      }
    },
    close() {
      // console.log("close", this.price);
    },

    //USELESS
    open() {
      // console.log("open");
      this.internalValue = this.cell.available_of_rooms;
      this.initialValue = !this.initialValue
        ? this.cell.available_of_rooms
        : this.initialValue;
    },
    cancel() {
      // console.log("cancel");
    }
  },
  computed: {
    getDayName() {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(this.info.day).format("dddd")
        : Vue.moment(this.info.day).format("dd");
    },
    getDay() {
      return Vue.moment(this.info.day).format("D");
    },
    getMonthShortName() {
      return Vue.moment(this.info.day)
        .format("MMM")
        .replace(".", "");
    },
    getShortYear() {
      return Vue.moment(this.info.day).format("YY");
    },
    getDayOfWeek() {
      return Vue.moment(this.info.day).format("d");
    },
    getColor() {
      return this.cell.available_of_rooms < 1
        ? "warning lighten-3"
        : this.cell.available_of_rooms !== this.cell.original_available_of_rooms
        ? "green lighten-4"
        : "info--text";
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/main.sass";
.uppercase {
  text-transform: uppercase;
}
.modified-cell {
  background-color: map-get($green, "lighten-5");
}

.edit-dialog {
  & ::v-deep .v-small-dialog__activator__content {
    display: block;
  }
  line-height: 1.25rem;
}
.edited {
  //border: 5px solid pink;
}
</style>
