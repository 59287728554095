<template>
  <v-card color="grey lighten-4 a-rms-day-room-types-table" outlined>
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      <v-row class="align-center">
        <v-col>
          {{ $t("update_rates") }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn
            v-if="haveChanges"
            color="secondary"
            rounded
            class="mr-2"
            @click="saveChanges()"
            :disabled="save_disabled"
          >
            {{ $t("save_changes") }}
          </v-btn>
          <v-btn
            v-if="haveChanges"
            color="info"
            rounded
            outlined
            @click="cancelChanges()"
          >
            {{ $t("discard_changes") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="white">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rows"
            hide-default-footer
            disable-pagination
            :item-class="getDatatableRowClass"
          >
            <template v-slot:[`item.roomtype`]="{ item }">
              <v-avatar
                size="10"
                class="avatar mr-1"
                :color="roomtype_color(item.roomtype)"
              ></v-avatar>
              <b>{{ roomtype_title(item.roomtype) }}</b>
              <small class="ml-2">{{ item.roomtype }}</small>
            </template>
            <template v-slot:[`item.pvp`]="{ item }">
              <template v-if="isPvpEditable(item)">
                <div class="d-flex align-start justify-center">
                  <v-text-field
                    :value="rows_changes[item.roomtype].pvp"
                    type="number"
                    :rules="rules_pvp"
                    :suffix="l.money_post_symbol"
                    required
                    block
                    rounded
                    outlined
                    dense
                    @input="updateItem(item, 'pvp', $event)"
                  >
                  </v-text-field>
                  <div class="d-flex flex-column btns-wrap">
                    <v-btn
                      rounded
                      depressed
                      x-small
                      @click="setPvp(item)"
                      :loading="loading_btns[item.roomtype + '_pvp']"
                      :disabled="
                        rows_changes[item.roomtype].pvp ===
                          rows_initial[item.roomtype].pvp
                      "
                    >
                      <v-icon small color="green">mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      rounded
                      depressed
                      x-small
                      @click="setInitialPvp(item)"
                      :disabled="
                        rows_changes[item.roomtype].pvp ===
                          rows_initial[item.roomtype].pvp
                      "
                    >
                      <v-icon small color="red">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-else>
                <a-kpi kpi="pvp" :value="item.pvp" x_small></a-kpi>
              </template>
            </template>
            <template v-slot:[`item.pvp_ly`]="{ item }">
              <a-kpi kpi="pvp" :value="item.pvp_ly" x_small></a-kpi>
            </template>
            <template v-slot:[`item.mlos`]="{ item }">
              <template v-if="isMlosEditable(item)">
                <div class="d-flex align-start justify-center">
                  <v-text-field
                    :value="rows_changes[item.roomtype].mlos"
                    type="number"
                    :rules="rules_mlos"
                    required
                    block
                    rounded
                    outlined
                    dense
                    @input="updateItem(item, 'mlos', $event)"
                  >
                  </v-text-field>
                  <div class="d-flex flex-column btns-wrap">
                    <v-btn
                      rounded
                      depressed
                      x-small
                      @click="setMlos(item)"
                      :loading="loading_btns[item.roomtype + '_mlos']"
                      :disabled="
                        rows_changes[item.roomtype].mlos ==
                          rows_initial[item.roomtype].mlos
                      "
                    >
                      <v-icon small color="green">mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      rounded
                      depressed
                      x-small
                      @click="setInitialMlos(item)"
                      :disabled="
                        rows_changes[item.roomtype].mlos ==
                          rows_initial[item.roomtype].mlos
                      "
                    >
                      <v-icon small color="red">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-else>
                <a-kpi kpi="mlos" :value="item.mlos" x_small></a-kpi>
              </template>
            </template>
            <template v-slot:[`item.mlos_ly`]="{ item }">
              <a-kpi kpi="mlos" :value="item.mlos_ly" x_small></a-kpi>
            </template>
            <template v-slot:[`item.pickup_1`]="{ item }">
              <div class="d-flex align-center justify-center">
                <a-kpi
                  kpi="occupancy"
                  :value="item.pickup_1_occupancy"
                  x_small
                ></a-kpi>
                <span class="ml-1 mr-1">|</span>
                <a-kpi kpi="rn" :value="item.pickup_1_rn" x_small></a-kpi>
              </div>
            </template>
            <template v-slot:[`item.pickup_2`]="{ item }">
              <div class="d-flex align-center justify-center">
                <a-kpi
                  kpi="occupancy"
                  :value="item.pickup_2_occupancy"
                  x_small
                ></a-kpi>
                <span class="ml-1 mr-1">|</span>
                <a-kpi kpi="rn" :value="item.pickup_2_rn" x_small></a-kpi>
              </div>
            </template>
            <template v-slot:[`item.pickup_3`]="{ item }">
              <div class="d-flex align-center justify-center">
                <a-kpi
                  kpi="occupancy"
                  :value="item.pickup_3_occupancy"
                  x_small
                ></a-kpi>
                <span class="ml-1 mr-1">|</span>
                <a-kpi kpi="rn" :value="item.pickup_3_rn" x_small></a-kpi>
              </div>
            </template>
            <template v-slot:[`item.pickup_4`]="{ item }">
              <div class="d-flex align-center justify-center">
                <a-kpi
                  kpi="occupancy"
                  :value="item.pickup_4_occupancy"
                  x_small
                ></a-kpi>
                <span class="ml-1 mr-1">|</span>
                <a-kpi kpi="rn" :value="item.pickup_4_rn" x_small></a-kpi>
              </div>
            </template>
            <template v-slot:[`item.pickup_5`]="{ item }">
              <div class="d-flex align-center justify-center">
                <a-kpi
                  kpi="occupancy"
                  :value="item.pickup_5_occupancy"
                  x_small
                ></a-kpi>
                <span class="ml-1 mr-1">|</span>
                <a-kpi kpi="rn" :value="item.pickup_5_rn" x_small></a-kpi>
              </div>
            </template>
            <template v-slot:[`item.pickup_6`]="{ item }">
              <div class="d-flex align-center justify-center">
                <a-kpi
                  kpi="occupancy"
                  :value="item.pickup_6_occupancy"
                  x_small
                ></a-kpi>
                <span class="ml-1 mr-1">|</span>
                <a-kpi kpi="rn" :value="item.pickup_6_rn" x_small></a-kpi>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aKpi from "@/components/rms_ui/aKpi.vue";

export default {
  name: "a-rms-day-room-types",
  components: {
    aKpi
  },
  props: {
    date: String
  },
  data: () => ({
    rows: [],
    rows_initial: {},
    rows_changes: {},
    loading_btns: [],
    save_disabled: false
  }),
  computed: {
    rules_pvp() {
      return [
        v => !!v || this.$t("required_field"),
        v => v > 0 || this.$t("must_be_positive")
      ];
    },
    rules_mlos() {
      return [v => v >= 0 || this.$t("must_be_positive")];
    },
    haveChanges() {
      return Object.keys(this.rows_changes).some(key => {
        return (
          this.rows_changes[key].pvp !== this.rows_initial[key].pvp ||
          this.rows_changes[key].mlos !== this.rows_initial[key].mlos
        );
      });
    },
    headers() {
      return [
        {
          text: this.$t("room_type"),
          value: "roomtype",
          align: "start",
          sortable: true
        },
        {
          text: this.$t("pvp"),
          value: "pvp",
          align: "center",
          sortable: true
        },
        {
          text: "pvp ly",
          value: "pvp_ly",
          align: "center",
          sortable: true
        },
        {
          text: this.$t("mlos"),
          value: "mlos",
          align: "center",
          sortable: true
        },
        {
          text: "mlos ly",
          value: "mlos_ly",
          align: "center",
          sortable: true
        },
        {
          text: this.$t("available_dense"),
          value: "free",
          align: "center",
          sortable: true
        },
        {
          text: this.getPeriodLiteral("pickup_1") + " (Occ | RN)",
          value: "pickup_1",
          align: "center",
          sortable: true
        },
        {
          text: this.getPeriodLiteral("pickup_2") + " (Occ | RN)",
          value: "pickup_2",
          align: "center",
          sortable: true
        },
        {
          text: this.getPeriodLiteral("pickup_3") + " (Occ | RN)",
          value: "pickup_3",
          align: "center",
          sortable: true
        },
        {
          text: this.getPeriodLiteral("pickup_4") + " (Occ | RN)",
          value: "pickup_4",
          align: "center",
          sortable: true
        },
        {
          text: this.getPeriodLiteral("pickup_5") + " (Occ | RN)",
          value: "pickup_5",
          align: "center",
          sortable: true
        },
        {
          text: this.getPeriodLiteral("pickup_6") + " (Occ | RN)",
          value: "pickup_6",
          align: "center",
          sortable: true
        }
      ];
    },
    ...mapGetters({
      l: "current_hotel_language",
      current_hotel: "current_hotel",
      roomtypes: "pmsRoomTypes"
    })
  },
  methods: {
    getPeriodLiteral(period) {
      if (period.split("_")[0] === "pickup") {
        return "Pickup " + this.current_hotel.settings.pickup_spots[period];
      }
      return this.$t(period);
    },
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    isPvpEditable(item) {
      return (
        item.pvp_editable && this.date >= Vue.moment().format("YYYY-MM-DD")
      );
    },
    setPvp(item) {
      let loading_btns = JSON.parse(JSON.stringify(this.loading_btns));
      loading_btns[item.roomtype + "_pvp"] = true;
      this.loading_btns = loading_btns;
      this.$store
        .dispatch("setRoomTypeDayRates", {
          date: this.date,
          roomtype: item.roomtype,
          pvp: this.rows_changes[item.roomtype].pvp
        })
        .then(response => {
          this.$emit("close-dialog");
          this.$store.dispatch("fetchRmsAllKpisDaily");
          this.$store.dispatch("fetchRmsPvpMlosChangesDaily");
          let initial = JSON.parse(JSON.stringify(this.rows_initial));
          initial[item.roomtype].pvp = this.rows_changes[item.roomtype].pvp;
          this.rows_initial = initial;
          this.loading_btns[item.roomtype + "_pvp"] = false;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setInitialPvp(item) {
      this.rows_changes[item.roomtype].pvp = this.rows_initial[
        item.roomtype
      ].pvp;
    },
    isMlosEditable(item) {
      return this.date >= Vue.moment().format("YYYY-MM-DD");
    },
    setMlos(item) {
      let loading_btns = JSON.parse(JSON.stringify(this.loading_btns));
      loading_btns[item.roomtype + "_mlos"] = true;
      this.loading_btns = loading_btns;
      this.$store
        .dispatch("setRoomTypeDayRates", {
          date: this.date,
          roomtype: item.roomtype,
          mlos: this.rows_changes[item.roomtype].mlos
        })
        .then(response => {
          this.$store.dispatch("fetchRmsAllKpisDaily");
          this.$store.dispatch("fetchRmsPvpMlosChangesDaily");
          let initial = JSON.parse(JSON.stringify(this.rows_initial));
          initial[item.roomtype].mlos = this.rows_changes[item.roomtype].mlos;
          this.rows_initial = initial;
          this.loading_btns[item.roomtype + "_mlos"] = false;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setInitialMlos(item) {
      this.rows_changes[item.roomtype].mlos = this.rows_initial[
        item.roomtype
      ].mlos;
    },
    cancelChanges() {
      this.rows_changes = JSON.parse(JSON.stringify(this.rows_initial));
    },
    saveChanges() {
      this.save_disabled = true;
      let roomtypes = [];
      Object.keys(this.rows_changes).forEach(key => {
        roomtypes.push({
          roomtype: key,
          pvp: this.rows_changes[key].pvp,
          mlos: this.rows_changes[key].mlos
        });
      });
      this.$store
        .dispatch("setRoomTypesDayRates", {
          date: this.date,
          roomtypes: roomtypes
        })
        .then(response => {
          this.$emit("close-dialog");
          this.$store.dispatch("fetchRmsAllKpisDaily");
          this.$store.dispatch("fetchRmsPvpMlosChangesDaily");
          this.rows = response;
          Object.keys(this.rows).forEach(key => {
            this.rows_initial[this.rows[key].roomtype] = {
              pvp: this.rows[key].pvp,
              mlos: this.rows[key].mlos
            };
          });
          this.rows_changes = this.rows_initial;
          this.save_disabled = false;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    updateItem(item, field, value) {
      let rows_changes = JSON.parse(JSON.stringify(this.rows_changes));
      rows_changes[item.roomtype][field] = value;
      this.rows_changes = rows_changes;
    },
    getRoomTypesDay(date) {
      this.$store
        .dispatch("fetchRmsRoomTypesDay", date)
        .then(response => {
          this.rows = response;
          Object.keys(this.rows).forEach(key => {
            this.rows_initial[this.rows[key].roomtype] = {
              pvp: this.rows[key].pvp,
              mlos: this.rows[key].mlos
            };
            this.loading_btns[this.rows[key].roomtype + "_pvp"] = false;
            this.loading_btns[this.rows[key].roomtype + "_mlos"] = false;
          });
          this.rows_changes = this.rows_initial;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    roomtype_title(code) {
      return Utils.getDimensionName(this.roomtypes, code);
    },
    roomtype_color(code) {
      return Utils.getDimensionColor(this.roomtypes, code);
    }
  },
  mounted() {
    this.getRoomTypesDay(this.date);
  }
};
</script>
<style lang="scss">
.a-rms-day-room-types-table {
  .v-card__title {
    min-height: 94px;
  }
  .v-data-table-header {
    th {
      font-size: 10px !important;
      padding: 0 2px !important;
      white-space: nowrap;
      i {
        font-size: 13px !important;
      }
    }
  }
  .a-datatable-row {
    td {
      padding: 0 2px !important;
      .d-flex {
        gap: 5px;
        .v-input {
          font-size: 13px;
          max-width: 72px;
          width: 72px;
          .v-input__slot {
            padding: 0 10px;
            min-height: 30px !important;
            margin: 0;
            legend {
              display: block;
              margin: 0;
              max-width: 0;
            }
          }
          .v-text-field__details {
            position: absolute;
            top: 100%;
            margin: 0;
          }
        }
        .btns-wrap {
          gap: 2px;
          .v-btn {
            padding: 3px;
            min-width: auto;
            height: auto;
            margin-top: 0px;
            .v-icon {
              font-size: 10px !important;
            }
          }
        }
      }
    }
  }
}
</style>
