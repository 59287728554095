<template>
  <v-row class="row-rate mx-0">
    <v-col class="rate-wrapper group-wrapper pa-0" @click="openSingleModal">
      <div class="label-wrapper d-flex align-center">
        {{ rowData.offer_name }}
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-window v-model="current_slide">
        <v-window-item v-for="(item, itemKey) of windowItems" :key="itemKey">
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="(day, index) of getDays"
              :key="index"
              class="pa-0 offers-day-column"
              :class="{ 'bg-weekend': isWeekend(day) }"
            >
              <div
                class="rate-wrapper"
                @mouseenter="
                  reportLocation({
                    offer_id: rowData.offer_id,
                    date: day,
                    row_index: rowIndex
                  })
                "
              >
                <div class="cell offer_data_main">
                  <div class="type-info">
                    <span class="" v-if="showMobile">
                      {{ rowData.days[day].price_mobile }}
                    </span>
                    <div v-else>
                      <a-offers-price-cell
                        :rate="{ ...rowData.days[day], ...rowData, date: day }"
                        :row_key="rowIndex"
                      ></a-offers-price-cell>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import aOffersPriceCell from "@/components/offers/aOffersPriceCell.vue";

export default {
  name: "a-offers-table-row-rate",
  components: {
    aOffersPriceCell
  },
  props: {
    rowData: Object,
    rowIndex: Number,
    showMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    current_slide: 0,
    isLoadingChannels: false
  }),
  watch: {
    slide_status(newValue, oldValue) {
      if (newValue === 2) {
        this.current_slide = this.page;
      }
    }
  },
  computed: {
    slide_status() {
      return this.$store.state.offers.slide_status;
    },
    getDays() {
      return this.rowData && Object.keys(this.rowData.days);
    },
    page() {
      return this.$store.state.offers.page;
    },
    windowItems() {
      return this.$store.state.offers.windowItems;
    }
  },
  methods: {
    isWeekend(day) {
      return Vue.moment(day).isoWeekday() >= 5;
    },
    reportLocation(event) {
      this.$emit("mouseRateHovering", event);
      this.$store.dispatch("setOffersHoveredRate", event);
    },
    openSingleModal() {
      this.$store.dispatch("setOffersClickedRate", this.rowData);
      this.$store.dispatch("setOffersBulkFormType", "single");
      this.$store.dispatch("setOffersBulkFormOpen", true);
    }
  },
  emits: ["mouseRateHovering"]
};
</script>
<style lang="scss" scoped>
$labelFontWeight: 700;
$labelFontSize: 0.8rem;
$labelColor: #555;
$bgColorHotel: #ddd;
.bg-weekend {
  background-color: $weekendBgColor;
}
.offers-day-column {
  width: 100%;
  &:hover .cell:has(.day-info) {
    border-bottom: 3px solid $highlightColRowColor;
  }
  .cell {
    height: $minHeightColRow;
    text-align: center;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    border-bottom: $borderType $borderColor;
    border-top: $borderType $borderColor;
    span {
      width: 100%;
      display: block;
      line-height: 20px;
      font-size: 12px;
    }
  }
  .day-heading {
    font-size: 12px;
    color: #aaa;
    text-transform: uppercase;
    font-weight: 600;
  }
  .rate-wrapper {
    &:hover {
      background-color: #f5fdff;
    }
  }
}
.row-rate {
  .group-wrapper {
    cursor: pointer;
  }
  .rate-wrapper {
    max-width: $widthLeftSide;
    .label-wrapper {
      border-top: $borderType $borderColor;
      border-bottom: $borderType $borderColor;
      height: $minHeightColRow;
      border-right: 3px solid transparent;
      padding-left: 10px;
      width: $widthLeftSide;
      font-size: $labelFontSize;
      font-weight: $labelFontWeight;
      color: $labelColor;
      justify-content: space-between;
      &:hover,
      &.highlighted {
        border-right: 3px solid $highlightColRowColor;
      }
    }
  }
  &:hover {
    .label-wrapper {
      border-right: 3px solid $highlightColRowColor;
    }
  }
}
</style>
