<template>
  <div id="rate-updated" class="d-flex">
    <div class="item rate-labels semibold"></div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="day in days"
          :key="'rate-graph-' + day"
          class="day"
          :class="{ weekend: is_weekend(day) }"
          @mouseover="date_hover = day"
          @mouseout="date_hover = ''"
        >
          <div class="day-date semibold">
            <v-tooltip
              content-class="a-rate-tooltip"
              bottom
              open-delay="1000"
              open-on-click
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  :color="color(day)"
                  size="7"
                  v-bind="attrs"
                  v-on="on"
                ></v-avatar>
              </template>
              <span>{{ $t("last_update") }} </span><br />
              <span
                :class="color(day) + '--text'"
                v-if="available_dates[day]"
                >{{
                  available_dates[day].import_datetime | formatDatetime
                }}</span
              >
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-parity-updated",
  components: {},
  props: [
    "extraction_id",
    "days",
    "wrapper_position",
    "days_per_screen",
    "language"
  ],
  data: vm => ({}),
  watch: {},
  methods: {
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    color(date) {
      if (
        this.current_hotel &&
        this.available_dates[date] &&
        this.available_dates[date].import_task_id === this.extraction_id
      ) {
        return "primary";
      } else {
        return "warning";
      }
    }
  },
  computed: {
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    ...mapState({
      available_dates: state => state.parity.available_dates.dates
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
