<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :color="isGroup ? '#ffffff' : '#f1f1f1'"
  >
    <template v-slot:header_options>
      <a-rms-range-selector
        v-if="!isGroup"
        ref="a-rms-range-selector"
        :event_period="[strategy_from, strategy_to]"
        @updatePeriod="updateRange"
      >
      </a-rms-range-selector>
      <a-rms-vs-selector
        ref="a-rms-vs-slector"
        v-if="!isGroup"
      ></a-rms-vs-selector>
      <a-rms-import-dates
        ref="rms_import_dates"
        v-if="!isGroup"
        class="ml-3"
      ></a-rms-import-dates>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <rms-strategy-app
      v-else
      ref="rms-strategy-app"
      :event_period="[strategy_from, strategy_to]"
    ></rms-strategy-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import rmsStrategyApp from "@/components/app/hotelsdot/rmsStrategyApp";
import aRmsRangeSelector from "@/components/rms/aRmsRangeSelector";
import aRmsVsSelector from "@/components/rms/aRmsVsSelector";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms";
import ARmsImportDates from "@/components/rms/aRmsImportDates.vue";

export default {
  name: "rms-strategy",
  components: {
    ARmsImportDates,
    aPage,
    rmsStrategyApp,
    aRmsRangeSelector,
    aRmsVsSelector,
    aHotelSelectorRms
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    event_period: []
  }),
  methods: {
    updateRange(v) {
      let range = JSON.parse(JSON.stringify(v));
      this.strategy_from = range[0];
      this.strategy_to = range[1];
    }
  },
  watch: {},
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 105 : 200;
    },
    strategy_from: {
      get() {
        return this.$store.state.rms.strategy_from;
      },
      set(value) {
        this.$store.dispatch("setStrategyFrom", value);
      }
    },
    strategy_to: {
      get() {
        return this.$store.state.rms.strategy_to;
      },
      set(value) {
        this.$store.dispatch("setStrategyTo", value);
      }
    },
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  mounted() {
    // Initialize selector
    this.strategy_from = Vue.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    this.strategy_to = Vue.moment()
      .endOf("month")
      .format("YYYY-MM-DD");
  }
};
</script>
