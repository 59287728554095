<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    main_filters
  >
    <br /><br /><br /><br />
    <a-test-app ref="a-test-app"></a-test-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import aCard from "@/components/aCard";
import aTestApp from "@/components/examples/aTestApp";

export default {
  components: {
    aPage,
    aTestApp
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 100, //Mandatory number
    current_hotel_id: 1,
    show_hotel_vs_selector: false
  }),
  methods: {
    setHotel(hotel) {
      this.current_hotel_id = hotel.id;
    },
    openHotelSelector() {
      this.show_hotel_vs_selector = true;
    },
    closeHotelSelector() {
      this.show_hotel_vs_selector = false;
    }
  },
  watch: {},
  computed: {
    ...mapState({}),
    ...mapGetters({})
  },
  created() {}
};
</script>
