<template>
  <div class="white--text" style="position:relative">
    <v-card>
      <v-card-title>
        {{ $t("new_import") }}
      </v-card-title>
      <v-card-text>
        <v-btn-toggle
          class="b-transparent v-sheet"
          v-model="import_type"
          mandatory
        >
          <v-tooltip right v-if="show_import_daily">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-avatar size="45"
                  ><v-icon>mdi-calendar-plus</v-icon>
                </v-avatar>
              </v-btn>
            </template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2 font-weight-bold">
                {{ $t("daily_import") }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-body-2 text-wrap"
                style="font-size:11px !important;"
                >{{ $t("daily_import_sub") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-tooltip>
          <v-tooltip right v-if="show_import_channel">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on"
                ><v-avatar size="45"
                  ><v-icon>mdi-graph-outline</v-icon></v-avatar
                ></v-btn
              >
            </template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2 font-weight-bold">
                {{ $t("channel_import") }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-body-2 text-wrap"
                style="font-size:11px !important;"
                >{{ $t("channel_import_sub") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-tooltip>
          <v-tooltip right v-if="show_import_hotesldot">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-avatar size="45"
                  ><v-img :src="hotelsdot_logo" contain max-height="25"></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2 font-weight-bold">
                {{ $t("migrate_hotelsdot") }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-body-2 text-wrap"
                style="font-size:11px !important;"
                >{{ $t("migrate_hotelsdot_sub") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-tooltip>
          <v-tooltip right v-if="show_import_reservations">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on"
                ><v-avatar size="45"
                  ><v-icon>mdi-book-open-variant-outline</v-icon></v-avatar
                ></v-btn
              >
            </template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2 font-weight-bold">
                {{ $t("reservation_import") }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-body-2 text-wrap"
                style="font-size:11px !important;"
                >{{ $t("reservation_import_sub") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-tooltip>
        </v-btn-toggle>
        <hotelsdot-migrate-imports
          v-if="import_type_key === 'hotelsdot'"
        ></hotelsdot-migrate-imports>
        <template v-else>
          <v-row>
            <v-col cols="12">
              <p class="mb-0">
                {{ $t("select_revenue_file_to_import") }}.
                {{ $t("download_an_example_if_not_know_format") }}
              </p>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
              v-if="import_type_key !== 'reservation'"
            >
              <v-btn
                small
                rounded
                outlined
                color="info"
                @click="downloadTemplate"
              >
                <v-icon small class="mr-2">mdi-cloud-download-outline</v-icon>
                {{ $t("download_template_example") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="mb-2">{{ $t("import_date") }}</div>
              <v-menu :close-on-content-click="true" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="friendly_import_date"
                    flat
                    readonly
                    dense
                    solo
                    rounded
                    outlined
                    background-color="white"
                    append-icon="mdi-calendar-month-outline"
                    v-on="on"
                    style="cursor:pointer"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="import_date"
                  :allowed-dates="allowedDate"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="10">
              <div class="mb-2">{{ $t("file_to_import") }}</div>
              <v-file-input
                v-model="import_file"
                accept=".xlsx,.csv,.xls"
                :label="input_label"
                flat
                dense
                solo
                rounded
                outlined
                show-size
              ></v-file-input>
            </v-col>
            <v-col cols="2">
              <import-columns-config
                ref="import-columns-config"
                v-show="import_type_key === 'daily'"
              ></import-columns-config>
              <import-reservation-columns-config
                ref="import-reservation-columns-config"
                v-show="import_type_key === 'reservation'"
              ></import-reservation-columns-config>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="secondary"
                rounded
                depressed
                class="px-5"
                @click="startImport"
                :disabled="!can_import"
              >
                {{ $t("start_import") }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              v-if="alias_check"
              class="text-center text-body-2 text-wrap"
            >
              * {{ $t("alias_check") }} <b>{{ alias_check }}</b>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils";
import Vue from "vue";
import ImportColumnsConfig from "@/components/revenue-import/ImportColumnsConfig.vue";
import ImportReservationColumnsConfig from "@/components/revenue-import/ImportReservationColumnsConfig.vue";
import hotelsdot_logo from "@/assets/imgs/hotelsdot.png";
import HotelsdotMigrateImports from "@/components/revenue-import/hotelsdot/MigrateImports.vue";

export default {
  name: "new-manual-import",
  components: {
    HotelsdotMigrateImports,
    ImportReservationColumnsConfig,
    ImportColumnsConfig
  },
  data: () => ({
    app_id: "revenue-import-app",
    image_source: "/img/header-2.jpg", // Mandatory path
    image_height: 140, // Mandatory number
    import_date: new Date().toISOString().split("T")[0],
    import_file: null,
    import_type: 0,
    show_import_columns: false,
    hotelsdot_logo
  }),
  computed: {
    friendly_import_date: function() {
      return this.import_date
        ? Utils.getFriendlyDate(this.import_date, true)
        : "";
    },
    can_import: function() {
      return this.import_file && this.import_date;
    },
    import_type_key: function() {
      return this.import_types[this.import_type]
        ? this.import_types[this.import_type].code
        : null;
    },
    import_types: function() {
      // depending on the user permissions
      const import_types = [];
      if (this.show_import_daily) {
        import_types.push({ code: "daily" });
      }
      if (this.show_import_channel) {
        import_types.push({ code: "channel" });
      }
      if (this.show_import_hotesldot) {
        import_types.push({ code: "hotelsdot" });
      }
      if (this.show_import_reservations) {
        import_types.push({ code: "reservation" });
      }
      return import_types;
    },
    input_label: function() {
      switch (this.import_type_key) {
        case "daily":
          return this.$t("daily_import_file");
        case "channel":
          return this.$t("channel_import_file");
        case "hotelsdot":
          return this.$t("hotelsdot_migrate");
        case "reservation":
          return this.$t("reservation_import_file");
        default:
          return this.$t("import_file");
      }
    },
    show_import_reservations() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.reservations_import_manual &&
            this.current_hotel.permissions.reservations_import_manual
        : false;
    },
    show_import_daily() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.daily_import_manual &&
            this.current_hotel.permissions.daily_import_manual
        : false;
    },
    show_import_channel() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.channel_import_manual &&
            this.current_hotel.permissions.channel_import_manual &&
            this.current_user.permissions.segmentschannels &&
            this.current_hotel.permissions.segmentschannels
        : false;
    },
    show_import_hotesldot() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rms_hotelsdot &&
            this.current_hotel.permissions.rms_hotelsdot
        : false;
    },
    alias_check() {
      return this.current_hotel &&
        this.current_hotel.settings.manual_revenue_alias_check
        ? this.current_hotel.settings.manual_revenue_alias_check
        : false;
    },
    ...mapState({
      daily_columns: state => state.dataimport.revenue_daily_columns
    }),
    ...mapGetters({
      current_user: "current_user",
      current_hotel: "current_hotel"
    })
  },
  methods: {
    startImport() {
      const formData = new FormData();
      formData.append("import_file", this.import_file);
      formData.append("import_date", this.import_date);
      formData.append("hotel_id", this.current_hotel.id);
      formData.append("import_type", this.import_type_key);

      this.$store
        .dispatch("importManualRevenue", {
          formData,
          hotel_id: this.current_hotel.id,
          import_date: this.import_date
        })
        .then(() => this.resetForm());
    },
    downloadTemplate() {
      this.$store.dispatch("downloadRevenueImportTemplate", {
        hotel_id: this.current_hotel.id,
        hotel_name: this.current_hotel.name
      });
    },
    allowedDate(date) {
      return Vue.moment(date).diff(Vue.moment()) <= 0;
    },
    updateImportList() {
      this.$store.dispatch("fetchDataimportRevenueLastImports");
    },
    resetForm() {
      this.updateImportList();
      this.import_file = null;
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped></style>
