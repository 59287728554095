<template>
  <div>
    <v-row class="px-4">
      <v-col cols="12" sm="2">
        <v-row>
          <v-col class="py-0">
            <v-text-field
              :label="$t('min_nights')"
              type="number"
              v-model="minMaxNightsForm.min_nights_value"
              :required="required.min_nights"
              :rules="rulesMinNights"
              outlined
              rounded
              dense
            >
              <template #append>
                <v-icon>
                  mdi-weather-night
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              :label="$t('max_nights')"
              type="number"
              v-model="minMaxNightsForm.max_nights_value"
              :required="required.max_nights"
              :rules="rulesMaxNights"
              outlined
              rounded
              dense
            >
              <template #append>
                <v-icon>
                  mdi-weather-night
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10">
        <autocomplete-rates
          v-model="minMaxNightsForm.selectedRates"
          required
          :rates="rates"
          :label="$t('rates_to_modify')"
        >
        </autocomplete-rates>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import autocompleteRates from "@/components/autocompleteRates.vue";
export default {
  components: { autocompleteRates },
  props: {
    minMaxNightsForm: {
      type: Object,
      default: () => ({
        min_nights_value: undefined,
        max_nights_value: undefined,
        selectedRates: []
      })
    },
    required: {
      type: Object,
      default: () => ({
        min_nights: false,
        max_nights: false
      })
    }
  },
  emits: ["update:value", "update:selectedRates"],
  computed: {
    rates() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.channelManagerTableData.hotelLabels
          .filter(e => !e.isHotel)
          // .filter(e => !e.isRate || (e.isRate && !e.parent_rate_id))
          .map(e => ({ ...e, disabled: e.isRoom, value: e.rate_id }))
      );
    },
    rulesMinNights() {
      const arrRules = [];
      if (this.required.min_nights) {
        arrRules.push(
          ...[
            v => !!v || this.$i18n.t("required_field"),
            v => v >= 0 || this.$i18n.t("must_be_positive")
          ]
        );
      }
      return arrRules;
    },
    rulesMaxNights() {
      const arrRules = [];
      if (this.required.max_nights) {
        arrRules.push(
          ...[
            v => !!v || this.$i18n.t("required_field"),
            v => v >= 0 || this.$i18n.t("must_be_positive")
          ]
        );
      }
      return arrRules;
    }
  }
};
</script>
<style lang="scss" scoped></style>
