var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4","outlined":""}},[_c('v-card-title',{staticClass:"text-uppercase font-weight-bold grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.$t("rates"))+" ")]),_c('v-card-text',{staticClass:"white pa-3",staticStyle:{"height":"90%"}},[(_vm.all_kpis_day_compset)?_c('v-data-table',{attrs:{"headers":_vm.rateDayDetailHeaders,"items":_vm.comparisonItems,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('span',{key:'label_' + item.id,staticClass:"caption font-weight-medium d-flex",class:{ 'text-uppercase': item.self }},[_c('span',{class:item.self ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item.label))])])]}},{key:"item.min_price",fn:function(ref){
var item = ref.item;
return [_c('div',{key:'min_price_' + item.id,staticClass:"d-flex justify-center align-center"},[(item.is_mobile_offer || item.is_genius_offer)?[_c('v-tooltip',{attrs:{"bottom":"","content-class":"a-rate-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.is_mobile_offer)?_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"margin-bottom":"1px"},attrs:{"color":"yellow darken-2","x-small":""}},on),[_vm._v("mdi-cellphone ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"text-center",staticStyle:{"font-size":"13px","line-height":"11px"},domProps:{"innerHTML":_vm._s(_vm.$t('is_mobile_offer'))}})]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"a-rate-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.is_genius_offer)?_c('span',_vm._g({staticClass:"blue--text text--darken-4 mt-n1 mr-2 ",staticStyle:{"font-size":"13px","font-weight":"600"}},on),[_vm._v(".g")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"text-center",staticStyle:{"font-size":"13px","line-height":"11px"},domProps:{"innerHTML":_vm._s(_vm.$t('is_genius_offer'))}})])]:_c('span',{staticClass:"mr-2",staticStyle:{"width":"10px"}}),(item.min_price)?_c('a-kpi',{staticClass:"subtitle-2",class:item.self ? 'font-weight-bold' : '',attrs:{"kpi":'min_price',"value":item.min_price}}):_c('v-chip',{attrs:{"outlined":"","color":"red","small":"","dark":""}},[_vm._v(" SOLD OUT")])],2)]}},{key:"item.regime",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:'regime_' + item.id,attrs:{"bottom":"","content-class":"a-rate-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.regime === 'alo')?_c('v-icon',_vm._g({attrs:{"color":"grey darken-2","small":""}},on),[_vm._v(" mdi-bed-king-outline ")]):_vm._e(),(item.regime === 'ad')?_c('v-icon',_vm._g({attrs:{"color":"grey darken-2","small":""}},on),[_vm._v(" mdi-coffee-outline ")]):_vm._e(),(item.regime === 'mp')?_c('v-icon',_vm._g({attrs:{"color":"grey darken-2","small":""}},on),[_vm._v(" mdi-silverware-variant ")]):_vm._e(),(item.regime === 'pc')?_c('v-icon',_vm._g({attrs:{"color":"grey darken-2","small":""}},on),[_vm._v(" mdi-silverware ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.regime)))])])]}},{key:"item.room_type_name",fn:function(ref){
var item = ref.item;
return [_c('span',{key:'room_type_name_' + item.id,staticClass:"caption font-weight-medium",class:item.self ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item.room_type_name))])]}},{key:"item.refund_policy",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:'refund_policy_' + item.id,attrs:{"bottom":"","content-class":"a-rate-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.free_cancellation)?_c('v-chip',_vm._g({class:item.self ? 'font-weight-bold' : '',attrs:{"color":"green","outlined":"","small":""}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-numeric-"+_vm._s(item.free_cancellation_days)+"-circle ")]),_vm._v(" CG ")],1):_vm._e(),(item.no_refundable)?_c('v-chip',_vm._g({class:item.self ? 'font-weight-bold' : '',attrs:{"color":"red darken-3","outlined":"","small":""}},on),[_vm._v(" NR ")]):_vm._e(),(
                item.min_price &&
                  !item.no_refundable &&
                  !item.free_cancellation
              )?_c('v-chip',_vm._g({class:item.self ? 'font-weight-bold' : '',attrs:{"color":"yellow darken-3","outlined":"","small":""}},on),[_vm._v(" PR ")]):_vm._e()]}}],null,true)},[(item.free_cancellation)?_c('span',[_vm._v(_vm._s(_vm.$t("free_cancellation")))]):_vm._e(),(item.no_refundable)?_c('span',[_vm._v(_vm._s(_vm.$t("no_refundable")))]):_vm._e(),(
              item.min_price && !item.no_refundable && !item.free_cancellation
            )?_c('span',[_vm._v(_vm._s(_vm.$t("partial_refundable")))]):_vm._e()])]}},{key:"item.minimum_nights",fn:function(ref){
            var item = ref.item;
return [_c('span',{key:'minimum_nights_' + item.id,class:item.self ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.minimum_nights)+" ")])]}},{key:"item.capacity",fn:function(ref){
            var item = ref.item;
return [_c('div',{key:'capacity_' + item.id},[_vm._l((item.room_type_adults),function(i){return _c('v-icon',{key:'type_adults_' + i + '_' + item.competitor_id,staticClass:"ml-n1",attrs:{"color":"grey darken-2","small":""}},[_vm._v("mdi-account ")])}),_c('span',{staticClass:"pa-1"}),_vm._l((item.room_type_childs),function(i){return _c('v-icon',{key:'type_childs_' + i + '_' + item.competitor_id,staticClass:"ml-n1 mt-1",attrs:{"color":"grey darken-2","x-small":""}},[_vm._v("mdi-account ")])})],2)]}},{key:"item.min_price_1",fn:function(ref){
            var item = ref.item;
return [(item.min_price)?_c('span',{key:'min_price_1_' + item.id,staticClass:"text-no-wrap",class:_vm.pickupPriceColor(item.min_price, item.min_price_1)},[_vm._v(" "+_vm._s(item.min_price - item.min_price_1)+" "),(item.currency === 'EUR')?[_c('span',{staticClass:"currency-symbol"},[_vm._v("€")])]:_vm._e()],2):_vm._e()]}},{key:"item.min_price_7",fn:function(ref){
            var item = ref.item;
return [(item.min_price)?_c('span',{key:'min_price_7_' + item.id,staticClass:"text-no-wrap",class:_vm.pickupPriceColor(item.min_price, item.min_price_7)},[_vm._v(" "+_vm._s(item.min_price - item.min_price_7)+" "),(item.currency === 'EUR')?[_c('span',{staticClass:"currency-symbol"},[_vm._v("€")])]:_vm._e()],2):_vm._e()]}},{key:"item.min_price_14",fn:function(ref){
            var item = ref.item;
return [(item.min_price)?_c('span',{key:'min_price_14_' + item.id,staticClass:"text-no-wrap",class:_vm.pickupPriceColor(item.min_price, item.min_price_14)},[_vm._v(" "+_vm._s(item.min_price - item.min_price_14)+" "),(item.currency === 'EUR')?[_c('span',{staticClass:"currency-symbol"},[_vm._v("€")])]:_vm._e()],2):_vm._e()]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }