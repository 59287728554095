<template>
  <div id="import-dates-graph" class="d-flex import-dates-row">
    <div class="item import-labels semibold pl-5">
      <div class="simple_view mt-1" style="height: 46px">
        <v-icon class="mt-n2 mr-1" small>mdi-calendar-month-outline</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">{{
          $t("this_year")
        }}</template>
      </div>
      <div class="simple_view mt-1" style="height: 42px">
        <v-icon class="mt-n1 mr-1" small
          >mdi-calendar-expand-horizontal-outline</v-icon
        >
        <template v-if="$vuetify.breakpoint.smAndUp">{{
          $t("vs_year")
        }}</template>
      </div>
    </div>
    <div class="item days">
      <div class="import-dates-graph-months-wrapper">
        <div
          class="imports-month grey lighten-5"
          v-for="(month, index) in graph_months"
          :key="'import_month_' + index"
          :style="'min-width:0;flex:' + month.number_of_days + ';'"
        >
          <span class="day-name grey--text darken-3--text py-1">{{
            getMonthName(month.month)
          }}</span>
        </div>
      </div>
      <div class="import-dates-graph-wrapper">
        <div
          class="import-day grey lighten-5"
          v-for="day in graph_values"
          :key="'import_day_' + day.date"
        >
          <v-tooltip bottom content-class="import-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="import-day-wrapper d-flex align-end"
                @click="openDay(day.date)"
              >
                <div class="import-day-data" :class="day.class"></div>
              </div>
            </template>
            <span>
              <b class="title text-center" v-if="day.imported">{{
                $t("imported")
              }}</b>
              <b class="title text-center" v-else
                >- {{ $t("not_imported") }} -</b
              >
              <br />
              {{ day.date | formatDateFull }}
            </span>
          </v-tooltip>
          <v-tooltip bottom content-class="import-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                style="border-bottom: 0 !important"
                class="import-day-wrapper d-flex align-start"
                @click="openDay(day.vs_date)"
              >
                <div class="import-day-data" :class="day.vs_class"></div>
              </div>
            </template>
            <span>
              <b class="title text-center" v-if="day.vs_imported">{{
                $t("imported")
              }}</b>
              <b class="title text-center" v-else
                >- {{ $t("not_imported") }} -</b
              >
              <br />
              {{ day.vs_date | formatDateFull }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="item">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            depressed
            elevation="0"
            color="white"
            rounded
            class="mx-2 mb-5"
            fab
            @click="show_full_year = !show_full_year"
          >
            <v-icon v-if="!show_full_year">mdi-fullscreen</v-icon>
            <v-icon v-else>mdi-fullscreen-exit</v-icon>
          </v-btn>
        </template>
        <span v-if="!show_full_year">{{ $t("show_full_year") }}</span>
        <span v-else>{{ $t("show_3_months") }}</span>
      </v-tooltip>
    </div>

    <v-dialog
      v-model="show_day_detail"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("import_date_detail")
        }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="text-right">{{ $t("date") }}</v-col>
            <v-col>{{ selected_day | formatDate }}</v-col>
          </v-row>
          <v-row v-if="!day_data">
            <v-col>
              <v-skeleton-loader type="text" class="mt-3"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-if="day_data && !day_data.imported">
            <v-col class="text-center">{{ $t("not_imported") }}</v-col>
          </v-row>
          <v-row v-if="day_data && day_data.imported">
            <v-col class="text-right">{{ $t("imported_days") }}</v-col>
            <v-col>{{ day_data.dates }}</v-col>
          </v-row>
          <v-row v-if="day_data && day_data.imported && no_dimension">
            <v-col class="text-center"
              ><v-btn
                small
                rounded
                outlined
                color="info"
                @click="downloadImportData"
              >
                <v-icon small class="mr-2">mdi-cloud-download-outline</v-icon>
                {{ $t("download_data") }}
              </v-btn></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="closeDay()"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "import-dates-graph",
  components: {},
  props: {
    calendar: {
      type: Array,
      default: () => []
    },
    highlight: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    show_day_detail: false,
    selected_day: null,
    day_data: null
  }),
  computed: {
    months() {
      return this.show_full_year
        ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        : [
            Vue.moment()
              .subtract(1, "month")
              .month() + 1,
            Vue.moment().month() + 1,
            Vue.moment()
              .add(1, "month")
              .month() + 1
          ];
    },
    graph_months() {
      const graph_months = [];
      for (let month of this.months) {
        graph_months.push({
          month: month,
          number_of_days: Vue.moment()
            .month(month - 1)
            .daysInMonth()
        });
      }
      return graph_months;
    },
    graph_values() {
      const values = [];
      for (let day of this.calendar) {
        // Check if date is in months array
        if (!this.months.includes(Vue.moment(day.date).month() + 1)) {
          continue;
        }
        const is_future = day.date > Vue.moment().format("YYYY-MM-DD");
        values.push({
          date: day.date,
          imported: day.imported,
          class:
            "imported_" +
            (is_future ? "future" : day.imported ? 1 : 0) +
            (day.date === this.highlight ? " highlight" : ""),
          vs_date: day.vs_date,
          vs_imported: day.vs_imported,
          vs_class:
            "imported_" +
            (day.vs_imported ? 1 : 0) +
            (day.vs_date === this.highlight ? " highlight" : "")
        });
      }
      return values;
    },
    show_full_year: {
      get() {
        return this.$store.state.dataimport
          .revenue_dates_calendar_show_full_year;
      },
      set(value) {
        this.$store.dispatch("setRevenueDatesCalendarShowFullYear", value);
      }
    },
    no_dimension() {
      return !this.revenue_dates_calendar_dimension;
    },
    ...mapGetters(["current_hotel"]),
    ...mapState({
      revenue_dates_calendar_dimension: state =>
        state.dataimport.revenue_dates_calendar_dimension
    })
  },
  methods: {
    getMonthName(month_number) {
      const year = Vue.moment().format("Y");
      const paddedMonth = String(month_number).padStart(2, "0");

      // date in YYYY-MM-DD using template literals
      const date = `${year}-${paddedMonth}-01`;

      // Simplified boolean logic
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("MMMM")
        : Vue.moment(date)
            .format("MMM")
            .substring(0, this.$vuetify.breakpoint.xsOnly ? 1 : 3);
    },
    openDay(day) {
      this.selected_day = day;
      this.show_day_detail = true;
      this.$store
        .dispatch("fetchImportDateDetails", {
          hotel_id: this.current_hotel.id,
          import_date: day,
          dimension: this.revenue_dates_calendar_dimension
        })
        .then(data => {
          this.day_data = data;
        });
    },
    closeDay() {
      this.selected_day = null;
      this.show_day_detail = false;
      this.day_data = null;
    },
    downloadImportData(item) {
      this.$store.dispatch("downloadImportData", {
        hotel_id: this.current_hotel.id,
        import_date: this.selected_day,
        dimension: this.revenue_dates_calendar_dimension
      });
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped>
.import-dates-row {
  display: flex;
  background: none;
  align-items: self-end;
  border-top: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
  position: relative;
}
.item {
  background-color: #ffffff;
  margin: 0px;
  text-align: center;
  font-size: 30px;
}
.item {
  @include flex(1 200px);
  @include order(2);
}

.days {
  flex: 1;
  background: none;
  padding: 0px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  position: relative;
}

.import-labels {
  width: 200px;
  border-right: 3px solid #ffffff;
  background: none;
  text-align: left;
  color: #555;
  font-family: "Roboto Condensed";
  font-size: 0.8rem;
  font-weight: bold;
}
.import-labels .simple_view {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.import-dates-graph-months-wrapper {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  display: flex;
  width: 100%;
}
.import-dates-graph-months-wrapper .day-name {
  display: block;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#import-dates-graph.rate-data-row:hover .import-labels {
  border-right: 3px solid #ffffff !important;
}
.import-dates-graph-wrapper {
  height: 90px;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.import-day {
  flex: 1;
  margin: auto;
  height: 90px;
}

.import-day-wrapper:hover {
  background: #b1cdff !important;
  border-bottom: 3px solid #999999;
}
.import-day-wrapper:hover .import-day-data {
  background: mediumblue !important;
}
.import-day-wrapper {
  cursor: pointer;
  width: 100%;
  height: 50%;
  padding: 0 0;
  border-bottom: 3px solid #cacaca;
}
.import-day-data {
  flex: 1;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin-right: 1px;
}
.import-tooltip {
  margin-top: 10px;
  background: white;
  z-index: 310 !important;
  color: black !important;
  opacity: 1;
  font-weight: 500;
  border: 2px solid #dedede;
  text-align: center;
}
.import-day-data.imported_1 {
  height: 100%;
  //background: #63a47c;
  background: rgba(99, 164, 124, 0.8);
}
.import-day-data.imported_0 {
  height: 100%;
  //background: #93231c;
  background: rgba(147, 35, 28, 0.8);
}
.import-day-data.imported_future {
  height: 100%;
  background: lightgray;
}
.import-day-data.highlight {
  background: rgba(238, 225, 143, 0.8) !important;
}

@media (max-width: 600px) {
  .import-labels {
    width: 50px !important;
  }
}
</style>
