<template>
  <div id="reputation-app">
    <a-reputation-header-graph
      ref="a-reputation-header-graph"
      class="mb-0 mx-n4"
      style="width:auto !important;"
      :ota="ota"
    ></a-reputation-header-graph>
    <v-row class="mb-4">
      <v-col cols="12" lg="3" class="pt-0 pb-4 pt-lg-0 pb-lg-0">
        <a-reputation-global-rating
          ref="a-reputation-global-rating"
          :ota="ota"
        ></a-reputation-global-rating>
      </v-col>
      <v-col cols="12" lg="9" class="d-flex justify-center py-0">
        <a-reputation-resume
          :ota="ota"
          ref="a-reputation-resume"
        ></a-reputation-resume>
      </v-col>
    </v-row>
    <a-reputation-reviews
      :ota="ota"
      ref="a-reputation-reviews"
      class="mb-4"
    ></a-reputation-reviews>
    <a-reputation-answer
      :ota="ota"
      ref="a-reputation-reviews"
      class="mb-4"
    ></a-reputation-answer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aReputationReviews from "@/components/reputation/ota/aReputationReviews";
import aReputationResume from "@/components/reputation/aReputationResume";
import aReputationHeaderGraph from "@/components/reputation/ota/aReputationHeaderGraph";
import aReputationGlobalRating from "@/components/reputation/aReputationGlobalRating";
import aReputationAnswer from "@/components/reputation/ota/aReputationAnswer.vue";

export default {
  name: "reputation-ota-app",
  components: {
    aReputationReviews,
    aReputationResume,
    aReputationHeaderGraph,
    aReputationGlobalRating,
    aReputationAnswer
  },
  props: {
    ota: {
      type: String,
      default: ""
    }
  },
  data: vm => ({}),
  watch: {},
  methods: {},
  mounted() {},
  computed: {
    // TODO: to delete
    new_reviews_items() {
      return this.bi_report
        ? [
            {
              name: "veetal",
              logo: "/img/logo/veetal-imagotipo-sma.png",
              value: parseFloat(this.bi_report.report.new_reviews) || null,
              vs_value:
                parseFloat(this.bi_report.data_to_compare.new_reviews) || null,
              increment:
                parseFloat(this.bi_report.increments.new_reviews) || null
            },
            {
              name: "booking",
              logo: "/img/otas/booking.jpg",
              value:
                parseFloat(this.bi_report.report.booking_new_reviews) || null,
              vs_value:
                parseFloat(
                  this.bi_report.data_to_compare.booking_new_reviews
                ) || null,
              increment:
                parseFloat(this.bi_report.increments.booking_new_reviews) ||
                null
            },
            {
              name: "googlebusiness",
              logo: "/img/otas/google.webp",
              value:
                parseFloat(this.bi_report.report.googlebusiness_new_reviews) ||
                null,
              vs_value:
                parseFloat(
                  this.bi_report.data_to_compare.googlebusiness_new_reviews
                ) || null,
              increment:
                parseFloat(
                  this.bi_report.increments.googlebusiness_new_reviews
                ) || null
            },
            {
              name: "expedia",
              logo: "/img/otas/expedia.jpg",
              value:
                parseFloat(this.bi_report.report.expedia_new_reviews) || null,
              vs_value:
                parseFloat(
                  this.bi_report.data_to_compare.expedia_new_reviews
                ) || null,
              increment:
                parseFloat(this.bi_report.increments.expedia_new_reviews) ||
                null
            },
            {
              name: "tripadvisor",
              logo: "/img/otas/tripadvisor.png",
              value:
                parseFloat(this.bi_report.report.tripadvisor_new_reviews) ||
                null,
              vs_value:
                parseFloat(
                  this.bi_report.data_to_compare.tripadvisor_new_reviews
                ) || null,
              increment:
                parseFloat(this.bi_report.increments.tripadvisor_new_reviews) ||
                null
            }
          ]
        : [];
    },
    ...mapState({
      bi_report: state => state.bi.report, // TODO: to delete
      bi_reputation_ratings: state => state.bi.reputation_ratings // TODO: to delete
    })
  }
};
</script>
<style scoped></style>
