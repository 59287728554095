<template>
  <v-col class="py-1" cols="6" md="3" v-if="available[permission]">
    <v-switch
      v-if="disabled"
      color="secondary"
      :label="$t(label)"
      class="my-0"
      hide-details
      dense
      v-model="item.permissions[permission]"
      disabled
    >
    </v-switch>
    <v-switch
      v-else
      color="secondary"
      v-model="item.permissions[permission]"
      :label="$t(label)"
      class="my-0"
      hide-details
      dense
      @change="changedPermission(permission)"
    >
      ></v-switch
    >
  </v-col>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    permission: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    available: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  watch: {},
  methods: {
    changedPermission() {
      this.$emit("changedPermission", this.permission);
    }
  },
  computed: {}
};
</script>
