<template>
  <div :id="app_id">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-toolbar color="white" light flat>
            <v-toolbar-title>{{ $t("segments") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showSearch" v-if="!open_search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon @click="hideSearch" v-else>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn icon @click="showAddSegment" color="secondary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-text-field
            class="px-4"
            v-model="search"
            v-if="open_search"
            rounded
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('search_segment')"
          >
          </v-text-field>
          <v-list dense>
            <v-list-item-group
              v-model="selected"
              active-class="primary--text"
              mandatory
            >
              <v-list-item :value="selected_all_channels">
                <v-list-item-avatar
                  class="white--text font-weight-bold"
                  color="pink lighten-1"
                >
                  {{ segments_store.segment_channels.length }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b class="text-uppercase">{{ $t("all_channels") }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template v-for="item in segments">
                <v-list-item
                  :key="item.name"
                  style="max-height: 60px"
                  :value="item"
                  :ripple="false"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar
                      :color="colorVisible(item)"
                      class="white--text font-weight-bold"
                    >
                      {{ item.channel_ids.length }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey darken-1"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense flat>
                          <v-list-item-group>
                            <v-list-item
                              @click.stop.prevent="showEditSegment(item)"
                            >
                              <v-list-item-title class="d-flex">
                                <v-icon small class="mr-2"
                                  >mdi-pencil-outline
                                </v-icon>
                                {{ $t("modify") }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="item.can_delete"
                              @click.stop.prevent="showDeleteSegment(item)"
                            >
                              <v-list-item-title
                                class="d-flex red--text text--darken-1"
                              >
                                <v-icon small class="mr-2" color="red darken-1">
                                  mdi-trash-can-outline
                                </v-icon>
                                {{ $t("delete") }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card :loading="loading_channels">
          <v-toolbar color="white" light flat>
            <v-toolbar-title v-if="selected.id !== -1"
              >{{ $t("channels_of") }} <b>{{ selected.name }}</b>
            </v-toolbar-title>
            <v-toolbar-title v-else>{{ $t("all_channels") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <segment-channels
              :channels="segments_store.segment_channels"
              :available_segments="available_segments"
              :segment_id="selected.id"
              @getSegments="getSegments"
            ></segment-channels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Edit Segment -->
    <v-dialog
      v-model="show_edit_segment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title
          v-if="item_to_edit.id"
          center
          class="headline justify-center py-6"
        >
          {{ $t("edit_segment") }}
        </v-card-title>
        <v-card-title v-else center class="headline justify-center py-6">
          {{ $t("new_segment") }}
        </v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-form ref="edit_segment_form" v-model="can_set_segment">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="item_to_edit.name"
                  maxlength="50"
                  counter
                  :label="$t('name')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('name')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="item_to_edit.code"
                  maxlength="50"
                  counter
                  :label="$t('code')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('code')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0 text-left">
                <label>{{ $t("is_visible") }}</label>
                <v-switch v-model="item_to_edit.visible"></v-switch>
              </v-col>
              <v-col v-if="!isAdd" cols="8" class="py-0 text-left">
                <label class="d-block mb-3">{{ $t("color") }}</label>
                <v-menu
                  v-model="segment_color_menu"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="swatch-style"
                      :style="{ backgroundColor: item_to_edit.color }"
                      v-on="on"
                    ></div>
                  </template>
                  <v-color-picker
                    v-model="item_to_edit.color"
                    mode="hexa"
                  ></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-5 justify-center">
          <v-btn
            v-if="item_to_edit.id"
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setSegment()"
            :disabled="!can_set_segment"
          >
            {{ $t("set_segment") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addSegment()"
            :disabled="!can_set_segment"
          >
            {{ $t("add_segment") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditSegment"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE Segment -->
    <v-dialog
      v-model="show_delete_segment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center"
          >{{ $t("are_you_sure") }}
        </v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_segment', { segment: item_to_edit.name })"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteSegment()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteSegment"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";
import SegmentChannels from "@/components/segments/SegmentChannels.vue";

export default {
  name: "segmentsChannelsRelatedApp",
  components: {
    SegmentChannels
  },
  data: () => ({
    app_id: "segments-channels-related-app",
    image_source: "/img/header-2.jpg", // Mandatory path
    image_height: 300, // Mandatory number
    search: "",
    open_search: false,
    selected: { name: "" },
    loading_channels: false,
    show_edit_segment: false,
    show_delete_segment: false,
    can_set_segment: null,
    segment_color_menu: false,
    isAdd: false,
    item_to_edit: {
      id: null,
      name: null,
      visible: false
    }
  }),
  computed: {
    segments() {
      let segments = this.segments_store.segments;
      if (this.open_search && this.search && this.search.length > 0) {
        segments = segments.filter(c => {
          return c.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      return segments;
    },
    available_segments() {
      let segments = JSON.parse(JSON.stringify(this.segments_store.segments));
      segments.push({ id: "new", name: this.$t("new_segment") });
      return segments;
    },
    ready() {
      return this.current_hotel && this.current_hotel.id;
    },
    selected_all_channels() {
      return { id: -1 };
    },
    ...mapState({
      segments_store: state => state.segmentschannels
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    open_hotel_selector: function() {
      this.$store.dispatch("setShowHotelList", true);
    },
    colorVisible(item) {
      if (item.visible && item.color) {
        return item.color;
      } else if (item.visible && !item.color) {
        return "pink lighten-1";
      }
      return "grey darken-1";
    },
    clearEditForm() {
      if (this.$refs.edit_segment_form) {
        this.$refs.edit_segment_form.resetValidation();
      }
    },
    showAddSegment() {
      this.clearEditForm();
      this.isAdd = true;
      this.show_edit_segment = true;
      this.item_to_edit = {
        id: null,
        name: null,
        code: null,
        hotel_id: this.current_hotel.id
      };
    },
    addSegment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_segment"),
        color: "secondary"
      });
      this.item_to_edit.visible = this.item_to_edit.visible ? true : false;
      this.item_to_edit.channel_ids = [];
      this.$store
        .dispatch("setSegment", this.item_to_edit)
        .then(() => {
          this.show_edit_segment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("segment_added"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_segment = false;
          this.item_to_edit = {};
          Utils.showError(this, "segment_not_added");
        });
    },
    showEditSegment(item) {
      if (!item) {
        return;
      }
      this.clearEditForm();
      this.isAdd = false;
      this.show_edit_segment = true;
      this.item_to_edit = JSON.parse(JSON.stringify(item));
    },
    hideEditSegment() {
      this.show_edit_segment = false;
      this.item_to_edit = {};
    },
    setSegment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_segment"),
        color: "secondary"
      });
      this.item_to_edit.visible = this.item_to_edit.visible ? true : false;
      this.$store
        .dispatch("setSegment", this.item_to_edit)
        .then(() => {
          this.show_edit_segment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("segment_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_segment = false;
          this.item_to_edit = {};
          Utils.showError(this, "segment_not_added");
        });
    },
    showDeleteSegment(segment) {
      if (!segment) {
        return;
      }
      this.show_delete_segment = true;
      this.item_to_edit = segment;
    },
    hideDeleteSegment() {
      this.show_delete_segment = false;
      this.item_to_edit = {};
    },
    deleteSegment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_segment"),
        color: "secondary"
      });
      this.$store
        .dispatch("deleteSegment", {
          hotel_id: this.current_hotel.id,
          id: this.item_to_edit.id
        })
        .then(() => {
          this.show_delete_segment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("segment_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_segment = false;
          this.item_to_edit = {};
          Utils.showError(this, "segment_not_deleted");
        });
    },
    showSearch() {
      this.open_search = true;
    },
    hideSearch() {
      this.open_search = false;
    },
    getSegments() {
      this.$emit("getSegments");
    }
  }
};
</script>

<style lang="scss" scoped>
.swatch-style {
  border-radius: 7px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.v-avatar {
  justify-content: center !important;
}
</style>
