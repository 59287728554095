<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :mobile_image_height="mobile_image_height"
  >
    <template v-slot:header_options>
      <div>
        <v-tooltip v-if="current_hotel" bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" color="white" outlined small>
              <v-icon small class="mr-1">mdi-clock-outline</v-icon>
              {{
                current_hotel.last_import_date.channelmanager | formatDatetime
              }}
            </v-chip>
          </template>
          <span>
            {{ $t("last_update") }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <channel-manager-app ref="channel-manager-app>"></channel-manager-app>
  </a-page>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import aPage from "@/components/aPage";
import ChannelManagerApp from "@/components/app/channelManagerApp.vue";

export default {
  components: { aPage, ChannelManagerApp },
  data: vm => ({
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 700, //Mandatory number,
    mobile_image_height: 1200 // Optional number
  }),
  methods: {
    ...mapActions(["showRateHighlight"])
  },
  watch: {},
  computed: {
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    ...mapState({}),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  updated() {},
  mounted() {
    if (this.current_hotel && !this.current_hotel.permissions.channelmanager) {
      this.$router.push({
        name: "dashboard",
        params: {
          hotel_slug: this.current_user.default_hotel_slug
        }
      });
    }
  }
};
</script>
