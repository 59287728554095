<template>
  <a-page :image_source="image_source" :image_height="image_height">
    <v-timeline :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item
        v-for="v in versions"
        :key="v.version"
        :color="v.type === 'major' ? 'secondary' : 'accent'"
        :small="v.type === 'minor'"
      >
        <span slot="opposite" class="font-weight-bold text-uppercase text-h3">{{
          v.version
        }}</span>
        <v-card class="elevation-2">
          <v-img
            position="top center"
            max-height="350"
            :src="'/img/versions/' + v.version + '.png'"
            gradient="to top, rgba(0, 0, 0, 0.1) 0%, transparent 42px"
          ></v-img>
          <v-card-title class="text-h5 text-uppercase mt-3">
            {{ $t(v.title) }}
          </v-card-title>
          <v-card-subtitle class=" text-uppercase">
            {{ v.date | formatDateFull }}
          </v-card-subtitle>
          <v-card-text>
            <p class="mb-3" v-html="$t(v.description)"></p>
            <ul v-if="v.items.length">
              <li v-for="i in v.items" :key="i" v-html="$t(i)"></li>
            </ul>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";

export default {
  components: { aPage },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 65 //Mandatory number,
  }),
  methods: {},
  watch: {},
  computed: {
    current_version() {
      return this.versions[0].version;
    },
    contentStyle() {
      // Mandatory
      var margin_top = this.image_height - 40;
      return "position:relative; margin-top:-" + margin_top + "px";
    },
    ...mapState({
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      versions: "versions"
    })
  },
  created() {}
};
</script>
