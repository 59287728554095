<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :mobile_image_height="mobile_image_height"
    :color="bgcolor"
  >
    <template v-slot:header_options></template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <special-rates-app ref="special-rates-app" v-else></special-rates-app>
  </a-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import aPage from "@/components/aPage";
import specialRatesApp from "@/components/app/specialRatesApp.vue";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms.vue";

export default {
  name: "rms-special-rates",
  components: {
    aHotelSelectorRms,
    specialRatesApp,
    aPage
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    mobile_image_height: 1200 // Optional number
  }),
  methods: {
    ...mapActions(["showRateHighlight"])
  },
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 700 : 200;
    },
    bgcolor() {
      return !this.isGroup ? "#f5f5f5" : "transparent";
    },
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user"
    })
  },
  mounted() {
    if (
      this.current_hotel &&
      !this.current_hotel.permissions.rms_special_rates_hotelsdot
    ) {
      this.$router.push({
        name: "dashboard",
        params: {
          hotel_slug: this.current_user.default_hotel_slug
        }
      });
    }
  }
};
</script>
