<template>
  <div id="rate-days" class="d-flex">
    <div class="item rate-labels semibold"></div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="day in days"
          :key="'rate-graph-' + day"
          class="day"
          :class="{ weekend: is_weekend(day), 'day-hover': date_hover == day }"
          @mouseover="date_hover = day"
          @mouseout="date_hover = ''"
        >
          <!-- <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                color="primary"
                size="8"
                style="position:absolute;top: -5px;"
                v-bind="attrs"
                v-on="on"
              ></v-avatar>
            </template>
            <span
              >Última actualización <br />
              25 mayo 2020, 12:00:00 am</span
            >
          </v-tooltip> -->
          <div class="day-date semibold">
            <span class="day-name grey--text text--darken-3">
              {{ getDayName(day) }} {{ getDay(day) }}
            </span>
            <br />
            <span class="grey--text">
              {{ getMonthShortName(day) }} '{{ getShortYear(day) }}
            </span>
            <!-- <v-tooltip color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  bordered
                  bottom
                  color="warning accent-4"
                  dot
                  offset-x="12"
                  offset-y="12"
                >
                  <span v-bind="attrs" v-on="on">
                    AA
                  </span>
                </v-badge>
              </template>
              <span
                >Última actualización <br />
                25 mayo 2020, 12:00:00 am</span
              >
            </v-tooltip> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "rate-day",
  components: {},
  props: ["days", "wrapper_position", "days_per_screen", "language"],
  data: vm => ({}),
  watch: {},
  methods: {
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    }
  },
  computed: {
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    ...mapGetters({})
  }
};
</script>
