<template>
  <!-- RATE DATE TO COMPARE -->
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show_rate_date_to_compare"
  >
    <v-card class="image-bg pb-0 white--text">
      <v-card-title class="headline justify-center text-capitalize">
        {{ rate_date_to_compare | formatDateFull }}
        <v-spacer></v-spacer>
        <v-btn
          style="position:absolute; top:13px; right:10px;"
          icon
          @click="show_rate_date_to_compare = false"
          ><v-icon class="white--text">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <a-base-component :subscribed_modules="subscribed_modules">
        <template v-slot:default>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            grow
          >
            <v-tab class="white--text" active-class="active-tab-bg">
              <v-icon small class="mr-1 white--text">mdi-compare</v-icon>
              <template v-if="$vuetify.breakpoint.smAndUp">{{
                $t("compare_competitor")
              }}</template>
              <template v-else>{{ $t("compare_competitor_abr") }}</template>
            </v-tab>
            <v-tab class="white--text" active-class="active-tab-bg">
              <v-icon small class="mr-1 white--text"
                >mdi-bed-king-outline</v-icon
              >
              <template v-if="$vuetify.breakpoint.smAndUp">{{
                $t("room_types")
              }}</template>
              <template v-else>{{ $t("room_types_abr") }}</template>
            </v-tab>
            <v-tab
              class="white--text"
              active-class="active-tab-bg"
              v-if="current_user.permissions.rate_channelmanager_tab"
            >
              <v-icon small class="mr-1 white--text">mdi-sitemap</v-icon>
              <template v-if="$vuetify.breakpoint.smAndUp">{{
                $t("channel_manager")
              }}</template>
              <template v-else>{{ $t("channel_manager") }}</template>
            </v-tab>
          </v-tabs>
          <v-card-text style="height: 90%;" class="grey lighten-5 py-8">
            <div v-show="tab === 0">
              <v-card-title>
                {{ $t("comparison") }}
                <v-spacer></v-spacer>
                <v-select
                  v-model="compared_competitors"
                  :items="competitorOptions"
                  item-text="name"
                  item-value="id"
                  item-color="color"
                  :label="$t('compared_competitors_select')"
                  multiple
                  chips
                  deletable-chips
                  small-chips
                  persistent-hint
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="competitorsToggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            compared_competitors.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("select_all")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-card-title>
              <v-data-table
                v-if="daily_rates.report"
                :headers="rateDayDetailHeaders"
                :items="comparisonItems"
                item-key="id"
                class="elevation-2"
                hidden-default-footer
              >
                <template v-slot:item.label="{ item }">
                  <span
                    class="caption font-weight-medium"
                    :class="{ 'text-uppercase': item.self == 1 }"
                  >
                    <v-avatar
                      v-if="
                        item.ranking && daily_rates.increments && item.self != 2
                      "
                      class="mb-1 mr-1"
                      :color="
                        rankingColor(
                          daily_rates.increments[
                            'competitor::' + item.competitor_id
                          ] &&
                            daily_rates.increments[
                              'competitor::' + item.competitor_id
                            ][rate_date_to_compare]
                            ? daily_rates.increments[
                                'competitor::' + item.competitor_id
                              ][rate_date_to_compare][0].ranking
                            : null
                        )
                      "
                      size="18"
                    >
                      <span class="white--text">{{ item.ranking }}</span>
                    </v-avatar>
                    <v-avatar
                      v-if="item.self == 2"
                      class="mt-n1 mr-1"
                      size="16"
                    >
                      <v-img src="/img/otas/mirai.png"></v-img>
                    </v-avatar>
                    {{ item.label }}
                    <v-btn
                      icon
                      x-small
                      color="indigo"
                      :href="bookingUrl(item, rate_date_to_compare)"
                      target="_blank"
                      class="ml-1"
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </span>
                </template>
                <template
                  v-slot:item.min_price="{ item }"
                  v-if="daily_rates.data_to_compare"
                >
                  <a-kpi
                    class="subtitle-2"
                    :kpi="'min_price'"
                    :value="item.min_price"
                    :increment="true"
                    :vs_value="
                      daily_rates.data_to_compare[
                        'competitor::' + item.competitor_id
                      ] &&
                      daily_rates.data_to_compare[
                        'competitor::' + item.competitor_id
                      ][rate_date_to_compare]
                        ? daily_rates.data_to_compare[
                            'competitor::' + item.competitor_id
                          ][rate_date_to_compare][0].min_price
                        : null
                    "
                    :increment_value="
                      daily_rates.increments[
                        'competitor::' + item.competitor_id
                      ] &&
                      daily_rates.increments[
                        'competitor::' + item.competitor_id
                      ][rate_date_to_compare]
                        ? daily_rates.increments[
                            'competitor::' + item.competitor_id
                          ][rate_date_to_compare][0].min_price
                        : null
                    "
                    :simple="false"
                  >
                    <template v-slot:prepend>
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="item.is_mobile_offer"
                            color="yellow darken-2"
                            x-small
                            style="margin-bottom: 1px;"
                            class="mr-2"
                            >mdi-cellphone</v-icon
                          >
                        </template>
                        <span
                          class="text-center black--text"
                          style="font-size:13px;line-height:11px;"
                          v-html="$t('is_mobile_offer')"
                        ></span>
                      </v-tooltip>
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <span
                            v-on="on"
                            class="blue--text text--darken-4 mt-n1 mr-2 "
                            v-if="item.is_genius_offer"
                            style="font-size:13px;font-weight:600;"
                            >.g</span
                          >
                        </template>
                        <span
                          class="text-center black--text"
                          style="font-size:13px;line-height:11px;"
                          v-html="$t('is_genius_offer')"
                        ></span>
                      </v-tooltip>
                    </template>
                  </a-kpi>
                </template>
                <template v-slot:item.regime="{ item }">
                  <v-icon
                    color="grey darken-2"
                    small
                    v-if="item.regime === 'alo'"
                    >mdi-bed-king-outline</v-icon
                  >
                  <v-icon
                    color="grey darken-2"
                    small
                    v-if="item.regime === 'ad'"
                    >mdi-coffee-outline</v-icon
                  >
                  <v-icon
                    color="grey darken-2"
                    small
                    v-if="item.regime === 'mp'"
                    >mdi-silverware-variant</v-icon
                  >
                  <v-icon
                    color="grey darken-2"
                    small
                    v-if="item.regime === 'pc'"
                    >mdi-silverware</v-icon
                  >
                  <span class="caption ml-1">{{ $t(item.regime) }}</span>
                </template>
                <template v-slot:item.room_type_name="{ item }">
                  <span class="caption font-weight-medium">{{
                    item.room_type_name
                  }}</span>
                </template>
                <template v-slot:item.refund_policy="{ item }">
                  <v-chip
                    v-if="item.free_cancelation"
                    color="green"
                    outlined
                    small
                  >
                    <v-icon left small
                      >mdi-numeric-{{
                        item.free_cancelation_days
                      }}-circle</v-icon
                    >
                    {{ $t("free_cancelation") }}
                  </v-chip>
                  <v-chip
                    v-if="item.no_refundable"
                    color="red darken-3"
                    outlined
                    small
                  >
                    {{ $t("no_refundable") }}
                  </v-chip>
                  <v-chip
                    v-if="
                      item.min_price &&
                        !item.no_refundable &&
                        !item.free_cancelation
                    "
                    color="yellow darken-3"
                    outlined
                    small
                  >
                    {{ $t("partial_refundable") }}
                  </v-chip>
                </template>
                <template v-slot:item.capacity="{ item }">
                  <v-icon
                    class="ml-n1"
                    v-for="i in item.room_type_adults"
                    :key="
                      'type_adults_' +
                        i +
                        '_' +
                        item.competitor_id +
                        '_' +
                        rate_date_to_compare
                    "
                    color="grey darken-2"
                    small
                    >mdi-account</v-icon
                  >
                  <span class="pa-1"></span>
                  <v-icon
                    class="ml-n1 mt-1"
                    v-for="i in item.room_type_childs"
                    :key="
                      'type_childs_' +
                        i +
                        '_' +
                        item.competitor_id +
                        '_' +
                        rate_date_to_compare
                    "
                    color="grey darken-2"
                    x-small
                    >mdi-account</v-icon
                  >
                </template>
              </v-data-table>
            </div>
            <div v-show="tab === 1">
              <v-card-title>
                <v-spacer></v-spacer>
                <v-select
                  v-model="compared_competitors"
                  :items="competitorOptions"
                  item-text="name"
                  item-value="id"
                  item-color="color"
                  :label="$t('compared_competitors_select')"
                  multiple
                  chips
                  deletable-chips
                  small-chips
                  persistent-hint
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="competitorsToggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            compared_competitors.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("select_all")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-card-title>
              <div
                v-for="competitor in comparisonItems"
                :key="competitor.competitor_id"
              >
                <v-card-title>
                  {{ competitor.label }}
                </v-card-title>
                <v-data-table
                  v-if="day_rates.report"
                  :headers="rateRoomTypeHeaders"
                  :items="
                    day_rates.report['competitor::' + competitor.competitor_id]
                  "
                  :items-per-page="5"
                  item-key="id"
                  class="elevation-2"
                  :sort-by.sync="sortBy"
                  :custom-sort="customSort"
                  must-sort
                >
                  <template v-slot:item.price="{ item }">
                    <a-kpi
                      v-if="daily_rates.data_to_compare"
                      class="subtitle-2"
                      :kpi="'price'"
                      :value="item.price"
                      :increment="true"
                      :vs_value="
                        daily_rates.data_to_compare[
                          'competitor::' + item.competitor_id
                        ] &&
                        daily_rates.data_to_compare[
                          'competitor::' + item.competitor_id
                        ][rate_date_to_compare]
                          ? daily_rates.data_to_compare[
                              'competitor::' + item.competitor_id
                            ][rate_date_to_compare][0].price
                          : null
                      "
                      :increment_value="
                        daily_rates.increments[
                          'competitor::' + item.competitor_id
                        ] &&
                        daily_rates.increments[
                          'competitor::' + item.competitor_id
                        ][rate_date_to_compare]
                          ? daily_rates.increments[
                              'competitor::' + item.competitor_id
                            ][rate_date_to_compare][0].price
                          : null
                      "
                      :simple="false"
                    >
                      <template v-slot:prepend>
                        <v-tooltip bottom content-class="a-rate-tooltip">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              v-show="item.is_mobile_offer"
                              color="yellow darken-2"
                              x-small
                              style="margin-bottom: 1px;"
                              class="mr-2"
                              >mdi-cellphone</v-icon
                            >
                          </template>
                          <span
                            class="text-center black--text"
                            style="font-size:13px;line-height:11px;"
                            v-html="$t('is_mobile_offer')"
                          ></span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="a-rate-tooltip">
                          <template v-slot:activator="{ on }">
                            <span
                              v-on="on"
                              class="blue--text text--darken-4 mt-n1 mr-2 "
                              v-show="item.is_genius_offer"
                              style="font-size:13px;font-weight:600;"
                              >.g</span
                            >
                          </template>
                          <span
                            class="text-center black--text"
                            style="font-size:13px;line-height:11px;"
                            v-html="$t('is_genius_offer')"
                          ></span>
                        </v-tooltip>
                      </template>
                    </a-kpi>
                  </template>
                  <template v-slot:item.room_type_name="{ item }">
                    <span class="caption font-weight-medium text-uppercase">{{
                      item.room_type_name
                    }}</span>
                  </template>
                  <template v-slot:item.refund_policy="{ item }">
                    <v-chip
                      v-if="item.free_cancelation"
                      color="green"
                      outlined
                      small
                    >
                      <v-icon left small
                        >mdi-numeric-{{
                          item.free_cancelation_days
                        }}-circle</v-icon
                      >
                      {{ $t("free_cancelation") }}
                    </v-chip>
                    <v-chip
                      v-if="item.no_refundable"
                      color="red darken-3"
                      outlined
                      small
                    >
                      {{ $t("no_refundable") }}
                    </v-chip>
                    <v-chip
                      v-if="
                        item.price &&
                          !item.no_refundable &&
                          !item.free_cancelation
                      "
                      color="yellow darken-3"
                      outlined
                      small
                    >
                      {{ $t("partial_refundable") }}
                    </v-chip>
                  </template>
                  <template v-slot:item.capacity="{ item }">
                    <v-icon
                      class="ml-n1"
                      v-for="i in item.room_type_adults"
                      :key="
                        'type_adults_' +
                          i +
                          '_' +
                          item.competitor_id +
                          '_' +
                          rate_date_to_compare
                      "
                      color="grey darken-2"
                      small
                      >mdi-account</v-icon
                    >
                    <span class="pa-1"></span>
                    <v-icon
                      class="ml-n1"
                      v-for="i in item.room_type_childs"
                      :key="
                        'type_childs_' +
                          i +
                          '_' +
                          item.competitor_id +
                          '_' +
                          rate_date_to_compare
                      "
                      color="grey darken-2"
                      x-small
                      >mdi-account</v-icon
                    >
                  </template>
                  <template v-slot:item.regime="{ item }">
                    <v-icon
                      color="grey darken-2"
                      small
                      v-if="item.regime === 'alo'"
                      >mdi-bed-king-outline</v-icon
                    >
                    <v-icon
                      color="grey darken-2"
                      small
                      v-if="item.regime === 'ad'"
                      >mdi-coffee-outline</v-icon
                    >
                    <v-icon
                      color="grey darken-2"
                      small
                      v-if="item.regime === 'mp'"
                      >mdi-silverware-variant</v-icon
                    >
                    <v-icon
                      color="grey darken-2"
                      small
                      v-if="item.regime === 'pc'"
                      >mdi-silverware</v-icon
                    >
                    <span class="caption ml-1">{{ $t(item.regime) }}</span>
                  </template>
                </v-data-table>
              </div>
            </div>
            <div v-show="tab === 2" v-if="channel_manager">
              <a-chm-edit-inline-rates :date_edit="rate_date_to_compare" />
            </div>
          </v-card-text>
        </template>
        <template v-slot:loading>
          <v-card class="mt-2" loading>
            <v-card-text>
              <v-skeleton-loader
                type="table-thead"
                class="mx-auto"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="table-tbody"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </template>
      </a-base-component>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";
import aBaseComponent from "@/components/aBaseComponent";
import AChmEditInlineRates from "@/components/channel-manager/aChmEditInlineRates.vue";
var numeral = require("numeral");

export default {
  name: "rate-day-detail",
  components: {
    aKpi,
    aBaseComponent,
    AChmEditInlineRates
    // aRateIncrement
  },
  props: [],
  data: vm => ({
    subscribed_modules: [
      "rate_day_rates",
      "parity_webs",
      "channelmanager_day_data"
    ],
    sortBy: "price",
    kpis: [
      "min_price",
      "minimum_nights",
      "regime",
      "refund_policy",
      "room_type_name",
      "capacity"
    ],
    type_kpis: [
      "room_type_name",
      "capacity",
      "price",
      "minimum_nights",
      "regime",
      "refund_policy",
      "available_rooms"
    ],
    tab: 0,
    loading_rates: false
  }),
  watch: {},
  methods: {
    competitorsToggle() {
      this.$nextTick(() => {
        if (this.allCompetitors) {
          this.compared_competitors = [];
        } else {
          this.compared_competitors = this.competitors
            .slice()
            .map(competitor => competitor.id);
        }
      });
    },
    rankingColor(v) {
      return v > 0 ? "red darken-2" : v < 0 ? "green" : "grey darken-2";
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "capacity") {
          if (!isDesc[0]) {
            return a["room_type_adults"] < b["room_type_adults"] ? -1 : 1;
          } else {
            return b["room_type_adults"] < a["room_type_adults"] ? -1 : 1;
          }
        } else if (index[0] === "refund_policy") {
          if (!isDesc[0]) {
            return a["free_cancelation"] < b["free_cancelation"] ? -1 : 1;
          } else {
            return b["free_cancelation"] < a["free_cancelation"] ? -1 : 1;
          }
        } else {
          if (!isDesc[0]) {
            return a[index[0]] < b[index[0]] ? -1 : 1;
          } else {
            return b[index[0]] < a[index[0]] ? -1 : 1;
          }
        }
      });
      return items;
    },
    competitorData(competitor) {
      var item = {};
      if (
        this.daily_rates &&
        this.daily_rates.report &&
        this.daily_rates.report["competitor::" + competitor.id] &&
        this.daily_rates.report["competitor::" + competitor.id][
          this.rate_date_to_compare
        ]
      ) {
        for (const kpi of Object.keys(
          this.daily_rates.report["competitor::" + competitor.id][
            this.rate_date_to_compare
          ][0]
        )) {
          item[kpi] = this.daily_rates.report["competitor::" + competitor.id][
            this.rate_date_to_compare
          ][0][kpi];
        }
      }
      item["label"] = item["self"] == 2 ? this.$t("your_web") : competitor.name;
      return item;
    },
    bookingUrl(item, checkin) {
      const competitor = this.getCompetitorById(item.competitor_id);
      return (
        "https://www.booking.com/hotel/" +
        competitor.iso +
        "/" +
        competitor.destination_txt +
        ".es.html?checkin=" +
        Vue.moment(checkin).format("YYYY-MM-DD") +
        "&checkout=" +
        Vue.moment(checkin)
          .add(item.minimum_nights, "days")
          .format("YYYY-MM-DD") +
        "&dest_id=" +
        competitor.destination_id +
        "&dest_type=city&group_adults=" +
        item.capacity +
        "&group_children=0&hapos=1&hpos=1&no_rooms=1&soh=1&sr_order=popularity;type=total&;changed_currency=1;selected_currency=" +
        competitor.currency +
        ";top_currency=1"
      );
    }
  },
  computed: {
    allCompetitors() {
      return this.compared_competitors.length === this.competitors.length;
    },
    someCompetitors() {
      return this.compared_competitors.length > 0 && !this.allCompetitors;
    },
    competitorOptions() {
      return this.competitors
        .filter(
          competitor =>
            this.daily_rates &&
            this.daily_rates.report &&
            this.daily_rates.report["competitor::" + competitor.id] &&
            this.daily_rates.report["competitor::" + competitor.id][
              this.rate_date_to_compare
            ]
        )
        .map(competitor => {
          return {
            id: competitor.id,
            color: competitor.color,
            name:
              competitor.name +
              " (" +
              this.l.money_pre_symbol +
              numeral(
                this.daily_rates.report["competitor::" + competitor.id][
                  this.rate_date_to_compare
                ][0].min_price
              ).format(this.l.number_format) +
              this.l.money_post_symbol +
              ")"
          };
        });
    },
    icon() {
      if (this.allCompetitors) return "mdi-close-box";
      if (this.someCompetitors) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    comparisonItems() {
      var items = [];
      // Insert hotel
      var main_item = {};
      if (this.hotel_competitor && this.day_rates.report) {
        main_item["label"] = this.hotel_competitor.name;
        if (
          this.day_rates &&
          this.day_rates.report &&
          this.daily_rates.report["competitor::" + this.hotel_competitor.id] &&
          this.daily_rates.report["competitor::" + this.hotel_competitor.id][
            this.rate_date_to_compare
          ]
        ) {
          for (const kpi of Object.keys(
            this.daily_rates.report["competitor::" + this.hotel_competitor.id][
              this.rate_date_to_compare
            ][0]
          )) {
            main_item[kpi] = this.daily_rates.report[
              "competitor::" + this.hotel_competitor.id
            ][this.rate_date_to_compare][0][kpi];
          }
        }
        items.push(main_item);
      }

      // Insert hotel competitors
      for (const competitor of this.competitors) {
        if (this.compared_competitors.indexOf(competitor.id) >= 0) {
          items.push(this.competitorData(competitor));
        }
      }
      for (const web of this.webs) {
        if (this.compared_competitors.indexOf(web.id) >= 0) {
          items.push(this.competitorData(web));
        }
      }
      return items;
    },
    rateDayDetailHeaders() {
      var headers = [
        {
          text: "",
          value: "label",
          align: "start",
          sortable: false
        }
      ];
      for (const kpi of this.kpis) {
        headers.push({
          text: this.$t(kpi),
          value: kpi,
          align: "center",
          sortable: false
        });
      }

      return headers;
    },
    rateRoomTypeHeaders() {
      var headers = [];
      for (const kpi of this.type_kpis) {
        headers.push({
          text: this.$t(kpi),
          value: kpi,
          align: kpi === "room_type_name" ? "start" : "center",
          sortable: true
        });
      }

      return headers;
    },
    channel_manager() {
      return (
        this.current_hotel.permissions.rate_channelmanager_tab &&
        this.current_user.permissions.rate_channelmanager_tab
      );
    },
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    compared_competitors: {
      get() {
        return this.$store.state.rate.compared_competitors;
      },
      set(value) {
        this.$store.dispatch("setComparedCompetitors", value);
      }
    },
    ...mapState({
      rate_date_to_compare: state => state.rate_date_to_compare,
      daily_rates: state => state.rate.daily_rates,
      day_rates: state => state.rate.day_rates,
      webs: state => state.parity.webs
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      competitors: "getOnlyCompetitors",
      getCompetitorById: "getCompetitorById",
      hotel_competitor: "getHotelFromCompetitors",
      l: "current_hotel_language"
    })
  }
};
</script>
<style lang="scss" scoped>
.active-tab-bg {
  background-color: transparent;
  font-weight: bold;
  color: white;
}
.image-bg {
  background-image: url("/img/header.jpg");
}
*::v-deep .v-tabs-slider {
  background-color: white;
  color: white;
}
</style>
