<template>
  <v-dialog
    v-model="show_upload_daily_dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="750px"
  >
    <form enctype="multipart/form-data" novalidate>
      <v-card>
        <v-card-title class="headline py-6 justify-space-between">
          <h5 class="modal-title" id="budget-upload-dialog-title">
            {{ $t("import_budget_for_year", { year: budget_year }) }}
          </h5>
          <v-btn
            class=""
            icon
            @click="show_upload_daily_dialog = false"
            :aria-label="$t('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="mb-0">
                {{
                  $t("select_the_file_with_data_from_year", {
                    year: budget_year
                  })
                }}.
                {{ $t("download_an_example_if_not_know_format") }}
              </p>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                outlined
                color="info"
                @click="downloadBudgetDailyTemplate"
              >
                <v-icon class="mr-2">mdi-cloud-download-outline</v-icon>
                {{ $t("download_template_example") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                accept=".xlsx"
                :label="$t('budget_excel_file')"
                rounded
                outlined
                depressed
                show-size
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center py-5">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="px-5"
            @click="startImport"
          >
            {{ $t("start_import") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="px-5"
            @click="show_upload_daily_dialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      file: null
    };
  },
  computed: {
    ...mapState({
      budget: state => state.budget,
      budget_year: state => state.budget.budget_year
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    }),
    show_upload_daily_dialog: {
      get() {
        return this.budget.show_upload_daily_dialog;
      },
      set(value) {
        this.$store.dispatch("showUploadDailyDialog", value);
      }
    }
  },
  methods: {
    startImport() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("hotel_id", this.current_hotel.id);
      formData.append("year", this.budget_year);
      formData.append("type", "daily");

      this.$store.dispatch("importBudget", {
        formData: formData,
        hotel_id: this.current_hotel.id,
        year: this.budget_year,
        type: "daily"
      });
      this.show_upload_daily_dialog = false;
    },
    downloadBudgetDailyTemplate() {
      this.$store.dispatch("downloadBudgetTemplate", {
        hotel_id: this.current_hotel.id,
        year: this.budget_year,
        hotel_name: this.current_hotel.name,
        type: "daily",
        type_name: this.$t("daily")
      });
    }
  }
};
</script>
