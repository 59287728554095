<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <v-btn icon @click="show_import_columns = true" class="mt-7">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
      <!-- SHOW IMPORT COLUMNS -->
      <v-dialog
        v-model="show_import_columns"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="600"
      >
        <v-card>
          <v-card-title center class="headline justify-center py-6">
            {{ $t("import_columns") }}
          </v-card-title>
          <v-card-text class="text-center justify-center">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              disable-pagination
              dense
            >
              <template v-slot:[`item.index`]="{ item }">
                <b class="mr-1">{{ item.index }}</b>
              </template>
              <template v-slot:[`item.kpi`]="{ item }">
                <v-edit-dialog :return-value.sync="item.code" @save="save">
                  <template v-slot:input>
                    <small class="mt-5">{{ $t("column_code") }}</small>
                    <v-text-field
                      v-model="item.code"
                      outlined
                      dense
                      rounded
                      single-line
                      label="Edit"
                      class="mt-5"
                    ></v-text-field>
                  </template>
                  <div v-if="item.type">
                    <v-chip color="secondary" outlined small
                      >{{ $t(item.type) }}: <b>{{ item.value }}</b>
                    </v-chip>
                    {{ item.kpi }}
                  </div>
                  <span v-else-if="item.kpi !== ''">{{ item.kpi }}</span>
                  <span v-else class="grey--text text-lighten-1 font-italic"
                    >-- {{ $t("not_used") }} --</span
                  >
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="ps-5 pb-5 justify-center">
            <v-btn
              color=""
              text
              rounded
              depressed
              class="pl-5 pr-5"
              @click="show_import_columns = false"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:loading>
      <v-skeleton-loader type="avatar" class="mt-5"></v-skeleton-loader>
    </template>
  </a-base-component>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils";
import Vue from "vue";
import ABaseComponent from "@/components/aBaseComponent.vue";

export default {
  name: "import-columns-config",
  components: { ABaseComponent },
  data: () => ({
    subscribed_modules: ["dataimport_revenue_daily_columns"],
    show_import_columns: false
  }),
  computed: {
    headers: function() {
      return [
        {
          text: this.$t("column"),
          value: "index",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("kpi"),
          value: "kpi",
          align: "left",
          sortable: false
        }
      ];
    },
    items: function() {
      return this.daily_columns.map((column, index) => {
        if (column.startsWith("dimension::")) {
          let parts = column.split("::");
          return {
            index: index + 1,
            kpi: this.$t(parts[3]),
            type: parts[1],
            value: parts[2],
            code: column,
            original_code: column
          };
        }
        return {
          index: index + 1,
          kpi: this.$t(column),
          code: column,
          original_code: column
        };
      });
    },
    ...mapState({
      daily_columns: state => state.dataimport.revenue_daily_columns
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      get_kpi: "get_kpi"
    })
  },
  methods: {
    save() {
      for (let item of this.items) {
        if (item.code !== item.original_code) {
          this.daily_columns.splice(item.index - 1, 1, item.code);
          this.$store.dispatch("updateRevenueDailyColumn", {
            hotel_id: this.current_hotel.id,
            index: item.index - 1,
            code: item.code
          });
        }
      }
      Utils.showSuccess(this, "columns_updated_ok", "columns_updated");
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped></style>
