<template>
  <v-dialog
    v-model="isOpenBulkForm"
    :fullscreen="$vuetify.breakpoint.mobile"
    :hide-overlay="$vuetify.breakpoint.mobile"
    persistent
    scrollable
    max-width="500px"
  >
    <a-offers-bulk-edit-form v-if="isOpenBulkForm"></a-offers-bulk-edit-form>
  </v-dialog>
</template>

<script>
import aOffersBulkEditForm from "@/components/offers/aOffersBulkEditForm.vue";

export default {
  name: "a-offers-bulk-edit",
  components: {
    aOffersBulkEditForm
  },
  props: {
    rateId: {
      type: Number,
      default: null
    }
  },
  computed: {
    isOpenBulkForm() {
      return this.$store.state.offers.bulk_form_open;
    }
  }
};
</script>
