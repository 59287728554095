<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <a-header-chart
        :kpi="'new_reviews'"
        :label_text="$t('reviews')"
        ref="a-reputation-header-graph"
        class="mb-0 mx-n4"
        style="width:auto !important;"
      ></a-header-chart>
    </template>
    <template v-slot:loading>
      <div :style="`height: ${height}`"></div>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import aBaseComponent from "@/components/aBaseComponent";
import aHeaderChart from "@/components/chart/aHeaderChart";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-reputation-header-graph",
  components: {
    aBaseComponent,
    aHeaderChart
  },
  props: {
    height: {
      type: [String, Number],
      default: "150px"
    }
  },
  data: vm => ({
    subscribed_modules: ["bi_report", "bi_daily_report"]
  }),
  methods: {},
  computed: {
    ...mapState({
      bi_daily_report: state => state.bi.daily_report,
      bi_report: state => state.bi.report
    }),
    ...mapGetters({})
  }
};
</script>
