<template>
  <a-page
    :id="app_id"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-select
        v-model="calendar_year"
        :items="available_years.reverse()"
        mandatory
        rounded
        outlined
        dense
        class="mt-8"
        style="max-width: 120px;"
      ></v-select>
    </template>
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-toolbar color="white" light flat>
            <v-toolbar-title>{{ $t("calendars") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showSearch" v-if="!open_search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon @click="hideSearch" v-else>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn icon @click="showAddCalendar" color="secondary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-text-field
            class="px-4"
            v-model="search"
            v-if="open_search"
            rounded
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('search_calendar')"
          >
          </v-text-field>
          <v-list dense>
            <v-list-item-group
              v-model="selected"
              active-class="primary--text"
              mandatory
              v-if="calendars.length > 0"
            >
              <template v-for="item in calendars">
                <v-list-item
                  :key="item.name"
                  style="max-height:60px;"
                  :value="item"
                  :ripple="false"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar
                      color="pink lighten-1"
                      class="white--text font-weight-bold"
                    >
                      {{ item.events_count }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                        <v-icon x-small v-if="!item.editable" color="grey"
                          >mdi-lock-outline</v-icon
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text">
                        {{ item.subscribed_hotels.length }} hoteles
                      </v-list-item-subtitle>

                      <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey darken-1"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense flat>
                          <v-list-item-group>
                            <v-list-item
                              @click.stop.prevent="showEditCalendar(item)"
                            >
                              <v-list-item-title class="d-flex">
                                <v-icon small class="mr-2"
                                  >mdi-pencil-outline</v-icon
                                >
                                {{ $t("modify") }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="item.events_count === 0"
                              @click.stop.prevent="showDeleteCalendar(item)"
                            >
                              <v-list-item-title
                                class="d-flex red--text text--darken-1"
                              >
                                <v-icon small class="mr-2" color="red darken-1"
                                  >mdi-trash-can-outline</v-icon
                                >
                                {{ $t("delete") }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                      <!-- <v-list-item-action-text v-text="item.available_years.join(', ')"></v-list-item-action-text> -->
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <!-- <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider> -->
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card :loading="loading_events">
          <v-toolbar color="white" light flat>
            <v-toolbar-title
              >{{ $t("events_of") }} <b>{{ selected.name }}</b>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tabs
              color="deep-purple accent-4"
              right
              v-model="tab"
              class="calendar_events_tabs"
            >
              <v-tab key="events">{{ $t("events") }}</v-tab>
              <v-tab key="hotels">{{ $t("subscribed_hotels") }}</v-tab>
            </v-tabs>
          </v-toolbar>
          <!-- <v-divider></v-divider> -->
          <v-tabs-items v-model="tab">
            <v-tab-item key="events">
              <v-card-text>
                <calendar-events
                  :events="calendar_store.calendar_events"
                  :calendar_id="selected.id"
                  :available_calendars="available_calendars"
                  @getCalendars="getCalendars"
                ></calendar-events>
              </v-card-text>
            </v-tab-item>
            <v-tab-item key="hotels">
              <v-card-text>
                <calendar-subscribed-hotels
                  :calendar="selected"
                  @getCalendars="getCalendars"
                ></calendar-subscribed-hotels>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="show_edit_calendar"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title
          center
          class="headline justify-center py-6"
          v-if="item_to_edit.id"
          >{{ $t("edit_calendar") }}</v-card-title
        >
        <v-card-title center class="headline justify-center py-6" v-else>{{
          $t("new_calendar")
        }}</v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-form ref="edit_calendar_form" v-model="can_set_calendar">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="item_to_edit.name"
                  maxlength="50"
                  counter
                  :label="$t('name')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('name')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="10" class="py-0 text-left">
                <label>{{ $t("is_editable") }}</label>
                <v-switch v-model="item_to_edit.editable"></v-switch>
              </v-col>
              <v-col cols="2" class="py-0"> </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-5 justify-center">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setCalendar()"
            :disabled="!can_set_calendar"
            v-if="item_to_edit.id"
          >
            {{ $t("set_calendar") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addCalendar()"
            :disabled="!can_set_calendar"
          >
            {{ $t("add_calendar") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditCalendar"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE CAlENDAR -->
    <v-dialog
      v-model="show_delete_calendar"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_calendar', { calendar: item_to_edit.name })"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteCalendar()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteCalendar"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE CALENDAR -->
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aPage from "@/components/aPage";
import CalendarSubscribedHotels from "../../components/calendar/CalendarSubscribedHotels.vue";
import CalendarEvents from "../../components/calendar/CalendarEvents.vue";

export default {
  name: "Calendar",
  components: {
    aPage,
    CalendarEvents,
    CalendarSubscribedHotels
  },
  data: () => ({
    app_id: "calendar-app",
    image_source: "/img/header-2.jpg", // Mandatory path
    image_height: 140, // Mandatory number
    search: "",
    open_search: false,
    open_searc_events: false,
    selected: { name: "" },
    loading_events: false,
    tab: null,
    show_edit_calendar: false,
    show_delete_calendar: false,
    can_set_calendar: null,
    item_to_edit: {
      id: null,
      name: null,
      editable: true
    }
  }),
  computed: {
    available_calendars() {
      let calendars = JSON.parse(JSON.stringify(this.calendar_store.calendars));
      calendars.push({ id: "new", name: this.$t("new_calendar") });
      return calendars;
    },
    calendars() {
      let calendars = this.calendar_store.calendars;
      if (this.open_search && this.search && this.search.length > 0) {
        calendars = calendars.filter(c => {
          return c.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      return calendars;
    },
    weekday_name() {
      return this.weekday_to_set >= 0
        ? this.getWeekDayName(this.weekday_to_set)
        : "";
    },
    ready() {
      return this.calendar_year && this.current_hotel && this.current_hotel.id;
    },
    calendar_year: {
      get() {
        return this.calendar_store.calendar_year;
      },
      set(value) {
        this.$store.dispatch("setCalendarYear", value);
      }
    },
    visible_calendars: {
      get() {
        return this.calendar_store.visible_calendars;
      },
      set(value) {
        this.$store.dispatch("setVisibleCalendars", value);
      }
    },
    total_hotel_events() {
      return this.calendar_store.hotel_events
        ? this.calendar_store.hotel_events.length
        : 0;
    },
    total_hotel_day_comments() {
      return this.calendar_store.hotel_comments
        ? this.calendar_store.hotel_comments
            .map(c => c.date)
            .filter((c, index, self) => {
              return self.indexOf(c) === index;
            }).length
        : 0;
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      available_years: "getHotelAvailableYears",
      yearly_calendar: "getYearlyCalendar",
      opened_days: "opened_days",
      getHotelCalendars: "getHotelCalendars"
    })
  },
  methods: {
    clearEditForm() {
      if (this.$refs.edit_calendar_form) {
        this.$refs.edit_calendar_form.resetValidation();
      }
    },
    showAddCalendar() {
      this.clearEditForm();
      this.show_edit_calendar = true;
      this.item_to_edit = {
        name: null
      };
    },
    showEditCalendar(item) {
      if (!item) {
        return;
      }
      this.clearEditForm();
      this.show_edit_calendar = true;
      this.item_to_edit = JSON.parse(JSON.stringify(item));
    },
    hideEditCalendar() {
      this.show_edit_calendar = false;
      this.item_to_edit = {};
    },
    setCalendar: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_calendar"),
        color: "secondary"
      });

      this.item_to_edit.editable = this.item_to_edit.editable ? true : false;
      this.$store
        .dispatch("setCalendar", this.item_to_edit)
        .then(() => {
          this.show_edit_calendar = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("calendar_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_calendar = false;
          this.item_to_edit = {};
          Utils.showError(this, "calendar_not_modified");
        });
    },
    addCalendar: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_calendar"),
        color: "secondary"
      });
      this.$store
        .dispatch("setCalendar", this.item_to_edit)
        .then(() => {
          this.show_edit_calendar = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("calendar_added"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_calendar = false;
          this.item_to_edit = {};
          Utils.showError(this, "calendar_not_added");
        });
    },
    showDeleteCalendar(calendar) {
      if (!calendar) {
        return;
      }
      this.show_delete_calendar = true;
      this.item_to_edit = calendar;
    },
    hideDeleteCalendar() {
      this.show_delete_calendar = false;
      this.item_to_edit = {};
    },
    deleteCalendar: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_calendar"),
        color: "secondary"
      });

      this.$store
        .dispatch("deleteCalendar", { id: this.item_to_edit.id })
        .then(() => {
          this.show_delete_calendar = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("calendar_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_calendar = false;
          this.item_to_edit = {};
          Utils.showError(this, "calendar_not_deleted");
        });
    },
    showSearch() {
      this.open_search = true;
    },
    hideSearch() {
      this.open_search = false;
    },
    getCalendarName(item) {
      let char = this.visible_calendars.length > 1 ? 20 : 30;
      return item.name.length > char
        ? item.name.substring(0, char) + "..."
        : item.name;
    },
    hasComments(date) {
      return this.calendar_store && this.calendar_store.hotel_comments
        ? this.calendar_store.hotel_comments.filter(c => c.date === date).length
        : 0;
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayName(date) {
      return Vue.moment(date).format("dddd,D MMMM");
    },
    getCalendars() {
      this.$store
        .dispatch("getCalendars", {
          // hotel_id: this.current_hotel.id
        })
        .then(() => {
          this.visible_calendars = this.getHotelCalendars.map(c => c.id);
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });

      this.getCalendarEvents();
    },
    getHotelEvents() {
      this.$store
        .dispatch("getHotelEvents", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelComments() {
      this.$store
        .dispatch("getHotelComments", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setOpeningDate(date) {
      this.$store
        .dispatch("setOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          opening_date: date,
          closing_date: this.calendar_store.closing_date
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setClosingDate(date) {
      this.$store
        .dispatch("setOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          opening_date: this.calendar_store.opening_date,
          closing_date: date
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getOpeningPeriod() {
      this.$store
        .dispatch("getOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          year: this.calendar_year
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getCalendarEvents() {
      if (this.selected.id == null) {
        return;
      }
      this.loading_events = true;
      this.$store
        .dispatch("getCalendarEvents", {
          year: this.calendar_year,
          calendar_id: this.selected.id
        })
        .then(() => {
          this.loading_events = false;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getHotelYearData() {
      this.getOpeningPeriod();
      this.getHotelEvents();
      this.getHotelComments();
      this.getCalendars();
    }
  },
  watch: {
    ready(v) {
      if (v) {
        this.getHotelYearData();
      }
    },
    calendar_year() {
      if (this.ready) {
        this.getHotelYearData();
        this.getCalendarEvents();
      }
    },
    selected(calendar) {
      if (calendar) {
        this.getCalendarEvents();
      }
    }
  },
  mounted() {
    this.calendar_year = Vue.moment()
      .year()
      .toString();
  }
};
</script>

<style scoped>
.calendar_year {
  text-align: center;
}
.calendar_month {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px;
  min-height: 350px;
}
.calendar_month_name {
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto;
  color: #444;
  text-transform: lowercase;
}
.calendar_week_number {
  width: 30px;
  display: flex;
  align-items: center;
  background: #e5e5e5;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: default;
}
.calendar_week_number .calendar_day_content_number {
  color: #ffffff;
  line-height: 33px;
  cursor: default;
}
.calendar_week_header {
  display: flex;
  margin: 20px 0 10px 0;
}
.calendar_weekday_header:first-of-type {
  width: 30px;
  flex: none;
}
.calendar_weekday_header {
  flex: 1;
  color: #cacaca;
  font-weight: 500;
}
.calendar_week {
  margin-bottom: 10px;
  display: flex;
}
.calendar_day {
  flex: 1;
}
.calendar_day_invisible {
}
.calendar_day_content {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #4bd;
  color: white;
  margin: 0 auto;
  line-height: 30px;
  cursor: pointer;
  background: #4bd;
  font-size: 13px;
  font-weight: 500;
}
.calendar_day_content.blocked {
  cursor: not-allowed;
}
.calendar_day_content_number {
  width: 30px;
  height: 30px;
  border: none;
  color: #cacaca;
  margin: 0 auto;
  line-height: 25px;
  cursor: default;
  background: none;
}
.calendar_day_content:hover {
  border: 1px solid #4bd !important;
  background: #f5fdff;
  color: #4bd;
}
.calendar_day_content.closed {
  border: 1px solid #dedede;
  background: none;
  color: #333333;
}
.calendar_events_tabs {
  max-width: 300px;
}
.v-avatar {
  justify-content: center !important;
}
</style>
