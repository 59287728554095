<template>
  <div>
    <div class="table-header d-flex">
      <v-text-field
        class=""
        block
        v-model="search"
        rounded
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search_segment')"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-tooltip bottom dark color="#333333" v-if="is_manual">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-1"
            @click="showAddSegment"
            rounded
            color="secondary"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            {{ $t("add_segment") }}
          </v-btn>
        </template>
        <span>
          {{ $t("create_segment") }}
        </span>
      </v-tooltip>
      <div v-else>
        <v-icon>mdi-information-outline</v-icon>
        {{ $t("pms_generated_segments") }}
      </div>
    </div>
    <v-data-table
      v-if="filtered_segments && filtered_segments.length > 0"
      :headers="segments_headers"
      :items="filtered_segments"
      hide-default-footer
      disable-pagination
      @click:row="showEditSegment"
      :item-class="getDatatableRowClass"
      class="segments-table"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-avatar size="10" class="mr-2" :color="item.color"></v-avatar>
        <b class="mr-1">{{ item.name }}</b>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <template v-if="item.visible">
          <v-chip small color="secondary">{{ $t("visible") }}</v-chip>
        </template>
        <template v-else>
          <v-chip small>{{ $t("hidden") }}</v-chip>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="item.can_delete && is_manual"
          bottom
          dark
          color="#333333"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="showDeleteSegment(item)"
              icon
              color="secondary"
              v-on="on"
              ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
            >
          </template>
          <span>
            {{ $t("delete") }}
          </span>
        </v-tooltip>
        <v-tooltip v-else-if="!item.can_delete" bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">mdi-lock-outline</v-icon>
          </template>
          <span>
            {{ $t("segment_locked") }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- Edit Segment -->
    <v-dialog
      v-model="show_edit_segment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title
          v-if="item_to_edit.id"
          center
          class="headline justify-center py-6"
        >
          {{ $t("edit_segment") }}
        </v-card-title>
        <v-card-title v-else center class="headline justify-center py-6">
          {{ $t("new_segment") }}
        </v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-form ref="edit_segment_form" v-model="can_set_segment">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="item_to_edit.name"
                  maxlength="50"
                  counter
                  :label="$t('name')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('name')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="item_to_edit.code"
                  maxlength="50"
                  counter
                  :label="$t('code')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('code')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0 text-left">
                <label>{{ $t("is_visible") }}</label>
                <v-switch v-model="item_to_edit.visible"></v-switch>
              </v-col>
              <v-col v-if="!isAdd" cols="8" class="py-0 text-left">
                <label class="d-block mb-3">{{ $t("color") }}</label>
                <v-menu
                  v-model="segment_color_menu"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="swatch-style"
                      :style="{ backgroundColor: item_to_edit.color }"
                      v-on="on"
                    ></div>
                  </template>
                  <v-color-picker
                    v-model="item_to_edit.color"
                    mode="hexa"
                  ></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-5 justify-center">
          <v-btn
            v-if="item_to_edit.id"
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setSegment()"
            :disabled="!can_set_segment"
          >
            {{ $t("set_segment") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addSegment()"
            :disabled="!can_set_segment"
          >
            {{ $t("add_segment") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditSegment"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE Segment -->
    <v-dialog
      v-model="show_delete_segment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center"
          >{{ $t("are_you_sure") }}
        </v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_segment', { segment: item_to_edit.name })"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteSegment()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteSegment"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    item_to_edit: {},
    show_delete_segment: false,
    show_edit_segment: false,
    can_set_segment: null,
    segment_color_menu: false,
    isAdd: false,
    search: "",
    tab: 0
  }),
  props: {
    segments: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    }
  },
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row segment-row";
    },
    clearEditForm() {
      if (this.$refs.edit_segment_form) {
        this.$refs.edit_segment_form.resetValidation();
      }
    },
    showAddSegment() {
      this.clearEditForm();
      this.isAdd = true;
      this.show_edit_segment = true;
      this.item_to_edit = {
        id: null,
        hotel_id: this.current_hotel.id ? this.current_hotel.id : null
      };
    },
    showEditSegment(segment) {
      if (!segment) {
        return;
      }
      this.isAdd = false;
      this.initShowEditSegment(segment);
    },
    initShowEditSegment(segment) {
      this.clearEditForm();
      this.item_to_edit = JSON.parse(JSON.stringify(segment));
      this.show_edit_segment = true;
    },
    hideEditSegment() {
      this.show_edit_segment = false;
      this.tab = 0;
      this.item_to_edit = {};
    },
    setSegment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_segment"),
        color: "secondary"
      });

      this.$store
        .dispatch("setSegment", this.item_to_edit)
        .then(() => {
          this.show_edit_segment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("segment_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getSegments();
        })
        .catch(() => {
          this.show_edit_segment = false;
          this.item_to_edit = {};
          Utils.showError(this, "segment_not_modified");
        });
    },
    addSegment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_segment"),
        color: "secondary"
      });

      this.$store
        .dispatch("setSegment", this.item_to_edit)
        .then(response => {
          this.show_edit_segment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("segment_added"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getSegments();
        })
        .catch(() => {
          this.show_edit_segment = false;
          this.item_to_edit = {};
          Utils.showError(this, "segment_not_modified");
        });
    },
    showDeleteSegment(segment) {
      if (!segment) {
        return;
      }
      if (!this.segment_id) {
        this.$store.dispatch("setSnackbar", {
          title: this.$t("you_cannot_delete_segment"),
          color: "error"
        });
        return;
      }
      this.show_delete_segment = true;
      this.item_to_edit = segment;
    },
    hideDeleteSegment() {
      this.show_delete_segment = false;
      this.item_to_edit = {};
    },
    deleteSegment: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_segment"),
        color: "secondary"
      });
      this.$store
        .dispatch("deleteSegment", {
          hotel_id: this.current_hotel.id,
          id: this.item_to_edit.id
        })
        .then(() => {
          this.show_delete_segment = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("segment_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getSegments();
        })
        .catch(() => {
          this.show_delete_segment = false;
          this.item_to_edit = {};
          Utils.showError(this, "segment_not_deleted");
        });
    },
    getSegments() {
      this.$emit("getSegments");
    }
  },
  computed: {
    filtered_segments() {
      let segments = this.segments;
      if (this.search && this.search.length > 0) {
        segments = segments.filter(c =>
          c.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return segments;
    },
    segments_headers() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions",
          width: "120px"
        }
      ];
    },
    is_manual() {
      return (
        this.current_hotel &&
        this.current_hotel.settings &&
        this.current_hotel.settings.pms &&
        this.current_hotel.settings.pms === "manual"
      );
    },
    ...mapState({
      segments_store: state => state.segmentssegments
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language"
    })
  }
};
</script>

<style lang="scss" scoped>
.segments-table .segment-row > td:last-child {
  display: flex;
  align-items: center;
  padding-right: 55px;
  justify-content: flex-end;
}
.periods-table .period-row > td {
  padding: 10px !important;
}
.periods-table
  .period-row
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 10px;
  width: 75px;
  min-height: 10px;
  margin-bottom: 0;
}
.periods-table
  .period-row
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  padding: 0;
  margin: 0;
  min-height: 0;
  width: 75px;
}
.periods-table
  .period-row
  .v-text-field.v-text-field--enclosed
  .v-text-field__details
  .v-messages {
  min-height: 0;
}
.periods-table
  .period-row
  .v-text-field.v-text-field--enclosed
  .v-text-field__details
  .v-messages__message {
  font-size: 10px;
}
.swatch-style {
  margin-top: -16px;
  border-radius: 7px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.label-input {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  transform: translateY(-16px) translateX(-16px) scale(0.75);
}
</style>
