<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :color="isGroup ? '#ffffff' : '#f1f1f1'"
  >
    <template v-slot:header_options>
      <a-btn-pdf
        v-if="has_permission('export_pdf')"
        :config="pdf_config"
        class="mr-4"
      ></a-btn-pdf>
      <div style="max-width: 250px;" v-if="tabs.length > 0">
        <v-select
          v-model="tab_view"
          :items="tabs"
          item-value="id"
          :label="$t('viewType')"
          class="mr-2"
          color="#fff"
          rounded
          outlined
          dense
          hide-details
          :full-width="true"
          v-if="!isGroup"
        >
          <template v-slot:[`item`]="{ item }">
            <v-icon>
              {{ item.icon }}
            </v-icon>
            <span class="ml-2">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`selection`]="{ item }">
            <v-icon>
              {{ item.icon }}
            </v-icon>
            <span class="ml-2">
              {{ item.name }}
            </span>
          </template>
        </v-select>
      </div>
      <div style="max-width: 150px;">
        <v-select
          v-model="year"
          :items="years"
          item-text="name"
          item-value="id"
          :label="$t('year')"
          :rules="[v => !!v || $t('required')]"
          class="mr-2"
          color="#fff"
          rounded
          outlined
          dense
          hide-details
          :full-width="true"
          v-if="!isGroup"
        >
          <template v-slot:[`item`]="{ item }">
            <span>
              {{ item.name }}
            </span>
          </template>
        </v-select>
      </div>

      <a-rms-import-dates
        ref="rms_import_dates"
        v-if="!isGroup"
      ></a-rms-import-dates>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <rms-year-sales-axel-app
      v-else
      ref="rms-year-sales-app"
      :tab_view="tab_view"
    ></rms-year-sales-axel-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import rmsYearSalesAxelApp from "@/components/app/axel/rmsYearSalesApp";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms";
import aRmsImportDates from "@/components/rms/aRmsImportDates";
import aBtnPdf from "@/components/aBtnPdf.vue";

export default {
  name: "rms-year-sales",
  components: {
    aBtnPdf,
    aPage,
    rmsYearSalesAxelApp,
    aHotelSelectorRms,
    aRmsImportDates
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    event_period: [],
    tab_view: "table"
  }),
  methods: {},
  watch: {},
  computed: {
    tabs() {
      return [
        { name: this.$t("resumeTable"), id: "table", icon: "mdi-table-large" },
        { name: this.$t("charts"), id: "chart", icon: "mdi-chart-bar" }
      ];
    },
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 105 : 200;
    },
    years() {
      const years = [];
      Object.keys(this.available_years).forEach(year => {
        years.push({ id: parseInt(year), name: year });
      });
      return years;
    },
    year: {
      get() {
        return this.$store.state.rms.year_sales_year;
      },
      set(value) {
        this.$store.commit("SET_YEAR_SALES_YEAR", value);
      }
    },
    pdf_config() {
      return {
        filename: this.$t("year-sales-report") + " -- " + this.year,
        elements: [
          {
            id: "rms-year-sales-table",
            is_table: true
          },
          {
            id: "rms-year-sales-chart",
            is_table: false
          }
        ],
        hotel_name: this.current_hotel.name,
        section_name: this.$t("year-sales-report")
      };
    },
    ...mapState({
      available_years: state => state.hotel.hotel_available_years_data,
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_user: "current_user",
      current_hotel: "current_hotel",
      has_permission: "has_permission"
    })
  },
  mounted() {
    // Initialize selector
    this.year = Vue.moment().format("YYYY") * 1;
  }
};
</script>
