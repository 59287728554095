<template>
  <a-card title="Test">
    <!-- <div>{{ bi_report.report }}</div> -->
    <a-test-comp-2 ref="test-comp-2"></a-test-comp-2>
  </a-card>
</template>

<script>
import Vue from "vue";
import aCard from "@/components/aCard";
import aTestComp2 from "@/components/examples/aTestComp2";
import * as Utils from "@/utils.js";
import { mapState } from "vuex";

export default {
  name: "a-test-comp-1",
  props: {},
  methods: {},
  components: {
    aTestComp2,
    aCard
  },
  data: () => ({
    subscribed_modules: []
  }),
  mounted() {},
  computed: {
    ...mapState({
      // bi_report: state => state.bi.report
    })
  }
};
</script>
