var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"a-rms-day-pickup-tooltip"},[_c('v-tooltip',{attrs:{"content-class":"pickup-tooltip-content","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._t("default")],2)]}}],null,true)},[_c('span',{staticClass:"text-center justify-center align-center pickup-tooltip"},[_c('v-card-title',{staticClass:"text-uppercase font-weight-bold grey--text text--darken-3 white text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("pickup_" + this.spot))+" | "+_vm._s(_vm.date)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-class":_vm.getDatatableRowClass,"sort-by":"rn","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.intermediate_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.intermediate_name)+" ")]}},{key:"item.rn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rn)+" ")]}},{key:"item.room_revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.room_revenue)+" "),_c('span',{staticClass:"currency-symbol"},[_vm._v("€")])]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }