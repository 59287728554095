<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :mobile_image_height="mobile_image_height"
  >
    <template v-slot:header_options>
      <div>
        <v-tooltip bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-btn small icon color="white" class="mr-1">
              <v-icon v-on="on" @click="showRateHighlight(true)" small
                >mdi-star-four-points-outline</v-icon
              >
            </v-btn>
          </template>
          <span>
            {{ $t("assistant") }}
          </span>
        </v-tooltip>
        <v-tooltip v-if="current_hotel" bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" color="white" outlined small>
              <v-icon small class="mr-1">mdi-clock-outline</v-icon>
              {{ current_hotel.last_import_date.rate | formatDatetime }}
            </v-chip>
          </template>
          <span>
            {{ $t("last_update") }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <rate-app ref="rate-app"></rate-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { mapActions } from "vuex";
import aPage from "@/components/aPage";
import rateApp from "@/components/app/rateApp";

export default {
  components: { aPage, rateApp },
  data: vm => ({
    image_source: "/img/header.jpg", //Mandatory path
    image_height: 700, //Mandatory number,
    mobile_image_height: 1200 // Optional number
  }),
  methods: {
    ...mapActions(["showRateHighlight"])
  },
  watch: {},
  computed: {
    groupDisplay() {
      return this.current_hotel
        ? this.current_hotel.children_number > 0
        : false;
    },
    ...mapState({}),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  updated() {},
  mounted() {}
};
</script>
