<template>
  <div class="a-rms-day-row-header">
    <v-row no-gutters>
      <v-col cols="3">
        {{ labels[0] }}
      </v-col>
      <v-col cols="2">
        {{ labels[1] }}
      </v-col>
      <v-col cols="3">
        {{ labels[2] }}
      </v-col>
      <v-col cols="4">
        {{ labels[3] }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "a-rms-day-header",
  components: {},
  props: {
    date: {
      type: String,
      mandatory: false,
      default: ""
    },
    labels: {
      type: Array,
      mandatory: true,
      default: () => []
    }
  },
  computed: {
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "competitor_name"
        },
        {
          align: "center",
          sortable: true,
          value: "competitor_rating"
        },
        {
          align: "center",
          sortable: true,
          value: "regime"
        },
        {
          align: "center",
          sortable: true,
          value: "min_price"
        },
        {
          align: "center",
          sortable: true,
          value: "is_genius_offer"
        },
        {
          align: "center",
          sortable: true,
          value: "is_mobile_offer"
        }
      ];
    }
  },
  methods: {
    getDatatableRowClass(item) {
      let classes = "a-datatable-row";
      if (item.self) {
        classes += " blue--text";
      }
      return classes;
    }
  }
};
</script>

<style lang="scss">
.compset-tooltip-content {
  background-color: white;
  border: 1px solid #000;
  border-radius: 0;
  padding: 0 !important;
  opacity: 1 !important;
  .v-card__title {
    padding: 5px 14px !important;
  }
  .v-data-table {
    border-radius: 0 !important;
    .v-data-table__wrapper {
      padding: 0 12px !important;
      .a-datatable-row {
        td {
          padding: 5px !important;
          &:first-child {
            color: inherit !important;
          }
          .currency-symbol {
            margin-left: -5px;
          }
          .v-btn--fab.v-size--x-small {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
