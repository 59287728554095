var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',_vm._l((_vm.item.settings.pickup_spots),function(i,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('pickup') + ' ' + (index + 1),"rules":_vm.rules,"type":"number","outlined":"","rounded":"","dense":""},on:{"input":function($event){_vm.item.settings.pickup_spots[
          _vm.item.settings.pickup_spots.length - 1 - index
        ] = parseInt(
          _vm.item.settings.pickup_spots[
            _vm.item.settings.pickup_spots.length - 1 - index
          ]
        )}},model:{value:(
        _vm.item.settings.pickup_spots[
          _vm.item.settings.pickup_spots.length - 1 - index
        ]
      ),callback:function ($$v) {_vm.$set(_vm.item.settings.pickup_spots, 
          _vm.item.settings.pickup_spots.length - 1 - index
        , $$v)},expression:"\n        item.settings.pickup_spots[\n          item.settings.pickup_spots.length - 1 - index\n        ]\n      "}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }