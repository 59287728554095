var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-page',{attrs:{"id":_vm.app_id,"image_source":_vm.image_source,"image_height":_vm.image_height},scopedSlots:_vm._u([{key:"header_options",fn:function(){return [(!_vm.is_all_hotels)?_c('div',[_c('v-select',{staticClass:"mt-8",staticStyle:{"max-width":"120px"},attrs:{"items":_vm.available_years.reverse(),"mandatory":"","rounded":"","outlined":"","dense":"","disabled":_vm.editing},model:{value:(_vm.budget_year),callback:function ($$v) {_vm.budget_year=$$v},expression:"budget_year"}})],1):_vm._e()]},proxy:true}])},[(_vm.is_all_hotels)?_c('div',{staticClass:"d-flex justify-center align-center text-center white--text mt-4 mb-12",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"px-10 mx-5"},[_c('h3',{staticClass:"text-h3 d-block"},[_vm._v(" "+_vm._s(_vm.$t("which_hotel_you_want_setup"))+" ")]),_c('v-btn',{staticClass:"mt-10",attrs:{"color":"secondary","rounded":""},on:{"click":_vm.open_hotel_selector}},[_vm._v(_vm._s(_vm.$t("select_hotel")))])],1)]):_c('div',{},[_c('div',{staticClass:"white--text mt-4 mb-12",staticStyle:{"position":"relative"}},[(!_vm.isReady)?_c('v-card',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1):_c('v-card',[_c('v-card-title',{class:{
            'justify-end': !_vm.is_saving_year_budget,
            'd-block': _vm.is_saving_year_budget
          }},[(_vm.is_saving_year_budget)?_c('div',{staticClass:"progress w-100"},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("generating_budget"))+"...")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"rounded":"","color":"secondary","height":"30"},model:{value:(_vm.progress_value),callback:function ($$v) {_vm.progress_value=$$v},expression:"progress_value"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.progress_text)+" ")])])],1)],1)],1):_c('div',{staticClass:"buttons-container"},[(!_vm.editing)?_c('div',{staticClass:"buttons-for-editing"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.isReady,"rounded":""},on:{"click":function($event){_vm.editing = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_vm._v(_vm._s(_vm.$t("modify")))],1),(_vm.daily_budget_visible)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.isReady,"rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cloud-upload")]),_vm._v(_vm._s(_vm.$t("upload_budget")))],1)]}}],null,false,2870176554)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"px-0"},[_c('v-btn',{staticClass:"px-0",attrs:{"text":"","block":"","x-large":""},on:{"click":function($event){_vm.show_upload_dialog = true}}},[_vm._v(_vm._s(_vm.$t("monthly")))])],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-btn',{staticClass:"px-0",attrs:{"text":"","block":"","x-large":""},on:{"click":function($event){_vm.show_upload_daily_dialog = true}}},[_vm._v(_vm._s(_vm.$t("daily")))])],1)],1)],1):_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.isReady,"rounded":""},on:{"click":function($event){_vm.show_upload_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cloud-upload")]),_vm._v(_vm._s(_vm.$t("upload_budget")))],1),(_vm.has_previous_year_budget)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.isReady,"rounded":""},on:{"click":_vm.copyPreviousYearBudget}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-timer-plus-outline")]),_vm._v(" "+_vm._s(_vm.$t("extend_from_year", { year: _vm.year_before }))+" ")],1):_vm._e(),(_vm.daily_budget_visible)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.isReady,"rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tray-arrow-down")]),_vm._v(" "+_vm._s(_vm.$t("download_excel"))+" ")],1)]}}],null,false,1617907493)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"px-0"},[_c('v-btn',{staticClass:"px-0",attrs:{"text":"","block":"","x-large":"","target":"_blank"},on:{"click":function($event){return _vm.downloadBudget('monthly')}}},[_vm._v(_vm._s(_vm.$t("monthly")))])],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-btn',{staticClass:"px-0",attrs:{"text":"","block":"","x-large":"","target":"_blank"},on:{"click":function($event){return _vm.downloadBudget('daily')}}},[_vm._v(_vm._s(_vm.$t("daily")))])],1)],1)],1):_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.isReady,"rounded":""},on:{"click":function($event){return _vm.downloadBudget('monthly')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tray-arrow-down")]),_vm._v(" "+_vm._s(_vm.$t("download_excel"))+" ")],1)],1):_c('div',{staticClass:"buttons-while-editing"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","rounded":"","disabled":!_vm.has_changes},on:{"click":_vm.saveNewBudget}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"rounded":"","text":"","depressed":""},on:{"click":_vm.discardNewBudget}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])]),_c('v-card-text',[_c('budget-kpis')],1)],1)],1)]),_c('budget-upload-dialog'),(_vm.daily_budget_visible)?_c('budget-upload-daily-dialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }