<template>
  <div class="rms-hotelsdot-day-general-columns" :class="dense ? 'dense' : ''">
    <v-row class="mx-0">
      <v-col class="kpi-cell spacer pa-0"> </v-col>
      <v-col class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col
            v-for="col of columns"
            :key="col.value"
            :class="classCol(col)"
            @mouseover="kpi_hover = col.value"
            @mouseleave="kpi_hover = ''"
          >
            {{ $t(col.value) }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "a-rms-day-general-pickup-columns",
  components: {},
  props: {
    columns: Array,
    dense: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    kpi_hover: {
      get() {
        return this.$store.state.rms.day_general_pickup_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsDayGeneralPickupHover", value);
      }
    }
  },
  methods: {
    classCol(col) {
      let clas =
        "pa-0 kpi-cell kpi-cell-row d-flex align-center justify-center";
      if (this.kpi_hover == col.value) {
        clas += " kpi-hover";
      }
      return clas;
    }
  }
};
</script>
<style lang="scss" scoped>
.rms-hotelsdot-day-general-columns {
  &.dense {
    .kpi-cell {
      height: 40px;
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    border-bottom: 3px solid #ffffff;
    display: block;
    line-height: 15px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    color: #666666;
    padding: 0px 15px !important;
    &.spacer {
      border-left: none;
      border-right: none;
      min-width: 250px;
      max-width: 250px;
      z-index: 1;
    }
    &:hover,
    &.kpi-hover {
      border-bottom: 3px solid #2d6ece;
    }
  }
}
</style>
