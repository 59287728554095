<template>
  <a-page
    id="trial-management-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <trial-management-app
      ref="trial-management-app"
      only_trial
    ></trial-management-app>
  </a-page>
</template>

<script>
import aPage from "@/components/aPage";
import trialManagementApp from "@/components/app/settings/trialManagementApp.vue";

export default {
  components: {
    aPage,
    trialManagementApp
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 280, //Mandatory number
    subscribed_modules: ["trial_requests"]
  })
};
</script>
<style>
.hotel_selected {
  background-color: #0084c8 !important;
}
</style>
