<template>
  <div>
    <v-row class="mx-0">
      <v-col class="pa-0 ma-0 days-container">
        <v-row class="pa-0 ma-0 days-wrapper">
          <v-col
            class="pa-0 pt-1 kpi-cell d-flex align-center justify-center first-column"
          ></v-col>
          <v-col
            v-for="(column, index) of columns"
            :key="'a-rms-strategy-day-header-' + index"
            class="pa-0 pt-1 kpi-cell d-flex align-center justify-center"
            :class="bgCellClass(column.kpi)"
          >
            <div>
              <span
                class="d-block day-name grey--text text--darken-3 text-uppercase"
                >{{ $t(column.name) }}</span
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as Utils from "@/utils.js";
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-rms-strategy-day-header",
  components: {},
  props: {
    columns: Array
  },
  computed: {
    kpi_hover() {
      return this.rms.strategy_kpi_hover;
    },
    ...mapState({
      rms: state => state.rms
    })
  },
  methods: {
    isHovered(kpi) {
      return this.kpi_hover === kpi;
    },
    bgCellClass(kpi) {
      let classes = [];

      if (this.isHovered(kpi)) {
        classes.push("kpi-hover");
      }
      return classes.join(" ");
    },
    getFriendlyDateTop(date) {
      return date
        ? Vue.moment(date)
            .format("ddd D")
            .replace(".", "")
        : "";
    },
    getFriendlyDateBottom(date) {
      return date
        ? Vue.moment(date)
            .format("MMM 'YY")
            .replace(".", "")
        : "";
    },
    getFriendlyDay(date) {
      return date ? Utils.getFriendlyDay(date) : "";
    }
  }
};
</script>
<style lang="scss" scoped>
.kpi-cell {
  height: $minHeightColRow;
  border-top: $borderType $borderColorV;
  border-left: $borderType $borderColorV;
  border-right: $borderType $borderColorV;
  border-bottom: 3px solid transparent;
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  text-wrap: wrap;

  &.weekend {
    background: rgba(219, 198, 77, 0.1);
  }
  &.kpi-hover {
    border-bottom: 3px solid #2d6ece;
  }
  &.first-column {
    flex-grow: 0;
    flex-basis: 200px;
  }
}
.days-container {
  overflow: hidden;
  .days-wrapper {
    transition: transform 0.2s;
    transition-timing-function: ease;
  }
}
</style>
