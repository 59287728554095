<template>
  <a-page
    id="room-type-management-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-if="is_all_hotels">
      <a-hotel-selector-page></a-hotel-selector-page>
    </template>
    <template v-else>
      <room-type-management-app
        ref="room-type-management-app"
      ></room-type-management-app>
    </template>
  </a-page>
</template>

<script>
import { mapGetters } from "vuex";
import aPage from "@/components/aPage";
import roomTypeManagementApp from "@/components/app/settings/roomTypeManagementApp.vue";
import aHotelSelectorPage from "@/components/selector/aHotelSelectorPage.vue";

export default {
  components: {
    aPage,
    roomTypeManagementApp,
    aHotelSelectorPage
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 280, //Mandatory number
    subscribed_modules: ["roomtype_types_mapped", "roomtype_types_available"]
  }),
  computed: {
    is_all_hotels: function() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style>
.hotel_selected {
  background-color: #0084c8 !important;
}
</style>
