<template>
  <div id="rate-graph-bg" class="d-flex">
    <div class="item rate-labels semibold"></div>
    <div class="item days">
      <div class="days-wrapper no-flex" :class="'x' + days_per_screen">
        <div class="d-flex">
          <div
            v-for="day in days"
            :key="'rate-graph-' + day"
            class="day"
            :class="{ weekend: is_weekend(day) }"
            @mouseover="date_hover = day"
            @mouseout="date_hover = ''"
          >
            <div class="day-graph-bg"></div>
          </div>
        </div>
        <div class="rate-graph-wrapper" :class="'x' + days_per_screen">
          <zingchart
            height="250px"
            width="100%"
            :data="graph_data"
            :series="graph_series"
          ></zingchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

function compareRanking(a, b) {
  if (a.ranking < b.ranking) {
    return -1;
  }
  if (a.ranking > b.ranking) {
    return 1;
  }
  return 0;
}

var tooltips = [];

export default {
  name: "rate-graph-bg",
  //components: {},
  props: ["days", "days_per_screen", "min_graph_value", "max_graph_value"],
  //data: vm => ({}),
  methods: {
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    }
  },
  computed: {
    // min_graph_data() {
    //   return this.daily_rates && this.daily_rates.extras
    //     ? this.daily_rates.extras.min_price_scale.bottom_value
    //     : null;
    // },
    // max_graph_data() {
    //   return this.daily_rates && this.daily_rates.extras
    //     ? this.daily_rates.extras.min_price_scale.top_value
    //     : null;
    // },
    graph_data() {
      return {
        type: "line",
        gui: {
          contextMenu: {
            empty: true
          }
        },
        plot: {
          aspect: "spline",
          fontFamily: "Roboto Condensed"
        },
        backgroundColor: "none", // This is in the root
        plotarea: {
          backgroundColor: "transparent",
          margin: "5px"
        },
        scaleX: {
          visible: false,
          lineColor: "none",
          item: {
            visible: false
          },
          tick: {
            lineColor: "none"
          }
        },
        scaleY: {
          minValue: this.min_graph_value - 10,
          maxValue:
            this.max_graph_value == this.min_graph_value
              ? this.max_graph_value + 10
              : this.max_graph_value,
          visible: false,
          lineColor: "none",
          item: {
            visible: false
          },
          tick: {
            lineColor: "none"
          }
        },
        tooltip: {
          jsRule: "window.customFncs.formatCrosshair()"
        }
      };
    },
    graph_series() {
      tooltips = [];
      var series = [];
      for (const competitor of this.visible_competitors) {
        var values = [];
        var index = 0;
        for (const day of this.days) {
          if (
            this.daily_rates.report &&
            this.daily_rates.report["competitor::" + competitor.id]
          ) {
            values.push(
              this.daily_rates.report["competitor::" + competitor.id][day]
                ? this.daily_rates.report["competitor::" + competitor.id][
                    day
                  ][0].min_price
                : null
            );
            index++;
          }
        }
        var item = {
          values: values,
          lineColor: competitor.color,
          marker: {
            "background-color": competitor.color
          }
        };

        series.push(item);
      }

      //Creamos el tooltip
      for (const day of this.days) {
        var t = "";
        t =
          t +
          "<div style='text-align:left;font-family:\"Roboto Condensed\"'><span style='text-align:left; color: #333333; font-size:13px; font-weight:700;font-family:\"Roboto Condensed\";line-height:30px;'>" +
          Vue.moment(day).format("dddd, D MMMM YYYY") +
          "</span>";

        var competitors = [];
        for (const competitor of this.visible_competitors) {
          if (
            this.daily_rates.report &&
            this.daily_rates.report["competitor::" + competitor.id]
          ) {
            var c = {
              name: competitor.name,
              color: competitor.color,
              ranking: this.daily_rates.report["competitor::" + competitor.id][
                day
              ]
                ? this.daily_rates.report["competitor::" + competitor.id][
                    day
                  ][0].ranking
                : 100,
              min_price: this.daily_rates.report[
                "competitor::" + competitor.id
              ][day]
                ? this.daily_rates.report["competitor::" + competitor.id][
                    day
                  ][0].min_price
                : null,
              self: this.daily_rates.report["competitor::" + competitor.id][day]
                ? this.daily_rates.report["competitor::" + competitor.id][
                    day
                  ][0].self
                : null
            };
            competitors.push(c);
          }
        }
        var ordered_competitors = competitors.sort(compareRanking);
        t =
          t +
          "<table width='170px' cellspacing='0' cellpadding='0' style='padding:0'>";
        var cont = 1;
        for (const competitor of ordered_competitors) {
          if (competitor.min_price > 0) {
            var fontWeight = competitor.self
              ? "font-weight:600;"
              : "font-weight:500;";
            var number =
              this.l.money_pre_symbol +
              numeral(competitor.min_price).format(this.l.number_format) +
              "" +
              this.l.money_post_symbol;
            t =
              t +
              "<tr><td align='left' style='padding:0;line-height:14px;'><span style='color: #333333; font-size:11px; line-height:14px; font-family:\"Roboto Condensed\";" +
              fontWeight +
              "'>" +
              cont +
              ".</span><span style='padding:0 5px; border-radius:10px;color: white; background-color: " +
              competitor.color +
              '; font-size:11px; line-height:14px; font-family:"Roboto Condensed";' +
              fontWeight +
              "'> " +
              competitor.name +
              "</span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: #333333; font-size:11px; line-height:14px; font-weight:700;font-family:\"Roboto Condensed\"'>" +
              number +
              "</span></td></tr>";
            cont++;
          }
        }
        t = t + "</table>";
        t = t + "</div>";

        tooltips.push(t);
      }
      return series;
    },
    date_hover: {
      get() {
        return this.$store.state.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    visible_competitors() {
      return this.competitors.filter(
        c => this.invisible_competitors.indexOf(c.id) < 0
      );
    },
    ...mapState({
      //current_hotel: state => state.hotel.hotel,
      competitors: state => state.rate.competitors,
      invisible_competitors: state => state.rate.invisible_competitors,
      daily_rates: state => state.rate.daily_rates
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language"
    })
  },
  mounted() {
    // Global wrapper
    if (!window.customFncs) {
      window.customFncs = {};
    }

    /*
     * Function under our ZC wrapper. Will
     * be fired when the node is hovered over
     *
     * @param {object} p ZingChart callback argument.
     * is associated to various graph properties
     */
    window.customFncs.formatCrosshair = function(p) {
      var text = tooltips[p.key];
      return {
        text: text,
        backgroundColor: "white",
        borderRadius: "3px",
        borderColor: "#cacaca",
        borderWidth: 2,
        lineHeight: "10px",
        // height: "250px",
        // width: "220px",
        htmlMode: true,
        fontFamily: "Roboto Condensed"
      };
    };
  }
};
</script>
