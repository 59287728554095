<template>
  <v-row class="kpi-row kpi-row-hotelsdot mx-0">
    <v-col class="kpi-label-wrapper pa-0">
      <div class="kpi-label d-flex align-center">
        <a-period :period="rowData['spot']" dense></a-period>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="(col, index) of columns"
          :key="index"
          class="pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center"
          :class="kpi_selected == col.value ? 'kpi-selected' : ''"
          @click="kpi_selected = col.value"
          @mouseover="kpi_hover = col.value"
          @mouseleave="kpi_hover = ''"
        >
          <a-kpi-columns :kpi="col.value" :value="rowData[col.value]" x_small>
            <template v-slot:right>
              <a-increment
                v-if="rowData['spot'] !== 'otb' && vs_value(col)"
                :value="vs_value(col)"
                :vs_value="rowData[col.value]"
                :kpi="col.value"
                :hide_tooltip="true"
              >
              </a-increment>
            </template>
          </a-kpi-columns>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import aPeriod from "@/components/rms_ui/aPeriod.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aIncrement from "@/components/rms_ui/aIncrement.vue";
import aKpiColumns from "@/components/rms_ui/aKpiColumns.vue";

export default {
  name: "a-rms-year-sales-row",
  components: {
    aKpiColumns,
    aIncrement,
    aPeriod,
  },
  props: {
    columns: Array,
    rowData: Object,
    rowIndex: Number
  },
  computed: {
    kpi_selected: {
      get() {
        return this.$store.state.rms.year_sales_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiGraph", value);
      }
    },
    kpi_hover: {
      get() {
        return this.$store.state.rms.year_sales_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiHover", value);
      }
    }
  },
  methods: {
    vs_value(col) {
      if (col.value + "_vs" in this.rowData) {
        return this.rowData[col.value + "_vs"];
      }
      return null;
    },
    classCol(col) {
      let clas =
        "pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center";
      if (this.kpi_selected == col.value) {
        clas += " kpi-selected";
      }
      return clas;
    },
    periods(spot) {
      if (spot == "otb") {
        return [this.rowData["spot"], this.rowData["spot"]];
      } else {
        return [this.rowData["spot"], "otb"];
      }
    }
  }
};
</script>
<style lang="scss">
.kpi-row.kpi-row-hotelsdot {
  &:hover {
    .kpi-label-wrapper {
      .kpi-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  .kpi-label-wrapper {
    min-width: 150px;
    max-width: 150px;
    .kpi-label {
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
      .spot-otb {
        .a-period:last-child {
          display: none !important;
        }
      }
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &:hover,
    &.kpi-selected {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
</style>
