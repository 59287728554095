<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="id"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-year-sales-occupation-chart",
  components: {},
  props: {
    id: {
      type: String,
      default: "a-year-sales-occupation-chart"
    },
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    },
    totals: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: vm => ({}),
  methods: {},
  computed: {
    graph_data() {
      const chartConfig = {
        type: "mixed",
        plot: {
          aspect: "spline"
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 40px 30px 50px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#cacaca"
          },
          tick: {
            size: 0
          },
          labels: this.labels
        },
        scaleY: {
          lineWidth: 0,
          values: "0:100:50",
          format: "%v%",
          guide: {
            visible: false,
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#cacaca"
          }
        },
        scaleY2: {
          lineWidth: 0,
          format: "%v€",
          guide: {
            visible: false,
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#cacaca"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
      if (this.totals) {
        chartConfig.scaleY.markers = [
          {
            type: "line",
            // label: {
            //   text: this.$t("occupancy") + " otb"
            // },
            labelPlacement: "opposite",
            lineColor: this.hotelsdot.series_ui.otb.color,
            lineStyle: "dotted",
            range: [this.totals.occupancy.otb]
          },
          {
            type: "line",
            // label: {
            //   text: this.$t("occupancy") + " stly"
            // },
            labelPlacement: "opposite",
            lineColor: this.hotelsdot.series_ui.stly.color,
            lineStyle: "dotted",
            range: [this.totals.occupancy.stly]
          }
        ];
        chartConfig.scaleY2.markers = [
          {
            type: "line",
            // label: {
            //   text: this.$t("adr") + " otb"
            // },
            labelPlacement: "opposite",
            lineColor: this.hotelsdot.series_ui.otb.color,
            lineStyle: "dashed",
            lineWidth: 2,
            range: [this.totals.adr.otb]
          },
          {
            type: "line",
            // label: {
            //   text: this.$t("adr") + " stly"
            // },
            labelPlacement: "opposite",
            lineColor: this.hotelsdot.series_ui.stly.color,
            lineStyle: "dashed",
            lineWidth: 2,
            range: [this.totals.adr.stly]
          }
        ];
      }
      return chartConfig;
    },
    graph_series() {
      let series = [];
      for (let serie in this.series.occupancy) {
        series.push({
          type: "bar",
          lineWidth: 1,
          rules: [
            {
              rule: `%v < 0`,
              borderRadiusBottomLeft: 10,
              borderRadiusBottomRight: 10,
              borderRadiusTopLeft: 10,
              borderRadiusTopRight: 10
            },
            {
              rule: `%v >= 0`,
              borderRadiusBottomLeft: 10,
              borderRadiusBottomRight: 10,
              borderRadiusTopLeft: 10,
              borderRadiusTopRight: 10
            }
          ],
          marker: {
            size: 3,
            borderWidth: 1,
            shadow: 0,
            backgroundColor: this.hotelsdot.series_ui[serie]
              ? this.hotelsdot.series_ui[serie].color
              : null
          },
          scales: "scale-x,scale-y",
          text: serie + "%",
          values: this.series.occupancy[serie],
          backgroundColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          lineColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null
        });
      }
      for (let serie in this.series.adr) {
        series.push({
          type: "line",
          text: serie + " €",
          scales: "scale-x,scale-y-2",
          values: this.series.adr[serie],
          aspect: "spline",
          lineWidth: 2,
          marker: {
            backgroundColor: this.hotelsdot.series_ui[serie]
              ? this.hotelsdot.series_ui[serie].color
              : null,
            size: 3,
            borderWidth: 1,
            opacity: "0.3",
            shadow: 1
          },
          backgroundColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          lineColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null
        });
      }
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({})
  }
};
</script>
