<template>
  <v-row>
    <v-col v-if="!canEdit">
      <v-card>
        <v-card-text class="text-center">
          <h3 class="">{{ $t("cannot_edit_rates_past_dates") }}</h3>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="py-0" v-else>
      <v-row>
        <v-col class="d-flex justify-end">
          <a-chm-bulk-edit ref="channelManagerBulkEdit">
            <template v-slot:button>
              <v-btn
                depressed
                class="super-raised mr-4"
                right
                rounded
                outlined
                color="info"
                @click="openChannelManagerBulkEdit"
              >
                <!-- <v-icon class="mr-2">mdi-flash</v-icon> -->
                {{ $t("bulk_edit") }}
              </v-btn>
            </template>
          </a-chm-bulk-edit>
          <v-btn
            depressed
            class="super-raised mr-4"
            right
            rounded
            outlined
            color="info"
            @click="goToChannelManager"
          >
            <!-- <v-icon class="mr-2">mdi-flash</v-icon> -->
            {{ $t("go_to_channel_manager") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="">
          <v-card elevation="2">
            <v-card-text>
              <v-data-table
                :items="items"
                :headers="headers"
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <div
                    class="d-flex master-rate justify-space-between align-center"
                  >
                    <span> {{ item.room_name }} ({{ item.rate_name }}) </span>
                    <v-chip
                      color="info"
                      class="px-2"
                      x-small
                      v-if="item.channel_ids"
                    >
                      <v-icon x-small class="mr-2">
                        mdi-sitemap
                      </v-icon>
                      {{ item.channel_ids.length }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:item.current_price="{ item }">
                  <span> {{ item.days[date_edit].original_price }} € </span>
                </template>
                <template v-slot:item.modification_type="{ item }">
                  <v-row no-gutters style="max-width: 500px">
                    <v-col class="mx-0 my-2">
                      <v-btn-toggle
                        class="b-transparent v-sheet"
                        v-model="modification_types[item.rate_id]"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              :class="{
                                'info white--text':
                                  modification_types[item.rate_id] === 0
                              }"
                            >
                              <v-avatar size="45"
                                ><v-img
                                  :src="pencil"
                                  contain
                                  max-height="40"
                                ></v-img>
                              </v-avatar>
                            </v-btn>
                          </template>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-body-2 font-weight-bold"
                            >
                              {{ $t("assign_new_price") }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="text-body-2 text-wrap"
                              style="font-size:11px !important;"
                              >{{
                                $t("assign_new_price_sub")
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              :class="{
                                'info white--text':
                                  modification_types[item.rate_id] === 1
                              }"
                              ><v-avatar size="45"
                                ><v-img
                                  :src="euro"
                                  contain
                                  max-height="40"
                                ></v-img></v-avatar
                            ></v-btn>
                          </template>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-body-2 font-weight-bold"
                            >
                              {{ $t("increase_decrease_current_price") }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="text-body-2 text-wrap"
                              style="font-size:11px !important;"
                              >{{
                                $t("increase_decrease_current_price_sub")
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              :class="{
                                'info white--text':
                                  modification_types[item.rate_id] === 2
                              }"
                              ><v-avatar size="45"
                                ><v-img
                                  :src="percent"
                                  contain
                                  max-height="40"
                                ></v-img></v-avatar
                            ></v-btn>
                          </template>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-body-2 font-weight-bold"
                            >
                              {{
                                $t("increase_decrease_current_price_percentage")
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="text-body-2 text-wrap"
                              style="font-size:11px !important;"
                              >{{
                                $t(
                                  "increase_decrease_current_price_percentage_sub"
                                )
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-tooltip>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.value="{ item }">
                  <v-text-field
                    v-model="modification_values[item.rate_id]"
                    :disabled="!(modification_types[item.rate_id] >= 0)"
                    type="number"
                    :label="$t('value')"
                  >
                    <!-- <template v-slot:prepend>
                  
                </template> -->
                  </v-text-field>
                </template>
                <template v-slot:item.updated_price="{ item }">
                  <span> {{ getUpdatedPrice(item) }} € </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-btn
            depressed
            class="super-raised mr-4"
            rounded
            color="success"
            @click="saveChanges"
            :disabled="!hasChanges"
          >
            <!-- <v-icon class="mr-2">mdi-flash</v-icon> -->
            {{ $t("save_changes") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import { channelManagerCellOperations } from "@/utils";
import aChmBulkEdit from "./aChmBulkEdit.vue";
const operations = channelManagerCellOperations();
import pencil from "@/assets/imgs/pencil.png";
import euro from "@/assets/imgs/euro.png";
import percent from "@/assets/imgs/percent.png";
export default {
  components: { aChmBulkEdit },
  props: {
    date_edit: {
      type: String,
      default: null
    }
  },
  data: () => ({
    operations,
    modification_types: {},
    modification_values: {},
    euro,
    pencil,
    percent
  }),
  methods: {
    getUpdatedPrice(item) {
      const operationIndex = this.modification_types[item.rate_id];
      const value = parseInt(this.modification_values[item.rate_id]);
      if (operationIndex >= 0 && value) {
        const operation = Object.values(this.operations)[operationIndex];
        const base = item.days[this.date_edit].original_price;
        return operation(value, base);
      }
      return item.days[this.date_edit].original_price;
    },
    openChannelManagerBulkEdit() {
      // TODO close current??
      this.$refs.channelManagerBulkEdit.openModal();
    },
    goToChannelManager() {
      this.$router.push(
        { name: "channel_manager" },
        { params: { hotel_slug: this.$route.params.hotel_slug } }
      );
    },
    saveChanges() {
      // TODO save changes
      Object.keys(this.modification_values).forEach(rate_id => {
        const value = parseInt(this.modification_values[rate_id]);
        const operationIndex = this.modification_types[rate_id];
        const operationName = Object.keys(this.operations)[operationIndex];
        if (value >= 0) {
          const operation = Object.values(this.operations)[operationIndex];
          const data = {
            action: "applyRatesPriceChanges",
            action_type: "price",
            payload: {
              strict: true,
              form: {
                entity_ids: [rate_id],
                type_rate_change: operationName,
                value,
                entity_type: "rate"
              },
              // daysSelected: [parseInt(this.getDayOfWeek)],
              daysSelected: [0, 1, 2, 3, 4, 5, 6],
              operation,
              datesRange: [this.date_edit, this.date_edit]
            }
          };
          this.$store.dispatch("updateChangesHistory", data);
        }
      });
      this.$store.dispatch("setChanges");
    }
  },
  computed: {
    hasChanges() {
      let changes = false;
      this.items.forEach(item => {
        const operationIndex = this.modification_types[item.rate_id];
        const value = parseInt(this.modification_values[item.rate_id]);
        const base = item.days[this.date_edit]
          ? item.days[this.date_edit].original_price
          : 0;
        const updated = this.getUpdatedPrice(item);
        if (
          operationIndex >= 0 &&
          value !== 0 &&
          updated > 0 &&
          updated !== base
        ) {
          changes = true;
        }
      });
      return changes;
    },
    canEdit() {
      const now = Vue.moment();
      const date_edit = Vue.moment(this.date_edit);
      return date_edit.diff(now, "days") >= 0;
    },
    items() {
      return this.$store.getters.getDayRowsMasterRates;
    },
    headers() {
      return [
        { text: this.$t("rates"), sortable: false, value: "name" },
        {
          text: this.$t("current_price"),
          align: "center",
          sortable: false,
          value: "current_price"
        },
        {
          text: this.$t("modification_type"),
          align: "center",
          sortable: false,
          value: "modification_type"
        },
        {
          text: this.$t("value"),
          align: "center",
          sortable: false,
          value: "value"
        },
        {
          text: this.$t("updated_price"),
          align: "center",
          sortable: false,
          value: "updated_price"
        }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.b-transparent {
  background-color: white !important;
  border: none !important;
}
</style>
