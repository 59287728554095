<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      {{ my_report }}
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import aBaseComponent from "@/components/aBaseComponent";
import * as Utils from "@/utils.js";

export default {
  name: "a-test-comp-2",
  props: {},
  methods: {},
  components: { aBaseComponent },
  data: () => ({
    // my_subscribed_modules: ["bi_report"],
    subscribed_modules: ["bi_report"]
  }),
  mounted() {
    // Utils.subscribedModules(this);
  },
  computed: {
    my_report() {
      return this.bi_report.report;
    },
    ...mapState({
      bi_report: state => state.bi.report
    })
  }
};
</script>
