<template>
  <v-row class="mx-0">
    <v-col cols="12" class="pa-0">
      <div
        class=" grey lighten-4 d-flex align-center px-2 py-1 text-uppercase font-weight-bold grey--text text--darken-2"
      >
        <v-avatar
          v-if="label !== 'total'"
          size="10"
          class="avatar mr-1"
          :color="roomtype_color(label)"
        ></v-avatar>
        <b>{{ roomtype_title(label) }}</b>
        <small v-if="label !== 'total'" class="ml-2 mt-1">{{ label }}</small>
      </div>
    </v-col>
    <v-col cols="12" class="pa-0">
      <v-row class="row-item px-3" v-if="isVisibleRowItem('rn')">
        <v-col class="item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize">
            {{ $t("available_rooms") }}
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 days-container">
          <v-row class="pa-0 ma-0 days-wrapper" :style="styleRow">
            <v-col
              v-for="(col, index) of rowAvailable.otb"
              :key="index"
              class="pa-0 kpi-cell d-flex align-center justify-center"
              :class="bgCellClass(index)"
              @mouseover="kpi_hover = index"
              @mouseleave="kpi_hover = ''"
            >
              <template v-if="rowAvailable[vs_pickup]">
                <a-kpi
                  class="mr-1"
                  kpi="rn"
                  :value="col"
                  :vs_values="[col - rowAvailable[vs_pickup][index]]"
                  :vs_periods="[vs_pickup]"
                  x_small
                  absolute
                ></a-kpi>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="total item-wrapper pa-0">
          <div
            class="item-label d-flex align-center text-capitalize align-center justify-center"
          >
            <a-kpi
              class="mr-1"
              kpi="rn"
              :value="total.free.otb"
              x_small
            ></a-kpi>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="row-item px-3"
        v-if="label === 'total' && isVisibleRowItem('locked')"
      >
        <v-col class="item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize">
            {{ $t("locked") }}
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 days-container">
          <v-row class="pa-0 ma-0 days-wrapper" :style="styleRow">
            <v-col
              v-for="(col, index) of rowLocked.otb"
              :key="index"
              class="pa-0 kpi-cell d-flex align-center justify-center"
              :class="bgCellClass(index)"
              @mouseover="kpi_hover = index"
              @mouseleave="kpi_hover = ''"
            >
              <a-kpi class="mr-1" :kpi="'rn'" :value="col" x_small></a-kpi>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="total item-wrapper pa-0">
          <div
            class="item-label d-flex align-center text-capitalize align-center justify-center"
          >
            <a-kpi
              class="mr-1"
              kpi="rn"
              :value="total.locked.otb"
              x_small
            ></a-kpi>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="row-item px-3"
        v-if="label !== 'total' && isVisibleRowItem('mlos')"
      >
        <v-col class="item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize">
            {{ $t("MLOS") }}
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 days-container">
          <v-row class="pa-0 ma-0 days-wrapper" :style="styleRow">
            <v-col
              v-for="(col, index) of rowMlos.otb"
              :key="index"
              class="pa-0 kpi-cell d-flex align-center justify-center"
              :class="bgCellClass(index)"
              @mouseover="kpi_hover = index"
              @mouseleave="kpi_hover = ''"
            >
              <v-avatar color="orange" size="30" v-if="highlightMlos(col)">
                <a-kpi
                  class="white--text"
                  :kpi="'mlos'"
                  :value="col"
                  x_small
                ></a-kpi>
              </v-avatar>
              <a-kpi
                v-else
                class="mr-1"
                :kpi="'mlos'"
                :value="col"
                x_small
              ></a-kpi>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="total item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize"></div>
        </v-col>
      </v-row>
      <v-row class="row-item px-3" v-if="isVisibleRowItem('occupancy')">
        <v-col class="item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize">
            {{ $t("occupancy") }}
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 days-container">
          <v-row class="pa-0 ma-0 days-wrapper" :style="styleRow">
            <v-col
              v-for="(col, index) of rowOccupancy.otb"
              :key="index"
              class="pa-0 kpi-cell d-flex align-center justify-center"
              :class="bgCellClass(index)"
              @mouseover="kpi_hover = index"
              @mouseleave="kpi_hover = ''"
            >
              <a-kpi
                class="mr-1"
                :class="occupancyColor(index)"
                :kpi="'occupancy'"
                :value="col"
                x_small
              ></a-kpi>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="total item-wrapper pa-0">
          <div
            class="item-label d-flex align-center text-capitalize align-center justify-center"
          >
            <a-kpi
              class="mr-1"
              kpi="occupancy"
              :value="total.occupancy.otb"
              x_small
            ></a-kpi>
          </div>
        </v-col>
      </v-row>

      <v-row
        class="row-item px-3"
        v-if="label !== 'total' && isVisibleRowItem('pvp')"
      >
        <v-col class="item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize">
            {{ $t("PVP") }}
          </div>
        </v-col>
        <v-col class="pa-0 ma-0 days-container">
          <v-row class="pa-0 ma-0 days-wrapper" :style="styleRow">
            <v-col
              v-for="(col, index) of rowPvp.otb"
              :key="index"
              class="pa-0 kpi-cell d-flex align-center justify-center"
              :class="bgCellClass(index)"
              @mouseover="kpi_hover = index"
              @mouseleave="kpi_hover = ''"
            >
              <a-kpi class="mr-1" :kpi="'pvp'" :value="col" x_small></a-kpi>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="total item-wrapper pa-0">
          <div class="item-label d-flex align-center text-capitalize"></div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi.vue";
import * as Utils from "@/utils";

export default {
  name: "a-rms-strategy-availability-row",
  components: {
    aKpi
  },
  props: {
    columns: Array,
    rowData: Object,
    rowIndex: Number,
    label: String,
    vs_pickup: String,
    only_rooms: Boolean,
    page: Number,
    days_per_screen: Number,
    total_days: Number,
    total: Object
  },
  data: () => ({
    min_occupancy: 30,
    max_occupancy: 70,
    limit_mlos: 2
  }),
  computed: {
    labels() {
      return Object.keys(this.rowData);
    },
    rows() {
      return this.rowData;
    },
    rowAvailable() {
      return this.rowData.free ? this.rowData.free : {};
    },
    rowLocked() {
      return this.rowData.locked ? this.rowData.locked : {};
    },
    rowMlos() {
      return this.rowData.mlos ? this.rowData.mlos : {};
    },
    rowOccupancy() {
      return this.rowData.occupancy ? this.rowData.occupancy : {};
    },
    rowPvp() {
      return this.rowData.pvp ? this.rowData.pvp : {};
    },
    kpi_hover: {
      get() {
        return this.$store.state.rms.strategy_date_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsStrategyDateHover", value);
      }
    },
    styleRow() {
      let width = (this.total_days / this.days_per_screen) * 100;
      if (width < 100) {
        width = 100;
      }
      return (
        "transform: translateX(calc(((-100%) * " +
        this.days_per_screen +
        " * " +
        this.page +
        ") / " +
        this.total_days +
        "));" +
        "width: " +
        width +
        "%"
      );
    },
    ...mapGetters({
      getHotelById: "getHotelById",
      roomtypes: "pmsRoomTypes",
      current_hotel: "current_hotel"
    })
  },
  methods: {
    getDayFromIndex(index) {
      return this.columns[index];
    },
    isWeekend(index) {
      return Vue.moment(this.getDayFromIndex(index)).isoWeekday() >= 5;
    },
    isClosed(index) {
      return this.rowAvailable && this.rowAvailable.otb[index] <= 0;
    },
    bgCellClass(index) {
      let classes = [];
      if (this.isWeekend(index)) {
        classes.push("weekend");
      }
      if (this.isClosed(index)) {
        classes.push("closed");
      }
      return classes.join(" ");
    },
    highlightMlos(v) {
      return v >= this.limit_mlos;
    },
    occupancyColor(index) {
      let min = this.min_occupancy;
      let max = this.max_occupancy;
      if (this.current_hotel.settings.ranges_occ_availability) {
        min = this.current_hotel.settings.ranges_occ_availability.min;
        max = this.current_hotel.settings.ranges_occ_availability.max;
      }
      return this.rowOccupancy.otb[index] < min
        ? "red--text text--darken-2"
        : this.rowOccupancy.otb[index] > max
        ? "green--text"
        : "";
    },
    vs_values(col) {
      return col.vs.map(v => this.rowData[v]);
    },
    classCol(col) {
      let hide = false;
      let clas = "pa-0 kpi-cell kpi-cell-row align-center justify-center";
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (col.value === "pickup_1" || col.value === "pickup_2") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        if (col.value === "pickup_3") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.xs) {
        if (col.value === "pickup_4") {
          hide = true;
        }
      }
      if (hide) {
        clas += " d-none";
      } else {
        clas += " d-flex";
      }
      return clas;
    },
    isVisibleRowItem(kpi) {
      if (this.only_rooms && (kpi === "rn" || kpi === "locked")) {
        return true;
      } else if (!this.only_rooms) {
        return true;
      }
      return false;
    },
    roomtype_title(code) {
      if (code === "total") {
        return this.$t("total");
      }
      return Utils.getDimensionName(this.roomtypes, code);
    },
    roomtype_color(code) {
      return Utils.getDimensionColor(this.roomtypes, code);
    }
  }
};
</script>
<style lang="scss" scoped>
.row-item {
  &:hover {
    .item-wrapper {
      .item-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  .item-wrapper {
    min-width: 200px;
    max-width: 200px;
    .item-label {
      border-left: $borderType $borderColorV;
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
    }
  }
  .total {
    min-width: 100px !important;
    max-width: 100px !important;
    z-index: 1;
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &.weekend {
      background: rgba(219, 198, 77, 0.1);
    }
    &.closed {
      background: rgba(219, 98, 77, 0.1);
    }
    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
.days-container {
  overflow: hidden;
  .days-wrapper {
    transition: transform 0.2s;
    transition-timing-function: ease;
  }
}
</style>
