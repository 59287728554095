<template>
  <v-row>
    <v-col cols="12" md="3">
      <a-card-kpi-icon
        :value="rms.strategy_range_kpis.otb.room_revenue"
        :vs_values="[
          rms.strategy_range_kpis.stly.room_revenue,
          rms.strategy_range_kpis.ly.room_revenue
        ]"
        :periods="['otb', 'stly', 'ly']"
        kpi="room_revenue"
        label="revenue"
        icon="mdi-cash-multiple"
        icon_circle
      ></a-card-kpi-icon>
    </v-col>
    <v-col cols="12" md="3">
      <a-card-kpi-icon
        :value="rms.strategy_range_kpis.otb.occupancy"
        :vs_values="[
          rms.strategy_range_kpis.stly.occupancy,
          rms.strategy_range_kpis.bud.occupancy
        ]"
        :periods="['otb', 'stly', 'bud']"
        kpi="occupancy"
        label="occupancy"
        icon="mdi-home-percent-outline"
        icon_circle
      ></a-card-kpi-icon>
    </v-col>
    <v-col cols="12" md="3">
      <a-card-kpi-icon
        :value="rms.strategy_range_kpis.otb.adr"
        :vs_values="[
          rms.strategy_range_kpis.stly.adr,
          rms.strategy_range_kpis.bud.adr
        ]"
        :periods="['otb', 'stly', 'bud']"
        kpi="adr"
        label="adr"
        icon="mdi-tag-outline"
        icon_circle
      ></a-card-kpi-icon>
    </v-col>
    <v-col cols="12" md="3">
      <a-card-kpi-icon
        :value="rms.strategy_range_kpis.otb.revpar"
        :vs_values="[
          rms.strategy_range_kpis.stly.revpar,
          rms.strategy_range_kpis.bud.revpar
        ]"
        :periods="['otb', 'stly', 'bud']"
        kpi="revpar"
        label="revpar"
        icon="mdi-hand-coin-outline"
        icon_circle
      ></a-card-kpi-icon>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import aCardKpiIcon from "@/components/rms_ui/aCardKpiIcon";

export default {
  name: "a-rms-strategy-weeks-resume-kpis",
  components: {
    aCardKpiIcon
  },
  computed: {
    ...mapState({
      rms: state => state.rms
    })
  }
};
</script>
