<template>
  <div>
    <v-text-field
      class=""
      block
      v-model="search"
      rounded
      outlined
      dense
      clearable
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('search_hotel')"
    ></v-text-field>
    <v-data-table
      v-if="calendar_store"
      :headers="hotel_calendar_subscriptions_headers"
      :items="available_hotels"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-switch
          :input-value="isSubscribed(item.id)"
          inset
          @change="toggleSubscription(item.id)"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import * as Utils from "@/utils.js";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    search: ""
  }),
  props: {
    calendar: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  filters: {},
  watch: {},
  methods: {
    getCalendars() {
      this.$emit("getCalendars");
    },
    isSubscribed(hotel_id) {
      return this.calendar.subscribed_hotels.find(h => h === hotel_id)
        ? true
        : false;
    },
    toggleSubscription(hotel_id) {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_hotel_subscription"),
        color: "secondary"
      });

      if (this.isSubscribed(hotel_id)) {
        this.$store
          .dispatch("unsubscribeHotelCalendar", {
            calendar_id: this.calendar.id,
            hotel_id: hotel_id
          })
          .then(() => {
            this.$store.dispatch("setSnackbar", {
              title: this.$t("hotel_unsubscribed"),
              color: "accent"
            });
            this.getCalendars();
          })
          .catch(() => {
            Utils.showError(this, "suscription_not_modified");
          });
      } else {
        this.$store
          .dispatch("subscribeHotelCalendar", {
            calendar_id: this.calendar.id,
            hotel_id: hotel_id
          })
          .then(() => {
            this.$store.dispatch("setSnackbar", {
              title: this.$t("hotel_subscribed"),
              color: "accent"
            });
            this.getCalendars();
          })
          .catch(() => {
            Utils.showError(this, "suscription_not_modified");
          });
      }
    }
  },
  computed: {
    filteredEvents() {
      let events = this.events;
      if (this.search && this.search.length > 0) {
        events = events.filter(e =>
          e.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return events;
    },
    available_hotels() {
      let hotels = this.hotels.filter(h => h.children_number === 0);
      if (this.search && this.search.length > 0) {
        hotels = hotels.filter(e =>
          e.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return hotels;
    },
    hotel_calendar_subscriptions_headers() {
      return [
        {
          text: this.$t("hotel"),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: null,
          align: "left",
          sortable: false,
          value: "actions"
        }
      ];
    },
    ...mapState({
      calendar_store: state => state.calendar,
      hotel_events: state => state.calendar.hotel_events,
      hotels: state => state.hotel.hotels
    }),
    ...mapGetters({
      getHotelCalendars: "getHotelCalendars",
      current_hotel: "current_hotel"
    })
  }
};
</script>
