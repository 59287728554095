import { render, staticRenderFns } from "./BudgetKpis.vue?vue&type=template&id=9d12f44e&scoped=true"
import script from "./BudgetKpis.vue?vue&type=script&lang=js"
export * from "./BudgetKpis.vue?vue&type=script&lang=js"
import style0 from "./BudgetKpis.vue?vue&type=style&index=0&id=9d12f44e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d12f44e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VIcon})
