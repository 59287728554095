<template>
  <zingchart
    :height="height"
    :width="width"
    :data="graph_settings"
    :series="graph_series"
  ></zingchart>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-reputation-daily-rating-graph",
  components: {},
  props: {
    height: {
      type: [String, Number],
      default: "100%"
    },
    width: {
      type: [String, Number],
      default: "auto"
    }
  },
  data: vm => ({}),
  methods: {},
  computed: {
    max_value() {
      var all_values = [];
      for (var ota of this.otas) {
        all_values = [...all_values, ...this.otas_values[ota.code]];
      }
      return all_values.length > 0 ? Math.max(...all_values) + 1 : 100;
    },
    min_value() {
      var all_values = [];
      for (var ota of this.otas) {
        all_values = [...all_values, ...this.otas_values[ota.code]];
      }
      return all_values.length > 0 ? Math.min(...all_values) - 0.2 : 0;
    },
    otas_values() {
      var otas_values = {};
      for (var ota of this.otas) {
        var values = [];
        for (var day_report of this.bi_daily_report.daily_list) {
          var v = parseFloat(day_report[ota.code + "_rating"]);
          if (!Number.isNaN(v)) {
            values.push(Math.round(v * 100) / 100);
          }
        }
        otas_values[ota.code] = values;
      }
      return otas_values;
    },
    graph_settings() {
      var tooltip = this.chart_settings.tooltip;
      tooltip.align = "left";
      tooltip.lineHeight = "21px";
      return {
        type: "mixed",
        backgroundColor: "transparent",
        tooltip: {
          visible: false
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        scaleX: {
          visible: false,
          values: this.current_period_items
        },
        scaleY: {
          visible: false,
          values: this.min_value + ":" + this.max_value
        },
        plot: {
          aspect: "spline"
        },
        plotarea: {
          margin: "0px 0px 0px 0px"
        },
        crosshairX: {
          plotLabel: tooltip,
          lineColor: "#cacaca",
          lineWidth: 2,
          lineStyle: "dashed",
          marker: {
            backgroundColor: "#ffffff",
            size: 4,
            borderWidth: 2,
            borderColor: "#444444",
            opacity: "0.8",
            shadow: 2
          }
        }
      };
    },
    graph_series() {
      var items = [];
      var first = true;

      var all_values = [];
      for (var ota of this.otas) {
        var text =
          "<span style='font-size:13px;font-weight:bold;background-color:%color; color:white;border-radius:15px; padding: 2px 2px;'>%v%</span> <span style='font-size:13px; color: #333333;'>" +
          this.$t(ota.code) +
          "</span>";
        if (first) {
          first = false;
          text =
            "<span style='color: #666666;font-size:13px;font-weight:bold;'>%kt </span><br><span style='font-size:13px;font-weight:bold;background-color:%color; color:white;border-radius:15px; padding: 2px 2px;'>%v%</span> <span style='font-size:13px; color: #333333;'>" +
            this.$t(ota.code) +
            "</span>";
        }
        items.push({
          scales: "scale-x,scale-y",
          type: "line",
          aspect: "spline",
          values: this.otas_values[ota.code],
          text: this.$t(ota.code),
          lineWidth: "2px",
          lineColor: ota.color,
          shadow: 1,
          marker: {
            visible: "false"
          },
          palette: 1,
          visible: 1,
          guideLabel: {
            text: text,
            thousandsSeparator: ".",
            decimalsSeparator: ","
          }
        });
      }
      return items;
    },
    values() {
      return this.current_period_items;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      otas: state => state.otas,
      bi_daily_report: state => state.bi.daily_report
    }),
    ...mapGetters({
      current_period_items: "current_period_items"
    })
  }
};
</script>
