<template>
  <a-page
    id="user-management-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-select
        v-model="environment"
        :items="['dev', 'prod']"
        label="Environment"
        mandatory
        rounded
        outlined
        flat
        height="20px"
        single-line
        style="max-width: 150px;"
        class="mt-8"
      ></v-select>
      <v-select
        v-model="customer_name"
        :items="customers"
        label="Customer"
        mandatory
        rounded
        outlined
        flat
        height="20px"
        single-line
        style="max-width: 150px;"
        class="mt-8"
      ></v-select>
      <v-tooltip bottom dark color="#333333">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-2"
            href="https://documenter.getpostman.com/view/1508314/TzY3BFz8#intro"
            target="_blank"
            icon
            v-on="on"
          >
            <v-icon>mdi-notebook</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("see_documentation") }}
        </span>
      </v-tooltip>
    </template>
    <!-- PAGE HEADER -->
    <!-- KPIS -->
    <div
      class="d-flex justify-center align-center text-center white--text mt-4 mb-12"
      style="position:relative"
    >
      <div class="">
        <span class="text-h4 d-block">GHA Real Time Api</span>
        <div class="d-flex">
          <div class="px-10" v-if="customer">
            <span class="text-h1 d-block bold"
              >{{ getCurrentProcessingRequests("real-time-api")
              }}<small class="text-h3"
                >/ {{ getServersReady("real-time-api") }}</small
              ></span
            >
            <span class="text-h5 d-block subtitle">{{
              $t("requests_now")
            }}</span>
          </div>
          <div class="px-10" v-if="customer">
            <span class="text-h1 d-block"
              >{{ getServersReady("real-time-api")
              }}<small class=" text-h3"
                >/ {{ getServers("real-time-api").length }}</small
              ></span
            >
            <span class="text-h5 d-block">{{ $t("ready_servers") }}</span>
          </div>
        </div>
      </div>
      <div class="">
        <span class="text-h4 d-block">Kayak Real Time Api</span>
        <div class="d-flex">
          <div class="px-10" v-if="customer">
            <span class="text-h1 d-block bold"
              >{{ getCurrentProcessingRequests("real-time-api-kayak")
              }}<small class="text-h3"
                >/ {{ getServersReady("real-time-api-kayak") }}</small
              ></span
            >
            <span class="text-h5 d-block subtitle">{{
              $t("requests_now")
            }}</span>
          </div>
          <div class="px-10" v-if="customer">
            <span class="text-h1 d-block"
              >{{ getServersReady("real-time-api-kayak")
              }}<small class=" text-h3"
                >/ {{ getServers("real-time-api-kayak").length }}</small
              ></span
            >
            <span class="text-h5 d-block">{{ $t("ready_servers") }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end PAGE HEADER -->

    <!-- LIST -->
    <v-tabs class="mb-0" background-color="transparent" v-model="tab" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        {{ $t("servers") }}
      </v-tab>
      <v-tab>
        {{ $t("rta_api") }}
      </v-tab>
      <v-tab>
        {{ $t("rta_kayak_api") }}
      </v-tab>
      <v-tab>
        {{ $t("rate_api") }}
      </v-tab>
    </v-tabs>
    <v-card>
      <v-tabs-items v-model="tab">
        <!-- Servers -->
        <v-tab-item>
          <v-card-text>
            <v-card-title
              class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
            >
              {{ $t("rta_server_status") }}
              <v-spacer></v-spacer>
              <template v-if="connect_api_status">
                {{ getServerTime(connect_api_launch_time) }}
              </template>
            </v-card-title>
            <v-data-table
              v-if="getServers('real-time-api')"
              :headers="servers_headers"
              :items="getServers('real-time-api')"
              hide-default-footer
              disable-pagination
              class="elevation-0"
            >
              <template v-slot:[`item.started_time`]="{ item }">
                {{ getServerTime(item.launch_time) }} <br />
                <span class="grey--text">{{
                  getApiTime(item.started_time)
                }}</span>
              </template>
              <template v-slot:[`item.free_memory`]="{ item }">
                <span :class="getColorMemory(item)">{{ getMemory(item) }}</span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  outlined
                  :color="item.status == 'stopped' ? 'error' : 'info'"
                  >{{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.browser_status`]="{ item }">
                <v-chip
                  small
                  outlined
                  :color="
                    item.browser_status == 'stopped'
                      ? 'error'
                      : item.browser_status == 'loading'
                      ? 'orange'
                      : 'info'
                  "
                  >{{ item.browser_status }}
                </v-chip>
              </template>
              <template v-slot:[`item.ec2_instance_id`]="{ item }">
                <a
                  @click="
                    filtered_servers = [item.ec2_instance_id];
                    tab = 1;
                  "
                  ><b>{{ item.ec2_instance_id }}</b></a
                >
                <span class="grey--text"> {{ item.ami_id }}</span
                ><br />{{ item.ec2_instance_ip }}
                <span
                  v-if="getReplacementInstance(item.ec2_instance_id)"
                  class="orange--text"
                  >{{ getReplacementInstance(item.ec2_instance_id) }}</span
                >
              </template>
              <template v-slot:[`item.is_processing_request`]="{ item }">
                <v-chip v-if="item.is_processing_request" small color="info">{{
                  $t("yes")
                }}</v-chip>
                <v-chip v-else outlined small color="grey">{{
                  $t("no")
                }}</v-chip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text>
            <v-card-title
              class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
            >
              {{ $t("rta_kayak_server_status") }}
              <v-spacer></v-spacer>
              <template v-if="connect_api_status">
                {{ getServerTime(connect_api_launch_time) }}
              </template>
            </v-card-title>
            <v-data-table
              v-if="getServers('real-time-api-kayak')"
              :headers="servers_headers"
              :items="getServers('real-time-api-kayak')"
              hide-default-footer
              disable-pagination
              class="elevation-0"
            >
              <template v-slot:[`item.started_time`]="{ item }">
                {{ getServerTime(item.launch_time) }} <br />
                <span class="grey--text">{{
                  getApiTime(item.started_time)
                }}</span>
              </template>
              <template v-slot:[`item.free_memory`]="{ item }">
                <span :class="getColorMemory(item)">{{ getMemory(item) }}</span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  outlined
                  :color="item.status == 'stopped' ? 'error' : 'info'"
                  >{{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.browser_status`]="{ item }">
                <v-chip
                  small
                  outlined
                  :color="
                    item.browser_status == 'stopped'
                      ? 'error'
                      : item.browser_status == 'loading'
                      ? 'orange'
                      : 'info'
                  "
                  >{{ item.browser_status }}
                </v-chip>
              </template>
              <template v-slot:[`item.ec2_instance_id`]="{ item }">
                <a
                  @click="
                    filtered_servers = [item.ec2_instance_id];
                    tab = 2;
                  "
                  ><b>{{ item.ec2_instance_id }}</b></a
                >
                <span class="grey--text"> {{ item.ami_id }}</span
                ><br />{{ item.ec2_instance_ip }}
                <span
                  v-if="getReplacementInstance(item.ec2_instance_id)"
                  class="orange--text"
                  >{{ getReplacementInstance(item.ec2_instance_id) }}</span
                >
              </template>
              <template v-slot:[`item.is_processing_request`]="{ item }">
                <v-chip v-if="item.is_processing_request" small color="info">{{
                  $t("yes")
                }}</v-chip>
                <v-chip v-else outlined small color="grey">{{
                  $t("no")
                }}</v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
        <!-- Real Time Api-->
        <v-tab-item class="grey lighten-4">
          <v-card-text v-if="connect_api_rta_statistics">
            <div class="d-flex justify-start"></div>
            <v-card-title
              class="text-h3 ml-0 pl-0 justify-center align-center mb-4 primary--text text--lighten-2 text-uppercase font-weight-bold"
            >
              <v-btn
                solo
                fab
                class="elevation-2 mr-2"
                color="white"
                @click="subtractDay"
                :disabled="loading_rta_statistics"
                small
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <v-menu
                v-model="request_date_menu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedRequestDate"
                    append-icon="mdi-calendar-month-outline"
                    solo
                    :disabled="loading_rta_statistics"
                    rounded
                    v-on="on"
                    style="width:290px; height:50px; flex: 0 0 auto;display: inline-flex; cursor:pointer;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="connect_api_date"
                  @change="changeRequestDate"
                  :allowed-dates="requestAllowedDate"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
              <v-btn
                solo
                fab
                class="elevation-2 ml-2"
                color="white"
                small
                @click="addDay"
                :disabled="loading_rta_statistics"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                solo
                fab
                class="elevation-2 mr-3"
                color="white"
                @click="reloadRta"
                small
                :loading="loading_rta_statistics"
                ><v-icon small>mdi-reload</v-icon></v-btn
              >
              {{ formatNumber(connect_api_rta_statistics.total_requests) }}
              <small
                class="ml-1 font-weight-regular text-body-1 align-self-end mb-1"
              >
                requests
              </small>
            </v-card-title>
            <v-card flat :loading="loading_rta_statistics">
              <v-tabs vertical>
                <div>
                  <v-tab>
                    <v-icon left> mdi-format-list-bulleted </v-icon>
                    <!-- {{ $t("last_requests") }} -->
                  </v-tab>
                  <v-tab>
                    <v-icon left> mdi-chart-donut </v-icon>
                    <!-- {{ $t("results") }} -->
                  </v-tab>
                  <v-tab>
                    <v-icon left> mdi-alert-circle-outline</v-icon>
                    <!-- {{ $t("errors") }} -->
                  </v-tab>
                </div>
                <v-tab-item>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          Efectividad total
                        </v-card-title>
                        <v-progress-linear
                          background-color="error"
                          color="success"
                          :value="result_percent.done_percent"
                          rounded
                          dark
                          height="40"
                        >
                          <template v-slot:default="{ value }">
                            <strong
                              >{{ formatNumber(parseFloat(value)) }}%</strong
                            >
                          </template>
                        </v-progress-linear>
                      </v-col>
                      <v-col cols="6">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          Efectividad última 100 llamadas
                        </v-card-title>
                        <v-progress-linear
                          background-color="error"
                          color="success"
                          :value="last_requests_done_percent"
                          rounded
                          dark
                          height="40"
                        >
                          <template v-slot:default="{ value }">
                            <strong
                              >{{ formatNumber(parseFloat(value)) }}%</strong
                            >
                          </template>
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="10">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          {{ $t("last_requests") }} {{ formatedRequestDate }}
                          <v-spacer></v-spacer>
                          <v-btn
                            solo
                            fab
                            class="elevation-2"
                            color="white"
                            @click="reloadRtaRequest"
                            small
                            :loading="loading_rta_request"
                            ><v-icon small>mdi-reload</v-icon></v-btn
                          >
                        </v-card-title>
                        <v-data-table
                          v-if="connect_api_rta_request"
                          :headers="connect_api_rta_headers"
                          :items="filtered_connect_api_rta_request"
                          hide-default-footer
                          disable-pagination
                          class="elevation-0"
                          :loading="loading_rta_request"
                        >
                          <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                              v-if="item.status == 'error'"
                              small
                              outlined
                              :color="
                                item.result.error_code == 9 ||
                                item.result.error_code == 3
                                  ? 'amber'
                                  : 'error'
                              "
                            >
                              <template
                                v-if="
                                  item.result.error_code == 9 ||
                                    item.result.error_code == 3
                                "
                              >
                                warning ({{ item.result.error_code }})
                              </template>
                              <template v-else>
                                error ({{ item.result.error_code }})
                              </template>
                            </v-chip>
                            <v-chip v-else small outlined color="info">{{
                              item.status
                            }}</v-chip>
                          </template>
                          <template v-slot:[`item.createdAt`]="{ item }">
                            <b>{{ item.params.hotel_id }}</b>
                            <br />
                            {{ getTime(item.createdAt) }}
                            <template v-if="item.cached && item.cached == true">
                              <v-chip x-small color="grey" dark>cached</v-chip>
                            </template>
                          </template>
                          <template
                            v-slot:[`item.result.total_time`]="{ item }"
                          >
                            <span
                              v-if="item.result && item.cached != true"
                              :class="getColorTime(item)"
                            >
                              {{ formatNumber(item.result.total_time) }}
                            </span>
                          </template>
                          <template
                            v-slot:[`item.result.total_otas_found`]="{ item }"
                          >
                            <v-avatar
                              v-if="item.result"
                              size="24"
                              class="white--text"
                              :color="getColorFromTotalRates(item)"
                              >{{ item.result.total_otas_found }}</v-avatar
                            >
                          </template>
                          <template v-slot:[`item.params.end_day`]="{ item }">
                            {{ getStayDays(item) }} {{ $t("days") }}
                          </template>
                          <template
                            v-slot:[`item.init_concurrent_requests`]="{ item }"
                          >
                            {{ item.init_concurrent_requests }} |
                            {{ item.end_concurrent_requests }}
                          </template>
                          <template v-slot:[`item.result.url`]="{ item }">
                            <v-btn
                              small
                              icon
                              :href="item.result ? item.result.url : ''"
                              target="_blank"
                              ><v-icon small>mdi-google</v-icon></v-btn
                            >
                            <v-btn
                              small
                              icon
                              :href="buildUrl(item)"
                              target="_blank"
                              ><v-icon small>mdi-api</v-icon></v-btn
                            >
                            <v-btn small icon @click="seeRequest(item)"
                              ><v-icon small>mdi-code-json</v-icon></v-btn
                            >
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          {{ $t("filters") }}
                          <v-spacer></v-spacer>
                          {{ filtered_connect_api_rta_requests }}
                        </v-card-title>
                        <v-switch
                          v-model="filtered_cache"
                          inset
                          label="Mostrar cacheadas"
                        ></v-switch>
                        <v-switch
                          v-model="filtered_justerrors"
                          inset
                          label="Solo errores"
                        ></v-switch>
                        <v-select
                          :label="$t('errors')"
                          v-model="filtered_errors"
                          :items="errors_list"
                          item-text="_id"
                          item-value="_id"
                          multiple
                          chips
                          deletable-chips
                          small-chips
                          persistent-hint
                        >
                        </v-select>
                        <v-select
                          :label="$t('servers')"
                          v-model="filtered_servers"
                          :items="getServers('real-time-api')"
                          item-text="ec2_instance_id"
                          item-value="ec2_instance_id"
                          multiple
                          chips
                          deletable-chips
                          small-chips
                          persistent-hint
                        >
                        </v-select>
                        <span class="d-block mb-8 grey--text"># Rates</span>
                        <v-range-slider
                          v-model="rates_range"
                          max="40"
                          min="0"
                          thumb-label="always"
                        ></v-range-slider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-card-title
                      class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                    >
                      Por día</v-card-title
                    >
                    <v-card-text
                      v-if="connect_api_rta_statistics"
                      class="d-flex justify-start align-center"
                    >
                      <div
                        v-for="day in connect_api_rta_statistics.requests_by_day"
                        :key="day._id"
                        class="mr-1"
                      >
                        <div>
                          <v-progress-circular
                            :rotate="-90"
                            :size="40"
                            :width="3"
                            :value="
                              (day.requests * 100) /
                                connect_api_rta_statistics.service.contract
                                  .daily_requests
                            "
                            :color="
                              day.requests /
                                connect_api_rta_statistics.service.contract
                                  .daily_requests >
                              1
                                ? 'danger'
                                : 'teal'
                            "
                            class="text-body-2"
                            :class="
                              isCurrentDay(day._id)
                                ? 'text--darken-4 font-weight-bold'
                                : ''
                            "
                          >
                            {{ formatNumber(day.requests) }}
                          </v-progress-circular>
                        </div>
                        <a
                          @click="gotToDay(day._id)"
                          class="text-subtitle-1 d-block grey--text font-weight-bold text-center"
                          :class="
                            isCurrentDay(day._id)
                              ? 'black--text text--darken-4'
                              : 'text--lighten-1'
                          "
                        >
                          {{ day._id }}
                        </a>
                      </div>
                    </v-card-text>
                    <v-card-title
                      class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                    >
                      Por día (sin cache)</v-card-title
                    >
                    <v-card-text
                      v-if="connect_api_rta_statistics"
                      class="d-flex justify-start align-center"
                    >
                      <div
                        v-for="day in connect_api_rta_statistics.requests_by_day"
                        :key="day._id"
                        class="mr-1"
                      >
                        <div>
                          <v-progress-circular
                            :rotate="-90"
                            :size="40"
                            :width="3"
                            :value="
                              (day.not_cached_requests * 100) /
                                connect_api_rta_statistics.service.contract
                                  .daily_requests
                            "
                            :color="
                              day.not_cached_requests /
                                connect_api_rta_statistics.service.contract
                                  .daily_requests >
                              1
                                ? 'danger'
                                : 'teal'
                            "
                            class="text-body-2"
                            :class="
                              isCurrentDay(day._id)
                                ? 'text--darken-4 font-weight-bold'
                                : ''
                            "
                          >
                            {{ formatNumber(day.not_cached_requests) }}
                          </v-progress-circular>
                        </div>
                        <a
                          @click="gotToDay(day._id)"
                          class="text-subtitle-1 d-block grey--text font-weight-bold text-center"
                          :class="
                            isCurrentDay(day._id)
                              ? 'black--text text--darken-4'
                              : 'text--lighten-1'
                          "
                        >
                          {{ day._id }}
                        </a>
                      </div>
                    </v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-card-title
                          class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                          >Por hora</v-card-title
                        >
                        <v-card-text>
                          <v-data-table
                            v-if="connect_api_rta_statistics"
                            :headers="requests_by_hour_headers"
                            :items="connect_api_rta_statistics.requests_by_hour"
                            class="elevation-0"
                            hide-default-footer
                            disable-pagination
                          ></v-data-table>
                        </v-card-text>
                      </v-col>
                      <v-col cols="4">
                        <v-card-title
                          class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                          >Por # tarifas</v-card-title
                        >
                        <v-card-text>
                          <v-data-table
                            v-if="connect_api_rta_statistics"
                            :headers="requests_by_otas_headers"
                            :items="
                              connect_api_rta_statistics.results_by_otas_found
                            "
                            class="elevation-0"
                            hide-default-footer
                            disable-pagination
                          ></v-data-table>
                        </v-card-text>
                      </v-col>
                      <v-col cols="4"
                        ><v-card-title
                          class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                          >Por adults&childs</v-card-title
                        >
                        <v-card-text>
                          <v-data-table
                            v-if="connect_api_rta_statistics"
                            :headers="requests_by_pax_headers"
                            :items="
                              connect_api_rta_statistics.request_by_adults_and_childs
                            "
                            class="elevation-0"
                            hide-default-footer
                            disable-pagination
                          >
                            <template v-slot:[`item.adults`]="{ item }">
                              {{ getAdults(item._id) }}
                            </template>
                            <template v-slot:[`item.childs`]="{ item }">
                              {{ getChilds(item._id) }}
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-card-title
                      class="grey--text text--lighten-2 text-uppercase font-weight-bold mt-0"
                      >Errores</v-card-title
                    >
                    <v-card-text>
                      <v-data-table
                        v-if="connect_api_rta_statistics"
                        :headers="results_by_error_type_headers"
                        :items="errors_list"
                        class="elevation-0"
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:[`item.percent`]="{ item }">
                          {{ getErrorPercent(item.count) }}%
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card-text>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-card-text>
        </v-tab-item>
        <!-- Real Time Api Kayak -->
        <v-tab-item class="grey lighten-4">
          <v-card-text v-if="connect_api_rta_kayak_statistics">
            <div class="d-flex justify-start"></div>
            <v-card-title
              class="text-h3 ml-0 pl-0 justify-center align-center mb-4 primary--text text--lighten-2 text-uppercase font-weight-bold"
            >
              <v-btn
                solo
                fab
                class="elevation-2 mr-2"
                color="white"
                @click="subtractDay"
                :disabled="loading_rta_kayak_statistics"
                small
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <v-menu
                v-model="request_date_menu_kayak"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedRequestDate"
                    append-icon="mdi-calendar-month-outline"
                    solo
                    :disabled="loading_rta_kayak_statistics"
                    rounded
                    v-on="on"
                    style="width:290px; height:50px; flex: 0 0 auto;display: inline-flex; cursor:pointer;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="connect_api_date"
                  @change="changeRequestDate"
                  :allowed-dates="requestAllowedDate"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
              <v-btn
                solo
                fab
                class="elevation-2 ml-2"
                color="white"
                small
                @click="addDay"
                :disabled="loading_rta_kayak_statistics"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                solo
                fab
                class="elevation-2 mr-3"
                color="white"
                @click="reloadRta"
                small
                :loading="loading_rta_kayak_statistics"
                ><v-icon small>mdi-reload</v-icon></v-btn
              >
              {{
                formatNumber(connect_api_rta_kayak_statistics.total_requests)
              }}
              <small
                class="ml-1 font-weight-regular text-body-1 align-self-end mb-1"
              >
                requests
              </small>
            </v-card-title>
            <v-card flat :loading="loading_rta_kayak_statistics">
              <v-tabs vertical>
                <div>
                  <v-tab>
                    <v-icon left> mdi-format-list-bulleted </v-icon>
                    <!-- {{ $t("last_requests") }} -->
                  </v-tab>
                  <v-tab>
                    <v-icon left> mdi-chart-donut </v-icon>
                    <!-- {{ $t("results") }} -->
                  </v-tab>
                  <v-tab>
                    <v-icon left> mdi-alert-circle-outline</v-icon>
                    <!-- {{ $t("errors") }} -->
                  </v-tab>
                </div>
                <v-tab-item>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          Efectividad total
                        </v-card-title>
                        <v-progress-linear
                          background-color="error"
                          color="success"
                          :value="result_percent.done_percent"
                          rounded
                          dark
                          height="40"
                        >
                          <template v-slot:default="{ value }">
                            <strong
                              >{{ formatNumber(parseFloat(value)) }}%</strong
                            >
                          </template>
                        </v-progress-linear>
                      </v-col>
                      <v-col cols="6">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          Efectividad última 100 llamadas
                        </v-card-title>
                        <v-progress-linear
                          background-color="error"
                          color="success"
                          :value="last_requests_done_percent"
                          rounded
                          dark
                          height="40"
                        >
                          <template v-slot:default="{ value }">
                            <strong
                              >{{ formatNumber(parseFloat(value)) }}%</strong
                            >
                          </template>
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="10">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          {{ $t("last_requests") }} {{ formatedRequestDate }}
                          <v-spacer></v-spacer>
                          <v-btn
                            solo
                            fab
                            class="elevation-2"
                            color="white"
                            @click="reloadRtaRequest"
                            small
                            :loading="loading_rta_kayak_request"
                            ><v-icon small>mdi-reload</v-icon></v-btn
                          >
                        </v-card-title>
                        <v-data-table
                          v-if="connect_api_rta_request"
                          :headers="connect_api_rta_kayak_headers"
                          :items="filtered_connect_api_rta_kayak_request"
                          hide-default-footer
                          disable-pagination
                          class="elevation-0"
                          :loading="loading_rta_kayak_request"
                        >
                          <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                              v-if="item.status == 'error'"
                              small
                              outlined
                              :color="
                                item.result.error_code == 9 ||
                                item.result.error_code == 3
                                  ? 'amber'
                                  : 'error'
                              "
                            >
                              <template
                                v-if="
                                  item.result.error_code == 9 ||
                                    item.result.error_code == 3
                                "
                              >
                                warning ({{ item.result.error_code }})
                              </template>
                              <template v-else>
                                error ({{ item.result.error_code }})
                              </template>
                            </v-chip>
                            <v-chip v-else small outlined color="info">{{
                              item.status
                            }}</v-chip>
                          </template>
                          <template v-slot:[`item.createdAt`]="{ item }">
                            <b>{{ item.params.origin }}</b> -
                            <b>{{ item.params.destination }}</b>
                            <br />
                            {{ getTime(item.createdAt) }}
                            <template v-if="item.cached && item.cached == true">
                              <v-chip x-small color="grey" dark>cached</v-chip>
                            </template>
                          </template>
                          <template
                            v-slot:[`item.result.times.total`]="{ item }"
                          >
                            <span
                              v-if="item.result && item.cached != true"
                              :class="getColorTimeKayak(item)"
                            >
                              {{
                                item.result && item.result.times
                                  ? formatNumber(item.result.times.total)
                                  : ""
                              }}
                            </span>
                          </template>
                          <template
                            v-slot:[`item.result.total_otas_found`]="{ item }"
                          >
                            <v-avatar
                              v-if="item.result"
                              size="24"
                              class="white--text"
                              :color="getColorFromTotalRates(item)"
                              >{{ item.result.total_otas_found }}</v-avatar
                            >
                          </template>
                          <!-- <template v-slot:[`item.params.end_day`]="{ item }">
                            {{ getStayDays(item) }} {{ $t("days") }}
                          </template> -->
                          <template
                            v-slot:[`item.init_concurrent_requests`]="{ item }"
                          >
                            {{ item.init_concurrent_requests }} |
                            {{ item.end_concurrent_requests }}
                          </template>
                          <template v-slot:[`item.result.url`]="{ item }">
                            <v-btn
                              small
                              icon
                              :href="item.result ? item.result.url : ''"
                              target="_blank"
                              ><v-icon small>mdi-airplane</v-icon></v-btn
                            >
                            <v-btn
                              small
                              icon
                              :href="buildKayakUrl(item)"
                              target="_blank"
                              ><v-icon small>mdi-api</v-icon></v-btn
                            >
                            <v-btn small icon @click="seeRequest(item)"
                              ><v-icon small>mdi-code-json</v-icon></v-btn
                            >
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-card-title
                          class="ml-0 pl-0 grey--text text--lighten-2 text-uppercase font-weight-bold"
                        >
                          {{ $t("filters") }}
                          <v-spacer></v-spacer>
                          {{ filtered_connect_api_rta_kayak_requests }}
                        </v-card-title>
                        <v-switch
                          v-model="filtered_cache"
                          inset
                          label="Mostrar cacheadas"
                        ></v-switch>
                        <v-switch
                          v-model="filtered_justerrors"
                          inset
                          label="Solo errores"
                        ></v-switch>
                        <v-select
                          :label="$t('errors')"
                          v-model="filtered_errors"
                          :items="errors_list"
                          item-text="_id"
                          item-value="_id"
                          multiple
                          chips
                          deletable-chips
                          small-chips
                          persistent-hint
                        >
                        </v-select>
                        <v-select
                          :label="$t('servers')"
                          v-model="filtered_servers"
                          :items="getServers('real-time-api-kayak')"
                          item-text="ec2_instance_id"
                          item-value="ec2_instance_id"
                          multiple
                          chips
                          deletable-chips
                          small-chips
                          persistent-hint
                        >
                        </v-select>
                        <span class="d-block mb-8 grey--text"># Rates</span>
                        <v-range-slider
                          v-model="rates_range"
                          max="40"
                          min="0"
                          thumb-label="always"
                        ></v-range-slider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-card-title
                      class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                    >
                      Por día</v-card-title
                    >
                    <v-card-text
                      v-if="connect_api_rta_kayak_statistics"
                      class="d-flex justify-start align-center"
                    >
                      <div
                        v-for="day in connect_api_rta_kayak_statistics.requests_by_day"
                        :key="day._id"
                        class="mr-1"
                      >
                        <div>
                          <v-progress-circular
                            :rotate="-90"
                            :size="40"
                            :width="3"
                            :value="
                              (day.requests * 100) /
                                connect_api_rta_kayak_statistics.service
                                  .contract.daily_requests
                            "
                            :color="
                              day.requests /
                                connect_api_rta_kayak_statistics.service
                                  .contract.daily_requests >
                              1
                                ? 'danger'
                                : 'teal'
                            "
                            class="text-body-2"
                            :class="
                              isCurrentDay(day._id)
                                ? 'text--darken-4 font-weight-bold'
                                : ''
                            "
                          >
                            {{ formatNumber(day.requests) }}
                          </v-progress-circular>
                        </div>
                        <a
                          @click="gotToDay(day._id)"
                          class="text-subtitle-1 d-block grey--text font-weight-bold text-center"
                          :class="
                            isCurrentDay(day._id)
                              ? 'black--text text--darken-4'
                              : 'text--lighten-1'
                          "
                        >
                          {{ day._id }}
                        </a>
                      </div>
                    </v-card-text>
                    <v-card-title
                      class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                    >
                      Por día (sin cache)</v-card-title
                    >
                    <v-card-text
                      v-if="connect_api_rta_kayak_statistics"
                      class="d-flex justify-start align-center"
                    >
                      <div
                        v-for="day in connect_api_rta_kayak_statistics.requests_by_day"
                        :key="day._id"
                        class="mr-1"
                      >
                        <div>
                          <v-progress-circular
                            :rotate="-90"
                            :size="40"
                            :width="3"
                            :value="
                              (day.not_cached_requests * 100) /
                                connect_api_rta_kayak_statistics.service
                                  .contract.daily_requests
                            "
                            :color="
                              day.not_cached_requests /
                                connect_api_rta_kayak_statistics.service
                                  .contract.daily_requests >
                              1
                                ? 'danger'
                                : 'teal'
                            "
                            class="text-body-2"
                            :class="
                              isCurrentDay(day._id)
                                ? 'text--darken-4 font-weight-bold'
                                : ''
                            "
                          >
                            {{ formatNumber(day.not_cached_requests) }}
                          </v-progress-circular>
                        </div>
                        <a
                          @click="gotToDay(day._id)"
                          class="text-subtitle-1 d-block grey--text font-weight-bold text-center"
                          :class="
                            isCurrentDay(day._id)
                              ? 'black--text text--darken-4'
                              : 'text--lighten-1'
                          "
                        >
                          {{ day._id }}
                        </a>
                      </div>
                    </v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-card-title
                          class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                          >Por hora</v-card-title
                        >
                        <v-card-text>
                          <v-data-table
                            v-if="connect_api_rta_kayak_statistics"
                            :headers="requests_by_hour_headers"
                            :items="
                              connect_api_rta_kayak_statistics.requests_by_hour
                            "
                            class="elevation-0"
                            hide-default-footer
                            disable-pagination
                          ></v-data-table>
                        </v-card-text>
                      </v-col>
                      <v-col cols="4">
                        <v-card-title
                          class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                          >Por # tarifas</v-card-title
                        >
                        <v-card-text>
                          <v-data-table
                            v-if="connect_api_rta_kayak_statistics"
                            :headers="requests_by_otas_headers"
                            :items="
                              connect_api_rta_kayak_statistics.results_by_otas_found
                            "
                            class="elevation-0"
                            hide-default-footer
                            disable-pagination
                          ></v-data-table>
                        </v-card-text>
                      </v-col>
                      <v-col cols="4"
                        ><v-card-title
                          class="grey--text text--lighten-2 text-uppercase font-weight-bold"
                          >Por Ruta</v-card-title
                        >
                        <v-card-text>
                          <v-data-table
                            v-if="connect_api_rta_kayak_statistics"
                            :headers="requests_by_route_headers"
                            :items="
                              connect_api_rta_kayak_statistics.requests_by_route
                            "
                            class="elevation-0"
                            hide-default-footer
                            disable-pagination
                          >
                            <template v-slot:[`item.origin`]="{ item }">
                              {{ item._id.origin }}
                            </template>
                            <template v-slot:[`item.destination`]="{ item }">
                              {{ item._id.destination }}
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-card-title
                      class="grey--text text--lighten-2 text-uppercase font-weight-bold mt-0"
                      >Errores</v-card-title
                    >
                    <v-card-text>
                      <v-data-table
                        v-if="connect_api_rta_kayak_statistics"
                        :headers="results_by_error_type_headers"
                        :items="errors_list"
                        class="elevation-0"
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:[`item.percent`]="{ item }">
                          {{ getErrorPercentKayak(item.count) }}%
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card-text>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-card-text>
        </v-tab-item>
        <!-- Rate Api -->
        <v-tab-item>
          <v-card-text v-if="connect_api_rate_request">
            <v-card-title
              class="text-h3 ml-0 pl-0 justify-center align-center mb-4 primary--text text--lighten-2 text-uppercase font-weight-bold"
            >
              <v-btn
                solo
                fab
                class="elevation-2 mr-2"
                color="white"
                @click="subtractDay"
                :disabled="loading_rta_statistics"
                small
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <v-menu
                v-model="request_date_menu_2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatedRequestDate"
                    append-icon="mdi-calendar-month-outline"
                    solo
                    rounded
                    v-on="on"
                    class="mr-2"
                    style="width:290px; height:50px; flex: 0 0 auto;display: inline-flex; cursor:pointer;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="connect_api_date"
                  @change="changeRequestDate"
                  :allowed-dates="requestAllowedDate"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>

              <v-btn
                solo
                fab
                class="elevation-2 ml-2"
                color="white"
                small
                @click="addDay"
                :disabled="loading_rta_statistics"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >

              <v-spacer></v-spacer>
              {{ formatNumber(connect_api_rate_request.length) }}
              <small
                class="ml-1 font-weight-regular text-body-1 align-self-end mb-1"
              >
                requests
              </small>
            </v-card-title>
            <v-data-table
              v-if="connect_api_rate_request"
              :headers="connect_api_rate_headers"
              :items="connect_api_rate_request"
              hide-default-footer
              disable-pagination
              class="elevation-0"
              :loading="loading_rate_request"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status == 'error'"
                  small
                  outlined
                  color="error"
                  >error</v-chip
                >
                <v-chip v-else small outlined color="info">{{
                  item.status
                }}</v-chip>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ getTime(item.createdAt) }}
                <template v-if="item.cached && item.cached == true">
                  <v-chip x-small color="grey" dark>cached</v-chip>
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      v-model="show_request"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="100%"
    >
      <v-card>
        <v-card-title center class="headline justify-center"
          >JSON
          <v-spacer></v-spacer>
          <v-btn
            style="position: absolute; top: 13px; right: 10px"
            icon
            @click="show_request = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-if="request_to_see">
          <pre>{{ JSON.stringify(request_to_see) | pretty }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import aPage from "@/components/aPage";

export default {
  components: {
    aPage
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    item_to_edit: {},
    tab: "servers",
    show_request: false,
    request_to_see: null,
    timer: null,
    customer_name: null,
    environment: "prod",
    loading_rta_request: false,
    loading_rta_kayak_request: false,
    loading_rta_statistics: false,
    loading_rta_kayak_statistics: false,
    loading_rate_request: false,
    request_date_menu: null,
    request_date_menu_kayak: null,
    request_date_menu_2: null,
    filtered_servers: [],
    filtered_cache: false,
    filtered_justerrors: false,
    filtered_errors: [],
    rates_range: [0, 40]
  }),
  filters: {
    pretty: function(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
  },
  watch: {
    customers() {
      if (this.customers && !this.customer_name) {
        // Only first time is fired
        this.customer_name = this.customers[0];
      }
    },
    connect_api_date() {
      if (this.customer_name && this.connect_api_date) {
        this.reloadRta();
        this.getRateRequest();
      }
    },
    customer_name() {
      if (this.customer_name && this.connect_api_date) {
        this.reloadRta();
        this.getRateRequest();
      }
    },
    environment() {
      if (this.customer_name && this.connect_api_date) {
        this.reloadRta();
        this.getRateRequest();
      }
    }
  },
  methods: {
    gotToDay(day) {
      let date = Vue.moment(Vue.moment().format("YYYY-MM-") + day).format(
        "YYYY-MM-DD"
      );
      this.connect_api_date = date;
    },
    isCurrentDay(day_number) {
      return parseInt(Vue.moment(this.connect_api_date).format("DD")) ==
        day_number
        ? true
        : false;
    },
    addDay() {
      this.connect_api_date = Vue.moment(this.connect_api_date)
        .clone()
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    subtractDay() {
      this.connect_api_date = Vue.moment(this.connect_api_date)
        .clone()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    reloadRta() {
      this.getRtaStatistics();
      this.getRtaKayakStatistics();
      this.getRtaRequest();
      this.getRtaKayakRequest();
    },
    reloadRtaRequest() {
      this.getRtaRequest();
      this.getRtaKayakRequest();
    },
    getMemory(item) {
      return this.formatNumber(parseFloat(item.free_memory) * 1000) + " MB";
    },
    getStayDays(item) {
      return item.params
        ? Vue.moment(item.params.end_day).diff(
            Vue.moment(item.params.init_day),
            "days"
          )
        : 0;
    },
    getServerTime(time) {
      return this.formatHours(
        Vue.moment().isDST()
          ? Vue.moment().diff(Vue.moment(time).add(1, "hours"), "minutes")
          : Vue.moment().diff(Vue.moment(time).add(0, "hours"), "minutes")
      );
    },
    getApiTime(time) {
      return this.formatHours(
        Vue.moment().isDST()
          ? Vue.moment().diff(Vue.moment(time).add(2, "hours"), "minutes")
          : Vue.moment().diff(Vue.moment(time).add(1, "hours"), "minutes")
      );
    },
    formatHours(minutes) {
      let hours = parseInt(minutes / 60);
      let mins = minutes % 60;
      return hours + "h " + mins + "m";
    },
    getTime(time) {
      return Vue.moment(time).fromNow();
    },
    formatNumber(v) {
      return v ? numeral(v).format("0,0[.]0") : 0;
    },
    getColorMemory(item) {
      let v = item.free_memory;
      let color = "error--text";
      if (!v) {
        return color;
      }
      if (v > 0.5 && v) {
        color = "amber--text";
      }
      if (v > 0.75) {
        color = "info--text";
      }
      if (v > 1) {
        color = "success--text";
      }
      return color;
    },
    getColorTime(item) {
      let color = "success--text";
      if (!item.result) {
        return color;
      }
      if (item.result.total_time > 6000) {
        color = "info--text";
      }
      if (item.result.total_time > 10000) {
        color = "amber--text";
      }
      if (item.result.total_time > 15000) {
        color = "error--text";
      }
      return color;
    },
    getColorTimeKayak(item) {
      let color = "success--text";
      if (!item.result || !item.result.times) {
        return color;
      }
      if (item.result.times.total > 4000) {
        color = "info--text";
      }
      if (item.result.times.total > 6000) {
        color = "amber--text";
      }
      if (item.result.times.total > 9000) {
        color = "error--text";
      }
      return color;
    },
    getColorFromTotalRates(item) {
      let color = "success";
      if (!item.result) {
        return color;
      }
      if (item.result.total_otas_found < 9) {
        color = "info";
      }
      if (item.result.total_otas_found < 5) {
        color = "amber";
      }
      if (item.result.total_otas_found < 3) {
        color = "error";
      }
      return color;
    },
    seeRequest(item) {
      this.show_request = true;
      this.request_to_see = item;
    },
    repeatRequest(item) {
      this.show_request = true;
      this.request_to_see = item;
    },
    buildUrl(item) {
      const url =
        item && item.params && item.params.hotel_id
          ? "http://" +
            item.hostname +
            ":3000/rta/v1/gha-parity?hotel_id=" +
            item.params.hotel_id +
            "&init_day=" +
            item.params.init_day +
            "&end_day=" +
            item.params.end_day +
            "&adults=" +
            item.params.adults
          : "";
      return item.params && item.params.childs
        ? url + "&childs=" + item.params.childs
        : url;
    },
    buildKayakUrl(item) {
      return item && item.params && item.params.origin
        ? "http://" +
            item.hostname +
            ":3000/rta/kayak/v1/flights?origin=" +
            item.params.origin +
            "&destination=" +
            item.params.destination +
            "&start_date=" +
            item.params.start_date
        : "";
    },
    getConnectApiStatus() {
      this.$store.dispatch("getConnectApiStatus", {
        environment: this.environment
      });
    },
    getRtaStatistics() {
      this.loading_rta_statistics = true;
      let vm = this;
      this.$store
        .dispatch("getRtaStatistics", {
          date: this.connect_api_date,
          customer_name: this.customer_name,
          environment: this.environment
        })
        .then(() => {
          vm.loading_rta_statistics = false;
        });
    },
    getRtaKayakStatistics() {
      this.loading_rta_kayak_statistics = true;
      let vm = this;
      this.$store
        .dispatch("getRtaKayakStatistics", {
          date: this.connect_api_date,
          customer_name: this.customer_name,
          environment: this.environment
        })
        .then(() => {
          vm.loading_rta_kayak_statistics = false;
        });
    },
    getRateStatistics() {
      // this.loading_rate_request = true;
      let vm = this;
      this.$store
        .dispatch("getRateStatistics", {
          date: this.connect_api_date,
          customer_name: this.customer_name
        })
        .then(() => {
          // vm.loading_rate_request = false;
        });
    },
    getRateRequest() {
      this.loading_rate_request = true;
      let vm = this;
      this.$store
        .dispatch("getRateRequest", {
          date: this.connect_api_date,
          customer_name: this.customer_name,
          environment: this.environment
        })
        .then(() => {
          vm.loading_rate_request = false;
        });
    },
    getRtaRequest() {
      this.loading_rta_request = true;
      let vm = this;
      this.$store
        .dispatch("getRtaRequest", {
          date: this.connect_api_date,
          customer_name: this.customer_name,
          environment: this.environment
        })
        .then(() => {
          vm.loading_rta_request = false;
        });
    },
    getRtaKayakRequest() {
      this.loading_rta_kayak_request = true;
      let vm = this;
      this.$store
        .dispatch("getRtaKayakRequest", {
          date: this.connect_api_date,
          customer_name: this.customer_name,
          environment: this.environment
        })
        .then(() => {
          vm.loading_rta_kayak_request = false;
        });
    },
    initData() {
      clearInterval(this.timer);
      this.getConnectApiStatus();
      this.timer = setInterval(
        vm => {
          if (vm.$route.name == "api-status") {
            vm.getConnectApiStatus();
          } else {
            vm.timer = null;
          }
        },
        5000,
        this
      );
      this.getRtaRequest();
      this.getRateRequest();
      this.getRtaKayakRequest();
      this.connect_api_date = Vue.moment().format("YYYY-MM-DD");
    },
    formatDate(date) {
      return date
        ? Vue.moment(date)
            .format("D MMM YYYY")
            .replace(".", "")
            .toUpperCase()
        : "";
    },
    changeRequestDate() {
      this.request_date_menu = false;
    },
    requestAllowedDate(v) {
      return Vue.moment(v).diff(Vue.moment()) > 0 ? false : true;
    },
    getAdults(v) {
      return v.split("_")[0];
    },
    getChilds(v) {
      return v.split("_")[1];
    },
    getErrorPercent(v) {
      return this.connect_api_rta_statistics
        ? ((v * 100) / this.connect_api_rta_statistics.total_requests).toFixed(
            3
          )
        : 0;
    },
    getErrorPercentKayak(v) {
      return this.connect_api_rta_kayak_statistics
        ? (
            (v * 100) /
            this.connect_api_rta_kayak_statistics.total_requests
          ).toFixed(3)
        : 0;
    },
    getReplacementInstance(instance_id) {
      let new_instance = this.loading_servers.find(
        s => s.instance_to_recycle_id == instance_id
      );
      return new_instance ? new_instance.ec2_instance_id : false;
    },
    getCurrentProcessingRequests(product_name) {
      let current_processing_requests = 0;

      if (
        this.connect_api_status[product_name] &&
        this.connect_api_status[product_name][this.customer_name]
      ) {
        const currentProcessingRequestsByProductAndCustomer = this
          .connect_api_status[product_name][this.customer_name]
          .current_processing_requests;
        currentProcessingRequestsByProductAndCustomer &&
          (current_processing_requests += currentProcessingRequestsByProductAndCustomer);
      }

      return current_processing_requests;
    },
    getServersReady(product_name) {
      let server_status_ready = 0;

      if (
        this.connect_api_status[product_name] &&
        this.connect_api_status[product_name][this.customer_name]
      ) {
        const serverStatusByProductAndCustomer = this.connect_api_status[
          product_name
        ][this.customer_name].server_status;
        serverStatusByProductAndCustomer &&
          (server_status_ready += serverStatusByProductAndCustomer.ready);
      }
      return server_status_ready;
    },
    getServers(product_name) {
      const servers = [];

      if (
        this.connect_api_status &&
        this.connect_api_status[product_name] &&
        this.connect_api_status[product_name][this.customer_name]
      ) {
        const serversByProductAndCustomer = this.connect_api_status[
          product_name
        ][this.customer_name].servers;
        serversByProductAndCustomer &&
          serversByProductAndCustomer.length > 0 &&
          serversByProductAndCustomer.forEach(s => {
            servers.push(s);
          });
      }

      return servers;
    }
  },
  mounted() {
    this.initData();
  },
  computed: {
    result_percent() {
      let real_errors = [
        "Google is taking too long to response. Try it again later",
        "Too many requests. Try it later"
      ];

      let total_errors = 0;
      this.errors_list.forEach(e => {
        total_errors += real_errors.includes(e._id) ? e.count : 0;
      });
      let data = {
        total: 0,
        total_errors: 0,
        total_done: 0,
        done_percent: 0,
        error_percent: 0
      };
      if (this.connect_api_rta_statistics.total_requests > 0) {
        let total = this.connect_api_rta_statistics.total_requests;
        let total_errors_percent = (total_errors * 100) / total;
        let total_dones_percent = 100 - total_errors_percent;
        data = {
          total: total,
          total_errors: total_errors,
          total_done: total - total_errors,
          done_percent: total_dones_percent.toFixed(3),
          error_percent: total_errors_percent.toFixed(3)
        };
      }
      return data;
    },
    errors_list() {
      return (
        (this.connect_api_rta_statistics.results_by_error_type &&
          this.connect_api_rta_statistics.results_by_error_type.filter(
            e => e._id !== null
          )) ||
        []
      );
    },
    servers() {
      const servers = [];
      if (
        this.products &&
        this.products.length > 0 &&
        this.connect_api_status
      ) {
        this.products.forEach(product => {
          if (
            this.connect_api_status[product.value] &&
            this.connect_api_status[product.value][this.customer_name]
          ) {
            const serversByProductAndCustomer = this.connect_api_status[
              product.value
            ][this.customer_name].servers;
            serversByProductAndCustomer &&
              serversByProductAndCustomer.length > 0 &&
              serversByProductAndCustomer.forEach(s => {
                servers.push(s);
              });
          }
        });
      }
      return servers;
    },
    customer() {
      return this.customers.find(c => c == this.customer_name);
    },
    products() {
      return (
        (this.connect_api_status && this.connect_api_status.products) || [
          { value: "rate-api" },
          { value: "real-time-api" }
        ]
      );
    },
    customers() {
      let customers = [];
      if (this.connect_api_status) {
        if (this.connect_api_status.customers) {
          customers = [...this.connect_api_status.customers];
        } else {
          const customersSet = new Set();
          Object.values(this.connect_api_status)
            .slice(1)
            .forEach(c => {
              customersSet.add(Object.values(c)[0].customer);
            });
          customers = Array.from(customersSet);
        }
      }
      return customers;
    },
    loading_servers() {
      // ec2_instance_id, instance_to_recycle_id
      const loading_servers = [];
      if (this.products && this.products.length > 0) {
        this.products.forEach(product => {
          if (this.connect_api_status[product.value][this.customer_name]) {
            const loadingServersByProductAndCustomer = this.connect_api_status[
              product.value
            ][this.customer_name].loading_servers;
            loadingServersByProductAndCustomer &&
              loadingServersByProductAndCustomer.length > 0 &&
              loadingServersByProductAndCustomer.forEach(s => {
                loading_servers.push(s);
              });
          }
        });
      }
      return loading_servers;
    },
    results_by_error_type_headers() {
      return [
        {
          text: this.$t("message"),
          align: "start",
          sortable: true,
          value: "_id"
        },
        {
          text: this.$t("percent"),
          align: "end",
          sortable: true,
          value: "percent"
        },
        {
          text: "requests",
          align: "end",
          sortable: true,
          value: "count"
        }
      ];
    },
    requests_by_hour_headers() {
      return [
        {
          text: this.$t("hour"),
          align: "start",
          sortable: true,
          value: "_id"
        },
        {
          text: "requests",
          align: "end",
          sortable: true,
          value: "total"
        }
      ];
    },
    requests_by_otas_headers() {
      return [
        {
          text: this.$t("rates"),
          align: "start",
          sortable: true,
          value: "_id"
        },
        {
          text: "requests",
          align: "end",
          sortable: true,
          value: "count"
        }
      ];
    },
    requests_by_pax_headers() {
      return [
        {
          text: this.$t("adults"),
          align: "start",
          sortable: true,
          value: "adults"
        },
        {
          text: this.$t("childs"),
          align: "start",
          sortable: true,
          value: "childs"
        },
        {
          text: "requests",
          align: "end",
          sortable: true,
          value: "count"
        }
      ];
    },
    requests_by_route_headers() {
      return [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: true,
          value: "origin"
        },
        {
          text: this.$t("destination"),
          align: "start",
          sortable: true,
          value: "destination"
        },
        {
          text: "requests",
          align: "end",
          sortable: true,
          value: "count"
        }
      ];
    },
    servers_headers() {
      return [
        {
          text: this.$t("created_at"),
          align: "start",
          sortable: true,
          value: "started_time"
        },
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "ec2_instance_id"
        },
        {
          text: this.$t("processed_request"),
          align: "center",
          sortable: true,
          value: "total_requests_processed"
        },
        {
          text: this.$t("free_memory"),
          align: "center",
          sortable: true,
          value: "free_memory"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: this.$t("browser_status"),
          align: "center",
          sortable: true,
          value: "browser_status"
        },
        {
          text: this.$t("cpu"),
          align: "center",
          sortable: true,
          value: "cpu_usage"
        },
        {
          text: this.$t("is_processing_request"),
          align: "center",
          sortable: true,
          value: "is_processing_request"
        }
      ];
    },
    connect_api_rta_headers() {
      return [
        {
          text: this.$t("hotel"),
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: this.$t("init_day"),
          align: "center",
          sortable: true,
          value: "params.init_day"
        },
        {
          text: this.$t("stay"),
          align: "center",
          sortable: true,
          value: "params.end_day"
        },
        {
          text: this.$t("adults"),
          align: "center",
          sortable: true,
          value: "params.adults"
        },
        {
          text: this.$t("children"),
          align: "center",
          sortable: true,
          value: "params.childs"
        },
        {
          text: this.$t("duration"),
          align: "center",
          sortable: true,
          value: "result.total_time"
        },
        {
          text: this.$t("rates_number"),
          align: "center",
          sortable: true,
          value: "result.total_otas_found"
        },
        // {
        //   text: this.$t("concurrency"),
        //   align: "center",
        //   sortable: true,
        //   value: "init_concurrent_requests"
        // },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "result.url"
        }
      ];
    },
    connect_api_rta_kayak_headers() {
      return [
        {
          text: this.$t("route"),
          align: "center",
          sortable: true,
          value: "createdAt"
        },
        {
          text: this.$t("origin"),
          align: "center",
          sortable: true,
          value: "params.origin"
        },
        {
          text: this.$t("destination"),
          align: "center",
          sortable: true,
          value: "params.destination"
        },
        {
          text: this.$t("date"),
          align: "center",
          sortable: true,
          value: "params.start_date"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: this.$t("duration"),
          align: "center",
          sortable: true,
          value: "result.times.total"
        },
        {
          text: this.$t("rates_number"),
          align: "center",
          sortable: true,
          value: "result.total_otas_found"
        },
        // {
        //   text: this.$t("concurrency"),
        //   align: "center",
        //   sortable: true,
        //   value: "init_concurrent_requests"
        // },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "result.url"
        }
      ];
    },
    connect_api_rate_headers() {
      return [
        {
          text: this.$t("Hora"),
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: true,
          value: "status"
        },
        {
          text: this.$t("hotel"),
          align: "center",
          sortable: true,
          value: "params.hotel_id"
        },
        {
          text: this.$t("month"),
          align: "center",
          sortable: true,
          value: "params.rate_month"
        }
      ];
    },
    formatedRequestDate() {
      return this.formatDate(this.connect_api_date);
    },
    filtered_connect_api_rta_requests() {
      return this.filtered_connect_api_rta_request.length;
    },
    filtered_connect_api_rta_request() {
      return this.connect_api_rta_request
        ? this.connect_api_rta_request.filter(
            r =>
              r.params &&
              r.params.hotel_id &&
              (this.filtered_servers.length == 0 ||
                this.filtered_servers.includes(r.instanceId)) &&
              (this.filtered_errors.length == 0 ||
                (r.result && this.filtered_errors.includes(r.result.error))) &&
              (!r.cached || (r.cached && this.filtered_cache)) &&
              (r.status == "error" ||
                (r.status != "error" && !this.filtered_justerrors)) &&
              ((r.result && !r.result.rates) ||
                (r.result &&
                  r.result.rates &&
                  r.result.rates.length >= this.rates_range[0] &&
                  r.result.rates.length <= this.rates_range[1]))
          )
        : [];
    },
    filtered_connect_api_rta_kayak_requests() {
      return this.filtered_connect_api_rta_request.length;
    },
    filtered_connect_api_rta_kayak_request() {
      return this.connect_api_rta_kayak_request
        ? this.connect_api_rta_kayak_request.filter(
            r =>
              r.params &&
              r.params.origin &&
              (this.filtered_servers.length == 0 ||
                this.filtered_servers.includes(r.instanceId)) &&
              (this.filtered_errors.length == 0 ||
                (r.result && this.filtered_errors.includes(r.result.error))) &&
              (!r.cached || (r.cached && this.filtered_cache)) &&
              (r.status == "error" ||
                (r.status != "error" && !this.filtered_justerrors)) &&
              ((r.result && !r.result.rates) ||
                (r.result &&
                  r.result.rates &&
                  r.result.rates.length >= this.rates_range[0] &&
                  r.result.rates.length <= this.rates_range[1]))
          )
        : [];
    },
    last_requests_done_percent() {
      let real_errors = [
        "Google is taking too long to response. Try it again later",
        "Too many requests. Try it later",
        "No browser ready yet. Try it later",
        "Error calling API"
      ];
      let done = this.filtered_connect_api_rta_request.filter(
        r =>
          r.status == "done" ||
          (r.status == "error" && !real_errors.includes(r.result.error))
      ).length;
      let total = this.filtered_connect_api_rta_request.filter(
        r => r.status != "loading"
      ).length;
      let percent = total ? (done * 100) / total : 0;
      return percent;
    },
    connect_api_date: {
      get() {
        return this.$store.state.connectapi.date;
      },
      set(value) {
        this.$store.dispatch("setConnectAPIDate", value);
      }
    },
    ...mapState({
      connect_api_rta_statistics: state => state.connectapi.rta_statistics,
      connect_api_rta_kayak_statistics: state =>
        state.connectapi.rta_kayak_statistics,
      connect_api_status: state => state.connectapi.status,
      connect_api_launch_time: state => state.connectapi.api_launch_time,
      connect_api_rta_request: state => state.connectapi.rta_request,
      connect_api_rta_kayak_request: state =>
        state.connectapi.rta_kayak_request,
      connect_api_rate_request: state => state.connectapi.rate_request
    }),
    ...mapGetters({
      // name_rules: "name_rules",
    })
  }
};
</script>
