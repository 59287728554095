<template>
  <div
    id="rate-min-prices"
    class="d-flex rate-data-row"
    :class="{
      is_hotel: competitor.self,
      'invisible-competitor': isInvisible(competitor.id),
      pulse: pulse
    }"
  >
    <div class="item rate-labels semibold">
      <slot name="name">
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="simple_view relative"
          :class="{
            'text-uppercase': competitor.self,
            competitor: !competitor.self
          }"
          @click="
            groupDisplay
              ? showDate(days[0], competitor.id)
              : changeCompetitorVisibility(competitor)
          "
        >
          <v-avatar :color="competitor.color" class="mt-n1" size="16">
            <span class="white--text" style="font-size:9px;font-weight: 400;">{{
              competitor.stars
            }}</span>
          </v-avatar>
          {{ competitor.name }}
          <v-avatar
            color="#003580"
            class="mt-3 mr-1 rounded-bl-0 booking-rating"
            size="16"
            v-if="competitor.rating"
          >
            <span class="white--text" style="font-size:9px;font-weight: 400;">{{
              competitor.rating
            }}</span>
          </v-avatar>
        </div>
        <div v-else class="simple_view py-0 pl-2">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                depressed
                :color="competitor.color"
                class="ma-0 v-list-item__avatar subtitle-2 white--text text-uppercase justify-center"
                >{{ competitor.abbreviation }}
              </v-btn>
            </template>
            <span>{{ competitor.name }}</span>
          </v-tooltip>
        </div>
      </slot>
    </div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="(day, index) in days"
          :key="'rate-price-min-' + day + '-competitor-' + competitor.id"
          class="day"
          :class="{ weekend: is_weekend(day) }"
          @mouseover="
            date_hover = day;
            day_hover = index;
          "
          @mouseleave="
            date_hover = '';
            day_hover = '';
          "
          @click="showDate(day, competitor.id)"
        >
          <div
            v-if="mustRender(day)"
            class="day-date semibold"
            :class="'visible-day visible-day-' + index"
          >
            <div
              class="simple_view"
              v-if="daily_rates && daily_rates[day] && mode == 'rate'"
            >
              <div class="rate_data_item">
                <span class="block rate_data_main">
                  <v-tooltip left content-class="a-rate-tooltip">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <a-kpi
                          :class="{ 'font-weight-bold': competitor.self }"
                          :kpi="'min_price'"
                          :value="daily_rates[day][0].min_price"
                          :increment="true"
                          :vs_value="
                            daily_rates_vs && daily_rates_vs[day]
                              ? daily_rates_vs[day][0].min_price
                              : null
                          "
                          :increment_value="
                            increments && increments[day]
                              ? increments[day][0].min_price
                              : null
                          "
                          :simple="true"
                        >
                          <template v-slot:prepend>
                            <v-tooltip bottom content-class="a-rate-tooltip">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  v-if="daily_rates[day][0].is_mobile_offer"
                                  color="yellow darken-2"
                                  x-small
                                  style="margin-bottom: 1px;"
                                  class="mr-2"
                                  >mdi-cellphone</v-icon
                                >
                              </template>
                              <span
                                class="text-center black--text"
                                style="font-size:13px;line-height:11px;"
                                v-html="$t('is_mobile_offer')"
                              ></span>
                            </v-tooltip>
                            <v-tooltip bottom content-class="a-rate-tooltip">
                              <template v-slot:activator="{ on }">
                                <span
                                  v-on="on"
                                  class="blue--text text--darken-4 mt-n1 mr-2 "
                                  v-if="daily_rates[day][0].is_genius_offer"
                                  style="font-size:13px;font-weight:600;"
                                  >.g</span
                                >
                              </template>
                              <span
                                class="text-center black--text"
                                style="font-size:13px;line-height:11px;"
                                v-html="$t('is_genius_offer')"
                              ></span>
                            </v-tooltip>
                          </template>
                        </a-kpi>
                        <div class="a-rate-position" :class="rankingClass(day)">
                          {{ daily_rates[day][0].ranking }}
                        </div>
                      </div>
                    </template>
                    <span
                      class="text-center"
                      style="font-size:13px;line-height:11px;"
                    >
                      {{ daily_rates[day][0].room_type_name }}
                      <br />
                      <v-icon
                        class="ml-n1"
                        v-for="i in daily_rates[day][0].room_type_adults"
                        :key="'adults_' + i + '_' + competitor.id + '_' + day"
                        color="primary"
                        small
                        >mdi-account</v-icon
                      >
                      <span class="pa-1"></span>
                      <v-icon
                        class="ml-n1 mt-1"
                        v-for="i in daily_rates[day][0].room_type_childs"
                        :key="'childs_' + i + '_' + competitor.id + '_' + day"
                        color="primary"
                        x-small
                        >mdi-account</v-icon
                      >
                    </span>
                  </v-tooltip>
                </span>
                <span class="block rate_data_vs">
                  <v-row class="mx-0">
                    <v-col class="pa-0" cols="4">
                      <!-- Free cancellation -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="daily_rates[day][0].free_cancelation"
                            color="grey lighten-2"
                            small
                            >mdi-alpha-c-circle</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size:13px;line-height:11px;"
                          >{{ $t("free_cancellation") }}<br />(
                          <b>{{ daily_rates[day][0].free_cancelation_days }}</b>
                          {{ $t("daysb") }} )</span
                        >
                      </v-tooltip>
                    </v-col>
                    <v-col class="pa-0" cols="4">
                      <!-- Minimum days -->
                      <!-- Free cancellation -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="daily_rates[day][0].minimum_nights > 1"
                            color="grey lighten-2"
                            small
                            >mdi-numeric-{{
                              daily_rates[day][0].minimum_nights
                            }}-circle</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size:13px;line-height:11px;"
                          >{{ $t("minimum_stay") }}: <br />
                          <b>{{ daily_rates[day][0].minimum_nights }}</b>
                          {{ $t("days") }}</span
                        >
                      </v-tooltip>

                      <!-- Adults -->
                      <!-- <v-icon class="ml-n1" v-for="i in daily_rates[day][0].room_type_adults" :key="'adults_' + i + '_' + competitor.id + '_' + day" color="grey lighten-2" small>mdi-account</v-icon>
                      <v-icon class="ml-n1" v-for="i in daily_rates[day][0].room_type_childs" :key="'childs_' + i + '_' + competitor.id + '_' + day" color="grey lighten-2" x-small>mdi-account</v-icon> -->
                    </v-col>
                    <v-col class="pa-0" cols="4">
                      <!-- Regime -->
                      <v-tooltip bottom content-class="a-rate-tooltip">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="grey lighten-2"
                            v-if="daily_rates[day][0].regime == 'alo'"
                            style="font-size:19px;"
                            >mdi-bed-king-outline</v-icon
                          >
                          <v-icon
                            v-on="on"
                            color="grey lighten-2"
                            v-if="daily_rates[day][0].regime == 'ad'"
                            style="font-size:19px;"
                            >mdi-coffee-outline</v-icon
                          >
                          <v-icon
                            v-on="on"
                            color="grey lighten-2"
                            v-if="daily_rates[day][0].regime == 'mp'"
                            style="font-size:19px;"
                            >mdi-silverware-variant</v-icon
                          >
                          <v-icon
                            v-on="on"
                            color="grey lighten-2"
                            v-if="daily_rates[day][0].regime == 'pc'"
                            style="font-size:19px;"
                            >mdi-silverware</v-icon
                          >
                        </template>
                        <span
                          class="text-center"
                          style="font-size:13px;line-height:11px;"
                          >{{ $t(daily_rates[day][0].regime) }}</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </span>
              </div>
            </div>
            <div
              class="simple_view"
              v-if="
                daily_availability &&
                  daily_availability[day] &&
                  mode == 'availability'
              "
            >
              <div
                class="rate_data_item"
                style="line-height:40px;"
                :class="{ 'green--text': daily_availability[day].free_sell }"
              >
                <a-kpi
                  :kpi="'rate_available_rooms'"
                  :value="daily_availability[day].rate_available_rooms"
                  :increment="true"
                  :vs_value="
                    daily_rates_vs && daily_rates_vs[day]
                      ? daily_rates_vs[day].rate_available_rooms
                      : null
                  "
                  :increment_value="
                    increments && increments[day]
                      ? increments[day].rate_available_rooms
                      : null
                  "
                  :simple="true"
                ></a-kpi>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- :color="isHover(day) ? 'primary' : 'grey lighten-2'"  -->
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";

export default {
  name: "rate-min-price",
  components: {
    aKpi
  },
  props: [
    "days",
    "days_per_screen",
    "competitor",
    "daily_rates",
    "daily_availability",
    "mode",
    "increments",
    "daily_rates_vs",
    "filters",
    "days_to_render",
    "groupDisplay",
    "pulse"
  ],
  data: vm => ({
    hover: false
  }),
  methods: {
    changeCompetitorVisibility(competitor) {
      if (!competitor.self) {
        if (this.isInvisible(competitor.id)) {
          this.$store.dispatch("removeVisibleCompetitor", competitor.id);
        } else {
          this.$store.dispatch("addVisibleCompetitor", competitor.id);
        }
      }
    },
    showDate(date, competitor_id) {
      if (this.groupDisplay) {
        const c = this.getCompetitorById(competitor_id);

        this.$store.dispatch("setCurrentHotelById", c.hotel_id);
        this.$router.push({ name: "rate" });
      } else {
        this.show_rate_date_to_compare = true;
        this.$store.dispatch("setComparedCompetitors", [competitor_id]);
        this.$store.dispatch("setRateDateToCompare", date);
      }
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow == 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    },
    isHover(date) {
      return this.date_hover == date;
    },
    rankingClass(date) {
      return this.increments &&
        this.increments[date] &&
        this.increments[date][0].ranking > 0
        ? "decrement"
        : this.increments &&
          this.increments[date] &&
          this.increments[date][0].ranking < 0
        ? "increment"
        : "";
    },
    mustRender(date) {
      // Must render if date inside days_per_screen
      return this.days_to_render.indexOf(date) >= 0;
    },
    isInvisible(id) {
      return this.invisible_competitors.indexOf(id) >= 0;
    }
  },
  computed: {
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },
    ...mapState({
      competitors: state => state.rate.competitors,
      invisible_competitors: state => state.rate.invisible_competitors
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getCompetitorById: "getCompetitorById"
    })
  }
};
</script>
<style>
.a-rate-tooltip {
  margin-top: 10px;
  background: white;
  z-index: 310 !important;
  color: black !important;
  opacity: 1;
  font-weight: 500;
  border: 2px solid #dedede;
  text-align: center;
}
.a-rate-position.increment {
  background: green;
}
.a-rate-position.decrement {
  background: rgb(155, 9, 9);
}
.a-rate-position {
  position: absolute;
  top: -8px;
  right: -5px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: #999999;
  color: white;
  font-weight: 500;
  font-size: 10px;
  z-index: 320;
  line-height: 25px;
}
.rate_data_main > div {
  position: relative;
}
.competitor {
  cursor: pointer;
}
.invisible-competitor {
  opacity: 0.5;
}

.relative {
  position: relative;
}
.booking-rating {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  position: absolute;
  top: 0px;
  right: 6px;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 25px;
  display: none;
}
.rate-data-row:hover .booking-rating {
  display: flex;
}

/* .pulse {
  box-shadow: 0 0 0 rgba(21, 118, 199, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
} */
</style>
