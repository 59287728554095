export default [
  "rgb(143,184,108)",
  "rgb(35,35,33)",
  "rgb(37,83,146)",
  "rgb(81,112,122)",
  "rgb(136,33,35)",
  "rgb(201,201,201)",
  "rgb(124,157,185)",
  "rgb(37,128,230)",
  "rgb(219,224,231)",
  "rgb(57,4,118)",
  "rgb(0,0,3)",
  "rgb(143,184,108)",
  "rgb(5,52,83)",
  "rgb(0,108,154)",
  "rgb(73,47,44)",
  "rgb(70,146,249)",
  "rgb(252,62,100)",
  "rgb(0,0,115)",
  "rgb(143,184,108)",
  "rgb(71,59,61)",
  "rgb(22,97,69)",
  "rgb(241,128,74)",
  "rgb(103,9,62)",
  "rgb(71,126,57)",
  "rgb(85,19,46)",
  "rgb(0,0,13)",
  "rgb(0,0,3)",
  "rgb(129,32,58)",
  "rgb(63,97,136)",
  "rgb(143,184,108)",
  "rgb(143,184,108)",
  "rgb(143,184,108)",
  "rgb(45,42,31)",
  "rgb(53,51,55)",
  "rgb(0,0,5)",
  "rgb(99,45,0)",
  "rgb(204,9,47)",
  "rgb(51,119,80)",
  "rgb(136,33,35)",
  "rgb(143,184,108)",
  "rgb(255,129,54)",
  "rgb(255,129,54)",
  "rgb(255,129,54)",
  "rgb(255,129,54)",
  "rgb(220,194,199)",
  "rgb(220,194,199)",
  "rgb(220,194,199)",
  "rgb(220,194,199)",
  "rgb(159,159,159)",
  "rgb(11,52,88)",
  "rgb(73,47,44)",
  "rgb(143,184,108)",
  "rgb(37,128,230)",
  "rgb(42,89,134)",
  "rgb(209,25,135)",
  "rgb(0,47,62)",
  "rgb(143,184,108)",
  "rgb(143,184,108)",
  "rgb(143,184,108)",
  "rgb(238,49,159)",
  "rgb(31,17,0)",
  "rgb(57,142,197)",
  "rgb(83,67,47)",
  "rgb(252,62,100)",
  "rgb(85,19,46)",
  "rgb(45,42,31)",
  "rgb(14,27,56)",
  "rgb(255,129,54)",
  "rgb(101,140,184)",
  "rgb(244,115,45)",
  "rgb(151,8,57)",
  "rgb(124,183,220)",
  "rgb(221,121,117)",
  "rgb(220,194,199)",
  "rgb(83,67,47)"
];
