<template>
  <v-dialog
    v-model="isOpenStopSellForm"
    :fullscreen="$vuetify.breakpoint.mobile"
    :hide-overlay="$vuetify.breakpoint.mobile"
    persistent
    scrollable
    max-width="500px"
  >
    <a-special-rates-stop-sell-edit-form
      v-if="isOpenStopSellForm"
    ></a-special-rates-stop-sell-edit-form>
  </v-dialog>
</template>

<script>
import aSpecialRatesStopSellEditForm from "@/components/special-rates/aSpecialRatesStopSellEditForm.vue";

export default {
  name: "a-special-rates-stop-sell-edit",
  components: {
    aSpecialRatesStopSellEditForm
  },
  props: {
    rateId: {
      type: Number,
      default: null
    }
  },
  computed: {
    isOpenStopSellForm() {
      return this.$store.state.specialrates.stop_sell_form_open;
    }
  }
};
</script>
