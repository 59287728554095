<template>
  <div id="room-type-management-app">
    <!-- LIST -->
    <v-card>
      <v-app-bar flat color="white">
        <v-toolbar-title class="title">
          <template>{{
            $t("room_types_mapped_in_hotel", {
              hotel_name: hotel_name
            })
          }}</template>
          <v-spacer></v-spacer>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-card-text class="pb-0">
        <v-row>
          <v-col>
            <v-text-field
              class=""
              block
              v-model="search"
              rounded
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search_room_types')"
            ></v-text-field>
          </v-col>
          <v-col class="text-right">
            <v-btn
              class="mb-1"
              @click="showAddRoomType"
              rounded
              color="secondary"
            >
              <v-icon small>mdi-plus</v-icon>
              {{ $t("add_room_type") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pt-0">
        <a-base-component :subscribed_modules="subscribed_modules">
          <template v-slot:default>
            <v-data-table
              :headers="headers"
              :items="filtered_room_types"
              hide-default-footer
              disable-pagination
              class="elevation-0 room-types-table"
              mobile-breakpoint="834"
              @click:row="showEditRoomType"
              v-sortable-data-table
              @sorted="saveOrder"
            >
              <template v-slot:no-data>
                <div class="my-6">
                  {{ $t("no_room_types_configured") }}
                </div>
                <div class="my-6">
                  <v-btn
                    rounded
                    color="secondary"
                    @click="generateFromPms()"
                    class="mr-4"
                    v-if="pms_visible"
                  >
                    <v-icon @click="generateFromPms()" class="pr-2"
                      >mdi-replay</v-icon
                    >
                    <template>{{ $t("roomtype_generate_from_pms") }}</template>
                  </v-btn>
                  <v-btn
                    rounded
                    color="secondary"
                    @click="generateFromChannelManager()"
                    class="mr-4"
                    v-if="channel_manager_visible"
                  >
                    <v-icon @click="generateFromChannelManager()" class="pr-2"
                      >mdi-replay</v-icon
                    >
                    <template>{{
                      $t("roomtype_generate_from_channel_manager")
                    }}</template>
                  </v-btn>
                  <v-btn
                    rounded
                    color="secondary"
                    @click="generateFromBooking()"
                    class="mr-4"
                    v-if="booking_visible"
                  >
                    <v-icon @click="generateFromBooking()" class="pr-2"
                      >mdi-replay</v-icon
                    >
                    <template>{{
                      $t("roomtype_generate_from_booking")
                    }}</template>
                  </v-btn>
                  <v-btn
                    rounded
                    color="secondary"
                    @click="generateFromWeb()"
                    class="mr-4"
                    v-if="web_visible"
                  >
                    <v-icon @click="generateFromWeb()" class="pr-2"
                      >mdi-replay</v-icon
                    >
                    <template>{{ $t("roomtype_generate_from_web") }}</template>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar
                    size="10"
                    class="mr-2"
                    :color="item.color"
                  ></v-avatar>
                  <b>{{ item.name }}</b>
                  <small v-if="item.code" class="ml-2">{{ item.code }}</small>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        rounded
                        class="ml-2"
                        @click.stop="highlight(item)"
                        v-on="on"
                      >
                        <v-icon v-if="!item.highlighted"
                          >mdi-star-outline</v-icon
                        >
                        <v-icon v-else color="amber">mdi-star</v-icon>
                      </v-btn>
                    </template>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2 font-weight-bold">
                        {{ $t("highlight_room_type") }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-body-2 text-wrap"
                        style="font-size:11px !important;"
                        >{{
                          $t("highlight_room_type_sub")
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.pms_room_type`]="{ item }">
                {{ getPMSRoomTypeName(item.pms_room_type_id) }}
              </template>
              <template v-slot:[`item.pms_rate_plan`]="{ item }">
                {{ getPMSRatePlanName(item.pms_rate_plan_id) }}
              </template>
              <template v-slot:[`item.channel_manager`]="{ item }">
                {{
                  getChannelManagerRoomTypeName(
                    item.channel_manager_room_rate_id
                  )
                }}
              </template>
              <template v-slot:[`item.booking`]="{ item }">
                {{ getBookingRoomTypeName(item.booking_room_type_id) }}
              </template>
              <template v-slot:[`item.web`]="{ item }">
                {{ getWebRoomTypeName(item.web_room_type_id) }}
              </template>
              <template v-slot:[`item.available_rooms`]="{ item }">
                {{ item.available_rooms }}
              </template>
              <template v-slot:[`item.ref_room_rate_id`]="{ item }">
                <div v-if="item.ref_room_rate_id === null">
                  -
                </div>
                <div v-else>
                  {{ getRoomRateName(item.ref_room_rate_id) }}
                  <span v-if="item.ref_operation === 'absolute'">{{
                    l.money_pre_symbol
                  }}</span
                  ><span v-if="item.ref_value > 0">+</span>{{ item.ref_value
                  }}<span v-if="item.ref_operation === 'absolute'">{{
                    l.money_post_symbol
                  }}</span
                  ><span v-if="item.ref_operation === 'percent'">%</span>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  @click.stop="showDeleteRoomType(item)"
                  icon
                  color="secondary"
                  class="mb-1"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
                <div class="drag-icon">
                  <v-icon class="mt-2">mdi-dots-vertical</v-icon>
                </div>
              </template>
            </v-data-table>
          </template>
          <template v-slot:loading>
            <v-card class="mt-2">
              <v-card-text>
                <v-skeleton-loader
                  type="table-thead"
                  class="mx-auto"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="table-tbody"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </template>
        </a-base-component>
      </v-card-text>
    </v-card>

    <!-- DELETE ROOM TYPE -->
    <v-dialog
      v-model="show_delete_room_type"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_room_type', { room_type: item_to_edit.name })"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteRoomType()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteRoomType"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CREATE/EDIT ROOM TYPE -->
    <v-dialog
      v-model="show_edit_room_type"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="1000"
    >
      <v-card :loading="loading_periods">
        <v-card-title
          center
          class="headline justify-center py-6"
          v-if="item_to_edit.id"
        >
          {{ $t("edit_room_type", { name: item_to_edit.name }) }}
        </v-card-title>
        <v-card-title center class="headline justify-center py-6" v-else>
          {{ $t("new_room_type") }}
        </v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-tabs
            class="mb-4"
            background-color="transparent"
            slider-size="2"
            v-model="tab_room_type"
            light
            grow
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab>
              {{ $t("general") }}
            </v-tab>
            <v-tab>
              {{ $t("change_periods") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab_room_type">
            <v-tab-item>
              <v-form ref="edit_room_type_form" v-model="can_set_room_type">
                <v-row>
                  <v-col :cols="form_cols" class="py-0">
                    <v-text-field
                      v-model="item_to_edit.name"
                      :label="$t('name')"
                      :rules="[v => !!v || $t('required')]"
                      :placeholder="$t('name')"
                      outlined
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="form_cols" class="py-0">
                    <v-text-field
                      v-model="item_to_edit.code"
                      :label="$t('code')"
                      :rules="[v => !!v || $t('required')]"
                      :placeholder="$t('code')"
                      outlined
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!isAdd" cols="2" class="py-0 text-left">
                    <label class="d-block label-input">
                      {{ $t("color") }}
                    </label>
                    <v-menu
                      v-model="room_type_color_menu"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                          class="swatch-style"
                          :style="{ backgroundColor: item_to_edit.color }"
                          v-on="on"
                        ></div>
                      </template>
                      <v-color-picker
                        v-model="item_to_edit.color"
                        mode="hexa"
                      ></v-color-picker>
                    </v-menu>
                  </v-col>
                  <v-col :cols="form_cols" class="py-0" v-if="pms_visible">
                    <v-select
                      v-model="item_to_edit.pms_room_type_id"
                      :items="pms_room_types"
                      :label="$t('room_type') + ' ' + $t('pms')"
                      rounded
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col :cols="form_cols" class="py-0" v-if="pms_visible">
                    <v-select
                      v-model="item_to_edit.pms_rate_plan_id"
                      :items="pms_rate_plans"
                      :label="$t('rate_plan') + ' ' + $t('pms')"
                      rounded
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    :cols="form_cols"
                    class="py-0"
                    v-if="channel_manager_visible"
                  >
                    <v-select
                      v-model="item_to_edit.channel_manager_room_rate_id"
                      :items="channel_manager_room_rates"
                      :label="$t('channel_manager')"
                      rounded
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col :cols="form_cols" class="py-0" v-if="booking_visible">
                    <v-select
                      v-model="item_to_edit.booking_room_type_id"
                      :items="booking_room_types"
                      label="booking.com"
                      rounded
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col :cols="form_cols" class="py-0" v-if="web_visible">
                    <v-select
                      v-model="item_to_edit.web_room_type_id"
                      :items="web_room_types"
                      :label="$t('web')"
                      rounded
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="periods_headers"
                :items="periods"
                hide-default-footer
                disable-pagination
                :item-class="getDatatablePeriodsClass"
                class="periods-table"
                :no-data-text="$t('no_periods')"
              >
                <template v-slot:[`item.dates`]="{ item }">
                  <v-tooltip bottom dark color="#333333">
                    <template v-slot:activator="{ on: onMenu }">
                      <v-menu
                        :close-on-content-click="false"
                        min-width="auto"
                        v-model="item.menu_opened"
                      >
                        <template v-slot:activator="{ on: onDate }">
                          <div
                            v-on="{ ...onMenu, ...onDate }"
                            @click="setPeriodToEdit(item.range)"
                          >
                            <v-icon color="secondary" class="mr-2" small>
                              mdi-calendar-month-outline
                            </v-icon>
                            <b>{{ item.dates }}</b>
                          </div>
                        </template>
                        <v-date-picker
                          scrollable
                          range
                          v-model="item.range"
                          :allowed-dates="allowedPeriodsDates"
                          @change="setPeriod(item)"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                    <span>
                      {{ $t("change_dates") }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.available_rooms`]="{ item }">
                  <div class="d-flex align-center">
                    <v-text-field
                      class="available_rooms"
                      v-model="item.available_rooms"
                      outlined
                      rounded
                      dense
                      min="0"
                      type="number"
                      @change="setPeriod(item)"
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:[`item.ref_room_rate_id`]="{ item }">
                  <div class="d-flex align-center">
                    <v-select
                      class="ref_room_rate"
                      v-model="item.ref_room_rate_id"
                      :items="ref_room_rates"
                      rounded
                      outlined
                      dense
                      @change="setPeriod(item)"
                    ></v-select>
                    <v-btn-toggle
                      rounded
                      dense
                      mandatory
                      v-model="item.ref_operation"
                      class="ml-5"
                      v-if="item.ref_room_rate_id !== null"
                      @change="setPeriod(item)"
                    >
                      <v-btn value="absolute">
                        <v-icon color="grey">mdi-currency-eur</v-icon>
                      </v-btn>
                      <v-btn value="percent">
                        <v-icon color="grey">mdi-percent-outline</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                    <v-text-field
                      class="ref_value ml-5"
                      v-model="item.ref_value"
                      outlined
                      rounded
                      dense
                      type="number"
                      @change="setPeriod(item)"
                      v-if="item.ref_room_rate_id !== null"
                    >
                      <template #append>
                        <v-icon v-if="item.ref_operation === 'absolute'">
                          mdi-currency-eur
                        </v-icon>
                        <v-icon v-if="item.ref_operation === 'percent'">
                          mdi-percent
                        </v-icon>
                      </template>
                    </v-text-field>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    @click.stop="showDeletePeriod(item)"
                    icon
                    color="secondary"
                  >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-btn
                outlined
                rounded
                color="secondary"
                @click="addPeriod()"
                class="mt-5"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t("add_period") }}
              </v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setRoomType()"
            :disabled="!can_set_room_type"
            v-if="item_to_edit.id"
          >
            {{ $t("set_room_type") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addRoomType()"
            :disabled="!can_set_room_type"
          >
            {{ $t("add_room_type") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditRoomType"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE AVAILABILITY PERIOD -->
    <v-dialog
      v-model="show_delete_period"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">
          {{ $t("are_you_sure") }}
        </v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="period_to_edit"
          v-html="
            $t('sure_delete_availability_period', {
              period: period_to_delete_dates
            })
          "
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deletePeriod()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeletePeriod"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aBaseComponent from "@/components/aBaseComponent.vue";
import euro from "@/assets/imgs/euro.png";
import percent from "@/assets/imgs/percent.png";
import Sortable from "sortablejs";

export default {
  components: {
    aBaseComponent
  },
  props: {},
  data: () => ({
    subscribed_modules: ["roomtype_types_mapped", "roomtype_types_available"],
    valid_edit: false,
    saving: false,
    item_to_edit: {},
    show_edit_room_type: false,
    show_delete_room_type: false,
    loading_periods: false,
    show_delete_period: false,
    can_set_room_type: null,
    period_to_edit: [],
    period_to_delete_dates: null,
    tab_room_type: 0,
    room_type_color_menu: false,
    isAdd: false,
    search: "",
    euro,
    percent
  }),
  methods: {
    reloadData() {
      this.$store.dispatch("fetchRoomtypeTypesMapped", {}).then(data => {
        this.valid_edit = false;
      });
    },
    generateFromPms: function() {
      this.saving = true;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("wait_a_moment") + "...",
        text: this.$t("generating_room_type_mappings"),
        color: "secondary"
      });

      this.$store
        .dispatch("generateRoomTypesMappingsFromPms")
        .then(data => {
          this.saving = false;
          this.valid_edit = false;
          this.reloadData();

          this.$store.dispatch("setSnackbar", {
            title: this.$t("generated_roomtypes"),
            text: this.$t("generated_roomtypes_ok"),
            color: "secondary"
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    },
    generateFromChannelManager: function() {
      this.saving = true;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("wait_a_moment") + "...",
        text: this.$t("generating_room_type_mappings"),
        color: "secondary"
      });

      this.$store
        .dispatch("generateRoomTypesMappingsFromChannelManager")
        .then(data => {
          this.saving = false;
          this.valid_edit = false;
          this.reloadData();

          this.$store.dispatch("setSnackbar", {
            title: this.$t("generated_roomtypes"),
            text: this.$t("generated_roomtypes_ok"),
            color: "secondary"
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    },
    generateFromBooking: function() {
      this.saving = true;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("wait_a_moment") + "...",
        text: this.$t("generating_room_type_mappings"),
        color: "secondary"
      });

      this.$store
        .dispatch("generateRoomTypesMappingsFromBooking")
        .then(data => {
          this.saving = false;
          this.valid_edit = false;
          this.reloadData();

          this.$store.dispatch("setSnackbar", {
            title: this.$t("generated_roomtypes"),
            text: this.$t("generated_roomtypes_ok"),
            color: "secondary"
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    },
    generateFromWeb: function() {
      this.saving = true;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("wait_a_moment") + "...",
        text: this.$t("generating_room_type_mappings"),
        color: "secondary"
      });

      this.$store
        .dispatch("generateRoomTypesMappingsFromWeb")
        .then(data => {
          this.saving = false;
          this.valid_edit = false;
          this.reloadData();

          this.$store.dispatch("setSnackbar", {
            title: this.$t("generated_roomtypes"),
            text: this.$t("generated_roomtypes_ok"),
            color: "secondary"
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    },
    getDatatablePeriodsClass() {
      return "a-datatable-row period-row";
    },
    clearEditForm() {
      if (this.$refs.edit_room_type_form) {
        this.$refs.edit_room_type_form.resetValidation();
      }
    },
    showAddRoomType() {
      this.clearEditForm();
      this.isAdd = true;
      this.show_edit_room_type = true;
      this.item_to_edit = {
        id: null,
        name: "",
        pms_room_type_id: null,
        pms_rate_plan_id: null,
        channel_manager_room_rate_id: null,
        booking_room_type_id: null,
        web_room_type_id: null,
        periods: []
      };
    },
    showEditRoomType(room_type) {
      if (!room_type) {
        return;
      }
      this.isAdd = false;
      if (!this.item_to_edit.hasOwnProperty("periods")) {
        this.loading_periods = true;
        this.$store
          .dispatch("getRoomTypePeriods", {
            room_rate_id: room_type.id
          })
          .then(() => {
            this.loading_periods = false;
            this.initShowEditRoomType(room_type);
          })
          .catch(() => {
            Utils.showError(this, "something_went_wrong");
          });
      } else {
        this.initShowEditRoomType(room_type);
      }
    },
    initShowEditRoomType(room_type) {
      this.clearEditForm();
      this.item_to_edit = JSON.parse(JSON.stringify(room_type));
      this.show_edit_room_type = true;
    },
    hideEditRoomType() {
      this.show_edit_room_type = false;
      this.tab_room_type = 0;
      this.item_to_edit = {};
    },
    setRoomType: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_room_type"),
        color: "secondary"
      });
      this.item_to_edit.hotel_id = this.current_hotel.id;
      this.$store
        .dispatch("setRoomType", this.item_to_edit)
        .then(() => {
          this.show_edit_room_type = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("room_type_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_room_type = false;
          this.item_to_edit = {};
          Utils.showError(this, "room_type_not_modified");
        });
    },
    addRoomType: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_room_type"),
        color: "secondary"
      });
      this.item_to_edit.hotel_id = this.current_hotel.id;
      this.$store
        .dispatch("setRoomType", this.item_to_edit)
        .then(response => {
          if (this.item_to_edit.periods.length > 0) {
            this.item_to_edit.id = response.id;
          }
          this.show_edit_room_type = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("room_type_added"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_edit_room_type = false;
          this.item_to_edit = {};
          Utils.showError(this, "room_type_not_added");
        });
    },
    showDeleteRoomType(room_type) {
      if (!room_type) {
        return;
      }
      this.show_delete_room_type = true;
      this.item_to_edit = room_type;
    },
    hideDeleteRoomType() {
      this.show_delete_room_type = false;
      this.item_to_edit = {};
    },
    deleteRoomType: function() {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_room_type"),
        color: "secondary"
      });
      this.$store
        .dispatch("removeRoomType", { id: this.item_to_edit.id })
        .then(() => {
          this.show_delete_room_type = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("room_type_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
        })
        .catch(() => {
          this.show_delete_room_type = false;
          this.item_to_edit = {};
          Utils.showError(this, "room_type_not_deleted");
        });
    },
    addPeriod() {
      if (!this.item_to_edit.periods) {
        this.item_to_edit.periods = [];
      }
      let last_date = Vue.moment().format("YYYY-MM-DD");
      if (this.item_to_edit.periods.length > 0) {
        this.item_to_edit.periods.sort((a, b) => {
          return Vue.moment(a.end_date).diff(Vue.moment(b.end_date));
        });
        last_date = this.item_to_edit.periods[
          this.item_to_edit.periods.length - 1
        ].end_date;
      }
      let start_date = Vue.moment(last_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
      last_date = Vue.moment(last_date)
        .add(7, "days")
        .format("YYYY-MM-DD");
      let period = {
        start_date: start_date,
        end_date: last_date,
        available_rooms: 1,
        ref_room_rate_id: null,
        ref_operation: 0,
        ref_value: 1
      };
      this.item_to_edit.periods.push(period);
    },
    setPeriodToEdit(period) {
      this.period_to_edit = period;
    },
    setPeriod(item) {
      if (!item) {
        return false;
      }
      this.item_to_edit.periods.filter(p => {
        if (
          p.start_date === item.range_original[0] &&
          p.end_date === item.range_original[1]
        ) {
          p.available_rooms = item.available_rooms;
          p.ref_room_rate_id = item.ref_room_rate_id;
          p.ref_operation = item.ref_operation;
          p.ref_value = item.ref_value;
          p.start_date = item.range[0];
          p.end_date = item.range[1];
        }
      });
      item.menu_opened = false;
    },
    showDeletePeriod(item) {
      if (!item) {
        return false;
      }
      this.show_delete_period = true;
      this.period_to_edit = item.range;
      this.period_to_delete_dates = item.dates;
    },
    hideDeletePeriod() {
      this.show_delete_period = false;
      this.period_to_edit = [];
      this.period_to_delete_dates = null;
    },
    deletePeriod() {
      if (!this.period_to_edit) {
        return false;
      }
      this.item_to_edit.periods = this.item_to_edit.periods.filter(
        p =>
          p.start_date !== this.period_to_edit[0] &&
          p.end_date !== this.period_to_edit[1]
      );
      this.hideDeletePeriod();
    },
    allowedPeriodsDates(date) {
      if (!this.item_to_edit.periods || !this.period_to_edit) {
        return true;
      }
      let periods = this.item_to_edit.periods;
      periods = periods.filter(
        p =>
          p.start_date !== this.period_to_edit[0] &&
          p.end_date !== this.period_to_edit[1]
      );
      let allowed = true;
      periods.forEach(p => {
        if (date >= p.start_date && date <= p.end_date) {
          allowed = false;
        }
      });
      return allowed;
    },
    getPMSRoomTypeName(id) {
      if (!id) {
        return "";
      }
      let pms = this.pms_room_types.find(r => r.value === id);
      return pms ? pms.text : "";
    },
    getPMSRatePlanName(id) {
      if (!id) {
        return "";
      }
      let pms = this.pms_rate_plans.find(r => r.value === id);
      return pms ? pms.text : "";
    },
    getChannelManagerRoomTypeName(id) {
      if (!id) {
        return "";
      }
      let channel_manager = this.channel_manager_room_rates.find(
        r => r.value === id
      );
      return channel_manager ? channel_manager.text : "";
    },
    getBookingRoomTypeName(id) {
      if (!id) {
        return "";
      }
      let booking = this.booking_room_types.find(r => r.value === id);
      return booking ? booking.text : "";
    },
    getWebRoomTypeName(id) {
      if (!id) {
        return "";
      }
      let web = this.web_room_types.find(r => r.value === id);
      return web ? web.text : "";
    },
    getRoomRateName(id) {
      if (!id) {
        return "";
      }
      let room_rate = this.room_types_mapped.find(r => r.id === id);
      return room_rate ? room_rate.name : "";
    },
    highlight(item) {
      this.$store.dispatch("setSnackbar", {
        title: this.$t("highlighting_room_type"),
        color: "secondary"
      });
      this.$store
        .dispatch("highlightRoomType", {
          id: item.id,
          hotel_id: this.current_hotel.id
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("room_type_highlighted"),
            color: "accent"
          });
        })
        .catch(() => {
          Utils.showError(this, "room_type_not_highlighted");
        });
    },
    saveOrder(event) {
      const newIndex = event.newIndex + 1;
      const movedItem = this.filtered_room_types.splice(event.oldIndex, 1)[0];
      this.filtered_room_types.splice(event.newIndex, 0, movedItem);
      this.$store.dispatch("setSnackbar", {
        title: this.$t("setting_room_type_position"),
        color: "secondary"
      });
      this.$store
        .dispatch("setRoomTypePosition", {
          id: movedItem.id,
          position: newIndex
        })
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("room_type_position_changed"),
            color: "accent"
          });
        })
        .catch(() => {
          Utils.showError(this, "room_type_position_not_changed");
        });
    }
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function(event) {
            vnode.child.$emit("sorted", event);
          }
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      }
    }
  },
  computed: {
    filtered_room_types() {
      let room_types = this.room_types_mapped;
      if (this.search && this.search.length > 0) {
        room_types = room_types.filter(c => {
          let search_text = this.search.toLowerCase();
          if (c.name && c.name.toLowerCase().includes(search_text)) {
            return true;
          }
          if (
            c.pms_room_type &&
            c.pms_room_type.toLowerCase().includes(search_text)
          ) {
            return true;
          }
          if (
            c.pms_rate_plan &&
            c.pms_rate_plan.toLowerCase().includes(search_text)
          ) {
            return true;
          }
          if (
            c.channel_manager_room_rate &&
            c.channel_manager_room_rate.toLowerCase().includes(search_text)
          ) {
            return true;
          }
          if (
            c.booking_room_type &&
            c.booking_room_type.toLowerCase().includes(search_text)
          ) {
            return true;
          }
          if (
            c.web_room_type &&
            c.web_room_type.toLowerCase().includes(search_text)
          ) {
            return true;
          }
          return false;
        });
      }
      return room_types;
    },
    ref_room_rates() {
      let ref_room_rates = this.room_types_mapped
        .filter(rt => rt.id !== this.item_to_edit.id)
        .map(rt => {
          return {
            value: rt.id,
            text: rt.name
          };
        });
      ref_room_rates.unshift({
        value: null,
        text: "- " + this.$t("no_calc_rate") + " -"
      });
      return ref_room_rates;
    },
    pms_room_types() {
      return this.room_types_available
        ? this.room_types_available["pms_room_types"]
        : [];
    },
    pms_rate_plans() {
      return this.room_types_available
        ? this.room_types_available["pms_rate_plans"]
        : [];
    },
    channel_manager_room_rates() {
      return this.room_types_available
        ? this.room_types_available["channel_manager_room_rates"]
        : [];
    },
    booking_room_types() {
      return this.room_types_available
        ? this.room_types_available["booking_room_types"]
        : [];
    },
    web_room_types() {
      return this.room_types_available
        ? this.room_types_available["web_room_types"]
        : [];
    },
    current_user: {
      get() {
        return this.$store.state.login.current_user;
      },
      set(value) {
        this.$store.dispatch("updateCurrentUser", value);
      }
    },
    periods() {
      if (!this.item_to_edit.periods) {
        return [];
      }
      return this.item_to_edit.periods.map(p => {
        let dates = Utils.getFriendlyPeriod(p.start_date, p.end_date);
        return {
          dates: dates,
          range: [p.start_date, p.end_date],
          range_original: [p.start_date, p.end_date],
          available_rooms: p.available_rooms,
          ref_room_rate_id: p.ref_room_rate_id,
          ref_operation: p.ref_operation,
          ref_value: p.ref_value,
          menu_opened: false
        };
      });
    },
    headers() {
      const headers = [];
      headers.push({
        text: this.$t("name"),
        align: "left",
        sortable: true,
        value: "name",
        width: this.width + "%"
      });
      if (this.pms_visible) {
        headers.push({
          text: this.$t("room_type") + " " + this.$t("pms"),
          align: "center",
          sortable: true,
          value: "pms_room_type",
          width: this.width + "%"
        });
        headers.push({
          text: this.$t("rate_plan") + " " + this.$t("pms"),
          align: "center",
          sortable: true,
          value: "pms_rate_plan",
          width: this.width + "%"
        });
      }
      if (this.channel_manager_visible) {
        headers.push({
          text: this.$t("channel_manager"),
          align: "center",
          sortable: true,
          value: "channel_manager",
          width: this.width + "%"
        });
      }
      if (this.booking_visible) {
        headers.push({
          text: this.$t("booking"),
          align: "center",
          sortable: true,
          value: "booking",
          width: this.width + "%"
        });
      }
      if (this.web_visible) {
        headers.push({
          text: this.$t("web"),
          align: "center",
          sortable: true,
          value: "web",
          width: this.width + "%"
        });
      }
      headers.push({
        text: this.$t("available_rooms"),
        align: "left",
        sortable: true,
        value: "available_rooms",
        width: this.width + "%"
      });
      headers.push({
        text: this.$t("calc_rate"),
        align: "left",
        sortable: true,
        value: "ref_room_rate_id",
        width: this.width + "%"
      });
      headers.push({
        text: null,
        align: "right",
        sortable: false,
        value: "actions",
        width: "10%"
      });
      return headers;
    },
    periods_headers() {
      return [
        {
          text: this.$t("dates"),
          align: "left",
          sortable: true,
          value: "dates"
        },
        {
          text: this.$t("available_rooms"),
          align: "left",
          sortable: true,
          value: "available_rooms"
        },
        {
          text: this.$t("calc_rate"),
          align: "left",
          sortable: true,
          value: "ref_room_rate_id"
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "actions"
        }
      ];
    },
    columns() {
      return (
        3 +
        (this.pms_visible ? 2 : 0) +
        (this.channel_manager_visible ? 1 : 0) +
        (this.booking_visible ? 1 : 0) +
        (this.web_visible ? 1 : 0)
      );
    },
    width() {
      return 90 / this.columns;
    },
    form_cols() {
      return this.isAdd ? 6 : 5;
    },
    pms_visible() {
      return (
        this.current_hotel &&
        this.current_user &&
        this.current_hotel.permissions.revenue &&
        this.current_user.permissions.revenue
      );
    },
    channel_manager_visible() {
      return (
        this.current_hotel &&
        this.current_user &&
        this.current_hotel.permissions.channelmanager &&
        this.current_user.permissions.channelmanager
      );
    },
    booking_visible() {
      // By now, not visible
      return (
        1 === 0 &&
        this.current_hotel &&
        this.current_user &&
        this.current_hotel.permissions.rate &&
        this.current_user.permissions.rate
      );
    },
    web_visible() {
      // By now, not visible
      return (
        1 === 0 &&
        this.current_hotel &&
        this.current_user &&
        this.current_hotel.permissions.rate_web &&
        this.current_user.permissions.rate_web
      );
    },
    hotel_name() {
      return this.current_hotel ? this.current_hotel.name : "";
    },
    ...mapState({
      room_types_mapped: state => state.roomtype.types_mapped,
      room_types_available: state => state.roomtype.types_available,
      snackbar: state => state.snackbar
      // query_params: state => state.route.query
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language"
      // booking_rules: "booking_rules"
    })
  }
};
</script>

<style lang="scss" scoped>
.room-types-table {
  tr {
    cursor: pointer;
    .drag-icon {
      display: inline-flex;
      width: 20px;
      i {
        display: none;
        cursor: move;
      }
    }
  }
  tr:hover {
    .drag-icon {
      i {
        display: block;
      }
    }
  }
}
.periods-table {
  th {
    white-space: nowrap;
  }
  .period-row {
    > td {
      padding: 10px !important;
    }
    .v-text-field--rounded {
      > .v-input__control {
        > .v-input__slot {
          padding: 0 15px;
          min-height: 10px;
          margin-bottom: 0;
        }
      }
    }
    .available_rooms {
      > .v-input__control {
        > .v-input__slot {
          width: 75px;
        }
      }
    }
    .ref_room_rate {
      > .v-input__control {
        > .v-input__slot {
          width: 150px;
        }
      }
    }
    .ref_value {
      > .v-input__control {
        > .v-input__slot {
          width: 100px;
          > .v-input__append-inner {
            margin-top: 4px;
          }
        }
      }
    }
    .v-text-field--enclosed {
      .v-text-field__details {
        padding: 0;
        margin: 0;
        min-height: 0;
        width: 75px;

        .v-messages {
          min-height: 0;
        }
      }
    }
  }
}
.theme--light.v-stepper {
  box-shadow: none;
  background: transparent;
}
#room-type-actions {
  margin: 0 auto;
  background-color: transparent;
}
.v-btn-toggle > .v-btn {
  height: 40px;
  opacity: 1;
}
.v-data-table__wrapper {
  overflow: visible;
}
.pulse {
  box-shadow: 0 0 0 rgba(21, 118, 199, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}
.swatch-style {
  margin-top: -16px;
  border-radius: 7px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.label-input {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  transform: translateY(-16px) translateX(-16px) scale(0.75);
}

/* // @-webkit-keyframes pulse
//   0%
//     -webkit-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4)
//   70%
//     -webkit-box-shadow: 0 0 0 10px rgba(21, 118, 199, 0)
//   100%
//     -webkit-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0)
// @keyframes pulse
//   0%
//     -moz-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4)
//     box-shadow: 0 0 0 0 rgba(21, 118, 199, 0.4)
//   70%
//     -moz-box-shadow: 0 0 0 10px rgba(21, 118, 199, 0)
//     box-shadow: 0 0 0 10px rgba(21, 118, 199, 0)
//   100%
//     -moz-box-shadow: 0 0 0 0 rgba(21, 118, 199, 0)
//     box-shadow: 0 0 0 0 rgba(21, 118, 199, 0) !important;;;;;;;;;;; */
</style>
