<template>
  <div>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-card>
          <v-row dense>
            <v-col class="py-0">
              <v-list dense class="transparent">
                <v-list-item
                  @mouseover="highlighted_date = dates['otb']['today']"
                  @mouseleave="highlighted_date = ''"
                >
                  <v-list-item-icon>
                    <v-icon :color="importStatusColor(dates['otb'])"
                      >mdi-circle</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>OTB</v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        class="text-decoration-line-through"
                        v-if="
                          dates['otb']['today'] !== dates['otb']['import_date']
                        "
                      >
                        {{ dates["otb"]["today"] | formatDayMonth }}</span
                      >
                      {{
                        dates["otb"]["import_date"] | formatDate
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col class="py-0">
              <v-list dense class="transparent">
                <v-list-item
                  @mouseover="highlighted_date = dates['stly']['today']"
                  @mouseleave="highlighted_date = ''"
                >
                  <v-list-item-icon>
                    <v-icon :color="importStatusColor(dates['stly'])"
                      >mdi-circle</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>STLY</v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        class="text-decoration-line-through"
                        v-if="
                          dates['stly']['today'] !==
                            dates['stly']['import_date']
                        "
                      >
                        {{ dates["stly"]["today"] | formatDayMonth }}</span
                      >
                      {{
                        dates["stly"]["import_date"] | formatDate
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col class="py-0">
              <v-list dense class="transparent">
                <v-list-item
                  @mouseover="highlighted_date = dates['ly']['today']"
                  @mouseleave="highlighted_date = ''"
                >
                  <v-list-item-icon>
                    <v-icon :color="importStatusColor(dates['ly'])"
                      >mdi-circle</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>LY</v-list-item-title>
                    <v-list-item-subtitle>
                      <span
                        class="text-decoration-line-through"
                        v-if="
                          dates['ly']['today'] !== dates['ly']['import_date']
                        "
                      >
                        {{ dates["ly"]["today"] | formatDayMonth }}</span
                      >
                      {{
                        dates["ly"]["import_date"] | formatDate
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row dense v-show="show_pickup_dates">
            <v-col
              cols="2"
              class="py-0"
              v-for="spot in pickups"
              :key="spot['pickup_days']"
            >
              <v-list dense class="transparent">
                <v-list-item
                  @mouseover="highlighted_date = spot['today']"
                  @mouseleave="highlighted_date = ''"
                >
                  <v-list-item-icon>
                    <v-icon :color="importStatusColor(spot)">mdi-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >Pickup -{{ spot["pickup_days"] }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <span
                        class="text-decoration-line-through"
                        v-if="spot['today'] !== spot['import_date']"
                      >
                        {{ spot["today"] | formatDayMonth }}</span
                      >
                      {{
                        spot["import_date"] | formatDate
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row dense v-show="show_pickup_dates">
            <v-col class="py-0">
              <div class="d-flex align-center">
                <div
                  class="rounded-pill white d-flex align-center py-1 px-1"
                  style="height: 100%; position: relative; margin: 0 auto;"
                >
                  <v-tooltip
                    bottom
                    v-for="(filter, i) in dimension_filters_with_segmentation"
                    :key="i"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        rounded
                        fab
                        :text="
                          revenue_dates_calendar_dimension !== filter.value
                        "
                        :color="
                          revenue_dates_calendar_dimension === filter.value
                            ? 'secondary'
                            : 'black'
                        "
                        @click="revenue_dates_calendar_dimension = filter.value"
                        :class="i > 0 ? 'ml-2' : ''"
                        v-on="on"
                      >
                        <v-icon
                          x-small
                          :color="
                            revenue_dates_calendar_dimension === filter.value
                              ? 'white'
                              : 'black'
                          "
                          >{{ filter.icon }}</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>{{ $t(filter.label) }}</span>
                  </v-tooltip>
                </div>
              </div>
              <import-dates-graph
                :calendar="revenue_dates_calendar.calendar"
                :highlight="highlighted_date"
              ></import-dates-graph>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-center py-0">
              <v-btn
                block
                depressed
                color="white"
                small
                @click="show_pickup_dates = !show_pickup_dates"
                ><v-icon v-if="!show_pickup_dates">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-slot:loading>
        <v-card>
          <v-row dense>
            <v-col>
              <v-skeleton-loader
                type="list-item-avatar"
                class="py-3"
              ></v-skeleton-loader>
            </v-col>
            <v-col>
              <v-skeleton-loader
                type="list-item-avatar"
                class="py-3"
              ></v-skeleton-loader>
            </v-col>
            <v-col>
              <v-skeleton-loader
                type="list-item-avatar"
                class="py-3"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import ImportDatesGraph from "@/components/revenue-import/ImportDatesGraph.vue";
import ABaseComponent from "@/components/aBaseComponent.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "import-dates-status",
  components: { ABaseComponent, ImportDatesGraph },
  props: {},
  data: () => ({
    subscribed_modules: [
      "dataimport_revenue_dates",
      "dataimport_revenue_dates_calendar"
    ],
    show_pickup_dates: false,
    highlighted_date: "",
    dimension_filters: [
      {
        value: null,
        label: "no_dimension",
        icon: "mdi-calendar"
      },
      {
        value: "roomtype",
        label: "dimension_roomtype",
        icon: "mdi-sofa-outline"
      },
      {
        value: "servicetype",
        label: "dimension_servicetype",
        icon: "mdi-chart-timeline-variant"
      }
    ]
  }),
  computed: {
    dates: function() {
      return this.revenue_dates.dates;
    },
    pickups: function() {
      return this.revenue_dates.pickups;
    },
    revenue_dates_calendar_dimension: {
      get() {
        return this.$store.state.dataimport.revenue_dates_calendar_dimension;
      },
      set(value) {
        this.$store.dispatch("setRevenueDatesCalendarDimension", value);
      }
    },
    dimension_filters_with_segmentation: function() {
      const dimension_filters = this.dimension_filters;
      if (!this.current_hotel) {
        return dimension_filters;
      }
      const channels = this.current_hotel.settings.channels_in_day_kpis;
      const segments = this.current_hotel.settings.segments_in_day_kpis;
      const segmentAsSegmentObj = {
        value: "segment",
        label: "dimension_segment",
        icon: "mdi-hub-outline"
      };
      const channelAsSegmentObj = {
        value: "channel",
        label: "dimension_segment",
        icon: "mdi-hub-outline"
      };
      const channelAsChannelObj = {
        value: "channel",
        label: "dimension_channel",
        icon: "mdi-hexagon-multiple-outline"
      };
      const intermediateAsChannelObj = {
        value: "intermediate",
        label: "dimension_channel",
        icon: "mdi-hexagon-multiple-outline"
      };

      if (segments && channels) {
        dimension_filters.push(segmentAsSegmentObj, channelAsChannelObj);
      } else if (segments) {
        dimension_filters.push(segmentAsSegmentObj, intermediateAsChannelObj);
      } else if (channels) {
        dimension_filters.push(channelAsSegmentObj, intermediateAsChannelObj);
      } else {
        dimension_filters.push(intermediateAsChannelObj);
      }
      return dimension_filters;
    },
    ...mapState({
      revenue_dates: state => state.dataimport.revenue_dates,
      revenue_dates_calendar: state => state.dataimport.revenue_dates_calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  methods: {
    importStatusColor: function(status) {
      if (status["today"] === status["import_date"]) {
        return "green";
      } else if (status["import_date"] !== null) {
        return "red lighten-1";
      } else {
        return "red darken-1";
      }
    }
  },
  watch: {},
  mounted() {}
};
</script>

<style scoped></style>
