var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"row-data"},[_c('td',{staticClass:"day-data sticky-columns-shadow",class:[_vm.color_class, { weekend: _vm.isWeekend }],staticStyle:{"width":"200px"},on:{"click":function($event){return _vm.$emit('clickOnDateCell', _vm.date)}}},[(!_vm.isSummary)?_c('div',{staticClass:"cell"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%","gap":"3px"}},[_c('div',{staticClass:"d-flex justify-start pl-5"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.day_of_week)}}),(_vm.is_today)?_c('v-chip',{staticClass:"ml-2",attrs:{"dark":"","x-small":"","color":"blue"}},[_vm._v(_vm._s(_vm.$t("today")))]):_vm._e(),_c('v-spacer'),_vm._l((_vm.impacts),function(impact){return _c('a-rms-strategy-day-events',{key:impact.impact,attrs:{"impact":impact,"events":_vm.events,"date":_vm.date}})})],2),_c('div',{staticClass:"d-flex justify-start pl-5 grey--text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.day_of_week_vs)}}),_c('v-spacer'),_vm._l((_vm.impacts_vs),function(impact_vs){return _c('a-rms-strategy-day-events',{key:impact_vs.impact,attrs:{"impact":impact_vs,"events":_vm.events_vs,"date":_vm.date_vs}})})],2)])]):_c('div',{staticClass:"cell justify-start"},[_c('div',{staticClass:"d-flex justify-start pl-5"},[_c('span',[_vm._v(_vm._s(_vm.$t("result_period")))])])])]),_vm._l((_vm.nonStickyColumnHeaders),function(column){return _c('td',{key:(_vm.date + "-" + (column.group) + "-" + (column.column)),class:[
      {
        'sticky-columns-shadow': column.column === _vm.lastStickyColumn.column
      },
      { weekend: _vm.isWeekend },
      _vm.getClass(
        _vm.configStrategyTable[column.group].columns[column.column].kpi,
        _vm.getValue(column),
        column
      ),
      { 'br-bolder': column.column === _vm.lastColumnOfEachGroup[column.group] },
      { previous: _vm.isPrevious },
      { summary: _vm.isSummary }
    ],on:{"mouseover":function($event){return _vm.$emit('hoveredKpi', column)},"mouseleave":function($event){return _vm.$emit('hoveredKpi', '')}}},[_c('div',{staticStyle:{"position":"relative","height":"100%"}},[_c('div',{staticClass:"cell"},[(column.group.startsWith('pickup_'))?[_c('v-row',{staticClass:"mx-0 pt-1"},[_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":_vm.kpiMode === 'hide_vs' || _vm.rms.strategy_vs_spot !== 'stly'
                  ? 12
                  : 6}},[_c('a-kpi',{class:{
                  'pt-4': _vm.rms.strategy_vs_spot !== 'stly'
                },attrs:{"kpi":_vm.configStrategyTable[column.group].columns[column.column].kpi,"value":_vm.getStaticKpiValue(column),"x_small":"","absolute":_vm.kpiMode === 'absolute'}})],1)],1),(_vm.rms.strategy_vs_spot === 'stly')?_c('v-row',{staticClass:"pt-1 mx-0 align-center"},[_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":_vm.kpiMode === 'hide_vs' ? 12 : 6}},[_c('a-kpi',{staticClass:"kpi-vs",attrs:{"kpi":_vm.configStrategyTable[column.group].columns[column.column].kpi,"value":_vm.getStaticKpiValue(
                    column,
                    ("pickup_stly_" + (column.group.slice(-1)))
                  ),"x_small":"","absolute":_vm.kpiMode === 'absolute'}})],1),(_vm.kpiMode !== 'hide_vs')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.kpiMode === 'hide_vs' ? 12 : 6}},[(_vm.rms.strategy_vs_spot === 'stly')?_c('a-kpi',{attrs:{"kpi":_vm.configStrategyTable[column.group].columns[column.column].kpi,"value":_vm.getStaticKpiValue(column),"vs_values":[
                  _vm.getStaticKpiValue(
                    column,
                    ("pickup_stly_" + (column.group.slice(-1)))
                  )
                ],"vs_periods":[_vm.rms.strategy_vs_spot],"x_small":"","hide_kpi":"","left":"","absolute":_vm.kpiMode === 'absolute',"shorten":""}}):_vm._e()],1):_vm._e()],1):_vm._e()]:[_c('v-row',{staticClass:"mx-0 pt-1"},[_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":_vm.kpiMode === 'hide_vs' ? 12 : 6}},[_c('a-kpi',{attrs:{"kpi":_vm.configStrategyTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column),"x_small":"","absolute":_vm.kpiMode === 'absolute'}})],1)],1),_c('v-row',{staticClass:"pt-1 mx-0 align-center"},[_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":_vm.kpiMode === 'hide_vs' ? 12 : 6}},[_c('a-kpi',{staticClass:"kpi-vs",attrs:{"kpi":_vm.configStrategyTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column, _vm.rms.strategy_vs_spot),"x_small":"","absolute":_vm.kpiMode === 'absolute'}})],1),(_vm.kpiMode !== 'hide_vs')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.kpiMode === 'hide_vs' ? 12 : 6}},[_c('a-kpi',{attrs:{"kpi":_vm.configStrategyTable[column.group].columns[column.column].kpi,"value":_vm.getValue(column),"vs_values":[_vm.getValue(column, _vm.rms.strategy_vs_spot)],"vs_periods":[_vm.rms.strategy_vs_spot],"x_small":"","hide_kpi":"","left":"","absolute":_vm.kpiMode === 'absolute',"shorten":""}})],1):_vm._e()],1)]],2),(
          _vm.showProgressPercent(
            _vm.configStrategyTable[column.group].columns[column.column].kpi
          )
        )?_c('div',{staticClass:"percent-progress",style:(_vm.getPercentStyle(_vm.getValue(column)))}):_vm._e()])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }