<template>
  <v-card
    no-title
    color="transparent"
    class="a-rms-labels py-1 px-0 elevation-0 text-left"
  >
    <div class="a-rms-day-row-day"></div>
    <div class="a-rms-day-row-events"></div>
    <a-rms-day-header :labels="['', '', '', '']"></a-rms-day-header>
    <div class="a-rms-day-row"></div>
    <a-rms-day-header :labels="['', '', '', '']"></a-rms-day-header>
    <div class="a-rms-day-row"></div>
    <a-rms-day-header
      class="mt-2"
      :labels="['', '', '', '']"
    ></a-rms-day-header>
    <div class="a-rms-day-row-resume">
      <a-period dense :bw="row !== 10" period="otb"></a-period>
    </div>
    <div v-if="rms.show_bud" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 1"
        :periods="['otb', 'bud']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_stly" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 2"
        :periods="['otb', 'stly']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_ly" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 3"
        :periods="['otb', 'ly']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_pickup_1" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 4"
        :periods="['otb', 'pickup_1']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_pickup_2" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 5"
        :periods="['otb', 'pickup_2']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_pickup_3" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 6"
        :periods="['otb', 'pickup_3']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_pickup_4" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 7"
        :periods="['otb', 'pickup_4']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_pickup_5" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 8"
        :periods="['otb', 'pickup_5']"
      ></a-period-compare>
    </div>
    <div v-if="rms.show_pickup_6" class="a-rms-day-row">
      <a-period-compare
        dense
        :bw="row !== 9"
        :periods="['otb', 'pickup_6']"
      ></a-period-compare>
    </div>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

var numeral = require("numeral");
import * as Utils from "@/utils.js";
import aPeriod from "@/components/rms_ui/aPeriod";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare";
import aRmsDayHeader from "@/components/rms/hotelsdot/aRmsDayHeader";

export default {
  name: "a-rms-day",
  components: {
    aPeriod,
    aPeriodCompare,
    aRmsDayHeader
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    row: {
      type: Number,
      nullable: true,
      default: null
    }
  },
  data: vm => ({
    title: "",
    subscribed_modules: [
      // "rms_kpis_monthly_graph",
      // "rms_segments_period_graph",
      // "rms_annual_kpis",
      // "rms_ytd_annual_kpis",
      "rms_all_kpis_daily"
    ]
  }),
  watch: {},
  methods: {
    ...mapActions([])
  },
  computed: {
    getFriendlyDate() {
      return this.item ? Utils.getFriendlyDate(this.item.date) : "";
    },
    day_data() {
      return this.item ? this.getRmsDayKpis(this.item.date) : {};
    },
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({
      getRmsDayKpis: "getRmsDayKpis"
    })
  },
  mounted() {
    // this.setDefault();
  }
};
</script>
