<template>
  <div>
    <div class="table-header d-flex">
      <v-text-field
        class=""
        block
        v-model="search"
        rounded
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search_event')"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-tooltip bottom dark color="#333333">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-1"
            @click="showAddEvent"
            rounded
            color="secondary"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            {{ $t("add_event") }}
          </v-btn>
        </template>
        <span>
          {{ $t("create_event_in_calendar") }}
        </span>
      </v-tooltip>
    </div>
    <v-data-table
      :headers="hotel_events_headers"
      :items="filteredEvents"
      hide-default-footer
      disable-pagination
      @click:row="showEditEvent"
      :item-class="getDatatableRowClass"
      :no-data-text="$t('no_calendar_events')"
    >
      <template v-slot:[`item.name`]="{ item }">
        <b class="mr-1">{{ item.name }}</b>
        <v-icon x-small v-if="!item.editable" color="grey"
          >mdi-lock-outline</v-icon
        >
        <div class="grey--text caption">
          <v-icon x-small color="grey">{{
            getEventTypeIcon(item.type)
          }}</v-icon>
          {{ $t(item.type) }}
          <v-icon
            x-small
            color="grey lighten-1"
            v-for="i in item.duration"
            :key="i"
            >mdi-circle-outline</v-icon
          >
        </div>
      </template>
      <!-- <template v-slot:[`item.start_date`]="{ item }">
      <v-icon small color="primary" v-for="i in getDuration(item)" :key="i">mdi-circle</v-icon>
    </template> -->
      <template v-slot:[`item.type`]="{ item }">
        {{ $t(item.type) }}
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        {{ item.days }}
      </template>
      <template v-slot:[`item.repeat`]="{ item }">
        <v-chip small color="primary" outlined v-if="item.repeat === 'annual'"
          ><b>{{ $t(item.repeat) }}</b></v-chip
        >
        <v-chip
          small
          color="secondary darken-2"
          outlined
          v-if="item.repeat === 'biannual'"
          ><b>{{ $t(item.repeat) }}</b></v-chip
        >
      </template>
      <template v-slot:[`item.impact`]="{ item }">
        <v-chip dark small :color="getImpactColor(item.impact)"
          ><b>{{ $t("impact_" + item.impact) }}</b></v-chip
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="showMoveEvent(item)"
              icon
              color="secondary"
              class="mr-2"
              v-on="on"
              ><v-icon small>mdi-calendar-import-outline</v-icon></v-btn
            >
          </template>
          <span>
            {{ $t("move_calendar") }}
          </span>
        </v-tooltip>

        <v-tooltip bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="showDeleteEvent(item)"
              icon
              color="secondary"
              v-on="on"
              ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
            >
          </template>
          <span>
            {{ $t("delete") }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- DELETE EVENT -->
    <v-dialog
      v-model="show_delete_event"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_event', { event: item_to_edit.name })"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteEvent()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteEvent"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MOVE EVENT -->
    <v-dialog
      v-model="show_move_event"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("move_calendar")
        }}</v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <p v-html="$t('select_calendar_to_move')"></p>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-select
                rounded
                outlined
                dense
                v-model="item_to_edit.calendar_id"
                :items="available_editable_calendars"
                item-text="name"
                item-value="id"
                :label="$t('target_calendar')"
                :rules="[v => !!v || $t('required')]"
                :placeholder="$t('select_calendar')"
                :disabled="item_to_edit.calendar_id === null"
              >
                <template v-slot:[`item`]="{ item }">
                  <span v-if="item.id !== 'new'">
                    {{ item.name }}
                  </span>
                  <b class="secondary--text" v-else>
                    {{ item.name }}
                  </b>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="py-0" v-if="must_show_new_calendar_name">
              <v-text-field
                v-model="item_to_edit.calendar_name"
                maxlength="50"
                counter
                :label="$t('new_calendar_name')"
                :rules="[v => !!v || $t('required')]"
                :placeholder="$t('new_calendar_name')"
                outlined
                rounded
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="primary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="moveEvent()"
            :disabled="!can_move_calendar"
          >
            {{ $t("move") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideMoveEvent"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CREATE/EDIT EVENT -->
    <v-dialog
      v-model="show_edit_event"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="450"
    >
      <v-card>
        <v-card-title
          center
          class="headline justify-center py-6"
          v-if="item_to_edit.id"
          >{{ $t("edit_event") }}
        </v-card-title>
        <v-card-title center class="headline justify-center py-6" v-else
          >{{ $t("new_event") }}
        </v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-form ref="edit_event_form" v-model="can_set_calendar">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="item_to_edit.name"
                  maxlength="50"
                  counter
                  :label="$t('name')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('name')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-select
                  v-model="item_to_edit.type"
                  :items="calendar_store.types"
                  item-text="text"
                  item-value="value"
                  :label="$t('type')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('select_type')"
                  mandatory
                  rounded
                  outlined
                  dense
                  class=""
                >
                  <template v-slot:[`item`]="{ item }"
                    ><v-icon small class="mr-1"
                      >{{ getEventTypeIcon(item.value) }}
                    </v-icon>
                    {{ item.text }}</template
                  >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-select
                  v-model="item_to_edit.impact"
                  :items="calendar_store.impacts"
                  item-text="text"
                  item-value="value"
                  :label="$t('impact')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('select_impact')"
                  mandatory
                  rounded
                  outlined
                  dense
                  class=""
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" class="py-0">
                <v-select
                  :disabled="item_to_edit.id === null"
                  v-model="item_to_edit.repeat"
                  :items="calendar_store.repeats"
                  item-text="text"
                  item-value="value"
                  :label="$t('repeat')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('select_repeat')"
                  mandatory
                  rounded
                  outlined
                  dense
                  class=""
                >
                </v-select>
              </v-col>
              <v-col
                v-show="!calendar_id && !item_to_edit.id"
                cols="12"
                sm="12"
                class="py-0"
              >
                <v-select
                  rounded
                  outlined
                  dense
                  v-model="item_to_edit.calendar_id"
                  :items="available_editable_calendars"
                  item-text="name"
                  item-value="id"
                  :label="$t('target_calendar')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('select')"
                >
                  <template v-slot:[`item`]="{ item }">
                    <span v-if="item.id !== 'new'">
                      {{ item.name }}
                    </span>
                    <b class="secondary--text" v-else>
                      {{ item.name }}
                    </b>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                class="py-0"
                v-if="must_show_new_calendar_name"
              >
                <v-text-field
                  v-model="item_to_edit.calendar_name"
                  maxlength="50"
                  counter
                  :label="$t('new_calendar_name')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('new_calendar_name')"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="10" class="py-0">
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatedEventPeriod"
                      flat
                      readonly
                      dense
                      solo
                      rounded
                      outlined
                      background-color="white"
                      append-icon="mdi-calendar-month-outline"
                      v-on="on"
                      style="width:100%; flex: 0 0 auto;display: inline-flex; cursor:pointer"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="event_period"
                    :allowed-dates="eventPeriodAllowedDate"
                    range
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="py-0">
                <i class="d-block subtitle-1 font-weight-bold pt-2 text-left"
                  >{{ total_days }} días</i
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setEvent()"
            :disabled="!can_set_calendar"
            v-if="item_to_edit.id"
          >
            {{ $t("set_event") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addEvent()"
            :disabled="!can_set_calendar"
          >
            {{ $t("add_event") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditEvent"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");
import * as Utils from "@/utils.js";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    item_to_edit: {},
    show_delete_event: false,
    show_move_event: false,
    show_edit_event: false,
    original_calendar_id: null,
    event_period: [],
    can_set_calendar: null,
    search: ""
  }),
  props: {
    events: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    },
    available_calendars: {
      type: Array,
      default: function() {
        return [];
      },
      required: true
    },
    calendar_id: {
      type: Number,
      default: null,
      required: false
    }
  },
  filters: {},
  watch: {},
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    getEventTypeIcon(type) {
      return Utils.getEventTypeIcon(type);
    },
    getImpactColor(impact) {
      return this.calendar_store.impacts.find(i => i.value === impact).color;
    },
    clearEditForm() {
      if (this.$refs.edit_event_form) {
        this.$refs.edit_event_form.resetValidation();
      }
    },
    showAddEvent() {
      this.clearEditForm();
      this.show_edit_event = true;
      this.item_to_edit = {
        calendar_name: null,
        calendar_id: this.calendar_id ? this.calendar_id : null,
        hotel_id: this.calendar_id ? null : this.current_hotel.id
      };
      this.event_period = [
        Vue.moment().format("YYYY-MM-DD"),
        Vue.moment().format("YYYY-MM-DD")
      ];
    },
    showEditEvent(event) {
      if (!event) {
        return;
      }
      if (!event.editable && !this.calendar_id) {
        this.$store.dispatch("setSnackbar", {
          title: this.$t("you_cannot_modify_event"),
          color: "error"
        });
        return;
      }
      this.clearEditForm();
      this.show_edit_event = true;
      this.item_to_edit = JSON.parse(JSON.stringify(event));
      this.item_to_edit.calendar_name = null;
      this.item_to_edit.hotel_id = this.calendar_id
        ? null
        : this.current_hotel.id; // Solo si estoy en la página de Calendario del hotel
      this.event_period = [event.start_date, event.end_date];
    },
    hideEditEvent() {
      this.show_edit_event = false;
      this.item_to_edit = {};
    },
    setEvent: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_event"),
        color: "secondary"
      });

      let sd = Vue.moment(this.event_period[0]);
      let ed = Vue.moment(this.event_period[1]);
      if (sd.isAfter(ed)) {
        this.item_to_edit.start_date = this.event_period[1];
        this.item_to_edit.end_date = this.event_period[0];
      } else {
        this.item_to_edit.start_date = this.event_period[0];
        this.item_to_edit.end_date = this.event_period[1];
      }

      this.item_to_edit.calendar_id =
        this.item_to_edit.calendar_id === "new"
          ? null
          : this.item_to_edit.calendar_id;
      this.$store
        .dispatch(this.setEventFunctionName, this.item_to_edit)
        .then(() => {
          this.show_edit_event = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("event_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getCalendars();
        })
        .catch(() => {
          this.show_edit_event = false;
          this.item_to_edit = {};
          Utils.showError(this, "event_not_modified");
        });
    },
    addEvent: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_event"),
        color: "secondary"
      });

      let sd = Vue.moment(this.event_period[0]);
      let ed = Vue.moment(this.event_period[1]);
      if (sd.isAfter(ed)) {
        this.item_to_edit.start_date = this.event_period[1];
        this.item_to_edit.end_date = this.event_period[0];
      } else {
        this.item_to_edit.start_date = this.event_period[0];
        this.item_to_edit.end_date = this.event_period[1];
      }

      this.item_to_edit.calendar_id =
        this.item_to_edit.calendar_id === "new"
          ? null
          : this.item_to_edit.calendar_id;
      this.$store
        .dispatch(this.setEventFunctionName, this.item_to_edit)
        .then(() => {
          this.show_edit_event = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("event_modified"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getCalendars();
        })
        .catch(() => {
          this.show_edit_event = false;
          this.item_to_edit = {};
          Utils.showError(this, "event_not_modified");
        });
    },
    showDeleteEvent(event) {
      if (!event) {
        return;
      }
      if (!event.editable && !this.calendar_id) {
        this.$store.dispatch("setSnackbar", {
          title: this.$t("you_cannot_modify_event"),
          color: "error"
        });
        return;
      }
      this.show_delete_event = true;
      this.item_to_edit = event;
    },
    hideDeleteEvent() {
      this.show_delete_event = false;
      this.item_to_edit = {};
    },
    deleteEvent: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_event"),
        color: "secondary"
      });

      this.$store
        .dispatch("deleteEvent", { id: this.item_to_edit.id })
        .then(() => {
          this.show_delete_event = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("event_deleted"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getCalendars();
        })
        .catch(() => {
          this.show_delete_event = false;
          this.item_to_edit = {};
          Utils.showError(this, "event_not_deleted");
        });
    },
    showMoveEvent(event) {
      if (!event) {
        return;
      }
      if (!event.editable && !this.calendar_id) {
        this.$store.dispatch("setSnackbar", {
          title: this.$t("you_cannot_modify_event"),
          color: "error"
        });
        return;
      }
      this.show_move_event = true;
      this.item_to_edit = JSON.parse(JSON.stringify(event));
      this.original_calendar_id = event.calendar_id;
      this.item_to_edit.hotel_id = this.calendar_id
        ? null
        : this.current_hotel.id; // Solo si estoy en la página de Calendario del hotel
    },
    hideMoveEvent() {
      this.show_move_event = false;
      this.original_calendar_id = null;
      this.item_to_edit = {};
    },
    moveEvent: function() {
      var vm = this;
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_event"),
        color: "secondary"
      });

      this.show_move_event = false;
      this.original_calendar_id = null;
      this.item_to_edit.calendar_id =
        this.item_to_edit.calendar_id === "new"
          ? null
          : this.item_to_edit.calendar_id;
      this.$store
        .dispatch(this.setEventFunctionName, this.item_to_edit)
        .then(() => {
          this.$store.dispatch("setSnackbar", {
            title: this.$t("event_moved"),
            color: "accent"
          });
          this.item_to_edit = {};
          this.getCalendars();
        })
        .catch(() => {
          this.item_to_edit = {};
          Utils.showError(this, "event_not_moved");
        });
    },

    changedEventPeriod(e) {},
    eventPeriodAllowedDate(date) {
      return true;
    },
    getCalendars() {
      this.$emit("getCalendars");
    }
  },
  computed: {
    available_editable_calendars() {
      return this.available_calendars.filter(c => c.editable || c.id === "new");
    },
    setEventFunctionName() {
      return this.calendar_id ? "setCalendarEvent" : "setHotelEvent";
    },
    filteredEvents() {
      let events = this.events;
      if (this.search && this.search.length > 0) {
        events = events.filter(e =>
          e.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.extendEvents(events);
    },
    must_show_new_calendar_name() {
      return this.item_to_edit.calendar_id === "new";
    },
    total_days() {
      return Utils.getDuration(this.event_period[0], this.event_period[1]);
    },
    formatedEventPeriod() {
      return Utils.getFriendlyPeriod(
        this.event_period[0],
        this.event_period[1]
      );
    },
    can_move_calendar() {
      return this.item_to_edit.calendar_id !== this.original_calendar_id;
    },
    hotel_events_headers() {
      return [
        {
          text: this.$t("event"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("dates"),
          align: "start",
          sortable: true,
          value: "start_date",
          width: "220px"
        },
        {
          text: this.$t("calendar"),
          align: "start",
          sortable: true,
          value: "original_calendar_name"
        },
        {
          text: this.$t("impact"),
          align: "center",
          sortable: true,
          value: "impact"
        },
        {
          text: this.$t("repeat"),
          align: "center",
          sortable: true,
          value: "repeat"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions",
          width: "120px"
        }
      ];
    },
    ...mapState({
      calendar_store: state => state.calendar
      // hotel_events: state => state.calendar.hotel_events,
    }),
    ...mapGetters({
      getHotelCalendars: "getHotelCalendars",
      current_hotel: "current_hotel",
      extendEvents: "extendEvents"
    })
  }
};
</script>
