<template>
  <a-page
    id="customer-management-app"
    :image_source="image_source"
    :image_height="image_height"
  >
    <template v-slot:header_options>
      <v-btn
        rounded
        color="secondary"
        :disabled="!valid_edit"
        @click="setCustomer"
        :loading="saving"
        >{{ $t("save") }}
      </v-btn>
    </template>

    <!-- EDIT CUSTOMER FORM -->
    <v-card color="">
      <v-card-text class="px-8">
        <v-form v-model="valid_edit">
          <permissions-editor
            :item="customer"
            :all_available="true"
          ></permissions-editor>
        </v-form>
      </v-card-text>
    </v-card>
  </a-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import PermissionsEditor from "@/components/permissions/PermissionsEditor";

export default {
  components: {
    aPage,
    PermissionsEditor
  },
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 280, //Mandatory number
    // type: {},
    saving: false,
    valid_edit: true
  }),
  methods: {
    setCustomer: function() {
      var vm = this;
      this.saving = true;
      vm.$store.dispatch("setSnackbar", {
        title: vm.$t("modifying_customer"),
        color: "secondary"
      });
      this.$store
        .dispatch("updateChainPermissions", this.customer)
        .then(() => {
          //Save modifications
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("modified_customer"),
            text: this.$t("modified_customer_ok", {
              name: this.name
            }),
            color: "accent"
          });
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("setSnackbar", {
            title: this.$t("something_went_wrong"),
            color: "error"
          });
        });
    }
  },
  computed: {
    name() {
      return window.location.hostname.split(".")[0];
    },
    customer() {
      return {
        name: this.name,
        permissions: this.available_permissions
      };
    },
    can_submit() {
      return true;
    },
    ...mapState({
      snackbar: state => state.snackbar,
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({})
  }
};
</script>
<style>
.hotel_selected {
  background-color: #0084c8 !important;
}
</style>
