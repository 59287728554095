var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4 mb-2"}},[_c('v-card-text',{staticClass:"white pa-0"},[_c('div',{staticClass:"grid-container",attrs:{"id":"rms-year-sales-table"}},[(_vm.group_of_columns)?_c('table',[_c('thead',[_c('tr',{staticClass:"group-row"},_vm._l((_vm.group_names),function(group){return _c('th',{key:group,class:[
                {
                  'sticky-columns-shadow': group === _vm.last_sticky_group_column,
                  'br-bolder': group !== _vm.last_sticky_group_column
                }
              ],attrs:{"colspan":_vm.group_of_columns[group].length}},[(group)?[_c('div',{staticClass:"column-group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(group.toUpperCase() !== "BUD" ? group.toUpperCase() : "BUDGET")+" ")]),(group !== 'otb' && group !== 'forecast')?[_c('span',[_vm._v(" vs OTB ")])]:_vm._e(),(group === 'forecast')?[_c('span',[_vm._v(" vs BUDGET ")])]:_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(group)))])])],1)]:_vm._e()],2)}),0),_c('tr',{staticClass:"header-row"},_vm._l((_vm.column_headers),function(column_header){return _c('th',{key:((column_header.group) + "-" + (column_header.column)),class:[
                {
                  'sticky-columns-shadow':
                    column_header.column === _vm.last_sticky_group_column,
                  'br-bolder':
                    _vm.last_column_of_each_group[column_header.group] ===
                      column_header.column &&
                    column_header.group !== _vm.last_sticky_group_column
                }
              ],style:({
                width: column_header.column == 'date' ? '200px' : '100px'
              })},[_c('div',{staticClass:"cell",class:{
                  'kpi-row': !_vm.sticky_columns.includes(column_header.column),
                  'kpi-hovered':
                    _vm.kpi_hover.column === column_header.column &&
                    _vm.kpi_hover.group === column_header.group
                },style:({
                  width: column_header.column == 'date' ? '200px' : '100px'
                })},[_c('span',{staticClass:"d-block day-name grey--text text--darken-3 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t(column_header.column))+" ")])])])}),0)]),_c('tbody',[_vm._l((_vm.rows),function(row){return [(row.type === 'month')?_c('aRmsYearSalesTableRow',{key:row.label,staticClass:"row-data",attrs:{"label":row.label,"row":_vm.monthsValues[row.id],"nonStickyColumnHeaders":_vm.non_sticky_column_headers,"lastStickyColumn":_vm.last_sticky_column,"configYearSalesTable":_vm.configYearSalesTable,"lastColumnOfEachGroup":_vm.last_column_of_each_group,"kpiMode":_vm.kpiMode},on:{"hoveredKpi":function($event){_vm.kpi_hover = $event}}}):_vm._e(),(row.type === 'quarter')?_c('aRmsYearSalesTableRow',{key:row.label,staticClass:"row-data quarter",attrs:{"label":row.label,"row":_vm.quartersValues[row.id],"nonStickyColumnHeaders":_vm.non_sticky_column_headers,"lastStickyColumn":_vm.last_sticky_column,"configYearSalesTable":_vm.configYearSalesTable,"lastColumnOfEachGroup":_vm.last_column_of_each_group,"kpiMode":_vm.kpiMode},on:{"hoveredKpi":function($event){_vm.kpi_hover = $event}}}):_vm._e()]}),_c('aRmsYearSalesTableRow',{staticClass:"row-data summary",attrs:{"date":null,"row":_vm.yearValues,"label":_vm.$t('total'),"nonStickyColumnHeaders":_vm.non_sticky_column_headers,"lastStickyColumn":_vm.last_sticky_column,"configYearSalesTable":_vm.configYearSalesTable,"lastColumnOfEachGroup":_vm.last_column_of_each_group,"isSummary":"","kpiMode":_vm.kpiMode},on:{"hoveredKpi":function($event){_vm.kpi_hover = $event}}})],2)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }