<template>
  <div>
    <v-row class="mx-0">
      <v-col class="kpi-cell spacer pa-0"> </v-col>
      <v-col class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col
            v-for="col of columns"
            :key="col.value"
            :class="classCol(col)"
            @click="kpi_selected = col.value"
            @mouseover="kpi_hover = col.value"
            @mouseleave="kpi_hover = ''"
          >
            {{ $t(col.value) }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "a-rms-year-sales-columns",
  components: {},
  props: {
    columns: Array
  },
  computed: {
    kpi_selected: {
      get() {
        return this.$store.state.rms.year_sales_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiGraph", value);
      }
    },
    kpi_hover: {
      get() {
        return this.$store.state.rms.year_sales_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesKpiHover", value);
      }
    }
  },
  methods: {
    classCol(col) {
      let clas =
        "pa-0 kpi-cell kpi-cell-row d-flex align-center justify-center";
      if (this.kpi_selected == col.value) {
        clas += " kpi-selected";
      }
      if (this.kpi_hover == col.value) {
        clas += " kpi-hover";
      }
      return clas;
    }
  }
};
</script>
<style lang="scss" scoped>
.hotelsdot-year-sales-table {
  &:hover {
    .kpi-hover {
      border-bottom: 3px solid #2d6ece;
    }
  }
}
.spacer {
  min-width: 150px;
  max-width: 150px;
  z-index: 1;
}
.kpi-cell {
  height: $minHeightColRow;
  border-left: $borderType $borderColorV;
  border-right: $borderType $borderColorV;
  border-bottom: 3px solid #ffffff;
  cursor: pointer;
  display: block;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
  width: 100%;

  &.kpi-selected {
    background-color: #f5fdff;
  }
}
</style>
