<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :color="isGroup ? '#ffffff' : '#f1f1f1'"
  >
    <template v-slot:header_options>
      <a-btn-pdf
        v-if="has_permission('export_pdf')"
        :config="pdf_config"
        class="mr-4"
      ></a-btn-pdf>
      <a-rms-range-selector
        v-if="!isGroup"
        ref="a-rms-range-selector"
        :event_period="[strategy_from, strategy_to]"
        @updatePeriod="updateRange"
        class="mr-2"
      >
      </a-rms-range-selector>
      <a-rms-import-dates
        ref="rms_import_dates"
        v-if="!isGroup"
      ></a-rms-import-dates>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <rms-strategy-axel-app
      v-else
      ref="rms-strategy-app"
      :event_period="[strategy_from, strategy_to]"
    ></rms-strategy-axel-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import rmsStrategyAxelApp from "@/components/app/axel/rmsStrategyApp";
import aRmsRangeSelector from "@/components/rms/aRmsRangeSelector";
//import aRmsVsSelector from "@/components/rms/aRmsVsSelector";
//import aRmsVsSelectorSingle from "@/components/rms/aRmsVsSelectorSingle";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms";
import aRmsImportDates from "@/components/rms/aRmsImportDates";
import aBtnPdf from "@/components/aBtnPdf.vue";

export default {
  name: "rms-strategy",
  components: {
    aBtnPdf,
    aPage,
    rmsStrategyAxelApp,
    aRmsRangeSelector,
    // aRmsVsSelector,
    // aRmsVsSelectorSingle,
    aHotelSelectorRms,
    aRmsImportDates
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    event_period: []
  }),
  methods: {
    updateRange(v) {
      let range = JSON.parse(JSON.stringify(v));
      this.strategy_from = range[0];
      this.strategy_to = range[1];
    },
    getFriendlyDate(date) {
      return Vue.moment(date).format("DD/MM/YYYY");
    }
  },
  watch: {},
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 105 : 200;
    },
    strategy_from: {
      get() {
        return this.$store.state.rms.strategy_from;
      },
      set(value) {
        this.$store.dispatch("setStrategyFrom", value);
      }
    },
    strategy_to: {
      get() {
        return this.$store.state.rms.strategy_to;
      },
      set(value) {
        this.$store.dispatch("setStrategyTo", value);
      }
    },
    pdf_config() {
      return {
        filename:
          this.$t("revenue_strategy") +
          " - " +
          this.current_hotel.name +
          " -- " +
          this.getFriendlyDate(this.strategy_from) +
          " - " +
          this.getFriendlyDate(this.strategy_to),
        elements: [
          {
            id: "rms-strategy-table",
            is_table: true
          }
        ],
        hotel_name: this.current_hotel.name,
        section_name: this.$t("revenue_strategy")
      };
    },
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      has_permission: "has_permission"
    })
  },
  mounted() {
    // Initialize selector
    if (!this.strategy_from) {
      this.strategy_from = Vue.moment()
        .startOf("month")
        .format("YYYY-MM-DD");
    }
    if (!this.strategy_to) {
      this.strategy_to = Vue.moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    }
  }
};
</script>
