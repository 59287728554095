var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-base-component',{attrs:{"subscribed_modules":_vm.subscribed_modules},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0"},[_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},[_c('v-list-item',{on:{"mouseover":function($event){_vm.highlighted_date = _vm.dates['otb']['today']},"mouseleave":function($event){_vm.highlighted_date = ''}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.importStatusColor(_vm.dates['otb'])}},[_vm._v("mdi-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("OTB")]),_c('v-list-item-subtitle',[(
                        _vm.dates['otb']['today'] !== _vm.dates['otb']['import_date']
                      )?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("formatDayMonth")(_vm.dates["otb"]["today"])))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.dates["otb"]["import_date"])))])],1)],1)],1)],1),_c('v-col',{staticClass:"py-0"},[_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},[_c('v-list-item',{on:{"mouseover":function($event){_vm.highlighted_date = _vm.dates['stly']['today']},"mouseleave":function($event){_vm.highlighted_date = ''}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.importStatusColor(_vm.dates['stly'])}},[_vm._v("mdi-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("STLY")]),_c('v-list-item-subtitle',[(
                        _vm.dates['stly']['today'] !==
                          _vm.dates['stly']['import_date']
                      )?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("formatDayMonth")(_vm.dates["stly"]["today"])))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.dates["stly"]["import_date"])))])],1)],1)],1)],1),_c('v-col',{staticClass:"py-0"},[_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},[_c('v-list-item',{on:{"mouseover":function($event){_vm.highlighted_date = _vm.dates['ly']['today']},"mouseleave":function($event){_vm.highlighted_date = ''}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.importStatusColor(_vm.dates['ly'])}},[_vm._v("mdi-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("LY")]),_c('v-list-item-subtitle',[(
                        _vm.dates['ly']['today'] !== _vm.dates['ly']['import_date']
                      )?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("formatDayMonth")(_vm.dates["ly"]["today"])))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.dates["ly"]["import_date"])))])],1)],1)],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_pickup_dates),expression:"show_pickup_dates"}],attrs:{"dense":""}},_vm._l((_vm.pickups),function(spot){return _c('v-col',{key:spot['pickup_days'],staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},[_c('v-list-item',{on:{"mouseover":function($event){_vm.highlighted_date = spot['today']},"mouseleave":function($event){_vm.highlighted_date = ''}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.importStatusColor(spot)}},[_vm._v("mdi-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pickup -"+_vm._s(spot["pickup_days"]))]),_c('v-list-item-subtitle',[(spot['today'] !== spot['import_date'])?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("formatDayMonth")(spot["today"])))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("formatDate")(spot["import_date"])))])],1)],1)],1)],1)}),1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_pickup_dates),expression:"show_pickup_dates"}],attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"rounded-pill white d-flex align-center py-1 px-1",staticStyle:{"height":"100%","position":"relative","margin":"0 auto"}},_vm._l((_vm.dimension_filters_with_segmentation),function(filter,i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({class:i > 0 ? 'ml-2' : '',attrs:{"x-small":"","rounded":"","fab":"","text":_vm.revenue_dates_calendar_dimension !== filter.value,"color":_vm.revenue_dates_calendar_dimension === filter.value
                          ? 'secondary'
                          : 'black'},on:{"click":function($event){_vm.revenue_dates_calendar_dimension = filter.value}}},on),[_c('v-icon',{attrs:{"x-small":"","color":_vm.revenue_dates_calendar_dimension === filter.value
                            ? 'white'
                            : 'black'}},[_vm._v(_vm._s(filter.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(filter.label)))])])}),1)]),_c('import-dates-graph',{attrs:{"calendar":_vm.revenue_dates_calendar.calendar,"highlight":_vm.highlighted_date}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center py-0"},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"white","small":""},on:{"click":function($event){_vm.show_pickup_dates = !_vm.show_pickup_dates}}},[(!_vm.show_pickup_dates)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],1)],1)]},proxy:true},{key:"loading",fn:function(){return [_c('v-card',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-skeleton-loader',{staticClass:"py-3",attrs:{"type":"list-item-avatar"}})],1),_c('v-col',[_c('v-skeleton-loader',{staticClass:"py-3",attrs:{"type":"list-item-avatar"}})],1),_c('v-col',[_c('v-skeleton-loader',{staticClass:"py-3",attrs:{"type":"list-item-avatar"}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }