<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "a-hotelsdot-year-sales-adr-period-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Array,
      default: function() {
        return [];
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      return {
        type: "line",
        plot: {
          aspect: "spline",
          lineWidth: 3,
          marker: {
            size: 5,
            borderWidth: 1,
            shadow: 1
          }
        },
        rules: this.columnsColor,
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 50px 50px 80px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels
        },
        scaleY: {
          lineWidth: 0,
          values: this.scaleValues,
          format: "%v€",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        series: [
          {
            values: this.series
          }
        ],
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    columnsColor() {
      let rules = [];
      this.series.forEach((value, i) => {
        let sui = this.hotelsdot.series_ui[this.labels[i]];
        rules.push({
          rule: `%i == ${i}`,
          backgroundColor: sui ? sui.color : ""
        });
      });
      return rules;
    },
    max_value() {
      let max = 0;
      this.series.forEach((value, i) => {
        if (value > max) {
          max = value;
        }
      });
      return parseInt(max + 5);
    },
    min_value() {
      let min = this.max_value;
      this.series.forEach((value, i) => {
        if (value < min) {
          min = value;
        }
      });
      return parseInt(min - 5 > 0 ? min - 5 : 0);
    },
    scaleValues() {
      return (
        this.min_value +
        ":" +
        this.max_value +
        ":" +
        parseInt((this.max_value - this.min_value) / 4)
      );
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
