<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span
        v-if="!simple"
        class="ml-1 d-flex justify-center align-center"
        style="font-size: 0.8rem"
        v-on="on"
      >
        <v-icon v-if="!absolute" :color="color" style="font-size: 1.2em;">{{
          icon
        }}</v-icon>
        <template v-if="increment">
          <span
            style="margin-left: -2px;"
            :class="color + '--text'"
            v-if="!absolute"
            v-html="increment_value"
          >
          </span>
          <span
            v-else
            class="ml-1"
            :class="color + '--text'"
            v-html="formatVs(value)"
          >
          </span>
        </template>
        <template v-else>
          <span class="ml-1" v-html="'&nbsp;'"> </span>
        </template>
      </span>
      <v-icon
        v-else
        v-on="on"
        class="ml-1"
        style="font-size: 1.2em;"
        :color="color"
        >{{ icon }}</v-icon
      >
    </template>
    <!-- 3 casos possibles: -->
    <!-- Es farà quan només rebem el valor actual i l'anterior i nosaltres calculem tota la resta, sempre igual: -->
    <!-- Si ensenyem increment:      tooltip valor anterior (+- diferència)   - !absolute && increment_type == "percent" -->
    <!-- Si ensenyem diferència:     tooltip valor anterior (+- increment)    - !absolute && increment_type == "absolute" -->
    <!-- Si ensenyem valor anterior: tooltip +-diferència (+- increment)      - absolute -->

    <span v-if="!absolute" v-html="formatVs(value)"></span>
    <span
      v-else
      v-html="(increment > 0 ? '+' : '-') + format(increment) + '%'"
    ></span>
  </v-tooltip>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { format_kpi_value } from "@/utils.js";
var numeral = require("numeral");

export default {
  name: "a-increment",
  components: {},
  props: {
    value: {
      type: Number,
      default: undefined
    },
    increment: {
      type: Number,
      default: undefined
    },
    kpi: {
      type: String,
      default: ""
    },
    simple: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    },
    increment_type: {
      type: String,
      default: "percent"
    }
  },
  data: vm => ({
    hover: false
  }),
  methods: {
    format(v) {
      if (!v) {
        return "-";
      }
      // Depending on kpi type
      return numeral(Math.abs(v)).format(this.l.number_format);
    },
    formatVs(v) {
      return format_kpi_value(v, this.type, this.l);
    }
  },
  computed: {
    increment_value() {
      return this.increment_type == "absolute"
        ? this.formatVs(Math.abs(this.increment))
        : this.format(this.increment) + "%";
    },
    type() {
      return this.get_kpi(this.kpi).data_format;
    },
    icon() {
      var icon = "";
      if (this.increment > 0 && this.increment <= 1) {
        icon = "mdi-chevron-up";
      }
      if (this.increment > 1 && this.increment <= 10) {
        icon = "mdi-chevron-double-up";
      }
      if (this.increment > 10) {
        icon = "mdi-chevron-triple-up";
      }

      if (this.increment < 0 && this.increment >= -1) {
        icon = "mdi-chevron-down";
      }
      if (this.increment < -1 && this.increment >= -10) {
        icon = "mdi-chevron-double-down";
      }
      if (this.increment < -10) {
        icon = "mdi-chevron-triple-down";
      }
      return icon;
    },
    color() {
      return this.increment > 0
        ? "green"
        : this.increment == 0
        ? "grey"
        : "red";
    },
    ...mapState({
      chain: state => state.chain.chain
    }),
    ...mapGetters({
      get_kpi: "get_kpi",
      current_hotel: "current_hotel",
      l: "current_hotel_language"
    })
  }
};
</script>
