var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"kpi-row mx-0",class:hover ? _vm.color_class : _vm.color_class,on:{"click":_vm.getDayDetails}},[_c('v-col',{staticClass:"kpi-label-wrapper pa-0"},[_c('div',{staticClass:"kpi-label d-flex align-center"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.label)}}),(_vm.isToday)?_c('v-chip',{staticClass:"ml-2",attrs:{"dark":"","x-small":"","color":"blue"}},[_vm._v(_vm._s(_vm.$t("today")))]):_vm._e(),_c('v-spacer'),_vm._l((_vm.impacts),function(impact){return _c('a-rms-strategy-day-events',{key:impact.impact,attrs:{"impact":impact,"events":_vm.events,"date":_vm.date}})})],2),(
                _vm.rms.strategy_vs_spot === 'stly' ||
                  _vm.rms.strategy_vs_spot === 'ly'
              )?_c('div',{staticClass:"d-flex justify-start align-center grey--text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.label_vs)}}),_c('v-spacer'),_vm._l((_vm.impacts_vs),function(impact_vs){return _c('a-rms-strategy-day-events',{key:impact_vs.impact,attrs:{"impact":impact_vs,"events":_vm.events_vs,"date":_vm.date_vs}})})],2):_vm._e()])])]),_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.columns),function(col,index){return _c('v-col',{key:index,staticClass:"pa-0 d-flex kpi-cell kpi-cell-row align-center justify-center",class:_vm.getClass(col.kpi, _vm.day_data.otb[col.kpi]),on:{"mouseover":function($event){_vm.kpi_hover = col.kpi},"mouseleave":function($event){_vm.kpi_hover = ''}}},[(_vm.day_data[_vm.rms.strategy_vs_spot][col.kpi])?[(_vm.rms.strategy_vs_spot.startsWith('pickup_'))?[_c('a-kpi',{attrs:{"kpi":col.kpi,"value":_vm.day_data.otb[col.kpi],"vs_values":[
                    _vm.day_data.otb[col.kpi] -
                      _vm.day_data[_vm.rms.strategy_vs_spot][col.kpi]
                  ],"vs_periods":[_vm.rms.strategy_vs_spot],"x_small":"","vertical":""}})]:[_c('a-kpi',{attrs:{"kpi":col.kpi,"value":_vm.day_data.otb[col.kpi],"vs_values":[_vm.day_data[_vm.rms.strategy_vs_spot][col.kpi]],"vs_periods":[_vm.rms.strategy_vs_spot],"x_small":"","vertical":""}})],(_vm.showProgressPercent(col.kpi))?_c('div',{staticClass:"percent-progress",style:(_vm.getPercentStyle(_vm.day_data.otb[col.kpi]))}):_vm._e()]:[_c('a-kpi',{attrs:{"kpi":col.kpi,"value":_vm.day_data.otb[col.kpi],"x_small":"","vertical":""}})]],2)}),1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }