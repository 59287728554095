var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell-padding",class:Object.assign({}, _vm.bgColor,
    {'info--text': _vm.rate.edited})},[_c('v-row',{staticClass:"h-half pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0"},[_c('a-kpi',{attrs:{"kpi":'price',"value":_vm.rate[_vm.objKey],"increment":true,"vs_value":_vm.initialValue,"increment_value":_vm.initialValue && _vm.rate[_vm.objKey] - _vm.initialValue,"simple":true}})],1)],1),_c('v-row',{staticClass:"h-half text-center pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0"},[(_vm.rate.stop_sell)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text--lighten-1",class:{
              'grey--text': !_vm.rate.edited,
              'info--text': _vm.rate.edited
            },attrs:{"size":"12"}},'v-icon',attrs,false),on),[_vm._v(" mdi-hand-back-right-off ")])]}}],null,false,403470898)},[_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.$t("stop_sell")))])]):_c('span',[_vm._v(" "+_vm._s(" ")+" ")])],1),_c('v-col',{staticClass:"pa-0"},[(_vm.rate.release)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text--lighten-1",class:{
              'grey--text':
                !_vm.rate.edited && _vm.rate.release === _vm.rate.original_release,
              'info--text':
                _vm.rate.edited && _vm.rate.release === _vm.rate.original_release,
              'green--text': _vm.rate.release !== _vm.rate.original_release
            },attrs:{"size":"16"}},'v-icon',attrs,false),on),[_vm._v(" mdi-numeric-"+_vm._s(_vm.rate.release)+"-circle-outline ")])]}}],null,false,3519795101)},[_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.$t("release_days")))])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }