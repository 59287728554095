<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pl-0">
          {{ $t("settings_compset_booking_url") }}
        </v-card-title>
        <a-booking-url-field
          v-model="item.settings.me_competitor_booking_url"
          :label="$t('hotel_booking_url')"
          hint="https://www.booking.com/hotel/{iso}/{nombre-hotel}.{iso}.html"
          :clean-https="false"
          class="pr-8"
        ></a-booking-url-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pl-0">
          {{ $t("settings_compset_competitors") }}
        </v-card-title>
        <v-list class="pt-0">
          <v-list-item
            v-for="(url, index) in item.settings.competitors_booking_urls"
            :key="index"
            class="pl-0 pr-8"
          >
            <v-list-item-content class="pt-0 pb-1">
              <v-list-item-title>
                <a-booking-url-field
                  v-model="item.settings.competitors_booking_urls[index]"
                  :label="$t('hotel_booking_url')"
                  hint="https://www.booking.com/hotel/{iso}/{nombre-hotel}.{iso}.html"
                  :clean-https="false"
                  @paste="onPaste"
                ></a-booking-url-field>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ma-0 align-self-start">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="removeUrl(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                {{ $t("delete_url") }}
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <div class="d-flex justify-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="addUrl">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              {{ $t("add_url") }}
            </v-tooltip>
          </div>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import aBookingUrlField from "@/components/aBookingUrlField";

export default {
  name: "a-hotel-compset",
  components: {
    aBookingUrlField
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    }
  },
  methods: {
    addUrl() {
      this.item.settings.competitors_booking_urls.push("");
    },
    removeUrl(index) {
      this.item.settings.competitors_booking_urls.splice(index, 1);
    },
    onPaste: function(e) {
      let pastedText = undefined;
      if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData("Text");
      } else if (e.clipboardData && e.clipboardData.getData) {
        pastedText = e.clipboardData.getData("text/plain");
      }
      if (pastedText !== undefined) {
        const lines = pastedText.split("\n");
        if (lines.length > 1) {
          e.preventDefault();
          this.item.settings.competitors_booking_urls = [];
          for (let i = 0; i < lines.length; i++) {
            if (lines[i].trim() !== "") {
              this.item.settings.competitors_booking_urls.push(lines[i].trim());
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-list-item__action {
  margin-top: 20px !important;
  min-width: auto !important;
  .v-btn--icon.v-size--default {
    height: auto;
    width: auto;
  }
}
</style>
