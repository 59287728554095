<template>
  <div>
    <v-row class="calendar_year">
      <v-col
        cols="12"
        md="4"
        xl="3"
        v-for="(month, month_index) in yearly_calendar"
        :key="'month_' + month_index"
      >
        <v-card flat class="calendar_month">
          <div class="calendar_month_name">
            {{ month_index }}
          </div>
          <div class="calendar_week_header">
            <div class="calendar_weekday_header"></div>
            <div
              class="calendar_weekday_header"
              v-for="dayweek in 7"
              :key="dayweek"
            >
              {{ $t("dayweek_" + dayweek + "_abr") }}
            </div>
          </div>

          <div class="calendar_month_content">
            <div
              v-for="(week, week_index) in month"
              :key="'week_' + week_index"
              class="calendar_week"
            >
              <div class="calendar_week_number justify-center">
                <div class="calendar_day_content_number">S{{ week_index }}</div>
              </div>
              <div
                v-for="(day, day_index) in week"
                :key="'day' + day_index"
                class="calendar_day"
              >
                <v-menu top left dense v-if="day">
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      color="grey lighten-2"
                      icon="mdi-comment"
                      overlap
                      small
                      dark
                      :value="hasComments(day.date)"
                    >
                      <template v-if="day">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="calendar_day_content"
                          :class="{ closed: !day.active }"
                        >
                          {{ getDay(day.date) }}
                        </div>
                      </template>
                      <event-progress
                        :date="day.date"
                        :events="getDayEvents(day.date)"
                      ></event-progress>
                    </v-badge>
                  </template>
                  <v-list dense>
                    <v-list-item
                      @click="setOpeningDate(day.date)"
                      v-if="day.can_open"
                    >
                      <v-list-item-title
                        ><v-icon small class="mr-2"
                          >mdi-check-circle-outline</v-icon
                        >{{ $t("set-opening-date") }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="setClosingDate(day.date)"
                      v-if="day.can_close"
                    >
                      <v-list-item-title
                        ><v-icon small class="mr-2"
                          >mdi-close-circle-outline</v-icon
                        >{{ $t("set-closing-date") }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import EventProgress from "@/components/calendar/EventProgress.vue";

export default {
  name: "CalendarOpenings",
  components: {
    EventProgress
  },
  computed: {
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      yearly_calendar: "getYearlyCalendar",
      getDayEvents: "getDayEvents"
    })
  },
  methods: {
    hasComments(date) {
      return this.calendar_store && this.calendar_store.hotel_comments
        ? this.calendar_store.hotel_comments.filter(c => c.date === date).length
        : 0;
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    setOpeningDate(date) {
      this.$store
        .dispatch("setOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          opening_date: date,
          closing_date: this.calendar_store.closing_date
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    setClosingDate(date) {
      this.$store
        .dispatch("setOpeningPeriod", {
          hotel_id: this.current_hotel.id,
          opening_date: this.calendar_store.opening_date,
          closing_date: date
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    }
  }
};
</script>

<style scoped>
.calendar_year {
  text-align: center;
}
.calendar_month {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px;
  min-height: 350px;
}
.calendar_month_name {
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto;
  color: #444;
  text-transform: lowercase;
}
.calendar_week_number {
  width: 30px;
  display: flex;
  align-items: center;
  background: #e5e5e5;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: default;
}
.calendar_week_number .calendar_day_content_number {
  color: #ffffff;
  line-height: 33px;
  cursor: default;
}
.calendar_week_header {
  display: flex;
  margin: 20px 0 10px 0;
}
.calendar_weekday_header:first-of-type {
  width: 30px;
  flex: none;
}
.calendar_weekday_header {
  flex: 1;
  color: #cacaca;
  font-weight: 500;
}
.calendar_week {
  margin-bottom: 10px;
  display: flex;
}
.calendar_day {
  flex: 1;
}
.calendar_day_content {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #4bd;
  color: white;
  margin: 0 auto;
  line-height: 30px;
  cursor: pointer;
  background: #4bd;
  font-size: 13px;
  font-weight: 500;
}
.calendar_day_content.blocked {
  cursor: not-allowed;
}
.calendar_day_content_number {
  width: 30px;
  height: 30px;
  border: none;
  color: #cacaca;
  margin: 0 auto;
  line-height: 25px;
  cursor: default;
  background: none;
}
.calendar_day_content:hover {
  border: 1px solid #4bd !important;
  background: #f5fdff;
  color: #4bd;
}
.calendar_day_content.closed {
  border: 1px solid #dedede;
  background: none;
  color: #333333;
}
</style>
