<template>
  <v-hover v-slot="{ hover }" open-delay="200">
    <v-card
      :elevation="hover ? 3 : 0"
      @click.stop="setCurrentHotel(hotel)"
      class="transition-swing mb-3"
      flat
    >
      <v-row class="mx-0">
        <v-col cols="12" md="3">
          <v-card-title class="pb-0">{{ hotel.name }}</v-card-title>
          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-rating
                :value="hotel.settings.stars"
                color="primary"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="9">
          <div class="d-flex" v-if="Object.keys(hotel_parities).length">
            <div
              class="mr-1"
              style="flex:1;"
              v-for="day in next_days"
              :key="'ranking_day_' + hotel.id + '_' + day"
            >
              <!-- v-for="(parities, day) in hotel_parities" -->
              <v-tooltip color="green" bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <div class="d-flex justify-center pb-2">
                      <v-progress-circular
                        :rotate="-90"
                        :size="25"
                        :value="
                          hotel_parities[day]
                            ? hotel_parities[day].ok.percent +
                              hotel_parities[day].impaired.percent
                            : 0
                        "
                        :color="parity_colors['ok']"
                      ></v-progress-circular>
                    </div>
                    <div
                      v-if="hotel_parities[day]"
                      style=" height: 50px; overflow:hidden; border-top-right-radius: 5px;border-top-left-radius: 5px;"
                    >
                      <div
                        style="width:100%;"
                        :style="
                          'height:' +
                            hotel_parities[day][parity_code].percent +
                            '%; background-color:' +
                            parity_colors[parity_code] +
                            ';'
                        "
                        v-for="parity_code in Object.keys(
                          hotel_parities[day]
                        ).reverse()"
                        :key="'percent_' + parity_code"
                      ></div>
                    </div>
                    <div
                      v-else
                      style=" height: 50px; overflow:hidden; border-top-right-radius: 5px;border-top-left-radius: 5px; background-color: lightgray"
                    ></div>
                    <div class="d-flex justify-center">
                      <span
                        class="grey--text darken-3--text text-uppercase font-weight-bold uppercase"
                      >
                        {{ getDay(day) }}
                      </span>
                    </div>
                  </div>
                </template>
                <div class="text-center">
                  {{ $t("parity") }} {{ getFullDay(day) }}: <br /><b
                    class="subtitle-1 d-block"
                    >{{
                      hotel_parities[day]
                        ? format_percent(
                            hotel_parities[day].ok.percent +
                              hotel_parities[day].impaired.percent
                          )
                        : $t("no_available_rooms")
                    }}</b
                  >
                </div>
              </v-tooltip>
            </div>
          </div>
          <div
            v-else
            style="border: 2px dashed #cacaca; border-radius: 5px;"
            class="d-flex align-center justify-center pa-8 headline grey--text"
          >
            <v-icon class="d-block headline">mdi-progress-clock</v-icon>
            <p class="subtitle-1 ml-2 my-0">{{ $t("empty_data") }}</p>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils.js";

export default {
  components: {},
  name: "hotel-list-parity-item",
  data: () => ({}),
  props: ["hotel", "level", "days", "daily_parities"],
  methods: {
    setCurrentHotel(hotel) {
      this.$store.dispatch("setCurrentHotel", hotel);
      this.show_hotel_list = false;
      return false;
    },
    getDay(day) {
      return Vue.moment(day).format("D");
    },
    getFullDay(day) {
      return Vue.moment(day)
        .format("D MMM")
        .toUpperCase();
    },
    format_percent(v) {
      return Utils.formatPercentPlain(v, this.l);
    }
  },
  watch: {},
  computed: {
    next_days() {
      return this.days.slice(0, 30);
    },
    hotel_parities() {
      return this.daily_parities.find(d => d.hotel_id == this.hotel.id)
        .parities;
    },
    isLeaf() {
      return this.hotel.children_number == 0;
    },
    isGroup() {
      return this.hotel.children_number > 0;
    },
    isActive() {
      return this.current_hotel.id == this.hotel.id;
    },
    ...mapState({
      parity_colors: state => state.parity_colors,
      chain: state => state.chain.chain,
      hotels: state => state.hotel.hotels,
      currencies: state => state.currency.currencies,
      currency: state => state.currency.currency
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      l: "current_hotel_language"
    })
  },
  created() {}
};
</script>
<style scoped>
.rotate-right .v-icon {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(-90deg);
  display: inline-block;
}
</style>
