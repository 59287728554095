<template>
  <div>
    <slot name="button">
      <v-btn
        depressed
        class="super-raised"
        right
        rounded
        outlined
        color="info"
        @click="openModal"
      >
        <template><v-icon class="">mdi-flash</v-icon></template>
      </v-btn>
    </slot>
    <v-dialog
      v-model="open"
      :fullscreen="$vuetify.breakpoint.mobile"
      :hide-overlay="$vuetify.breakpoint.mobile"
      persistent
      scrollable
      :max-width="!$vuetify.breakpoint.mobile ? '75%' : '100%'"
    >
      <a-chm-bulk-edit-form
        v-if="open"
        @formSubmitted="submit"
        @formCancel="cancel"
      ></a-chm-bulk-edit-form>
    </v-dialog>
  </div>
</template>

<script>
import aChmBulkEditForm from "@/components/channel-manager/aChmBulkEditForm.vue";
export default {
  components: { aChmBulkEditForm },
  props: {
    rateId: {
      type: Number,
      default: null
    },
    saveOnClose: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    open: false
  }),
  methods: {
    openModal() {
      this.$store.dispatch("setClickedRateId", this.rateId);
      this.open = true;
    },
    submit() {
      this.open = false;
      if (this.saveOnClose) {
        this.$store.dispatch("setChanges").then(response => {
          this.$emit("onSave");
        });
      }
    },
    cancel() {
      this.open = false;
    }
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue === false) {
        this.$store.dispatch("reloadChannelManagerGraphData");
      }
    }
  }
};
</script>
