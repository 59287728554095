<template>
  <v-autocomplete
    multiple
    deletable-chips
    :value="selectedRates"
    @input="updateModel"
    :items="offers"
    :item-text="getItemText"
    item-value="value"
    :rules="rules"
    rounded
    outlined
    :hide-details="hideDetails"
    :placeholder="placeholder"
    class="min-height-44"
    dense
  >
    <template #prepend>
      <v-icon size="24" v-if="prependIcon">{{ prependIcon }}</v-icon>
    </template>
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
        small
        v-if="data.index === 0"
      >
        <span v-if="data.item.offer_name">
          {{ data.item.offer_name }} ({{ data.item.group_name }})
        </span>
        <span v-else>
          {{ data.item.group_name }}
        </span>
      </v-chip>
      <span v-if="data.index === 1" class="grey--text text-caption">
        (+{{ selectedRates.length - 1 }} {{ $t("more") }})
      </span>
    </template>
    <template v-slot:item="data">
      <template v-if="data.item.isGroup">
        <v-list-item-content>
          <span>{{ data.item.group_name }}</span>
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          {{ data.item.offer_name }}
          <span class="font-weight-regular" v-if="data.item.offer_description"
            >( {{ data.item.offer_description }} )</span
          >
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: "a-offers-autocomplete-offers",
  props: {
    selectedRates: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    offers: {
      type: Array,
      default: () => [{}]
    },
    prependIcon: {
      type: String,
      default: "mdi-ticket"
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: function() {
        return this.$i18n.t("select_offers");
      }
    },
    type: {
      type: String,
      default: "offer"
    }
  },
  model: {
    prop: "selectedRates",
    event: "input"
  },
  methods: {
    remove(item) {
      const index = this.selectedRates.indexOf(item.value);
      if (index >= 0) this.selectedRates.splice(index, 1);
    },
    updateModel(e) {
      this.$emit("input", e);
    }
  },
  computed: {
    rules() {
      return this.required
        ? [v => v.length > 0 || this.$i18n.t("at_least_one_option")]
        : [];
    },
    getItemText() {
      return this.type === "offer" ? "offer_name" : "group_name";
    }
  }
};
</script>
