<template>
  <div id="channel-manager-app">
    <!-- FILTERS -->
    <v-card dark color="transparent" class="elevation-0 mb-4">
      <v-card-text class="pb-1 pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="6" lg="3" class="pb-0">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper">
                {{ $t("select_rooms") }}
              </div>
            </div>
            <autocomplete-rates
              v-model="filters.rooms"
              :rates="rooms"
              :prependIcon="null"
              :hideDetails="true"
              :placeholder="$t('all_selected')"
              type="room"
            ></autocomplete-rates>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="pb-0">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper">
                {{ $t("select_rates") }}
              </div>
            </div>
            <autocomplete-rates
              v-model="filters.rates"
              :rates="rates"
              :prependIcon="null"
              :hideDetails="true"
              :placeholder="$t('all_selected')"
            ></autocomplete-rates>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" lg="3" class="pb-0 d-none d-sm-block">
            <div class="v-messages theme--dark ml-2">
              <div class="v-messages__wrapper" v-html="'&nbsp;'"></div>
            </div>
            <v-btn
              color="info"
              class="text-center"
              block
              rounded
              large
              @click="filterTable"
            >
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- FILTERS -->
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-card>
          <v-card-text
            class="pl-0 d-flex channel-manager-container"
            v-if="days && days.length > 0"
          >
            <!-- <div class="app-left-side-container">
              <channel-manager-rooms-labels
                :hotel-labels="hotelLabels"
                :hovered-label="hoveredLabel"
              >
              </channel-manager-rooms-labels>
            </div> -->
            <div class="app-right-side-container">
              <v-card elevation="0">
                <div
                  class="app-channel-chart-container d-flex justify-space-between"
                >
                  <div class="d-flex">
                    <div class="spacer"></div>
                    <v-menu
                      v-model="goto_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          depressed
                          class="super-raised mr-4"
                          right
                          rounded
                          outlined
                          color="info"
                          v-on="on"
                        >
                          <v-icon left>mdi-calendar-search-outline</v-icon>
                          <span class="flex-grow-1 text-left mr-5">
                            {{ $t("go_to_day") }}
                          </span>
                        </v-btn>
                      </template>
                      <v-date-picker
                        color="accent"
                        v-model="goto_date"
                        @change="changeGotoDate"
                        first-day-of-week="1"
                        :min="minDate"
                        :max="maxDate"
                      ></v-date-picker>
                      <!--                        :allowed-dates="gotoAllowedDate"-->
                    </v-menu>
                    <a-chm-bulk-edit ref="channelManagerBulkEdit">
                      <template v-slot:button>
                        <v-btn
                          depressed
                          class="super-raised mr-4"
                          right
                          rounded
                          outlined
                          color="info"
                          @click="$refs.channelManagerBulkEdit.openModal()"
                        >
                          <v-icon left>mdi-pencil-outline</v-icon>
                          {{ $t("bulk_edit") }}
                        </v-btn>
                      </template>
                    </a-chm-bulk-edit>
                    <v-btn
                      depressed
                      class="super-raised mr-4"
                      right
                      rounded
                      outlined
                      color="grey"
                      @click="resetValues"
                      v-if="hasChanged"
                    >
                      {{ $t("undo") }}
                    </v-btn>
                  </div>
                  <div class="d-flex justify-end"></div>
                  <div class="d-flex justify-end">
                    <v-btn
                      depressed
                      class="super-raised ml-4"
                      right
                      rounded
                      color="success"
                      @click="saveValues"
                      :disabled="!hasChanged"
                    >
                      <!-- <v-icon class="mr-2">mdi-cloud-upload-outline</v-icon> -->
                      {{ $t("save_changes") }}
                    </v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="super-raised ml-4"
                          right
                          rounded
                          outlined
                          color="info"
                          @click="download"
                          icon
                          v-on="on"
                        >
                          <v-icon>mdi-download-outline</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("download") }}
                    </v-tooltip>
                    <div v-show="showBulkUploadButton">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="bulk-upload-btn super-raised ml-4"
                            right
                            rounded
                            outlined
                            color="info"
                            @click="show_bulk_upload_dialog = true"
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-upload-outline</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("bulk_upload") }}
                      </v-tooltip>
                    </div>
                    <div v-show="showBulkUploadProgress">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <div class="bulk-upload-progress mt-2 ml-3" v-on="on">
                            <a-progress-bar
                              :progress="bulkPvpMlosStatus.percent"
                            ></a-progress-bar>
                          </div>
                        </template>
                        {{ $t("bulk_upload_running") }}
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </v-card>
              <a-chm-bulk-upload-dialog
                :show="show_bulk_upload_dialog"
                @close="closeBulkUploadDialog"
              ></a-chm-bulk-upload-dialog>
              <!-- <v-card>
                <div class="app-channel-cols-container">
                  <channel-manager-window-slider
                    :days="days"
                    :maxSlides="maxPages"
                    :channelManagerColumns="columns"
                  ></channel-manager-window-slider>
                </div>
              </v-card> -->
              <v-row class="row-availability mx-0">
                <v-col class="channel-manager-graph-labels pa-0"> </v-col>
                <v-col
                  class="pa-0 my-0 pr-2"
                  style="margin-bottom:-20px !important;"
                >
                  <a-chm-graph
                    :series="seriesData"
                    :daysSelected="days"
                    :new_value="null"
                    :operation="null"
                    :graphValue="'price'"
                    :overlay="false"
                    v-if="seriesData && seriesData.length > 0"
                  ></a-chm-graph>
                  <v-skeleton-loader
                    type="image"
                    class="mx-auto"
                    v-else
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-card elevation="0" class="pr-2">
                <a-chm-rows
                  :days="days"
                  :rows="rows"
                  :maxSlides="maxPages"
                ></a-chm-rows>
              </v-card>
            </div>
          </v-card-text>
          <!-- <v-skeleton-loader v-else type="table"></v-skeleton-loader> -->
        </v-card>
      </template>
      <template v-slot:loading>
        <v-card class="mt-2" loading>
          <v-card-text>
            <v-row class="mx-0">
              <v-col cols="12" md="2"></v-col>
              <v-col cols="12" md="10">
                <v-skeleton-loader
                  type="image"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-skeleton-loader type="table-thead" class="mx-auto">
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </template>
    </a-base-component>
  </div>
</template>
<script>
import aBaseComponent from "@/components/aBaseComponent.vue";
// import ChannelManagerRoomsLabels from "@/components/channel-manager/aChmRoomsLabels.vue";
// import ChannelManagerWindowSlider from "@/components/channelManagerWindowSlider.vue";
import aChmRows from "@/components/channel-manager/aChmRows.vue";
import aChmBulkEdit from "@/components/channel-manager/aChmBulkEdit.vue";
import AutocompleteRates from "@/components/autocompleteRates.vue";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import aChmGraph from "@/components/channel-manager/aChmGraph.vue";
import AChmBulkUploadDialog from "@/components/channel-manager/aChmBulkUploadDialog.vue";
import AProgressBar from "@/components/aProgressBar.vue";

export default {
  components: {
    AProgressBar,
    AChmBulkUploadDialog,
    aChmGraph,
    aBaseComponent,
    // ChannelManagerRoomsLabels,
    // ChannelManagerWindowSlider,
    aChmBulkEdit,
    AutocompleteRates,
    aChmRows
  },
  name: "channel-manager-app",
  data: () => ({
    filters: {
      rates: [],
      rooms: []
    },
    pagination: {
      page: 0
    },
    goto_menu: null,
    goto_date: null,
    subscribed_modules: ["channelmanager_table_data"],
    show_bulk_upload_dialog: false,
    interval: null
    // hotelLabels: [],
    // rates: [],
    // rooms: [],
    // columns: [],
    // rows: []
  }),
  mounted() {
    this.bulkImportStatus();
    //this.filterTable(this.filters, this.pagination);
    // this.$store.dispatch("fetchChannelManagerTableData", {
    //   page: 0
    // });
  },
  computed: {
    page() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.page
      );
    },
    maxDays() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2;
        case "sm":
        case "md":
          return 7;
        default:
          return 14;
        // return 7;
      }
    },
    maxPages() {
      return Math.round(365 / this.maxDays);
    },
    days() {
      return this.$store.state.channelmanager.channelManagerTableData.days;
    },
    rows() {
      return this.$store.getters.filteredRows;
    },
    hoveredLabel() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.hoveredRate
      );
    },
    hasChanged() {
      return this.channelManagerChangesPending;
    },
    rates() {
      return (
        this.$store.getters &&
        this.$store.getters.getFilterRatesByRoomsIds(this.filters.rooms)
      );
    },
    rooms() {
      return this.$store.getters && this.$store.getters.filterRooms;
    },
    channelManagerChangesPending() {
      return (
        this.$store.getters && this.$store.getters.channelManagerChangesPending
      );
    },
    minDate() {
      return Vue.moment(
        this.current_hotel.last_import_date["channelmanager"]
      ).format("YYYY-MM-DD");
    },
    maxDate() {
      return Vue.moment(this.minDate)
        .add(this.maxDays * this.maxPages, "days")
        .format("YYYY-MM-DD");
    },
    seriesData() {
      return (
        this.$store.state.channelmanager &&
        this.$store.state.channelmanager.channelManagerGraphData.map(e => ({
          id: e.id,
          name: e.name,
          values: e.values.map(value => [
            Vue.moment(value[0] + " +0000", "YYYY-MM-DD Z").valueOf(),
            value[1]
          ])
        }))
      );
    },
    showBulkUploadButton() {
      return this.bulkPvpMlosStatus && this.bulkPvpMlosStatus.status === "done";
    },
    showBulkUploadProgress() {
      return (
        this.bulkPvpMlosStatus && this.bulkPvpMlosStatus.status === "running"
      );
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    }),
    ...mapState({
      bulkPvpMlosStatus: state => state.channelmanager.bulkPvpMlosStatus
    })
  },
  methods: {
    log(event) {
      // console.log(event);
    },
    async filterTable(filters, pagination) {
      this.$store.dispatch("updateFilters", this.filters);
    },
    resetValues() {
      this.$store.dispatch("resetRowValues");
    },
    saveValues() {
      this.$store.dispatch("setChanges");
    },
    loaded() {
      return true;
    },
    changeGotoDate() {
      this.goto_menu = false;
      const days = Vue.moment(this.goto_date).diff(
        Vue.moment(this.minDate),
        "days"
      );
      this.$store.dispatch(
        "updateChannelmanagerPage",
        Math.floor(days / this.maxDays)
      );
    },
    download() {
      this.$store.dispatch("downloadChannelManagerPricing", {
        hotel_id: this.current_hotel.id,
        year: Vue.moment(this.minDate).format("YYYY")
      });
    },
    bulkImportStatus() {
      this.$store.dispatch("fetchBulkPvpMlosStatus");
    },
    closeBulkUploadDialog() {
      this.show_bulk_upload_dialog = false;
      setTimeout(() => {
        this.bulkImportStatus();
      }, 1000);
    }
  },
  watch: {
    goto_menu(newValue) {
      if (newValue) {
        this.goto_date = this.days[0];
      }
    },
    current_hotel() {
      this.bulkImportStatus();
    },
    bulkPvpMlosStatus(newVal, oldVal) {
      this.$forceUpdate(); // Força una actualització del component
      if (newVal && newVal.status === "running") {
        setTimeout(() => {
          this.bulkImportStatus();
        }, 5000);
      } else if (oldVal && oldVal.status === "running") {
        this.$store.dispatch("setReloadSubscribedModules");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$chartHeight: 60px;
$weekendBg: rgba(219, 198, 77, 0.1);
$colHoverBg: #f5fdff;
.spacer {
  min-width: $widthLeftSide;
}
.v-messages {
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}

.channel-manager-container {
  .col-weekend {
    background-color: $weekendBg;
    &:hover {
      background-color: #f5fdff;
    }
  }
  .app-left-side-container {
    width: $widthLeftSide;
    padding-top: $chartHeight;
  }
  .app-right-side-container {
    width: 100%;
    .app-channel-chart-container {
      width: 100%;
      height: $chartHeight;
    }
    .app-channel-cols-container {
      width: 100%;
      padding-top: $chartHeight;
      position: absolute;
    }
  }
}
.super-raised {
  z-index: 1;
}
.channel-manager-graph-labels {
  max-width: $widthLeftSide - 65;
}
.bulk-upload-progress {
  width: 100px;
}
</style>
