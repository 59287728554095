<template>
  <div id="hotels-resume-app">
    <div class="list-group">
      <hotel-list-parity-item
        v-for="hotel in last_level_hotels"
        :hotel="hotel"
        :level="0"
        :currencies="currencies"
        :key="hotel.id"
        :daily_parities="daily_parities"
        :days="days"
      >
      </hotel-list-parity-item>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import hotelListParityItem from "@/components/parity/hotelListParityItem";

export default {
  name: "aParityGroup",
  components: {
    hotelListParityItem
  },
  props: ["daily_parities", "days"],
  data: () => ({}),
  methods: {},
  watch: {},
  computed: {
    userHotels() {
      // Filter just user hotels
      // return this.hotels.filter(hotel => {
      //   return current_user.hotels.find(hotel.id) ? true : false;
      // })
      return this.hotels;
    },
    last_level_hotels() {
      return this.hotels.filter(hotel => hotel.children_number == 0);
    },
    ...mapState({
      hotels: state => state.hotel.hotels,
      currencies: state => state.currency.currencies,
      hotel_selected: state => state.hotel.hotel
    }),
    ...mapGetters({})
  },
  created() {}
};
</script>
