<template>
  <v-card color="grey lighten-4">
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      {{ $t("price_evolution") }}
      <v-spacer></v-spacer>
      <v-select
        v-model="rate_type"
        :items="rate_types"
        :label="$t('rates')"
        @change="getRates"
        rounded
        outlined
        dense
        background-color="white"
        hide-details
        class="font-weight-regular text-capitalize"
      >
      </v-select>
    </v-card-title>
    <v-card-text class="white">
      <v-data-table
        :headers="headers"
        :items="filteredRates"
        hide-default-footer
        disable-pagination
        :item-class="getDatatableRowClass"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ getFriendlyDate(item.date) }}
        </template>
        <template v-slot:[`item.pvp`]="{ item }">
          <a-kpi
            kpi="pvp"
            :value="item.pvp"
            :vs_values="[item.pvp_vs]"
            :vs_periods="['old_rate']"
            x_small
          ></a-kpi>
        </template>
        <template v-slot:[`item.roomtype`]="{ item }">
          {{ item.roomtype }}
        </template>
        <template v-slot:[`item.rn_day`]="{ item }">
          <template v-if="item.rn_day > 0">
            {{ item.rn_day + " " + $t("room_day") }}
          </template>
        </template>
        <template v-slot:[`item.rn`]="{ item }">
          {{ item.rn }}
        </template>
        <template v-slot:[`item.days`]="{ item }">
          {{ item.days }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import * as Utils from "@/utils.js";
import aKpi from "@/components/rms_ui/aKpi.vue";

export default {
  name: "a-rms-day-price-evolution",
  components: {
    aKpi
  },
  props: {
    date: String
  },
  data: () => ({
    rate_type: "",
    rates: []
  }),
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    async getRates() {
      await this.$store
        .dispatch("fetchRmsRoomTypesEvolutionDay", this.date)
        .then(response => {
          this.rates = response.data;
        })
        .catch(() => {
          Utils.showError(this, "something_went_wrong");
        });
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date, true);
    }
  },
  computed: {
    rate_types() {
      return [
        { text: this.$t("featured_rate_only"), value: "featured_rate" },
        { text: this.$t("all_rates"), value: "all_rates" }
      ];
    },
    filteredRates() {
      if (this.rate_type === "featured_rate") {
        return this.rates.filter(rate => rate.highlighted);
      } else {
        return this.rates;
      }
    },
    headers() {
      return [
        {
          text: this.$t("date"),
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("pvp"),
          align: "center",
          sortable: true,
          value: "pvp"
        },
        {
          text: this.$t("roomtype"),
          align: "center",
          sortable: true,
          value: "roomtype"
        },
        {
          text: this.$t("daily_average"),
          align: "center",
          sortable: true,
          value: "rn_day"
        },
        {
          text: this.$t("rn"),
          align: "center",
          sortable: true,
          value: "rn"
        },
        {
          text: this.$t("days"),
          align: "center",
          sortable: true,
          value: "days"
        }
      ];
    },
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  mounted() {
    this.getRates();
    this.rate_type = this.rate_types[0].value;
  }
};
</script>
