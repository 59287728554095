<template>
  <v-card elevation="0">
    <div class="pa-4 py-0" v-if="graph_series.length > 0">
      <v-overlay absolute :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <zingchart
        height="200px"
        width="100%"
        :data="graph_data"
        :series="graph_series"
      ></zingchart>
    </div>
  </v-card>
</template>
<script>
import Vue from "vue";
import colors from "@/constants/colors";

let tooltips = [];
if (!window.customFncs) {
  window.customFncs = {};
}
window.customFncs.formatCrosshair = function(p) {
  let text = tooltips[p.key];
  return {
    text: text,
    backgroundColor: "white",
    borderRadius: "3px",
    borderColor: "#cacaca",
    borderWidth: 2,
    lineHeight: "10px",
    // height: "250px",
    // width: "220px",
    htmlMode: true,
    fontFamily: "Roboto Condensed"
  };
};
export default {
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    operation: {
      type: Function,
      default: v => 1 * v
    },
    new_value: {
      type: [Number, String],
      default: null
    },
    daysSelected: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    },
    graphValue: {
      type: String,
      default: "rates"
    },
    series: {
      type: Array,
      default: () => []
    },
    overlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    graph_data() {
      return {
        type: "line",
        // gui: {
        //   contextMenu: {
        //     empty: true
        //   }
        // },
        // legend: {
        //   //highlightLegend: true,
        //   marker: {
        //     showLine: true
        //   },
        //   verticalAlign: "top",
        //   layout: "1x4", //row x column
        //   align: "center"
        // },

        // "crosshair-x": {
        //   plotLabel: {
        //     visible: true,
        //     htmlMode: true,

        //   },
        //   scaleLabel: {
        //     visible: false
        //   },
        //   lineWidth: 0
        // },
        // title: this.$i18n.t(this.title),
        plot: {
          aspect: "spline",
          fontFamily: "Roboto Condensed"
          // tooltip: {
          //   visible: false
          // }
        },
        backgroundColor: "none", // This is in the root
        plotarea: {
          backgroundColor: "transparent",
          margin: "35px"
        },
        scaleX: {
          guide: {
            visible: false
          },
          labels: this.xAxisLabels,
          lineColor: "none",
          //   fixedStep: true,
          ranged: true,
          step: "1day",
          visible: true,
          itemsOverlap: true,
          item: {
            visible: true,
            overlap: true,
            fontFamily: "Roboto Condensed",
            fontSize: "12px",
            fontWeight: "bold"
          },
          tick: {
            // lineColor: "none"
            visible: false
          },
          markers: this.weekendRangesMarkers
        },
        scaleY: {
          //   minValue: 0,
          //   maxValue: 100,
          //   maxValue:
          //     this.max_graph_value == this.min_graph_value
          //       ? this.max_graph_value + 10
          //       : this.max_graph_value,
          visible: true,
          lineColor: "transparent",
          guide: {
            visible: false
          },
          item: {
            visible: true
          },
          tick: {
            lineColor: "none"
          }
        },
        tooltip: {
          jsRule: "window.customFncs.formatCrosshair()"
        }
      };
    },
    graph_series() {
      const graphSeries = {};
      // const key = this.graphValue;
      // const original_key = `original_${key}`;

      this.series.forEach((serie, index) => {
        graphSeries[`${serie.id}`] = {
          text: `${serie.name}`,
          lineStyle: "",
          lineColor: colors[index],
          marker: {
            backgroundColor: colors[index],
            borderWidth: 2
          },
          values: serie.values
        };

        graphSeries[`${serie.id}_new`] = {
          text: `${serie.name} ${this.textSuffix}`,
          lineStyle: "dotted",
          lineColor: colors[index],
          marker: {
            backgroundColor: colors[index],
            borderWidth: 2
          },
          values: serie.values.map(value => [
            value[0],
            this.daysSelected.includes(
              (Vue.moment(value[0])
                .toDate()
                .getUTCDay() +
                6) %
                7
            )
              ? this.operation(this.new_value, value[1])
              : value[1]
          ])
        };
      });

      // this.columns.forEach(column => {
      //   column.forEach((cell, index) => {
      //     if (!graphSeries[this.getSerieKey(cell, false)]) {
      //       graphSeries[this.getSerieKey(cell, false)] = {
      //         text: this.getSerieText(cell, false),
      //         lineStyle: "",
      //         lineColor: colors[index],
      //         marker: {
      //           backgroundColor: colors[index],
      //           borderWidth: 2
      //         },
      //         values: []
      //       };
      //       graphSeries[this.getSerieKey(cell, true)] = {
      //         text: this.getSerieText(cell, true),
      //         lineStyle: "dotted",
      //         lineColor: colors[index],
      //         marker: {
      //           backgroundColor: colors[index],
      //           borderWidth: 2
      //         },
      //         values: []
      //       };
      //     }
      //     const date = new Date(cell.date);
      //     date.setUTCHours(0, 0, 0, 0);
      //     graphSeries[this.getSerieKey(cell, false)].values.push([
      //       date.getTime(),
      //       this.getCellValue(cell, this.graphValue, true)
      //     ]);
      //     graphSeries[this.getSerieKey(cell, true)].values.push([
      //       date.getTime(),
      //       this.daysSelected.includes((date.getUTCDay() + 6) % 7)
      //         ? this.operation(
      //             this.new_value,
      //             this.getCellValue(cell, this.graphValue, false)
      //           )
      //         : this.getCellValue(cell, this.graphValue, true) * 1
      //     ]);
      //   });
      // });

      //Creamos el tooltip
      tooltips = [];
      // for (const day of this.days) {
      //   var t = "";
      //   t =
      //     t +
      //     "<div style='text-align:left;font-family:\"Roboto Condensed\"'><span style='text-align:left; color: #333333; font-size:13px; font-weight:700;font-family:\"Roboto Condensed\";line-height:30px;'>" +
      //     Vue.moment(day).format("dddd, D MMMM YYYY") +
      //     "</span>";

      //   var competitors = [];
      //   for (const competitor of this.visible_competitors) {
      //     if (
      //       this.daily_rates.report &&
      //       this.daily_rates.report["competitor::" + competitor.id]
      //     ) {
      //       var c = {
      //         name: competitor.name,
      //         color: competitor.color,
      //         ranking: this.daily_rates.report["competitor::" + competitor.id][
      //           day
      //         ]
      //           ? this.daily_rates.report["competitor::" + competitor.id][
      //               day
      //             ][0].ranking
      //           : 100,
      //         min_price: this.daily_rates.report[
      //           "competitor::" + competitor.id
      //         ][day]
      //           ? this.daily_rates.report["competitor::" + competitor.id][
      //               day
      //             ][0].min_price
      //           : null,
      //         self: this.daily_rates.report["competitor::" + competitor.id][day]
      //           ? this.daily_rates.report["competitor::" + competitor.id][
      //               day
      //             ][0].self
      //           : null
      //       };
      //       competitors.push(c);
      //     }
      //   }
      //   var ordered_competitors = competitors.sort(compareRanking);
      //   t =
      //     t +
      //     "<table width='170px' cellspacing='0' cellpadding='0' style='padding:0'>";
      //   var cont = 1;
      //   for (const competitor of ordered_competitors) {
      //     if (competitor.min_price > 0) {
      //       var fontWeight = competitor.self
      //         ? "font-weight:600;"
      //         : "font-weight:500;";
      //       var number =
      //         this.l.money_pre_symbol +
      //         numeral(competitor.min_price).format(this.l.number_format) +
      //         "" +
      //         this.l.money_post_symbol;
      //       t =
      //         t +
      //         "<tr><td align='left' style='padding:0;line-height:14px;'><span style='color: #333333; font-size:11px; line-height:14px; font-family:\"Roboto Condensed\";" +
      //         fontWeight +
      //         "'>" +
      //         cont +
      //         ".</span><span style='padding:0 5px; border-radius:10px;color: white; background-color: " +
      //         competitor.color +
      //         '; font-size:11px; line-height:14px; font-family:"Roboto Condensed";' +
      //         fontWeight +
      //         "'> " +
      //         competitor.name +
      //         "</span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: #333333; font-size:11px; line-height:14px; font-weight:700;font-family:\"Roboto Condensed\"'>" +
      //         number +
      //         "</span></td></tr>";
      //       cont++;
      //     }
      //   }
      //   t = t + "</table>";
      //   t = t + "</div>";

      //   tooltips.push(t);
      // }

      if (this.series && this.series.length > 0) {
        this.series[0].values.forEach((serie0Value, serie0Index) => {
          const [timestamp, value] = serie0Value;
          let cont = 1;
          let t = "";
          const fontWeight = "font-weight:600;";
          t =
            t +
            "<div style='text-align:left;font-family:\"Roboto Condensed\"'><span style='text-align:left; color: #333333; font-size:13px; font-weight:700;font-family:\"Roboto Condensed\";line-height:30px;'>" +
            Vue.moment(timestamp).format("dddd, D MMMM YYYY") +
            "</span>";
          t =
            t +
            "<table width='170px' cellspacing='0' cellpadding='0' style='padding:0'>";

          for (let serieKey in graphSeries) {
            this.series.forEach(serie => {
              t =
                t +
                "<tr><td align='left' style='padding:0;line-height:14px;'><span style='color: #333333; font-size:11px; line-height:14px; font-family:\"Roboto Condensed\";" +
                fontWeight +
                "'>" +
                cont +
                ".</span><span style='padding:0 5px; border-radius:10px;color: white; background-color: " +
                graphSeries[serieKey].lineColor +
                '; font-size:11px; line-height:14px; font-family:"Roboto Condensed";' +
                fontWeight +
                "'> " +
                graphSeries[serieKey].text +
                "</span></td><td style='padding:0;text-align:right;line-height:14px;' align='right'><span style='color: #333333; font-size:11px; line-height:14px; font-weight:700;font-family:\"Roboto Condensed\"'>" +
                graphSeries[serieKey].values[serie0Index][1] +
                "€ </span></td></tr>";
              cont++;
            });
          }

          t = t + "</table>";
          t = t + "</div>";

          tooltips[timestamp] = t;
        });
      }

      return Object.values(graphSeries);
    },
    textSuffix() {
      return this.title === "availability"
        ? this.$i18n.t("new_availability")
        : this.$i18n.t("new_rate");
    },
    weekendRangesMarkers() {
      if (this.graph_series && this.graph_series.length > 0) {
        const markers = [];
        const [minDate, maxDate] = [
          this.graph_series[0].values[0][0],
          this.graph_series[0].values.at(-1)[0]
        ];

        let friday = Vue.moment(minDate);
        let sunday;
        // adjusting vertical lines
        const offsetMin =
          (12 +
            Vue.moment()
              .toDate()
              .getTimezoneOffset() /
              60) *
          60 *
          60 *
          1000;
        const offsetMax =
          (12 -
            Vue.moment()
              .toDate()
              .getTimezoneOffset() /
              60) *
          60 *
          60 *
          1000;
        do {
          friday = friday.startOf("isoWeek").add(4, "days");
          sunday = Vue.moment(friday).add(2, "days");
          markers.push({
            type: "area",
            backgroundColor: "rgba(219, 198, 77, 0.1)",
            range: [friday.valueOf() - offsetMin, sunday.valueOf() + offsetMax],
            // range: [friday.valueOf(), sunday.valueOf()],
            valueRange: true
          });
          friday.add(4, "days");
        } while (sunday.valueOf() < maxDate);

        // vertical lines
        this.graph_series[0].values.forEach(valueSerie => {
          const offsetMax = 12 * 60 * 60 * 1000;
          markers.push({
            type: "line",
            range: [valueSerie[0] + offsetMax],
            valueRange: true,
            lineColor: "#dadada",
            lineWidth: 1,
            lineStyle: "solid",
            alpha: 1
          });
        });
        // delete last element
        markers.pop();

        return markers;
      }
      return [];
    },
    xAxisLabels() {
      if (this.graph_series && this.graph_series.length > 0) {
        return this.graph_series[0].values.map(tupple =>
          Vue.moment(tupple[0])
            .format("dddd D")
            .toUpperCase()
            .replace(".", "")
        );
      }
      return [];
    }
  },
  methods: {
    getCellValue(cell, type, needsOriginal = true) {
      let original = needsOriginal ? "original_" : "";
      let key;
      if (type === "rates") {
        key = `${original}price`;
      }
      if (type === "availability") {
        key = `${original}available_of_rooms`;
      }
      return cell[key];
    },
    getSerieKey(cell, needsNew = true) {
      let newTerm = needsNew ? "_new" : "";
      if (cell.isRate) {
        return `${cell.rate_id}${newTerm}`;
      }
      if (cell.isRoom) {
        return `${cell.room_id}${newTerm}`;
      }
      if (cell.isRateAvailability) {
        return `${cell.room_id}_${cell.room_id}${newTerm}`;
      }
    },
    getSerieText(cell, needsNew = true) {
      let newTerm = needsNew ? this.textSuffix : "";
      if (cell.isRate || cell.isRateAvailability) {
        return `${cell.rate_name} ${newTerm}`;
      }
      if (cell.isRoom) {
        return `${cell.room_name} ${newTerm}`;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
