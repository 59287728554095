<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "a-hotelsdot-year-sales-occupancy-period-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Array,
      default: function() {
        return [];
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      return {
        type: "area",
        plot: {
          aspect: "spline",
          lineWidth: 1,

          marker: {
            size: 3,
            borderWidth: 1,
            shadow: 0
          }
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 50px 50px 80px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels
        },
        scaleY: {
          lineWidth: 0,
          values: "0:100:25",
          format: "%v%",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        series: [
          {
            values: this.series
          }
        ],
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
