<template>
  <v-form v-model="isValid" class="special-rates-stop-sell-edit-form">
    <v-card>
      <v-card-title>
        <div class="d-block full-width">
          <div class="d-flex justify-space-between">
            <span class="text-h5"> {{ $t("open/close_rates") }}</span>
            <v-btn @click="cancel" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <div class="title-label">{{ $t("rates") }}</div>
            <a-special-rates-autocomplete-rates
              v-model="form.selectedRates"
              required
              :rates="rates"
              :prependIcon="null"
            >
            </a-special-rates-autocomplete-rates>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <div class="title-label">{{ $t("period") }}</div>
            <v-menu
              v-model="menuPeriod"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  block
                  rounded
                  outlined
                  large
                  :label="$t('type')"
                  background-color="transparent"
                  v-on="on"
                >
                  <span class="flex-grow-1 text-left">
                    {{ formatedDatesRange }}
                  </span>
                  <v-icon class="align-self-right">
                    mdi-calendar-month-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-date-picker
                class="d-block"
                :min="minDate"
                color="accent"
                v-model="datesRange"
                @input="autoSelectDays"
                range
                :title-date-format="() => formatedDatesRange"
                first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <div class="title-label">{{ $t("Days") }}</div>
            <v-chip-group v-model="daysSelected" active-class="white" multiple>
              <v-chip
                outlined
                v-for="(day, key) of days"
                :key="key"
                :value="key"
              >
                {{ day }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pb-0 d-flex align-center stop-sell-switch-wrap"
          >
            <label class="v-label">{{ $t("open_rate") }}</label>
            <v-switch
              v-model="form.stop_sell_value"
              :label="$t('close_rate')"
              class="ma-0"
              color="red"
              hide-details
            >
            </v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn
          depressed
          rounded
          @click="save"
          color="info"
          class="px-12"
          :disabled="!isValid"
        >
          {{ $t("save_changes") }}
        </v-btn>
        <v-btn depressed rounded outlined @click="cancel" class="px-12">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Vue from "vue";
import aSpecialRatesAutocompleteRates from "@/components/special-rates/aSpecialRatesAutocompleteRates.vue";

const days = [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek => {
  // keep this date as it will produce lun, mar, mie, jue, vie, sab, dom
  return Vue.moment()
    .weekday(dayOfWeek)
    .format("dddd")
    .substring(0, 3);
});
const enumerateDaysBetweenDates = function(startDate, endDate) {
  let dates = [];
  let currDate = Vue.moment(startDate).startOf("day");
  let lastDate = Vue.moment(endDate).startOf("day");
  dates.push(currDate.clone());
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.clone());
  }
  return dates;
};
const getUTCDaysBetweenDates = function(datesRange) {
  return Array.from(
    new Set(
      enumerateDaysBetweenDates(...datesRange).map(e => Vue.moment(e).weekday())
    )
  );
};

export default {
  name: "a-special-rates-stop-sell-edit-form",
  components: {
    aSpecialRatesAutocompleteRates
  },
  props: {
    currency: {
      type: String,
      default: "eur"
    }
  },
  data: () => ({
    isValid: false,
    days,
    daysSelected: [],
    menuPeriod: false,
    datesRange: [],
    form: {
      stop_sell_value: null,
      selectedRates: []
    }
  }),
  mounted() {
    const displayedDays =
      this.$store.state.specialrates &&
      this.$store.state.specialrates.specialRatesTableData.days;

    let period = [
      { date: displayedDays[0] },
      { date: displayedDays[displayedDays.length - 1] }
    ];
    this.datesRange = period.map(e => e.date).sort((a, b) => a - b);
    this.daysSelected = getUTCDaysBetweenDates(this.datesRange);
    this.form.selectedRates.push(this.firstRate());
  },
  computed: {
    rates() {
      return (
        this.$store.state.specialrates &&
        this.$store.state.specialrates.specialRatesTableData.hotelLabels.map(
          e => ({ ...e, disabled: e.isGroup, value: e.rate_id })
        )
      );
    },
    minDate() {
      return Vue.moment().format("YYYY-MM-DD");
    },
    formatedDatesRange() {
      if (this.datesRange.length > 1) {
        const [monthIni, monthEnd] = [
          Vue.moment(this.datesRange[0]).format("MMMM"),
          Vue.moment(this.datesRange[1]).format("MMMM")
        ];
        return monthIni !== monthEnd
          ? this.$i18n.t("from_to_dates", {
              from: Vue.moment(this.datesRange[0]).format("D MMMM"),
              to: Vue.moment(this.datesRange[1]).format("D MMMM")
            })
          : this.$i18n.t("from_day_to_day_of_month", {
              from: Vue.moment(this.datesRange[0]).format("D"),
              to: Vue.moment(this.datesRange[1]).format("D"),
              month: monthIni
            });
      }
      return "";
    }
  },
  methods: {
    save() {
      let payload = {};
      this.saveStopSell();
      this.$store.dispatch("setSpecialRatesChanges", payload);
      this.cancel();
    },
    saveStopSell() {
      this.$store.dispatch("applySpecialRatesStopSellChanges", {
        daysSelected: this.daysSelected,
        datesRange: this.datesRange,
        form: {
          entity_ids: this.form.selectedRates,
          value: this.form.stop_sell_value ? true : false
        }
      });
    },
    cancel() {
      this.$store.dispatch("setSpecialRatesStopSellFormOpen", false);
    },
    autoSelectDays(e) {
      if (e.length === 2) {
        this.daysSelected = getUTCDaysBetweenDates(e);
      }
    },
    firstRate() {
      const hotelLables = this.$store.state.specialrates.specialRatesTableData
        .hotelLabels;
      for (let label in hotelLables) {
        if (hotelLables[label].isRate) {
          return hotelLables[label].rate_id;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.special-rates-stop-sell-edit-form {
  .title-label {
    font-size: 15px;
    font-weight: 400;
    transform: scale(0.75) translateX(23px);
    transform-origin: top left;
  }
  .v-chip {
    flex: 1;
    justify-content: center;
  }
  .stop-sell-switch-wrap {
    > label {
      color: rgba(0, 0, 0, 0.6);
      margin-top: 5px;
      margin-right: 10px;
    }
    .v-input .v-input--selection-controls__input * {
      color: #4caf50;
      caret-color: #4caf50;
    }
  }
}
</style>
