<template>
  <div>
    <div class="table-header d-flex">
      <v-text-field
        class=""
        block
        v-model="search"
        rounded
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search_comment')"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-tooltip bottom dark color="#333333">
        <template v-slot:activator="{ on }">
          <v-btn
            class="mb-1"
            @click="showAddComment"
            rounded
            color="secondary"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            {{ $t("add_comment") }}
          </v-btn>
        </template>
        <span>
          {{ $t("create_comment") }}
        </span>
      </v-tooltip>
    </div>
    <v-data-table
      v-if="calendar_store"
      :headers="hotel_comments_headers"
      :items="filteredComments"
      hide-default-footer
      disable-pagination
      :item-class="getDatatableRowClass"
      @click:row="showEditComment"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ getFriendlyDate(item.date) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom dark color="#333333">
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="showDeleteComment(item)"
              icon
              color="secondary"
              v-on="on"
              ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
            >
          </template>
          <span>
            {{ $t("delete") }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- DELETE COMMENT -->
    <v-dialog
      v-model="show_delete_comment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400"
    >
      <v-card>
        <v-card-title center class="headline justify-center">{{
          $t("are_you_sure")
        }}</v-card-title>
        <v-card-text
          class="text-center justify-center"
          v-if="item_to_edit"
          v-html="$t('sure_delete_comment')"
        >
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="red"
            rounded
            depressed
            text
            class="pl-5 pr-5"
            @click="deleteComment()"
          >
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideDeleteComment"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CREATE/EDIT COMMENT -->
    <v-dialog
      v-model="show_edit_comment"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="450"
    >
      <v-card>
        <v-card-title
          center
          class="headline justify-center py-6"
          v-if="item_to_edit.id"
          >{{ $t("edit_comment") }}
        </v-card-title>
        <v-card-title center class="headline justify-center py-6" v-else
          >{{ $t("new_comment") }}
        </v-card-title>
        <v-card-text class="text-center justify-center" v-if="item_to_edit">
          <v-form ref="edit_comment_form" v-model="edit_comment_form">
            <v-row>
              <v-col cols="12" sm="12" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :label="$t('date')"
                      outlined
                      rounded
                      dense
                      readonly
                      v-on="on"
                      :value="getFriendlyDate(item_to_edit.date)"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="item_to_edit.date"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="item_to_edit.comment"
                  maxlength="255"
                  counter
                  :label="$t('comment')"
                  :rules="[v => !!v || $t('required')]"
                  :placeholder="$t('write_your_comment')"
                  outlined
                  rounded
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="ps-5 pb-5 justify-center">
          <v-btn
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="setComment()"
            :disabled="!edit_comment_form"
            v-if="item_to_edit.id"
          >
            {{ $t("set_comment") }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            rounded
            depressed
            class="pl-5 pr-5"
            @click="addComment()"
            :disabled="!edit_comment_form"
          >
            {{ $t("add_comment") }}
          </v-btn>
          <v-btn
            color=""
            text
            rounded
            depressed
            class="pl-5 pr-5"
            @click="hideEditComment"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
var numeral = require("numeral");
import * as Utils from "@/utils.js";

export default {
  components: {},
  data: () => ({
    image_source: "/img/header-2.jpg", //Mandatory path
    image_height: 380, //Mandatory number
    item_to_edit: {},
    show_delete_comment: false,
    show_edit_comment: false,
    edit_comment_form: false,
    search: ""
  }),
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    // Función para mostrar el diálogo de confirmación de eliminación de comentario
    // Parámetros:
    //   comment: el objeto de comentario a eliminar
    showDeleteComment(comment) {
      // Verificar si el objeto de comentario es válido
      if (!comment) {
        return;
      }
      // Establecer la bandera show_delete_comment en true para mostrar el diálogo de confirmación
      this.show_delete_comment = true;
      // Establecer el objeto item_to_edit al objeto de comentario que se está eliminando
      this.item_to_edit = comment;
    },

    // Función para ocultar el diálogo de confirmación de eliminación de comentario
    hideDeleteComment() {
      // Establecer la bandera show_delete_comment en false para ocultar el diálogo de confirmación
      this.show_delete_comment = false;
      // Restablecer el objeto item_to_edit
      this.item_to_edit = {};
    },

    // Función para eliminar el comentario seleccionado
    deleteComment: function() {
      var vm = this;
      // Mostrar un mensaje de snackbar indicando que se está eliminando el comentario
      this.$store.dispatch("setSnackbar", {
        title: this.$t("deleting_comment"),
        color: "secondary"
      });

      // Enviar una acción de deleteComment a la tienda con el id del comentario que se está eliminando
      this.$store
        .dispatch("deleteComment", { id: this.item_to_edit.id })
        .then(() => {
          // En caso de eliminación exitosa, ocultar el diálogo de confirmación
          this.show_delete_comment = false;
          // Mostrar un mensaje de snackbar indicando que se eliminó el comentario con éxito
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comment_deleted"),
            color: "accent"
          });
          // Restablecer el objeto item_to_edit
          this.item_to_edit = {};
        })
        .catch(() => {
          // En caso de error de eliminación, ocultar el diálogo de confirmación y mostrar un mensaje de error
          this.show_delete_comment = false;
          this.item_to_edit = {};
          Utils.showError(this, "comment_not_deleted");
        });
    },
    showAddComment() {
      this.clearEditForm();
      this.show_edit_comment = true;
      this.item_to_edit = {
        id: null,
        hotel_id: this.current_hotel.id,
        date: Vue.moment().format("YYYY-MM-DD")
      };
    },
    // Función para mostrar el diálogo de edición de comentario
    // Parámetros:
    //   comment: el objeto de comentario a editar
    showEditComment(comment) {
      // Verificar si el objeto de comentario es válido
      if (!comment) {
        return;
      }
      // Limpiar el formulario de edición
      this.clearEditForm();
      // Establecer la bandera show_edit_comment en true para mostrar el diálogo de edición
      this.show_edit_comment = true;
      // Establecer el objeto item_to_edit al objeto de comentario que se está editando
      this.item_to_edit = JSON.parse(JSON.stringify(comment));
    },

    // Función para ocultar el diálogo de edición de comentario
    hideEditComment() {
      // Establecer la bandera show_edit_comment en false para ocultar el diálogo de edición
      this.show_edit_comment = false;
      // Restablecer el objeto item_to_edit
      this.item_to_edit = {};
    },

    // Función para guardar los cambios en el comentario editado
    setComment: function() {
      var vm = this;
      // Mostrar un mensaje de snackbar indicando que se está guardando el comentario editado
      this.$store.dispatch("setSnackbar", {
        title: this.$t("modifying_comment"),
        color: "secondary"
      });

      // Enviar una acción de setComment a la tienda con el objeto de comentario editado
      this.$store
        .dispatch("setComment", this.item_to_edit)
        .then(() => {
          // En caso de guardar el comentario exitosamente, ocultar el diálogo de edición
          this.show_edit_comment = false;
          // Mostrar un mensaje de snackbar indicando que se guardó el comentario con éxito
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comment_modified"),
            color: "accent"
          });
          // Restablecer el objeto item_to_edit
          this.item_to_edit = {};
        })
        .catch(() => {
          // En caso de error de guardado, ocultar el diálogo de edición y mostrar un mensaje de error
          this.show_edit_comment = false;
          this.item_to_edit = {};
          Utils.showError(this, "comment_not_modified");
        });
    },
    // Función para guardar los cambios en el comentario editado
    addComment: function() {
      var vm = this;
      // Mostrar un mensaje de snackbar indicando que se está guardando el comentario editado
      this.$store.dispatch("setSnackbar", {
        title: this.$t("adding_comment"),
        color: "secondary"
      });

      // Enviar una acción de setComment a la tienda con el objeto de comentario editado
      this.$store
        .dispatch("addComment", this.item_to_edit)
        .then(() => {
          // En caso de guardar el comentario exitosamente, ocultar el diálogo de edición
          this.show_edit_comment = false;
          // Mostrar un mensaje de snackbar indicando que se guardó el comentario con éxito
          this.$store.dispatch("setSnackbar", {
            title: this.$t("comment_added"),
            color: "accent"
          });
          // Restablecer el objeto item_to_edit
          this.item_to_edit = {};
        })
        .catch(() => {
          // En caso de error de guardado, ocultar el diálogo de edición y mostrar un mensaje de error
          this.show_edit_comment = false;
          this.item_to_edit = {};
          Utils.showError(this, "comment_not_added");
        });
    },
    clearEditForm() {
      if (this.$refs.edit_comment_form) {
        this.$refs.edit_comment_form.resetValidation();
      }
    },
    getFriendlyDate(date) {
      return Utils.getFriendlyDate(date, true);
    }
  },
  computed: {
    hotel_comments_headers() {
      return [
        {
          text: this.$t("day"),
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("user"),
          align: "left",
          sortable: true,
          value: "user_full_name"
        },
        {
          text: this.$t("comment"),
          align: "left",
          sortable: true,
          value: "comment",
          width: "50%"
        },
        {
          text: this.$t("writed_at"),
          align: "left",
          sortable: true,
          value: "creation_datetime"
        },
        {
          text: null,
          align: "center",
          sortable: false,
          value: "actions"
        }
      ];
    },
    filteredComments() {
      let items = this.calendar_store.hotel_comments;
      if (this.search && this.search.length > 0) {
        items = items.filter(i =>
          i.comment.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return items;
    },
    ...mapState({
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
