<template>
  <div>
    <p v-if="kpi_to_edit != ''">
      {{ $t("modify_monthly_values_of") }} <b>{{ kpi.name }}</b
      >. {{ $t("you_can_also") }}
    </p>
    <div class="m--margin-bottom-20 text-left">
      <v-btn
        rounded
        @click="massive = !massive"
        outlined
        color="success"
        class="mr-10"
      >
        <v-icon>mdi-microsoft-excel</v-icon>
        <span class="pl-2">{{ $t("paste_from_excel") }} </span>
      </v-btn>
      <v-btn
        @click="copyFromLastYear"
        outlined
        rounded
        color="info"
        class="mr-10"
        :loading="copying_previous_year"
      >
        <v-icon>mdi-content-copy</v-icon>
        <span class="pl-2">{{ $t("copy_them_from_previous_year") }} </span>
      </v-btn>
    </div>
    <div class="massive_paste" v-show="massive">
      <v-row>
        <v-col
          ><div class="massive_paste_textarea">
            <v-textarea
              :placeholder="$t('paste_here_12_months_values')"
              class="form-control "
              auto-grow
              rows="4"
              v-model="massive_data"
              outlined
            ></v-textarea></div
        ></v-col>
        <v-col
          ><div class="massive_paste_selector m-form__group form-group">
            <p>{{ $t("which_format_you_paste_in_your_excel") }}</p>
            <v-radio-group v-model="punctuation">
              <v-radio
                id="comma"
                value="comma"
                color="secondary"
                label="1.000,00"
              />
              <v-radio
                id="dot"
                value="dot"
                color="secondary"
                label="1,000.00"
              />
            </v-radio-group></div
        ></v-col>
      </v-row>
    </div>
    <div class="buttons-wrapper mb-10">
      <v-btn
        v-show="massive && massive_data !== ''"
        color="secondary"
        @click="setMassive"
        small
        rounded
        outlined
        class="mr-3"
      >
        {{ $t("apply") }}
      </v-btn>
      <v-btn
        v-show="massive && massive_data !== ''"
        @click="massive_data = ''"
        small
        rounded
        outlined
        class="mr-3"
      >
        {{ $t("clean") }}
      </v-btn>
      <v-btn
        rounded
        v-show="massive"
        @click="massive = false"
        small
        class="mr-3"
      >
        {{ $t("cancel") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { cleanPunctuation, justNumber } from "@/utils";
import { mapGetters, mapState } from "vuex";
// BUDGET APP
function generateMonthValuesFromInput(data) {
  var rows = data.split("\n");
  var rows_data = [];
  for (var y in rows) {
    var cells = rows[y].split("\t");
    for (var x in cells) {
      if (cells[x] != undefined && cells[x] != "") {
        rows_data.push(cells[x]);
      }
    }
  }
  var i = 0;
  var month_values = [];
  for (i; i < 12; ++i) {
    month_values[i] =
      rows_data[i] != undefined && rows_data[i] != "" ? rows_data[i] : 0;
  }
  return month_values;
}

export default {
  data: function() {
    return {
      massive: false,
      massive_data: ""
    };
  },
  props: ["kpi", "kpi_to_edit"],
  computed: {
    ...mapState({
      budget_store: state => state.budget,
      copying_previous_year: state => state.budget.copying_previous_year
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      previous_year: "getPreviousBudgetYear"
    }),
    punctuation: {
      get: function() {
        return this.budget_store.excel_punctuation;
      },
      set: function(v) {
        this.$store.dispatch("setExcelPunctuation", v);
      }
    }
  },
  methods: {
    // Lanzada al pegar valores en el textarea
    setMassive: function() {
      const month_values = generateMonthValuesFromInput(this.massive_data);

      for (let i = 1; i <= 12; i++) {
        let v = month_values[i - 1];
        v = cleanPunctuation(v, this.punctuation);

        this.$store.dispatch("updateEditingKpi", {
          kpiCode: this.kpi_to_edit,
          kpiPeriod: "periodo" + i,
          value: justNumber(v)
        });
      }
    },
    copyFromLastYear: function() {
      this.$store.dispatch("copyFromPreviousYear", {
        year_before: this.previous_year,
        hotel_id: this.current_hotel.id,
        kpiCode: this.kpi.code
      });
    }
  }
};
</script>
